import React, { useState } from "react";
import { observer } from "mobx-react";
import { Form, Radio, Space } from "antd";
import notify from "@app/components/notify";
import BaseModal from "@app/components/modal";
import "./style/clone-modal.scoped.scss";

const projectCloneOption = {
    SEARCHES: "searches",
    SEARCHES_AND_ARTICLES: "searches and articles",
    INCLUDE: "include",
};

const ProjectCloneModal = observer(({ modalOpen, setModalOpen, project, projectState }) => {
    const [form] = Form.useForm();
    const [showFormValidationMessage, setShowFormValidationMessage] = useState(false);

    const closeModal = () => {
        form.resetFields();
        setShowFormValidationMessage(false);
        setModalOpen(false);
    };

    const onError = () => {
        notify.error("Please select at least one option");
    };

    const onSubmit = async (data) => {
        const option = {
            search: { include: false },
            sourceDocument: { include: false },
            reportDocument: { include: false },
        };
        const { sota, due, sourceDocument, reportDocument } = data;

        if (!sota && !due && !sourceDocument && !reportDocument) {
            setShowFormValidationMessage(true);
        } else {
            if (sota) {
                option.search.include = true;
                option.search.sota = sota;
            }

            if (due) {
                option.search.include = true;
                option.search.due = due;
            }

            if (sourceDocument) {
                option.sourceDocument.include = true;
            }

            if (reportDocument) {
                option.reportDocument.include = true;
            }
            await projectState.clone(project._id, option);
            closeModal();
        }
    };

    return (
        <BaseModal
            title="Please select the options for cloning"
            visible={modalOpen}
            onOk={form.submit.bind(form)}
            okText="Clone"
            onCancel={closeModal}
        >
            {showFormValidationMessage && (
                <label className="form-validation-message">Please select at least one option</label>
            )}

            <Form
                form={form}
                layout="vertical"
                name="Cloning Options"
                onFinish={onSubmit}
                onFinishFailed={onError}
            >
                <Form.Item label="SoTA" name="sota">
                    <div className="radio-group">
                        <Radio.Group>
                            <Space direction="vertical" size={1}>
                                <Radio value={projectCloneOption.SEARCHES}>Searches only</Radio>
                                <Radio value={projectCloneOption.SEARCHES_AND_ARTICLES}>
                                    Complete
                                </Radio>
                            </Space>
                        </Radio.Group>
                    </div>
                </Form.Item>
                <Form.Item label="DUE" name="due">
                    <div className="radio-group">
                        <Radio.Group>
                            <Space direction="vertical" size={1}>
                                <Radio value={projectCloneOption.SEARCHES}>Searches only</Radio>
                                <Radio value={projectCloneOption.SEARCHES_AND_ARTICLES}>
                                    Complete
                                </Radio>
                            </Space>
                        </Radio.Group>
                    </div>
                </Form.Item>
                <Form.Item label="Source Document" name="sourceDocument">
                    <div className="radio-group">
                        <Radio.Group>
                            <Space direction="vertical" size={1}>
                                <Radio value={projectCloneOption.INCLUDE}>Include</Radio>
                            </Space>
                        </Radio.Group>
                    </div>
                </Form.Item>
                <Form.Item label="Report Document" name="reportDocument">
                    <div className="radio-group">
                        <Radio.Group>
                            <Space direction="vertical" size={1}>
                                <Radio value={projectCloneOption.INCLUDE}>Include</Radio>
                            </Space>
                        </Radio.Group>
                    </div>
                </Form.Item>
            </Form>
        </BaseModal>
    );
});

export default ProjectCloneModal;
