/*
 * @param table A table over which the stepper iterates.
 * @param options An object with configuration.
 * @param options.row A row index for which this iterator will output cells. Can't be used together with `startRow` and `endRow`.
 * @param options.startRow A row index from which this iterator should start. Can't be used together with `row`. Default value is 0.
 * @param options.endRow A row index at which this iterator should end. Can't be used together with `row`.
 * @param options.column A column index for which this iterator will output cells.
 * Can't be used together with `startColumn` and `endColumn`.
 * @param options.startColumn A column index from which this iterator should start.
 * Can't be used together with `column`. Default value is 0.
 * @param options.endColumn A column index at which this iterator should end. Can't be used together with `column`.
 * @param options.includeAllSlots Also return values for spanned cells. Default value is "false".
 */
export default class TableStepper {
    _table;
    _startRow;
    _endRow;
    _startColumn;
    _endColumn;
    _includeAllSlots;
    _skipRows;
    _row;
    _rowIndex;
    _column;
    _cellIndex;
    _spannedCells;
    _nextCellAtColumn;
    _jumpedToStartRow;
    _tableMode;
    cellIndex;

    constructor(table, options = {}) {
        this._table = table;
        this._startRow = options.row !== undefined ? options.row : options.startRow || 0;
        this._endRow = options.row !== undefined ? options.row : options.endRow;
        this._startColumn =
            options.column !== undefined ? options.column : options.startColumn || 0;
        this._endColumn = options.column !== undefined ? options.column : options.endColumn;
        this._includeAllSlots = !!options.includeAllSlots;
        this._skipRows = new Set();
        this._row = 0;
        this._rowIndex = 0;
        this._column = 0;
        this._cellIndex = 0;
        this._spannedCells = new Map();
        this._nextCellAtColumn = -1;
        this._tableMode = options.tableMode === "JSON" ? "JSON" : "NODE";
    }

    [Symbol.iterator]() {
        return this;
    }

    next() {
        if (this._canJumpToStartRow()) {
            this._jumpToNonSpannedRowClosestToStartRow();
        }

        const row =
            this._tableMode === "NODE"
                ? this._table.content.content[this._rowIndex]
                : this._table.content[this._rowIndex];

        // Iterator is done when there's no row (table ended) or the row is after `endRow` limit.
        if (!row || this._isOverEndRow()) {
            return { done: true, value: undefined };
        }

        if (this._isOverEndColumn()) {
            return this._advanceToNextRow();
        }

        let outValue = null;

        const spanData = this._getSpanned();

        if (spanData) {
            if (this._includeAllSlots && !this._shouldSkipSlot()) {
                outValue = this._formatOutValue(spanData.cell, spanData.row, spanData.column);
            }
        } else {
            // corrupted table with empty rows
            if (!row.content || (this._tableMode === "NODE" && !row.content.content)) {
                return this._advanceToNextRow();
            }

            const cell =
                this._tableMode === "NODE"
                    ? row.content.content[this._cellIndex]
                    : row.content[this._cellIndex];

            if (!cell) {
                // If there are no more cells left in row advance to the next row.
                return this._advanceToNextRow();
            }

            const colspan = parseInt(cell.attrs.colspan || "1");
            const rowspan = parseInt(cell.attrs.rowspan || "1");

            // Record this cell spans if it's not 1x1 cell.
            if (colspan > 1 || rowspan > 1) {
                this._recordSpans(cell, rowspan, colspan);
            }

            if (!this._shouldSkipSlot()) {
                outValue = this._formatOutValue(cell);
            }

            this._nextCellAtColumn = this._column + colspan;
        }

        // Advance to the next column before returning value.
        this._column++;

        if (this._column === this._nextCellAtColumn) {
            this._cellIndex++;
        }

        // The current value will be returned only if current row and column are not skipped.
        return outValue || this.next();
    }

    /**
     * Marks a row to skip in the next iteration. It will also skip cells from the current row if there are any cells from the current row
     * to output.
     *
     * @param row The row index to skip.
     */
    skipRow(row) {
        this._skipRows.add(row);
    }

    /**
     * Advances internal cursor to the next row.
     */
    _advanceToNextRow() {
        this._row++;
        this._rowIndex++;
        this._column = 0;
        this._cellIndex = 0;
        this._nextCellAtColumn = -1;

        return this.next();
    }

    /**
     * Checks if the current row is over {@link #_endRow}.
     */
    _isOverEndRow() {
        // If #_endRow is defined skip all rows after it.
        return this._endRow !== undefined && this._row > this._endRow;
    }

    /**
     * Checks if the current cell is over {@link #_endColumn}
     */
    _isOverEndColumn() {
        // If #_endColumn is defined skip all cells after it.
        return this._endColumn !== undefined && this._column > this._endColumn;
    }

    /**
     * A common method for formatting the iterator's output value.
     *
     * @param cell The table cell to output.
     * @param anchorRow The row index of a cell anchor slot.
     * @param anchorColumn The column index of a cell anchor slot.
     */
    _formatOutValue(cell, anchorRow = this._row, anchorColumn = this._column) {
        return {
            done: false,
            value: new TableSlot(this, cell, anchorRow, anchorColumn),
        };
    }

    /**
     * Checks if the current slot should be skipped.
     */
    _shouldSkipSlot() {
        const rowIsMarkedAsSkipped = this._skipRows.has(this._row);
        const rowIsBeforeStartRow = this._row < this._startRow;

        const columnIsBeforeStartColumn = this._column < this._startColumn;
        const columnIsAfterEndColumn =
            this._endColumn !== undefined && this._column > this._endColumn;

        return (
            rowIsMarkedAsSkipped ||
            rowIsBeforeStartRow ||
            columnIsBeforeStartColumn ||
            columnIsAfterEndColumn
        );
    }

    /**
     * Returns the cell element that is spanned over the current cell location.
     */
    _getSpanned() {
        const rowMap = this._spannedCells.get(this._row);

        // No spans for given row.
        if (!rowMap) {
            return null;
        }

        // If spans for given rows has entry for column it means that this location if spanned by other cell.
        return rowMap.get(this._column) || null;
    }

    /**
     * Updates spanned cells map relative to the current cell location and its span dimensions.
     *
     * @param cell A cell that is spanned.
     * @param rowspan Cell height.
     * @param colspan Cell width.
     */
    _recordSpans(cell, rowspan, colspan) {
        const data = {
            cell,
            row: this._row,
            column: this._column,
        };

        for (let rowToUpdate = this._row; rowToUpdate < this._row + rowspan; rowToUpdate++) {
            for (
                let columnToUpdate = this._column;
                columnToUpdate < this._column + colspan;
                columnToUpdate++
            ) {
                if (rowToUpdate !== this._row || columnToUpdate !== this._column) {
                    this._markSpannedCell(rowToUpdate, columnToUpdate, data);
                }
            }
        }
    }

    /**
     * Marks the cell location as spanned by another cell.
     *
     * @param row The row index of the cell location.
     * @param column The column index of the cell location.
     * @param data A spanned cell details (cell element, anchor row and column).
     */
    _markSpannedCell(row, column, data) {
        if (!this._spannedCells.has(row)) {
            this._spannedCells.set(row, new Map());
        }

        const rowSpans = this._spannedCells.get(row);

        rowSpans.set(column, data);
    }

    /**
     * Checks if part of the table can be skipped.
     */
    _canJumpToStartRow() {
        return !!this._startRow && this._startRow > 0 && !this._jumpedToStartRow;
    }

    /**
     * Sets the current row to `this._startRow` or the first row before it that has the number of cells
     * equal to the number of columns in the table.
     *
     * Example:
     * 	+----+----+----+
     *  | 00 | 01 | 02 |
     *  |----+----+----+
     *  | 10      | 12 |
     *  |         +----+
     *  |         | 22 |
     *  |         +----+
     *  |         | 32 | <--- Start row
     *  +----+----+----+
     *  | 40 | 41 | 42 |
     *  +----+----+----+
     *
     * If the 4th row is a `this._startRow`, this method will:
     * 1.) Count the number of columns this table has based on the first row (3 columns in this case).
     * 2.) Check if the 4th row contains 3 cells. It doesn't, so go to the row before it.
     * 3.) Check if the 3rd row contains 3 cells. It doesn't, so go to the row before it.
     * 4.) Check if the 2nd row contains 3 cells. It does, so set the current row to that row.
     *
     * Setting the current row this way is necessary to let the `next()`  method loop over the cells
     * spanning multiple rows or columns and update the `this._spannedCells` property.
     */
    _jumpToNonSpannedRowClosestToStartRow() {
        const firstRowLength = this._getRowLength(0);

        for (let i = this._startRow; !this._jumpedToStartRow; i--) {
            if (firstRowLength === this._getRowLength(i)) {
                this._row = i;
                this._rowIndex = i;
                this._jumpedToStartRow = true;
            }
        }
    }

    /**
     * Returns a number of columns in a row taking `colspan` into consideration.
     */
    _getRowLength(rowIndex) {
        const row =
            this._tableMode === "NODE"
                ? this._table.content.content[rowIndex]
                : this._table.content[rowIndex];

        return this._tableMode === "NODE"
            ? row.content.content.reduce((acc, currentCell) => acc + currentCell.attrs.colspan, 0)
            : row.content.reduce((acc, currentCell) => acc + currentCell.attrs.colspan, 0);
    }
}

/**
 * An object returned by {@link module:table/tablewalker~TableWalker} when traversing table cells.
 */
class TableSlot {
    /**
     * The current table cell.
     */
    cell;

    /**
     * The row index of a table slot.
     */
    row;

    /**
     * The column index of a table slot.
     */
    column;

    /**
     * The row index of a cell anchor slot.
     */
    cellAnchorRow;

    /**
     * The column index of a cell anchor slot.
     */
    cellAnchorColumn;

    /**
     * The index of the current cell in the parent row.
     */
    _cellIndex;

    /**
     * The index of the current row element in the table.
     */
    _rowIndex;

    /**
     * The table element.
     */
    _table;

    /**
     * Creates an instance of the table walker value.
     *
     * @param tableWalker The table walker instance.
     * @param cell The current table cell.
     * @param anchorRow The row index of a cell anchor slot.
     * @param anchorColumn The column index of a cell anchor slot.
     */
    constructor(tableWalker, cell, anchorRow, anchorColumn, mode) {
        this.cell = cell;
        this.row = tableWalker._row;
        this.column = tableWalker._column;
        this.cellAnchorRow = anchorRow;
        this.cellAnchorColumn = anchorColumn;
        this._cellIndex = tableWalker._cellIndex;
        this._rowIndex = tableWalker._rowIndex;
        this._table = tableWalker._table;
    }

    /**
     * Whether the cell is anchored in the current slot.
     */
    get isAnchor() {
        return this.row === this.cellAnchorRow && this.column === this.cellAnchorColumn;
    }

    /**
     * The width of a cell defined by a `colspan` attribute. If the model attribute is not present, it is set to `1`.
     */
    get cellWidth() {
        return parseInt(this.cell.attrs.colspan || "1");
    }

    /**
     * The height of a cell defined by a `rowspan` attribute. If the model attribute is not present, it is set to `1`.
     */
    get cellHeight() {
        return parseInt(this.cell.attrs.rowspan || "1");
    }

    /**
     * The index of the current row element in the table.
     */
    get rowIndex() {
        return this._rowIndex;
    }
}
