import React, { useState } from "react";
import { observer } from "mobx-react";
import { Progress, Tooltip } from "antd";
import {
    DownOutlined,
    UpOutlined,
    ClearOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined,
    CheckOutlined,
} from "@ant-design/icons";

import "./progress.scoped.scss";

const BAR_COLOR_ACTIVE = "#6d84c2";
const BAR_COLOR_ERROR = "#ec7c7a";
const BAR_COLOR_SUCCESS = "#68cd2b";

const UploadProgress = observer(({ state }) => {
    const [expanded, setExpanded] = useState(false);

    // control the visibility of the widget
    if (!state.visible) {
        return;
    }

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    const clear = (event) => {
        event.stopPropagation();
        event.preventDefault();
        state.clear();
    };

    const ToggleIcon = () => {
        if (expanded) {
            return <UpOutlined />;
        } else {
            return <DownOutlined />;
        }
    };

    const done = state.files.filter((file) => file.complete).length;
    let barColor = BAR_COLOR_ACTIVE;
    if (state.hasError) {
        barColor = BAR_COLOR_ERROR;
    } else if (state.progress === 100) {
        barColor = BAR_COLOR_SUCCESS;
    }

    return (
        <div className="upload-progress">
            <div className="summary" onClick={toggleExpand}>
                <div className="buttons">
                    {state.hasError && (
                        <Tooltip title="Error uploading one of the files" placement="topRight">
                            <div className="error">
                                <ExclamationCircleOutlined />
                            </div>
                        </Tooltip>
                    )}
                    <Tooltip title="Clear" placement="topRight">
                        <div className="clear" onClick={clear}>
                            <ClearOutlined />
                        </div>
                    </Tooltip>
                    <div className="toggle">
                        <ToggleIcon />
                    </div>
                </div>
                <div className="title">Upload Progress</div>
                <div className="progress">
                    <span className="percent">{state.progress}% complete</span>
                    <span className="count">
                        {done} of {state.count} uploaded
                    </span>
                </div>
                <div className="bar">
                    <Progress
                        percent={state.progress}
                        showInfo={false}
                        strokeWidth={12}
                        strokeColor={barColor}
                        trailColor="#cecece"
                    />
                </div>
            </div>
            {expanded && (
                <div className="details">
                    {state.files.map((file) => {
                        return (
                            <File
                                key={file.uid}
                                name={file.name}
                                progress={file.progress}
                                complete={file.complete}
                                error={file.error}
                                onCancel={() => file.cancel()}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
});

const File = observer(({ name, progress, complete, error, onCancel }) => {
    let barColor = BAR_COLOR_ACTIVE;
    if (error) {
        barColor = BAR_COLOR_ERROR;
    } else if (complete) {
        barColor = BAR_COLOR_SUCCESS;
    }

    return (
        <div className="file">
            <div className="details">
                <span className="name">{name}</span>
                {!complete && !error && (
                    <Tooltip title="Cancel the upload of this file" placement="topRight">
                        <span className="icon remove" onClick={onCancel}>
                            <CloseCircleOutlined />
                        </span>
                    </Tooltip>
                )}
                {error && (
                    <Tooltip title="Error during the file upload" placement="topRight">
                        <span className="icon canceled">
                            <ExclamationCircleOutlined />
                        </span>
                    </Tooltip>
                )}
                {complete && (
                    <Tooltip title="The file was successfully uploaded" placement="topRight">
                        <span className="icon complete">
                            <CheckOutlined />
                        </span>
                    </Tooltip>
                )}
            </div>
            <div className="bar">
                <Progress
                    percent={progress}
                    showInfo={false}
                    strokeWidth={8}
                    trailColor="#cecece"
                    strokeColor={barColor}
                />
            </div>
        </div>
    );
});

export default UploadProgress;
