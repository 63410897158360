import React from "react";
import { observer } from "mobx-react";
import { ReasonCard } from "@app/components/setup/reason/reason";

import "./style/reason.scoped.scss";

const Reason = observer(({ data }) => {
    return (
        <div className="reason">
            <ReasonCard value={data} readonly={true} />
        </div>
    );
});

export default Reason;
