import React, { useEffect } from "react";
import { observer } from "mobx-react";
import cn from "classnames";
import { Tooltip } from "antd";

import onlineStatusStore from "@app/state/store/online-status";
import sectionsStore from "@app/state/store/report-document/report-document-sections";

import "./style/online-status.scoped.scss";

const OnlineStatus = observer(() => {
    const { isOnline } = onlineStatusStore;
    const { localSectionIds, syncChangesForSection } = sectionsStore;

    useEffect(() => {
        const runSync = async () => {
            const promises = localSectionIds.map((id) => syncChangesForSection(id));

            await Promise.all(promises);
        };

        if (isOnline && localSectionIds.length) {
            runSync();
        }
    }, [isOnline, localSectionIds]);

    return (
        <Tooltip title={isOnline ? "Online" : "Offline"}>
            <div
                className={cn("online-status", {
                    offline: !isOnline,
                })}
            />
        </Tooltip>
    );
});

export default OnlineStatus;
