import React from "react";
import { observer } from "mobx-react";
import { CheckOutlined } from "@ant-design/icons";

import "./style/report-document-section.scoped.scss";

const ReportDocumentSection = observer(({ data }) => {
    return (
        <div className="sectionContainer">
            <div>
                <b>Title:</b> {data.title}
            </div>
            <div>
                <b>Status:</b> {data.status}
            </div>
            {data.reviewers?.length > 0 && (
                <>
                    <div>
                        <u>Reviewers:</u>
                    </div>
                    <div>
                        {data.reviewers?.map(({ fullName, reviewed }) => (
                            <div className="reviewer">
                                {fullName} {reviewed && <CheckOutlined width="10" />}
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
});

export default ReportDocumentSection;
