import React from "react";
import { observer } from "mobx-react";
import { Form, Input } from "antd";

import Modal from "@app/components/modal";
import notify from "../../notify";

const CategoryForm = observer(({ onSave, onCancel, categories, category = undefined }) => {
    const [form] = Form.useForm();

    const error = () => {
        notify.error("Please fill all required fields to continue");
    };

    const close = () => {
        onCancel && onCancel();
        form.resetFields();
    };

    React.useEffect(() => {
        form.setFieldsValue({
            category: category?.name ?? "",
        });
    });

    const save = (data) => {
        const found = categories.find(
            (category) => category.name.trim().toUpperCase() === data.category.trim().toUpperCase(),
        );
        if (found && category?._id !== found._id) {
            notify.error(`Category ${data.category} already exists. Please choose another name.`);
            return;
        }

        if (category) {
            // Edit
            if (data.category.trim().toUpperCase() !== category.name.trim().toUpperCase()) {
                onSave &&
                    onSave({
                        ...category,
                        name: data.category,
                    });
            }
        } else {
            // Add
            const payload = {};
            payload.name = data.category;
            payload.system = false;
            payload.enabled = true;

            onSave && onSave(payload);
        }

        close();
    };

    return (
        <Modal
            title={category ? "Edit" : "Add Category"}
            visible={true}
            okText="Save"
            onOk={form.submit.bind(form)}
            onCancel={close}
            width={600}
        >
            <div className="category-form">
                <Form layout="vertical" form={form} onFinishFailed={error} onFinish={save}>
                    <Form.Item
                        label="Category Name"
                        name="category"
                        rules={[
                            {
                                required: true,
                                message: "Please enter the category name",
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input autoFocus={true} />
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
});

export default CategoryForm;
