import React from "react";
import { Tooltip } from "antd";
import { observer } from "mobx-react";
import { ReportDocumentSectionStatus } from "@app/constants";
import { EditOutlined, CheckOutlined, EyeOutlined } from "@ant-design/icons";

import "../styles/toc.scoped.scss";

const StatusIcon = observer(({ status = ReportDocumentSectionStatus.NOT_STARTED, onClick }) => {
    let tooltip = "The section hasn't been started yet";
    let icon = null;
    let className = "not-started";

    if (status === ReportDocumentSectionStatus.IN_PROGRESS) {
        className = "in-progress";
        tooltip = "This section is in-progress";
        icon = <EditOutlined />;
    } else if (status === ReportDocumentSectionStatus.READY_FOR_REVIEW) {
        className = "ready-for-review";
        tooltip = "This section is ready for review";
        icon = <EyeOutlined />;
    } else if (status === ReportDocumentSectionStatus.REVIEWED) {
        className = "reviewed";
        tooltip = "This section is reviewed";
        icon = <CheckOutlined />;
    }

    return (
        <Tooltip title={tooltip} placement="right">
            <div className={`status-icon ${className}`} onClick={onClick}>
                {icon}
            </div>
        </Tooltip>
    );
});

export default StatusIcon;
