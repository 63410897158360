import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { Table } from "antd";
// import { DownOutlined, UpOutlined, SelectOutlined } from "@ant-design/icons";
import format from "@app/lib/format";
import Status from "@app/components/report-document/status";
import { Role } from "@app/constants";
import NewTab from "@app/assets/icons/new-tab";

import "./style/overview.scoped.scss";

const TABLE_COLUMN = {
    DOCUMENT: "Document",
    STATUS: "Status",
    SINCE: "Since",
    PROGRESS: "Progress",
    ASSIGNED_TEAM: "Assigned Team",
    LINK: "Link",
};

const Overview = observer(({ project, reportDocuments, projectUsers }) => {
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        if (reportDocuments?.length > 0) {
            setTableData(buildTableData(reportDocuments));
        }
    }, [reportDocuments]);

    const totalAuthors = projectUsers?.filter((user) =>
        user.projectRoles.find((projectRole) =>
            [Role.LEAD_AUTHOR, Role.AUTHOR].includes(projectRole),
        ),
    );

    const buildTableData = (reportDocuments) => {
        return reportDocuments.map((reportDocument) => {
            const metaData = reportDocument.metaData;

            return {
                key: reportDocument._id,
                [TABLE_COLUMN.DOCUMENT]: reportDocument,
                [TABLE_COLUMN.STATUS]: { reportDocument, metaData },
                [TABLE_COLUMN.SINCE]: reportDocument,
                [TABLE_COLUMN.PROGRESS]: { reportDocument, metaData },
                [TABLE_COLUMN.ASSIGNED_TEAM]: { reportDocument, metaData },
                [TABLE_COLUMN.LINK]: reportDocument,
            };
        });
    };

    const renderReportName = (reportDocument) => {
        return reportDocument.name;
    };

    const renderStatus = ({ reportDocument, metaData }) => {
        return <Status.Status reportDocument={reportDocument} reportDocumentStatus={metaData} />;
    };

    const renderSince = (reportDocument) => {
        const since = reportDocument.review?.stage?.started
            ? reportDocument.review.stage.started
            : reportDocument.statusStarted;

        return <div className="since">{format.date(since)}</div>;
    };

    const renderProgress = ({ reportDocument, metaData }) => {
        return <Status.StageProgress reportDocumentStatus={metaData} />;
    };

    const renderAssignedTeam = ({ reportDocument, metaData }) => {
        return metaData?.currentStage ? (
            <Status.AssignedUsers users={metaData.currentStage.reviewers} />
        ) : (
            <Status.AssignedUsers users={Status.mapTeamStatus(totalAuthors, reportDocument)} />
        );
    };

    const renderLink = (reportDocument) => {
        const path = `/r/${reportDocument.project}/reports/documents/${reportDocument._id}`;

        return (
            <Link to={path} key={path} className="link">
                <NewTab />
            </Link>
        );
    };

    const columns = [
        {
            title: (
                <div
                // style={{ position: "absolute", left: "-30px" }}
                >
                    {TABLE_COLUMN.DOCUMENT}
                </div>
            ),
            dataIndex: TABLE_COLUMN.DOCUMENT,
            key: TABLE_COLUMN.DOCUMENT,
            width: "35%",
            render: renderReportName,
        },
        {
            title: TABLE_COLUMN.STATUS,
            dataIndex: TABLE_COLUMN.STATUS,
            width: "25%",
            render: renderStatus,
        },
        {
            title: TABLE_COLUMN.SINCE,
            dataIndex: TABLE_COLUMN.SINCE,
            width: "10%",
            render: renderSince,
        },
        {
            title: TABLE_COLUMN.PROGRESS,
            dataIndex: TABLE_COLUMN.PROGRESS,
            width: "10%",
            render: renderProgress,
        },
        {
            title: TABLE_COLUMN.ASSIGNED_TEAM,
            dataIndex: TABLE_COLUMN.ASSIGNED_TEAM,
            width: "15%",
            render: renderAssignedTeam,
        },
        {
            title: "",
            dataIndex: TABLE_COLUMN.LINK,
            width: "5%",
            render: renderLink,
        },
    ];

    // const ExpandedContainer = observer(({ record }) => {
    //     return <div className="expandedContainer">Expanded</div>;
    // });

    return (
        <div className="reportDocumentsOverview">
            <div className="helpMessage">
                You can see here an overview of the report documents progress. Please click on the
                document's name for more detail.
            </div>
            <Table
                columns={columns}
                dataSource={tableData}
                pagination={false}
                // expandable={{
                //     expandIcon: ({ expanded, onExpand, record }) =>
                //         expanded ? (
                //             <UpOutlined onClick={(e) => onExpand(record, e)} />
                //         ) : (
                //             <DownOutlined onClick={(e) => onExpand(record, e)} />
                //         ),
                //     expandedRowRender: (record) => <ExpandedContainer record={record} />,
                // }}
            />
        </div>
    );
});

export default Overview;
