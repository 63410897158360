import React from "react";
import { observer } from "mobx-react";
import { Select } from "antd";
import Group from "./group";

import "./style/min-max.scoped.scss";

const MinMax = observer(
    ({ filter, name, title, min, step = 1, max, disabled, collapsed, groupKey }) => {
        const options = [];
        const range = filter.state.filter[name] ?? {};

        if (min !== undefined && max !== undefined) {
            let value = max;
            while (value >= min) {
                options.push(value);
                value = value - step;
            }
        }

        /**
         * Update the value
         */
        const update = (args) => {
            let value = {
                ...range,
                ...args,
            };

            // reset the max value if the min value is bigger
            if (value.min !== undefined && value.max !== undefined && value.min > value.max) {
                const min = value.max;
                value.max = value.min;
                value.min = min;
            }

            filter.filter(name, value);
        };

        return (
            <Group
                className="min-max"
                title={title}
                hasActiveFilters={Object.keys(range).length}
                collapsed={collapsed}
                groupKey={groupKey ?? name}
            >
                <div className="options">
                    <div className="min">
                        <div className="title">From</div>
                        <Select
                            placeholder="any"
                            allowClear
                            defaultValue={range.min}
                            value={range.min}
                            onChange={(value) => {
                                update({ min: value });
                            }}
                            disabled={disabled}
                        >
                            {options.map((value) => {
                                return (
                                    <Select.Option key={value} value={value}>
                                        {value}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </div>
                    <div className="max">
                        <div className="title">To</div>
                        <Select
                            placeholder="any"
                            allowClear
                            defaultValue={range.max}
                            value={range.max}
                            onChange={(value) => {
                                update({ max: value });
                            }}
                            disabled={disabled}
                        >
                            {options.map((value) => {
                                return (
                                    <Select.Option key={value} value={value}>
                                        {value}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </div>
                </div>
            </Group>
        );
    },
);

export default MinMax;
