import { action, observable } from "mobx";
import BaseStore from "./base";
import { ws } from "@app/lib/socket";

export class OnlineStatusStore extends BaseStore {
    @observable isOnline = navigator.onLine;

    constructor() {
        super();

        window.addEventListener("online", this.handleOnline);
        window.addEventListener("offline", this.handleOffline);
    }

    handleOnline = () => {
        this.setOnlineStatus(true);
    };

    handleOffline = () => {
        this.setOnlineStatus(false);
    };

    @action.bound
    setOnlineStatus(status) {
        this.isOnline = status;
    }

    dispose() {
        window.removeEventListener("online", this.handleOnline);
        window.removeEventListener("offline", this.handleOffline);
    }
}

const onlineStatusStore = new OnlineStatusStore();

ws.on("connect", () => {
    onlineStatusStore.setOnlineStatus(true);
});

ws.on("disconnect", () => {
    onlineStatusStore.setOnlineStatus(false);
});

export default onlineStatusStore;
