import React, { useCallback, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { EditorContent } from "@tiptap/react";
import classNames from "classnames";
import { useScreenSize } from "@app/lib/screen";
import { ContextMenu } from "@app/components/report-document/editor/modules/context-menu";

import { DiffPlugin } from "@app/components/report-document/editor/modules/diff";
import BubbleList from "@app/components/report-document/editor/modules/node-position/components/bubble-list";
import "./styles/content.scoped.scss";
import InsertReportModal from "@app/components/report-document/editor/modules/report/modal";

const Content = observer(({ editor, size = "A4", sectionId, templateMode, historyMode }) => {
    const [centered, setCentered] = useState(true);
    const viewRef = useRef(null);
    const screen = useScreenSize();

    /**
     * Position the content view based on the size of the container
     */
    const position = useCallback(() => {
        if (viewRef.current) {
            const view = viewRef.current;
            const wrapper = view.parentElement;

            const width = view.offsetWidth;
            if (wrapper.clientWidth <= width) {
                setCentered(false);
            } else {
                setCentered(true);
            }
        }
    });

    /**
     * Position the content view based on the size of the container
     */
    const onRender = useCallback((ref) => {
        if (ref) {
            position();
        }
    });

    /**
     * Handle screen resize
     */
    useEffect(() => {
        position();
    }, [screen]);

    return (
        <ContextMenu editor={editor} templateMode={templateMode}>
            <div className="content" ref={onRender}>
                <BubbleList editor={editor} sectionId={sectionId} />
                <div className={classNames("view", { centered })} ref={viewRef}>
                    <EditorContent editor={editor} />
                    {historyMode && <DiffPlugin editor={editor} sectionId={sectionId} />}
                    <InsertReportModal editor={editor} />
                </div>
            </div>
        </ContextMenu>
    );
});

export default Content;
