import { Node, mergeAttributes } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import Render from "./node";

export default Node.create({
    name: "citation-group",
    group: "inline",
    inline: true,

    addAttributes() {
        return {
            id: {
                default: null,
                required: true,
            },
            comment: {
                default: undefined,
            },
        };
    },

    addOptions() {
        return {
            HTMLAttributes: {},
            readonly: false,
        };
    },

    parseHTML() {
        return [{ tag: "citation-group" }];
    },

    renderHTML({ HTMLAttributes }) {
        return ["citation-group", mergeAttributes(HTMLAttributes)];
    },

    addNodeView() {
        return ReactNodeViewRenderer(Render);
    },
});
