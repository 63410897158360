import { default as React, useEffect } from "react";
import { observer } from "mobx-react";
import { Form } from "antd";
import CaptionInput from "./input";

import { default as Modal } from "@app/components/modal";

export const CaptionModal = observer(({ open, caption, onSubmit, onCancel, title }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();

        if (caption) {
            form.setFieldsValue({
                caption,
            });
        }
    }, [caption, open]);

    return (
        <Modal
            title={title}
            visible={open}
            okText="Save"
            onOk={form.submit.bind(form)}
            onCancel={onCancel}
            width={800}
            forceRender
            focusTriggerAfterClose={false}
        >
            <Form
                layout="vertical"
                form={form}
                onFinish={(values) => {
                    values.caption = values.caption?.trim();
                    onSubmit(values);
                }}
                autoComplete={"off"}
            >
                <Form.Item name={"caption"}>
                    <CaptionInput />
                </Form.Item>
            </Form>
        </Modal>
    );
});

export default CaptionModal;
