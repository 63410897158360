import React from "react";
import classNames from "classnames";

import { CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";
import HBox from "@app/components/container/hbox/hbox";
import Title from "./title";

Header.Left = HBox.Left;
Header.Center = HBox.Center;
Header.Right = HBox.Right;
Header.Separator = Separator;

function Header({ title, spacing = true, closable, onClose, className, children, ...rest }) {
    /**
     * Handle the close icon click event
     */
    const close = () => {
        if (onClose) {
            onClose();
        }
    };

    /**
     * Render the page title if the title prop is passed
     */
    const PageTitle = () => {
        if (!title) {
            return null;
        }

        return (
            <HBox.Left>
                <Title>{title}</Title>
            </HBox.Left>
        );
    };

    /**
     * Render the close icon
     */
    const CloseIcon = () => {
        if (!closable) {
            return null;
        }

        return (
            <HBox.Right className="close-icon" shrink>
                <Button type="icon" onClick={close} icon={<CloseOutlined />}></Button>
            </HBox.Right>
        );
    };

    return (
        <HBox className={classNames("header", className, { spacing: spacing })} {...rest}>
            <PageTitle />
            {children}
            <CloseIcon />
        </HBox>
    );
}

function Separator({ className, ...rest }) {
    const classes = classNames("separator", className);
    return <div className={classes} {...rest}></div>;
}

export default Header;
