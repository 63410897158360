import React, { useState } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Button } from "antd";
import { SendOutlined } from "@ant-design/icons";
import notify from "@app/components/notify";
import Mention from "@app/components/mention";
import assigned from "@app/state/store/projects/assigned";

import "./style/input.scoped.scss";

const ENTER_KEY = "Enter";

const InputControl = observer(
    ({ comment, onSave, onCancel, className, focus, setFocus, showHint = false, ...props }) => {
        const [value, setValue] = useState((comment && comment.text) || "");
        /**
         * Handle the shift + enter key to save the comment
         */
        const onKeyDown = (event) => {
            if (event.key === ENTER_KEY && event.shiftKey) {
                event.preventDefault();
                save();
            }
        };

        const cancel = () => {
            onCancel && onCancel();
        };

        /**
         * Update state on input change event.
         */
        function onChange(value) {
            setValue(value);
        }

        /**
         * Save the comment
         */
        const save = async () => {
            if (value.trim() === "") {
                notify.error("Please enter any comment to save it");
                return;
            }

            const success = await onSave(value);
            if (success) {
                setValue("");
            }
        };

        const users = assigned.getAllUsers();

        return (
            <>
                <div className={classNames("input-control", className)} {...props}>
                    {!comment?.text && showHint && (
                        <div className="hint">You can use shift + enter to save</div>
                    )}
                    <div className="input">
                        <Mention
                            users={users}
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            value={value}
                            focus={focus}
                            setFocus={setFocus}
                        />
                    </div>

                    {!comment && (
                        <div className="button">
                            <Button
                                type="icon"
                                icon={<SendOutlined />}
                                onClick={save}
                                disabled={value.trim() === ""}
                            />
                        </div>
                    )}
                </div>
                {comment?.text && (
                    <div className="buttons">
                        <Button type="primary" onClick={save}>
                            Save
                        </Button>
                        <Button onClick={cancel}>Cancel</Button>
                    </div>
                )}
            </>
        );
    },
);

export default InputControl;
