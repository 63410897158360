import { computed, action } from "mobx";
import http from "@app/lib/http";
import BaseStore from "./base";
import project from "@app/state/store/projects/setup";
import report from "@app/state/store/report";
import ChatHistory from "@app/state/model/ai/chat-history";
import notify from "@app/components/notify/index";
import { AI_SUGGESTION_KEY } from "@app/constants";

export class AI extends BaseStore {
    observable() {
        return {
            loading: false,
            questions: [],
            chatHistory: [],
        };
    }

    @computed get busy() {
        return this.loading;
    }

    @computed get project() {
        return project.id || report.id;
    }

    @action
    async load() {
        try {
            this.loading = true;

            const { data: questionsData } = await http.get(`/ai/questions`);

            this.questions = questionsData;
        } catch (error) {
            notify.error(error.response?.data?.error);
        } finally {
            this.loading = false;
        }
    }

    @action
    async question(source, question, saveHistory = true, withHistory = true) {
        if (!source || !question) {
            return;
        }

        try {
            this.loading = true;

            if (!this.project) {
                return;
            }

            let { data } = await http.put(`/project/${this.project}/ai/question`, {
                source,
                question,
                saveHistory,
                withHistory,
            });

            if (data.answer) {
                return data.answer;
            } else {
                notify.info("No suggestion found");
            }
        } catch (error) {
            notify.error(
                "Error while answering your question. " +
                    "This may be due to the AI service experiencing technical difficulties. " +
                    "Please try again later or contact our team for further assistance.",
            );
        } finally {
            await this.getChatHistory(source);
            this.loading = false;
        }
    }

    @action
    async getChatHistory(source) {
        if (!source) {
            return;
        }

        try {
            this.loading = true;

            if (!this.project) {
                return;
            }

            let { data } = await http.get(`/project/${this.project}/ai/chat-history`, {
                source,
            });

            if (data) {
                this.chatHistory = data.map((item) => new ChatHistory(item));
            }
            return this.chatHistory;
        } catch (error) {
            notify.error(error.response?.data?.error);
        } finally {
            this.loading = false;
        }
    }

    @action isValidSuggestion(suggestion) {
        return (
            suggestion &&
            suggestion !== AI_SUGGESTION_KEY.NOT_REQUIRED &&
            suggestion !== AI_SUGGESTION_KEY.NO_MATCH
        );
    }
}

export default new AI();
