import React, { useState } from "react";
import { observer } from "mobx-react";
import { Redirect, useLocation } from "react-router-dom";
import Loader from "../../loader/http";
import "./style/login.scoped.scss";

import Footer from "./footer";
import Banner from "./banner";
import TermsAndConditionsModal from "./modal/terms-and-conditions-modal";

import session from "@app/state/store/session";
import profile from "@app/state/store/profile";

const LoginLayout = observer(({ children }) => {
    let location = useLocation();
    const [termsModalIsOpen, setTermsModalIsOpen] = useState(true);

    if (!session.loggedIn && session.mfaPending && location.pathname !== "/auth/mfa") {
        return <Redirect to="/auth/mfa" />;
    } else if (!session.loggedIn && !session.mfaPending && !location.pathname.startsWith("/auth")) {
        return <Redirect to="/auth/login" />;
    }

    if (session.loggedIn) {
        profile.load();

        if (!session.user?.termsAccepted) {
            return (
                <TermsAndConditionsModal
                    visible={termsModalIsOpen}
                    closable={false}
                    onOk={() => {
                        profile.save({ ...profile.data, termsAccepted: true });
                        setTermsModalIsOpen(false);
                    }}
                    onCancel={() => {
                        session.logout();
                    }}
                />
            );
        }

        if (session.redirect) {
            let redirect = session.redirect;
            session.redirect = null;

            return <Redirect to={redirect} />;
        } else {
            return <Redirect to="/" />;
        }
    }

    return (
        <div className="layout login">
            <Loader />
            <div className="wrapper">
                <div className="page">
                    <div className="content">
                        {children}
                        <Footer />
                    </div>
                </div>
                <Banner />
            </div>
        </div>
    );
});

export default LoginLayout;
