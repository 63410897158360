import { Extension } from "@tiptap/core";

import copyParserPlugin from "./copy-parser";

export default Extension.create({
    name: "clipboard",

    addCommands() {
        return {
            /**
             * Perform a cut command
             */
            cut: () => ({ state, commands }) => {
                // copy the selected text
                document.execCommand("copy");

                // remove the selected text
                const { from } = state.selection;
                commands.deleteSelection();
                commands.setTextSelection(from);
            },

            /**
             * Perform a copy command
             */
            copy: () => () => {
                document.execCommand("copy");
            },

            /**
             * Show the non supported warning
             */
            paste: () => ({ editor }) => {
                editor.emit("showClipboardWarning");
            },
        };
    },
    addProseMirrorPlugins() {
        return [copyParserPlugin({ editor: this.editor })];
    },
});
