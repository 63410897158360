import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { reaction } from "mobx";

import commentStore from "@app/state/store/report-document/comment";
import nodePositionStore from "@app/state/store/report-document/node-position";

const nodePositionPlugin = () => {
    return new Plugin({
        key: new PluginKey("nodePositionPlugin"),
        view(view) {
            const cleanup = reaction(
                () => {
                    return [commentStore.filteredComments];
                },
                () => {
                    nodePositionStore.onPositionUpdate(view);
                },
            );

            return {
                update: (updateView) => {
                    nodePositionStore.onPositionUpdate(updateView);
                },
                destroy: () => {
                    cleanup();
                },
            };
        },
    });
};

export const NodePosition = Extension.create({
    name: "node-position",
    addProseMirrorPlugins() {
        return [nodePositionPlugin()];
    },
});

export default NodePosition;
