import React, { createContext, useContext, useEffect } from "react";

import loadingStore, { LOADING_STATES } from "@app/state/store/loading-store";
import http from "@app/lib/http";

const LoadingStateContext = createContext();

export const LoadingStateContextProvider = ({ children }) => {
    return (
        <LoadingStateContext.Provider value={loadingStore}>{children}</LoadingStateContext.Provider>
    );
};

/**
 * Custom hook to manage the loading state, error handling, and network error detection
 * for a given store and method using the LoadingStateContext.
 * @note The storeName, methodName, and args should be the same as the ones used in the `AutoLoadingState` decorator.
 *
 * @param {string} storeName - The name of the store associated with the loading state.
 * @param {string} methodName - The name of the method being tracked in the loading state.
 * @param {Array} args - The arguments passed to the method being tracked.
 * @returns {Object} - An object containing various flags related to the loading state and errors.
 * @returns {boolean} isLoading - True if the method is currently loading.
 * @returns {boolean} isError - True if there was an error during the method execution.
 * @returns {boolean} isNotLoaded - True if the method has not been run.
 * @returns {boolean} isLoaded - True if the method completed successfully.
 * @returns {boolean} isNetworkError - True if the error is specifically a network error.
 * @returns {Error} error - The error object returned by the method.
 *
 * @throws {Error} - Throws an error if `useAutoLoadingState` is used outside of a `LoadingStateContextProvider`.
 */
export const useAutoLoadingState = (keyArgs) => {
    const loadingStore = useContext(LoadingStateContext);

    if (!loadingStore) {
        throw new Error("useAutoLoadingState must be used within a LoadingStateContextProvider");
    }

    const key = loadingStore.getKey(keyArgs);

    useEffect(() => {
        return () => {
            loadingStore.resetForKey(key);
        };
    }, [key]);

    const { loadingStates, errors } = loadingStore;

    const loadingState = loadingStates.get(key);
    const error = errors.get(key);

    const isLoading = loadingState === LOADING_STATES.LOADING;
    const isNotLoaded = !loadingState;
    const isLoaded = loadingStates.get(key) === LOADING_STATES.LOADED;
    const isError = loadingState === LOADING_STATES.ERROR;
    const isNetworkError = isError && http._isNetworkError(errors.get(key));

    return { isLoading, isError, isNotLoaded, isLoaded, isNetworkError, error };
};
