import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router-dom";
import Page from "@app/components/page/page";
import ReportDocumentsGroup from "@app/components/setup/report-documents/group";
import ToggleReportDocuments from "@app/components/setup/report-documents/toggle";
import StylesOverview from "@app/components/setup/report-documents/styles/overview";
import notify from "@app/components/notify";

import setup from "@app/state/store/projects/setup";
import projectStore from "@app/state/store/projects/details";
import store from "@app/state/store/report-document/report-documents";
import configClipboardStore from "@app/state/store/config-clipboard";

const SetupReportDocuments = observer(() => {
    const history = useHistory();
    const { id } = useParams();
    const config = setup.config;
    const clientId = projectStore.data.client._id;
    const [clientTemplates, setClientTemplates] = useState();
    const readonly = setup.isComplete;

    useEffect(() => {
        store.setProjectId(id);
        store.loadReportDocuments();

        return () => {
            store.reset();
        };
    }, [id]);

    useEffect(() => {
        (async () => {
            const templates = await store.getClientReportDocuments(clientId);
            setClientTemplates(templates);
        })();
    }, [clientId]);

    if (!store.reportDocuments || !config) {
        return;
    }

    // Update Project Configuration - Enable/Disable Source Documents.
    const updateConfig = async (data) => {
        const updates = {
            reportDocuments: {
                ...config.reportDocuments,
                ...data,
            },
        };

        config.set(updates);
        await setup.updateConfig(updates);
    };

    const close = () => {
        history.push("../setup");
    };

    const update = async (type, entry, args = {}) => {
        // reset the copy clipboard
        configClipboardStore.paste("reportDocument");

        try {
            await store.updateReportDocument(type, entry);
            await store.loadReportDocuments();
        } catch (ex) {
            const message = args.error || "Error saving the report document configuration";
            notify.error(message);
        }
    };

    const onCopy = (reportDocumentId) => {
        const copy = {
            id: reportDocumentId,
            sourceProjectId: id,
            sourceClientId: projectStore.data.client._id,
        };
        configClipboardStore.copyReportDocument(copy);
    };

    const onPaste = async () => {
        const pasteData = configClipboardStore.paste("reportDocument");
        await projectStore.pasteReportDocument(id, pasteData);
        await store.loadReportDocuments();
    };

    const onStyleEdit = () => {
        history.push(`./report-documents/styles`);
    };

    const copied =
        configClipboardStore.reportDocument.id &&
        configClipboardStore.reportDocument.sourceProjectId &&
        configClipboardStore.reportDocument.sourceClientId === projectStore.data.client._id;

    return (
        <Page>
            <Page.Header closable onClose={close}>
                <Page.Header.Left shrink>
                    <Page.Title>Report Documents</Page.Title>
                </Page.Header.Left>

                <Page.Header.Right></Page.Header.Right>
            </Page.Header>

            <Page.Body maxWidth={1000}>
                <ToggleReportDocuments
                    config={config.reportDocuments}
                    onChange={(data) => {
                        updateConfig(data);
                    }}
                    readonly={readonly}
                />

                {config.reportDocuments?.enabled && (
                    <>
                        <StylesOverview config={config} onEdit={onStyleEdit} readonly={readonly} />
                        <ReportDocumentsGroup
                            list={store.reportDocuments}
                            title="Report Documents"
                            onChange={(type, entry) => {
                                update(type, {
                                    project: id,
                                    client: clientId,
                                    ...entry,
                                });
                            }}
                            onCopy={onCopy}
                            onPaste={onPaste}
                            copied={copied}
                            clientTemplates={clientTemplates}
                            readonly={readonly}
                        />
                    </>
                )}
            </Page.Body>
        </Page>
    );
});

export default SetupReportDocuments;
