import React from "react";
import { Tooltip } from "antd";
import { CommentOutlined } from "@ant-design/icons";

import "./style/comments.scoped.scss";

const CommentsTag = ({ count }) => {
    return (
        <div className={"comments"}>
            <Tooltip title={`${count} ${count > 1 ? "Comments" : "Comment"}`}>
                <CommentOutlined />
            </Tooltip>
        </div>
    );
};

export default CommentsTag;
