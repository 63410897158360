import React from "react";
import { observer } from "mobx-react";

import Note from "@app/components/article/chunks/note";

const Notes = observer(({ data }) => {
    return (
        <div className="notes" style={{ marginTop: 5 }}>
            <Note className="group" text={data} readonly={true} />
        </div>
    );
});

export default Notes;
