import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Form, Input, Select } from "antd";

import Modal from "@app/components/modal";
import notify from "../../notify";

const ReasonForm = observer(({ value = {}, existing = [], onSave, onCancel }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            label: value.label,
            positive: value.positive,
        });
    }, [form, value]);

    /**
     * Show the validation error
     */
    const error = () => {
        notify.error("Please fill all required fields to continue");
    };

    /**
     * Cancel the edit
     */
    const cancel = () => {
        onCancel && onCancel();
        form.resetFields();
    };

    /**
     * Save the reason
     */
    const save = (data) => {
        const label = data.label.trim();

        // search for duplicates
        const duplicate = existing.find((entry) => {
            return entry.label.trim() === label && entry._id !== value._id;
        });

        if (duplicate) {
            notify.error("Keyword already exists. Please modify your input and try again");
            return;
        }

        value.label = label;
        value.positive = data.positive;

        onSave && onSave(value);
    };

    const title = value._id ? "Edit keyword" : "Add keyword";

    return (
        <Modal
            title={title}
            visible={true}
            okText="Save"
            onOk={form.submit.bind(form)}
            onCancel={cancel}
            width={600}
        >
            <div className="reason-form">
                <Form layout="vertical" form={form} onFinishFailed={error} onFinish={save}>
                    <Form.Item
                        label="Type"
                        name="positive"
                        rules={[
                            {
                                required: true,
                                message: "Please pick a type",
                            },
                        ]}
                    >
                        <Select>
                            <Select.Option value={true}>Positive</Select.Option>
                            <Select.Option value={false}>Negative</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Name"
                        name="label"
                        rules={[
                            {
                                required: true,
                                message: "Please enter the keyword",
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
});

export default ReasonForm;
