import React from "react";
import classNames from "classnames";
import { observer } from "mobx-react-lite";

import { Context, createState } from "./context";
import { useContent } from "./nested";
import Header from "./header";
import Footer from "./footer";
import Body from "./body";
import Sidebar from "./sidebar";
import Layout from "./layout";
import Title from "./title";

import "./styles/page.scoped.scss";

const Page = observer(({ className, children, ...rest }) => {
    const state = createState();
    const nested = useContent();

    return (
        <>
            <div className={classNames("page", className)} {...rest}>
                <Context.Provider value={state}>
                    <div className="center">{children}</div>
                </Context.Provider>
            </div>
            <div className="nested">{nested}</div>
        </>
    );
});

Page.Header = Header;
Page.Body = Body;
Page.Footer = Footer;
Page.Sidebar = Sidebar;
Page.Layout = Layout;
Page.Title = Title;

export default Page;
