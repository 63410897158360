import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import BaseModal from "@app/components/modal";
import { Form, Select, Switch, Input } from "antd";

import { ReportDocumentSection } from "@app/constants";

const SelectCustomObjectModal = observer(({ onInsert, onCancel, initialData = {}, id }) => {
    const [form] = Form.useForm();
    const [type, setType] = useState(initialData?.type);
    const [showCustomLabels, setShowCustomLabels] = useState(
        initialData?.name && initialData?.text,
    );
    const okText = id ? "Update" : "Insert";
    const formTitle = `${okText} Custom Object`;

    const onSubmit = async (data) => {
        form.resetFields();

        onInsert(data);
    };

    const onClickCancel = () => {
        form.resetFields();

        onCancel();
    };

    useEffect(() => {
        form.setFieldsValue(initialData);
    }, [initialData]);

    const onShowCustomLabelInput = (v) => {
        setShowCustomLabels(v);

        if (v) {
            form.setFieldsValue({ name: "Name", text: "Text" });
        }
    };

    const onSetType = (type) => {
        setType(type);
        form.resetFields();
        form.setFieldsValue({ type });
    };

    return (
        <BaseModal
            title={formTitle}
            visible={true}
            onOk={form.submit.bind(form)}
            okText={okText}
            onCancel={onClickCancel}
            bodyStyle={{ overflow: "visible" }}
        >
            <Form form={form} layout="vertical" name="commentForm" onFinish={onSubmit}>
                <Form.Item
                    label="Custom Object Type"
                    name="type"
                    rules={[
                        {
                            required: true,
                            message: "Please select type",
                        },
                    ]}
                >
                    <Select
                        placeholder="Please select type of custom object to insert"
                        onChange={onSetType}
                    >
                        <Select.Option
                            value={ReportDocumentSection.CUSTOM_OBJECT.TYPES.ABBREVIATIONS}
                        >
                            List of Abbreviations
                        </Select.Option>
                        <Select.Option value={ReportDocumentSection.CUSTOM_OBJECT.TYPES.REFERENCES}>
                            List of References
                        </Select.Option>
                    </Select>
                </Form.Item>

                {type === ReportDocumentSection.CUSTOM_OBJECT.TYPES.ABBREVIATIONS && (
                    <>
                        <Form.Item
                            label="Include custom labels"
                            name={"includeCustomLabels"}
                            valuePropName="checked"
                            style={{ marginBottom: 0 }}
                        >
                            <Switch onChange={onShowCustomLabelInput} />
                        </Form.Item>
                        {showCustomLabels && (
                            <>
                                <Form.Item
                                    label="Label for abbreviation name"
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter label",
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Label for abbreviation text"
                                    name="text"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter label",
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </>
                        )}
                    </>
                )}
            </Form>
        </BaseModal>
    );
});

export default SelectCustomObjectModal;
