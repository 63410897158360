import React from "react";
import { observer } from "mobx-react";
import BaseModal from "@app/components/modal";
import { Form } from "antd";
import notify from "@app/components/notify";
import Mention from "@app/components/mention";
import assigned from "@app/state/store/projects/assigned";

const ENTER_KEY = "Enter";

const Modal = observer(({ modalOpen, setModalOpen, onSubmitHandler }) => {
    const [form] = Form.useForm();

    const closeModal = () => {
        form.resetFields();
        setModalOpen(false);
    };

    const onError = () => {
        notify.error("Please enter a comment");
    };

    const onSubmit = async (data) => {
        closeModal();
        onSubmitHandler && (await onSubmitHandler({ text: data.comment }));
    };

    /**
     * Handle the shift + enter key to save the comment
     */
    const onKeyDown = async (event) => {
        if (event.key === ENTER_KEY && event.shiftKey) {
            event.preventDefault();
            form.submit();
        }
    };

    const users = assigned.getAllUsers();

    return (
        <BaseModal
            title="New Comment"
            visible={modalOpen}
            onOk={form.submit.bind(form)}
            okText="Add"
            onCancel={closeModal}
            bodyStyle={{ overflow: "visible" }}
            mask={false}
        >
            <Form
                form={form}
                layout="vertical"
                name="commentForm"
                onFinish={onSubmit}
                onFinishFailed={onError}
            >
                <Form.Item
                    label="Comment"
                    name="comment"
                    rules={[
                        {
                            required: true,
                            message: "Please add a comment",
                            whitespace: true,
                        },
                    ]}
                >
                    <Mention users={users} onKeyDown={onKeyDown} />
                </Form.Item>
            </Form>
        </BaseModal>
    );
});

export default Modal;
