import React from "react";
import { default as classes } from "classnames";
import { observer } from "mobx-react";

import TOC from "../../report-document/viewer/toc";
import Appendix from "../../report-document/viewer/appendix";

import Section from "./section";

import documentStore from "@app/state/store/report-document/document";

import "./styles/viewer.scoped.scss";

import CreateVersionModal from "./create-version-modal";
import CompareVersionsModal from "./compare-versions-modal";
import CompareNotification from "./compare-notification";

const Viewer = observer(
    ({ sections, citations, abbreviations, editingState, documentId, templateMode = false }) => {
        const hasTables = !!documentStore.tables.length;
        const hasFigures = !!documentStore.figures.length;
        const hasCitations = !!citations.cited.length;
        const hasAbbreviations = !!abbreviations.usedAbbreviations.length;
        const hasAppendix = hasTables || hasFigures || hasCitations || hasAbbreviations;

        const appendix = {
            hasAppendix,
            hasTables,
            hasFigures,
            hasCitations,
            hasAbbreviations,
        };

        return (
            <>
                <div className={classes({ viewer: true })}>
                    <TOC sections={sections} appendix={appendix} />
                    <div className="content">
                        <CompareNotification />

                        {sections.map((section) => (
                            <Section
                                key={section._id}
                                section={section}
                                editing={editingState}
                                templateMode={templateMode}
                                documentId={documentId}
                            />
                        ))}
                        <Appendix
                            sections={sections}
                            abbreviations={abbreviations}
                            citations={citations}
                            appendix={appendix}
                        />
                    </div>
                </div>
                <CreateVersionModal />
                <CompareVersionsModal />
            </>
        );
    },
);

Viewer.displayName = "Viewer";

export default Viewer;
