import React, { useEffect } from "react";
import classNames from "classnames";
import { CommentOutlined } from "@ant-design/icons";
import qs from "qs";
import { observer } from "mobx-react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Button, Spin } from "antd";
import { Page } from "@app/components/page";
import { download } from "@app/lib/file";
import DocumentSidebar from "@app/components/document/sidebar/index";
import PdfViewer from "@app/components/pdf-viewer";
import ExcelViewer from "@app/components/excel-viewer";

import state from "@app/state/store/report/document/details";
import bookmarksStore from "@app/state/store/report/document/bookmarks";

import applicationStore from "@app/state/store/application";

import report from "@app/state/store/report";
import notify from "@app/components/notify";
import File from "@app/state/model/file";

import "./style/preview.scoped.scss";

import BookmarkTab from "./bookmark-tab";
import ImagePreview from "./image-preview";

const getPreviewFile = (document, versionNumber) => {
    let entry = document;
    if (versionNumber) {
        entry = document.history?.find((history) => history.version === versionNumber);
    }

    if (entry?.preview) {
        return entry?.preview;
    }

    return entry?.file;
};

function parseParams({ area, excel }) {
    let json;
    try {
        if (area) {
            json = JSON.parse(area);
        } else if (excel) {
            json = JSON.parse(excel);
        }

        // eslint-disable-next-line
    } catch (ex) {}

    return json;
}

const Preview = observer(() => {
    const history = useHistory();
    const { search } = useLocation();
    const params = qs.parse(search, { ignoreQueryPrefix: true });

    const document = state.document || {};
    const { document: documentId } = useParams();
    params.load = documentId === document?._id;

    const goto = parseParams(params);
    const { version } = params;
    const file = getPreviewFile(document, Number(version));

    const readonly = report.readOnly;

    useEffect(() => {
        if (documentId && document._id !== documentId) {
            state.load(documentId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentId]);

    useEffect(() => {
        if (documentId && document._id !== documentId) {
            bookmarksStore.load(documentId);
        }

        return () => {
            bookmarksStore.reset();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentId]);

    if (!document._id || !file) {
        return;
    }

    /**
     * Close the article
     */
    const close = () => {
        history.push(`../${document._id}`);
    };

    const restore = async (data) => {
        await state.restore(document._id, data);
    };

    if (document?.deleted) {
        return (
            <div className="removedDocument">The source document was removed from the system</div>
        );
    }

    return (
        <Page>
            <Page.Header closable={true} onClose={close}>
                <Page.Header.Left>
                    <Page.Title breadcrumbs={false}>{`${document.name} ${
                        version ? `(Version ${version})` : ""
                    }`}</Page.Title>
                </Page.Header.Left>
                <Page.Header.Right shrink>
                    <Button
                        onClick={() => {
                            download(document.file._id);
                        }}
                    >
                        Download
                    </Button>
                </Page.Header.Right>
            </Page.Header>

            <Page.Layout>
                <Page.Body id="document-viewer" className="page-body" padding={false}>
                    <Viewer
                        file={file}
                        document={state.document}
                        comments={state.filteredComments}
                        goto={goto}
                    />
                </Page.Body>

                <DocumentSidebar
                    document={document}
                    onRestore={restore}
                    readonly={readonly}
                    enableAI={applicationStore.enableAI}
                    keepBookmarksOnUnmount={true}
                />
            </Page.Layout>
        </Page>
    );
});

const Viewer = observer(({ file: fileRaw, document, comments, goto }) => {
    const history = useHistory();
    const { search, pathname } = useLocation();
    const params = qs.parse(search, { ignoreQueryPrefix: true });
    const { project, category, document: documentId } = useParams();

    const file = fileRaw instanceof File ? fileRaw : new File(fileRaw);

    const highlights = [];
    const selectedComment = comments.find((comment) => {
        const matchVersion = comment.version === document.file?._id;
        return matchVersion && comment._id === params.comment;
    });
    const selectedBookmark = bookmarksStore?.bookmarks?.find(
        (bookmark) => bookmark._id === params.bid,
    );

    if (selectedComment) {
        highlights.push(selectedComment);
    } else if (selectedBookmark) {
        highlights.push(selectedBookmark);
    }

    const focusBookmark = (bookmark) => {
        const currentPath = pathname;
        history.push(`${currentPath}?bid=${bookmark._id}`);
    };

    const focusComment = (comment) => {
        const currentPath = pathname;
        history.push(`${currentPath}?comment=${comment._id}`);
    };

    /**
     * Handle the add highlight event
     */
    const addBookmark = (bookmark) => {
        bookmark.document = document._id;
        bookmarksStore.save(bookmark, focusBookmark);
    };

    /**
     * Handle the add comment event
     */
    const addComment = async (data) => {
        try {
            await state.saveComment(data, "desc", focusComment);
            return true;
        } catch (ex) {
            notify.error("Error saving the comment");
        }
    };

    const onCommentClick = (comment) => {
        let link = `/r/${project}/document/categories/${category}/${documentId}/open`;

        if (comment) {
            const commentId = selectedComment?._id !== comment._id ? comment._id : "";
            link += `?${commentId ? `comment=${commentId}` : ""}`;
        }

        const { pathname, search } = history.location;
        if (link !== pathname + search) {
            history.push(link);
        }
    };

    const renderPage = (props) => {
        const bookmarks = bookmarksStore?.bookmarks || [];

        const CommentTab = ({ comment }) => (
            <div
                onClick={() => {
                    const commentId = selectedComment?._id !== comment._id ? comment._id : "";
                    const link =
                        `/r/${project}/document/categories/${category}/${documentId}/open` +
                        `?${commentId ? `comment=${commentId}` : ""}`;

                    history.push(link);
                }}
                className={classNames({
                    "comment-tab": true,
                    selected: selectedComment?._id === comment._id,
                    pending: comment.status === "pending",
                    resolved: comment.status === "resolved",
                })}
                style={{
                    top: `${comment.areas[0].top}%`,
                }}
            >
                <CommentOutlined />
            </div>
        );

        return (
            <>
                {props.canvasLayer.children}
                {comments
                    .filter((comment) => {
                        const matchVersion = comment.version === document.file?._id;
                        const matchPage = comment.areas?.[0]?.pageIndex === props.pageIndex;

                        return matchVersion && matchPage;
                    })
                    .map((comment, idx) => (
                        <CommentTab comment={comment} key={idx} />
                    ))}
                {bookmarks
                    .filter(
                        (bookmark) =>
                            bookmark?.areas[0]?.pageIndex === props.pageIndex &&
                            bookmark?.document === documentId,
                    )
                    .map((bookmark) => (
                        <BookmarkTab key={bookmark._id} bookmark={bookmark} />
                    ))}
                {props.textLayer.children}
                {props.annotationLayer.children}
            </>
        );
    };

    if (state.loading) {
        return (
            <div className="loading">
                <Spin size="large" />
                <p>Loading Document...</p>
            </div>
        );
    }

    if (document.isImage && !document.preview) {
        return (
            <ImagePreview
                file={file}
                document={document}
                onBookmark={addBookmark}
                onComment={addComment}
                comments={comments}
            />
        );
    } else if (file.isExcel) {
        return (
            <ExcelViewer
                fileId={file._id}
                goto={goto}
                onBookmark={addBookmark}
                onComment={addComment}
                onCommentClick={onCommentClick}
                comments={comments}
            />
        );
    } else {
        return (
            <PdfViewer
                fileId={file._id}
                goto={goto}
                highlights={highlights}
                renderPage={renderPage}
                onBookmark={addBookmark}
                onComment={addComment}
            />
        );
    }
});

export default Preview;
