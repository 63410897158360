import React, { useState } from "react";
import { observer } from "mobx-react";
import { Button, Form, Input, Badge, Select, Switch, Checkbox, Card, Divider } from "antd";
import { BookOutlined, EditOutlined } from "@ant-design/icons";
import classNames from "classnames";
import Modal from "@app/components/modal";

import "./style/report.scoped.scss";

const ReportForm = observer(({ formState, setFormState, setIsSaved }) => {
    const handleFieldChange = (name) => (value) => {
        setFormState((state) => {
            const field = state[name];
            const fieldState = { ...field };
            if (value instanceof Array) {
                fieldState.values = value;
            } else {
                fieldState.value = value;
            }
            return { ...state, [name]: fieldState };
        });
        setIsSaved(false);
    };

    const fields = [
        Object.values(formState).filter((f) => ["input", "select"].includes(f.fieldType)),
        Object.values(formState).filter((f) => f.fieldType === "toggle"),
        Object.values(formState).filter((f) => f.fieldType === "checkbox"),
    ];

    const articleTagsField = formState["articleTags"];

    return (
        <Card
            title={
                <>
                    <BookOutlined /> Report Configuration
                </>
            }
        >
            <Form>
                <div className="form">
                    <div className="column">
                        {fields[0].map((field) => (
                            <Field
                                key={field.name}
                                field={field}
                                onChange={handleFieldChange(field.name)}
                            />
                        ))}
                        {articleTagsField && (
                            <Field
                                key={articleTagsField.name}
                                field={articleTagsField}
                                onChange={handleFieldChange(articleTagsField.name)}
                            />
                        )}
                    </div>
                    <Divider type="vertical" />
                    <div className="column">
                        {fields[1].map((field) => (
                            <Field
                                key={field.name}
                                field={field}
                                onChange={handleFieldChange(field.name)}
                            />
                        ))}
                    </div>
                    <Divider type="vertical" />
                    <div className="column">
                        {fields[2].length ? <b>Configured data points/columns:</b> : null}
                        {fields[2].map((field) => (
                            <Field
                                key={field.name}
                                field={field}
                                onChange={handleFieldChange(field.name)}
                            />
                        ))}
                    </div>
                </div>
            </Form>
        </Card>
    );
});

const InputField = ({ field, onChange }) => {
    return (
        <>
            <label className="label" htmlFor={field.name}>
                {field.label}
            </label>
            <Input value={field.value} onChange={(e) => onChange(e.target.value)} />
        </>
    );
};

const BadgeSelectField = ({ field, onChange }) => {
    return (
        <div className="badge-select-field">
            <label className="label" htmlFor={field.name}>
                {field.label}
            </label>
            <div>
                {field.options.map((option) => (
                    <Badge
                        className={classNames("badge-select", {
                            selected: field.value === option.value,
                        })}
                        count={option.label}
                        key={option.value}
                        onClick={() => onChange(option.value)}
                    />
                ))}
            </div>
        </div>
    );
};

const ToggleField = ({ field, onChange }) => {
    return (
        <>
            <Switch checked={field.value} onChange={onChange} />
            <label htmlFor={field.name}>{field.label}</label>
        </>
    );
};

const ReviewQuestionsField = ({ field, onChange }) => {
    const [showModal, setShowModal] = useState(false);
    const [selected, setSelected] = useState(field?.values?.map((i) => i.value) || []);

    const checkAll = field.options?.length === selected.length;

    const edit = () => {
        setShowModal(true);
    };

    const cancel = () => {
        // reset back to the original state
        setSelected(field?.values?.map((i) => i.value));
        setShowModal(false);
    };

    const save = () => {
        // get the options from the selected ids
        const selectedOptions = selected.reduce((acc, option) => {
            const found = field.options.find((i) => i.value === option);
            if (found) {
                acc.push(found);
            }
            return acc;
        }, []);
        onChange(selectedOptions);
        setShowModal(false);
    };

    return (
        <>
            <Switch
                checked={field.value}
                onChange={(value) => {
                    onChange(value);
                }}
            />
            <label htmlFor={field.name}>{field.label}</label>
            {field.value && field.options?.length > 0 && (
                <Button
                    type="icon"
                    style={{ marginLeft: "-15px" }}
                    onClick={edit}
                    icon={<EditOutlined />}
                ></Button>
            )}
            {showModal && (
                <Modal
                    title={field.modalTitle}
                    visible={true}
                    onOk={save}
                    okText="Save"
                    onCancel={cancel}
                    width={1000}
                >
                    <div>
                        <Checkbox
                            onChange={(e) =>
                                setSelected(
                                    e.target.checked ? field.options.map((i) => i.value) : [],
                                )
                            }
                            checked={checkAll}
                        >
                            Check all
                        </Checkbox>
                        <Divider style={{ margin: "6px 0" }} />
                        <div className="review-question-list">
                            <Checkbox.Group
                                options={field.options}
                                value={selected}
                                onChange={(list) => setSelected(list)}
                            />
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};

const CheckboxField = ({ field, onChange }) => {
    const handleChange = ({ target }) => {
        onChange(target.checked);
    };

    return (
        <>
            <Checkbox checked={field.value} onChange={handleChange} />
            <label htmlFor={field.name}>{field.label}</label>
        </>
    );
};

const MultiSelectField = ({ field, onChange }) => {
    return (
        <div className="multi-select">
            <label className="label" htmlFor={field.name}>
                {field.label}
            </label>
            <Select
                mode="multiple"
                allowClear
                labelInValue
                value={field.values}
                options={field.options}
                onChange={onChange}
                showArrow
                showSearch={false}
            ></Select>
        </div>
    );
};

export const Field = ({ field, onChange = () => {} }) => {
    let FieldToRender;
    const handleChange = (value) => {
        onChange(value);
    };

    const fieldMap = {
        input: InputField,
        select: BadgeSelectField,
        toggle: ToggleField,
        checkbox: CheckboxField,
        "multi-select": MultiSelectField,
    };

    if (field.name === "showReviewQuestions") {
        FieldToRender = ReviewQuestionsField;
    } else {
        FieldToRender = fieldMap[field.fieldType];
    }

    return (
        <Form.Item name={field.name}>
            <FieldToRender field={field} onChange={handleChange} />
        </Form.Item>
    );
};

export default ReportForm;
