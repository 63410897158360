import React from "react";
import { observer, useLocalStore } from "mobx-react";
import classNames from "classnames";
import { EditOutlined, DeleteOutlined, BulbOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { MarkdownViewer, MarkdownEditor } from "@app/components/markdown-editor";
import confirm from "@app/components/confirm";
import NoteModal from "./edit/note";

import "./style/note.scoped.scss";

/**
 * Render the article note
 */
const Note = observer(
    ({
        text,
        processing,
        className,
        onChange,
        onCancel,
        keywords,
        add,
        readonly = false,
        ...rest
    }) => {
        var state = useLocalStore(() => ({
            text: "",
            draft: "",
            edit: false,
            dirty: false,
            highlight: null,
        }));

        /**
         * Handle the edit event
         */
        const edit = () => {
            state.draft = text;
            state.edit = true;
            state.dirty = false;
        };

        /**
         * Handle the save event
         */
        const save = async () => {
            if (state.draft !== text) {
                onChange && onChange(state.draft);
            }

            state.draft = "";
            state.edit = false;
        };

        /**
         * Handle the remove
         */
        const remove = async () => {
            let proceed = await confirm("Are you sure you want to remove the note?");
            if (proceed) {
                state.draft = "";
                save();
            }
        };

        /**
         * Handle the abstract change event while editing
         */
        const update = (text) => {
            state.draft = text;
            state.dirty = true;
        };

        /**
         * Cancel the editing
         */
        const cancel = () => {
            state.draft = "";
            state.dirty = false;
            state.edit = false;

            onCancel();
        };

        return (
            <>
                {text && (
                    <div className={classNames("group note", className)} {...rest}>
                        <div className="header">
                            <div className="title">
                                <BulbOutlined className="icon" /> Note
                            </div>
                            <div className="icons">
                                {!state.edit && !readonly && (
                                    <>
                                        <Button
                                            type="icon"
                                            onClick={edit}
                                            icon={<EditOutlined />}
                                        ></Button>
                                        {text && (
                                            <Button
                                                type="icon"
                                                onClick={remove}
                                                icon={<DeleteOutlined />}
                                            ></Button>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="content">
                            {state.edit && (
                                <MarkdownEditor content={text} onChange={update} className="editor">
                                    <div className="buttons button-group">
                                        <Button type="primary" onClick={save}>
                                            Save
                                        </Button>
                                        <Button onClick={cancel}>Cancel</Button>
                                    </div>
                                </MarkdownEditor>
                            )}
                            {!state.edit && <MarkdownViewer content={text} keywords={keywords} />}
                        </div>
                    </div>
                )}
                {add && (
                    <NoteModal
                        onChange={(text) => {
                            onChange(text);
                        }}
                        onCancel={onCancel}
                    />
                )}
            </>
        );
    },
);

export default Note;
