import React, { useState } from "react";
import { observer } from "mobx-react";
import { Button, Input, Tooltip } from "antd";
import { SnippetsOutlined } from "@ant-design/icons";

import notify from "@app/components/notify";
import Modal from "@app/components/modal";
import { getSourceDocumentAttrs } from "@app/components/report-document/editor/modules/captis-link/helpers/pasteHandler";
import { ReportDocumentSection } from "@app/constants";

import "./style.scoped.scss";

const Picker = observer(({ editor, onPick, onClose }) => {
    const [href, setHref] = useState();
    const [type, setType] = useState();

    const onOk = () => {
        if (href && type) {
            onPick && onPick(href, type);
        } else {
            notify.info("Invalid source document link");
        }
    };

    const pasteHandler = async (e) => {
        let tempHref, tempType;
        const clipboardData = await navigator.clipboard.read();
        const clipboardItem = clipboardData?.[0];

        if (clipboardItem?.types) {
            await Promise.all(
                clipboardItem.types.map(async (type) => {
                    if ("text/html" === type) {
                        let html = await clipboardItem.getType("text/html");
                        if (html) {
                            const link = getSourceDocumentAttrs(await html.text());

                            if (link) {
                                tempHref = editor.commands.buildSourceDocumentHref(link);
                                tempType = ReportDocumentSection.CAPTIS_LINK.TYPE.SOURCE_DOCUMENT;
                            }
                        }
                    }
                }),
            );
        }

        if (!tempHref) {
            notify.info(
                "No source document link found!  Please use the copy feature from source document",
            );
        } else {
            if (tempHref === href) {
                return;
            }
            setHref(tempHref);
            setType(tempType);
        }
    };

    const showType = (type) => {
        let label = "Type: ";
        switch (type) {
            case ReportDocumentSection.CAPTIS_LINK.TYPE.SOURCE_DOCUMENT:
                label += "Source Document";
                break;
            default:
                label = "";
                break;
        }

        return label;
    };

    const typeLabel = showType(type);

    return (
        <Modal title="Source Document Link" visible={true} onOk={onOk} onCancel={onClose}>
            <div className="container">
                <Input.Group compact>
                    <Tooltip title="Paste link">
                        <Button icon={<SnippetsOutlined />} onClick={pasteHandler}></Button>
                    </Tooltip>
                    <Input
                        style={{ width: "93%", backgroundColor: "#f0f0f0" }}
                        value={typeLabel}
                        disabled={true}
                    />
                </Input.Group>
            </div>
        </Modal>
    );
});

export default Picker;
