import React, { useEffect } from "react";
import { Form, InputNumber, Button, Switch } from "antd";
import Modal from "@app/components/modal";
import CaptionInput from "@app/components/caption/input";

export const InsertTableModal = ({ open, onInsert, onClose }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (open) {
            form.setFieldsValue({
                withHeader: true,
                rows: 3,
                cols: 3,
                caption: "",
            });
        }
    }, [open]);

    return (
        <Modal
            title="Insert Table"
            visible={open}
            width={1000}
            onCancel={onClose}
            footer={<Button form={"insertTableForm"} htmlType={"submit"} children={"Insert"} />}
        >
            {open && (
                <Form
                    form={form}
                    id={"insertTableForm"}
                    name={"insertTableForm"}
                    layout={"vertical"}
                    onFinish={onInsert}
                    autoComplete={"off"}
                    children={
                        <>
                            <Form.Item
                                name="withHeader"
                                label="With Header Row"
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                            <Form.Item name="cols" label="Columns">
                                <InputNumber min={1} max={20} />
                            </Form.Item>
                            <Form.Item name="rows" label="Rows">
                                <InputNumber min={1} />
                            </Form.Item>
                            <Form.Item name="caption" label="Caption">
                                <CaptionInput />
                            </Form.Item>
                        </>
                    }
                />
            )}
        </Modal>
    );
};

export default InsertTableModal;
