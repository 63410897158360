import { action, computed } from "mobx";

import http from "@app/lib/http";
import notify from "@app/components/notify";
import report from "@app/state/store/report";
import reportDocuments from "@app/state/store/report-document/report-documents";
import documentStore from "@app/state/store/report-document/document";

import BaseStore from "../../base";
import { Citation } from "../../../model/citation";

/**
 * State management controlling the available citations to reference in report document editor
 */
export class CitationStore extends BaseStore {
    /**
     * Observable store data
     */
    observable() {
        return {
            citations: [],
            loading: false,
            loaded: false,
        };
    }

    /**
     * Return the project id of the currently loaded project
     */
    @computed get project() {
        return report.id;
    }

    /**
     * Gets available citations as a hash map
     */
    get citationsMap() {
        return this.citations.reduce((acc, citation) => ({ ...acc, [citation._id]: citation }), {});
    }

    /**
     * Get a unique list of citation ObjectIDs that have been used across all report document sections
     */
    get cited() {
        return reportDocuments.citationsSet;
    }

    /**
     * Load the list of articles, documents, and custom citations
     */
    @action
    async load() {
        this.loading = true;

        let { data } = await http.get(`/project/${this.project}/citation/all`);
        this.citations = data.list.map((citation) => new Citation(citation));

        documentStore.setCitations(this.citationsMap);

        this.loading = false;
        this.loaded = true;
    }

    /**
     * Save a citation
     */
    @action
    async save(params) {
        if (!params || !this.project || this.saving) {
            return;
        }

        try {
            let result;

            this.saving = true;

            if (!params._id) {
                result = await http.post(`/project/${this.project}/citation`, params);
            } else {
                result = await http.put(`/project/${this.project}/citation/${params._id}`, params);
            }

            await this.load();

            return result.data;
        } catch (ex) {
            notify.error(ex.response?.data?.error);
            return false;
        } finally {
            this.saving = false;
        }
    }

    @action
    async delete(params) {
        if (params._id) {
            try {
                const result = await http.delete(`/project/${this.project}/citation/${params._id}`);

                await this.load();

                return result.data;
            } catch (ex) {
                notify.error(ex.response?.data?.error);
            }
        }
    }
}

export default new CitationStore();
