import React, { useEffect } from "react";
import { observer, useLocalStore } from "mobx-react";
import Page from "@app/components/page/page";
import config from "@app/config";
import { Role } from "@app/constants";
import UserPicker from "@app/components/user/picker";

import "../style/team.scoped.scss";
import project from "@app/state/store/projects/details";
import assigned from "@app/state/store/projects/assigned";
import setup from "@app/state/store/projects/setup";

import Group from "@app/components/setup/team/group";
import { useHistory } from "react-router-dom";

const roles = config.projectRoles;

const Team = observer(() => {
    const readonly = setup.isComplete;
    const state = useLocalStore(() => ({
        pick: false,
        role: [Role.USER],
        projectRole: "",
        exclude: [],
    }));

    const history = useHistory();

    const handleClose = () => {
        history.push("../setup");
    };

    useEffect(() => {
        assigned.load(project.id);
    }, []);

    // return empty page while loading
    if (!project.id) {
        return null;
    }

    /**
     * Handle the user add event
     */
    const addUsers = (list) => {
        state.pick = false;
        assigned.assign(list, state.projectRole);
    };

    /**
     * Handle the user remove event
     */
    const removeUser = (id, projectRole) => {
        assigned.remove(id, projectRole);
    };

    /**
     * Handle the user add event
     */
    const showPicker = (projectRole) => {
        state.pick = true;
        state.projectRole = projectRole;

        // Any user can be assigned to any project role apart from PM,
        // which must have a PM system role.
        if (projectRole === Role.PROJECT_MANAGER) {
            state.role = [Role.PROJECT_MANAGER];
        } else if (projectRole === Role.CLIENT) {
            state.role = [Role.CLIENT];
        } else {
            state.role = [Role.PROJECT_MANAGER, Role.USER];
        }

        // exclude added users
        state.exclude = assigned.users.filter((user) => user.projectRole === projectRole);
    };

    /**
     * Handle the user add event
     */
    const closePicker = () => {
        state.pick = false;
    };

    /**
     * Close the current project
     */
    const closeProject = () => {
        history.push("/projects");
    };

    return (
        <>
            <Page>
                <Page.Header title="Team" closable onClose={handleClose} />
                <Page.Body maxWidth={1000}>
                    {roles.map((role) => {
                        return (
                            <Group
                                title={role.label}
                                role={role.value}
                                key={role.value}
                                users={assigned.users.filter(
                                    (user) => user.projectRole === role.value,
                                )}
                                onAdd={showPicker}
                                onRemove={(id) => removeUser(id, role.value)}
                                onCloseProject={closeProject}
                                disabled={readonly}
                            />
                        );
                    })}
                    <UserPicker
                        onClose={closePicker}
                        onPick={addUsers}
                        visible={state.pick}
                        role={state.role}
                        project={project.id}
                        client={project.data.client._id}
                        exclude={state.exclude}
                        title="Add Users"
                        addButton="Add"
                    />
                </Page.Body>
            </Page>
        </>
    );
});

export default Team;
