import React from "react";
import { observer } from "mobx-react";
import format from "@app/lib/format";
import "./style/title.scoped.scss";

const Title = observer(({ article = {}, keywords = [] }) => {
    return (
        <div className="title">
            <em>{format.cid(article.mdrId)}</em>
            <span
                dangerouslySetInnerHTML={{
                    __html: format.highlight(article.title, keywords, { sanitize: true }),
                }}
            />
        </div>
    );
});

export default Title;
