// import { ReactNodeViewRenderer } from "@tiptap/react";
import { Mark } from "@tiptap/core";
import cn from "classnames";
import { v4 as uuid } from "uuid";

import { pasteHandler } from "./helpers/pasteHandler";
import { ReportDocumentSection } from "@app/constants";
import notify from "@app/components/notify";

import "./style.scss";

const CaptisLinkMark = Mark.create({
    name: ReportDocumentSection.CAPTIS_LINK.MARK_NAME,
    inline: true,
    inclusive: false,
    inclusiveRight: false,

    onBeforeCreate() {
        this.storage.store = this.options.store || {};
    },

    addAttributes() {
        return {
            area: {
                default: null,
            },
            excel: {
                default: null,
            },
            documentId: {
                default: null,
            },
            id: {
                default: uuid(),
                parseHTML: (element) => element.getAttribute("id"),
            },
            project: {
                default: null,
            },
            section: {
                default: null,
            },
            type: {
                default: null,
            },
            target: {
                default: "_blank",
            },
            class: {
                default: undefined,
                renderHTML: (attrs) => {
                    const { isFocused } = attrs;

                    return {
                        class: cn({
                            "captis-link": true,
                            focused: isFocused,
                        }),
                    };
                },
            },
        };
    },

    addStorage() {
        return {
            store: {},
        };
    },

    parseHTML() {
        return [
            {
                tag: `span.${ReportDocumentSection.CAPTIS_LINK.MARK_NAME}`,
                getAttrs: (node) => {
                    return {
                        id: uuid(),
                        area: node.getAttribute("area"),
                        excel: node.getAttribute("excel"),
                        documentId: node.getAttribute("documentId"),
                        project: node.getAttribute("project"),
                        section: node.getAttribute("section"),
                        type: node.getAttribute("type"),
                        target: node.getAttribute("target"),
                    };
                },
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ["span", HTMLAttributes, 0];
    },

    addCommands() {
        return {
            insertCaptisLink: (link) => ({ editor, tr, commands }) => {
                if (link) {
                    if (link.project !== editor.options.editorProps.projectId) {
                        notify.info("Cannot insert link! The link is not from this project.");
                        return;
                    }

                    const { sectionId } = editor;
                    const { selection } = editor.state;
                    const { from, to } = selection;

                    // prepare the node attrs
                    const attrs = { ...link, section: sectionId, id: uuid() };
                    delete attrs.text;

                    const markType =
                        editor.schema.marks[ReportDocumentSection.CAPTIS_LINK.MARK_NAME];
                    const mark = markType.create(attrs);

                    if (link.text) {
                        const textNode = editor.schema.text(link.text, [mark]);

                        // fix for mismatched transaction error
                        setTimeout(() => {
                            if (attrs.isArticle) {
                                // paste the text if it was copied from an article
                                editor.chain().insertContentAt(from, link.text).run();
                            } else {
                                editor
                                    .chain()
                                    .insertContentAt(from, textNode.toJSON(), {
                                        updateSelection: true,
                                        parseOptions: {
                                            preserveWhitespace: "full",
                                        },
                                    })
                                    .focus()
                                    .setTextSelection(from, from + link.text.length)
                                    .setMark(ReportDocumentSection.CAPTIS_LINK.MARK_NAME, attrs)
                                    .run();
                            }
                        }, 0);
                    } else {
                        const tr = editor.state.tr;

                        tr.addMark(from, to, mark);

                        // fix for mismatched transaction error
                        setTimeout(() => {
                            editor.view.dispatch(tr);
                        }, 0);
                    }
                }
            },

            toggleCaptisLink: () => async ({ editor, commands, tr }) => {
                const { selection, doc } = editor.state;
                const selectedText = doc.textBetween(selection.from, selection.to);
                if (selectedText) {
                    const markType =
                        editor.schema.marks[ReportDocumentSection.CAPTIS_LINK.MARK_NAME];
                    const markPresent = doc.rangeHasMark(selection.from, selection.to, markType);
                    if (markPresent) {
                        setTimeout(() => {
                            editor
                                .chain()
                                .unsetMark(ReportDocumentSection.CAPTIS_LINK.MARK_NAME)
                                .run();
                        }, 0);
                    } else {
                        editor.emit("showCaptisLinkPicker");
                    }
                }
            },

            buildSourceDocumentHref: ({ project, documentId, area, excel }) => () => {
                let url =
                    window.location.origin + `/fp/r/${project}/document/bookmarks/${documentId}`;

                if (area) {
                    url += `?area=${area}`;
                } else if (excel) {
                    url += `?excel=${excel}`;
                }

                return url;
            },

            extractSourceDocumentLink: (href) => () => {
                const url = new URL(href);
                const area = url.searchParams.get("area");
                const excel = url.searchParams.get("excel");

                const parts = url.pathname.split("/");
                const project = parts[3];
                const documentId = parts[6];

                return { project, documentId, area, excel };
            },
        };
    },

    addProseMirrorPlugins() {
        const plugins = [];
        plugins.push(
            pasteHandler({
                editor: this.editor,
                type: this.type,
            }),
        );

        return plugins;
    },
});

export default CaptisLinkMark;
