import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Button } from "antd";
import { PlusOutlined, EditOutlined, WarningOutlined, DeleteOutlined } from "@ant-design/icons";
import UploadButton from "@app/components/form/upload/button";

import List from "../list/ordered";

import "./style/group.scoped.scss";

const Group = observer(
    ({
        title,
        button,
        children,
        right,
        className,
        onAdd,
        onAddButton,
        onEdit,
        onCopyButton,
        onPasteButton,
        onDelete,
        onUpload,
        ...rest
    }) => {
        return (
            <div className={classNames("group", className)} {...rest}>
                <div className="header">
                    <div className="title">{title}</div>
                    <div className="buttons">
                        {right}
                        {onPasteButton}
                        {onCopyButton}
                        {onAddButton
                            ? onAddButton
                            : onAdd && (
                                  <Button icon={<PlusOutlined />} type="icon" onClick={onAdd} />
                              )}

                        {onEdit && (
                            <Button icon={<EditOutlined />} type="icon" onClick={onEdit}></Button>
                        )}
                        {onDelete && (
                            <Button
                                icon={<DeleteOutlined />}
                                type="icon"
                                danger
                                onClick={onDelete}
                            ></Button>
                        )}
                        {onUpload && (
                            <UploadButton
                                accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                onChange={onUpload}
                                label=""
                            ></UploadButton>
                        )}
                    </div>
                </div>
                <div className="body">{children}</div>
            </div>
        );
    },
);

const Item = observer(({ id, index, order = true, children, className, disableDrag }) => {
    if (!order) {
        return <div className={classNames("group-item", className)}>{children}</div>;
    }

    return (
        <List.Item id={id} index={index} disableDrag={disableDrag}>
            <div className={classNames("group-item", className)}>{children}</div>
        </List.Item>
    );
});

const Empty = observer(({ warn = false, children }) => {
    return (
        <div className="group-empty">
            {warn && (
                <div className="icon">
                    <WarningOutlined />
                </div>
            )}
            <div className="message">{children}</div>
        </div>
    );
});

Group.Item = Item;
Group.Empty = Empty;

export default Group;
