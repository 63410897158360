import React, { useEffect } from "react";
import { observer, useLocalStore } from "mobx-react";
import { useHistory, useParams } from "react-router-dom";
import { Page } from "@app/components/page";
import adverseEvents from "@app/state/store/report/adverse-events";
import Details from "@app/components/adverse-events/details";

const EventDetails = observer(() => {
    const { id } = useParams();

    const history = useHistory();

    const state = useLocalStore(() => ({
        details: {},
    }));

    const close = () => {
        history.push("../adverse-events");
    };

    useEffect(() => {
        (async () => {
            const results = await adverseEvents.details(id);
            if (!results?.error) {
                state.details = results;
            }
        })();
    }, [state, id]);

    return (
        <Page className="adverse-events">
            <Page.Header title={id} closable onClose={close}></Page.Header>

            <Page.Layout>
                <Page.Body>
                    <Details data={state.details} />
                </Page.Body>
            </Page.Layout>
        </Page>
    );
});

export default EventDetails;
