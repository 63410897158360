import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Page } from "@app/components/page";
import Filter from "@app/components/filter";
import Projects from "./projects";
import DashboardFilter from "./filter";

import store from "@app/state/store/dashboard";
import session from "@app/state/store/session";
import profileStore from "@app/state/store/profile";

const Dashboard = observer(() => {
    // load the data when the component mounts
    useEffect(() => {
        store.load();
        profileStore.load(session.user._id);
    }, []);

    return (
        <Page className="dashboard">
            <Page.Header>
                <Page.Header.Left shrink>
                    <Page.Title>Dashboard</Page.Title>
                </Page.Header.Left>
            </Page.Header>

            <Filter.Header filter={store.filter} />

            <Page.Layout>
                <Page.Body filter={store.filter}>
                    {!store.loading && (
                        <Projects projects={store.projects} user={profileStore.data} />
                    )}
                </Page.Body>

                <DashboardFilter filter={store.filter} />
            </Page.Layout>

            <Page.Footer>
                <Filter.Pager filter={store.filter} />
            </Page.Footer>
        </Page>
    );
});

export default Dashboard;
