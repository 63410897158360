import React from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import format from "@app/lib/format";
import Card from "@app/components/card/card";

import IdList from "../article/chunks/id-list";
import Authors from "../article/chunks/authors";
import Snippets from "../article/chunks/snippets";

import "./style/result.scoped.scss";

const Result = observer(({ article, className, keywords = [], ...rest }) => {
    const Title = observer(() => {
        if (article.link) {
            return (
                <a href={article.link} target="_blank" rel="noreferrer">
                    <div
                        className="title"
                        dangerouslySetInnerHTML={{
                            __html: format.highlight(article.title, keywords, { sanitize: true }),
                        }}
                    />
                </a>
            );
        }

        return <div className="title">{article.title}</div>;
    });

    return (
        <Card className={classNames("result", className)} {...rest}>
            <Title />
            <Authors article={article} />
            <IdList article={article} />

            <Snippets article={article} className="mt-15" keywords={keywords} />
        </Card>
    );
});

export default Result;
