import React from "react";

export default function () {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.6953 5H13.2422C13.0645 5.02539 12.9375 5.15234 12.9375 5.30469C12.9375 5.40625 12.9629 5.48242 13.0137 5.5332L14.2324 6.75195L9.32617 11.6582C9.27539 11.7344 9.22461 11.8105 9.22461 11.8867C9.22461 11.9629 9.27539 12.0391 9.32617 12.0898L9.91016 12.6738C9.96094 12.7246 10.0371 12.7754 10.1133 12.7754C10.1895 12.7754 10.2656 12.7246 10.3418 12.6738L15.248 7.76758L16.4668 8.98633C16.5176 9.03711 16.5938 9.0625 16.6699 9.0625C16.8477 9.0625 16.9746 8.93555 17 8.75781V5.30469C17 5.15234 16.8477 5 16.6953 5Z"
                fill="#595959"
            />
            <path
                d="M10 7H7C5.89543 7 5 7.89543 5 9V15C5 16.1046 5.89543 17 7 17H13C14.1046 17 15 16.1046 15 15V12"
                stroke="#595959"
                strokeWidth="2"
            />
        </svg>
    );
}
