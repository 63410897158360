import { Node, mergeAttributes } from "@tiptap/core";

import "./style.scss";

export default Node.create({
    name: "landscape",
    group: "block",
    content: "block+",
    isolating: true,

    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },

    parseHTML() {
        return [{ tag: "landscape" }];
    },

    renderHTML({ HTMLAttributes }) {
        return ["landscape", mergeAttributes(HTMLAttributes), 0];
    },

    addCommands() {
        return {
            toggleLandscape: (options) => async ({ editor, commands, tr }) => {
                const { selection, doc } = editor.state;

                // ignore the action if the selection contains a landscape node
                const slice = doc.slice(selection.$from.pos, selection.$to.pos, false);
                const nodes = slice.content.content ?? [];
                for (const node of nodes) {
                    if (node.type.name === this.name) {
                        return;
                    }
                }

                if (editor.isActive(this.name)) {
                    return commands.unwrap(this.name);
                } else {
                    const from = selection.$from.before(1);
                    const to = selection.$to.after(1);

                    const slice = doc.slice(from, to, false);
                    let content = slice.content;
                    if (!content.content?.length) {
                        const pType = editor.schema.nodes.paragraph;
                        const paragraph = pType.create();
                        content = paragraph;
                    }

                    const node = this.type.create(null, content);

                    tr.replaceWith(from, to, node);
                    commands.setTextSelection(selection.from + 1);
                }
            },
        };
    },
});
