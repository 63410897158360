import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";

import "./style/header.scoped.scss";

const Group = observer(({ title, className, children, ...rest }) => {
    return (
        <div className={classNames("header", className)} {...rest}>
            <div className="title">{title}</div>
            {children}
        </div>
    );
});

export default Group;
