import { action, computed } from "mobx";
import { v4 as uuid } from "uuid";
import { events } from "@app/lib/store";

import http from "@app/lib/http";
import notify from "@app/components/notify";
import FilterState from "@app/components/filter/state";
import { Citation } from "@app/state/model/citation";

import BaseStore from "../../base";
import report from "../../report";

const STGR_LOAD = uuid();

/**
 * State management controlling the list of custom citations in the citations list page
 */
export class CustomCitationStore extends BaseStore {
    filter = null;

    /**
     * Observable store data
     */
    observable() {
        return {
            list: [],
            loading: false,
            saving: false,
        };
    }

    constructor() {
        super();

        // handle dictionary events
        events.on("citations.update", () => this.load());
        events.on("citations.delete", () => this.load());

        this.filter = new FilterState({
            default: { search: "" },
        });

        this.filter.on("find", () => {
            this.load();
        });

        events.on("project.unload", () => {
            this.reset();
        });
    }

    @computed get project() {
        return report.id;
    }

    @action
    async load() {
        this.loading = true;

        let filter = this.filter.value();
        let { data } = await http
            .get(`/project/${this.project}/citation/all`, filter)
            .stagger(STGR_LOAD);

        this.list = data.list.map((citation) => new Citation(citation));

        this.filter.stats(data.stats);
        this.loading = false;
    }

    @action
    async save(params) {
        if (!params || !this.project || this.saving) {
            return;
        }

        try {
            let result;

            this.saving = true;

            if (params._id) {
                result = await http.put(`/project/${this.project}/citation/${params._id}`, params);
            } else {
                result = await http.post(`/project/${this.project}/citation`, params);
            }

            // emit the update event
            const citation = new Citation(result.data);
            events.emit("citations.update", citation);
        } catch (ex) {
            notify.error(ex.response?.data?.error);
            return false;
        } finally {
            this.saving = false;
        }
    }

    @action
    async remove(id) {
        this.loading = true;
        let { data } = await http.delete(`/project/${this.project}/citation/${id}`);

        // emit the delete event
        let client = new Citation(data);
        events.emit("citations.delete", client);

        this.loading = false;
    }

    @action
    async fetchCitation(args) {
        const PMID = "pmid";
        const type = args.type === PMID ? "pmid" : "doi";

        const { data } = await http.get(`/project/${this.project}/citation/fetch/${type}`, {
            id: args.id,
        });

        if (data?.title) {
            return new Citation(data);
        }

        return undefined;
    }
}

export default new CustomCitationStore();
