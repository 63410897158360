import React from "react";
import { Tooltip } from "antd";

import { FORMATTING_TYPES, applyFormatting } from "../../utils";

import colors from "./colors";
import "./style.scss";

const Menu = ({ editor }) => {
    return (
        <div className="highlight-container">
            {colors.map((item, index) => (
                <Tooltip title={item.name} placement="top">
                    <div
                        className="item"
                        style={{ backgroundColor: item.value }}
                        key={index}
                        onClick={() => {
                            applyFormatting(editor, FORMATTING_TYPES.HIGHLIGHT, {
                                color: item.value,
                            });
                        }}
                    ></div>
                </Tooltip>
            ))}
        </div>
    );
};

export default Menu;
