import { action } from "mobx";
import { groupBy } from "lodash";

import NodePositionModel from "@app/state/model/report-document/node-position";
import { ReportDocumentSection } from "@app/constants";

import BaseStore from "../base";
import commentStore from "./comment";

const trackedMarks = [
    ReportDocumentSection.COMMENT.MARK_NAME,
    ReportDocumentSection.CAPTIS_LINK.MARK_NAME,
];

function isWrappedIn(doc, pos, type) {
    let depth = doc.resolve(pos).depth;
    while (depth > 0) {
        const node = doc.resolve(pos).node(depth);
        if (node.type.name === type) {
            return true;
        }
        depth--;
    }
    return false;
}

export class NodePosition extends BaseStore {
    observable() {
        return {
            nodes: [],
            applied: {},
            previousFoundCommentIds: [],
            sectionId: undefined,
        };
    }

    _getNodeData(node) {
        const nodes = [];
        if (node.attrs.comment) {
            const commentIds = node.attrs.comment.split(",");
            commentIds.forEach((id) => {
                nodes.push({
                    type: ReportDocumentSection.COMMENT.MARK_NAME,
                    id,
                });
            });
        }

        if (node.attrs[ReportDocumentSection.CAPTIS_LINK.MARK_NAME]) {
            nodes.push({
                type: ReportDocumentSection.CAPTIS_LINK.MARK_NAME,
                id: node.attrs[ReportDocumentSection.CAPTIS_LINK.MARK_NAME],
            });
        }

        const commentMark = node.marks.find(
            (mark) => mark.type.name === ReportDocumentSection.COMMENT.MARK_NAME,
        );

        if (commentMark) {
            const commentIds = commentMark.attrs.comment.split(",");

            commentIds.forEach((id) => {
                nodes.push({
                    type: ReportDocumentSection.COMMENT.MARK_NAME,
                    id,
                });
            });
        }

        const captisLinkMark = node.marks.find(
            (mark) => mark.type.name === ReportDocumentSection.CAPTIS_LINK.MARK_NAME,
        );

        if (captisLinkMark) {
            nodes.push({
                type: ReportDocumentSection.CAPTIS_LINK.MARK_NAME,
                id: captisLinkMark.attrs.id,
                metadata: captisLinkMark.attrs,
            });
        }

        return nodes;
    }

    getGroupedSectionNodes(sectionId) {
        const sectionNodes = this.nodes
            .filter((node) => node.sectionId === sectionId)
            .sort((a, b) => a.left - b.left);

        return groupBy(sectionNodes, "top");
    }

    getSectionCommentNodes(sectionId) {
        const sectionComments = commentStore.wholeSectionComments[sectionId] || [];

        if (!sectionComments.length) {
            return null;
        }

        return {
            nodes: sectionComments.map((comment) => ({
                type: ReportDocumentSection.COMMENT.MARK_NAME,
                id: comment._id,
                isSectionComment: true,
            })),
            pos: -52,
        };
    }

    @action onPositionUpdate(updateView) {
        const { sectionId } = updateView._props;
        const indexedNodes = {};
        let commentIds = undefined;
        let shouldUpdate = false;
        let checkForRemoved = false;
        const commentsInDocument = [];

        let { appliedComments } = updateView._props;
        if (!appliedComments) {
            appliedComments = updateView._props.appliedComments = {};
        }

        updateView.state.doc.descendants((node, pos) => {
            const foundNodes = this._getNodeData(node);

            foundNodes.forEach((nodeData) => {
                const { id, type, metadata } = nodeData || {};
                commentIds =
                    commentIds ?? commentStore.filteredComments.map((comment) => comment._id);

                if (type === ReportDocumentSection.COMMENT.MARK_NAME) {
                    commentsInDocument.push(id);
                }

                const shouldTrack = trackedMarks.includes(type);
                const shouldAdd =
                    type === ReportDocumentSection.COMMENT.MARK_NAME
                        ? commentIds.includes(id)
                        : true;

                checkForRemoved = shouldTrack || checkForRemoved;
                if (shouldTrack && id && shouldAdd) {
                    if (id && !indexedNodes[id]) {
                        let top, left;
                        try {
                            const { top: topPos, left: leftPos } = updateView.coordsAtPos(pos);

                            top = topPos;
                            left = leftPos;
                        } catch (e) {
                            return;
                        }
                        const isInLandscape = isWrappedIn(updateView.state.doc, pos, "landscape");
                        const container = updateView.dom.parentNode;

                        const containerRect = container.getBoundingClientRect();
                        const relativeTop = top - containerRect.top;

                        // find the existing record and check if it changed
                        const existing = this.nodes.find(
                            (node) => node.id === id && node.sectionId === sectionId,
                        );
                        if (existing?.top === relativeTop && existing?.left === left) {
                            indexedNodes[id] = existing;
                            return;
                        }

                        shouldUpdate = true;
                        this.applied[id] = indexedNodes[id] = new NodePositionModel({
                            id,
                            top: relativeTop,
                            left,
                            isInLandscape,
                            sectionId,
                            type,
                            metadata,
                        });
                    }
                }
            });
        });

        if (!shouldUpdate && checkForRemoved) {
            const existingNodes = this.nodes.filter((node) => node.sectionId === sectionId);
            if (existingNodes.length !== Object.keys(indexedNodes).length) {
                shouldUpdate = true;
            }
        }

        if (shouldUpdate) {
            const otherSectionNodes = this.nodes.filter((node) => node.sectionId !== sectionId);
            this.nodes = [...otherSectionNodes, ...Object.values(indexedNodes)];
        }
    }

    redraw(editor) {
        const sectionId = editor.sectionId;

        if (sectionId) {
            Object.values(this.applied).map((node) => {
                if (node.sectionId === sectionId) {
                    delete this.applied[node.id];
                }
            });

            this.nodes = this.nodes.filter((node) => node.sectionId !== sectionId);
            this.applied = { ...this.applied };
            this.onPositionUpdate(editor.view);
        }
    }
}

export default new NodePosition();
