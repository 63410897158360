import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import BaseCard from "@app/components/card/card";
import { Button, Tag } from "antd";
import { DeleteOutlined, EditOutlined, FilePdfOutlined } from "@ant-design/icons";
import DropArea from "@app/components/form/upload/drop/area";
import Icon from "./icon";
import format from "@app/lib/format";

import "./style/card.scoped.scss";
import { CitationType } from "@app/state/model/citation";

export const Card = observer(
    ({ citation, short, refStyle, readonly, onClick, onEdit, onRemove, onUpload, ...rest }) => {
        const canEdit = [
            CitationType.CUSTOM,
            CitationType.PREVIOUS_DUE,
            CitationType.PREVIOUS_SOTA,
        ].includes(citation.type);

        /**
         * Handle edit button click
         */
        const edit = (event) => {
            event.preventDefault();

            if (onEdit) {
                onEdit(citation, event);
            }
        };

        /**
         * Handle delete button click
         */
        const remove = (event) => {
            event.preventDefault();

            if (onRemove) {
                onRemove(citation, event);
            }
        };

        return (
            <BaseCard
                className={classNames("citation-card", { short, invalid: !citation.file })}
                onClick={onClick}
                {...rest}
            >
                <DropArea
                    onChange={onUpload}
                    accept=".pdf,application/pdf"
                    className="small"
                    disabled={citation.type !== CitationType.CUSTOM}
                >
                    <div className="card-wrapper">
                        <div className="details">
                            <div className="title">{citation.title}</div>
                            <div className="flex-break" />
                            <div
                                className="reference"
                                dangerouslySetInnerHTML={{
                                    __html: format.reference(citation, refStyle),
                                }}
                            />
                            <div className="tags">
                                {!citation.file && (
                                    <Tag className="" color="red">
                                        Missing Full Text
                                    </Tag>
                                )}
                                <Tag className="tag category">
                                    <Icon citation={citation} /> {citation.tagLabel}
                                </Tag>
                            </div>
                        </div>

                        {!readonly && (
                            <div className="actions">
                                {citation.file && (
                                    <a
                                        href={format.file(citation.file)}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <Button type="icon" icon={<FilePdfOutlined />}></Button>
                                    </a>
                                )}
                                {canEdit && onEdit && (
                                    <Button
                                        onClick={edit}
                                        type="icon"
                                        icon={<EditOutlined />}
                                    ></Button>
                                )}
                                {canEdit && onRemove && (
                                    <Button
                                        onClick={remove}
                                        type="icon"
                                        danger
                                        icon={<DeleteOutlined />}
                                    ></Button>
                                )}
                            </div>
                        )}
                    </div>
                </DropArea>
            </BaseCard>
        );
    },
);
