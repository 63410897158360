import { ReportTypes } from "@app/constants";

const generateArticlePreviewColumns = (fields, questions) => {
    const columns = [
        {
            title: "#",
            key: "num",
        },
    ];

    if (fields.showCID?.value) {
        columns.push({
            title: "CID",
            key: "mdrId",
        });
    }
    if (fields.showRefColumnCompact?.value) {
        columns.push({
            title: "Reference",
            key: "reference",
        });
    } else {
        columns.push({
            title: "Year",
            key: "publicationYear",
        });
        columns.push({
            title: "Title",
            key: "title",
        });
        columns.push({
            title: "Authors",
            key: "authors",
        });
        columns.push({
            title: "Source",
            key: "source",
        });
    }

    if (fields.showExcludedArticles?.value && fields.reviewStatus?.value) {
        if (fields.reviewStage?.value === "l1") {
            columns.push({
                title: "Included",
                key: "l1InclusionStatus",
            });
        } else if (fields.reviewStage?.value === "l2") {
            columns.push({
                title: "Included",
                key: "l2InclusionStatus",
            });
        } else {
            columns.push({
                title: "L1 Included",
                key: "l1InclusionStatus",
            });
            columns.push({
                title: "L2 Included",
                key: "l2InclusionStatus",
            });
        }
    }

    if (fields.showExclusionReason?.value) {
        columns.push({
            title: "Reason",
            key: "reason",
        });
    }

    if (fields.showReviewQuestions?.value) {
        fields.showReviewQuestions.values.forEach((v) => {
            columns.push({
                title: v.label,
                key: v.value,
            });
        });
    }

    Object.values(fields)
        .filter((field) => field.fieldType === "checkbox")
        .forEach((field) => {
            if (field.value) {
                columns.push({
                    title: field.label,
                    key: field.name,
                });
            }
        });

    if (fields.showReviewScore?.value) {
        columns.push({
            title: "Review Score",
            key: "score",
        });
    }

    return columns.reduce((acc, column) => ({ ...acc, [column.key]: column }), {});
};

const generateArticlePreviewRows = (fields, questions, additionalData) => {
    const articles = [
        {
            _id: "6347fbf78347e600ac1dac01",
            mdrId: "C1408",
            publicationYear: "2020",
            title:
                "Balana C et al. A phase II randomized, multicenter, open-label trial of continuing adjuvant temozolomide beyond 6 cycles in patients with glioblastoma ",
            authors:
                "Ilyas A, Chen C, Ding D, Mastorakos P, Taylor D, Pomeraniec I, Lee C, Sheehan J",
            source: "Neuro-oncology. 2020 Dec;22(12):1851-1861.",
            reference:
                "Balana C et al. A phase II randomized, multicenter, open-label trial of continuing adjuvant temozolomide beyond 6 cycles in patients with glioblastoma (GEINO 14-01). Neuro-oncology. 2020 Dec;22(12):1851-1861.",
            score: "3",
            l1InclusionStatus: "No",
            l2InclusionStatus: "No",
            reason: "Lack of detail for adequate evaluation",
        },
        {
            _id: "6347fbf78347e600ac1dac02",
            mdrId: "C1412",
            publicationYear: "2020",
            title:
                "Combined radiotherapy and concurrent tumor treating fields (TTFields) for glioblastoma:Dosimetric consequences on non-coplanar IMRT as initial results from a phase I trial. ",
            authors: "Guberina N et al. ",
            source: "Radiation oncology (London, England). 2020 Apr;15(1):83.",
            reference:
                "Guberina N et al. Combined radiotherapy and concurrent tumor treating fields (TTFields) for glioblastoma: Dosimetric consequences on non-coplanar IMRT as initial results from a phase I trial. Radiation oncology (London, England). 2020 Apr;15(1):83.",
            score: "4",
            l1InclusionStatus: "No",
            l2InclusionStatus: "No",
            reason: "Data not pertaining to review of interest",
        },
        {
            _id: "6347fbf78347e600ac1dac03",
            mdrId: "C633",
            publicationYear: "2018",
            title:
                "Cyst formation after stereotactic radiosurgery for brain arteriovenous malformations: a systematic review. ",
            authors: "Ilyas A et al. ",
            source: "Journal of neurosurgery. 2018 May;128(5):1354-1363.",
            reference:
                "Ilyas A et al. Cyst formation after stereotactic radiosurgery for brain arteriovenous malformations: a systematic review. Journal of neurosurgery. 2018 May;128(5):1354-1363.",
            score: "9",
            l1InclusionStatus: "Yes",
            l2InclusionStatus: "Yes",
            reason: "",
        },
        {
            _id: "6347fbf78347e600ac1dac04",
            mdrId: "C1414",
            publicationYear: "2019",
            title:
                "Efficacy and toxicity of particle radiotherapy in WHO grade II and grade III meningiomas: a systematic review.  ",
            authors: "Wu A et al. ",
            source: "Radiation oncology (London, England). 2020 Apr;15(1):83.",
            reference:
                "Wu A et al. Efficacy and toxicity of particle radiotherapy in WHO grade II and grade III meningiomas: a systematic review. Neurosurgical focus. 2019 Jun;46(6):E12.",
            score: "2",
            l1InclusionStatus: "No",
            l2InclusionStatus: "No",
            reason: "Lack of detail for adequate evaluation",
        },
        {
            _id: "6347fbf78347e600ac1dac05",
            mdrId: "C797",
            publicationYear: "2020",
            title:
                "Health-related quality of life in adult patients with brain metastases after stereotactic radiosurgery: a systematic, narrative review.",
            authors: "Verhaak E et al. ",
            source:
                "Supportive care in cancer : official journal of the Multinational Association of Supportive Care in Cancer. 2020 Feb;28(2):473-484.",
            reference:
                "Verhaak E et al. Health-related quality of life in adult patients with brain metastases after stereotactic radiosurgery: a systematic, narrative review. Supportive care in cancer : official journal of the Multinational Association of Supportive Care in Cancer. 2020 Feb;28(2):473-484.",
            score: "7",
            l1InclusionStatus: "Yes",
            l2InclusionStatus: "Yes",
            reason: "",
        },
    ];

    const getRandomProps = (index) => {
        return additionalData.reduce((acc, field) => {
            const len = field.options.length;
            const value = len
                ? field.options[index % len].value
                : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin quam eros, vulputate quis felis at, maximus viverra lorem. Sed volutpat ligula est, a tincidunt augue finibus id. Sed auctor lectus nec velit rhoncus finibus. ";
            return {
                ...acc,
                [field._id]: value,
            };
        }, {});
    };

    const getRandomAnswers = (index) => {
        return questions.reduce((acc, question) => {
            const randomAnswer = question.answers[index % question.answers.length].grade;
            return {
                ...acc,
                [question._id]: randomAnswer,
            };
        }, {});
    };

    const rows = [
        ...articles.map((article, index) => ({
            num: index + 1,
            ...article,
            ...getRandomAnswers(index),
            ...getRandomProps(index),
        })),
    ];
    return rows;
};

const generateArticlePreview = (fields, projectConfig) => {
    const questions = projectConfig.l2.questions;
    const additionalData = projectConfig.additionalData;
    const columns = generateArticlePreviewColumns(fields, questions);
    const rows = generateArticlePreviewRows(fields, questions, additionalData);

    return {
        rows,
        columns,
    };
};

let result;

const randomExclusionSummary = (exclusionReasons) => {
    if (result) {
        return result;
    }

    const randInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

    const totalArticles = randInt(100, 200);
    const numOfReasons = exclusionReasons.length;

    const randomExclusionReasons = exclusionReasons.map((reason) => {
        const count = randInt(0, totalArticles / numOfReasons);
        return {
            reason: reason.reason,
            count,
        };
    });

    const totalExcluded = randomExclusionReasons.reduce((acc, reason) => acc + reason.count, 0);

    result = {
        total: totalArticles,
        excluded: totalExcluded,
        included: totalArticles - totalExcluded,
        reasons: randomExclusionReasons,
    };

    return result;
};

const generateL1ExclusionSummaryPreview = (exclusionReasons) => {
    const { reasons, total, excluded } = randomExclusionSummary(exclusionReasons);

    return {
        columns: [
            {
                title: "#",
                key: "num",
            },
            {
                title: "Exclusion Reason",
                key: "reason",
            },
            {
                title: "Count",
                key: "count",
            },
        ],
        rows: [
            ...reasons.map((reason, index) => ({
                ...reason,
                num: index + 1,
            })),
            {
                num: "",
                reason: "Total abstracts/articles from databases",
                count: total,
            },
            {
                num: "",
                reason: "Total abstracts/articles excluded after screening (L1)",
                count: excluded,
            },
        ],
    };
};

const generateArticleFlowPreview = () => {
    return {
        columns: [
            {
                title: "Stage",
                key: "stage",
            },
            {
                title: "Count",
                key: "count",
            },
        ],
        rows: [
            {
                stage: "Total number of retrieved articles",
                count: 148,
            },
            {
                stage: "Articles after removing duplicates",
                count: 123,
            },
            {
                stage: "Articles screened",
                count: 123,
            },
            {
                stage: "Articles excluded at L1 (Title and Abstract Screening)",
                count: 86,
            },
            {
                stage: "Full-text articles reviewed at L2",
                count: 37,
            },
            {
                stage: "Full-text articles excluded at L2",
                count: 11,
            },
            {
                stage: "Articles included in the CER",
                count: 26,
            },
        ],
    };
};

const generateSearchParamsPreview = (fields, exclusionReasons) => {
    const columns = [
        {
            title: "#",
            key: "num",
        },
        {
            title: "Database",
            key: "database",
        },
        {
            title: "Date",
            key: "date",
        },
        {
            title: "Search Terms",
            key: "searchTerms",
        },
        {
            title: "Filters",
            key: "filters",
        },
        {
            title: "Results",
            key: "results",
        },
    ];

    if (fields.showIncludedCount?.value) {
        columns.push({
            title: "Included",
            key: "includedCount",
        });
    }
    if (fields.showExcludedCount?.value) {
        columns.push({
            title: "Excluded",
            key: "excludedCount",
        });
    }
    if (fields.showExcludedWithReasons?.value) {
        columns.push({
            title: "Exclusion Reasons",
            key: "excludedWithReasons",
        });
    }

    const r1 = randomExclusionSummary(exclusionReasons);
    const r2 = randomExclusionSummary(exclusionReasons);

    const rows = [
        {
            num: 1,
            key: "search1",
            database: "PubMed",
            date: "2020-01-01",
            searchTerms: "brain metastases",
            filters: "Language: English",
            results: r1.total,
            includedCount: r1.included,
            excludedCount: r1.excluded,
            excludedWithReasons: r1.reasons
                .map(({ reason, count }) => `${count} = ${reason}`)
                .join("\n"),
        },
        {
            num: 2,
            key: "search2",
            database: "Google Scholar",
            date: "2021-09-21",
            searchTerms: "brain metastases",
            filters: "Language: English",
            results: r2.total,
            includedCount: r2.included,
            excludedCount: r2.excluded,
            excludedWithReasons: r2.reasons
                .map(({ reason, count }) => `${count} = ${reason}`)
                .join("\n"),
        },
    ];

    return {
        rows,
        columns,
    };
};

export const generatePreview = ({ reportType }, projectConfig, fields) => {
    if ([ReportTypes.PRISMA_DIAGRAM].includes(reportType)) {
        return;
    } else if (reportType === ReportTypes.ARTICLE_FLOW) {
        return generateArticleFlowPreview();
    } else if (reportType === ReportTypes.L1_EXCLUSION_SUMMARY) {
        return generateL1ExclusionSummaryPreview(projectConfig.l1.excludeReasons);
    } else if (
        [
            ReportTypes.APPENDIX,
            ReportTypes.ARTICLE_DATA_SHEET,
            ReportTypes.L2_REVIEW_SUMMARY,
            ReportTypes.CUSTOM,
        ].includes(reportType)
    ) {
        return generateArticlePreview(fields, projectConfig);
    } else if (reportType === ReportTypes.SEARCH_PARAMS) {
        return generateSearchParamsPreview(fields, projectConfig.l1.excludeReasons);
    }
};
