import { observable, computed } from "mobx";
import Model from "@app/state/model/base";

export default class ProjectConfig extends Model {
    @observable tags = [];
    @observable slr = {
        l1: {
            excludeReasons: [],
        },
        l2: {
            enableScore: false,
            criteria: {},
            excludeReasons: [],
            questions: [],
        },
        keywords: {
            positive: [],
            negative: [],
        },
    };

    @observable sourceDocuments = {
        categories: [],
    };

    @observable reportDocuments = {
        enabled: false,
    };

    @observable sota = {
        enabled: false,
        l1: {
            excludeReasons: [],
        },
        l2: {
            enableScore: false,
            excludeReasons: [],
            questions: [],
        },
        keywords: {
            positive: [],
            negative: [],
        },
    };

    @observable search = {
        google: true,
        pubmed: true,
        embase: true,
        cochrane: true,
    };

    @observable reference = {
        style: undefined,
    };

    @observable risk = {
        startDate: true,
        endDate: true,
        productCodes: [],
    };

    @observable contentTags = [];
    @observable articleTags = [];
    @observable documentTags = [];

    @observable additionalData = [];
    @observable reports = [];
    @observable ai = {
        questions: [],
    };

    @computed get slrL1Configured() {
        return this.slr.l1.excludeReasons.length > 0;
    }

    @computed get slrL2Configured() {
        return this.slr.l2.questions.length > 0 && this.slr.l2.excludeReasons.length > 0;
    }

    @computed get sotaEnabled() {
        return this.sota.enabled;
    }

    @computed get sotaWorkflowEnabled() {
        return this.sota.enabled && this.sota.workflowEnabled;
    }

    @computed get sotaL1Configured() {
        return (
            !this.sota.enabled ||
            !this.sota.workflowEnabled ||
            this.sota.l1.excludeReasons.length > 0
        );
    }

    @computed get sotaL2Configured() {
        return (
            !this.sota.enabled ||
            !this.sota.workflowEnabled ||
            (this.sota.l2.questions.length > 0 && this.sota.l2.excludeReasons.length > 0)
        );
    }

    @computed get isConfigured() {
        return (
            this.slrL1Configured &&
            this.slrL2Configured &&
            this.sotaL1Configured &&
            this.sotaL2Configured
        );
    }
}
