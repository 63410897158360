import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import qs from "qs";
import { DownOutlined } from "@ant-design/icons";
import { Button, Menu, Dropdown } from "antd";
import classNames from "classnames";
import { Page } from "@app/components/page";
import { Review, SearchType } from "@app/constants";
import Details from "@app/components/article/details";
import ViewToggle, { useArticleViewState } from "@app/components/article/chunks/view-toggle";
import ArticleSidebar from "@app/components/article/sidebar";
import ArticleMenu from "@app/components/article/chunks/article-menu";
import confirm from "@app/components/confirm";
import { useSidebar } from "@app/components/page/sidebar";
import { ReasonType } from "@app/constants";
import OriginalArticleModal from "@app/components/article/original-article-modal";

import "./style/review.scoped.scss";

import state from "@app/state/store/report/article";
import session from "@app/state/store/session";
import reportStore from "@app/state/store/report";
import applicationStore from "@app/state/store/application";
import ArticleNavigation from "@app/components/article/chunks/navigation";
import l2Store from "@app/state/store/report/l2";
import articleActions from "@app/state/store/action/article";
import { ActionType } from "@app/constants";

const Article = observer(({ className, ...rest }) => {
    const history = useHistory();
    const [modalOpen, setModalOpen] = useState(false);
    const config = reportStore.config || {};
    const { article, type, stage } = useParams();
    const sidebar = useSidebar();
    const l2 = state.article?.review?.l2 || {};
    const l1Reasons = config[type]?.l1.excludeReasons || [];
    const duplicateReason = l1Reasons.find((reason) => ReasonType.DUPLICATE === reason.type);
    const keywords = config[type]?.keywords || [];
    const viewState = useArticleViewState();
    const readonly = !session.can("article.update") || reportStore.readOnly;
    const location = useLocation();

    const params = qs.parse(location.search, { ignoreQueryPrefix: true });

    const viewFile = params["view-file"] === "true";

    useEffect(() => {
        if (article) {
            state.load(article);

            articleActions.setContext({ stage: Review.L2, type });
            articleActions.load(ActionType.ARTICLE);

            viewState.reset();

            if (viewFile) {
                viewState.view = "file";
            }
        }
    }, [article, viewState, type, viewFile]);

    useEffect(() => {
        l2Store.article = article;
    }, [article]);

    const navigateTo = (id) => {
        history.push({
            pathname: `../l2/${id}`,
            search: history.location.search,
        });
    };

    const navigateToRecent = (type, stage, id) => {
        history.push({
            pathname: `../../${type}/${stage}/${id}`,
            search: history.location.search,
        });
    };

    const next = async () => {
        const id = await l2Store.getNextArticle();
        navigateTo(id);
    };

    const previous = async () => {
        const id = await l2Store.getPreviousArticle();
        navigateTo(id);
    };

    // do not render unless there is a loaded article
    if (!state.article?._id) {
        return;
    }

    /**
     * Close the article
     */
    const close = () => {
        const path = stage === "noreview" ? "../noreview" : "../l2";
        history.push({ pathname: path, search: history.location.search });
    };

    /**
     * Update article details
     */
    const update = async (data) => {
        await state.update(data);
    };

    /**
     * Start the review process
     */
    const start = () => {
        state.startL2({ type });
    };

    /**
     * Render the start button
     */
    const StartButton = observer(() => {
        // do not render if the review is started, or if
        // sota is disabled and type === sota (noreview)
        if (l2.started || stage === "noreview") {
            return;
        }

        return (
            <Button type="primary" onClick={start}>
                Review
            </Button>
        );
    });

    /**
     * Perform an pass/fail action on the article
     */
    const resolve = async (action, reason) => {
        // check if we are going to perform any action at all
        if (
            action === "pass" &&
            ((type === SearchType.SLR && state.article.slrL1Passed) ||
                (type === SearchType.SOTA && state.article.sotaL1Passed))
        ) {
            return;
        }

        if (duplicateReason?.reason === reason) {
            // trigger a original suggestion modal
            setModalOpen(true);
            return;
        }

        if (action === "pass") {
            await state.pass({ stage: Review.L1, type });
            return;
        }

        if (action === "fail") {
            await state.fail(Review.L1, { type, reason });
            close();
            return;
        }
    };

    /**
     * Prepare the reasons menu
     */
    const l1ReasonsMenu = (
        <Menu className="menu">
            {l1Reasons.map((reason) => {
                return (
                    <Menu.Item
                        key={reason._id}
                        onClick={() => {
                            resolve("fail", reason.reason);
                        }}
                    >
                        {reason.reason}
                    </Menu.Item>
                );
            })}
        </Menu>
    );

    const l1Complete =
        type === SearchType.SLR ? state.article?.slrL1Complete : state.article?.sotaL1Complete;
    const l1Passed =
        type === SearchType.SLR ? state.article?.slrL1Passed : state.article?.sotaL1Passed;

    const includeBtnType = l1Complete && l1Passed ? "success" : "default";
    const excludeBtnType = l1Complete && !l1Passed ? "danger" : "default";

    const removeFile = async () => {
        // switch to the summary view and remove the file
        viewState.view = "summary";
        await state.removeFile();
    };

    const onResetStatus = async () => {
        let proceed = await confirm("Are you sure you want to reset the status?");
        if (proceed) {
            await state.resetReviewStatus({ stage: "l2", type });
        }
    };

    const onRemoveArticle = async () => {
        let proceed = await confirm("Are you sure you want to remove this article?");
        if (proceed) {
            await state.deleteArticle(article);
        }
        close();
    };

    return (
        <Page className={classNames("l2", className)}>
            <Page.Header closable={true} onClose={close}>
                <Page.Header.Left>
                    <ArticleNavigation
                        onNext={next}
                        onPrevious={previous}
                        hasNext={l2Store.hasNextArticle()}
                        hasPrevious={l2Store.hasPreviousArticle()}
                        recentActions={articleActions?.data}
                        onRecent={navigateToRecent}
                    />
                    <Page.Title breadcrumbs={false}>
                        C{state.article.mdrId} - {state.article.title}
                    </Page.Title>
                </Page.Header.Left>
                <Page.Header.Right>
                    <ViewToggle article={state.article} viewState={viewState} />
                    <div className="separator" />

                    {stage !== "noreview" && !readonly && (
                        <Dropdown overlay={l1ReasonsMenu} trigger="click" placement="bottomRight">
                            <Button>
                                L1 Exclude <DownOutlined />
                            </Button>
                        </Dropdown>
                    )}

                    {stage === "noreview" && !readonly && (
                        <>
                            <Button type={includeBtnType} onClick={() => resolve("pass")}>
                                Include
                            </Button>

                            <Dropdown
                                overlay={l1ReasonsMenu}
                                trigger="click"
                                placement="bottomRight"
                            >
                                <Button type={excludeBtnType}>
                                    Exclude <DownOutlined />
                                </Button>
                            </Dropdown>
                        </>
                    )}

                    {!readonly && (
                        <ArticleMenu
                            article={state?.article}
                            onUpload={(file) => {
                                update({ file: file._id });
                            }}
                            onRemove={removeFile}
                            onChange={(data) => state.update(data)}
                            onChangeType={(type) => state.addType(type)}
                            switchArticleType={config.sota?.enabled}
                            onResetStatus={onResetStatus}
                            stage="l2"
                            onRemoveArticle={onRemoveArticle}
                        />
                    )}

                    {!readonly && (
                        <>
                            <Page.Header.Separator />
                            <StartButton />
                        </>
                    )}
                </Page.Header.Right>
            </Page.Header>

            <Page.Layout>
                <Page.Body className="page-body" padding={false}>
                    <Details
                        article={state.article}
                        type={type}
                        stage={stage === "noreview" ? Review.L1 : Review.L2}
                        viewState={viewState}
                        keywords={keywords}
                        onUpload={(file) => {
                            update({ file: file._id });
                        }}
                    />

                    {modalOpen && (
                        <OriginalArticleModal
                            type={type}
                            modalOpen={modalOpen}
                            setModalOpen={setModalOpen}
                            articleIds={[state.article._id]}
                            afterSelect={async () => {
                                await state.fail(Review.L1, {
                                    type,
                                    reason: duplicateReason.reason,
                                });
                                close();
                            }}
                        />
                    )}
                </Page.Body>

                <ArticleSidebar
                    article={article}
                    state={sidebar}
                    review={stage !== "noreview"}
                    width={450}
                    enableAI={applicationStore.enableAI}
                    readonly={readonly}
                />
            </Page.Layout>
        </Page>
    );
});

export default Article;
