import React, { useEffect } from "react";
import throttle from "./throttle";

export function useMediaQuery(args) {
    // set the initial state values
    const initial = React.useMemo(() => {
        const initial = {};
        Object.keys(args).map((key) => {
            const queryString = args[key];
            const query = window.matchMedia(queryString);
            initial[key] = query.matches;
        });

        return initial;
    }, [args]);

    // create the state with the initial values populated
    const [state, setState] = React.useState(initial);

    // attach the query event handlers to update the state after every change
    useEffect(() => {
        var queries = [];
        if (window?.matchMedia) {
            for (let key in args) {
                let string = args[key];

                let query = window.matchMedia(string);

                // update the current state if it is a different value
                if (state[key] !== query.matches) {
                    setState({ ...state, [key]: query.matches });
                }

                // attach an onchange event to handle the query state change
                const onChange = () => {
                    setState({ ...state, [key]: query.matches });
                };

                query.addListener(onChange);
                query.cleanup = function () {
                    query.removeListener(onChange);
                };

                queries.push(query);
            }
        }

        return function cleanup() {
            queries.map((query) => {
                query.cleanup();
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    return state;
}

export function useScreenSize(args) {
    const [state, setSize] = React.useState({
        width: document.body.clientWidth,
        height: document.body.clientHeight,
    });

    useEffect(() => {
        const onResize = throttle(() => {
            setSize({
                width: document.body.clientWidth,
                height: document.body.clientHeight,
            });
        });

        window.addEventListener("resize", onResize);
        onResize();

        return function cleanup() {
            window.removeEventListener("resize", onResize);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return state;
}
