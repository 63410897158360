import { mergeAttributes } from "@tiptap/core";
import OrderedList from "@tiptap/extension-ordered-list";
import cn from "classnames";

import "./style.scss";

export default OrderedList.extend({
    renderHTML({ HTMLAttributes }) {
        delete HTMLAttributes.start;

        return ["ol", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },
    addAttributes() {
        return {
            ...this.parent?.(),
            helpText: {
                default: false,
                parseHTML: (element) => element.classList.contains("help-text"),
            },
            hasComment: {
                default: false,
            },
            class: {
                default: undefined,
                renderHTML: (attributes) => {
                    return {
                        class: cn({
                            "help-text": attributes.helpText,
                            "has-comment": attributes.hasComment,
                        }),
                    };
                },
            },
        };
    },
});
