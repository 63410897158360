import React from "react";

import "./empty.scoped.scss";

const Empty = () => {
    return (
        <li className="no-results">
            <p>No versions found</p>
        </li>
    );
};

export default Empty;
