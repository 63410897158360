import React from "react";
import { observer } from "mobx-react";
import { NodeViewWrapper } from "@tiptap/react";
import cn from "classnames";

import documentStore from "@app/state/store/report-document/document";
import commentStore from "@app/state/store/report-document/comment";

import "./style.scss";

export default observer(({ editor, node }) => {
    const { comment, ids } = node.attrs;
    const isHighlighted = comment && comment.includes(commentStore.focusedComment);
    const status =
        commentStore.focusedComment &&
        commentStore.comments.find((c) => c._id === commentStore.focusedComment)?.status;
    const result = [];
    let indices = [];

    for (const id of ids) {
        const ref = documentStore.refMap[id];
        if (ref) {
            indices.push(ref.index);
        }
    }

    // sort the indices
    indices = [...new Set(indices)].sort();

    for (let i = 0; i < indices.length; i++) {
        const value = indices[i];

        // look ahead for incremental indices
        let skip = 1;
        let next = value + 1;

        while (next === indices[i + skip]) {
            skip++;
            next++;
        }

        next--;
        skip--;

        if (next > value + 1) {
            result.push(`${value}-${next}`);
            i += skip;
        } else {
            result.push(value);
        }
    }

    const value = result.join(",");

    return (
        <>
            <NodeViewWrapper
                className={cn("citation-group", {
                    pending: isHighlighted && status === "pending",
                    resolved: isHighlighted && status === "resolved",
                })}
            >
                <sup>[{value}]</sup>
            </NodeViewWrapper>
        </>
    );
});
