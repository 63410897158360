import React from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";
import "./style/http.scoped.scss";

import http from "@app/lib/http";

var state = observable({
    loading: false,
});

// attach to the http events
http.on("loading", (loading) => {
    state.loading = loading;
});

const Loader = observer(() => {
    // hide the loader
    if (!state.loading) {
        return null;
    }

    return (
        <div className="http-loader">
            <div className="bar"></div>
        </div>
    );
});

export default Loader;
