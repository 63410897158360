import React from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import { NodeViewWrapper } from "@tiptap/react";

import { ReportDocumentSection } from "@app/constants";

import commentStore from "@app/state/store/report-document/comment";
import References from "./references";
import Abbreviations from "./abbreviations";

import "./style.scss";

const ObjectWrapper = ({ isHighlighted, status, change, diffId, children, comment, selected }) => {
    return (
        <NodeViewWrapper>
            <div
                className={classNames("custom-object-node", {
                    pending: isHighlighted && status === "pending",
                    resolved: isHighlighted && status === "resolved",
                    inserted: change === "ins",
                    deleted: change === "del",
                    selected: selected,
                })}
                data-diff-id={diffId}
                data-diff-node={change}
                data-comment={comment}
            >
                {children}
            </div>
        </NodeViewWrapper>
    );
};

export const CustomObjectNodeView = observer(({ node, editor, selected }) => {
    const { "data-diff-node": change, "data-diff-id": diffId, comment, metadata } =
        node.attrs || {};
    const { type } = metadata || {};
    const isHighlighted = comment && comment.includes(commentStore.focusedComment);
    const status =
        commentStore.focusedComment &&
        commentStore.comments.find((c) => c._id === commentStore.focusedComment)?.status;

    if (type === ReportDocumentSection.CUSTOM_OBJECT.TYPES.REFERENCES) {
        return (
            <ObjectWrapper
                isHighlighted={isHighlighted}
                status={status}
                change={change}
                diffId={diffId}
                comment={comment}
                selected={selected}
            >
                <References metadata={metadata} />
            </ObjectWrapper>
        );
    }

    if (type === ReportDocumentSection.CUSTOM_OBJECT.TYPES.ABBREVIATIONS) {
        return (
            <ObjectWrapper
                isHighlighted={isHighlighted}
                status={status}
                change={change}
                diffId={diffId}
                comment={comment}
                selected={selected}
            >
                <Abbreviations metadata={metadata} />
            </ObjectWrapper>
        );
    }

    return null;
});

export default CustomObjectNodeView;
