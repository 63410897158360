import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";

import "./style/authors.scoped.scss";

/**
 * Render the author's list
 */
const Authors = observer(({ article, className, trim }) => {
    return <div className={classNames("authors", className, { trim: trim })}>{article.team}</div>;
});

export default Authors;
