import { observable } from "mobx";
import Model from "@app/state/model/base";

export default class ReportDocumentSection extends Model {
    @observable _id = undefined;
    @observable project = undefined;
    @observable client = undefined;
    @observable reportDocumentId = undefined;
    @observable title = undefined;
    @observable pos = undefined;
    @observable content = {};
    @observable status = undefined;
    @observable parent = undefined;
    @observable lockedBy = undefined;
    @observable toc = false;
    @observable displayPos = undefined;
    @observable citations = [];
    @observable reviewers = [];
}
