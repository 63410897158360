import { action, computed } from "mobx";
import BaseStore from "../base";
import { v4 as uuid } from "uuid";
import http from "@app/lib/http";
import Highlight from "@app/state/model/highlight";
import FilterState from "@app/components/filter/state";

import report from "../report";
import { events } from "@app/lib/store";

const STGR_LOAD = uuid();

/**
 * State management controlling the list of users in
 * the user management sections
 */
export class Highlights extends BaseStore {
    filter = null;

    /**
     * Observable store data
     */
    observable() {
        return {
            list: [],
            loading: false,
        };
    }

    constructor() {
        super();

        events.on("project.unload", () => {
            this.resetFilters();
            this.reset();
        });

        this.resetFilters();
    }

    @action
    resetFilters() {
        this.filter = new FilterState({
            default: {
                search: "",
                role: [],
            },
        });

        this.filter.on("find", () => {
            this.load();
        });
    }

    /**
     * Return the project id if the currently loaded project
     */
    @computed get project() {
        return report.id;
    }

    /**
     * Load a list with highlights from the backend
     */
    @action
    async load() {
        this.loading = true;

        let filter = this.filter.value();
        let { data } = await http
            .get(`/project/${this.project}/highlights`, filter)
            .stagger(STGR_LOAD);

        this.list = data.list.map((entry) => {
            return new Highlight(entry);
        });

        // update the query stats
        this.filter.stats(data.stats);
        this.loading = false;
    }
}

export default new Highlights();
