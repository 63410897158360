import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import qs from "qs";
import _ from "lodash";

// a custom hook that updates the filters by extracting the url search query
const useHistoryFilters = (filter) => {
    const history = useHistory();

    // removing listener before adding
    filter.removeAllListeners(["persist.history"]);
    filter.persistHistory = true;

    filter.once("persist.history", ({ name, value, resetPage = false }) => {
        const urlParams = qs.parse(history.location.search, {
            ignoreQueryPrefix: true,
        });

        if (resetPage) {
            urlParams.page = 1;
        }
        const filterState = { ...urlParams, [name]: value };
        const { page, ...rest } = filterState;

        const serializedValues = filter.serialized({ page, filter: rest });

        history.push({
            pathname: history?.location?.pathname,
            search:
                "?" + qs.stringify(serializedValues, { encodeValuesOnly: true, indices: false }),
        });
    });

    useEffect(() => {
        if (filter) {
            const parsedUrlParams = qs.parse(history.location.search, {
                ignoreQueryPrefix: true,
                decoder: (str, defaultDecoder) => {
                    if (str === "true" || str === "false") {
                        return str === "true";
                    } else {
                        return defaultDecoder(str);
                    }
                },
            });

            const { page, ...rest } = parsedUrlParams;

            if (!_.isEqual(filter.state.filter, rest)) {
                filter.state.filter = rest;
            }

            if (page) {
                filter.state.page = Number(page);
            } else {
                filter.state.page = 1;
            }

            filter.update();
        }
    }, [history.location.search, filter]);

    return { filter };
};

export default useHistoryFilters;
