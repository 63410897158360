import { Extension } from "@tiptap/core";
import { FORMATTING_TYPES, validElements } from "@app/components/report-document/editor/utils";

const validAlignments = [
    FORMATTING_TYPES.VERTICAL_ALIGN_TOP,
    FORMATTING_TYPES.VERTICAL_ALIGN_MIDDLE,
    FORMATTING_TYPES.VERTICAL_ALIGN_BOTTOM,
];

export const verticalAlign = Extension.create({
    name: "verticalAlign",

    addGlobalAttributes() {
        return [
            {
                types: validElements,
                attributes: {
                    verticalAlign: {
                        default: FORMATTING_TYPES.VERTICAL_ALIGN_TOP,
                        parseHTML: (element) => {
                            const alignment =
                                element.style.verticalAlign || FORMATTING_TYPES.VERTICAL_ALIGN_TOP;

                            const validAlignment = validAlignments.find(
                                (validAlignment) => validAlignment === alignment,
                            );

                            return validAlignment || FORMATTING_TYPES.VERTICAL_ALIGN_TOP;
                        },
                        renderHTML: (attributes) => {
                            let verticalAlign = attributes.verticalAlign;

                            if (verticalAlign === FORMATTING_TYPES.VERTICAL_ALIGN_TOP) {
                                return {};
                            }

                            return { style: `vertical-align: ${verticalAlign}` };
                        },
                    },
                },
            },
        ];
    },

    addCommands() {
        return {
            setVerticalAlign: (alignment) => ({ editor, commands }) => {
                const currentActiveElementName = validElements.find((element) =>
                    editor.isActive(element),
                );

                if (!validAlignments.includes(alignment)) {
                    return false;
                }

                if (!validElements.includes(currentActiveElementName)) {
                    return false;
                }

                return commands.updateAttributes(currentActiveElementName, {
                    verticalAlign: alignment,
                });
            },
        };
    },
});
