import React from "react";
import { Modal } from "antd";
import {
    ExclamationCircleOutlined,
    CheckCircleOutlined,
    QuestionCircleOutlined,
} from "@ant-design/icons";

export default function confirm(message, args = {}) {
    let title = args.title || "Confirmation Required";
    let type = args.type || "danger";
    let icon, danger;

    if (type === "danger") {
        icon = <ExclamationCircleOutlined />;
        danger = true;
    } else if (type === "success") {
        icon = <CheckCircleOutlined />;
    } else if (type === "default") {
        icon = <QuestionCircleOutlined />;
    }

    return new Promise((resolve) => {
        Modal.confirm({
            title: title,
            icon: icon,
            content: message,
            okText: "Cancel",
            cancelText: "OK",
            okButtonProps: {
                type: "default",
            },
            cancelButtonProps: {
                type: "primary",
                danger: danger,
            },
            onOk() {
                resolve(false);
            },
            onCancel(close) {
                if (close.triggerCancel) {
                    return resolve(false);
                }

                resolve(true);
                close();
            },
        });
    });
}
