import React from "react";
import { observer } from "mobx-react";

import Group from "../../group";
import "./style.scoped.scss";

const ReportDocumentStructureOverview = observer(
    ({ document, sections, readonly, onEdit, right }) => {
        const hasSections = !!sections?.length;

        if (!sections) {
            return null;
        }

        return (
            <Group title="Document Structure" onEdit={!readonly && onEdit} right={right}>
                <div className="structure">
                    {sections?.map((section) => {
                        const title = `${section.displayPos} ${section.title}`;
                        const level = section.pos.split("/").length;

                        return (
                            <div className={`section level-${level}`} key={section._id}>
                                {title}
                            </div>
                        );
                    })}

                    {!hasSections && (
                        <div className="empty text-center p-10">
                            No sections are configured for this document
                        </div>
                    )}
                </div>
            </Group>
        );
    },
);

export default ReportDocumentStructureOverview;
