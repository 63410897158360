import React from "react";

export default function () {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 36 36"
            className="icon"
        >
            <path fill="currentColor" d="M20.25 26H6v2.2h14.25a1.1 1.1 0 0 0 0-2.2Z" />
            <path fill="currentColor" d="M28 20H6v2.2h22a1.1 1.1 0 0 0 0-2.2Z" />
            <path
                fill="currentColor"
                d="M22.6 15.1a1.1 1.1 0 0 0-1.1-1.1H6v2.2h15.5a1.1 1.1 0 0 0 1.1-1.1Z"
            />
            <path fill="currentColor" d="M29.25 8H6v2.2h23.25a1.1 1.1 0 1 0 0-2.2Z" />
            <path fill="none" d="M0 0h36v36H0z" />
        </svg>
    );
}
