import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

import projectSetup from "@app/state/store/projects/setup";
import SetupReportsConfig from "@app/components/setup/reports/config/index";

const ProjectSetupReportConfig = observer(() => {
    const { id } = useParams();

    useEffect(() => {
        projectSetup.load(id);
    }, []);

    if (!projectSetup.config) {
        return null;
    }

    return <SetupReportsConfig setup={projectSetup} />;
});

export default ProjectSetupReportConfig;
