import React from "react";
import { observer, useLocalStore } from "mobx-react";
import { v4 as uuid } from "uuid";

import configClipboard from "@app/state/store/config-clipboard";

import List from "../../list/ordered";
import CopyConfig from "../../config-clipboard/copy";
import PasteConfig from "../../config-clipboard/paste";
import Group from "../group";
import Tag from "./card";
import Form from "./form";

const TagGroup = observer(({ list, title, onChange, readonly = false }) => {
    const state = useLocalStore(() => ({
        edit: false,
        tag: undefined,
    }));

    const copyable = list.filter((tag) => !tag.system);

    /**
     * Handle the reorder event and save the list
     */
    const reorder = (action) => {
        let [item] = list.splice(action.from, 1);

        list.splice(action.to, 0, item);
        save();
    };

    /**
     * Handle the remove event and save the list
     */
    const remove = (tag) => {
        const index = list.findIndex((entry) => {
            return entry._id === tag._id;
        });

        if (index !== -1) {
            list.splice(index, 1);
            save();
        }
    };

    /**
     * Handle the edit canceled event and hide the form
     */
    const onCancel = () => {
        state.edit = false;
        state.tag = undefined;
    };

    /**
     * Handle the save event from an edit or an add operation
     */
    const onSave = (entry) => {
        state.edit = false;
        state.tag = undefined;

        // add operation
        if (!entry._id) {
            list.push(entry);
        }

        save();
    };

    /**
     * Do the actual configuration update
     */
    const save = () => {
        onChange && onChange(list);
    };

    const copy = () => {
        configClipboard.copy("tags", copyable);
    };

    const paste = () => {
        list.push(
            ...configClipboard.paste("tags", (entry) =>
                list.some((li) => li.label === entry.label),
            ),
        );
        save();
    };

    return (
        <>
            <Group
                title={title}
                className="tags"
                onAdd={
                    readonly
                        ? undefined
                        : () => {
                              state.edit = true;
                              state.tag = undefined;
                          }
                }
                onCopyButton={
                    <CopyConfig onClick={copy} tooltip={"Copy Tags"} disabled={!copyable.length} />
                }
                onPasteButton={
                    !readonly && configClipboard.tags.length ? (
                        <PasteConfig onClick={paste} tooltip={"Paste Tags"} />
                    ) : null
                }
            >
                <List onChange={reorder}>
                    {list.map((entry, index) => {
                        const id = entry._id || "new_" + uuid();

                        return (
                            <List.Item index={index} key={id} id={id} disableDrag={readonly}>
                                <Tag
                                    index={index}
                                    key={id}
                                    value={entry}
                                    onSave={save}
                                    onRemove={remove}
                                    onEdit={() => {
                                        state.edit = true;
                                        state.tag = entry;
                                    }}
                                    readonly={readonly}
                                />
                            </List.Item>
                        );
                    })}
                </List>
            </Group>

            {state.edit && (
                <Form tag={state.tag} existing={list} onSave={onSave} onCancel={onCancel} />
            )}
        </>
    );
});

export default TagGroup;
