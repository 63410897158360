import React from "react";
import { observer } from "mobx-react";
import { Radio } from "antd";
import classNames from "classnames";
import { MarkdownViewer } from "@app/components/markdown-editor";

import "./style/question.scoped.scss";

const Question = observer(({ question, answers, onChange, readonly = false }) => {
    let selected = question.answer?._id;

    /**
     * Handle the answer event
     */
    const answer = (event) => {
        const id = event.target.value;
        const answer = answers.find((el) => el._id === id);

        // update the selected value
        selected = id;

        question.answer = answer;
        onChange && onChange(question);
    };

    return (
        <div className={classNames("answer", { [question.color]: question.color })}>
            <div className="question">
                <div className="text">{<MarkdownViewer content={question.question} />}</div>
                <div className="contribution">{question.contribution}</div>
            </div>

            <div className="answers">
                <Radio.Group onChange={answer} value={selected}>
                    {answers.map((answer) => {
                        return (
                            <Radio
                                value={answer._id}
                                className="answer"
                                key={answer._id}
                                disabled={readonly}
                            >
                                <b>{answer.grade}:</b> {answer.answer}
                            </Radio>
                        );
                    })}
                </Radio.Group>
            </div>
        </div>
    );
});

export default Question;
