import React from "react";
import { observer } from "mobx-react";
import Tag from "@app/components/setup/tag/card";

import "./style/tags.scoped.scss";

const Tags = observer(({ data }) => {
    return (
        <div className="tag">
            <Tag value={data} readonly={true} />
        </div>
    );
});

export default Tags;
