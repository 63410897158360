import { Extension } from "@tiptap/core";
import { localStore } from "@app/lib/storage";

const MAX_ZOOM = 2.5;
const MIN_ZOOM = 0.5;
const ZOOM_STEP = 0.25;

export default Extension.create({
    name: "zoom",

    onBeforeCreate() {
        const key = this.options.key || "default";
        const storeKey = `editor-zoom-${key}`;
        const zoomLevel = localStore.get(storeKey, 1);

        this.storage.zoomLevel = zoomLevel;
        this.storage.storeKey = storeKey;
    },

    addStorage() {
        return {
            zoomLevel: 1,
            storeKey: undefined,
        };
    },

    addCommands() {
        return {
            setZoom: (zoomLevel) => ({ editor }) => {
                this.storage.zoomLevel = zoomLevel;
                localStore.set(this.storage.storeKey, zoomLevel);
                editor.emit("zoom", zoomLevel);
            },

            zoomIn: () => ({ commands }) => {
                if (this.storage.zoomLevel < MAX_ZOOM) {
                    const zoomLevel = this.storage.zoomLevel + ZOOM_STEP;
                    commands.setZoom(zoomLevel);
                }
            },

            zoomOut: () => ({ commands }) => {
                if (this.storage.zoomLevel > MIN_ZOOM) {
                    const zoomLevel = this.storage.zoomLevel - ZOOM_STEP;
                    commands.setZoom(zoomLevel);
                }
            },
        };
    },
});
