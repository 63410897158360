import React from "react";
import { Button } from "antd";
import classNames from "classnames";

import "./styles/toggle-button.scoped.scss";

const ToggleButton = ({ icon, value, disabled, className, onChange }) => {
    return (
        <Button
            type="icon"
            icon={icon}
            onClick={onChange}
            disabled={disabled}
            className={classNames("toggle", className, {
                active: value,
                inactive: !value,
            })}
        />
    );
};

export default ToggleButton;
