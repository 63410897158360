import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";
import { inLandscape } from "@app/components/report-document/editor/utils";

function handlePasteText(value) {
    value = sanitize(value);
    return value;
}

function handlePasteHtml(value, editor) {
    value = sanitize(value);
    value = sanitizeImage(value, editor);
    value = sanitizeTable(value, editor);

    return value;
}

function sanitize(value) {
    /* eslint-disable no-control-regex */
    value = value.replace(/\u0002/gi, "-");
    value = value.replace(/[\u0001-\u001f]/gi, (char) => {
        // Preserve newline characters
        if (char === "\n" || char === "\r") {
            return char;
        }
        return " ";
    });
    value = value.replace(/[\u0080-\u009F]/gi, " ");

    return value;
}

function sanitizeImage(html, editor) {
    if (html.includes("<img")) {
        const cursor = editor.state.tr.selection.$cursor;
        const parentDepth = inLandscape(editor) ? 2 : 1;
        const notRootLevel = cursor?.depth > parentDepth;

        if (notRootLevel) {
            // retrieve the parent node pos and create a paragraph after it
            const parentNode = cursor.node(parentDepth);
            const nodePos = editor.commands.getNodePos(parentNode);
            if (nodePos) {
                editor
                    .chain()
                    .focus()
                    .insertContentAt(nodePos.to, "</p>")
                    .setTextSelection(nodePos.to)
                    .run();
            }
        }
    }

    return html;
}

function sanitizeTable(html, editor) {
    if (html.includes("</tr>")) {
        const cursor = editor.state.tr.selection.$cursor;
        const parentDepth = inLandscape(editor) ? 2 : 1;
        const notRootLevel = cursor?.depth > parentDepth;

        if (notRootLevel) {
            // retrieve the parent node pos and create a paragraph after it
            const parentNode = cursor.node(parentDepth);
            const nodePos = editor.commands.getNodePos(parentNode);
            if (nodePos) {
                editor
                    .chain()
                    .focus()
                    .insertContentAt(nodePos.to, "</p>")
                    .setTextSelection(nodePos.to)
                    .run();
            }
        }

        // // Convert valign="value" to style="vertical-align: value"
        // html = html.replace(/valign="(top|middle|bottom)"/gi, (match, p1) => {
        //     return `style="vertical-align: ${p1 || "middle"}"`;
        // });

        const wrapper = document.createElement("div");
        wrapper.innerHTML = html;

        const pasteFromWord = html.match(/WordDocument/);
        const tds = wrapper.querySelectorAll("td");

        for (const td of tds) {
            if (td.getAttribute("valign")) {
                td.style.verticalAlign = td.getAttribute("valign");
            } else if (pasteFromWord) {
                const styles = window.getComputedStyle(td);
                if (!styles.verticalAlign) {
                    td.style.verticalAlign = "middle";
                }
            }
        }

        const tables = wrapper.querySelectorAll("table");
        for (const table of tables) {
            const tbody = table.querySelector("tbody");
            const rows = Array.from(tbody.childNodes).filter((node) => node.nodeType === 1); // Leave only element nodes

            // remove empty rows
            for (let i = 0; i < rows.length; i++) {
                const row = rows[i];
                const next = rows[i + 1];

                // reset the rowspan if the next row is empty
                if (!next || !next.childNodes.length) {
                    const cells = row.querySelectorAll("td");
                    for (const cell of cells) {
                        cell.removeAttribute("rowspan");
                    }
                }

                if (!row.childNodes.length) {
                    tbody.removeChild(row);
                }
            }
        }

        html = wrapper.innerHTML;

        const hasContentBeyondATable =
            html
                .replace(/<!--.*?-->/g, "") // remove comments
                .replace(/<table.*?>.*?<\/table>/g, "") // remove tables
                .replace(/<[^>]*>/g, "") // remove tags
                .trim().length > 0; // check if there is any content

        if (!hasContentBeyondATable) {
            // replace the slice with a correct node type
            html = html.replace(/data-pm-slice="(\d+) \d+ \[\]"/g, 'data-pm-slice="1 1 []"');
        }
    }

    return html;
}

export default Extension.create({
    name: "pasteExtension",

    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: new PluginKey("transformPasted"),
                props: {
                    transformPastedText: handlePasteText,
                    transformPastedHTML: (value) => handlePasteHtml(value, this.editor),
                },
            }),
        ];
    },
});
