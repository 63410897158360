import React from "react";
import { observer, useLocalStore } from "mobx-react";
import { Link } from "react-router-dom";

import { Form, Button, Input, Alert } from "antd";
import { UserOutlined } from "@ant-design/icons";

import "./style/auth.scoped.scss";

import session from "@app/state/store/session";

const Password = observer(() => {
    const [form] = Form.useForm();
    var state = useLocalStore(() => ({
        error: false,
        success: false,
        loading: false,
    }));

    /**
     * Perform the authentication
     * @param {Object} args
     */
    const reset = async (args) => {
        state.loading = true;
        let success = await session.passwd(args);
        state.loading = false;

        if (success === null) {
            state.error = false;
            state.success = false;
        } else if (success) {
            state.error = false;
            state.success = true;
        } else {
            state.error = true;
            state.success = false;
        }
    };

    /**
     * Hide the error message
     */
    const hideError = () => {
        state.error = false;
    };

    /**
     * Render the feedback alert widget
     */
    const Feedback = observer(() => {
        if (state.error) {
            return (
                <Alert
                    message="Error"
                    description="This email does not exist in our system"
                    type="error"
                    showIcon
                />
            );
        } else if (state.success) {
            return (
                <Alert
                    message="Success"
                    description="Check your email and use the link to reset your password."
                    type="success"
                    showIcon
                />
            );
        } else {
            return null;
        }
    });

    // render the page
    return (
        <div className="password auth">
            <div className="logo">
                <img src={require("@app/assets/logo-name.svg")} />
            </div>

            <div className="alert">
                <Feedback />
            </div>

            <Form layout="vertical" form={form} onFinish={reset} onFieldsChange={hideError}>
                <div className="form-title">Password Reset</div>

                <div className="mb-20">
                    Enter the email associated with your account and we will send you a link to help
                    you reset your password.
                </div>

                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: "Please enter your email",
                        },
                        {
                            type: "email",
                            message: "Invalid email address",
                        },
                    ]}
                >
                    <Input autoComplete="username" prefix={<UserOutlined />} />
                </Form.Item>

                {state.success ? null : (
                    <Form.Item className="pt-10">
                        <Button
                            type="primary"
                            size="large"
                            disabled={state.success}
                            loading={state.loading}
                            htmlType="submit"
                        >
                            Send Email
                        </Button>
                    </Form.Item>
                )}

                <div className="mt-10">
                    <Link to="./login">Back to the login page</Link>
                </div>
            </Form>
        </div>
    );
});

export default Password;
