import { computed, action } from "mobx";
import http from "@app/lib/http";
import BaseStore from "./base";
import OverviewModel from "@app/state/model/overview";
import report from "@app/state/store/report";
import { events } from "@app/lib/store";
import notify from "@app/components/notify/index";

export class Overview extends BaseStore {
    constructor() {
        super();

        events.on("project.unload", () => this.reset());
    }
    observable() {
        return {
            loading: false,
            data: null,
        };
    }

    @computed get busy() {
        return this.loading;
    }

    @computed get project() {
        return report.id;
    }

    @action
    async stats() {
        try {
            this.loading = true;

            if (!this.project) {
                return;
            }

            let { data } = await http.get(`/project/${this.project}/stats`);

            if (data) {
                this.data = new OverviewModel(data);
            }
        } catch (error) {
            notify.error(error.response?.data?.error);
        } finally {
            this.loading = false;
        }
    }
}

export default new Overview();
