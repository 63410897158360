import { useCallback, useEffect, useRef, useState } from "react";

const useSelection = () => {
    const [selectedText, setSelectedText] = useState("");
    const timeoutRef = useRef(null);

    const handleSelectionChange = useCallback(() => {
        const selection = document.getSelection();
        const selectedString = selection ? selection.toString() : "";
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            setSelectedText(selectedString);
        }, 100);
    }, [timeoutRef, selectedText]);

    useEffect(() => {
        document.addEventListener("selectionchange", handleSelectionChange);

        return () => {
            document.removeEventListener("selectionchange", handleSelectionChange);
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    return selectedText;
};

export default useSelection;
