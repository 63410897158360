import { Mark } from "@tiptap/core";

import cn from "classnames";
import { ReportDocumentSection } from "@app/constants";

import commentStore from "@app/state/store/report-document/comment";

import { serializeSelectionToPlainText } from "../../utils";

import { HighlightPlugin, highlightKey } from "./highlight-plugin";
import { calculateModalPosition } from "./calculate-modal-position";

export default Mark.create({
    name: ReportDocumentSection.COMMENT.MARK_NAME,
    inline: true,
    inclusive: false,
    inclusiveRight: false,

    addAttributes() {
        return {
            comment: {
                default: undefined,
            },
            focused: {
                default: false,
            },
            resolved: {
                default: false,
            },
            class: {
                default: undefined,
                renderHTML: (attrs) => {
                    const { focused, resolved } = attrs;
                    let visible = true;

                    if (commentStore.tab === "open") {
                        visible = !resolved;
                    } else if (commentStore.tab === "resolved") {
                        visible = resolved;
                    }

                    return {
                        class: cn({
                            "comment-mark": true,
                            hidden: !visible,
                            focused: focused,
                            resolved: resolved,
                            pending: !resolved,
                        }),
                    };
                },
            },
        };
    },

    renderHTML({ HTMLAttributes }) {
        return ["span", HTMLAttributes, 0];
    },

    addCommands() {
        return {
            createComment: () => ({ editor, dispatch, tr }) => {
                const { from, to, empty } = editor.state.selection;
                const node = tr.curSelection?.node;

                if (empty) {
                    return false;
                }

                if ("report" === node?.type?.name) {
                    return false;
                }

                if (dispatch) {
                    const selectedText = serializeSelectionToPlainText(editor, from, to);
                    const { sectionId } = editor;

                    const startPos = editor.view.coordsAtPos(from);
                    const endPos = editor.view.coordsAtPos(to);

                    editor.view.dispatch(editor.view.state.tr.setMeta(highlightKey, { add: true }));

                    const modalPosition = calculateModalPosition(
                        startPos,
                        endPos,
                        520,
                        242,
                        "div.section-content",
                    );

                    const onModalClose = () => {
                        try {
                            editor.view.dispatch(
                                editor.view.state.tr.setMeta(highlightKey, { clear: true }),
                            );
                        } catch (e) {
                            console.error("Error clearing highlight", e); // should not happen normally, sometimes on hot reload
                        }
                    };

                    commentStore.openAddCommentModal({
                        sectionId,
                        selectedText,
                        from,
                        to,
                        isSectionComment: false,
                        onModalClose,
                        modalPosition,
                    });
                }

                return true;
            },
        };
    },
    addProseMirrorPlugins() {
        return [
            HighlightPlugin({
                editor: this.editor,
            }),
        ];
    },
});
