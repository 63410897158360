import React from "react";
import { observable, computed } from "mobx";

export class PageState {
    // a flag to re-render the page
    @observable _render = 0;
    @observable sidebars = 0;

    @computed get hasSidebar() {
        return this.sidebars > 0;
    }

    /**
     * Add a sidebar to the page
     */
    addSidebar() {
        this.sidebars++;
    }

    /**
     * Add a sidebar to the page
     */
    removeSidebar() {
        this.sidebars--;
    }

    render() {
        this._render++;
    }
}

/**
 * The page context definition
 */
export const Context = React.createContext(null);

/**
 * Create a page state
 */
export function createState() {
    // create the page state and return it from the function.
    // the value is memoised so only a single state will be created
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const state = React.useMemo(() => {
        return new PageState();
    }, []);

    // add a dummy use effects so the components is re-rendered
    // when the state._render value changes
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {}, [state._render]);

    return state;
}

/**
 * A convenient hook to use the page context and check if it
 * is available in the place of usage
 */
export function usePage() {
    const context = React.useContext(Context);
    if (context === null) {
        throw new Error("usePage must be used within a Page");
    }

    return context;
}
