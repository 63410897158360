import React, { useEffect } from "react";
import { observer, useLocalStore } from "mobx-react";
import { Pagination } from "antd";

const Pager = observer(({ filter }) => {
    var state = useLocalStore(() => ({
        page: 1,
        total: 1,
        rows: 40,
    }));

    // update the search value when changed in the filter state
    useEffect(() => {
        state.page = filter.state.page;
        state.total = filter.state.stats.count || 1;
        state.rows = filter.state.rows;
    }, [state, filter.state.page, filter.state.stats.count, filter.state.rows]);

    // render empty widget if there are no results
    if (filter.state.to === 0) {
        return null;
    }

    /**
     * Update the page
     */
    const onChange = (page) => {
        filter.updatePage(page);
    };

    return (
        <div className="pager">
            <Pagination
                current={state.page}
                pageSize={state.rows}
                showSizeChanger={false}
                onChange={onChange}
                total={state.total}
            />
        </div>
    );
});

export default Pager;
