import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { NavLink } from "react-router-dom";
import { Button, Tag } from "antd";
import { CloudDownloadOutlined, SettingOutlined } from "@ant-design/icons";
import { Box } from "@app/components/box";
import { Copy } from "@app/components/clipboard";
import { Page } from "@app/components/page";
import { triggerDownload } from "@app/lib/file";
import SyncWarning from "../sync-warning";
import format from "@app/lib/format";

import "../style/common.scoped.scss";
import "../style/report-styles.scoped.scss";

import setup from "@app/state/store/projects/setup";
import session from "@app/state/store/session";
import adverseEvents from "@app/state/store/report/adverse-events";
import tplcState from "@app/state/store/report/output/tplc";
import reportState from "@app/state/store/report";

const TPLC = observer(() => {
    const hasSyncState = adverseEvents?.synchronizing !== undefined;
    const config = setup.config?.risk?.tplc || {};
    const manufacturerNames = config.manufacturerNames || [];
    const deviceNames = config.deviceNames || [];
    const hasManufacturer = manufacturerNames.length > 0;
    const hasBrands = deviceNames.length > 0;
    const hasReports = !tplcState.loading && tplcState.reports.length > 0;
    const readonly = !session.can("tplc.update") || reportState.readOnly;

    // Load the TPLC reports content
    useEffect(() => {
        adverseEvents.loadSyncState();

        (async () => {
            await setup.load(reportState?.project?._id);
            await tplcState.load();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const downloadReport = async ({ _id, productCode }) => {
        const reportContent = await tplcState.export(_id);
        triggerDownload(`TPLCReport-${productCode}-${_id}.xlsx`, reportContent);
    };

    const renderNoReports = () => {
        if (tplcState.loading) {
            return;
        }

        return (
            <Box className="display-style">
                <div className="text-center p-20">The report hasn't been configured yet.</div>
            </Box>
        );
    };

    const renderReportBody = ({ _id, deviceProblems }) => {
        return (
            <Box key={`tplcReport-${_id}`} className="display-style">
                <table>
                    <thead>
                        <tr>
                            <th>Device Problems</th>
                            <th>Category Total</th>
                            <th>Device Specific</th>
                        </tr>
                    </thead>
                    <tbody>
                        {deviceProblems.map((deviceProblem, index) => {
                            return (
                                <tr key={index}>
                                    <td>{deviceProblem.problem}</td>
                                    <td>{deviceProblem.totalEventCount}</td>
                                    <td>{deviceProblem.deviceEventCount}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </Box>
        );
    };

    const renderReports = () => {
        return tplcState.reports.map((report, index) => {
            const productCode = report.productCode;
            const reportBody = renderReportBody(report);

            return (
                <div className="section" key={index}>
                    <div className="header">
                        <span>
                            Summary of Device Problems Reported in TPLC for Product Code '
                            {productCode}'
                        </span>
                        <div className="actions">
                            <Copy component={reportBody} icon className="copy-style" />
                            <Button
                                type="icon"
                                icon={<CloudDownloadOutlined />}
                                onClick={() => downloadReport(report)}
                            />
                        </div>
                    </div>
                    <div className="body">{reportBody}</div>
                </div>
            );
        });
    };

    const Configuration = () => {
        return (
            <div className="section config">
                <div className="header">Report Configuration</div>
                <div className="body">
                    <Box>
                        {hasManufacturer !== false ? (
                            <div className="config-row manufacturers">
                                <label>Manufacturer:</label>
                                {manufacturerNames.map((name, index) => {
                                    return (
                                        <Tag className="tag" key={index}>
                                            {name}
                                        </Tag>
                                    );
                                })}
                            </div>
                        ) : null}

                        {hasBrands !== false ? (
                            <div className="config-row brands">
                                <label>Device:</label>
                                {deviceNames.map((name, index) => (
                                    <Tag className="tag" key={index}>
                                        {name}
                                    </Tag>
                                ))}
                            </div>
                        ) : null}

                        {config.cutOff > 1 && (
                            <div className="config-row">
                                <label>Results Cut-off:</label>
                                {config.cutOff}
                            </div>
                        )}

                        <div className="config-row">
                            <label>Date Range:</label>
                            {`${format.date(config.startDate)} to ${format.date(config.endDate)}`}
                        </div>

                        <div className="config-row">
                            <label>Generated on:</label>
                            {format.datetime(config.generated)}
                        </div>
                    </Box>
                </div>
            </div>
        );
    };

    return (
        <Page className="tplc report">
            <Page.Header title="TPLC">
                <Page.Header.Right>
                    {!readonly && (
                        <NavLink to="./tplc/setup">
                            <Button
                                type="primary"
                                icon={<SettingOutlined />}
                                disabled={tplcState.loading && hasSyncState}
                            >
                                Configure
                            </Button>
                        </NavLink>
                    )}
                </Page.Header.Right>
            </Page.Header>
            <Page.Layout>
                <Page.Body maxWidth={1200}>
                    {adverseEvents.synchronizing === true && <SyncWarning />}
                    {adverseEvents.synchronizing === false && (
                        <>
                            {hasReports ? <Configuration /> : null}
                            {hasReports ? renderReports() : renderNoReports()}
                        </>
                    )}
                </Page.Body>
            </Page.Layout>
        </Page>
    );
});

export default TPLC;
