import { notification } from "antd";
import "./notify.scss";

const PLACEMENT = "topRight";
const DURATION = 4;

var uuid = 1;

export default {
    show(message, args) {
        let key = `notify-${++uuid}`;
        let show = notification[args.type];

        show({
            description: message,
            placement: PLACEMENT,
            duration: DURATION,
            className: `notify ${args.type}`,
            key: key,
            onClick: () => {
                notification.close(key);
            },
        });
    },

    info(message, args = {}) {
        this.show(message, { type: "info" });
    },

    warn(message, args = {}) {
        this.show(message, { type: "warn" });
    },

    success(message, args = {}) {
        this.show(message, { type: "success" });
    },

    error(message, args = {}) {
        this.show(message, { type: "error" });
    },
};
