import _ from "lodash";
import { decodeCommentIds, encodeCommentIds } from "../comment/components/hooks/util";

export function extractTextContent({ tr, from, to }) {
    let content = [];
    let rangeFrom = from ? from : tr.curSelection.from;
    let rangeTo = to ? to : tr.curSelection.to;

    tr.doc.nodesBetween(rangeFrom, rangeTo, (node, pos, parent, i) => {
        if (node.type.name === "text") {
            let text = node.text;
            let textFrom, textTo;

            if (rangeFrom > pos) {
                textFrom = rangeFrom;
            } else {
                textFrom = pos;
            }

            const nodeEndPos = pos + node.nodeSize;
            if (nodeEndPos > rangeTo) {
                textTo = rangeTo;
            } else {
                textTo = nodeEndPos;
            }

            if (textFrom && textTo) {
                text = tr.doc.textBetween(textFrom, textTo);
            }

            content.push({ ...node.toJSON(), text });
        }
    });

    return content;
}

export function sanitizeContent(content = {}, { comments = [], abbreviations = [] }) {
    let newContent = _.cloneDeep(content);

    const commentIds = comments.filter((i) => !i.deleted).map((i) => i._id.toString());
    const abbreviationNames = abbreviations.filter((i) => !i.deleted).map((i) => i.name);

    const sanitizeNodeType = (node) => {
        let nodeContent = [node];

        switch (node.type) {
            case "abbreviation": {
                if (!abbreviationNames.includes(node.attrs.key)) {
                    const newNode = { ...node, type: "text", text: node.attrs.key };
                    delete newNode.attrs;
                    nodeContent = [newNode];
                }
                break;
            }
            default:
                break;
        }

        return nodeContent;
    };

    const sanitizeNodeMarks = (node) => {
        if (node.marks) {
            node.marks = node.marks.filter((mark) => {
                if (mark.type === "commentMark") {
                    const markCommentIds = decodeCommentIds(mark.attrs.comment);
                    const validCommentIds = markCommentIds.filter((id) => commentIds.includes(id));
                    if (validCommentIds.length === 0) {
                        return false; // remove mark
                    }

                    mark.attrs.comment = encodeCommentIds(validCommentIds);
                }
                return true; // retain mark
            });
        }
    };

    const sanitizeNodeAttributes = (node) => {
        if (node.attrs && node.attrs.comment) {
            const nodeCommentIds = decodeCommentIds(node.attrs.comment);
            const validCommentIds = nodeCommentIds.filter((id) => commentIds.includes(id));
            if (validCommentIds.length === 0) {
                delete node.attrs.comment;
            } else {
                node.attrs.comment = encodeCommentIds(validCommentIds);
            }
        }
    };

    const sanitizeNode = (node) => {
        if (node?.type === "paragraph") {
            node.content = node.content?.reduce((acc, node) => {
                sanitizeNodeMarks(node);
                sanitizeNodeAttributes(node);
                const nodeContent = sanitizeNodeType(node);
                acc = [...acc, ...nodeContent];

                return acc;
            }, []);
        } else {
            if (node?.content?.length > 0) {
                node.content.forEach(sanitizeNode);
            }
        }
    };

    sanitizeNode(newContent);

    return { newContent };
}

/**
 * Extract the text marks from the previous node
 */
export function getMarks({ editor }) {
    const selection = editor.view.state.selection;
    const nodeBefore = selection.$from.nodeBefore;

    const marks = nodeBefore?.marks.map((mark) => {
        return {
            type: mark.type.name,
            attrs: mark.attrs,
        };
    });

    return marks ?? [];
}

export function hasSpaceBefore(editor) {
    const selection = editor.view.state.selection;
    const nodeBefore = selection.$from.nodeBefore;

    return nodeBefore && nodeBefore.text?.endsWith(" ");
}

export default { extractTextContent, sanitizeContent, getMarks };
