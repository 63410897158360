import React from "react";
import { observer } from "mobx-react";
import { Button } from "antd";
import classNames from "classnames";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import "./style/small.scoped.scss";

const SmallPager = observer(({ className, filter, ...rest }) => {
    const state = filter.state;

    // render empty widget if there are no results
    if (state.to === 0) {
        return null;
    }

    /**
     * Update the state
     */
    const next = (event) => {
        filter.next();
    };

    /**
     * Trigger a search
     */
    const prev = (value) => {
        filter.prev();
    };

    const classes = classNames("count", className);
    return (
        <div className={classes} {...rest}>
            <Button.Group>
                <Button icon={<LeftOutlined />} disabled={state.page === 1} onClick={prev}></Button>
                <Button icon={<RightOutlined />} disabled={state.eol} onClick={next}></Button>
            </Button.Group>
        </div>
    );
});

export default SmallPager;
