import React from "react";
import { observer, useLocalStore } from "mobx-react";
import { v4 as uuid } from "uuid";

import configClipboard from "@app/state/store/config-clipboard";

import List from "../../list/ordered";
import CopyConfig from "../../config-clipboard/copy";
import PasteConfig from "../../config-clipboard/paste";
import Group from "../group";
import Keyword from "./card";
import Form from "./form";

const PositiveKeywords = observer(({ list, readonly = false, onChange }) => {
    const state = useLocalStore(() => ({
        edit: false,
        entry: undefined,
    }));

    /**
     * Handle the remove event and save the list
     */
    const remove = (entry) => {
        const index = list.findIndex((el) => {
            return el._id === entry._id;
        });

        if (index !== -1) {
            list.splice(index, 1);
            save();
        }
    };

    /**
     * Handle the edit canceled event and hide the form
     */
    const onCancel = () => {
        state.edit = false;
        state.entry = undefined;
    };

    /**
     * Handle the save event from an edit or an add operation
     */
    const onSave = (entry) => {
        state.edit = false;
        state.entry = undefined;

        // add operation
        if (!entry._id) {
            list.push(entry);
        }

        save();
    };

    /**
     * Do the actual configuration update
     */
    const save = () => {
        list = list
            .sort((a, b) => {
                return a.label <= b.label;
            })
            .sort((a, b) => {
                return a.positive <= b.positive;
            });

        onChange && onChange(list);
    };

    const copy = () => {
        configClipboard.copy("keywords", list);
    };

    const paste = () => {
        list.push(
            ...configClipboard.paste("keywords", (entry) =>
                list.find((li) => li.label === entry.label),
            ),
        );
        save();
    };

    return (
        <>
            <Group
                title="Highlight Keywords"
                className="keywords"
                onAdd={
                    readonly
                        ? undefined
                        : () => {
                              state.edit = true;
                              state.entry = undefined;
                          }
                }
                onCopyButton={
                    <CopyConfig onClick={copy} tooltip={"Copy Keywords"} disabled={!list.length} />
                }
                onPasteButton={
                    !readonly && configClipboard.keywords.length ? (
                        <PasteConfig onClick={paste} tooltip={"Paste Keywords"} />
                    ) : null
                }
            >
                <List>
                    {list.map((entry, index) => {
                        const id = entry._id || "new_" + uuid();

                        return (
                            <Keyword
                                key={id}
                                value={entry}
                                onSave={save}
                                onRemove={remove}
                                onEdit={() => {
                                    state.edit = true;
                                    state.entry = entry;
                                }}
                                readonly={readonly}
                            />
                        );
                    })}
                </List>
            </Group>

            {state.edit && (
                <Form value={state.entry} existing={list} onSave={onSave} onCancel={onCancel} />
            )}
        </>
    );
});

export default PositiveKeywords;
