import { Extension } from "@tiptap/core";
import { Plugin } from "prosemirror-state";
import { selectedRect } from "./utils/merge-cells";

export const PreserveMergeOnPaste = Extension.create({
    name: "preserveMergeOnPaste",

    addProseMirrorPlugins() {
        return [
            new Plugin({
                props: {
                    handlePaste(view, event, slice) {
                        const { state, dispatch } = view;
                        const { selection, tr } = state;
                        const table = findTable(selection);

                        if (table) {
                            const rect = selectedRect(state);

                            const isPastingIntoMergedCell = checkIfAllCellsAreMerged(
                                rect,
                                rect.map,
                            );

                            if (isPastingIntoMergedCell) {
                                const mergedContent = extractNodesFromTableCell(slice);

                                if (!mergedContent.length) {
                                    return false;
                                }

                                tr.replaceWith(
                                    selection.$from.start(),
                                    selection.$from.end(),
                                    mergedContent,
                                );

                                dispatch(tr);
                                return true;
                            }
                        }
                    },
                },
            }),
        ];
    },
});

function findTable(selection) {
    for (let i = 0; i < selection.$from.depth; i++) {
        const node = selection.$from.node(i);

        if (node?.type.name === "table") {
            return node;
        }
    }
}

function checkIfAllCellsAreMerged(rect, map) {
    const { top, left, right, bottom } = rect;
    const cells = [];
    for (let row = top; row < bottom; row++) {
        for (let col = left; col < right; col++) {
            cells.push(map.map[row * map.width + col]);
        }
    }
    return cells.every((cell) => cell === cells[0]);
}

function extractNodesFromTableCell(node) {
    let nodes = [];

    if (node.type?.name === "tableCell") {
        node.content.content.forEach((childNode) => {
            nodes = nodes.concat(childNode);
        });
    } else if (node.content.content) {
        node.content.content.forEach((childNode) => {
            nodes = nodes.concat(extractNodesFromTableCell(childNode));
        });
    }

    return nodes;
}
