import { reaction } from "mobx";
import _ from "lodash";
import { subscribe, unsubscribe } from "../../../../../util/events";

import documentStore from "@app/state/store/report-document/document";
import { parseCaptionText } from "@app/components/report-document/editor/modules/diff/diff-utility";

export class TableView {
    constructor({ editor, node, view, getPos, cellMinWidth, tables, isEditable }) {
        this.editor = editor;
        this.node = node;
        this.id = node.attrs.id;
        this.cellMinWidth = cellMinWidth;
        this.dom = document.createElement("div");
        this.dom.className = "tableWrapper";
        this.index = tables.findIndex((table) => table.id === node.attrs.id);
        this.isEditable = isEditable;
        this.table = this.dom.appendChild(document.createElement("table"));
        this.colgroup = this.table.appendChild(document.createElement("colgroup"));
        this.caption = this.table.appendChild(document.createElement("caption"));
        this.contentDOM = this.table.appendChild(document.createElement("tbody"));
        this.dom.setAttribute("data-table-id", node.attrs.id);

        const change = node.attrs["data-diff-node"];
        const diffId = node.attrs["data-diff-id"];

        if (change) {
            this.dom.classList.add(`diff-${change}`);
            this.dom.setAttribute("data-diff-id", diffId);
        }

        // check the parent to determine the landscape/portrait mode
        const pos = getPos();
        const slice = editor.state.doc.slice(pos, pos + 1, true);
        const parent = slice.content.firstChild;
        if (parent.type.name === "landscape") {
            this.table.className = "landscape";
        }

        // check if table is in a list
        if (
            parent.firstChild?.type?.name === "listItem" ||
            parent.firstChild?.firstChild?.type?.name === "listItem"
        ) {
            this.dom.classList.add("invalid");
        }

        this.onScrollToTable = (e) => {
            const tableId = e.detail;

            if (tableId === node.attrs.id) {
                this.dom.scrollIntoView({ behavior: "smooth" });
            }
        };

        this.updateSection = () => {
            if (editor.sectionId) {
                const content = editor.getJSON();
                documentStore.processSection(editor.sectionId, content);
            }
        };

        // listen for store table change to update the caption
        this.reactionCleanup = reaction(
            () => documentStore.tables,
            () => updateTableCaption(this),
            { delay: 200 },
        );

        subscribe("scrollToNode", this.onScrollToTable);

        updateTableCaption(this);
        updateColumns(node, this.colgroup, this.table, cellMinWidth);
    }

    update(node) {
        if (node.type !== this.node.type) {
            return false;
        }

        if (node.attrs.caption !== this.node.attrs.caption) {
            documentStore.updateTableCaption(this.id, node.attrs.caption);
            this.updateSection();
        }

        this.node = node;

        updateTableCaption(this);
        updateColumns(node, this.colgroup, this.table, this.cellMinWidth);
        return true;
    }

    ignoreMutation(record) {
        return (
            record.type === "attributes" &&
            (record.target === this.table || this.colgroup.contains(record.target))
        );
    }

    destroy() {
        unsubscribe("scrollToNode", this.onScrollToTable);
        this.reactionCleanup();
    }
}

function updateTableCaption({ id, table, isEditable, caption, node }) {
    const captionData = documentStore.tableDetails(id) ?? {};

    if (isEditable) {
        caption.classList.add("editable");
    }

    if (!_.isEmpty(node.attrs.caption) && captionData.fullCaption) {
        caption.innerHTML = parseCaptionText(captionData.fullCaption);
    } else {
        caption.innerHTML = "";
    }
}

export function updateColumns(node, colgroup, table, cellMinWidth, overrideCol, overrideValue) {
    let nextDOM = colgroup.firstChild,
        row = node.firstChild,
        updatedColValType = null,
        updatedColVal = 0;

    if (!row) {
        return;
    }

    for (let i = 0, col = 0; i < row.childCount; i++) {
        let { colspan, colwidth } = row.child(i).attrs;
        for (let j = 0; j < colspan; j++, col++) {
            let hasWidth = overrideCol === col ? overrideValue : colwidth && colwidth[j];
            let _colwidth = colwidth ? colwidth[j] : cellMinWidth;

            if (overrideCol === col) {
                if (_colwidth >= hasWidth) {
                    updatedColValType = "ADD";
                    updatedColVal = _colwidth - hasWidth;
                } else {
                    updatedColValType = "DELETE";
                    updatedColVal = hasWidth - _colwidth;
                }
            }

            if (overrideCol + 1 === col) {
                if (updatedColValType === "ADD") {
                    hasWidth += updatedColVal;
                } else {
                    hasWidth -= updatedColVal;
                }
            }

            let cssWidth = hasWidth ? hasWidth + "px" : "";

            if (!nextDOM) {
                colgroup.appendChild(document.createElement("col")).style.width = cssWidth;
            } else {
                if (cssWidth.length && nextDOM.style.width !== cssWidth) {
                    nextDOM.style.width = cssWidth;
                }
                nextDOM = nextDOM.nextSibling;
            }
        }
    }

    while (nextDOM) {
        let after = nextDOM.nextSibling;
        nextDOM.parentNode.removeChild(nextDOM);
        nextDOM = after;
    }

    // table.style.width = 650 + "px";
    // table.style.minWidth = "650px";
}
