import React from "react";
import { observer } from "mobx-react";
import { Form, Button, Input } from "antd";

import session from "@app/state/store/session";

const ResetForm = observer(({ state, token }) => {
    const [form] = Form.useForm();

    /**
     * Perform the authentication
     * @param {Object} args
     */
    const reset = async (args) => {
        args.token = token;

        state.loading = true;
        let success = await session.updatePassword(args);
        state.loading = false;

        if (success) {
            state.error = false;
            state.success = true;
        } else {
            state.error = true;
            state.success = false;
        }
    };

    // render the page
    return (
        <Form layout="vertical" form={form} onFinish={reset}>
            <div className="mb-20">
                Please enter the new password to be used when you login in the platform.
            </div>

            <Form.Item
                label="New Password"
                name="password"
                rules={[
                    {
                        required: true,
                        message: "Please enter your new password",
                    },
                ]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item
                label="Confirm Password"
                name="repeated"
                rules={[
                    {
                        required: true,
                        message: "Please confirm your password",
                    },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (!value || getFieldValue("password") === value) {
                                return Promise.resolve();
                            }

                            return Promise.reject(
                                "The two passwords that you entered do not match!",
                            );
                        },
                    }),
                ]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item className="pt-10">
                <Button type="primary" size="large" loading={state.loading} htmlType="submit">
                    Reset Password
                </Button>
            </Form.Item>
        </Form>
    );
});

export default ResetForm;
