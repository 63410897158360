export default [
    { name: "Alpha", value: "Α" },
    { name: "alpha", value: "α" },
    { name: "Beta", value: "Β" },
    { name: "beta", value: "β" },
    { name: "Gamma", value: "Γ" },
    { name: "gamma", value: "γ" },
    { name: "Delta", value: "Δ" },
    { name: "delta", value: "δ" },
    { name: "Epsilon", value: "Ε" },
    { name: "epsilon", value: "ε" },
    { name: "Zeta", value: "Ζ" },
    { name: "zeta", value: "ζ" },
    { name: "Eta", value: "Η" },
    { name: "eta", value: "η" },
    { name: "Theta", value: "Θ" },
    { name: "theta", value: "θ" },
    { name: "Iota", value: "Ι" },
    { name: "iota", value: "ι" },
    { name: "Kappa", value: "Κ" },
    { name: "kappa", value: "κ" },
    { name: "Lambda", value: "Λ" },
    { name: "lambda", value: "λ" },
    { name: "Mu", value: "Μ" },
    { name: "mu", value: "μ" },
    { name: "Nu", value: "Ν" },
    { name: "nu", value: "ν" },
    { name: "Xi", value: "Ξ" },
    { name: "xi", value: "ξ" },
    { name: "Omicron", value: "Ο" },
    { name: "omicron", value: "ο" },
    { name: "Pi", value: "Π" },
    { name: "pi", value: "π" },
    { name: "Rho", value: "Ρ" },
    { name: "rho", value: "ρ" },
    { name: "Sigma", value: "Σ" },
    { name: "sigma", value: "σ" },
    { name: "Tau", value: "Τ" },
    { name: "tau", value: "τ" },
    { name: "Upsilon", value: "Υ" },
    { name: "upsilon", value: "υ" },
    { name: "Phi", value: "Φ" },
    { name: "phi", value: "φ" },
    { name: "Chi", value: "Χ" },
    { name: "chi", value: "χ" },
    { name: "Psi", value: "Ψ" },
    { name: "psi", value: "ψ" },
    { name: "Omega", value: "Ω" },
    { name: "omega", value: "ω" },
    { name: "copyright", value: "©" },
    { name: "trademark", value: "™" },
    { name: "Registered", value: "®" },
    { name: "not equal to", value: "≠" },
    { name: "greater than or equal to", value: "≥" },
    { name: "less than or equal to", value: "≤" },
    { name: "approximately equal", value: "≈" },
    { name: "plus - minus", value: "±" },
    { name: "division sign", value: "÷" },
    { name: "degree", value: "°" },
    { name: "checked checkbox", value: "☑" },
    { name: "empty checkbox", value: "☐" },
];
