import * as Diff from "text-diff";
import { DOMSerializer } from "prosemirror-model";
import { v4 as uuid } from "uuid";

export const emptyContent = {
    type: "doc",
    content: [],
};

const IMAGE_WRAPPER_TAG = "placeholder";

export const parseContent = (node) => {
    if (Array.isArray(node)) {
        return node.map(parseContent).flat();
    }

    if (node.marks) {
        const marks = node.marks.filter(
            (mark) => mark.type !== "commentMark" && mark.type !== "captis-link",
        );
        if (marks.length !== node.marks.length) {
            return parseContent({ ...node, marks: marks });
        }
    }

    if (node.type === "comment" || node.type === "captis-link") {
        return node.content?.map(parseContent)?.flat() || [];
    }

    if (node.type === "abbreviation") {
        return { type: "text", text: node.attrs.key };
    }

    if (node.type === "dictionary") {
        return node;
    }

    if (!node.content) {
        return node;
    }

    return { ...node, content: parseContent(node.content) };
};

export const searchNodes = ["table", "tableRow", "tableCell", "tableHeader", "paragraph"];

export function getTableCaptionMap(content = [], map) {
    if (!Array.isArray(content)) {
        return;
    }

    content.forEach((node) => {
        if (node.type === "table" || node.type === "image") {
            map.set(node.attrs.id, node.attrs?.caption);
        }

        if (searchNodes.includes(node.type) && node.content) {
            getTableCaptionMap(node.content, map);
        }
    });
}

export const prettyHtml = (diffs) => {
    let html = [];
    for (let x = 0; x < diffs.length; x++) {
        const op = diffs[x][0]; // Operation (insert, delete, equal)
        const text = diffs[x][1]; // Text of change.

        switch (op) {
            case 1:
                html[x] = "[ins]" + text + "[/ins]";
                break;
            case -1:
                html[x] = "[del]" + text + "[/del]";
                break;
            case 0:
                html[x] = text;
                break;
            default:
                break;
        }
    }
    return html.join("");
};

// currently limited to one level tables (no nested tables)
export const compareAndUpdateTableCaptions = (content, lhsTableMap) => {
    const diff = new Diff();

    return content.map((node) => {
        if (node.type === "table" || node.type === "image") {
            const prevTableCaption = lhsTableMap.get(node.attrs.id);
            const newNode = { ...node, attrs: { ...node.attrs } };

            if (prevTableCaption !== node.attrs.caption) {
                const textDiff = diff.main(prevTableCaption || "", node.attrs.caption || "", false);
                diff.cleanupSemantic(textDiff);
                newNode.attrs.caption = prettyHtml(textDiff);
            }

            return newNode;
        } else {
            return node;
        }
    });
};

export function getVersionedTableCaptions(lhs, rhs) {
    const lhsTableCaptionMap = new Map();
    getTableCaptionMap(lhs.content, lhsTableCaptionMap);

    return { ...rhs, content: compareAndUpdateTableCaptions(rhs.content, lhsTableCaptionMap) };
}

export const removeCommentReferences = (input) => {
    const newObject = {};

    Object.keys(input).forEach((key) => {
        newObject[key] = input[key]
            ? {
                  ...input[key],
                  ...(input[key]?.content && { content: parseContent(input[key].content) }),
              }
            : emptyContent;
    });

    return newObject;
};

export const getHtmlString = (schema, content) => {
    const target = document.createElement("div");
    const serializer = DOMSerializer.fromSchema(schema);
    const outputHtml = serializer.serializeFragment(content);

    // Convert the HTML element to an HTML string
    target.appendChild(outputHtml);

    return target.innerHTML;
};

const assignIdsToTags = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    const elements = [...doc.querySelectorAll("ins, del, table[data-diff-node]")];

    elements.forEach((element) => {
        element.setAttribute("data-diff-id", uuid());
    });

    return doc.body.innerHTML;
};

export function updateWrappedTags(htmlString) {
    const htmlWithIds = assignIdsToTags(htmlString);
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlWithIds, "text/html");
    const tableList = [];

    const elements = [
        ...doc.querySelectorAll(
            "ins img, del img, table, span[data-cross-reference], span[data-image-node], span[data-citation], span[data-type='dictionary']",
        ),
    ];

    elements.forEach((element) => {
        const parent = element.parentNode;

        let change;

        if (element.hasAttribute("data-diff-node")) {
            change = element.getAttribute("data-diff-node");
        } else if (parent.tagName === "INS") {
            change = "ins";
        } else if (parent.tagName === "DEL") {
            change = "del";
        }

        if (!change) {
            return;
        }

        const newElement = element.cloneNode(true);
        const id = uuid();
        newElement.setAttribute("data-diff-node", change);
        newElement.setAttribute("data-diff-id", id);

        if (newElement.tagName === "TABLE") {
            const tableId = element.getAttribute("id");
            tableList.push({ tableId, diffId: id, change });
        }

        parent.replaceChild(newElement, element);
    });

    return { html: doc.body.innerHTML, tableList };
}

export const parseCaptionText = (caption) => {
    if (typeof caption !== "string") {
        return caption;
    }

    return caption ? caption.replace(/\[(\/?)(ins|del)]/g, "<$1$2>") : "";
};

export const removeInsDelMarkers = (string) => {
    if (typeof string !== "string") {
        return string;
    }

    return string ? string.replace(/\[(ins|del)](.*?)\[\/(ins|del)]/g, "$2") : "";
};

export const preProcessImageTags = (htmlString, imageMap, isOld) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const images = doc.getElementsByTagName("img");

    for (let i = images.length - 1; i >= 0; i--) {
        const img = images[i];
        const id = img.getAttribute("id");
        const caption = img
            .getAttribute("caption")
            .replace(/\[ins\]|\[\/ins\]|\[del\]|\[\/del\]/g, "");
        const attrs = {};

        for (let attr of img.attributes) {
            attrs[attr.name] = attr.value;
        }

        if (isOld) {
            attrs.oldCaption = caption;
        } else {
            const { oldCaption } = imageMap.get(id) || {};
            if (oldCaption) {
                attrs.oldCaption = oldCaption;
            }
            attrs.newCaption = caption;
        }

        imageMap.set(id, attrs);

        const p = doc.createElement(IMAGE_WRAPPER_TAG);
        const newImg = doc.createElement("img");

        newImg.setAttribute("id", id);

        p.appendChild(newImg);

        img.parentNode.replaceChild(p, img);
    }

    return {
        htmlString: doc.body.innerHTML,
    };
};

const findImageWrapper = (element) => {
    let currentElement = element;

    while (currentElement.tagName.toLowerCase() !== IMAGE_WRAPPER_TAG) {
        currentElement = currentElement.parentNode;
    }

    return currentElement;
};

export const postProcessTags = (htmlString, imageMap) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const images = doc.querySelectorAll("img");
    const diff = new Diff();

    for (let i = images.length - 1; i >= 0; i--) {
        const img = images[i];
        const id = img.getAttribute("id");
        const parent = img.parentNode;

        const attrs = imageMap.get(id);
        const oldCaption = attrs.oldCaption || "";
        const newCaption = attrs.newCaption || "";

        const textDiff = diff.main(oldCaption, newCaption, false);
        diff.cleanupSemantic(textDiff);
        const caption = prettyHtml(textDiff);

        let change;

        const parentTagName = parent.tagName.toLowerCase();

        if (parentTagName === "ins") {
            change = "ins";
        } else if (parentTagName === "del") {
            change = "del";
        }

        // Set attributes from the imageMap
        for (let attr in attrs) {
            img.setAttribute(attr, attrs[attr]);
        }

        if (change) {
            img.setAttribute(
                "caption",
                `[${change}]${change === "ins" ? newCaption : oldCaption}[/${change}]`,
            );
        } else {
            img.setAttribute("caption", caption);
        }

        if (change) {
            img.setAttribute("data-diff-node", change);
            img.setAttribute("data-diff-node-id", uuid());
        } else if (caption.includes("[ins]") || caption.includes("[del]")) {
            img.setAttribute("data-diff-node", "edit");
            img.setAttribute("data-diff-node-id", uuid());
        }

        img.setAttribute("data-diff-mode", true);

        const wrapper = findImageWrapper(img);

        if (wrapper && wrapper.parentNode) {
            wrapper.parentNode.replaceChild(img, wrapper);
        }
    }

    const tables = doc.querySelectorAll("table");

    for (let i = tables.length - 1; i >= 0; i--) {
        let table = tables[i];

        table.setAttribute("data-diff-mode", true);
    }

    const reports = doc.querySelectorAll("report");

    for (let i = reports.length - 1; i >= 0; i--) {
        let report = reports[i];

        report.setAttribute("data-diff-mode", true);
    }

    return doc.body.innerHTML;
};

export const addDiffModeToTags = (htmlString) => {
    let parser = new DOMParser();
    let doc = parser.parseFromString(htmlString, "text/html");
    let nodes = doc.querySelectorAll("img, table, report");

    for (let i = 0; i < nodes.length; i++) {
        nodes[i].setAttribute("data-diff-mode", true);
    }

    return doc.body.innerHTML;
};
