import React from "react";
import { observer } from "mobx-react";
import { NavLink } from "react-router-dom";
import format from "@app/lib/format";

import "./style/search.scoped.scss";

const SearchSummary = observer(({ results = [], title }) => {
    const total = results.reduce((total, source) => total + (source.results || 0), 0);
    const sources = results.slice(0, 3);
    const hasMore = results.length > 3;

    return (
        <div className="search">
            <div className="heading">
                <div>
                    <label className="label">{title}</label>
                </div>
                <div>
                    <NavLink to="./search" className="link">
                        View All
                    </NavLink>
                </div>
            </div>

            <div className="body">
                <div className="total">
                    <div className="count">{format.number(total)}</div>
                    <div className="label">{format.pluralize(total, "Publication")}</div>
                </div>

                {sources.map((source) => {
                    return (
                        <div className="source" key={source._id}>
                            <div className="count">{format.number(source.results)}</div>
                            <div className="label">{format.searchDb(source._id)}</div>
                        </div>
                    );
                })}

                {hasMore && (
                    <div className="more">
                        <div className="dots">...</div>
                    </div>
                )}
            </div>
        </div>
    );
});

export default SearchSummary;
