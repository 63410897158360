import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { InfoCircleOutlined } from "@ant-design/icons";
import Card from "@app/components/highlight/card";
import HighlightModal from "@app/components/highlight/modal";

import "./style/icon.scoped.scss";

import state from "@app/state/store/report/article";
import session from "@app/state/store/session";
import report from "@app/state/store/report";

const Highlights = observer(() => {
    const [highlight, setHighlight] = useState(null);
    const readonly = !session.can("article.update") || report.readOnly;

    useEffect(() => {
        state.loadHighlights();
    }, []);

    /**
     * Edit a highlight
     */
    const edit = (hl) => {
        setHighlight(hl);
    };

    /**
     * Save the highlight
     */
    const save = (hl) => {
        state.saveHighlight(hl);
        setHighlight(null);
    };

    /**
     * Remove a highlight
     */
    const remove = (hl) => {
        state.removeHighlight(hl);
    };

    return (
        <div>
            {state.highlights.slice(0).map((hl) => {
                return (
                    <Card
                        highlight={hl}
                        key={hl._id}
                        readonly={readonly}
                        onEdit={() => {
                            edit(hl);
                        }}
                        onDelete={() => {
                            remove(hl);
                        }}
                    />
                );
            })}

            {state.highlights.length === 0 && (
                <div className="emptyIconContainer">
                    <div className="icon">
                        <InfoCircleOutlined />
                    </div>
                    <div className="text">
                        There are no highlights for this article.
                        <br />
                        You can select a text from the text on left to add a new highlight.
                    </div>
                </div>
            )}

            <HighlightModal
                visible={!!highlight}
                highlight={highlight}
                onSave={(hl) => {
                    save(hl);
                }}
                onDiscard={() => {
                    setHighlight(null);
                }}
            />
        </div>
    );
});

export default Highlights;
