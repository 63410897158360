import React, { useState } from "react";
import { observer } from "mobx-react";
import { SnippetsOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { UploadService } from "./service";
import { useUploadContext } from "./context";
import notify from "@app/components/notify";

const ClipBoardButton = observer(({ typeMatcher = /image/, handleItem }) => {
    const [loading, setLoading] = useState(false);
    const { projectId, clientId } = useUploadContext();

    return (
        <Button
            loading={loading}
            onClick={async (e) => {
                e.preventDefault();
                setLoading(true);
                let isImage = false;

                try {
                    const clipboardItems = await navigator.clipboard.read();
                    const clipBoardItem = clipboardItems?.[0];

                    clipBoardItem?.types?.length > 0 &&
                        clipBoardItem.types.forEach(async (type) => {
                            if (typeMatcher.test(type)) {
                                const item = await clipBoardItem.getType(type);
                                item.name = item?.name || "clipboard";
                                const { data } = await UploadService.upload(item, {
                                    projectId,
                                    clientId,
                                });

                                if (data) {
                                    handleItem && (await handleItem(data));
                                }
                                isImage = true;
                            }
                        });
                } catch (err) {
                    notify.error("Unable to upload from clipboard");
                } finally {
                    if (!isImage) {
                        notify.info("The clipboard item is not an image.  Please use an image.");
                    }
                    setLoading(false);
                }
            }}
            type="icon"
            icon={<SnippetsOutlined />}
        ></Button>
    );
});

export default ClipBoardButton;
