import React from "react";
import { observer } from "mobx-react";

import { Button } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import confirm from "../../confirm";
import Group from "../group";
import Locked from "../locked";

import "./style/reason.scoped.scss";
import { ReasonType } from "@app/constants";

const Reason = observer(({ value, index, readonly = false, onRemove, onEdit }) => {
    /**
     * Handle edit button click
     */
    const edit = (event) => {
        event.preventDefault();
        onEdit && onEdit(value);
    };

    /**
     * Handle delete button click
     */
    const remove = async (event) => {
        event.preventDefault();

        let proceed = await confirm(
            "You are about to delete this exclusion reason.  This will reset the review status of any articles using this exclusion reason.  Do you want to continue?",
        );
        if (proceed) {
            onRemove && onRemove(value, event);
        }
    };

    return (
        <Group.Item id={value._id || "new"} index={index} disableDrag={readonly}>
            <ReasonCard value={value} readonly={readonly} remove={remove} edit={edit} />
        </Group.Item>
    );
});

export const ReasonCard = observer(({ value, readonly, remove, edit }) => {
    /**
     * Render the system type label
     */
    const Type = () => {
        if (!value.system) {
            return null;
        }

        let type = "Other";
        if (value.type === ReasonType.DUPLICATE) {
            type = "Duplicate Article";
        } else if (value.type === ReasonType.BOOK) {
            type = "Books / Thesis";
        } else if (value.type === ReasonType.LOW_SCORE) {
            type = "Insufficient Score";
        }

        return <div className="type">{type}</div>;
    };

    return (
        <div className="reason">
            <div className="text">{value.reason}</div>
            <Type />

            {!readonly && (
                <div className="actions">
                    <div className="icons">
                        <Button onClick={edit} type="icon" icon={<EditOutlined />}></Button>

                        {value.system && <Locked />}
                        {!value.system && (
                            <Button
                                onClick={remove}
                                type="icon"
                                danger
                                icon={<DeleteOutlined />}
                            ></Button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
});

export default Reason;
