import React from "react";
import { Button } from "antd";

import symbols from "./symbols";
import "./style.scss";

const SymbolsMenu = ({ editor }) => {
    const insertSymbol = (item) => {
        editor.commands.insertContent(item.value);
    };
    return (
        <div className="symbol-container" style={{ gridTemplateColumns: "repeat(6, 1fr)" }}>
            {symbols.map((item, index) => (
                <Button className="item" key={index} onClick={() => insertSymbol(item)}>
                    {item.value}
                </Button>
            ))}
        </div>
    );
};

export default SymbolsMenu;
