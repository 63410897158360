import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router-dom";
import {
    FileSearchOutlined,
    DeploymentUnitOutlined,
    SettingOutlined,
    CopyOutlined,
    BookOutlined,
    FileZipOutlined,
} from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { Page } from "@app/components/page";
import Section from "@app/components/setup/section";
import client from "@app/state/store/clients/details";

const Setup = observer(() => {
    const { configuration, id } = useParams();
    const history = useHistory();

    const config = client?.config;

    useEffect(() => {
        (async () => {
            const success = await client.loadConfig(id, configuration);
            if (!success) {
                history.push(`/clients/${client.data._id}/view/config`);
            }
        })();
    }, [id, configuration, history]);

    if (!config || client.configuration !== configuration) {
        return null;
    }

    /**
     * Close the page
     */
    const close = () => {
        history.push(`/clients/${client.data._id}/view/config`);
    };

    const copyConfig = () => {
        client.copyConfig(configuration);
    };

    const CopyConfiguration = () => {
        return (
            <Tooltip title="Copy this configuration">
                <Button onClick={copyConfig} icon={<CopyOutlined />}>
                    Copy
                </Button>
            </Tooltip>
        );
    };

    return (
        <Page className="setup">
            <Page.Header title="Client Configuration" closable onClose={close}>
                <Page.Header.Right shrink>
                    <CopyConfiguration />
                </Page.Header.Right>
            </Page.Header>

            <Page.Body maxWidth={800}>
                <div className="sections">
                    <Section link="./setup/general" title="General" icon={<SettingOutlined />} />
                    <Section link="./setup/slr" title="DUE" icon={<FileSearchOutlined />} />
                    <Section link="./setup/sota" title="SoTA" icon={<DeploymentUnitOutlined />} />
                    <Section
                        link="./setup/reports"
                        title="Literature Reports"
                        icon={<BookOutlined />}
                    />
                    <Section
                        link="./setup/report-documents"
                        title="Report Documents"
                        icon={<FileZipOutlined />}
                    />
                </div>
            </Page.Body>
        </Page>
    );
});

export default Setup;
