import { action, computed } from "mobx";
import http from "@app/lib/http";

import BaseStore from "@app/state/store/base";

import report from "../../../report";

export class ArticleFlowStore extends BaseStore {
    observable() {
        return {
            loading: false,
            data: null,
        };
    }

    @computed get busy() {
        return this.loading;
    }

    @computed get project() {
        return report.id;
    }

    @action
    async load(type) {
        this.loading = true;

        let { data } = await http.get(`/project/${this.project}/reports/flow/${type}`);
        this.data = data;

        this.loading = false;
    }
}

export default new ArticleFlowStore();
