import React from "react";
import { observer } from "mobx-react";

import Group from "../../group";
import "./style.scoped.scss";

const ReportDocumentReviewOverview = observer(({ document, readonly, onEdit }) => {
    const config = document.reviewConfig;
    const isEnabled = config?.stages?.length > 0;

    return (
        <Group title="Review" onEdit={!readonly && onEdit}>
            <div className="review">
                {isEnabled &&
                    config.stages.map((stage, i) => {
                        return (
                            <div className="stage" key={stage._id}>
                                <div className="title">
                                    {i + 1}. {stage.title}
                                </div>
                                {stage.reviewers.map((user) => {
                                    return (
                                        <div className="reviewer" key={user._id}>
                                            {user.fullName}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}

                {!isEnabled && (
                    <div className="text-center p-10">
                        Review workflow is not enabled for this document
                    </div>
                )}
            </div>
        </Group>
    );
});

export default ReportDocumentReviewOverview;
