import React from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import EmptyList from "@app/components/list/empty";
import FiltersSummary from "@app/components/search/summary";
import format from "@app/lib/format";
import state from "@app/state/store/report/output/slr/searches";

import { Switch } from "antd";
import { Page } from "@app/components/page";
import { Copy } from "@app/components/clipboard";
import { Box } from "@app/components/box";

import "../style/common.scoped.scss";
import "../style/searches.scoped.scss";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import _ from "lodash";

const ReportPage = observer(() => {
    const [included, setIncluded] = React.useState(false);
    const [reasons, setReasons] = React.useState(false);
    const [term, setTerm] = React.useState(false);
    const history = useHistory();

    const close = () => {
        history.push("../report");
    };

    const props = {
        included,
        reasons,
        term,
    };

    return (
        <>
            <Page className="setup">
                <Page.Header title="Search Parameters" closable onClose={close}>
                    <Page.Header.Right>
                        <Copy component={<Report {...props} />} className="copy-style" />
                    </Page.Header.Right>
                </Page.Header>
                <Page.Body maxWidth="1200">
                    {state.list?.length > 0 && (
                        <>
                            <div className="row toggle items-center mb-15">
                                <div className="col auto">
                                    <Switch
                                        checked={reasons}
                                        onChange={(value) => setReasons(value)}
                                    />
                                </div>
                                <div className="col ml-15">Show Exclusion Reasons</div>
                            </div>
                            <div className="row toggle items-center mb-15">
                                <div className="col auto">
                                    <Switch
                                        checked={included}
                                        onChange={(value) => setIncluded(value)}
                                    />
                                </div>
                                <div className="col ml-15">N Included</div>
                            </div>
                            <div className="row toggle items-center mb-15">
                                <div className="col auto">
                                    <Switch checked={term} onChange={(value) => setTerm(value)} />
                                </div>
                                <div className="col ml-15">Show Search Query</div>
                            </div>
                        </>
                    )}
                    <Box className="display-style">
                        <Report {...props} />
                    </Box>
                </Page.Body>
            </Page>
        </>
    );
});

const Report = observer(({ included, reasons, term }) => {
    const { type } = useParams();

    React.useEffect(() => {
        state.load(type);
    }, [type]);

    const { list, loading } = state;
    const searches = _.groupBy(list, "source");

    return (
        <>
            <EmptyList title="No Records Found" show={list.length === 0 && !loading}></EmptyList>
            {Object.keys(searches).length !== 0 && (
                <table className="report-searches">
                    <colgroup>
                        <col width="10" />
                        <col width="100" />
                        <col width="100" />
                        <col width="400" />
                        <col width="200" />
                        <col width="100" />
                        <col width="150" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>Database</th>
                            <th>Date</th>
                            <th>Search Terms</th>
                            <th>Filters</th>
                            <th>Results</th>
                            {included && <th>N Included</th>}
                            <th>Excluded</th>
                            {term && <th>Search Query</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(searches).map((key) => {
                            const dbWiseRecords = searches[key];
                            return dbWiseRecords.map((search, idx) => {
                                const URL = `/r/${state.project}/search/${search._id}`;
                                return (
                                    <tr key={search._id}>
                                        <td>{idx + 1}</td>
                                        {idx === 0 ? (
                                            <td rowSpan={dbWiseRecords.length} className="center">
                                                {format.searchDb(search.source)}
                                            </td>
                                        ) : null}
                                        <td>{format.date(search.time)}</td>
                                        <td>{search.query}</td>
                                        <td>
                                            <FiltersSummary search={search} display="report" />
                                        </td>
                                        <td>{search.results}</td>
                                        {included && <td>{search.included}</td>}
                                        <td>
                                            {reasons ? search.excludedWithReasons : search.excluded}
                                        </td>
                                        {term && (
                                            <td>
                                                <Link to={URL}>{search.term}</Link>
                                            </td>
                                        )}
                                    </tr>
                                );
                            });
                        })}
                    </tbody>
                </table>
            )}
        </>
    );
});

export default ReportPage;
