import classes from "classnames";
import { Checkbox } from "antd";
import { observer } from "mobx-react";
import React from "react";
import Group from "./group";

import "./style/toggle.scoped.scss";

const OptionToggle = observer(
    ({ filter, filterKey, label, name, title, options, disabled, collpased, groupKey }) => {
        let filterContent = filter.state.filter;
        if (filterKey) {
            filterContent = filterContent[filterKey] || {};
        }

        let selected = filterContent[name] ?? false;

        /**
         * Update the selection
         */
        const toggle = () => {
            if (disabled) {
                return;
            }

            filter.filter(name, !selected, { filterKey });
        };

        return (
            <Group
                className="toggle"
                filter={filter}
                title={title}
                hasActiveFilters={selected}
                collapsed={collpased}
                groupKey={groupKey ?? name}
            >
                <div
                    className={classes("option", {
                        selected: selected,
                        disabled: disabled,
                    })}
                    onClick={toggle}
                >
                    <Checkbox className="checkbox" checked={selected} disabled={disabled} />
                    <div className="label">{label}</div>
                </div>
            </Group>
        );
    },
);

export default OptionToggle;
