import { action } from "mobx";
import BaseStore from "./base";
import http from "@app/lib/http";
import notify from "@app/components/notify";

/**
 * State management controlling the health details of monitored services
 */
export class Health extends BaseStore {
    /**
     * Observable store data
     */
    observable() {
        return {
            loading: false,
        };
    }

    /**
     * Check for Health
     */
    @action
    async check(system) {
        this.loading = true;

        try {
            let { data } = await http.get(`/health`, { name: system });
            return data;
        } catch (ex) {
            notify.error(ex.response?.data?.error || "Health Check Failed.");
            return false;
        } finally {
            this.loading = false;
        }
    }
}

export default new Health();
