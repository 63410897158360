import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Form, Input } from "antd";

import Modal from "@app/components/modal";
import notify from "../../notify";
import "./style/reason.scoped.scss";

const ReasonForm = observer(({ reason = {}, onSave, onCancel }) => {
    const [form] = Form.useForm();
    const refReason = React.useRef(null);

    useEffect(() => {
        form.setFieldsValue({ reason: reason.reason });
        if (refReason.current) {
            refReason.current.focus();
        }
    }, [form, reason]);

    /**
     * Show the validation error
     */
    const error = () => {
        notify.error("Please fill all required fields to continue");
    };

    /**
     * Cancel the edit
     */
    const cancel = () => {
        onCancel && onCancel();
        form.resetFields();
    };

    /**
     * Save the reason
     */
    const save = (data) => {
        onSave &&
            onSave({
                _id: reason._id,
                reason: data.reason.trim(),
            });
    };

    const title = reason._id ? "Edit exclusion reason" : "Add exclusion reason";

    return (
        <Modal
            title={title}
            visible={true}
            okText="Save"
            onOk={form.submit.bind(form)}
            onCancel={cancel}
            width={600}
        >
            <div className="reason-form">
                <Form layout="vertical" form={form} onFinishFailed={error} onFinish={save}>
                    <Form.Item
                        label="Reason"
                        name="reason"
                        rules={[
                            {
                                required: true,
                                message: "Please enter the reason",
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={refReason} />
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
});

export default ReasonForm;
