import React from "react";

export default function () {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            width="1em"
            height="1em"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 14 14"
        >
            <path
                fill="currentColor"
                d="M2 0h10a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2Zm0 4v8h10V4H2Zm5 5.414l-1.414 1.414a1 1 0 1 1-1.414-1.414L5.586 8L4.172 6.586a1 1 0 1 1 1.414-1.414L7 6.586l1.414-1.414a1 1 0 1 1 1.414 1.414L8.414 8l1.414 1.414a1 1 0 0 1-1.414 1.414L7 9.414Z"
            />
        </svg>
    );
}
