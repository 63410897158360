import { observable } from "mobx";
import Model from "@app/state/model/base";

export default class Search extends Model {
    @observable _id = undefined;
    @observable project = undefined;
    @observable query = undefined;
    @observable source = undefined;
    @observable type = undefined;
    @observable results = 0;
    @observable created = undefined;
    @observable status = undefined;
    @observable progress = 0;
    @observable $selected = false;
    @observable term = undefined;
    @observable link = undefined;
    @observable minPublicationYear = undefined;
    @observable maxPublicationYear = undefined;
    @observable excludeBooks = true;
    @observable maxResults = undefined;
}
