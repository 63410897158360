import React, { useEffect } from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { Form, Button, Input, Alert } from "antd";
import Box from "@app/components/box/box";
import notify from "@app/components/notify";

import profile from "@app/state/store/profile";

const state = observable({ success: false, error: false });

const Password = observer(({ className, ...props }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        // cleanup the success state when unmounting
        return function cleanup() {
            if (state.success) {
                state.success = false;
            }
        };
    });

    /**
     * Update the password
     */
    const update = async (data) => {
        const success = await profile.passwd({
            password: data.password,
            newPassword: data.newPassword,
        });
        state.success = success;
        state.error = !success;
    };

    /**
     * Show the validation error
     */
    const validationError = () => {
        notify.error("Please fill all required fields to continue");
    };

    /**
     * Render the feedback alert widget
     */
    const Feedback = observer(() => {
        if (state.error) {
            return (
                <Alert
                    message="Password Error"
                    description="Provided password does not match the current account password. Account password was not updated."
                    type="error"
                    className="mb-20"
                />
            );
        } else if (state.success) {
            return (
                <Alert
                    message="Success"
                    description="Your password was updated successfully."
                    type="success"
                    className="mb-20"
                />
            );
        } else {
            return null;
        }
    });

    return (
        <Box title="Change Your Password" className={`password ${className}`} {...props}>
            <Feedback />
            <Form layout="vertical" form={form} onFinish={update} onFinishFailed={validationError}>
                <Form.Item
                    label="Current Password"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: "Please enter your password",
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    label="New Password"
                    name="newPassword"
                    rules={[
                        {
                            required: true,
                            message: "Please enter your new password",
                        },
                        {
                            message: "The password must be at least 10 characters long",
                            min: 10,
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    label="Confirm Password"
                    name="repeated"
                    rules={[
                        {
                            required: true,
                            message: "Please confirm your password",
                            pattern: new RegExp(/\S/),
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue("newPassword") === value) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(
                                    "The two passwords that you entered do not match!",
                                );
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item className="pt-20 text-right">
                    <Button type="primary" loading={profile.busy} onClick={() => form.submit()}>
                        Update
                    </Button>
                </Form.Item>
            </Form>
        </Box>
    );
});

export default Password;
