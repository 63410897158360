import React, { useEffect, useState } from "react";
import { default as classes } from "classnames";
import { observer } from "mobx-react";

import useComponentWillUnmount from "@app/hooks/useComponentWillUnmount";
import TOC from "./toc";
import Section from "./section";
import Appendix from "./appendix";
import Statusbar from "./statusbar";

import Toolbar from "@app/components/report-document/editor/toolbar";
import sectionsStore from "@app/state/store/report-document/report-document-sections";
import documentStore from "@app/state/store/report-document/document";

import CommentModal from "@app/components/report-document/editor/modules/comment/comment-modal";

import "./styles/viewer.scoped.scss";

const Viewer = observer(
    ({
        sections,
        citations,
        abbreviations,
        editingState,
        documentId,
        readonly = false,
        templateMode = false,
    }) => {
        const [editing, setEditing] = editingState;
        const [toolbarKey, setToolbarKey] = useState(null);
        const sectionId = editing?.section._id;
        const projectId = editing?.section.project;
        const editor = editing?.editor;

        const hasTables = !!documentStore.tables.length;
        const hasFigures = !!documentStore.figures.length;
        const hasCitations = !!citations.cited.length;
        const hasAbbreviations = !!abbreviations.usedAbbreviations.length;
        const hasAppendix = hasTables || hasFigures || hasCitations || hasAbbreviations;

        const appendix = {
            hasAppendix,
            hasTables,
            hasFigures,
            hasCitations,
            hasAbbreviations,
        };

        const save = async (overwrite) => {
            if (sectionsStore.saving || (sectionsStore.saved && !overwrite)) {
                return;
            }

            const content = editor?.getJSON();

            if (!templateMode) {
                await sectionsStore.saveContent(sectionId, content, {
                    projectId,
                    overwrite,
                    section: editing?.section,
                });
            } else {
                await sectionsStore.saveTemplateContent(sectionId, content, documentId);
            }
        };

        const close = async () => {
            await save();

            if (!templateMode) {
                await sectionsStore.releaseLock(sectionId);
                setEditing(null);
            } else {
                setEditing(null);
            }
        };

        useComponentWillUnmount(async () => {
            if (sectionId && !templateMode) {
                await sectionsStore.releaseLock(sectionId, projectId);
            }
        });

        // Various toolbar commands depend on changes to editor cursor depth. Listen for those changes
        // and re-render the toolbar accordingly
        useEffect(() => {
            if (editor) {
                const onSelectionUpdate = () => {
                    setToolbarKey(new Date().getTime());
                };

                editor.on("selectionUpdate", onSelectionUpdate);
                editor.on("updateToolbar", onSelectionUpdate);

                return () => {
                    editor.off("selectionUpdate", onSelectionUpdate);
                    editor.off("updateToolbar", onSelectionUpdate);
                };
            }
        }, [editor?.state.selection]);

        const showStatusBar = !templateMode || (templateMode && editing);

        return (
            <>
                {editor && <Toolbar editor={editor} key={toolbarKey} templateMode={templateMode} />}
                <div className={classes({ viewer: true, editing: !!editor })}>
                    <TOC sections={sections} appendix={appendix} />
                    <div className="content">
                        {sections.map((section) => (
                            <Section
                                key={section._id}
                                section={section}
                                editing={editingState}
                                templateMode={templateMode}
                                documentId={documentId}
                                readonly={readonly}
                            />
                        ))}
                        <Appendix
                            sections={sections}
                            abbreviations={abbreviations}
                            citations={citations}
                            appendix={appendix}
                        />
                    </div>
                    {showStatusBar && (
                        <Statusbar
                            editor={editor}
                            onSave={save}
                            onClose={close}
                            sectionId={sectionId}
                            projectId={projectId}
                        />
                    )}
                    <CommentModal />
                </div>
            </>
        );
    },
);

Viewer.displayName = "Viewer";

export default Viewer;
