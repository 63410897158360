import React from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import ReportWrapper from "./report-wrapper";
import EmptyList from "@app/components/list/empty";
import state from "@app/state/store/report/output/slr/article-flow";

const ReportPage = () => <ReportWrapper name="Article Flow" component={<Report />} />;

const Report = observer(() => {
    const { type } = useParams();

    React.useEffect(() => {
        state.load(type);
    }, [type]);

    const { data, loading } = state;
    return (
        <>
            <EmptyList title="No Records Found" show={data === null && !loading}></EmptyList>
            {data !== null && (
                <table>
                    <thead>
                        <tr>
                            <th>Stage</th>
                            <th className="center">Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Total Number of Articles Retrieved</td>
                            <td className="center">{data.saved}</td>
                        </tr>
                        <tr>
                            <td>Articles after removing duplicates</td>
                            <td className="center">{data.unique}</td>
                        </tr>
                        <tr>
                            <td>Articles screened</td>
                            <td className="center">{data.screened}</td>
                        </tr>
                        <tr>
                            <td>Articles excluded at L1 (Title and Abstract Screening)</td>
                            <td className="center">{data.failedL1}</td>
                        </tr>
                        <tr>
                            <td>Full-text articles reviewed at L2</td>
                            <td className="center">{data.passedL1}</td>
                        </tr>
                        <tr>
                            <td>Full-text articles excluded at L2</td>
                            <td className="center">{data.failedL2}</td>
                        </tr>
                        <tr>
                            <td>Articles included in the CER</td>
                            <td className="center">{data.passedL2}</td>
                        </tr>
                    </tbody>
                </table>
            )}
        </>
    );
});

export default ReportPage;
