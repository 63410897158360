import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Page } from "@app/components/page";
import Filter from "@app/components/filter";
import EmptyList from "@app/components/list/empty";
import Card from "@app/components/highlight/card";
import HighlightFilter from "@app/components/highlight/filter";

import "./style/common.scoped.scss";
import "./style/report-styles.scss";

import state from "@app/state/store/report/highlights";

const Highlights = observer(() => {
    // Load the highlights
    useEffect(() => {
        state.load();
    }, []);

    return (
        <Page className="highlights">
            <Page.Header title="Article Snippets" />
            <Filter.Header filter={state.filter} />

            <Page.Layout>
                <Page.Body maxWidth={1000}>
                    <EmptyList
                        title="No Results Found"
                        show={state.list.length === 0 && !state.loading}
                    />

                    {state.list.map((hl) => {
                        const { title, _id, type, mdrId, project, review } = hl?.articleDetails;
                        const articleType = type.length ? type : "slr";
                        const stage = review.stage;

                        const area = hl.areas[0] ? `area=${JSON.stringify(hl.areas[0])}` : "";
                        const viewFile = hl.source === "reactPdfViewer" ? "view-file=true&" : "";

                        const URL = `/r/${project}/${articleType}/${stage}/${_id}?${viewFile}snippet=${hl._id}&highlight=true&${area}`;

                        return (
                            <Card
                                highlight={hl}
                                key={hl._id}
                                link={URL}
                                title={title}
                                mdrId={mdrId}
                            />
                        );
                    })}
                </Page.Body>
                <HighlightFilter filter={state.filter} />
            </Page.Layout>

            <Page.Footer>
                <Filter.Pager filter={state.filter} />
            </Page.Footer>
        </Page>
    );
});

export default Highlights;
