import BaseStore from "./base";
import { computed, action } from "mobx";
import Notification from "@app/state/model/notification";
import http from "@app/lib/http";
import notify from "@app/components/notify";
import { ws } from "@app/lib/socket";
import { events } from "@app/lib/store";
import FilterState from "@app/components/filter/state";

export class NotificationList extends BaseStore {
    constructor() {
        super();
        this.filter = new FilterState({
            rows: 25,
        });

        ws.on("notification", (notification) => {
            this.prependNotification(notification);
        });

        events.on("auth.login", () => {
            this.load();
        });

        this.filter.on("find", () => {
            this.load();
        });
    }

    observable() {
        return {
            loading: false,
            list: [],
            pager: { currentPage: 1, pageSize: 10 },
            unread: 0,
        };
    }

    @computed get busy() {
        return this.loading;
    }

    @action
    prependNotification(notification) {
        const newNotification = new Notification(notification);
        this.list = [newNotification, ...this.list];
        this.unread++;
    }

    formatSearchParams() {
        return {
            from: `${(this.pager.currentPage - 1) * this.pager.pageSize}`,
            rows: `${this.pager.pageSize}`,
            terms: this.searchCriteria,
        };
    }

    /**
     * Check and display notifications
     */
    @action
    async load() {
        this.loading = true;
        let filter = this.filter.value();

        try {
            const { data } = await http.get(`/profile/notifications`, filter);
            let unread = 0;

            const results = data.list?.map((notification) => {
                if (notification.read === false) {
                    unread++;
                }
                return new Notification(notification);
            });
            this.list.push(...results);
            this.filter.stats(data.stats);
            this.unread += unread;
        } catch (ex) {
            notify.error(ex.response?.data?.error);
            return false;
        } finally {
            this.loading = false;
        }
    }

    /**
     * mark a notification as seen
     */
    @action
    async seen(params) {
        this.loading = true;

        try {
            const { data } = await http.put(`/profile/notification/${params._id}/seen`);
            if (data && this.unread > 0) {
                this.unread--;

                this.list
                    .filter((notification) => notification._id === params._id)
                    .map((notification) => (notification.read = true));
            }
        } catch (ex) {
            notify.error(ex.response?.data?.error);
            return false;
        } finally {
            this.loading = false;
        }
    }

    @action
    async markAllAsRead() {
        this.loading = true;

        try {
            const { data } = await http.put(`/profile/notification/mark-all-seen`);

            if (data && this.unread > 0) {
                this.unread = 0;
                this.list.map((notification) => (notification.read = true));
            }
        } catch (ex) {
            notify.error(ex.response?.data?.error);
            return false;
        } finally {
            this.loading = false;
        }
    }
}

export default new NotificationList();
