import React, { useEffect } from "react";
import { observer, useLocalStore } from "mobx-react";
import { useHistory, useParams } from "react-router-dom";
import { Modal } from "antd";
import confirm from "@app/components/confirm";
import { ws } from "@app/lib/socket";
import notify from "@app/components/notify";

import sessionStore from "@app/state/store/session";
import sectionsStore from "@app/state/store/report-document/report-document-sections";

const Lock = observer(() => {
    const history = useHistory();
    const { project } = useParams();

    var state = useLocalStore(() => ({
        showModal: false,
        requestUser: undefined,
        section: undefined,
    }));

    useEffect(() => {
        if (project) {
            sectionsStore.projectId = project;
        }
    }, [project]);

    useEffect(() => {
        const listenerRequest = async ({
            requestUserId,
            sectionOwnerId,
            sectionId,
            projectId,
            requestUserName,
        }) => {
            // show the modal
            if (sectionOwnerId === sessionStore.user._id) {
                const section = await sectionsStore.get(sectionId, false, projectId);

                state.requestUser = {
                    _id: requestUserId,
                    fullName: requestUserName,
                };
                state.section = section;
                state.showModal = true;
            }
        };

        const listenerRelease = async ({ requestUserId, sectionId, projectId }) => {
            // ask the requestor if they want to proceed to edit the content.
            if (requestUserId === sessionStore.user._id) {
                const section = await sectionsStore.get(sectionId, false, projectId);
                const { project, reportDocumentId, title } = section;

                const proceed = await confirm(
                    `Your request for section ${title} has been approved.  Do you want to edit the section?`,
                );

                if (proceed) {
                    history.push(
                        `/r/${project}/reports/documents/${reportDocumentId}?section=${sectionId}`,
                    );
                }
            }

            resetState();
        };

        const listenerDeny = async ({ requestUserId, sectionId, projectId }) => {
            if (requestUserId === sessionStore.user._id) {
                // notify the requestor the request has been denied by owner
                const section = await sectionsStore.get(sectionId, false, projectId);
                const { lockedBy } = section;

                notify.info(`Your request has been denied by the owner: ${lockedBy?.fullName}`);
            }
        };

        const listenerUpdated = async ({ sectionId, projectId }) => {
            // Update local store with new updated section lock
            // only update when route is within project
            if (project === projectId) {
                const section = await sectionsStore.get(sectionId, false, projectId);
                const sectionIndex = sectionsStore.sections.findIndex((s) => s._id === section._id);

                if (sectionIndex > -1) {
                    sectionsStore.sections[sectionIndex] = section;
                }
            }
        };

        const forceReleased = ({ sectionId, projectId }, ...rest) => {
            const sectionIds = sectionsStore.sections.map((s) => s._id);

            if (sectionIds.includes(sectionId) && project === projectId) {
                window.location.reload();
            }
        };

        ws.on("reportDocument.section.lock.request", listenerRequest);
        ws.on("reportDocument.section.lock.release", listenerRelease);
        ws.on("reportDocument.section.lock.deny", listenerDeny);
        ws.on("reportDocument.section.lock.updated", listenerUpdated);
        ws.on("reportDocument.section.lock.forceRelease", forceReleased);

        return () => {
            ws.removeListener("reportDocument.section.lock.request", listenerRequest);
            ws.removeListener("reportDocument.section.lock.release", listenerRelease);
            ws.removeListener("reportDocument.section.lock.deny", listenerDeny);
            ws.removeListener("reportDocument.section.lock.updated", listenerUpdated);
            ws.removeListener("reportDocument.section.lock.forceRelease", forceReleased);
        };
    }, []);

    const resetState = () => {
        state.showModal = false;
        state.requestUser = undefined;
        state.section = undefined;
    };

    const handleOk = async () => {
        if (state.section) {
            await sectionsStore.releaseLock(state.section._id);

            // Full reload the previous owner if they are currently on the section
            if (/reports\/documents\//.test(history.location.pathname)) {
                history.go(0);
            }

            resetState();
        }
    };

    const handleCancel = async () => {
        if (state.section) {
            await sectionsStore.denyLock(state.section._id);
            resetState();
        }
    };

    return (
        <Modal
            title={`${state?.requestUser?.fullName} wants to edit section ${state?.section?.title}`}
            visible={state.showModal}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <p>
                Do you want to release the lock?
                <br />
                You have about 30secs to respond before it automatically unlocks.
            </p>
        </Modal>
    );
});

export default Lock;
