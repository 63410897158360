import React, { useEffect, useRef } from "react";
import classes from "classnames";

export default function Body({ className, children, filter, padding = true, maxWidth, ...rest }) {
    const ref = useRef(null);

    // scroll the body to the top
    const scrollTop = () => {
        if (ref.current) {
            ref.current.scrollTop = 0;
        }
    };

    useEffect(() => {
        // subscribe to filter.load event to scroll the page to the top
        if (filter) {
            filter.on("find", scrollTop);
        }

        // clean up on unmount
        return function cleanup() {
            if (filter) {
                filter.off("find", scrollTop);
            }
        };
    }, [filter]);

    var content = children;
    if (maxWidth) {
        const style = { margin: "auto", maxWidth: maxWidth };
        content = (
            <div className="body-wrapper" style={style}>
                {children}
            </div>
        );
    }

    return (
        <div ref={ref} className={classes("body", className, { padding: padding })} {...rest}>
            {content}
        </div>
    );
}
