import { action, computed } from "mobx";
import http from "@app/lib/http";
import BaseStore from "./base";
import SessionStore from "./session";
import ApplicationSettings from "@app/state/model/application/settings";
import notify from "@app/components/notify/index";

export class Application extends BaseStore {
    observable() {
        return {
            settings: null,
            loading: false,
        };
    }

    @computed get enableAI() {
        return this.settings.ai?.enabled && SessionStore.can("copilot");
    }

    @action
    async load() {
        if (this.settings) {
            return;
        }

        try {
            this.loading = true;

            const { data } = await http.get(`/application/settings`);

            let ssoSettings = this.loadSSOSettingsFromLocalStorage();
            if (!ssoSettings && data.auth) {
                ssoSettings = data.auth;
                this.saveSSOSettingsToLocalStorage(ssoSettings);
            }

            if (ssoSettings.sso && !data.auth.sso) {
                this.clearSSOSettingsFromLocalStorage();
            }

            this.settings = new ApplicationSettings({
                ...data,
                sso: ssoSettings?.sso || null,
            });

            this.loading = false;
        } catch (error) {
            this.loading = false;
            notify.error(error.response?.data?.error || "Failed to load application settings");
        }
    }

    @action
    setSSOSettings({ enabled }) {
        if (!this.settings) {
            this.settings = new ApplicationSettings({});
        }
        if (!this.settings.sso) {
            this.settings.sso = {};
        }
        this.settings.sso.enabled = enabled;
        this.saveSSOSettingsToLocalStorage();
    }

    saveSSOSettingsToLocalStorage() {
        if (this.settings?.sso) {
            localStorage.setItem("ssoSettings", JSON.stringify(this.settings.sso));
        }
    }

    loadSSOSettingsFromLocalStorage() {
        const ssoSettings = localStorage.getItem("ssoSettings");
        if (ssoSettings) {
            return { sso: JSON.parse(ssoSettings) };
        }

        return null;
    }

    clearSSOSettingsFromLocalStorage() {
        localStorage.removeItem("ssoSettings");
    }
}

export default new Application();
