import { observable } from "mobx";
import Model from "@app/state/model/base";

export default class History extends Model {
    @observable _id = undefined;
    @observable project = undefined;
    @observable searchTerms = undefined;
    @observable from = undefined;
    @observable rows = undefined;
    @observable results = 0;
    @observable created = undefined;
}
