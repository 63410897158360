import React from "react";
import "./style/source.scoped.scss";

import PubMedIcon from "@app/assets/search/pubmed-small.svg";
import GoogleIcon from "@app/assets/search/google-small.png";
import EuPmcIcon from "@app/assets/search/eupmc.png";
import EmbaseIcon from "@app/assets/search/elsevier.png";
import CochraneIcon from "@app/assets/search/cochrane.png";
import ProsperoIcon from "@app/assets/search/prospero.png";
import OtherIcon from "@app/assets/search/other.png";

const options = {
    pubmed: { label: "PubMed", icon: PubMedIcon },
    google: { label: "Google Scholar", icon: GoogleIcon },
    eupmc: { label: "Europe PMC", icon: EuPmcIcon },
    embase: { label: "Embase", icon: EmbaseIcon },
    cochrane: { label: "Cochrane", icon: CochraneIcon },
    prospero: { label: "Prospero", icon: ProsperoIcon },
    other: { label: "Import", icon: OtherIcon },
};

const Source = ({ name, ...rest }) => {
    const option = options[name];

    return (
        <div className="source" {...rest}>
            <SourceIcon name={name} />
            <span>{option.label}</span>
        </div>
    );
};

export const SourceIcon = ({ name }) => {
    const option = options[name];

    return <img src={option.icon} className="icon" />;
};

export default Source;
