import { action } from "mobx";
import BaseStore from "./base";

import config from "@app/config";
import http from "@app/lib/http";
/**
 * Monitor session expiry to notify the user that their session has expired
 */
export class Expiry extends BaseStore {
    timeout = null;
    sessionDuration = config.cookie.maxAge;

    observable() {
        return {
            lastRequest: null,
            hasExpired: false,
            checkingStatus: false,
        };
    }

    /**
     * Clear the current session timeout
     */
    clearSessionTimeout() {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    }

    /**
     * Set the session to expire after the session duration
     */
    setSessionTimeout() {
        this.clearSessionTimeout();

        this.timeout = setTimeout(async () => {
            await this.checkStatus();
        }, this.sessionDuration);
    }

    /**
     * Renew the session on successful request
     */
    @action
    async renew() {
        this.lastRequest = new Date();

        // Session renewed, so reset expiry
        this.hasExpired = false;
        this.setSessionTimeout();
    }

    @action
    async checkStatus() {
        // Check the guard at the very start of the method
        if (this.hasExpired || this.checkingStatus) {
            return;
        }
        this.checkingStatus = true;

        try {
            const { status } = await http.get("/profile");

            if (status >= 200 && status < 300) {
                this.renew();
            } else {
                this.hasExpired = true;
            }
        } catch (_e) {
            this.hasExpired = true;
        } finally {
            // Use a finally block to ensure checkingStatus is reset even if an exception occurs
            this.checkingStatus = false;
        }
    }
}

const expiry = new Expiry();

http.on("success", () => {
    expiry.renew();
});

http.on("error", async (status) => {
    if (status >= 400 && status < 500) {
        await expiry.checkStatus();
    }
});

export default expiry;
