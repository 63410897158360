import { default as React } from "react";
import { useHistory } from "react-router-dom";
import { Node, mergeAttributes } from "@tiptap/core";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import { Button } from "antd";
import format from "@app/lib/format";

import "./styles/caption-list-item.scoped.scss";

export const CaptionListItem = Node.create({
    name: "caption-list-item",
    group: "block",
    inline: false,
    selectable: false,

    addAttributes() {
        return {
            text: "",
            search: "",
        };
    },

    parseHTML() {
        return [{ tag: "caption-list-item" }];
    },

    renderHTML({ HTMLAttributes }) {
        return ["caption-list-item", mergeAttributes(HTMLAttributes)];
    },

    addNodeView() {
        return ReactNodeViewRenderer(({ node }) => {
            const history = useHistory();
            const goToCaptionItem = () => {
                history.push({
                    pathname: history.location.pathname,
                    search: `?node=${node.attrs.search}`,
                });
            };

            return (
                <NodeViewWrapper className={"caption-list-item"}>
                    <Button type="link" onClick={goToCaptionItem} color={"black"}>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: format.caption(node.attrs.text),
                            }}
                        />
                    </Button>
                </NodeViewWrapper>
            );
        });
    },
});

export default CaptionListItem;
