import React from "react";
import { observer } from "mobx-react";
import EmptyList from "@app/components/list/empty";
import activity from "@app/state/store/activity";
import { Button } from "antd";
import { ActivityLogView } from "@app/constants";

import Group from "./list";

const ActivityList = observer(({ id, context }) => {
    React.useEffect(() => {
        activity.load({ id, view: ActivityLogView.Sidebar, context });
        return function () {
            activity.unload();
        };
    }, [id]);

    if (Object.keys(activity.data)?.length === 0 && !activity.loading) {
        return <EmptyList title="No Activities Found" show={true}></EmptyList>;
    }

    return (
        <>
            {Object.keys(activity.data).map((key) => {
                return (
                    <Group
                        activities={activity.data[key]}
                        date={key}
                        key={key}
                        context={context}
                        view="sidebar"
                    />
                );
            })}

            {activity.filter?.hasNextPage && (
                <Button
                    type="link"
                    onClick={() => {
                        activity.filter.next();
                    }}
                >
                    Load More
                </Button>
            )}
        </>
    );
});

export default ActivityList;
