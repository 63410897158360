import React from "react";
import classNames from "classnames";
import { Tag } from "antd";
import format from "@app/lib/format";
import Tags from "@app/components/tags/tags";
import { PlusSquareOutlined, FullscreenExitOutlined } from "@ant-design/icons";
import report from "@app/state/store/report";
import { SearchType, SearchTypeDescription } from "@app/constants";
import reportStore from "@app/state/store/report";
import { SearchCategory } from "@app/constants";
import { observer } from "mobx-react";

import "./style/article-tags.scoped.scss";

const ArticleTags = observer(({ article, className, edit = false, onRemove, onAdd, ...rest }) => {
    const config = reportStore.config || {};
    const sotaEnabled = config.sota?.enabled;

    const crossChannelUsage = article?.crossChannel?.used;
    const movedArticle = article?.moved;

    const otherChannel = article.type === SearchType.SLR ? SearchType.SOTA : SearchType.SLR;

    const movedInfo = article.originalSearch
        ? `Moved from ${SearchTypeDescription[otherChannel]}`
        : `Moved to ${SearchTypeDescription[otherChannel]} `;

    const category = article.search?.category;
    const crossChannelArticle = crossChannelUsage && sotaEnabled;

    return (
        <div className={classNames("article-tags", className)} {...rest}>
            <Tag key={article.source}>{format.searchDb(article.source)}</Tag>
            {movedArticle && <Tag key={article._id}>{`${movedInfo}`}</Tag>}
            {crossChannelArticle && <Tag key={article.mdrId}>DUE & SoTA</Tag>}
            {category === SearchCategory.GREY_LITERATURE && (
                <Tag className="search-category">
                    <PlusSquareOutlined /> {SearchCategory.GREY_LITERATURE}
                </Tag>
            )}
            {category === SearchCategory.FOCUSED_SEARCH && (
                <Tag className="search-category">
                    <FullscreenExitOutlined /> {SearchCategory.FOCUSED_SEARCH}
                </Tag>
            )}

            <Tags
                assignedTags={article?.articleTags}
                availableTags={report.config?.articleTags}
                edit={edit}
                onRemove={onRemove}
                onAdd={onAdd}
            />
        </div>
    );
});

export default ArticleTags;
