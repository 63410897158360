import React from "react";
import { observer } from "mobx-react";
import { InfoCircleOutlined } from "@ant-design/icons";
import Card from "./card";
import "./style/index.scoped.scss";

const Versions = observer(({ document, onRestore }) => {
    if (document.history?.length === 0) {
        return <NoData />;
    }

    return (
        <>
            {document.history
                .slice()
                .sort((a, b) => {
                    return b.version - a.version;
                })
                .map((history) => {
                    return <Card history={history} onRestore={onRestore} key={history._id} />;
                })}
        </>
    );
});

const NoData = observer(() => {
    return (
        <div className="versions">
            <div className="empty">
                <div className="icon">
                    <InfoCircleOutlined />
                </div>
                <div className="text">
                    There is no previous version of the document. The current document is the first
                    version added to the system.
                </div>
            </div>
        </div>
    );
});

export default Versions;
