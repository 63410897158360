import React, { useEffect } from "react";
import { observer, useLocalStore } from "mobx-react-lite";
import { CloseOutlined, RightOutlined, LeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import classNames from "classnames";
import { useMediaQuery } from "@app/lib/screen";
import { events } from "@app/lib/store";

import "./styles/sidebar.scoped.scss";

/**
 * Sidebar component
 */
const Sidebar = observer(
    ({
        width = 300,
        sidebar,
        title,
        padding = true,
        collapsible = false,
        onClose,
        onChange,
        className,
        children,
        footer,
        ...rest
    }) => {
        const state = useSidebar(sidebar);
        const query = useMediaQuery({
            small: "(max-width: 1000px)",
            xs: "(max-width: 600px)",
        });

        // monitor for screen size changes
        useEffect(() => {
            // if the device size is small make the component modal
            if (query.small) {
                state.inline = false;
                state.visible = false;
            } else {
                state.inline = true;
                state.visible = true;
            }

            // if the device size is extra small make it closable
            // because we are going to show it as full screen page
            if (query.xs) {
                state.fullScreen = true;
            } else {
                state.fullScreen = false;
            }

            onChange && onChange();

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [state, query]);

        // attach the document click handler when the sidebar is opened
        useEffect(() => {
            /**
             * Handle the document click event to close the sidebar
             */
            const onPageClick = (event) => {
                // the user clicked inside the sidebar
                if (
                    event.target.closest(".sidebar") ||
                    event.target.closest(".mentions__suggestions") ||
                    event.target.closest(".ant-modal") ||
                    event.target.closest(".ant-select-dropdown") ||
                    event.target.closest(".ant-dropdown-menu-item")
                ) {
                    return;
                }

                // close the sidebar
                close();
            };

            if (state.visible && !state.inline && !state.fullScreen) {
                document.addEventListener("click", onPageClick, true);
            } else {
                document.removeEventListener("click", onPageClick, true);
            }

            // cleanup
            return function () {
                document.removeEventListener("click", onPageClick, true);
            };

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [state.visible, state.inline, state.fullScreen]);

        /**
         * Close the sidebar
         */
        const close = () => {
            state.hide();

            onClose && onClose();
            onChange && onChange();
        };

        /**
         * Toggle the collapsed state
         */
        const toggleCollapse = () => {
            state.collapsed = !state.collapsed;
            events.emit("sidebar.toggle", state.collapsed);
        };

        // Build the sidebar header. It is visible only when in full screen mode
        const Header = observer(() => {
            if (state.inline) {
                return;
            }

            return (
                <div className="header">
                    <div className="left">{title && <div className="title">{title}</div>}</div>
                    <div className="right">
                        <Button
                            icon={<CloseOutlined />}
                            type="icon"
                            className="close"
                            onClick={close}
                        />
                    </div>
                </div>
            );
        });

        // Build the sidebar footer
        const Footer = observer(() => {
            if (!footer) {
                return;
            }

            return <div className="footer">{footer}</div>;
        });

        return (
            <div
                className={classNames("sidebar-wrapper", className, {
                    hidden: !state.visible,
                    modal: !state.inline,
                    collapsed: state.collapsed,
                    "full-screen": state.fullScreen,
                })}
                {...rest}
            >
                <div className="backdrop"></div>

                {collapsible && (
                    <>
                        <div className="handle" onClick={toggleCollapse}>
                            <RightOutlined className="icon right" />
                            <LeftOutlined className="icon left" />
                        </div>
                        <div className="placeholder"></div>
                    </>
                )}

                <div
                    className={classNames("sidebar", { padding: padding })}
                    style={{ width: width, minWidth: width }}
                >
                    <Header />
                    <div className="body">{children}</div>
                    <Footer />
                </div>
            </div>
        );
    },
);

/**
 * Return the sidebar state
 */
export function useSidebar(initial) {
    const store = useLocalStore(() => ({
        fullScreen: false,
        visible: true,
        inline: true,
        collapsed: false,

        /** Show the sidebar */
        show() {
            this.visible = true;
        },

        /** Hide the sidebar */
        hide() {
            this.visible = false;
        },

        /** toggle the sidebar */
        toggle() {
            if (this.visible) {
                this.hide();
            } else {
                this.show();
            }
        },

        /** set the inline value */
        setInline(value) {
            this.inline = value;
            this.collapsed = false;
        },
    }));

    return initial || store;
}

export default Sidebar;
