import sessionStore from "@app/state/store/session";

export const CanDo = ({ children, feature }) => {
    try {
        if (sessionStore.can(feature)) {
            return children;
        }

        return null;
    } catch (e) {
        return null;
    }
};

export default CanDo;
