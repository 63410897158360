import React from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { Page } from "@app/components/page";
import { useSidebar } from "../page/sidebar";
import BookmarkList from "./bookmark/list";

import state from "@app/state/store/report/document/bookmarks";

const BookmarkSidebar = observer(
    ({ width = 400, document, onRestore, readonly = false, isFullPage = false, ...rest }) => {
        const sidebar = useSidebar();
        const history = useHistory();

        /**
         * Open a bookmark
         */
        const open = (bookmark) => {
            const document = bookmark.document;

            let link = isFullPage ? `/fp/r` : `/r`;
            if (bookmark.areas) {
                link =
                    link +
                    `/${state.project}/document/bookmarks/${document}` +
                    `?area=${JSON.stringify(bookmark.areas[0])}&bid=${bookmark._id}`;
            } else if (bookmark.excel) {
                link =
                    link +
                    `/${state.project}/document/bookmarks/${document}` +
                    `?excel=${JSON.stringify(bookmark.excel)}&bid=${bookmark._id}`;
            }

            history.push(link);
        };

        return (
            <Page.Sidebar
                className="bookmark-sidebar"
                sidebar={sidebar}
                width={width}
                collapsible={true}
                {...rest}
            >
                <BookmarkList onOpen={open} readonly={readonly} />
            </Page.Sidebar>
        );
    },
);

export default BookmarkSidebar;
