// inspired by from https://gist.github.com/spoike/9e258febc6a94f3e5ed3
import _ from "lodash";

const FPS = 20;

// throttle using lodash
var throttle = function (callback) {
    return _.throttle(callback, 1 / (FPS * 1000));
};

// check for requestAnimationFrame
if (window.requestAnimationFrame) {
    throttle = function (callback) {
        let mayUpdate = false;
        const update = function () {
            mayUpdate = true;
            window.requestAnimationFrame(update);
        };

        // initial update
        window.requestAnimationFrame(update);

        // the function called by, e.g. an input event
        return function () {
            var thisArg = this;
            // discard the invocation when mayUpdate
            // is false (i.e. is throttled)
            if (mayUpdate) {
                mayUpdate = false;
                return callback.apply(thisArg, arguments);
            }
        };
    };
}

export default throttle;
