import React from "react";
import { observer, useLocalStore } from "mobx-react";
import { DownOutlined, UpOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Tag } from "antd";
import UserChip from "@app/components/user/chip";

import Group from "@app/components/setup/group";

import "./group.scoped.scss";

const StageGroup = observer(({ title, users, complete, onAdd, onEdit, onDelete, onRemove }) => {
    const state = useLocalStore(() => ({
        opened: true,

        toggle() {
            this.opened = !this.opened;
        },
    }));

    const addUser = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        onAdd();
    };

    const removeUser = async (id, args = {}) => {
        onRemove(id);
    };

    const deleteStage = async () => {
        onDelete();
    };

    const count = users.length;

    return (
        <Group
            title={
                <div className="expand-header">
                    <div className="expand-toggle" onClick={state.toggle}>
                        <div className="toggle">
                            {state.opened ? <UpOutlined /> : <DownOutlined />}
                        </div>
                    </div>
                    {title} ({count})
                </div>
            }
            right={complete ? <Tag color="green">complete</Tag> : null}
            onEdit={complete ? undefined : onEdit}
            onAdd={complete ? undefined : addUser}
            onDelete={complete ? undefined : deleteStage}
        >
            {state.opened &&
                users.map((user) => {
                    return (
                        <div className="user" key={user._id}>
                            <UserChip user={user} />
                            <div className="buttons">
                                {!complete && (
                                    <Button
                                        onClick={() => removeUser(user._id)}
                                        type="text"
                                        danger
                                        icon={<DeleteOutlined />}
                                    ></Button>
                                )}
                            </div>
                        </div>
                    );
                })}
        </Group>
    );
});

export default StageGroup;
