import React from "react";
import { observer } from "mobx-react";
import { Button, Menu, Dropdown } from "antd";
import {
    SortAscendingOutlined,
    SortDescendingOutlined,
    UpOutlined,
    DownOutlined,
} from "@ant-design/icons";

import "./style/sort.scoped.scss";

const Sort = observer(({ filter }) => {
    const state = filter.state;

    // do not render the component if there are no sorting options
    if (!filter.options.sort.length) {
        return null;
    }

    /**
     * Update the state
     */
    const sort = (event) => {
        let option = filter.options.sort.find((option) => {
            return option.key === event.key;
        });

        if (option) {
            filter.sort(option.key, option.order);
        }
    };

    /**
     * Reverse the sorting value
     */
    const reverse = (value) => {
        if (state.sort.value) {
            state.sort.order = state.sort.order === 1 ? -1 : 1;
        }
    };

    // find the currently sorted option and extract the label
    // use the string "Sort" for default value
    const option = filter.options.sort.find((option) => {
        return option.key === state.sort.value;
    });
    const SortLabel = option?.label ?? "Sort";

    // prepare the sort icon based on the current sorting direction
    const SortIcon =
        state.sort.order === -1 ? <SortDescendingOutlined /> : <SortAscendingOutlined />;

    // create the sort menu with the options
    const SortMenu = (
        <Menu onClick={sort}>
            {filter.options.sort.map((option) => {
                return <Menu.Item key={option.key}>{option.label}</Menu.Item>;
            })}
        </Menu>
    );

    return (
        <div className="sort">
            <Button.Group>
                <Dropdown overlay={SortMenu} placement="bottomRight">
                    <Button icon={SortIcon}>{SortLabel}</Button>
                </Dropdown>
                <Button className="reverse" onClick={reverse}>
                    <div className="icon up">
                        <UpOutlined />
                    </div>
                    <div className="icon down">
                        <DownOutlined />
                    </div>
                </Button>
            </Button.Group>
        </div>
    );
});

export default Sort;
