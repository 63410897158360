import { action } from "mobx";
import { v4 as uuid } from "uuid";
import BaseStore from "@app/state/store/base";
import { events } from "@app/lib/store";
import http from "@app/lib/http";
import Project from "@app/state/model/project";
import Client from "@app/state/model/client";
import FilterState from "@app/components/filter/state";

const STAGGER_ID = uuid();

/**
 * State management controlling the list of projects in
 * the projects sections
 */
export class ProjectList extends BaseStore {
    filter = null;

    /**
     * Observable store data
     */
    observable() {
        return {
            list: [],
            clients: [],
            loading: false,
        };
    }

    constructor() {
        super();

        events.on("project.update", this.reload.bind(this));
        events.on("project.delete", this.reload.bind(this));
        events.on("project.clone", this.reload.bind(this));
        events.on("client.update", this.loadClients.bind(this));
        events.on("client.delete", this.loadClients.bind(this));

        this.filter = new FilterState({
            default: {
                search: "",
            },
        });

        this.filter.on("find", () => {
            this.load();
        });
    }

    /**
     * Load a list with projects from the backend
     */
    @action
    async load() {
        this.loading = true;

        let filter = this.filter.value();
        let { data } = await http.get("/projects", filter).stagger(STAGGER_ID);

        this.list = data.list.map((entry) => {
            return new Project(entry);
        });

        // update the query stats
        this.filter.stats(data.stats);
        this.loading = false;
    }

    /**
     * Reload the list
     */
    @action
    async reload() {
        return this.load();
    }

    /**
     * Remove a project
     */
    @action
    async remove(id) {
        this.loading = true;
        let { data } = await http.delete(`/project/${id}`);

        // emit the delete event
        let project = new Project(data);
        events.emit("project.delete", project);

        this.loading = true;
    }

    /**
     * Clone a project
     */
    @action
    async clone(id, options) {
        this.loading = true;
        let { data } = await http.post(`/project/${id}/clone`, { options });

        // emit the clone event
        let project = new Project(data);
        events.emit("project.clone", project);

        this.loading = true;
    }

    /**
     * Load the list of clients
     */
    @action
    async loadClients() {
        let { data } = await http.get("/clients");
        this.clients = data.list.map((entry) => {
            return new Client(entry);
        });
    }
}

export default new ProjectList();
