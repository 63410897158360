import React from "react";

export default function () {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            viewBox="0 0 32 32"
            className="icon"
        >
            <path
                fill="currentColor"
                d="m20.413 14.584l-7.997-7.997a2.002 2.002 0 0 0-2.832 0l-7.997 7.997a2.002 2.002
                0 0 0 0 2.832l3.291 3.292L3 22.585L4.414 24l1.879-1.878l3.291 3.291a2.002 2.002
                0 0 0 2.832 0l2.256-2.256l-1.416-1.415l-2.258 2.257l-7.997-7.997l7.997-8.001l8.001
                8.001L17.5 17.5l1.415 1.415l1.498-1.499a2.002 2.002 0 0 0 0-2.832Z"
            />
            <path
                fill="currentColor"
                d="m30.413 14.584l-3.291-3.292L29 9.415L27.586 8l-1.878 1.878l-3.292-3.291a2.002
                2.002 0 0 0-2.832 0l-2.256 2.256l1.415 1.414l2.255-2.256l8.001 8.001l-8.001
                7.997l-7.997-7.997l1.5-1.501l-1.416-1.416l-1.498 1.499a2.002 2.002 0 0 0 0
                2.832l7.997 7.997a2.002 2.002 0 0 0 2.832 0l7.997-7.997a2.002 2.002 0 0 0 0-2.832Z"
            />
        </svg>
    );
}
