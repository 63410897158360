import React from "react";
import { observer } from "mobx-react";
import format from "@app/lib/format";
import classNames from "classnames";

import { LanguageCodes, Search as Database } from "@app/constants";
import "./style/summary.scoped.scss";

const FiltersSummary = observer(({ search, display = "search", className, ...rest }) => {
    const {
        excludeBooks,
        humanOnly,
        expandSynonyms,
        minPublicationDate: minPubDate,
        maxPublicationDate: maxPubDate,
        minPublicationYear: minPubYear,
        maxPublicationYear: maxPubYear,
        publicationTypes,
        languageCode,
        source,
        maxResults,
        medline,
    } = search;

    const filterCriteria = [];

    if (languageCode?.length > 0) {
        const langs = languageCode.map((code) => LanguageCodes[code]).filter((lang) => !!lang);
        const label = languageCode.length > 1 ? "Languages" : "Language";
        filterCriteria.push(`${label}: ${langs.join(", ")}`);
    }

    // add the patents/citations
    if (source === Database.GOOGLE) {
        filterCriteria.push("Exclude Patents");
        filterCriteria.push("Exclude Citations");
    }

    if (humanOnly) {
        filterCriteria.push("Only human studies");
    }

    if (expandSynonyms) {
        filterCriteria.push("Expand MeSH synonyms");
    }

    if (medline) {
        filterCriteria.push("Include Medline");
    }

    if (source === Database.PUBMED && excludeBooks) {
        filterCriteria.push("Exclude Books");
    }

    if (publicationTypes?.length > 0) {
        filterCriteria.push({
            label: "Article type",
            children: publicationTypes,
        });
    }

    if (minPubDate && maxPubDate) {
        filterCriteria.push(`From ${format.date(minPubDate)} to ${format.date(maxPubDate)}`);
    } else if (minPubYear && maxPubYear) {
        filterCriteria.push(`From ${minPubYear} to ${maxPubYear}`);
    } else if (minPubDate) {
        filterCriteria.push(`Since ${format.date(minPubDate)}`);
    } else if (maxPubDate) {
        filterCriteria.push(`Prior to ${format.date(maxPubDate)}`);
    } else if (minPubYear) {
        filterCriteria.push(`Since ${minPubYear}`);
    } else if (maxPubYear) {
        filterCriteria.push(`Prior to ${maxPubYear}`);
    }

    if (maxResults) {
        filterCriteria.push(`Max Results: ${maxResults}`);
    }

    if (!filterCriteria.length) {
        return;
    }

    // default display = "search"; standard csv text
    return (
        <div
            className={classNames("filters", className, { report: display === "report" })}
            {...rest}
        >
            <label>Filters: </label>
            <span className="criteria">
                {filterCriteria.map((item, index) => {
                    if (item instanceof Object) {
                        return (
                            <em key={index} className="list">
                                <label>{item.label}</label>
                                <ul>
                                    {item.children.map((child, childIndex) => {
                                        return <li key={childIndex}>{child}</li>;
                                    })}
                                </ul>
                            </em>
                        );
                    }

                    return <em key={index}>{item}</em>;
                })}
            </span>
        </div>
    );
});

export default FiltersSummary;
