import React from "react";
import { Link } from "react-router-dom";

const Download = ({ file, children, ...props }) => {
    return (
        <Link to={`/api/file/${file}`} {...props} target="_blank">
            {children}
        </Link>
    );
};

export default Download;
