import { computed, action } from "mobx";
import BaseStore from "@app/state/store/base";
import http from "@app/lib/http";
import { events } from "@app/lib/store";
import notify from "@app/components/notify";
import Article from "@app/state/model/project/article";
import report from "../report";

export class OriginalArticleStore extends BaseStore {
    observable() {
        return {
            loading: false,
            saving: false,
            selectedArticles: [],
            suggestedArticles: [],
            searchArticles: [],
        };
    }

    constructor() {
        super();
        events.on("project.unload", () => {
            this.reset();
        });

        events.on("search.update", () => {
            this.reset();
        });
    }

    @computed get busy() {
        return this.loading || this.saving;
    }

    @computed get project() {
        return report.id;
    }

    @action
    async getOriginalArticleSuggestions({ search, duplicateArticleIds, type }) {
        this.loading = true;
        try {
            const { data } = await http.post(`/project/${this.project}/articles/original/search`, {
                search,
                duplicateArticleIds,
                type,
            });
            const { suggestedArticles, searchArticles } = data;
            this.suggestedArticles = suggestedArticles?.map((article) => new Article(article));
            this.searchArticles = searchArticles?.map((article) => new Article(article));
        } catch (ex) {
            notify.error(
                ex.response?.data?.error ||
                    "An error occurred while get original article suggestions",
            );
        } finally {
            this.loading = false;
        }
    }

    @action
    async setOriginalArticle({ originalArticleId, duplicateArticleIds }) {
        this.saving = true;
        try {
            await http.post(`/project/${this.project}/article/${originalArticleId}/original`, {
                duplicateArticleIds,
            });
        } catch (ex) {
            notify.error(
                ex.response?.data?.error ||
                    "An error occurred while trying set article as original",
            );
        } finally {
            this.saving = false;
        }
    }

    @action
    async findArticles(articleIds) {
        try {
            const { data } = await http.post(`/project/${this.project}/articles/search`, {
                articleIds,
            });
            this.selectedArticles = data.map((item) => new Article(item));
        } catch (ex) {
            notify.error(
                ex.response?.data?.error ||
                    "An error occurred while trying set article as original",
            );
        }
    }
}
export default new OriginalArticleStore();
