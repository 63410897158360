import Card from "@app/components/highlight/card";

import React from "react";
import { observer } from "mobx-react";

const Highlight = observer(({ data }) => {
    return (
        <div className="highlight" style={{ marginTop: 5 }}>
            <Card className="group" highlight={data} readonly={true} />
        </div>
    );
});

export default Highlight;
