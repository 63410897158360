import { Node, mergeAttributes } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

import Render from "./render";

import { getMarks } from "../utils/content";

export default Node.create({
    name: "dictionary",
    group: "inline",
    inline: true,
    atom: true,

    onBeforeCreate() {
        this.storage.store = this.options.dictionary || {};

        const readonly = !this.editor.options.editable;
        this.options.readonly = readonly;
        this.type.spec.selectable = !readonly;
    },

    addAttributes() {
        return {
            key: {
                default: null,
                required: true,
                parseHTML: (el) => el.getAttribute("data-key"),
                renderHTML: (attr) => ({ "data-key": attr.key }),
            },
            "data-diff-node": {
                default: null,
            },
            "data-diff-id": {
                default: null,
            },
            comment: {
                default: undefined,
            },
        };
    },

    addOptions() {
        return {
            HTMLAttributes: {},
            dictionary: {},
            readonly: false,
        };
    },

    addStorage() {
        return {
            store: {},
        };
    },

    parseHTML() {
        return [
            {
                tag: 'span[data-type="dictionary"]',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ["span", mergeAttributes(HTMLAttributes, { "data-type": "dictionary" })];
    },

    addNodeView() {
        return ReactNodeViewRenderer(Render);
    },

    addCommands() {
        return {
            /**
             * Adds a new dictionary entry to the content
             */
            addDictionaryEntry: (options, surroundSpace = false) => async ({
                editor,
                commands,
            }) => {
                const selection = editor.view.state.selection;
                const nodeBefore = selection.$from.nodeBefore;
                const nodeAfter = selection.$from.nodeAfter;
                const addSpaceBefore =
                    surroundSpace && nodeBefore && !nodeBefore.text?.endsWith(" ");
                const addSpaceAfter =
                    surroundSpace && nodeAfter && !nodeAfter.text?.startsWith(" ");
                const marks = getMarks({ editor });

                const content = [];
                if (addSpaceBefore) {
                    content.push({
                        type: "text",
                        text: " ",
                    });
                }

                content.push({
                    type: this.name,
                    attrs: options,
                    marks,
                });

                if (addSpaceAfter) {
                    content.push({
                        type: "text",
                        text: " ",
                    });
                }

                commands.insertContent(content);
            },

            /**
             * Updates the dictionary values
             */
            setDictionary: (dictionary) => async ({ editor }) => {
                editor.storage.dictionary.store = dictionary;
            },

            /**
             * Show a dictionary picker
             */
            showDictionaryPicker: () => async ({ editor }) => {
                editor.emit("dictionaryPicker");
            },

            /**
             * Create a dictionary entry
             */
            createDictionaryEntry: () => async ({ editor }) => {
                const { selection, doc } = editor.state;
                const text = doc.textBetween(selection.from, selection.to);

                editor.emit("dictionaryCreate", text);
            },
        };
    },

    addKeyboardShortcuts() {
        return {
            "Alt-/": () => {
                this.editor.commands.showDictionaryPicker();
            },
        };
    },
});
