import React, { useCallback, useEffect } from "react";
import { observer, useLocalStore } from "mobx-react";

import { ReportDocumentSection } from "@app/constants";

import SelectCustomObjectModal from "./custom-object-select-modal";

export const CustomObjectPlugin = observer(({ editor }) => {
    const state = useLocalStore(() => ({
        showEditModal: false,
        initialData: {},
        id: null,
    }));

    const onShowModal = useCallback(() => {
        state.showEditModal = true;
    });

    const onEditEntry = useCallback(({ metadata, id }) => {
        console.log(metadata, id);
        state.showEditModal = true;
        state.initialData = metadata;
        state.id = id;
    });

    const resetState = useCallback(() => {
        state.showEditModal = false;
        state.initialData = {};
        state.id = null;
    });

    useEffect(() => {
        if (!editor) {
            return;
        }

        editor.on(ReportDocumentSection.CUSTOM_OBJECT.SHOW_SELECT_MODAL_EVENT, onShowModal);
        editor.on(ReportDocumentSection.CUSTOM_OBJECT.SHOW_EDIT_MODAL_EVENT, onEditEntry);

        return () => {
            editor.off(ReportDocumentSection.CUSTOM_OBJECT.SHOW_SELECT_MODAL_EVENT, onShowModal);
            editor.on(ReportDocumentSection.CUSTOM_OBJECT.SHOW_EDIT_MODAL_EVENT, onEditEntry);
        };
    }, [editor]);

    /**
     * Insert entry
     */
    const onInsert = (metadata) => {
        if (state.id) {
            editor.chain().focus().editCustomObject({ id: state.id, metadata }).run();
        } else {
            editor.chain().focus().addCustomObject({ metadata }).run();
        }

        resetState();
    };

    return (
        <>
            {state.showEditModal && (
                <SelectCustomObjectModal
                    onInsert={onInsert}
                    onCancel={resetState}
                    initialData={state.initialData}
                    id={state.id}
                />
            )}
        </>
    );
});

export default CustomObjectPlugin;
