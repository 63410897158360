import React, { useCallback, useEffect, useState } from "react";
import { CaptionModal } from "@app/components/caption/modal";

export const EditCaptionModal = ({ editor }) => {
    const [editCaption, setEditCaption] = useState(false);
    const [caption, setCaption] = useState("");

    const onEditCaption = useCallback(({ node }) => {
        setEditCaption(true);
        setCaption(node.attrs.caption);
    });

    useEffect(() => {
        if (!editor || !editor.isEditable) {
            return;
        }

        editor.on("editTableCaption", onEditCaption);
        return () => editor.off("editTableCaption", onEditCaption);
    }, [editor]);

    const saveCaption = ({ caption }) => {
        setEditCaption(false);
        editor.commands.updateAttributes("table", { caption });
    };

    return (
        <CaptionModal
            title={"Edit Table Caption"}
            caption={caption}
            open={editCaption}
            onSubmit={saveCaption}
            onCancel={() => setEditCaption(false)}
        />
    );
};

export default EditCaptionModal;
