import React, { useMemo } from "react";
import { observer } from "mobx-react";

import CommentListener from "./components/comment-listener";

import commentStore from "@app/state/store/report-document/comment";

import "./style.scss";

const CommentPlugin = observer(({ editor, sectionId, section, readonly }) => {
    const { focusedComment, filteredComments } = commentStore;

    const sectionComments = useMemo(() => {
        return filteredComments.filter((comment) => comment.section === sectionId);
    }, [filteredComments]);

    return (
        <CommentListener
            editor={editor}
            sectionId={sectionId}
            section={section}
            sectionComments={sectionComments}
            focusedComment={focusedComment}
            readonly={readonly}
        />
    );
});

export default CommentPlugin;
