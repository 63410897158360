import { observable, computed } from "mobx";
import Model from "@app/state/model/base";
import { Review, SearchType } from "@app/constants";

export default class Article extends Model {
    @observable _id = undefined;
    @observable title = undefined;
    @observable authors = [];
    @observable publicationYear = undefined;
    @observable publicationMonth = undefined;
    @observable journalIssue = undefined;
    @observable journalName = undefined;
    @observable journalVolume = undefined;
    @observable pageNumbers = undefined;
    @observable file = undefined;
    @observable abstract = undefined;
    @observable snippets = [];
    @observable source = undefined;
    @observable link = undefined;
    @observable type = [];
    @observable isDownloading = false;
    @observable articleTags = [];
    @observable contentTags = [];
    @observable moved = undefined;
    @observable linkedArticle = undefined;
    @observable processing = {
        abstract: undefined,
        additionalData: undefined,
    };

    @observable review = {
        stage: undefined,
        l1: {
            status: undefined,
            fail: {
                reason: undefined,
                note: undefined,
            },
        },
        l2: {
            started: false,
            complete: false,
            score: 0,
            status: undefined,
            questions: [],
        },
        additionalData: [],
    };

    @observable ids = null;
    @observable $selected = false;

    /**
     * Return formatted value of the authors of the article.
     */
    @computed get team() {
        return this.authors.map(({ firstName, lastName }) => `${firstName} ${lastName}`).join(", ");
    }

    /**
     * Flag indicating if L1 is complete
     */
    @computed get slrL1Complete() {
        return !!this.review?.l1?.status && this.type === SearchType.SLR;
    }

    @computed get sotaL1Complete() {
        return !!this.review?.l1?.status && this.type === SearchType.SOTA;
    }

    /**
     * Flag indicating if the article passed L1
     */
    @computed get slrL1Passed() {
        return this.review?.l1?.status === Review.PASS && this.type === SearchType.SLR;
    }

    @computed get sotaL1Passed() {
        return this.review?.l1?.status === Review.PASS && this.type === SearchType.SOTA;
    }

    /**
     * Flag indicating if the article failed L1
     */
    @computed get slrL1Failed() {
        return this.review?.l1?.status === Review.FAIL && this.type === SearchType.SLR;
    }

    @computed get sotaL1Failed() {
        return this.review?.l1?.status === Review.FAIL && this.type === SearchType.SOTA;
    }

    /**
     * Flag indicating if L2 is complete
     */
    @computed get slrL2Complete() {
        return !!this.review?.l2?.status && this.type === SearchType.SLR;
    }

    @computed get sotaL2Complete() {
        return !!this.review?.l2?.status && this.type === SearchType.SOTA;
    }

    /**
     * Flag indicating if the article passed L2
     */
    @computed get slrL2Passed() {
        return this.review?.l2?.status === Review.PASS && this.type === SearchType.SLR;
    }

    @computed get sotaL2Passed() {
        return this.review?.l2?.status === Review.PASS && this.type === SearchType.SOTA;
    }

    /**
     * Flag indicating if the article failed L1
     */
    @computed get slrL2Failed() {
        return this.review?.l2?.status === Review.FAIL && this.type === SearchType.SLR;
    }

    @computed get sotaL2Failed() {
        return this.review?.l2?.status === Review.FAIL && this.type === SearchType.SOTA;
    }
}
