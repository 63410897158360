import { action, computed } from "mobx";
import { v4 as uuid } from "uuid";

import http from "@app/lib/http";
import notify from "@app/components/notify";
import FilterState from "@app/components/filter/state";
import AbbreviationEntry from "@app/state/model/abbreviation/entry";
import { events } from "@app/lib/store";

import BaseStore from "../../base";
import report from "../../report";
import reportDocumentStore from "../../report-document/report-documents";

const STGR_LOAD = uuid();

/**
 * State management controlling the list of users in
 * the user management sections
 */
export class Abbreviation extends BaseStore {
    filter = null;

    /**
     * Observable store data
     */
    observable() {
        return {
            list: [],
            loading: false,
            saving: false,
        };
    }

    constructor() {
        super();

        // handle abbreviation events
        events.on("abbreviation.update", () => this.load());
        events.on("abbreviation.updateTemplateValues", (entry) => this.loadTemplateValues(entry));
        events.on("abbreviation.delete", () => this.load());

        this.filter = new FilterState({
            default: { search: "" },
        });

        this.filter.on("find", () => {
            this.load();
        });

        events.on("project.unload", () => {
            this.reset();
        });
    }

    /**
     * Return the project id if the currently loaded project
     */
    @computed get project() {
        return report.id;
    }

    /**
     * Load the list of abbreviation entries from the backend
     */
    @action
    async load() {
        this.loading = true;

        let filter = this.filter.value();
        let { data } = await http
            .get(`/project/${this.project}/abbreviation`, filter)
            .stagger(STGR_LOAD);

        this.list = data.list.map((entry) => {
            return new AbbreviationEntry(entry);
        });

        this.loading = false;
    }

    /**
     * Load the list of abbreviation entries from the backend for template
     */
    @action
    async loadTemplateValues({ documentId }) {
        if (!documentId) {
            return;
        }

        this.loading = true;
        const { clientId } = reportDocumentStore;

        let filter = this.filter.value();
        let { data } = await http
            .get(`/client/${clientId}/template/${documentId}/abbreviation`, filter)
            .stagger(STGR_LOAD);

        this.list = data.list.map((entry) => {
            return new AbbreviationEntry(entry);
        });

        this.loading = false;
    }

    /**
     * Update an entry
     */
    @action
    async save(params) {
        if (!params || !this.project || this.saving) {
            return;
        }

        try {
            let result;

            this.saving = true;

            if (params._id) {
                result = await http.put(
                    `/project/${this.project}/abbreviation/${params._id}`,
                    params,
                );
            } else {
                result = await http.post(`/project/${this.project}/abbreviation`, params);
            }

            // emit the update event
            const entry = new AbbreviationEntry(result.data);
            events.emit("abbreviation.update", entry);
        } catch (ex) {
            notify.error(ex.response?.data?.error);
            return false;
        } finally {
            this.saving = false;
        }
    }

    /**
     * Update an entry for template
     */
    @action
    async saveTemplateEntry(documentId, params) {
        const { clientId } = reportDocumentStore;

        if (!params || !documentId || this.saving) {
            return;
        }

        try {
            let result;

            this.saving = true;

            if (params._id) {
                result = await http.put(
                    `/client/${clientId}/template/${documentId}/abbreviation/${params._id}`,
                    params,
                );
            } else {
                result = await http.post(
                    `/client/${clientId}/template/${documentId}/abbreviation`,
                    params,
                );
            }

            // emit the update event
            const entry = new AbbreviationEntry(result.data);
            events.emit("abbreviation.updateTemplateValues", entry);
        } catch (ex) {
            notify.error(ex.response?.data?.error);
            return false;
        } finally {
            this.saving = false;
        }
    }

    /**
     * Remove an entry
     */
    @action
    async remove(id) {
        this.loading = true;
        try {
            let { data } = await http.delete(`/project/${this.project}/abbreviation/${id}`);

            // emit the delete event
            let client = new AbbreviationEntry(data);
            events.emit("abbreviation.delete", client);
        } catch (ex) {
            if (ex.status === 409) {
                notify.error("Operation denied. The abbreviation is used in the project.");
            } else {
                notify.error("An error occurred");
            }
        }

        this.loading = false;
    }

    // validate save entry
    @action
    validateSave = (entry) => {
        const found = this.list.find((i) => i.name === entry.name && i._id !== entry._id);

        if (found) {
            notify.error("Abbreviation name already exists!");
            return false;
        }

        return true;
    };

    @action
    unload() {
        this.list = [];
    }
}

export default new Abbreviation();
