import { observable } from "mobx";
import Model from "@app/state/model/base";

export default class ReportDocument extends Model {
    @observable _id = undefined;
    @observable project = undefined;
    @observable client = undefined;
    @observable name = undefined;
    @observable status = undefined;
    @observable comments = undefined;
    @observable header = undefined;
    @observable footer = undefined;
    @observable review = undefined;
    @observable reviewConfig = {
        stages: [],
    };
    @observable metaData = undefined;
}
