import React, { useEffect } from "react";
import { observer } from "mobx-react";
import MultiSelectContext, { useContext, createState } from "./context";
import { Checkbox } from "antd";

import "./style/multi-select.scoped.scss";

const MultiSelect = observer(({ totalList, children }) => {
    const state = createState();

    const Wrapper = observer(({ totalList }) => {
        const { context } = useContext();
        const { setTotalList } = context;

        useEffect(() => {
            setTotalList(totalList);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [totalList]);

        return null;
    });

    return (
        <MultiSelectContext.Provider value={state}>
            <Wrapper totalList={totalList} />
            {children}
        </MultiSelectContext.Provider>
    );
});

const MultiSelectCheckBox = observer(({ id }) => {
    const { context } = useContext();
    const { isChecked, toggle } = context;

    const checked = isChecked(id);

    return (
        <span
            className="checkbox-container"
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                toggle(id);
            }}
        >
            <Checkbox checked={checked} />
        </span>
    );
});

export default MultiSelect;
export { MultiSelect, MultiSelectCheckBox };
