import React from "react";
import { observer } from "mobx-react";
import { Button, Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useContext } from "./context";

const MultiAction = observer(({ handleUpdate }) => {
    const { context } = useContext();
    const { selected, stats, selectAll, reset } = context;

    const ActionMenu = observer(({ selected, stats }) => {
        return (
            <Menu className="menu">
                <Menu.Item
                    key="selectAll"
                    onClick={selectAll}
                    disabled={stats.selected === selected?.length}
                >
                    Select All
                </Menu.Item>
                <Menu.Item
                    key="deselectAll"
                    onClick={reset}
                    disabled={stats.unSelected === selected?.length}
                >
                    Deselect All
                </Menu.Item>
                <Menu.Item key="update" onClick={handleUpdate} disabled={stats.selected === 0}>
                    Update
                </Menu.Item>
            </Menu>
        );
    });

    return (
        <div className="multi-action-container">
            <Dropdown
                overlay={<ActionMenu selected={selected} stats={stats} />}
                trigger="click"
                placement="bottomRight"
            >
                <Button>
                    Selection <DownOutlined />
                </Button>
            </Dropdown>
        </div>
    );
});

export default MultiAction;
