import React, { useEffect, useCallback, useRef, useState } from "react";
import classNames from "classnames";
import qs from "qs";
import _ from "lodash";
import { observer } from "mobx-react";
import { useLocation, useParams } from "react-router-dom";
import { Button, Dropdown, Menu, Tooltip } from "antd";
import {
    CheckOutlined,
    CommentOutlined,
    EditOutlined,
    EllipsisOutlined,
    EyeOutlined,
    LockOutlined,
    UndoOutlined,
    WarningOutlined,
} from "@ant-design/icons";

import { Editor } from "@app/components/report-document";
import Avatar from "@app/components/user/avatar";
import CanDo from "@app/components/can-do";
import { CommentStatus, ReportDocumentStatus, ReportDocumentSectionStatus } from "@app/constants";
import { antdHelpers } from "@app/util";
import contentHelper from "@app/components/report-document/editor/modules/utils/content";
import confirm from "@app/components/confirm";
import { getPageWidth } from "@app/components/report-document/editor/utils";

import sessionStore from "@app/state/store/session";
import reportDocumentsStore from "@app/state/store/report-document/report-documents";
import documentStore from "@app/state/store/report-document/document";
import sectionsStore from "@app/state/store/report-document/report-document-sections";
import commentStore from "@app/state/store/report-document/comment";
import dictionaryStore from "@app/state/store/report/dictionary/pick";
import abbreviationStore from "@app/state/store/report/abbreviation/pick";
import { CommentPlugin } from "@app/components/report-document/editor/modules/comment";

import "./styles/section.scoped.scss";

const SAVE_TIMEOUT = 3000;

const emptyContent = {
    type: "doc",
    content: [
        {
            type: "paragraph",
            content: [
                {
                    type: "text",
                    text: "The section is empty. Click the edit button above to edit its content.",
                },
            ],
            attrs: {
                textAlign: "center",
            },
        },
    ],
};

const Section = observer(
    ({
        section,
        editing: editingState,
        size = "A4",
        templateMode,
        documentId,
        readonly = false,
    }) => {
        const location = useLocation();
        const { project } = useParams();
        const ref = useRef(null);
        const sectionId = section._id;
        const [details, setDetails] = useState();
        const [editor, setEditor] = useState();
        const [editCurrentSection, setEditCurrentSection] = useState(false);
        const [editing, setEditing] = editingState;
        const prevEditingCurrentSection = useRef(editCurrentSection);
        const { divergedSectionIds } = sectionsStore;
        const isDiverged = divergedSectionIds.includes(sectionId);

        const autoSave = useCallback(
            _.debounce(
                (content) => {
                    if (!templateMode) {
                        sectionsStore.saveContent(section._id, content, {
                            projectId: project,
                            documentId,
                            section,
                        });
                    } else {
                        sectionsStore.saveTemplateContent(section._id, content, documentId);
                    }
                },
                SAVE_TIMEOUT,
                { trailing: true },
            ),
            [],
        );

        const isEditingSection = editing?.section._id === sectionId;
        const title = (section.displayPos ? `${section.displayPos} ` : "") + section.title;
        const width = getPageWidth(size);
        const reportDocument = reportDocumentsStore.reportDocument;
        const hasContent = Object.keys(details?.content ?? {}).length;
        const reviewStarted = reportDocument.review?.stage?.title;

        const accessSection = async () => {
            const lockedBy = await sectionsStore.isLocked(sectionId);

            if (_.isEmpty(lockedBy)) {
                await sectionsStore.updateLock(sectionId);
                return true;
            } else if (lockedBy?._id === sessionStore.user._id) {
                return true;
            } else {
                const requestLock = await confirm(
                    <p>
                        Section is currently locked by user {lockedBy.fullName}
                        <br />
                        Would you like to request the lock?
                    </p>,
                );

                if (requestLock) {
                    await sectionsStore.requestLock(sectionId);
                }
            }
        };

        const onEditSection = async () => {
            if (!templateMode) {
                const access = await accessSection(section._id);
                if (access) {
                    await load();
                    setEditing({ section });
                }
            } else {
                setEditing({ section });
            }
        };

        const onChange = async (editor) => {
            if (isEditingSection) {
                const json = editor.getJSON();
                const selection = editor.view.state.selection;

                sectionsStore.saved = false;
                autoSave(json);

                setDetails((state) => {
                    state.content = json;
                    return state;
                });

                editor.commands.setTextSelection(selection);
            }
        };

        const load = async () => {
            let object = {};

            if (templateMode && documentId) {
                object = await sectionsStore.loadTemplateDetails(section._id, documentId);
            } else {
                object = await sectionsStore.loadDetails(section._id);
            }

            documentStore.processSection(section._id, object.content);

            const { newContent } = contentHelper.sanitizeContent(object.content, {
                comments: commentStore.comments,
                abbreviations: abbreviationStore.list,
            });

            setDetails({ ...object, content: newContent });
        };

        const resetLock = async () => {
            const lockedBy = details?.lockedBy || section?.lockedBy;
            if (!_.isEmpty(lockedBy) && lockedBy?._id === sessionStore.user._id) {
                if (editor) {
                    await sectionsStore.saveContent(section._id, editor.getJSON(), {
                        projectId: project,
                        section,
                    });
                }
                await sectionsStore.releaseLock(section._id);
            }
        };

        const exitEdit = async () => {
            if (editor) {
                await sectionsStore.saveContent(section._id, editor.getJSON(), {
                    projectId: project,
                    section,
                });
                setEditing(null);
            }
        };

        useEffect(() => {
            if (section._id) {
                load();
            }

            //  when the same user has multiple tabs working on the same section.
            //  when a section is unlocked by one of the tab,
            //  we want to save the content and exit edit mode
            if (section.lockedBy?._id !== sessionStore.user._id && editCurrentSection) {
                exitEdit();
            }
        }, [section._id]);

        useEffect(() => {
            if (isEditingSection) {
                setEditCurrentSection(true);
            }

            if (editCurrentSection && !isEditingSection) {
                setEditCurrentSection(false);
                resetLock();
            }
        }, [editing]);

        useEffect(() => {
            if (ref && ref.current) {
                const params = qs.parse(location.search, { ignoreQueryPrefix: true });
                if (params.section === section._id) {
                    ref.current.scrollIntoView({
                        behavior: "smooth",
                    });
                }
            }
        }, [location]);

        useEffect(() => {
            if (!editor?.isEditable) {
                autoSave.cancel();
            }

            if (isEditingSection) {
                setEditing((value) => ({ ...value, editor }));
            }
        }, [editor]);

        useEffect(() => {
            // Get the previous value
            const wasEditing = prevEditingCurrentSection.current;

            const save = async (content) => {
                sectionsStore.saveTemplateContent(section._id, content, documentId);
            };

            // If the value has changed from true to false
            if (wasEditing && !editCurrentSection && details?.content && templateMode) {
                save(details?.content);
            }

            prevEditingCurrentSection.current = editCurrentSection;
        }, [editCurrentSection, details, templateMode]);

        if (!details) {
            return null;
        }

        const hasReviewers = details?.reviewers?.length > 0;
        const hasAnyReviewersApproved = details?.reviewers?.find((reviewer) => reviewer.reviewed);
        const userReportReviewer = reportDocumentsStore.getUserReviewer(
            reportDocument.review?.stage?.reviewers,
        );
        const userReviewer = reportDocumentsStore.getUserReviewer(details?.reviewers);

        const approveSection = async () => {
            // check for any pending comments
            const pendingComments = commentStore.comments?.filter(
                (comment) =>
                    comment.section === sectionId && comment.status === CommentStatus.PENDING,
            );

            if (pendingComments.length > 0) {
                const proceed = await confirm(
                    "There are still pending comments in this section, do you want to approve?",
                );

                if (!proceed) {
                    return;
                }
            }

            const updateSection = await sectionsStore.reviewSection(details._id);
            setDetails(updateSection);
        };

        const resetAllApprovals = async () => {
            const proceed = await confirm(
                "This will reset all the approvals for this stage and this section! " +
                    "Are you sure you want to proceed?",
            );

            if (proceed) {
                const updatedSectionReviewers = details.reviewers.map((reviewer) => {
                    reviewer.reviewed = false;
                    return reviewer;
                });

                await sectionsStore.update(details._id, {
                    reviewers: updatedSectionReviewers,
                });

                const updatedReportReviewers = reportDocument.review.stage.reviewers.map(
                    (reviewer) => {
                        reviewer.reviewed = false;
                        return reviewer;
                    },
                );

                await reportDocumentsStore.update(reportDocument._id, {
                    review: { stage: { reviewers: updatedReportReviewers } },
                });
            }
        };

        const readyForReview = async () => {
            const updateSection = await sectionsStore.update(details._id, {
                status: ReportDocumentSectionStatus.READY_FOR_REVIEW,
            });
            setDetails(updateSection);
        };

        const resetMyApproval = async () => {
            const proceed = await confirm(
                "This will reset your approval for this stage and this section! " +
                    "Are you sure you want to proceed?",
            );

            if (proceed) {
                const updatedSectionReviewers = details.reviewers.map((reviewer) => {
                    if (reviewer._id === sessionStore.user._id) {
                        reviewer.reviewed = false;
                    }
                    return reviewer;
                });

                const updateSection = await sectionsStore.update(details._id, {
                    reviewers: updatedSectionReviewers,
                });

                setDetails(updateSection);

                let updateReport = false;
                const updatedReportReviewers = reportDocument.review.stage.reviewers.map(
                    (reviewer) => {
                        if (reviewer._id === sessionStore.user._id && reviewer.reviewed) {
                            reviewer.reviewed = false;
                            updateReport = true;
                        }
                        return reviewer;
                    },
                );

                updateReport &&
                    (await reportDocumentsStore.update(reportDocument._id, {
                        review: { stage: { reviewers: updatedReportReviewers } },
                    }));
            }
        };

        const resetReviewStatus = async () => {
            const updateSection = await sectionsStore.update(details._id, {
                status: ReportDocumentSectionStatus.IN_PROGRESS,
            });
            setDetails(updateSection);
        };

        const onMenu = async (action) => {
            if (action.key === "resetReviewStatus") {
                await resetReviewStatus();
            } else if (action.key === "resetMyApproval") {
                await resetMyApproval();
            } else if (action.key === "resetAllApprovals") {
                await resetAllApprovals();
            }
        };

        const onDiscardLocalCopy = async () => {
            sectionsStore.discardLocal(sectionId);
        };

        const onAddCommentToSection = () => {
            commentStore.openAddCommentModal({ isSectionComment: true, sectionId });
        };

        const menuItems = !readonly && (
            <Menu onClick={onMenu} className="menu">
                {[ReportDocumentStatus.NOT_STARTED, ReportDocumentStatus.IN_PROGRESS].includes(
                    reportDocument.status,
                ) &&
                    !reviewStarted &&
                    details.status === ReportDocumentSectionStatus.READY_FOR_REVIEW && (
                        <Menu.Item key="resetReviewStatus" icon={<UndoOutlined />}>
                            Reset review status
                        </Menu.Item>
                    )}

                {reportDocument.status === ReportDocumentStatus.IN_PROGRESS &&
                    userReviewer?.reviewed && (
                        <Menu.Item key="resetMyApproval" icon={<UndoOutlined />}>
                            Reset my approval for this section
                        </Menu.Item>
                    )}

                {reportDocument.status === ReportDocumentStatus.IN_PROGRESS &&
                    hasAnyReviewersApproved && (
                        <Menu.Item key="resetAllApprovals" icon={<UndoOutlined />}>
                            Reset all approvals for this section
                        </Menu.Item>
                    )}
            </Menu>
        );

        return (
            <div
                className={classNames("section", {
                    fade: editing && !isEditingSection,
                    editing: isEditingSection,
                })}
                style={{ width: width }}
                ref={ref}
            >
                <div className={classNames("header")}>
                    <div className="title" data-section-id={sectionId}>
                        <div>{title}</div>
                        {hasAnyReviewersApproved && (
                            <div className="reviewers">
                                {details?.reviewers.map((reviewer) =>
                                    reviewer.reviewed ? (
                                        <Tooltip
                                            title={`Approved by ${reviewer.fullName}`}
                                            key={reviewer._id}
                                        >
                                            <Avatar className="avatar" user={reviewer} size={24} />
                                        </Tooltip>
                                    ) : null,
                                )}
                            </div>
                        )}

                        {!!section.lockedBy && (
                            <div className="lockedBy">
                                <div className="vl" />
                                <Tooltip title={`Section locked by ${section.lockedBy.fullName}`}>
                                    <Avatar className="avatar" user={section.lockedBy} size={24} />
                                </Tooltip>
                            </div>
                        )}
                    </div>

                    <div className="right">
                        {isDiverged && !readonly && (
                            <Tooltip
                                title={`Unable to sync locally stored changes. There is a newer version of content on server. Please copy your changes, discard local version by clicking this button.`}
                            >
                                <Button
                                    type="default"
                                    icon={<WarningOutlined />}
                                    onClick={onDiscardLocalCopy}
                                    style={{ marginRight: "5px", color: "red" }}
                                ></Button>
                            </Tooltip>
                        )}
                        {!isEditingSection && !readonly && (
                            <>
                                {!templateMode && (
                                    <Tooltip title="Comment on Section">
                                        <Button
                                            type="default"
                                            icon={<CommentOutlined />}
                                            onClick={onAddCommentToSection}
                                            style={{ marginRight: "5px" }}
                                        ></Button>
                                    </Tooltip>
                                )}
                                {!templateMode &&
                                    details.status !==
                                        ReportDocumentSectionStatus.READY_FOR_REVIEW && (
                                        <Tooltip title="Ready for review">
                                            <Button
                                                type="default"
                                                icon={<EyeOutlined />}
                                                onClick={readyForReview}
                                                style={{ marginRight: "5px" }}
                                            ></Button>
                                        </Tooltip>
                                    )}

                                {hasReviewers && (
                                    <>
                                        {reportDocument.status ===
                                            ReportDocumentStatus.IN_PROGRESS &&
                                            details.status ===
                                                ReportDocumentSectionStatus.READY_FOR_REVIEW &&
                                            !userReportReviewer?.reviewed &&
                                            userReviewer &&
                                            !userReviewer.reviewed && (
                                                <Tooltip title="Approve Section">
                                                    <Button
                                                        type={"primary"}
                                                        icon={<CheckOutlined />}
                                                        onClick={approveSection}
                                                        style={{ marginRight: "5px" }}
                                                    ></Button>
                                                </Tooltip>
                                            )}
                                    </>
                                )}

                                <CanDo feature="section.edit">
                                    <Button
                                        icon={
                                            section.lockedBy ? (
                                                <Tooltip
                                                    title={section.lockedBy.fullName}
                                                    placement="bottom"
                                                >
                                                    <LockOutlined />
                                                </Tooltip>
                                            ) : (
                                                <EditOutlined />
                                            )
                                        }
                                        disabled={
                                            !section ||
                                            reportDocument.status === ReportDocumentStatus.REVIEWED
                                        }
                                        onClick={onEditSection}
                                    ></Button>
                                </CanDo>

                                {antdHelpers.hasMenuItems(menuItems) && (
                                    <Dropdown
                                        overlay={menuItems}
                                        trigger="click"
                                        placement="bottomRight"
                                    >
                                        <Button
                                            type="default"
                                            icon={<EllipsisOutlined />}
                                            style={{ border: "none" }}
                                        />
                                    </Dropdown>
                                )}
                            </>
                        )}
                    </div>
                </div>

                <div className={classNames("section-content")}>
                    {details?.content && !hasContent && !isEditingSection && (
                        <Editor
                            readonly={true}
                            value={emptyContent}
                            onChange={() => {}}
                            onEditorReady={setEditor}
                            metadata={{
                                sectionId: section._id,
                                pos: section.pos,
                            }}
                            dictionary={dictionaryStore}
                            abbreviation={abbreviationStore}
                            project={project}
                            templateMode={false}
                        />
                    )}

                    {(hasContent || isEditingSection) && (
                        <Editor
                            readonly={!isEditingSection || readonly}
                            value={details?.content}
                            onChange={onChange}
                            onEditorReady={setEditor}
                            metadata={{
                                sectionId: section._id,
                                pos: section.pos,
                            }}
                            dictionary={dictionaryStore}
                            abbreviation={abbreviationStore}
                            project={project}
                            templateMode={templateMode}
                        />
                    )}

                    <CommentPlugin
                        editor={editor}
                        sectionId={sectionId}
                        section={section}
                        readonly={!isEditingSection || readonly}
                    />
                </div>
            </div>
        );
    },
);

Section.displayName = "Section";

export default Section;
