import React from "react";
import { observer } from "mobx-react";
import Criteria from "@app/components/setup/score/criteria";
import "./style/scoring-criteria.scoped.scss";

const ScoringCriteria = observer(({ data }) => {
    return (
        <div className="scoring-criteria">
            <div>
                {data?.maybe?.label} : {data?.maybe?.enabled === true ? "Yes" : "No"}
            </div>
            <div>
                <Criteria score={data?.include} label={data?.include.label} />
                {data?.maybe?.enabled && (
                    <Criteria score={data?.maybe} label={data?.maybe?.label} />
                )}
                <Criteria score={data?.exclude} label={data?.exclude?.label} />
            </div>
        </div>
    );
});

export default ScoringCriteria;
