import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { Button, Form, Input } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import { Page } from "@app/components/page";
import Box from "@app/components/box/box";
import notify from "@app/components/notify";

import client from "@app/state/store/clients/details";
import "../style/client.scoped.scss";
import _ from "lodash";

const EditConfiguration = observer(({ className, ...props }) => {
    const [form] = Form.useForm();
    const { id, configuration } = useParams();

    useEffect(() => {
        (async () => {
            let data;

            if (configuration) {
                await client.load(id);
                const arr = client.data.configurations.filter((item) => item._id === configuration);
                data = { name: arr[0].name };
            }

            form.setFieldsValue(data);
        })();
    }, [form, id, configuration]);

    // return empty page while loading
    if (client.data?._id !== id) {
        return null;
    }

    /**
     * Save the client
     * @param {Object} data
     */
    const save = async (data) => {
        const configurations = _.cloneDeep(client.data.configurations);
        const name = data.name.trim();

        let duplicate = false;
        duplicate = configurations.some((entry) => {
            return entry.name.toLowerCase() === name.toLowerCase() && entry._id !== configuration;
        });

        if (duplicate) {
            notify.error(
                "Another configuration with this name already exists. Please modify your input and try again",
            );

            return;
        }

        if (configuration) {
            let entry = configurations.find((item) => {
                return item._id === configuration;
            });

            if (entry) {
                entry.name = name;
            }
            if (entry._id) {
                delete entry._id;
            }

            await client.updateConfiguration({
                _id: id,
                configuration: configuration,
                ...entry,
            });

            close();
        } else {
            await client.updateConfiguration({
                _id: id,
                data: {
                    name: name,
                },
            });

            const configurations = client.data.configurations;
            const config = configurations.find((entry) => {
                return entry.name === name;
            });

            if (config) {
                props.history.push(`/clients/${client.data._id}/view/config/${config._id}/setup`);
            } else {
                close();
            }
        }
    };

    /**
     * Close the page
     */
    const close = () => {
        props.history.push(`/clients/${client.data._id}/view/config`);
    };

    /**
     * Show the validation error
     */
    const validationError = () => {
        notify.error("Please fill all required fields to continue");
    };

    return (
        <Page className={`general ${className}`}>
            <Page.Header>
                <Page.Header.Left>
                    <Page.Title> {configuration ? "Edit " : "Add "} Configuration</Page.Title>
                </Page.Header.Left>
                <Page.Header.Right>
                    <Button
                        type="icon"
                        loading={client.busy}
                        onClick={close}
                        icon={<CloseOutlined />}
                    ></Button>
                </Page.Header.Right>
            </Page.Header>
            <Page.Body filter={client.filter} maxWidth={800}>
                <Box className={`general ${className}`}>
                    <Form
                        layout="vertical"
                        form={form}
                        onFinishFailed={validationError}
                        onFinish={save}
                    >
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter a configuration name",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Form>
                    <div className="button-group">
                        <Button type="primary" onClick={() => form.submit()}>
                            Save
                        </Button>
                        <Button onClick={close}>Cancel</Button>
                    </div>
                </Box>
            </Page.Body>
        </Page>
    );
});

export default EditConfiguration;
