import React from "react";
import { observer } from "mobx-react";

import "./style/empty.scoped.scss";

const EmptyList = observer(({ title, show = false, children }) => {
    if (!show) {
        return null;
    }

    return (
        <div className="empty-list">
            <div className="image">
                <img src={require("@app/assets/empty.svg")} width="80" />
            </div>
            <div className="title">{title}</div>
            <div className="text">{children}</div>
        </div>
    );
});

export default EmptyList;
