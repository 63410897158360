import React, { useEffect } from "react";
import { observer, useLocalStore } from "mobx-react";
import Modal from "@app/components/modal";

import Content from "./chunks/content";
import TagPicker from "./chunks/picker";

import "./style/add.scoped.scss";

export const HighlightModal = observer(({ visible, highlight, onSave, onDiscard }) => {
    const state = useLocalStore(() => ({
        tags: [],
        content: "",
    }));

    useEffect(() => {
        state.tags = highlight?.tags || [];
        if (highlight?.content) {
            state.content = highlight.content;
        }
    }, [state, highlight]);

    // do not render the modal without a highlight
    if (!highlight) {
        return;
    }

    /**
     * Handle the OK button press and validate the form
     */
    const save = () => {
        const newHighlight = { ...highlight, tags: state.tags, content: state.content };
        onSave && onSave(newHighlight);
        state.tags = [];
        state.content = "";
    };

    /**
     * Close the modal
     */
    const cancel = () => {
        onDiscard && onDiscard();
        state.tags = [];
    };

    /**
     * Update the highlight tags
     */
    const updateTags = (tags) => {
        state.tags = tags;
    };

    return (
        <Modal
            title="Do you want to save this highlight?"
            visible={visible}
            onOk={save}
            onCancel={cancel}
            width={800}
        >
            <div className="add-highlight">
                <Content
                    content={state.content}
                    onChange={(content) => (state.content = content)}
                />
                <TagPicker value={state.tags} onChange={updateTags} />
            </div>
        </Modal>
    );
});

export default HighlightModal;
