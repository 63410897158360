import React from "react";
import { observer } from "mobx-react";
import { MarkdownViewer } from "@app/components/markdown-editor";
import "./style/abstract.scoped.scss";

const Abstract = observer(({ data }) => {
    return (
        <div className="abstract">
            <MarkdownViewer content={data} />
        </div>
    );
});

export default Abstract;
