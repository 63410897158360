/**
 * Base convenience class for a data model
 */
export default class Model {
    constructor(data) {
        if (data !== undefined) {
            this.set(data);
        }
    }

    /**
     * Update the object from provided data
     * @param {Object} data
     */
    set(data) {
        for (let key in data) {
            this[key] = data[key];
        }
    }
}
