import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import Component from "./node";

export default Node.create({
    name: "page-break",
    group: "block",
    inline: false,
    selectable: false,

    addAttributes() {
        return {
            text: {
                default: "Page Break",
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: "p",
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ["p", HTMLAttributes];
    },

    addNodeView() {
        return ReactNodeViewRenderer(Component);
    },

    addCommands() {
        return {
            /**
             * Adds a page break entry to the content
             */

            addPageBreakEntry: (option) => ({ commands, tr, dispatch }) => {
                const depth = tr.selection.$cursor?.depth ?? 1;

                // do not allow adding it in content nodes
                if (depth > 2 || !tr.selection.$cursor) {
                    return false;
                }

                // allow it only in landscape node at depth 2
                else if (depth === 2) {
                    const node = tr.selection.$cursor.node(1);
                    if (node.type.name !== "landscape") {
                        return false;
                    }
                }

                if (dispatch) {
                    const content = [];
                    content.push({
                        type: this.name,
                        attrs: { text: option?.text },
                    });

                    content.push({ type: "paragraph", content: [] });
                    commands.insertContent(content);
                }

                return true;
            },
        };
    },
});
