import { action } from "mobx";
import BaseStore from "@app/state/store/base";
import http from "@app/lib/http";
import { events } from "@app/lib/store";
import Client from "@app/state/model/client";

/**
 * State management controlling the list of clients used
 * to generate a client selection dropdown
 */
export class ClientPick extends BaseStore {
    loaded = false;

    /**
     * Observable store data
     */
    observable() {
        return {
            list: [],
            loading: false,
        };
    }

    constructor() {
        super();

        events.on("client.update", this.reload.bind(this));
        events.on("client.delete", this.reload.bind(this));
        events.on("auth.logout", this.unload.bind(this));
    }

    /**
     * Load a list with all clients from the backend
     */
    @action
    async load() {
        if (this.loading || this.loaded) {
            return;
        }

        this.loading = true;
        this.list = [];

        let { data } = await http.get("/clients");
        this.list = data.list.map((entry) => {
            return new Client(entry);
        });

        this.loading = false;
        this.loaded = true;
    }

    /**
     * Reload the list
     */
    async reload() {
        // if the list is not loaded do not reload it
        if (!this.loaded) {
            return;
        }

        this.loaded = false;
        return this.load();
    }

    @action
    async unload() {
        // so that when another user logs in, client load API will get invoked.
        this.loaded = false;
    }
}

export default new ClientPick();
