import React from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { Page } from "@app/components/page";
import { Link } from "react-router-dom";
import { SearchType } from "@app/constants";

import "./style/common.scoped.scss";
import "./style/report-styles.scss";

const SLR = observer(() => {
    const { type } = useParams();
    const title = type === SearchType.SLR ? "DUE" : "SoTA";

    return (
        <Page className="setup">
            <Page.Header title={title} />
            <Page.Body maxWidth={800}>
                <div className="section">
                    <div className="header">{`${title} Reports`}</div>
                    <div className="body">
                        <Link to="report/exclusion" className="link">
                            L1 Exclusion Summary
                        </Link>
                        <Link to="report/scoring" className="link">
                            L2 Screening Summary
                        </Link>
                        <Link to="report/article-flow" className="link">
                            Article Flow
                        </Link>
                        {type === SearchType.SLR && (
                            <Link to="report/performance" className="link">
                                Clinical Safety and Performance Summary
                            </Link>
                        )}
                        <Link to="report/searches" className="link">
                            Search Parameters
                        </Link>
                        <Link to="report/articles" className="link">
                            Literature Search Results and Analysis (Appendix)
                        </Link>
                    </div>
                </div>

                <div className="section">
                    <div className="header">Highlights</div>
                    <div className="body">
                        <Link to="report/highlights" className="link">
                            Article Highlights
                        </Link>
                    </div>
                </div>
            </Page.Body>
        </Page>
    );
});

export default SLR;
