import DefaultBulletList from "@tiptap/extension-bullet-list";
import cn from "classnames";

import "./style.scss";

const BulletList = DefaultBulletList.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            helpText: {
                default: false,
                parseHTML: (element) => element.classList.contains("help-text"),
            },
            hasComment: {
                default: false,
            },
            class: {
                default: undefined,
                renderHTML: (attributes) => {
                    return {
                        class: cn({
                            "help-text": attributes.helpText,
                            "has-comment": attributes.hasComment,
                        }),
                    };
                },
            },
        };
    },
});

export default BulletList;
