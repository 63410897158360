import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import moment from "moment";
import { Box } from "@app/components/box";
import { Button, Form, Input, Select, DatePicker } from "antd";
import { SearchOutlined, HistoryOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import notify from "@app/components/notify";

import query from "@app/state/store/report/query";
import setup from "@app/state/store/projects/setup";
import adverseEvents from "@app/state/store/report/adverse-events";
import tplcState from "@app/state/store/report/output/tplc";
import { EventTypes } from "@app/constants";
import format from "@app/lib/format";
import { events } from "@app/lib/store";

import "./style/search-criteria.scoped.scss";

const SEARCH_TERM_KEYS = [
    "brandName",
    "eventType",
    "manufacturerName",
    "modelNumber",
    "productCodes",
    "reportNumber",
    "startDate",
    "endDate",
];

const SearchCriteria = observer(({ className, ...rest }) => {
    const [form] = Form.useForm();

    const [allBrands, setAllBrands] = React.useState([]);
    const [showHistory, setShowHistory] = React.useState(false);
    const [allManufacturers, setAllManufacturers] = React.useState([]);
    const config = setup.config?.risk || {};
    const productCodes = config.productCodes || [];

    const loadHistory = async () => {
        await adverseEvents.loadHistory();
    };

    useEffect(() => {
        loadHistory();
        events.on("adverse-events.load", loadHistory);

        return () => {
            events.removeListener("adverse-events.load", loadHistory);
        };
    }, []);

    useEffect(() => {
        (async () => {
            if (adverseEvents.project) {
                setup.load(adverseEvents.project);
            }

            // configuration is not loaded
            if (!productCodes.length) {
                return;
            }

            const blackList = [".", "", "*"];
            const filterBlackLists = (array) => array.filter((item) => !blackList.includes(item));

            const { manufacturerNames, brandNames } = await tplcState.options();
            setAllBrands(filterBlackLists(brandNames).sort());
            setAllManufacturers(filterBlackLists(manufacturerNames).sort());

            const startDate = config.startDate ? moment(config.startDate) : undefined;
            const endDate = config.endDate ? moment(config.endDate) : undefined;

            form.setFieldsValue({
                eventType: "",
                modelNumber: "",
                productCodes: [],
                reportNumber: "",
                startDate: startDate,
                endDate: endDate,
            });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, config.productCodes]);

    /**
     * Prevents the exporting of a set of results when the search criteria have been changed,
     * otherwise a different set of results from what the user is seeing would be exported
     */
    const preventExport = async () => {
        adverseEvents.exportable = false;
    };

    /**
     * Perform a search
     */
    const doSearch = async (data, saveHistory) => {
        const searchCriteria = {};
        for (const key of SEARCH_TERM_KEYS) {
            const value = data[key];
            if (value) {
                // string value
                if (typeof value === "string") {
                    searchCriteria[key] = value.trim();
                }

                // momentjs value
                else if (value.isValid?.()) {
                    let inputDate = value.toDate();

                    if (key === "startDate") {
                        inputDate = moment(inputDate).startOf("day").format("YYYY-MM-DD");
                    }
                    if (key === "endDate") {
                        inputDate = moment(inputDate).startOf("day").format("YYYY-MM-DD");
                    }

                    searchCriteria[key] = inputDate;
                }

                // array values
                else if (value instanceof Array && value.length) {
                    searchCriteria[key] = value;
                }
            }
        }

        adverseEvents.pager.currentPage = 1;
        adverseEvents.searchCriteria = searchCriteria;
        await adverseEvents.loadEvents(saveHistory);
    };

    /**
     * Show the validation error
     */
    const error = () => {
        notify.error("Unable to perform search");
    };

    /**
     * Trigger the search if the user presses the Enter key
     */
    const onKeyDown = (event) => {
        if (event.key === "Enter") {
            if (!query.busy) {
                event.preventDefault();
                form.submit();
            }
        }
    };

    const Filter = ({ list, ...props }) => {
        return (
            <Select
                mode="multiple"
                maxTagCount={2}
                allowClear
                autoClearSearchValue={false}
                className="select"
                {...props}
            >
                {list.map((entry, index) => {
                    return (
                        <Select.Option value={entry} key={entry + index}>
                            {entry}
                        </Select.Option>
                    );
                })}
            </Select>
        );
    };

    const hasHistory = adverseEvents.history.length > 0;

    /**
     * Toggle the history widget
     */
    const toggleHistory = () => {
        setShowHistory(!showHistory);
    };

    const loadCriteria = (record) => {
        const {
            brandName,
            eventType,
            manufacturerName,
            modelNumber,
            productCodes,
            reportNumber,
        } = record.searchTerms;

        const startDate = record.searchTerms.startDate
            ? moment(record.searchTerms.startDate)
            : undefined;
        const endDate = record.searchTerms.endDate ? moment(record.searchTerms.endDate) : undefined;

        const data = {
            brandName,
            eventType,
            manufacturerName,
            modelNumber,
            productCodes,
            reportNumber,
            startDate,
            endDate,
        };

        // populate the form
        form.setFieldsValue(data);

        // close the history drawer
        toggleHistory();
        doSearch(data, false);
    };

    const SearchTerms = observer(({ searchTerms }) => {
        const { brandName, manufacturerName, productCodes } = searchTerms;

        return (
            <div className="searchTerms">
                <div>Product Codes: {productCodes.join(", ")}</div>
                <div>Brand Name: {brandName.join(", ")}</div>
                <div>Manufacturer Name: {manufacturerName.join(", ")}</div>
            </div>
        );
    });

    return (
        <Box className={classNames("search-criteria", className)} {...rest}>
            <Form
                layout="vertical"
                form={form}
                onFinishFailed={error}
                onFinish={doSearch}
                onValuesChange={preventExport}
            >
                <div className="row input-fields items-center">
                    <div className="col input-field large">
                        <Form.Item label="Brand Name" name="brandName">
                            <Filter list={allBrands} />
                        </Form.Item>
                    </div>
                    <div className="col input-field large">
                        <Form.Item label="Manufacturer Name" name="manufacturerName">
                            <Filter list={allManufacturers} />
                        </Form.Item>
                    </div>
                    <div className="col input-field">
                        <Form.Item label="Event Type" name="eventType">
                            <Select allowClear>
                                {EventTypes.map((type) => {
                                    return (
                                        <Select.Option key={type} value={type}>
                                            {type}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col input-field">
                        <Form.Item label="Model Number" name="modelNumber">
                            <Input onKeyDown={onKeyDown} />
                        </Form.Item>
                    </div>
                    <div className="col input-field">
                        <Form.Item label="Report Number" name="reportNumber">
                            <Input onKeyDown={onKeyDown} />
                        </Form.Item>
                    </div>
                    <div className="col input-field">
                        <Form.Item label="Product Codes" name="productCodes">
                            <Select mode="multiple" allowClear>
                                {productCodes.map((code) => {
                                    return (
                                        <Select.Option key={code} value={code}>
                                            {code}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col input-field">
                        <Form.Item label="From Date" name="startDate">
                            <DatePicker
                                style={{ width: "100%" }}
                                disabledDate={(date) => date.isAfter(form.getFieldValue("endDate"))}
                            />
                        </Form.Item>
                    </div>
                    <div className="col input-field">
                        <Form.Item label="To Date" name="endDate">
                            <DatePicker
                                style={{ width: "100%" }}
                                disabledDate={(date) =>
                                    date.isBefore(form.getFieldValue("startDate"))
                                }
                            />
                        </Form.Item>
                    </div>
                </div>
            </Form>

            <div className="row controls">
                {hasHistory && (
                    <div
                        className={classNames("history-toggle col auto", {
                            active: showHistory,
                        })}
                    >
                        <Button icon={<HistoryOutlined />} onClick={toggleHistory}>
                            {showHistory ? <UpOutlined /> : <DownOutlined />}
                        </Button>
                    </div>
                )}
                <div className="button col text-right">
                    <div className="button-group">
                        <Button
                            type="primary"
                            onClick={form.submit.bind(form)}
                            icon={<SearchOutlined />}
                        >
                            {"Search"}
                        </Button>
                    </div>
                </div>
            </div>

            {showHistory && (
                <div className="history">
                    <div className="header">Search History</div>
                    <div className="body">
                        {adverseEvents.history.map((record) => (
                            <div
                                key={record._id}
                                className="entry"
                                onClick={() => {
                                    loadCriteria(record);
                                }}
                            >
                                <div className="results">
                                    {record.results} {"result(s)"}
                                </div>
                                <div className="time">on {format.datetime(record.created)}</div>
                                <div className="performedBy">by {record.performedBy.fullName}</div>
                                <SearchTerms searchTerms={record.searchTerms} />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </Box>
    );
});

export default SearchCriteria;
