import Highlight from "@tiptap/extension-highlight";

import documentStore from "@app/state/store/report-document/document";

export default Highlight.extend({
    priority: 10000,

    addKeyboardShortcuts() {
        return {
            "Mod-Shift-h": () =>
                this.editor.commands.toggleHighlight({ color: documentStore.highlight }),
        };
    },
});
