import React from "react";

export default function () {
    return (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M25.8368 17.7578L10.4268 10.0307C10.3642 9.9994 10.2923 9.99203 10.2241 10.0086C10.1485 10.0273 10.0833 10.0752 10.0429 10.1419C10.0025 10.2085 9.9902 10.2885 10.0086 10.3642L11.5966 16.8532C11.6205 16.9508 11.6924 17.03 11.7882 17.0614L14.5091 17.9955L11.79 18.9296C11.6942 18.9627 11.6224 19.0401 11.6003 19.1378L10.0086 25.6359C9.99203 25.7041 9.9994 25.776 10.0307 25.8368C10.1026 25.9823 10.2794 26.0413 10.4268 25.9694L25.8368 18.2866C25.8939 18.2589 25.9399 18.211 25.9694 18.1557C26.0413 18.0084 25.9823 17.8315 25.8368 17.7578ZM11.825 23.7898L12.7516 20.0018L18.1898 18.1355C18.2322 18.1207 18.2672 18.0876 18.2819 18.0434C18.3077 17.966 18.2672 17.8831 18.1898 17.8554L12.7516 15.9909L11.8287 12.2177L23.3977 18.0194L11.825 23.7898Z"
                fill="#7284BD"
            />
        </svg>
    );
}
