import React, { useState } from "react";
import PrivacyModal from "./modal/privacy-modal";
import TermsAndConditionsModal from "./modal/terms-and-conditions-modal";

export default function Footer({ children }) {
    const [privacyModalIsOpen, setPrivacyModalIsOpen] = useState(false);
    const [termsModalIsOpen, setTermsModalIsOpen] = useState(false);

    const year = new Date().getFullYear();

    return (
        <div className="footer">
            <PrivacyModal
                visible={privacyModalIsOpen}
                footer={null}
                onCancel={() => setPrivacyModalIsOpen(false)}
            />

            <TermsAndConditionsModal
                visible={termsModalIsOpen}
                footer={null}
                onCancel={() => setTermsModalIsOpen(false)}
            />

            <div className="links">
                <button onClick={() => setPrivacyModalIsOpen(!privacyModalIsOpen)}>
                    Privacy policy
                </button>

                <button onClick={() => setTermsModalIsOpen(!termsModalIsOpen)}>
                    Terms & Conditions
                </button>
            </div>
            <div className="copyright">©{year} Celegence Inc. All rights reserved</div>
            <div className="celegence">
                <img src={require("@app/assets/celegence.svg")} />
            </div>
        </div>
    );
}
