import BaseStore from "../base";
import { observable, computed, action } from "mobx";
import format from "@app/lib/format";
import _ from "lodash";
import { ReportTypes } from "@app/constants";

import report from "@app/state/store/report";

export class DocumentStore extends BaseStore {
    sections = [];
    sectionMap = {};
    citationData = {};
    refCount = {};

    observable() {
        return {
            documentId: null,
            figureMap: {},
            tableMap: {},
            refMap: {},
            figures: [],
            tables: [],
            refs: [],
            reports: {},
            highlight: "yellow",
            loaded: false,
        };
    }

    get globalIndex() {
        return report.config?.reportDocuments?.style?.captionIndex === "global";
    }

    /**
     * Update the current active document
     */
    init(documentId, sections) {
        if (this.documentId === documentId) {
            return;
        }

        this.documentId = documentId;
        this.sections = [];
        this.figureMap = {};
        this.tableMap = {};
        this.refMap = {};
        this.figures = [];
        this.tables = [];
        this.refs = [];
        this.refCount = {};
        this.reports = {};

        const styleConfig = report.config?.reportDocuments?.style ?? {};
        const withDots = styleConfig.sectionNumbering === "withDots";

        // process the sections
        let prefix = 1;
        for (const section of sections) {
            // update the top level prefix
            if (!section.parent) {
                const match = section.displayPos.match(/^(\d+)\./);
                if (match) {
                    prefix = match[1];
                }
            }

            const titlePrefix = section.displayPos.replace(/\.$/, "");
            const fullTitle = withDots
                ? `${titlePrefix}. ${section.title}`
                : `${titlePrefix} ${section.title}`;

            const data = {
                _id: section._id,
                title: section.title,
                prefix: prefix,
                number: section.displayPos,
                fullTitle,
                figures: [],
                tables: [],
                refs: [],
            };

            this.sections.push(data);
            this.sectionMap[data._id] = data;

            this.loaded = true;
        }
    }

    /**
     * Process a section content
     */
    processSection(sectionId, data) {
        const section = this.sectionMap[sectionId];
        if (!section) {
            throw new Error(`Processing unknown section ${sectionId}`);
        }

        const nodes = this.flatten(data?.content ?? []);
        const prefix = this.globalIndex ? "" : section.prefix;

        // find all figures
        const figures = nodes
            .filter((node) => {
                if (!node.attrs?.error && node.attrs?.caption) {
                    if ("image" === node.type || "image" === node.attrs?.dataType) {
                        return true;
                    }
                }
                return false;
            })
            .map((figure) => {
                const { id, caption } = figure.attrs;

                const entry = new FigureEntry();
                entry.id = id;
                entry.caption = caption;
                entry.sectionPrefix = prefix;

                this.figureMap[id] = entry;
                return entry;
            });

        // find all tables
        const tables = nodes
            .filter((node) => {
                if (!node.attrs?.error && node.attrs?.caption) {
                    if ("table" === node.type || "table" === node.attrs?.dataType) {
                        return true;
                    }
                }
                return false;
            })
            .map((table) => {
                const { id, caption } = table.attrs;

                const entry = new TableEntry();
                entry.id = id;
                entry.caption = caption;
                entry.sectionPrefix = prefix;

                this.tableMap[id] = entry;
                return entry;
            });

        // find all references
        const refs = nodes
            .filter((node) => node.type === "citation")
            .map((ref) => {
                const { id } = ref.attrs;
                let entry = this.refMap[id];

                if (!entry) {
                    entry = new ReferenceEntry();
                    entry.id = id;
                    entry.data = this.citationData[id];

                    this.refMap[id] = entry;
                }

                return entry;
            });

        // find all reports
        nodes
            .filter((node) => node.type === "report")
            .forEach((node) => {
                // only create a default when it doesn't exist
                if (!this.reports[node.attrs.id]) {
                    if (node.attrs.reportType === ReportTypes.APPENDIX) {
                        this.reports[node.attrs.id] = { rows: 1 };
                    } else {
                        this.reports[node.attrs.id] = { rows: 20 };
                    }
                }
            });

        // save section tables and figures
        section.figures = figures;
        section.tables = tables;
        section.refs = refs;

        // update the captions
        this.updateFigures();
        this.updateTables();
        this.updateRefs();
    }

    /**
     * Update the figure captions
     */
    updateFigures() {
        let count = {};
        let list = [];
        let global = 1;

        // update numbering
        for (const section of this.sections) {
            if (!count[section.prefix]) {
                count[section.prefix] = 1;
            }

            for (const figure of section.figures) {
                if (this.globalIndex) {
                    figure.index = global++;
                } else {
                    figure.index = count[section.prefix]++;
                }
            }

            list.push(...section.figures);
        }

        if (!_.isEqual(this.figures, list)) {
            this.figures = list;
        }
    }

    /**
     * Update the table captions
     */
    updateTables() {
        let count = {};
        let list = [];
        let global = 1;

        for (const section of this.sections) {
            if (!count[section.prefix]) {
                count[section.prefix] = 1;
            }

            for (const table of section.tables) {
                if (this.globalIndex) {
                    table.index = global++;
                } else {
                    table.index = count[section.prefix]++;
                }
            }

            list.push(...section.tables);
        }

        if (!_.isEqual(this.tables, list)) {
            this.tables = list;
        }
    }

    /**
     * Update the references
     */
    updateRefs() {
        let list = [];
        let used = {};
        let index = 1;

        for (const section of this.sections) {
            for (const ref of section.refs) {
                if (!used[ref.id]) {
                    list.push(ref);
                    used[ref.id] = true;

                    if (ref.isValid) {
                        ref.index = index++;
                    }
                }
            }
        }

        if (!_.isEqual(this.refs, list)) {
            this.refs = list;
        }
    }

    /**
     * Flattens a tree of nodes
     */
    flatten(tree) {
        const reducer = (result, value) => {
            result.push(value);

            if (value.content?.length) {
                const nodes = value.content.reduce(reducer, []);
                result.push(...nodes);
            }

            return result;
        };

        return tree.reduce(reducer, []);
    }

    /**
     * Return the figure details by id
     */
    figureDetails(id) {
        return this.figureMap[id];
    }

    /**
     * Return the table details by id
     */
    tableDetails(id) {
        return this.tableMap[id];
    }

    /**
     * Update a figure caption
     */
    updateFigureCaption(id, caption) {
        const figure = this.figureMap[id];
        if (figure) {
            figure.caption = caption;
        }
    }

    /**
     * Update a table caption
     */
    updateTableCaption(id, caption) {
        const table = this.tableMap[id];
        if (table) {
            table.caption = caption;
        }
    }

    /**
     * Update citation data
     */
    @action
    setCitations(data) {
        this.citationData = data;
        this.refs.map((ref) => {
            const entry = data[ref.id];
            if (entry) {
                ref.data = entry;
            }
        });

        this.updateRefs();
    }
}

class FigureEntry {
    id = undefined;
    sectionPrefix = "";

    @observable index = "";
    @observable caption = "";

    get prefix() {
        if (this.sectionPrefix) {
            return `Figure ${this.sectionPrefix}-${this.index}`;
        }

        return `Figure ${this.index}`;
    }

    get fullCaption() {
        return `${this.prefix}: ${format.caption(this.caption)}`;
    }
}

class TableEntry {
    id = undefined;
    sectionPrefix = "";

    @observable index = "";
    @observable caption = "";

    get prefix() {
        if (this.sectionPrefix) {
            return `Table ${this.sectionPrefix}-${this.index}`;
        }

        return `Table ${this.index}`;
    }

    get fullCaption() {
        return `${this.prefix}: ${format.caption(this.caption)}`;
    }
}

class ReferenceEntry {
    id = undefined;
    @observable index = 0;
    @observable data = undefined;

    @computed get isValid() {
        return !!this.data;
    }

    @computed get file() {
        return this.data?.file;
    }

    @computed get formatted() {
        if (!this.data) {
            return "";
        }

        const refStyle = report?.config?.reference?.style;
        return `${this.index}. ${format.reference(this.data, refStyle)}`;
    }
}

export default new DocumentStore();
