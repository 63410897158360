import React from "react";
import { observer } from "mobx-react";

import abbreviationStore from "@app/state/store/report/abbreviation/pick";

const AbbreviationsTable = ({ usedAbbreviations, name, text }) => {
    if (!usedAbbreviations.length || !Array.isArray(usedAbbreviations)) {
        return <div className="empty">No abbreviations used in document</div>;
    }

    return (
        <table>
            {name && text && (
                <thead>
                    <tr>
                        <th>{name}</th>
                        <th>{text}</th>
                    </tr>
                </thead>
            )}
            <tbody>
                {usedAbbreviations.map((abbreviation) => (
                    <tr key={abbreviation.name}>
                        <td>{abbreviation.name}</td>
                        <td>{abbreviation.text}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

const Abbreviations = observer(({ metadata }) => {
    const { usedAbbreviations } = abbreviationStore ?? {};
    const { name, text } = metadata;

    return (
        <div className="content-list">
            <AbbreviationsTable usedAbbreviations={usedAbbreviations} name={name} text={text} />
        </div>
    );
});

export default Abbreviations;
