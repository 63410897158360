import { useEffect } from "react";
import { Node } from "prosemirror-model";
import * as htmlDiff from "node-htmldiff";

import versionStore from "@app/state/store/report-document/version";
import documentStore from "@app/state/store/report-document/document";

import {
    getVersionedTableCaptions,
    getHtmlString,
    updateWrappedTags,
    preProcessImageTags,
    postProcessTags,
} from "../diff-utility";

export const useDiff = ({ editor, left, right, sectionId, hasLoaded }) => {
    useEffect(() => {
        if (editor && left && right && hasLoaded) {
            const iRight = getVersionedTableCaptions(left, right);

            const docOld = Node.fromJSON(editor.schema, left);
            const docNew = Node.fromJSON(editor.schema, iRight);

            if (!docNew.content) {
                docNew.content = [];
            }

            const oldHtml = getHtmlString(editor.schema, docOld.content);
            const newHtml = getHtmlString(editor.schema, docNew.content);

            const imageMap = new Map();
            const { htmlString: oldHtmlProcessed } = preProcessImageTags(oldHtml, imageMap, true);
            const { htmlString: newHtmlProcessed } = preProcessImageTags(newHtml, imageMap, false);

            const diff = htmlDiff(
                oldHtmlProcessed,
                newHtmlProcessed,
                null,
                null,
                "iframe,object,math,svg,script,video,head,style,img",
            );

            const processedDiffHtml = postProcessTags(diff, imageMap);

            const { html, tableList } = updateWrappedTags(processedDiffHtml);

            editor.commands.setContent(html);
            versionStore.updateChangeCounter();

            //manually set data-diff-id and data-diff-node attributes on tables
            tableList.forEach(({ tableId, diffId, change }) => {
                const table = document.querySelector(`[data-table-id="${tableId}"]`);

                if (table) {
                    table.setAttribute("data-diff-id", diffId);
                    table.setAttribute("data-diff-node", change);
                    table.classList.add(`diff-${change}`);
                }
            });

            const content = editor.getJSON();
            documentStore.processSection(sectionId, content);
        }
    }, [editor, left, right, hasLoaded]);
};

export default useDiff;
