export default class ArticlePager {
    static use(instance) {
        instance.hasNextArticle = this.hasNextArticle.bind(instance);
        instance.hasPreviousArticle = this.hasPreviousArticle.bind(instance);

        instance.getNextArticle = this.getNextArticle.bind(instance);
        instance.getPreviousArticle = this.getPreviousArticle.bind(instance);
    }

    static hasNextArticle() {
        let index = this.list.findIndex((item) => item._id === this.article);

        if (index < this.list.length - 1) {
            return true;
        }

        if (this.filter[this.type].hasNextPage) {
            return true;
        }

        return false;
    }

    static hasPreviousArticle() {
        let index = this.list.findIndex((item) => item._id === this.article);

        if (index > 0) {
            return true;
        }

        if (this.filter[this.type].hasPreviousPage) {
            return true;
        }

        return false;
    }

    static async getNextArticle() {
        let index = this.list.findIndex((item) => item._id === this.article);

        if (index === this.list.length - 1) {
            this.filter[this.type].suspend();
            this.filter[this.type].next();

            await this.load();
            this.filter[this.type].resume();

            index = -1;
        }

        return this.list[index + 1]._id;
    }

    static async getPreviousArticle() {
        let index = this.list.findIndex((item) => item._id === this.article);

        if (index <= 0 && this.filter[this.type].hasPreviousPage) {
            this.filter[this.type].suspend();
            this.filter[this.type].prev();

            await this.load();
            this.filter[this.type].resume();

            index = this.list.length;
        }

        return this.list[index - 1]._id;
    }
}
