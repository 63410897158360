import React, { useEffect } from "react";
import { observer } from "mobx-react";

import AIChatHistory from "./ai-chat-history";
import AIChatDialogue from "./ai-chat-dialogue";

import aiStore from "@app/state/store/ai";

import "./style/ai-chat.scoped.scss";

const AIChat = observer(({ source, readonly, enableDialogue = false }) => {
    useEffect(() => {
        aiStore.getChatHistory(source);

        return () => {
            aiStore.chatHistory = [];
        };
    }, [source._id]);

    const submitDialogue = async (message) => {
        if (!message) {
            return;
        }

        await aiStore.question(source, message);
    };

    return (
        <div className="aiChatContainer">
            <AIChatHistory history={aiStore.chatHistory} />
            {!readonly && enableDialogue && (
                <AIChatDialogue onSubmit={submitDialogue} loading={aiStore.loading} />
            )}
        </div>
    );
});

export default AIChat;
