import React, { useEffect } from "react";
import { observer, useLocalStore } from "mobx-react";
import { useParams } from "react-router-dom";
import http from "@app/lib/http";
import canvasDatagrid from "canvas-datagrid";
import notify from "@app/components/notify";
import classes from "classnames";
import { default as BookmarkModal } from "@app/components/document/bookmark/modal";
import { default as CommentsModal } from "@app/components/comments/modal";
import { events } from "@app/lib/store";
import { ReportDocumentSection } from "@app/constants";

import "./style/excel-viewer.scoped.scss";

const ExcelViewer = observer(
    ({
        readOnly = false,
        fileId,
        options = {},
        goto,
        onBookmark,
        onComment,
        onCommentClick,
        comments = [],
    }) => {
        const { project, document: documentId } = useParams();

        const state = useLocalStore(() => ({
            canvasGrid: null,
            workbook: [],
            currentWorksheet: null,
            sheetNames: [],
            bookmark: null,
            comment: null,
            showCommentModal: false,
            commentsMap: {},
            stylesMap: [],
        }));

        const resetCanvasWidth = () => {
            const myTimer = setTimeout(() => {
                const documentViewer = document.getElementById("document-viewer");
                if (state.canvasGrid?.style && documentViewer) {
                    const newHeight = `${documentViewer.clientHeight - 50}px`;
                    const newWidth = `${documentViewer.clientWidth}px`;
                    if (state.canvasGrid.style.height !== newHeight) {
                        state.canvasGrid.style.height = newHeight;
                    }
                    if (state.canvasGrid.style.width !== newWidth) {
                        state.canvasGrid.style.width = newWidth;
                    }
                }

                clearTimeout(myTimer);
            }, [200]);
        };

        const jumpToCell = ({ columnIndex, rowIndex, sheet: sheetName }) => {
            setCurrentWorksheet(sheetName);
            const myTimer = setTimeout(() => {
                state.canvasGrid.gotoCell(columnIndex, rowIndex);
                state.canvasGrid.setActiveCell(columnIndex, rowIndex);
                state.canvasGrid.selectArea({
                    top: rowIndex,
                    bottom: rowIndex,
                    left: columnIndex,
                    right: columnIndex,
                });
                state.canvasGrid.draw();
                clearTimeout(myTimer);
            }, 200);
        };

        useEffect(() => {
            state.commentsMap = {};
            if (comments.length > 0) {
                comments.forEach((comment) => {
                    const excel = comment.excel;
                    if (excel) {
                        state.commentsMap[
                            `${excel.sheet}-${excel.columnIndex}-${excel.rowIndex}`
                        ] = comment;
                    }
                });
            }
        }, [comments.length]);

        useEffect(() => {
            if (state.canvasGrid && goto) {
                const myInterval = setInterval(function () {
                    if (state.canvasGrid.data) {
                        jumpToCell(goto);
                        clearInterval(myInterval);
                    }
                }, 200);
            }
        }, [state.canvasGrid, goto]);

        useEffect(() => {
            events.on("sidebar.toggle", resetCanvasWidth);
            events.on("navigation.toggle", resetCanvasWidth);

            const viewer = document.getElementById("excel-viewer");
            const grid = canvasDatagrid({
                parentNode: viewer,
                editable: false,
                allowSorting: false,
                hideColumnText: "",
                showClearSettingsOption: false,
                selectionMode: "cell",
                style: {
                    cellHeight: 25,
                },
                ...options,
            });

            const isValidCellSelected = () => {
                const rowsValues = Object.values(grid.selectedCells);
                const singleCell =
                    rowsValues.length === 1 && Object.values(rowsValues[0]).length === 1;

                return singleCell;
            };

            const contextMenuFunc = (e) => {
                e.items.splice(0, e.items.length);

                if (!readOnly) {
                    if (isValidCellSelected()) {
                        onBookmark &&
                            e.items.push({
                                title: "Create Bookmark",
                                click: function (ev) {
                                    state.bookmark = {
                                        excel: {
                                            ...grid.activeCell,
                                            sheet: state.currentWorksheet[0],
                                        },
                                    };
                                },
                            });

                        // check if a comment already exist on cell
                        // only single comment can be associated to a cell
                        const commentKey = `${state.currentWorksheet[0]}-${grid.activeCell.columnIndex}-${grid.activeCell.rowIndex}`;
                        if (!state.commentsMap[commentKey]) {
                            onComment &&
                                e.items.push({
                                    title: "Create Comment",
                                    click: function (ev) {
                                        state.comment = {
                                            excel: {
                                                ...grid.activeCell,
                                                sheet: state.currentWorksheet[0],
                                            },
                                        };
                                        state.showCommentModal = true;
                                    },
                                });
                        }

                        e.items.push({
                            title: "Copy Content Link",
                            click: function (ev) {
                                const data = {};
                                const currentCell = grid.currentCell;

                                if (currentCell) {
                                    data["text/plain"] = new Blob([currentCell.value], {
                                        type: "text/plain",
                                    });

                                    if (documentId && project) {
                                        const sourceDocumentLinkTag =
                                            ReportDocumentSection.CAPTIS_LINK.SOURCE_DOCUMENT_LINK;

                                        const excelData = {
                                            ...grid.activeCell,
                                            sheet: state.currentWorksheet[0],
                                        };
                                        const excel = JSON.stringify(excelData);

                                        data["text/html"] = new Blob(
                                            [
                                                `<${sourceDocumentLinkTag} project=${project} documentId=${documentId} excel='${excel}'>${currentCell.value}</${sourceDocumentLinkTag}>`,
                                            ],
                                            { type: "text/html" },
                                        );
                                    }

                                    navigator.clipboard.write([new window.ClipboardItem(data)]);
                                }
                            },
                        });
                    }
                }
            };

            const renderCellFunc = (e) => {
                if (state.currentWorksheet && state.stylesMap.length > 0) {
                    if (e.cell.isNormal) {
                        e.cell.value = e.value ?? "";

                        const style = state.stylesMap?.[e.cell.rowIndex]?.[e.cell.columnIndex];
                        let html = "";
                        let values = "";
                        let borderStyle = "";

                        const createFontValue = (font, value) => {
                            let result = "";

                            result += '<span style=" ';
                            if (font?.bold) {
                                result += "font-weight: bold; ";
                            }
                            if (font?.italic) {
                                result += "font-style: italic; ";
                            }
                            if (font?.color) {
                                result += `color: ${font.color}; `;
                            }

                            let textDecoration = "text-decoration: ";
                            if (font?.strike) {
                                textDecoration += "line-through ";
                            }
                            if (font?.underline) {
                                textDecoration += "underline ";
                            }
                            textDecoration += "; ";
                            result += textDecoration;

                            if (font?.vertAlign === "superscript") {
                                result += "vertical-align: super; ";
                            }
                            if (font?.vertAlign === "subscript") {
                                result += "vertical-align: sub; ";
                            }

                            let sanitisedValue = value
                                .replace(/[\u00A0-\u9999<>&]/g, (i) => "&#" + i.charCodeAt(0) + ";")
                                .replaceAll("\n", "<br />");

                            result += `">${sanitisedValue}</span>`;
                            return result;
                        };

                        if (style) {
                            if (style.richText?.length > 0) {
                                style.richText.forEach((s) => {
                                    values += createFontValue(s.font, s.text, true);
                                });
                            } else if (e.cell?.value) {
                                values = createFontValue(style.font, e.cell.value);
                            }

                            if (style.fill?.color) {
                                e.ctx.fillStyle = style.fill.color;
                            }
                            if (style.border?.bottom) {
                                borderStyle += `border-bottom: 1px solid ${style.border.bottom}; `;
                            }
                            if (style.border?.left) {
                                borderStyle += `border-left: 1px solid ${style.border.left}; `;
                            }
                            if (style.border?.right) {
                                borderStyle += `border-right: 1px solid ${style.border.right}; `;
                            }
                            if (style.border?.top) {
                                borderStyle += `border-top: 1px solid ${style.border.top}; `;
                            }

                            const createHtml = (child = "") => {
                                return (
                                    `<div style="display: flex; height: ${
                                        e.cell.height - 3
                                    }px; width: ${e.cell.width - 3}px; ${borderStyle}">` +
                                    `<div style="font-family: Helvetica, Arial, sans-serif; font-size: 16px; margin: 4px 4px;">` +
                                    values +
                                    "</div>" +
                                    child +
                                    "</div>"
                                );
                            };

                            const commentKey = `${state.currentWorksheet[0]}-${e.cell.columnIndex}-${e.cell.rowIndex}`;
                            if (state.commentsMap[commentKey]) {
                                // custom styling for rendering the cell with comment
                                html = createHtml(
                                    `<div style="height: 10px; width: 10px; border-top: 3px solid #f8b816; border-right: 3px solid #f8b816; position: absolute; left: ${
                                        e.cell.width - 14
                                    }px;"/>`,
                                );
                            } else {
                                html = createHtml();
                            }

                            e.cell.innerHTML = html;
                        }
                    }
                }
            };

            const clickFunc = (e) => {
                const commentKey = `${state.currentWorksheet[0]}-${e.cell.columnIndex}-${e.cell.rowIndex}`;
                const comment = state.commentsMap[commentKey];
                onCommentClick && onCommentClick(comment);
            };

            const copyFunc = (e) => {
                if (grid.selections?.length === 0) {
                    e.preventDefault();
                }
            };

            const styleChangedFunc = (e) => {
                resetCanvasWidth();
            };

            grid.addEventListener("contextmenu", contextMenuFunc);
            grid.addEventListener("rendercell", renderCellFunc);
            grid.addEventListener("click", clickFunc);
            grid.addEventListener("copy", copyFunc);
            grid.addEventListener("stylechanged", styleChangedFunc);

            state.canvasGrid = grid;
            resetCanvasWidth();

            return () => {
                events.removeListener("sidebar.toggle", resetCanvasWidth);
                events.removeListener("navigation.toggle", resetCanvasWidth);

                grid.removeEventListener("contextmenu", contextMenuFunc);
                grid.removeEventListener("rendercell", renderCellFunc);
                grid.removeEventListener("click", clickFunc);
                grid.removeEventListener("copy", copyFunc);
                grid.removeEventListener("stylechanged", styleChangedFunc);
            };
        }, []);

        useEffect(() => {
            if (fileId) {
                const load = async () => {
                    try {
                        const { data } = await http.get(`/excel/${fileId}`);
                        if (data.length > 0) {
                            state.workbook = data;
                            state.sheetNames = data.map((sheet) => sheet[0]);
                            state.currentWorksheet = data[0];
                        }
                    } catch (ex) {
                        notify.error("Cannot retrieve excel data!");
                    }
                };

                load();
            }
        }, [fileId]);

        useEffect(() => {
            if (state.canvasGrid && state.currentWorksheet) {
                state.canvasGrid.data = state.currentWorksheet[1];
                state.stylesMap = state.currentWorksheet[2];
            }
        }, [state.currentWorksheet]);

        const setCurrentWorksheet = (sheetName) => {
            if (sheetName !== state.currentWorksheet[0]) {
                const sheet = state.workbook.find((sheet) => sheetName === sheet[0]);
                if (sheet) {
                    state.currentWorksheet = sheet;
                }
            }
        };

        return (
            <div>
                <div id="excel-viewer" className="excel-viewer"></div>
                <div className="sheet-tabs">
                    {state.sheetNames.map((name) => (
                        <button
                            key={name}
                            className={classes({
                                activeTab:
                                    state.currentWorksheet && name === state.currentWorksheet[0],
                            })}
                            onClick={() => setCurrentWorksheet(name)}
                        >
                            <span>{name}</span>
                        </button>
                    ))}
                </div>

                <BookmarkModal
                    visible={!!state.bookmark}
                    bookmark={state.bookmark}
                    onSave={(data) => {
                        state.bookmark = null;
                        onBookmark && onBookmark(data);
                    }}
                    onDiscard={() => {
                        state.bookmark = null;
                    }}
                />
                <CommentsModal
                    modalOpen={state.showCommentModal}
                    setModalOpen={(value) => (state.showCommentModal = value)}
                    onSubmitHandler={(data) => {
                        onComment && onComment({ ...data, ...state.comment });
                        state.comment = null;
                    }}
                />
            </div>
        );
    },
);

export default ExcelViewer;
