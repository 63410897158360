import { computed, action } from "mobx";
import { v4 as uuid } from "uuid";
import BaseStore from "@app/state/store/base";
import http from "@app/lib/http";
import User from "@app/state/model/user";
import { events } from "@app/lib/store";

const STAGGER_ID = uuid();

/**
 * State management controlling the project details
 */
export class AssignedUsers extends BaseStore {
    /**
     * Observable store data
     */
    observable() {
        return {
            loading: false,
            saving: false,
            project: null,
            users: [],
        };
    }

    constructor() {
        super();

        events.on("project.unload", () => {
            this.reset();
        });
    }

    @computed get busy() {
        return this.loading || this.saving;
    }

    /**
     * Load assigned users to a project
     */
    @action
    async load(project, args = {}) {
        if (this.project === project && args.force !== true) {
            return;
        }

        this.project = project;
        this.loading = true;

        let { data } = await http.get(`/project/${project}/assigned`).stagger(STAGGER_ID);
        this.users = data.map((entry) => {
            return new User(entry);
        });

        this.loading = false;
    }

    /**
     * Get assigned users from a project
     */
    @action
    async getAssignedUsers(project) {
        if (!project) {
            return;
        }

        let { data } = await http.get(`/project/${project}/assigned`);
        return data.map((entry) => {
            return new User(entry);
        });
    }

    /**
     * Assign a list of users to the project
     */
    @action
    async assign(list, projectRole) {
        if (!this.project || this.saving) {
            return;
        }

        this.saving = true;
        let users = list.map((entry) => ({
            _id: entry._id,
            projectRole,
        }));

        try {
            await http.put(`/project/${this.project}/assigned`, { users });

            // events.emit("project.update", this.data);
            await this.load(this.project, { force: true });
        } finally {
            this.saving = false;
        }
    }

    /**
     * Remove an user from the project
     */
    @action
    async remove(id, projectRole) {
        if (!this.project || this.saving) {
            return;
        }

        this.saving = true;
        const url = projectRole
            ? `/project/${this.project}/assigned/${id}/${projectRole}`
            : `/project/${this.project}/assigned/${id}`;
        await http.delete(url);

        // emit the delete event
        // events.emit("project.update", this.data);
        await this.load(this.project, { force: true });

        this.saving = false;
    }

    @action
    getAllUsers(users) {
        let allUsers = users || this.users;

        const allUsersMap = {};
        if (allUsers) {
            allUsers.forEach((user) => {
                if (!allUsersMap[user._id]) {
                    allUsersMap[user._id] = user;
                }

                //merge the project roles
                const projectRoles = [
                    ...(allUsersMap[user._id].projectRoles || []),
                    user.projectRole,
                ];
                allUsersMap[user._id].projectRoles = projectRoles;
            });
        }

        return Object.values(allUsersMap);
    }
}

export default new AssignedUsers();
