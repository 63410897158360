import { observable, computed } from "mobx";
import Model from "@app/state/model/base";

const MIME_TYPES = {
    "application/pdf": "pdf",
    "image/png": "png",
    "image/gif": "gif",
    "image/jpeg": "jpeg",
    "image/bmp": "bmp",
    "application/vnd.ms-excel": "xls",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
};

export default class File extends Model {
    @observable _id = undefined;
    @observable name = undefined;
    @observable mime = undefined;

    @computed get type() {
        return MIME_TYPES[this.mime];
    }

    @computed get isImage() {
        return !!this.mime?.match(/image\//);
    }

    @computed get isExcel() {
        const mimeType = MIME_TYPES[this.mime];
        return mimeType === "xls" || mimeType === "xlsx";
    }
}
