import React from "react";
import { Switch } from "antd";
import notify from "@app/components/notify";
import Group from "../group";

import "./style/databases.scoped.scss";

const Databases = ({ config, onChange, readonly = false }) => {
    const update = async (args) => {
        const result = {
            ...config,
            ...args,
        };

        // validate it
        if (!result.pubmed && !result.google && !result.embase) {
            notify.error("At least one of PubMed, Google Scholar or Embase should be enabled");
            return;
        }

        onChange && onChange(result);
    };

    return (
        <Group title="Search Databases">
            <div className="databases">
                <div className="row toggle items-center mb-15">
                    <div className="col auto">
                        <Switch
                            checked={config.pubmed}
                            onChange={(value) => update({ pubmed: value })}
                            disabled={readonly}
                        />
                    </div>
                    <div className="col ml-15">PubMed</div>
                </div>
                <div className="row toggle items-center mb-15">
                    <div className="col auto">
                        <Switch
                            checked={config.google}
                            onChange={(value) => update({ google: value })}
                            disabled={readonly}
                        />
                    </div>
                    <div className="col ml-15">Google Scholar</div>
                </div>
                <div className="row toggle items-center mb-15">
                    <div className="col auto">
                        <Switch
                            checked={config.eupmc}
                            onChange={(value) => update({ eupmc: value })}
                            disabled={readonly}
                        />
                    </div>
                    <div className="col ml-15">Europe PMC</div>
                </div>
                <div className="row toggle items-center mb-15">
                    <div className="col auto">
                        <Switch
                            checked={config.embase}
                            onChange={(value) => update({ embase: value })}
                            disabled={readonly}
                        />
                    </div>
                    <div className="col ml-15">Embase</div>
                </div>
                <div className="row toggle items-center mb-15">
                    <div className="col auto">
                        <Switch
                            checked={config.cochrane}
                            onChange={(value) => update({ cochrane: value })}
                            disabled={readonly}
                        />
                    </div>
                    <div className="col ml-15">Cochrane</div>
                </div>
                <div className="row toggle items-center mb-15">
                    <div className="col auto">
                        <Switch
                            checked={config.prospero}
                            onChange={(value) => update({ prospero: value })}
                            disabled={readonly}
                        />
                    </div>
                    <div className="col ml-15">Prospero</div>
                </div>
            </div>
        </Group>
    );
};

export default Databases;
