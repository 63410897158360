import React from "react";
import { observer } from "mobx-react";
import { Checkbox } from "antd";
import Avatar from "@app/components/user/avatar";
import classNames from "classnames";
import format from "@app/lib/format";

import "./style/chip.scoped.scss";

/**
 * Render a user entry
 */
const UserChip = observer(({ checkbox = false, onClick, user, className, ...rest }) => {
    /**
     * Click handler
     */
    const clicked = () => {
        if (checkbox) {
            user.$selected = !user.$selected;
        }

        if (onClick) {
            onClick(user);
        }
    };

    /**
     * Render the checkbox
     */
    const UserCheckbox = observer(() => {
        if (!checkbox) {
            return null;
        }

        return (
            <div className="checkbox">
                <Checkbox checked={user.$selected} />
            </div>
        );
    });

    // combine the roles in a comma separated list
    const roles = user.roles
        .map((role) => {
            return format.role(role);
        })
        .join(", ");

    const classes = classNames("chip", className, {
        selectable: checkbox,
    });
    return (
        <div className={classes} onClick={clicked} {...rest}>
            <UserCheckbox />

            <div className="avatar">
                <Avatar user={user} size={40} />
            </div>

            <div className="details">
                <div className="name">{user.fullName}</div>
                <div className="role">{roles}</div>
            </div>
        </div>
    );
});

export default UserChip;
