import React from "react";
import { observer } from "mobx-react";

import format from "@app/lib/format";

import Box from "@app/components/box/box";
import Tags from "@app/components/tags/tags";

import Status from "./status";
import PreviewStatus from "./preview-status";

import "./style/details.scoped.scss";

const Card = observer(
    ({
        document,
        assignedTags = [],
        availableTags = [],
        assignedCategories,
        availableCategories,
        edit = false,
        onRemove,
        onAdd,
        addCategory,
        removeCategory,
    }) => {
        // Format input so that AntD Component can work.
        const format = (data) => {
            return data.map((item) => {
                item.label = item.name;
                return item;
            });
        };

        return (
            <Box className="document">
                <Meta document={document} />

                <PreviewStatus document={document} />
                <CategoryList
                    assignedCategories={assignedCategories}
                    availableCategories={format(availableCategories)}
                    addCategory={addCategory}
                    removeCategory={removeCategory}
                    edit={edit}
                />

                <TagList
                    assignedTags={assignedTags}
                    availableTags={availableTags}
                    edit={edit}
                    onRemove={onRemove}
                    onAdd={onAdd}
                />
            </Box>
        );
    },
);

Card.displayName = "Card";

const Meta = observer(({ document }) => {
    return (
        <>
            <div className="head">
                <div className="name">{document.name}</div>
                <div className="status">
                    <Status status={document.status} />
                </div>
            </div>

            <div className="meta">
                <div className="uploaded">
                    Uploaded by <span className="name">{document.author?.fullName}</span> on{" "}
                    {format.date(document.created)}
                </div>
                <div className="version">Version: {document.version}</div>
            </div>
        </>
    );
});

Meta.displayName = "Meta";

const CategoryList = observer(
    ({ assignedCategories, availableCategories, addCategory, removeCategory, edit }) => {
        if (availableCategories.length === 0) {
            return;
        }

        return (
            <div className="group">
                <div className="header">
                    <div className="title">Categories</div>
                </div>

                <div className="content">
                    <Tags
                        assignedTags={assignedCategories}
                        availableTags={availableCategories}
                        edit={edit}
                        onRemove={(tag) => removeCategory(tag)}
                        onAdd={(tag) => addCategory(tag)}
                        className="tags"
                    />
                </div>
            </div>
        );
    },
);

CategoryList.displayName = "CategoryList";

const TagList = observer(({ assignedTags, availableTags, edit, onRemove, onAdd }) => {
    if (availableTags.length === 0) {
        return;
    }

    return (
        <div className="group">
            <div className="header">
                <div className="title">Tags</div>
            </div>
            <div className="content">
                <Tags
                    assignedTags={assignedTags}
                    availableTags={availableTags}
                    edit={edit}
                    onRemove={onRemove}
                    onAdd={onAdd}
                    className="tags"
                />
            </div>
        </div>
    );
});

TagList.displayName = "TagList";

export default Card;
