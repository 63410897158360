import React from "react";
import cn from "classnames";
import format from "@app/lib/format";
import "./version-list-item.scoped.scss";

import versionAliasStore from "@app/state/store/report-document/version-alias";

const VersionListItem = ({
    menu,
    versionName,
    createdBy,
    aliasId,
    selectedAliasId,
    created,
    versionDate,
    index,
}) => {
    const onSelectVersionAlias = () => {
        versionAliasStore.onSelectVersionAlias(aliasId);
    };

    return (
        <li className={cn("version-list-item", { selected: aliasId === selectedAliasId })}>
            <div className="container" onClick={onSelectVersionAlias}>
                <div className="version-title">
                    {versionName} - {format.date(versionDate)} {!index && "(latest)"}
                </div>
                <div>
                    Created by {createdBy.fullName} {format.datetime(created)}
                </div>
            </div>
        </li>
    );
};

export default VersionListItem;
