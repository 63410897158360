import { computed, action } from "mobx";
import BaseStore from "@app/state/store/base";
import http from "@app/lib/http";
import { ws } from "@app/lib/socket";

import notify from "@app/components/notify";
// report wrapper state
import report from "../report";
import { events } from "@app/lib/store";
import History from "@app/state/model/adverse-event/history";

/**
 * Adverse Events state class
 */
export class AdverseEventsStore extends BaseStore {
    /**
     * Observable store data
     */
    observable() {
        return {
            pager: { currentPage: 1, pageSize: 20 },
            searchCriteria: {},
            results: [],
            totalCount: undefined,
            loaded: false,
            loading: false,
            exportable: false,
            exporting: false,
            synchronizing: undefined,
            history: [],
        };
    }

    constructor() {
        super();

        events.on("project.unload", () => this.reset());

        // handle the change of sync state for the product codes
        // and update the the sync state of the project
        ws.on("adverseEvents.sync", (productCodes) => {
            for (const code of productCodes) {
                const hasProductCode = report.config?.risk?.productCodes?.indexOf(code) > -1;
                if (hasProductCode) {
                    this.synchronizing = undefined;
                    this.loadSyncState();
                    break;
                }
            }
        });
    }

    /**
     * Return the project id if the currently loaded project
     */
    @computed get project() {
        return report.id;
    }

    formatSearchParams(saveHistory) {
        return {
            from: `${(this.pager.currentPage - 1) * this.pager.pageSize}`,
            rows: `${this.pager.pageSize}`,
            terms: this.searchCriteria,
            saveHistory,
        };
    }

    /**
     * Perform a search
     */
    @action
    async loadEvents(saveHistory = true) {
        if (!this.project) {
            return;
        }

        this.loading = true;

        try {
            const searchParams = this.formatSearchParams(saveHistory);
            let {
                data: { list, totalCount },
            } = await http.post(`/project/${this.project}/adverse-events/search`, searchParams);

            this.loaded = true;
            this.exportable = true;
            this.results = list;
            this.totalCount = totalCount;
            events.emit("adverse-events.load");
        } catch (ex) {
            notify.error("An error occurred while loading the adverse events");
            return { error: true, code: ex.response?.data.code };
        } finally {
            this.loading = false;
        }
    }

    /**
     * Load the adverse event history
     */
    @action
    async loadHistory() {
        let { data } = await http.get(`/project/${this.project}/adverse-events/search/history`);
        this.history = data.map((entry) => {
            return new History(entry);
        });
    }

    /**
     * Fetch search Details
     */
    @action
    async details(key) {
        if (!this.project) {
            return;
        }

        this.loading = true;

        try {
            const { data } = await http.get(
                `/project/${this.project}/adverse-events/search/${key}`,
            );
            return data;
        } catch (ex) {
            notify.error("An error occurred while loading Maude report details. Please try later.");
            return { error: true, code: "ex.response?.data.code" };
        } finally {
            this.loading = false;
        }
    }

    /**
     * Exports the complete set of search results, returning the raw file content for an Excel file
     */
    @action
    async export() {
        if (!this.project) {
            return;
        }

        this.exporting = true;
        try {
            const response = await http.fetchRawFileContent(
                `/project/${this.project}/adverse-events/export`,
                { terms: this.searchCriteria },
            );
            return response.data;
        } finally {
            this.exporting = false;
        }
    }

    /**
     * Perform a search
     */
    @action
    async loadSyncState() {
        // check for missing project
        if (!this.project) {
            return;
        }

        // we have loaded the sync state
        if (this.synchronizing !== undefined) {
            return;
        }

        try {
            let { data } = await http.get(`/project/${this.project}/adverse-events/syncState`);
            this.synchronizing = data.synchronizing;
        } catch (ex) {
            notify.error("An error occurred while loading the adverse events");
            return { error: true, code: ex.response?.data.code };
        }
    }
}

export default new AdverseEventsStore();
