import React from "react";

export const renderToolbar = (Toolbar) => (
    <Toolbar>
        {(slots) => {
            const {
                CurrentPageInput,
                EnterFullScreen,
                GoToNextPage,
                GoToPreviousPage,
                NumberOfPages,
                Print,
                ShowSearchPopover,
                Zoom,
                ZoomIn,
                ZoomOut,
            } = slots;
            return (
                <div
                    style={{
                        alignItems: "center",
                        display: "flex",
                        width: "100%",
                    }}
                >
                    <div className="rpv-toolbar__item">
                        <ShowSearchPopover />
                    </div>
                    <div className="rpv-toolbar__item">
                        <ZoomOut />
                    </div>
                    <div className="rpv-toolbar__item">
                        <Zoom />
                    </div>
                    <div className="rpv-toolbar__item">
                        <ZoomIn />
                    </div>
                    <div className="rpv-toolbar__item" style={{ marginLeft: "auto" }}>
                        <GoToPreviousPage />
                    </div>
                    <div className="rpv-toolbar__item">
                        <CurrentPageInput /> / <NumberOfPages />
                    </div>
                    <div className="rpv-toolbar__item">
                        <GoToNextPage />
                    </div>
                    <div className="rpv-toolbar__item" style={{ marginLeft: "auto" }}>
                        <EnterFullScreen />
                    </div>
                    <div className="rpv-toolbar__item">
                        <Print />
                    </div>
                </div>
            );
        }}
    </Toolbar>
);
