import { observable, computed } from "mobx";
import Model from "@app/state/model/base";

export default class Client extends Model {
    @observable _id = undefined;
    @observable name = undefined;
    @observable logo = undefined;
    @observable configurations = undefined;
    @observable $selected = false;
    @observable owners = false;

    /**
     * Return Client's initials
     */
    @computed get initials() {
        return this.name.charAt(0).toUpperCase();
    }
}
