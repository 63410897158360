import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { Button, Dropdown, Menu } from "antd";
import { Page } from "@app/components/page";
import { DownOutlined, FileWordOutlined } from "@ant-design/icons";
import Box from "@app/components/box/box";

const Prisma = observer(() => {
    const { project, type } = useParams();
    const history = useHistory();

    const close = () => {
        history.push(`../${type}`);
    };

    const Image = () => {
        const time = Date.now();
        return <img src={`/api/project/${project}/reports/prisma/${type}?${time}`} />;
    };

    return (
        <Page>
            <Page.Header title="PRISMA Flow Diagram" closable onClose={close}>
                <Page.Header.Right>
                    <ExportMenu type={type} project={project} />
                </Page.Header.Right>
            </Page.Header>
            <Page.Body maxWidth={830}>
                <Box>
                    <Image />
                </Box>
            </Page.Body>
        </Page>
    );
});

const ExportMenu = observer(({ project, type }) => {
    const exportURL = `/api/project/${project}/reports/prisma/${type}/export`;

    const menu = (
        <Menu>
            <Menu.Item key="word" icon={<FileWordOutlined />}>
                <a href={exportURL} target="_blank" rel="noopener noreferrer">
                    Word
                </a>
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger="click" placement="bottomRight">
            <Button>
                Export <DownOutlined />
            </Button>
        </Dropdown>
    );
});

export default Prisma;
