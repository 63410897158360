import { useEffect, useRef } from "react";

/**
 * Hook that alerts when you click outside of the passed ref
 * @param {Function} callback - Function to call when a click outside is detected
 * @returns {React.RefObject} - Ref object to attach to the element
 */
const useClickOutside = (callback) => {
    const ref = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        };

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [callback]);

    return ref;
};

export default useClickOutside;
