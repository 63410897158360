import _ from "lodash";
import Page from "@app/components/page/page";
import { Button } from "antd";
import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";

import { SaveOutlined } from "@ant-design/icons";
import ReportForm from "@app/components/setup/reports/form";

import { generatePreview } from "@app/components/setup/reports/config/preview-utils";
import Preview from "@app/components/setup/reports/preview";
import { ReportTypes } from "@app/constants";
import format from "@app/lib/format";

import "./style.scss";
import notify from "@app/components/notify/index";

const toObject = (array, key = "name") => {
    return array.reduce((obj, item) => {
        obj[item[key]] = item;
        return obj;
    }, {});
};

const getFields = (fields, properties, reportType, articleTags, l2Questions) => {
    if (
        [
            ReportTypes.L2_REVIEW_SUMMARY,
            ReportTypes.ARTICLE_DATA_SHEET,
            ReportTypes.APPENDIX,
            ReportTypes.SEARCH_PARAMS,
            ReportTypes.CUSTOM,
        ].includes(reportType)
    ) {
        let additionalDataFields = [];

        if (ReportTypes.SEARCH_PARAMS !== reportType) {
            additionalDataFields = toObject(
                properties.map((prop) => ({
                    fieldType: "checkbox",
                    name: prop._id,
                    label: prop.title,
                    value: false,
                    options: prop.options,
                })),
            );
        }

        const alreadyConfiguredFields = toObject(
            fields
                .filter((field) => {
                    if (field.fieldType === "checkbox") {
                        return additionalDataFields[field.name];
                    }
                    return true;
                })
                .map((field) => {
                    if (field.name === "articleTags") {
                        return {
                            ...field,
                            options: articleTags.map((tag) => {
                                return { label: tag.label, value: tag._id };
                            }),
                        };
                    }
                    if (field.name === "showReviewQuestions") {
                        return {
                            ...field,
                            value: field.value === "true",
                            modalTitle: "Select Review Questions",
                            options:
                                l2Questions?.map((q) => {
                                    return { label: q.contribution, value: q._id };
                                }) || [],
                            values:
                                field.values?.reduce((acc, v) => {
                                    const foundQuestion = l2Questions?.find(
                                        (q) => q._id === v.value,
                                    );
                                    if (foundQuestion) {
                                        v.label = foundQuestion.contribution;
                                        acc.push(v);
                                    }
                                    return acc;
                                }, []) || [],
                        };
                    }
                    if (field.fieldType === "toggle" || field.fieldType === "checkbox") {
                        return {
                            ...field,
                            value: field.value === "true",
                        };
                    }
                    return field;
                }),
        );

        return Object.values({
            ...additionalDataFields,
            ...alreadyConfiguredFields,
        });
    }

    return fields;
};

const getConfig = (parentConfig, type, reportId, isEdit) => {
    if (!isEdit) {
        const index = parentConfig.reports[type].findIndex(
            (item) => item.reportType === ReportTypes.L2_REVIEW_SUMMARY,
        );

        const config = _.cloneDeep(parentConfig.reports[type][index]);
        config.reportName = "New Report";
        config.reportType = ReportTypes.CUSTOM;

        const name = config.fields.find((item) => item.name === "reportName");
        name.value = config.reportName;

        // fix font size
        const fontSize = config.fields.find((item) => item.name === "fontSize");
        fontSize.value = "regular";

        const articleTags = config.fields.find((item) => item.name === "articleTags") ?? {
            fieldType: "multi-select",
            label: "Article Tags",
            name: "articleTags",
            options: [],
            values: [],
        };
        articleTags.values = [];

        // disable some fields
        for (const name of ["showReviewQuestions", "showExclusionReason", "showReviewScore"]) {
            const field = config.fields.find((item) => item.name === name);
            field.value = false;
        }

        return config;
    }

    const index = parentConfig.reports[type].findIndex((item) => item._id === reportId);
    return parentConfig.reports[type][index];
};

const SetupReportsConfig = ({ setup }) => {
    const { config } = setup;
    const history = useHistory();
    const { type, reportId } = useParams();
    const isEdit = reportId !== "new";

    const reportConfig = getConfig(config, type, reportId, isEdit);
    const fields = getFields(
        reportConfig.fields,
        config[type].additionalData,
        reportConfig.reportType,
        config.articleTags,
        config[type].l2?.questions,
    );

    const [formState, setFormState] = useState(toObject(fields));
    const [isSaved, setIsSaved] = useState(isEdit);
    const [preview, setPreview] = useState({});

    useEffect(() => {
        if (config) {
            setPreview(generatePreview(reportConfig, config[type], formState));
        }
    }, [reportConfig.reportType, formState]);

    const back = useCallback(() => {
        history.push("../../reports");
    }, [history]);

    const submit = useCallback(async () => {
        const cloneConfig = _.cloneDeep(config);
        // delete _.id to prevent conflict of params in the api
        delete cloneConfig._id;

        const fields = Object.values(formState).map((field) => ({
            ...field,
            value: field.value?.toString(),
        }));

        const name = formState.reportName.value?.trim();
        if (!name) {
            notify.error("Please enter a valid report name");
            return;
        }

        const nameExist = cloneConfig.reports[type].findIndex(
            (item) => item._id !== reportId && item.reportName.trim() === name,
        );

        if (nameExist > 0) {
            notify.error("Report name already exist!");
            return;
        }

        if (isEdit) {
            const index = cloneConfig.reports[type].findIndex((item) => item._id === reportId);
            const reportConfig = cloneConfig.reports[type][index];

            reportConfig.fields = fields;
            reportConfig.reportName = formState.reportName.value;

            await setup.updateConfig({ reports: cloneConfig.reports });
            setIsSaved(true);
        } else {
            // new report
            const index = cloneConfig.reports[type].findIndex(
                (item) => item.reportType === ReportTypes.L2_REVIEW_SUMMARY,
            );
            const baseConfig = _.cloneDeep(cloneConfig.reports[type][index]);
            delete baseConfig._id;

            cloneConfig.reports[type].push({
                ...baseConfig,
                fields,
                reportName: formState.reportName.value,
                reportType: ReportTypes.CUSTOM,
            });
            await setup.updateConfig({ reports: cloneConfig.reports });
            setIsSaved(true);
        }
    }, [formState]);

    return (
        <Page>
            <Page.Header closable onClose={back}>
                <Page.Header.Left shrink>
                    <Page.Title>Edit {`${format.channel(type)}`} report</Page.Title>
                </Page.Header.Left>

                <Page.Header.Right>{/* TODO search */}</Page.Header.Right>
            </Page.Header>

            <Page.Body>
                <ReportForm
                    formState={formState}
                    setFormState={setFormState}
                    setIsSaved={setIsSaved}
                />
                <Preview
                    type={reportConfig.reportType}
                    layout={formState.layout}
                    view={formState.view}
                    fontSize={formState.fontSize}
                    preview={preview}
                />
            </Page.Body>

            <Page.Footer>
                <Page.Footer.Right>
                    <Page.Footer.SaveStatus status={isSaved} />

                    <Button icon={<SaveOutlined />} type="primary" onClick={submit}>
                        Save
                    </Button>
                    <Page.Footer.CloseButton onClick={back} />
                </Page.Footer.Right>
            </Page.Footer>
        </Page>
    );
};

export default SetupReportsConfig;
