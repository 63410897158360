import React from "react";
import classNames from "classnames";

import "./style/keywords.scoped.scss";

const Keywords = ({ label, keywords = [], className, ...rest }) => {
    return (
        <div className={classNames("keywords", className)} {...rest}>
            <label>{label}</label>
            <div className="list">
                {keywords.map((keyword, index) => {
                    return (
                        <span className="keyword" key={index}>
                            {keyword}
                        </span>
                    );
                })}
            </div>
        </div>
    );
};

export default Keywords;
