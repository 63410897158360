import { observable } from "mobx";
import Model from "@app/state/model/base";

export default class Category extends Model {
    @observable _id = undefined;
    @observable name = undefined;
    @observable status = undefined;
    @observable enabled = undefined;
    @observable system = undefined;
    @observable project = undefined;
    @observable deleted = undefined;
}
