import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { observer, useLocalStore } from "mobx-react";
import { Button } from "antd";
import { UsergroupAddOutlined } from "@ant-design/icons";
import qs from "qs";

import { Page } from "@app/components/page";
import Filter from "@app/components/filter";
import UserCard from "@app/components/user/card";
import UserFilter from "@app/components/user/filter";
import EmptyList from "@app/components/list/empty";
import confirm from "@app/components/confirm";
import users from "@app/state/store/users/list";

import "./style/list.scoped.scss";
import session from "@app/state/store/session";

const UsersList = observer((props) => {
    const state = useLocalStore(() => ({ focus: null }));

    // load the list when the component mounts. Include pending users.
    useEffect(() => {
        users.load();
    }, []);

    /**
     * Focus an user
     */
    const focus = (id) => {
        state.focus = id;
    };

    /**
     * Handle edit event
     */
    const onEdit = (user) => {
        focus(user._id);

        let url = `/users/${user._id}/edit`;
        props.history.push(url);
    };

    /**
     * Handle delete event
     */
    const onRemove = async (user) => {
        let proceed = await confirm("Are you sure you want to remove this user?");
        if (proceed) {
            await users.remove(user._id);
        }
    };

    let exportUrl = `/api/users/export?`;

    const params = users.filter.value();

    const queryString = qs.stringify(params);
    exportUrl += queryString;

    return (
        <Page className="users">
            <Page.Header>
                <Page.Header.Left shrink>
                    <Page.Title>Users</Page.Title>
                </Page.Header.Left>

                <Page.Header.Right>
                    {!session.isSSO && (
                        <Link to="/users/invite">
                            <Button type="primary" icon={<UsergroupAddOutlined />}>
                                Invite Users
                            </Button>
                        </Link>
                    )}
                    <a href={exportUrl + "&format=excel"} target="_blank" rel="noopener noreferrer">
                        <Button type="secondary">Export</Button>
                    </a>
                </Page.Header.Right>
            </Page.Header>

            <Filter.Header filter={users.filter} />

            <Page.Layout>
                <Page.Body filter={users.filter}>
                    <EmptyList
                        title="No Results Found"
                        show={users.list.length === 0 && !users.loading}
                    >
                        You can use the <b>Add User</b> button to add more users
                    </EmptyList>

                    {users.list.map((user) => {
                        return (
                            <Link
                                to={`/users/${user._id}`}
                                onClick={() => focus(user._id)}
                                key={user._id}
                            >
                                <UserCard
                                    user={user}
                                    focus={user._id === state.focus}
                                    onEdit={onEdit}
                                    onRemove={onRemove}
                                />
                            </Link>
                        );
                    })}
                </Page.Body>
                <UserFilter filter={users.filter} />
            </Page.Layout>

            <Page.Footer>
                <Filter.Pager filter={users.filter} />
            </Page.Footer>
        </Page>
    );
});

export default UsersList;
