import React from "react";

export default function () {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.5938 16.5C18.8125 16.4688 19 16.25 19 16V4.5C19 4.25 18.75 4 18.5 4H7.5C6.09375 4 5 5.125 5 6.5V17.5C5 18.9062 6.09375 20 7.5 20H18.5C18.75 20 19 19.7812 19 19.5V19C19 18.7812 18.8125 18.5625 18.5938 18.5312C18.4375 18.125 18.4375 16.9062 18.5938 16.5ZM17.3125 16.5C17.2188 17.0625 17.2188 17.9688 17.3125 18.5H7.5C6.9375 18.5 6.5 18.0625 6.5 17.5C6.5 16.9688 6.9375 16.5 7.5 16.5H17.3125ZM17.5 5.5V15H7.5C7.125 15 6.78125 15.0938 6.5 15.2188V6.5C6.5 5.96875 6.9375 5.5 7.5 5.5H17.5Z"
                fill="#4273B0"
            />
            <path
                d="M12.1855 7.2373C13.0221 7.2373 13.5755 7.28613 13.8457 7.38379C14.1191 7.48145 14.2559 7.67188 14.2559 7.95508C14.2559 8.22526 14.1566 8.46777 13.958 8.68262C13.2061 8.34408 12.5534 8.1748 12 8.1748C11.6322 8.1748 11.3766 8.23828 11.2334 8.36523C11.0902 8.49219 11.0186 8.67611 11.0186 8.91699C11.0186 9.20345 11.1081 9.41667 11.2871 9.55664C11.4661 9.69661 11.8275 9.84635 12.3711 10.0059C13.0547 10.1849 13.5804 10.4111 13.9482 10.6846C14.3193 10.958 14.513 11.404 14.5293 12.0225C14.5293 12.7256 14.2754 13.248 13.7676 13.5898C13.263 13.9284 12.5257 14.0977 11.5557 14.0977C10.8525 14.0977 10.3333 14.0456 9.99805 13.9414C9.66602 13.8372 9.5 13.6305 9.5 13.3213C9.5 13.0479 9.61393 12.8053 9.8418 12.5938C10.5872 12.9714 11.2855 13.1602 11.9365 13.1602C12.363 13.1602 12.6608 13.1048 12.8301 12.9941C12.9993 12.8802 13.084 12.693 13.084 12.4326C13.084 12.1266 12.9521 11.889 12.6885 11.7197C12.4248 11.5505 11.9902 11.3796 11.3848 11.207C10.7402 11.0345 10.2764 10.7952 9.99316 10.4893C9.71322 10.1833 9.57324 9.79102 9.57324 9.3125C9.57324 8.6582 9.80436 8.14876 10.2666 7.78418C10.7321 7.4196 11.3717 7.2373 12.1855 7.2373Z"
                fill="#4273B0"
            />
        </svg>
    );
}
