import React, { useState } from "react";
import { observer } from "mobx-react";
import { Upload } from "antd";
import { UploadOutlined, LoadingOutlined } from "@ant-design/icons";

import { UploadService } from "./service";
import { useUploadContext } from "./context";

import "./style/menu-item.scoped.scss";

const MenuItem = observer(
    ({ accept = "*", icon = <UploadOutlined />, onChange, children, state, multiple = false }) => {
        const [loading, setLoading] = useState(false);
        const { projectId, clientId } = useUploadContext();

        /**
         * Handle the beforeUpload event and start the upload process.
         * Return false to prevent the component uploading the file.
         * We are handling the upload ourselves.
         *
         * @param {File} file
         */
        const onFilePick = (file) => {
            if (loading) {
                return;
            }

            (async () => {
                setLoading(!multiple && true);

                const { data } = await UploadService.upload(file, {
                    projectId,
                    clientId,
                    state,
                });

                setLoading(false);
                if (data) {
                    onChange && (await onChange(data));
                }
            })();

            return false;
        };

        return (
            <div className="upload">
                <Upload
                    showUploadList={false}
                    beforeUpload={onFilePick}
                    accept={accept}
                    disabled={loading}
                >
                    {icon && <div className="icon">{loading ? <LoadingOutlined /> : icon}</div>}
                    {children}
                </Upload>
            </div>
        );
    },
);

export default MenuItem;
