export class HighlightState {
    highlightAreas = [];

    constructor(highlightAreas = []) {
        this.highlightAreas = highlightAreas;
    }
}

class NoSelectionState extends HighlightState {}

class SelectingState extends HighlightState {}

export class SelectedState extends HighlightState {
    selectedText;
    selectionData;
    selectionRegion;

    constructor(selectedText, highlightAreas, selectionData, selectionRegion) {
        super(highlightAreas);
        this.selectedText = selectedText;
        this.selectionData = selectionData;
        this.selectionRegion = selectionRegion;
    }
}

export class HighlightSelectionState extends HighlightState {
    selectedText;
    selectionData;
    selectionRegion;

    constructor(selectedText, highlightAreas, selectionData, selectionRegion) {
        super(highlightAreas);
        this.selectedText = selectedText;
        this.selectionData = selectionData;
        this.selectionRegion = selectionRegion;
    }
}

export const NO_SELECTION_STATE = new NoSelectionState();
export const SELECTING_STATE = new SelectingState();
