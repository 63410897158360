import React from "react";
import { observable, action } from "mobx";
import { useRouteMatch } from "react-router-dom";
import { routes as routeConfig } from "@app/routes";

let routes = null;

export class State {
    @observable crumbs = [];

    /**
     * Construct the state and parse the route configuration
     * on the first run.
     */
    constructor(type) {
        this.type = type;
        if (!routes) {
            routes = [];
            this.read(routeConfig);
        }
    }

    /**
     * Read the breadcrumb configuration from the route spec
     */
    read(list) {
        list.map(({ path, breadcrumb, children }) => {
            if (breadcrumb) {
                routes.push({
                    path: path,
                    text: breadcrumb,
                });
            }

            // if the route has children go over their configuration
            if (children) {
                this.read(children);
            }
        });
    }

    /**
     * Perform the match of the current path against the breadcrumbs.
     * We are getting all the routes that are contained in the current one.
     */
    @action
    match(match) {
        this.crumbs = routes

            // filter the routes which are included in the current one
            .filter(({ path }) => {
                let partial = path.replace(/\/$/, "") + "/";
                return match.path.includes(partial) || match.path === path;
            })

            // replace the params in the path
            .map((route) => {
                let path = route.path;

                // there are any parameters to be replaced
                if (Object.keys(match.params).length) {
                    Object.entries(match.params).forEach(([key, value]) => {
                        value = value ?? "";
                        path = path.replace(`:${key}`, value);
                    });
                }

                return {
                    text: route.text,
                    path,
                };
            });
    }
}

/**
 * A convenient hook to create the state if it does not exist
 */
export function useCrumbs(type) {
    const match = useRouteMatch();

    // create the breadcrumb state
    const state = React.useMemo(() => {
        return new State(type);
    }, [type]);

    // match the path
    React.useEffect(() => {
        state.match(match);
    }, [state, match]);

    return state;
}
