import React from "react";
import { observer } from "mobx-react";

import "./style/answer.scoped.scss";

const Answer = observer(({ data }) => {
    if (!data?.answer?.answer) {
        return null;
    }

    return (
        <div className="answer">
            <div className="question">
                <div className="text">{data?.question}</div>
                <div className="contribution">{data?.contribution}</div>
            </div>
            <div className="grading">
                <label>{data?.answer?.grade}:</label>
                {data?.answer?.answer}
            </div>
        </div>
    );
});

export default Answer;
