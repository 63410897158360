import React from "react";

export default function () {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            width="1em"
            height="1em"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 14 14"
        >
            <rect x="0" y="0" width="14" height="14" fill="currentColor" />
            <rect x="0" y="2" width="14" height="10" fill="white" />
            <rect x="5" y="2" width="4" height="10" fill="currentColor" />
        </svg>
    );
}
