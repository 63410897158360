import React from "react";
import { observer } from "mobx-react";
import classes from "classnames";
import { DownOutlined, FilterOutlined } from "@ant-design/icons";

import useToggle from "@app/hooks/useToggle";

import "./style/group.scoped.scss";

const KEY_PREFIX = "filter-group:";
export const FieldGroupContext = React.createContext(null);

const ToggleButton = ({ isOpen }) => {
    let mergedClasses = classes({
        "toggle-button": true,
        flipped: isOpen,
    });

    return (
        <div className={mergedClasses}>
            <DownOutlined />
        </div>
    );
};

const ActiveFilterIndicator = ({ hasActiveFilters = false, sectionOpen = false }) => {
    let mergedClasses = classes({
        "filter-indicator": true,
        visible: hasActiveFilters && !sectionOpen,
    });

    return (
        <div className={mergedClasses}>
            <FilterOutlined />
        </div>
    );
};

const OptionWrapper = ({ children, isOpen }) => {
    let mergedClasses = classes({
        "option-wrapper": true,
        open: isOpen,
        closed: !isOpen,
    });

    return <div className={mergedClasses}>{children}</div>;
};

const Group = observer(
    ({ className, children, title = "", hasActiveFilters = true, collapsed = false, groupKey }) => {
        const persistanceKey = groupKey ? `${KEY_PREFIX}${groupKey}` : undefined;
        const [isOpen, toggleOpen] = useToggle(!collapsed, persistanceKey);

        let mergedClasses = classes(className, "filter-group", {
            expanded: isOpen,
        });

        return (
            <div className={mergedClasses}>
                <div className="header" onClick={toggleOpen}>
                    <div className="title">{title}</div>
                    <ActiveFilterIndicator
                        hasActiveFilters={Boolean(hasActiveFilters)}
                        sectionOpen={isOpen}
                    />
                    <ToggleButton isOpen={isOpen} />
                </div>

                <OptionWrapper isOpen={isOpen} children={children} />
            </div>
        );
    },
);

export default Group;
