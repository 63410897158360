import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import clientDetails from "@app/state/store/clients/details";
import SetupReportsConfig from "@app/components/setup/reports/config/index";

const ClientSetupReportConfig = observer(() => {
    const { id } = useParams();

    useEffect(() => {
        clientDetails.load(id);
    }, []);

    return <SetupReportsConfig setup={clientDetails} />;
});

export default ClientSetupReportConfig;
