import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Form, Input } from "antd";

import ColorPicker from "@app/components/form/color-picker";
import Modal from "@app/components/modal";
import notify from "../../notify";

const ReasonForm = observer(({ tag = {}, existing = [], onSave, onCancel }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            label: tag.label,
            color: tag.color,
        });
    }, [form, tag]);

    /**
     * Show the validation error
     */
    const error = () => {
        notify.error("Please fill all required fields to continue");
    };

    /**
     * Cancel the edit
     */
    const cancel = () => {
        onCancel && onCancel();
        form.resetFields();
    };

    /**
     * Save the reason
     */
    const save = (data) => {
        const label = data.label.trim();

        // search for duplicates
        const duplicate = existing.find((entry) => {
            return entry.label.trim() === label && entry._id !== tag._id;
        });

        if (duplicate) {
            notify.error("Tag already exists. Please modify your input and try again");
            return;
        }

        tag.label = label;
        tag.color = data.color;

        onSave && onSave(tag);
    };

    const title = tag._id ? "Edit tag" : "Add tag";

    return (
        <Modal
            title={title}
            visible={true}
            okText="Save"
            onOk={form.submit.bind(form)}
            onCancel={cancel}
            width={600}
        >
            <div className="reason-form">
                <Form layout="vertical" form={form} onFinishFailed={error} onFinish={save}>
                    <Form.Item
                        label="Name"
                        name="label"
                        rules={[
                            {
                                required: true,
                                message: "Please enter the name",
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input disabled={tag.system} />
                    </Form.Item>

                    <Form.Item
                        label="Color"
                        name="color"
                        rules={[
                            {
                                required: true,
                                message: "Please pick a color",
                            },
                        ]}
                    >
                        <ColorPicker />
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
});

export default ReasonForm;
