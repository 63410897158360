import { action, computed } from "mobx";
import BaseStore from "@app/state/store/base";

import http from "@app/lib/http";

import VersionAlias from "@app/state/model/report-document/version-alias";

import report from "../report";
import notify from "@app/components/notify";
import sessionStore from "../session";

import versionStore from "./version";

export class VersionAliasStore extends BaseStore {
    isLoading = false;
    projectId = null;
    reportDocumentId = null;
    showCreateVersionAliasModal = false;
    createAliasForVersionId = null;
    showCompareVersionsModal = false;
    selectedAliasId = null;

    observable() {
        return {
            isLoading: false,
            aliases: [],
            showCreateVersionAliasModal: false,
            createAliasForVersionId: null,
            showCompareVersionsModal: false,
            selectedAliasId: null,
        };
    }

    /**
     * Return the project id if the currently loaded project
     */
    @computed get project() {
        return report.id;
    }

    @computed get apiUrl() {
        return `/project/${this.project}/report-documents/${this.reportDocumentId}/version-alias`;
    }

    @computed get userId() {
        return sessionStore.user._id;
    }

    @action
    async load() {
        this.isLoading = true;

        try {
            const { data } = await http.get(`${this.apiUrl}`);
            this.aliases = data
                .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
                .map((entry) => new VersionAlias(entry));
        } catch (error) {
            console.error(error);
        }

        versionStore.projectId = this.projectId;
        versionStore.reportDocumentId = this.reportDocumentId;
        this.isLoading = false;
    }

    @action
    reset() {
        this.reportDocumentId = null;
        this.aliases = [];
        this.showCreateVersionAliasModal = false;
        this.createAliasForVersionId = null;
        this.selectedAliasId = null;
    }

    @computed get userMenuValues() {
        const authors = new Map();

        this.aliases
            .filter((version) => version?.createdBy)
            .forEach(({ createdBy: { fullName: label, _id: value } }) => {
                authors.set(value, label);
            });

        return Array.from(authors, ([value, label]) => ({ value, label }));
    }

    @action
    onShowCreateVersionModal({ versionId }) {
        if (versionId) {
            this.showCreateVersionAliasModal = true;
            this.createAliasForVersionId = versionId;
        }
    }

    @action.bound
    onCloseCreateVersionModal() {
        this.showCreateVersionAliasModal = false;
        this.createAliasForVersionId = null;
    }

    @action.bound
    async onSubmitCreateVersionForm({ versionName }) {
        try {
            const { data } = await http.post(`${this.apiUrl}`, {
                versionId: this.createAliasForVersionId,
                versionName,
            });

            this.aliases.push(new VersionAlias(data));

            this.showCreateVersionAliasModal = false;
            this.createAliasForVersionId = null;
        } catch (error) {
            const { message } = error;
            notify.error(message || "Failed to create version");
        }
    }

    @action.bound
    async onSelectVersionAlias(aliasId) {
        if (!aliasId) {
            this.selectedAliasId = aliasId;
            return;
        }

        const { versionId } = this.aliases.find((a) => a._id === aliasId) || {};

        if (!versionId) {
            console.error("Selected alias was not found");
        }

        await versionStore.loadContentVersion({
            projectId: this.projectId,
            reportDocumentId: this.reportDocumentId,
            versionId,
        });

        this.selectedAliasId = aliasId;
        versionStore.setSelectedAlias(versionId);
    }

    @computed
    get versionsToCompareOptions() {
        const aliasOptions = this.aliases.map(({ _id, versionName }) => {
            return { label: versionName, value: _id };
        });

        return aliasOptions;
    }

    @computed
    get versionNames() {
        return this.aliases.map(({ versionName }) => versionName);
    }

    @action.bound
    onShowCompareVersionsModal() {
        this.showCompareVersionsModal = true;
    }

    @action.bound
    onHideCompareVersionsModal() {
        this.showCompareVersionsModal = false;
    }

    @action.bound
    async onCompareVersions(aliasAId, aliasBId) {
        const aliasA = this.aliases.find(({ _id }) => _id === aliasAId);
        const aliasB =
            aliasBId === "latest"
                ? { versionId: versionStore.versions[0]._id, versionName: "latest" }
                : this.aliases.find(({ _id }) => _id === aliasBId);

        await versionStore.onCompareVersions(aliasA, aliasB);
    }
}

export default new VersionAliasStore();
