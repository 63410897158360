import { Mark, Extension, mergeAttributes } from "@tiptap/core";
import { v4 as uuid } from "uuid";

import "./mark.scoped.scss";

export const InsertMark = Mark.create({
    name: "ins",

    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },

    parseHTML() {
        return [
            {
                tag: "ins",
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return [
            "ins",
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
                class: "insert-mark",
            }),
            0,
        ];
    },
    addAttributes() {
        return {
            "data-diff-id": {
                default: uuid(),
            },
        };
    },
});

export const DeleteMark = Mark.create({
    name: "del",

    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },

    parseHTML() {
        return [
            {
                tag: "del",
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return [
            "del",
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
                class: "delete-mark",
            }),
            0,
        ];
    },
    addAttributes() {
        return {
            "data-diff-id": {
                default: uuid(),
            },
        };
    },
});

export const VersionExtension = Extension.create({
    name: "version",
    addGlobalAttributes() {
        return [
            {
                // Extend the following extensions
                types: ["image", "table"],
                // … with those attributes
                attributes: {
                    "data-diff-node": {
                        default: undefined,
                    },
                    "data-diff-id": {
                        default: undefined,
                    },
                },
            },
        ];
    },
});
