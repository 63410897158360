import React, { useEffect, useState } from "react";
import { InsertTableModal } from "./insert-table-modal";

export const TablePlugin = ({ editor }) => {
    const [open, setOpen] = useState(false);
    const showInsertTable = () => setOpen(true);

    const onInsert = (values) => {
        editor.chain().focus().insertTable(values).run();
        setOpen(false);
    };

    useEffect(() => {
        if (!editor) {
            return;
        }

        editor.on("showInsertTable", showInsertTable);

        return () => editor.off("showInsertTable", showInsertTable);
    }, [editor]);

    return (
        <>
            {open && (
                <InsertTableModal open={true} onInsert={onInsert} onClose={() => setOpen(false)} />
            )}
        </>
    );
};

export default TablePlugin;
