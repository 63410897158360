import React from "react";

import loader from "@app/assets/loader.svg";
import "./style/content.scoped.scss";

export default function Loader({ loading = false, children }) {
    if (!loading) {
        return true;
    }

    return (
        <div className="content-loader">
            <img src={loader} />
        </div>
    );
}
