import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import Overview from "@app/components/report-document/overview";

import reportDocumentsStore from "@app/state/store/report-document/report-documents";
import assignedStore from "@app/state/store/projects/assigned";

const ReportDocumentsOverview = observer(({ project }) => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const load = async () => {
            reportDocumentsStore.setProjectId(project);
            await reportDocumentsStore.loadReportDocuments({ metaData: "true" });
            await assignedStore.load(project);
            setUsers(assignedStore.getAllUsers());
        };

        load();
    }, []);

    return (
        <Overview
            project={project}
            reportDocuments={reportDocumentsStore.reportDocuments}
            projectUsers={users}
        />
    );
});

export default ReportDocumentsOverview;
