import { computed, action } from "mobx";
import BaseStore from "./base";
import Activity from "@app/state/model/activity";
import report from "@app/state/store/report";
import { events } from "@app/lib/store";
import FilterState from "@app/components/filter/state";
import { v4 as uuid } from "uuid";
import http from "@app/lib/http";
import notify from "@app/components/notify";
import { ActivityLogView, Activities } from "@app/constants";

import _ from "lodash";
import moment from "moment";

const STAGGER_ID = uuid();

export class ActivityStore extends BaseStore {
    constructor() {
        super();

        this.filter = new FilterState({
            default: {
                search: "",
            },
            rows: 20,
        });

        this.filter.on("find", () => {
            this.load();
        });

        events.on("project.unload", () => {
            this.unload();
        });

        events.on("article.update", (article) => {
            if (this.payload?.articleId?.toString() === article._id?.toString()) {
                this.reset();
                this.load({
                    id: article._id,
                    view: ActivityLogView.Sidebar,
                    context: Activities.ARTICLE,
                });
            }
        });

        events.on("document.update", (document) => {
            if (this.payload?.documentId?.toString() === document._id?.toString()) {
                this.reset();
                this.load({
                    id: document._id,
                    view: ActivityLogView.Sidebar,
                    context: Activities.DOCUMENT,
                });
            }
        });
    }
    observable() {
        return {
            loading: false,
            data: {}, // for Sidebar grouped view
            list: [], // for list view
            payload: undefined,
            view: undefined,
            context: undefined,
            users: [],
        };
    }

    @computed get busy() {
        return this.loading;
    }

    @computed get project() {
        return report.id;
    }

    /**
     * load activities
     * payload : Id of article, document or project
     */
    @action
    async load(payload = {}) {
        const { id = undefined, view = undefined, context = undefined } = payload;
        this.loading = true;

        try {
            if (!this.payload) {
                // Payload can come form various sources, so save it.
                // On Load More, original payload is immediately available.
                if (context === Activities.ARTICLE) {
                    this.payload = { articleId: id };
                } else if (context === Activities.SEARCH) {
                    this.payload = { searchId: id };
                } else if (context === Activities.PROJECT) {
                    this.payload = { project: id };
                } else if (context === Activities.DOCUMENT) {
                    this.payload = { documentId: id };
                }
                this.view = view;
                this.context = context;
            }

            const filter = { ...this.filter.value(), ...this.payload };

            let { data } = await http
                .get(`/project/${this.project}/activities`, filter)
                .stagger(STAGGER_ID);

            if (data.list?.length === 0) {
                this.data = {};
            }

            this.list = []; // Reset List and push as necessary to avoid another loop.

            const results = data.list?.map((entry) => {
                entry.date = moment(entry.time).format("DD-MMM-YYYY");
                entry.created = moment(entry.time).format("LT");

                const activity = new Activity(entry);
                this.list.push(activity);

                return activity;
            });

            if (this.view === ActivityLogView.Sidebar) {
                // Show Date wise Grouped data
                const grouped = _.groupBy(results, "date");
                Object.keys(grouped).sort((a, b) => a > b);

                for (const activity in grouped) {
                    const found = this.data[activity];
                    if (found) {
                        this.data[activity].push(...grouped[activity]);
                    } else {
                        this.data[activity] = grouped[activity];
                    }
                }
            }

            this.filter.stats(data.stats);
        } catch (ex) {
            notify.error(ex.response?.data?.error);
        } finally {
            this.loading = false;
        }
    }

    @action
    async allUsers() {
        this.loading = true;

        try {
            if (this.users.length === 0) {
                const { data } = await http.get(`/project/${this.project}/activities/users`);

                data.list.forEach((user) => {
                    this.users.push(user);
                });
            }
        } catch (ex) {
            notify.error(ex.response?.data?.error);
        } finally {
            this.loading = false;
        }
    }

    @action
    unload() {
        this.reset();
        this.filter = new FilterState({
            default: {
                search: "",
            },
            rows: 20,
        });

        this.filter.on("find", () => {
            this.load();
        });
    }
}

export default new ActivityStore();
