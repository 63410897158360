import { observable } from "mobx";
import Model from "@app/state/model/base";

export default class Overview extends Model {
    @observable search = [];
    @observable documentCategories = [];
    @observable documents = [];
    @observable l1Review = [];
    @observable l2Review = [];
}
