import React from "react";
import { observer } from "mobx-react-lite";
import { Box } from "@app/components/box";
import { MarkdownViewer } from "@app/components/markdown-editor/index";
import { FDADeviceProblemCodes, FDAPatientProblemCodes } from "@app/constants";
import { LinkOutlined } from "@ant-design/icons";

import "./style/details.scoped.scss";

const toYesNo = (input) => {
    if (input?.toUpperCase() === "Y") {
        return "Yes";
    }
    if (input?.toUpperCase() === "N") {
        return "No";
    }
    return input;
};

const Details = observer(({ data = {} }) => {
    const Title = observer(() => (
        <div className="title">
            <div className="label">
                <a href={data.url} target="_blank" rel="noreferrer">
                    {data.manufacturerName && (
                        <span className="chunk manufacturer">{data.manufacturerName}:</span>
                    )}
                    <span className="chunk brand">{data.brandName}</span>
                    {data.device?.typeOfDevice && (
                        <span className="chunk type">({data.device.typeOfDevice})</span>
                    )}
                    <span className="icon">
                        <LinkOutlined />
                    </span>
                </a>
            </div>
        </div>
    ));

    const Snippet = observer(() => {
        const eventTextMarkdown = (data?.eventText || [])
            .map(({ label, text }) => {
                const header = `# ${label}`;
                return header + "\n" + text;
            })
            .join("\n");

        if (!eventTextMarkdown) {
            return (
                <div className="text">
                    <div className="no-text">No event text</div>
                </div>
            );
        }
        return (
            <div className="text">
                <MarkdownViewer className="snippet" content={eventTextMarkdown} />
            </div>
        );
    });

    const deviceProblems = (data.deviceProblems ?? [])
        .map((problem) => {
            if (FDADeviceProblemCodes[problem]) {
                return `${problem} (${FDADeviceProblemCodes[problem]})`;
            }
            return problem;
        })
        .join(", ");

    const patientProblems = (data.patientProblems ?? [])
        .map((problem) => {
            if (FDAPatientProblemCodes[problem]) {
                return `${problem} (${FDAPatientProblemCodes[problem]})`;
            }
            return problem;
        })
        .join(", ");

    const sourceType = data?.sourceType?.join(", ");

    const evaluatedByManufacturer = () => {
        const evaluatedByManufacturer = data?.device?.evaluatedByManufacturer?.toUpperCase();
        if (evaluatedByManufacturer === "*") {
            return "No Answer Provided";
        }
        if (evaluatedByManufacturer === "R") {
            return "Device Not Returned To Manufacturer";
        }
        if (evaluatedByManufacturer === "I") {
            return "";
        }
        if (evaluatedByManufacturer === "Y") {
            return "Yes";
        }
        if (evaluatedByManufacturer === "N") {
            return "No";
        }
        return data?.device?.evaluatedByManufacturer;
    };

    const eventLocation = () => {
        if (data?.eventLocation?.toUpperCase() === "I") {
            return "No Information";
        }
        return data?.eventLocation;
    };

    const deviceOperator = () => {
        if (data?.device?.deviceOperator?.toUpperCase() === "I") {
            return "No Information";
        }
        return data?.device?.deviceOperator;
    };

    const singleUseDevice = () => {
        const singleUseDevice = data?.singleUseDevice?.toUpperCase();
        if (singleUseDevice === "*") {
            return "No Answer Provided";
        }
        if (singleUseDevice === "Y") {
            return "Yes";
        }
        if (singleUseDevice === "N") {
            return "No";
        }
        return data?.singleUseDevice;
    };

    const reportToFDA = () => {
        if (data?.reportToFDA === "*") {
            return "";
        }
        return data?.reportToFDA;
    };

    const reprocessed = () => {
        const reprocessed = data?.reprocessed?.toUpperCase();
        if (reprocessed === "I") {
            return "";
        }
        if (reprocessed === "Y") {
            return "Yes";
        }
        if (reprocessed === "N") {
            return "No";
        }
        return data?.reprocessed;
    };

    const healthProfessional = () => {
        const healthProfessional = data?.healthProfessional?.toUpperCase();
        if (healthProfessional === "I") {
            return "";
        }
        if (healthProfessional === "Y") {
            return "Yes";
        }
        if (healthProfessional === "N") {
            return "No";
        }
        if (healthProfessional === "U") {
            return "Unknown";
        }
        if (healthProfessional === "*") {
            return "No Answer Provided";
        }
        return data?.healthProfessional;
    };

    const reporterOccupation = () => {
        const reporterOccupation = data?.reporterOccupation?.toUpperCase();
        if (["", "1", "003"].includes(reporterOccupation)) {
            return "";
        }
        if (reporterOccupation === "117") {
            return "NURSE PRACTITIONER";
        }
        return data?.reporterOccupation;
    };

    const textKey = data?.eventText?.length > 0 && data?.eventText[0].textKey;

    const patientData = data?.patient?.length > 0 && data?.patient[0];
    const patientTreatment = patientData?.sequenceNumberTreatment?.join(",");

    const patientReceivedDate = patientData?.dateReceived;
    const patientSequenceNumber = patientData?.patientSequenceNumber;
    const showPatientTable =
        patientData?.sequenceNumberOutcome?.length > 0 &&
        patientData?.sequenceNumberOutcome[0] !== ""
            ? true
            : false;

    return (
        <>
            <Box className="details">
                <Title />
                <div className="content">
                    <table className="datatable contents">
                        <tbody>
                            {data.modelNumber !== "" ? (
                                <tr>
                                    <td>Model Number</td>
                                    <td>{data.modelNumber}</td>
                                </tr>
                            ) : null}

                            {data.device?.catalogNumber !== "" ? (
                                <tr>
                                    <td>Catalog Number</td>
                                    <td>{data.device?.catalogNumber}</td>
                                </tr>
                            ) : null}

                            <tr>
                                <td>Device Problem</td>
                                <td>{deviceProblems}</td>
                            </tr>

                            <tr>
                                <td>Patient Problem</td>
                                <td>{patientProblems}</td>
                            </tr>
                            {data.eventDate !== "" ? (
                                <tr>
                                    <td>Event Date</td>
                                    <td>{data.eventDate}</td>
                                </tr>
                            ) : null}
                            <tr>
                                <td>Event Type</td>
                                <td>{data.eventType}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <Snippet />
            </Box>

            <Box className="additional">
                <table className="datatable contents">
                    <tbody>
                        <tr>
                            <td>Brand Name</td>
                            <td>{data.brandName}</td>
                        </tr>
                        <tr>
                            <td>Type of Device </td>
                            <td>{data.device?.typeOfDevice}</td>
                        </tr>

                        {data.manufacturerName !== "" ? (
                            <tr>
                                <td>Manufacturer (Section D)</td>
                                <td>
                                    <div>{data.manufacturerName}</div>
                                    <div>{data.device?.manufacturerAddress1}</div>
                                    <div>{data.device?.manufacturerAddress2}</div>
                                    <div>
                                        {`${data.device?.manufacturerCity} ${data.device?.manufacturerState}`}
                                    </div>
                                    <div>{`${data.device?.manufacturerCountry} ${data.device?.manufacturerZipCode}`}</div>
                                </td>
                            </tr>
                        ) : null}

                        {data.manufacturerSectionG?.name !== "" ? (
                            <tr>
                                <td>Manufacturer (Section G)</td>
                                <td>
                                    <div>{data.manufacturerSectionG?.name}</div>
                                    <div>{data.manufacturerSectionG?.address1}</div>
                                    <div>{data.manufacturerSectionG?.address2}</div>
                                    <div>
                                        {`${data.manufacturerSectionG?.city} ${data.manufacturerSectionG?.state}`}
                                    </div>
                                    <div>{`${data.manufacturerSectionG?.country} ${data.manufacturerSectionG?.postalCode}`}</div>
                                </td>
                            </tr>
                        ) : null}

                        {data.manufacturerContact?.firstName !== "" ? (
                            <tr>
                                <td>Manufacturer Contact</td>
                                <td>
                                    <div>
                                        {`${data.manufacturerContact?.firstName}  ${data.manufacturerContact?.lastName}`}
                                    </div>
                                    <div>{data.manufacturerContact?.address1}</div>
                                    <div>{data.manufacturerContact?.address2}</div>
                                    <div>
                                        {`${data.manufacturerContact?.city} ${data.manufacturerContact?.state} ${data.manufacturerContact?.zipCode}`}
                                    </div>
                                    <div>{`${data.manufacturerContact?.country} ${data.manufacturerContact?.postalCode}`}</div>
                                    <div>{data.manufacturerContact?.local}</div>
                                </td>
                            </tr>
                        ) : null}

                        <tr>
                            <td>MDR Report Key</td>
                            <td>{data?.mdrReportKey}</td>
                        </tr>
                        <tr>
                            <td>MDR Text Key</td>
                            <td>{textKey}</td>
                        </tr>

                        <tr>
                            <td>Report Number</td>
                            <td>{data?.reportNumber}</td>
                        </tr>
                        <tr>
                            <td>Device Sequence Number</td>
                            <td>{data?.device?.deviceSequenceNumber}</td>
                        </tr>
                        <tr>
                            <td>Product Code</td>
                            <td>{data?.productCode}</td>
                        </tr>

                        <tr>
                            <td>Combination Product(Y/N)</td>
                            <td>{data?.device?.combinationProduct}</td>
                        </tr>
                        {data?.pmaPMN ? (
                            <tr>
                                <td>PMA/PMN Number</td>
                                <td>{data?.pmaPMN}</td>
                            </tr>
                        ) : null}

                        {data?.reporterCountryCode !== "" ? (
                            <tr>
                                <td>Reporter Country Code</td>
                                <td>{data?.reporterCountryCode}</td>
                            </tr>
                        ) : null}
                        {/* FIXME: Understand how to populate this field. Reports observed so far has always been 1.*/}
                        {/* <tr>
                            <td>Number of Events Reported</td>
                            <td>1</td>
                        </tr> */}
                        <tr>
                            <td>Summary Report (Y/N)</td>
                            <td>{data?.summaryReportFlag}</td>
                        </tr>
                        <tr>
                            <td>Report Source</td>
                            <td>{data?.reportSource?.replace(" report", "")}</td>
                        </tr>

                        {sourceType !== "" ? (
                            <tr>
                                <td>Source Type</td>
                                <td>{data?.sourceType?.join(", ")}</td>
                            </tr>
                        ) : null}
                        <tr>
                            <td>Reporter Occupation</td>
                            <td>{reporterOccupation()}</td>
                        </tr>
                        <tr>
                            <td>Type of Report</td>
                            <td>{data?.typeOfReport?.join(", ")}</td>
                        </tr>
                        <tr>
                            <td>Report Date</td>
                            <td>{data?.reportDate}</td>
                        </tr>

                        <tr>
                            <td>
                                <em>{data?.totalDevicesInvolved}</em> Device Was Involved in the
                                Event
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <em>{data?.totalPatientsInvolved}</em> Patient Was Involved in the
                                Event
                            </td>
                        </tr>
                        <tr>
                            <td>Date FDA Received</td>
                            <td>{data?.dateReceived}</td>
                        </tr>

                        <tr>
                            <td>Is This An Adverse Event Report?</td>
                            <td>{toYesNo(data?.adverseEventReport)}</td>
                        </tr>

                        <tr>
                            <td>Is This An Product Problem Report</td>
                            <td>{toYesNo(data?.productProblemReport)}</td>
                        </tr>
                        <tr>
                            <td>Device Operator</td>
                            <td>{deviceOperator()}</td>
                        </tr>

                        {data.modelNumber !== "" ? (
                            <tr>
                                <td>Device MODEL Number</td>
                                <td>{data?.modelNumber}</td>
                            </tr>
                        ) : null}

                        {data.device?.catalogNumber !== "" ? (
                            <tr>
                                <td>Device Catalogue Number</td>
                                <td>{data?.device?.catalogNumber}</td>
                            </tr>
                        ) : null}

                        {data.device?.lotNumber !== "" ? (
                            <tr>
                                <td>Device LOT Number</td>
                                <td>{data?.device?.lotNumber}</td>
                            </tr>
                        ) : null}

                        <tr>
                            <td>Was Device Available For Evaluation?</td>
                            <td>{data?.device?.availableForEvaluation}</td>
                        </tr>

                        {data?.healthProfessional !== "" ? (
                            <tr>
                                <td>Is The Reporter A Health Professional?</td>
                                <td>{healthProfessional()}</td>
                            </tr>
                        ) : null}
                        <tr>
                            <td>Was the Report Sent to FDA?</td>
                            <td> {reportToFDA()}</td>
                        </tr>
                        {data?.dateReportToFDA !== "" ? (
                            <tr>
                                <td>Date Report Sent to FDA</td>
                                <td>{data?.dateReportToFDA}</td>
                            </tr>
                        ) : null}

                        {data?.facilityAwareDate !== "" ? (
                            <tr>
                                <td>Distributor Facility Aware Date</td>
                                <td>{data?.facilityAwareDate}</td>
                            </tr>
                        ) : null}
                        {data?.eventLocation !== "" ? (
                            <tr>
                                <td>Event Location</td>
                                <td>{eventLocation()}</td>
                            </tr>
                        ) : null}

                        {data?.dateManufacturerReceived ? (
                            <tr>
                                <td>Date Manufacturer Received</td>
                                <td> {data?.dateManufacturerReceived}</td>
                            </tr>
                        ) : null}

                        {data?.dateReportToManufacturer ? (
                            <tr>
                                <td>Date Report To Manufacturer</td>
                                <td> {data?.dateReportToManufacturer}</td>
                            </tr>
                        ) : null}

                        <tr>
                            <td>Was Device Evaluated By Manufacturer</td>
                            <td>{evaluatedByManufacturer()}</td>
                        </tr>

                        {data?.deviceDateOfManufacturer !== "" ? (
                            <tr>
                                <td>Date Device Manufactured</td>
                                <td>{data?.deviceDateOfManufacturer}</td>
                            </tr>
                        ) : null}

                        <tr>
                            <td>Is The Device Single Use?</td>
                            <td>{singleUseDevice()}</td>
                        </tr>
                        <tr>
                            <td>
                                Is this a Reprocessed and
                                <br /> Reused Single-Use Device?
                            </td>
                            <td>{reprocessed()}</td>
                        </tr>

                        {/* //FIXME: Understand how to populate this field */}
                        {/* <tr>
                            <td>Type of Device Usage</td>
                            <td>Initial</td>
                        </tr> */}
                    </tbody>
                </table>

                {showPatientTable === true ? (
                    <div className="lastTable">
                        <table className="datatable contents">
                            <tbody>
                                <tr>
                                    <td>Patient TREATMENT DATA</td>
                                </tr>
                                <tr>
                                    <td>
                                        {`Date Received: ${patientReceivedDate} Patient Sequence Number ${patientSequenceNumber}`}
                                    </td>
                                </tr>

                                {patientTreatment !== "" ? (
                                    <>
                                        <tr>
                                            <td>Treatment</td>
                                        </tr>
                                        <tr>
                                            <td>{patientTreatment}</td>
                                        </tr>
                                    </>
                                ) : null}
                            </tbody>
                        </table>
                    </div>
                ) : null}
            </Box>
        </>
    );
});

export default Details;
