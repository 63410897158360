import { ReportDocumentSection } from "@app/constants";

export const decodeCommentIds = (commentIdString) => {
    return commentIdString ? commentIdString.split(",") : [];
};

export const encodeCommentIds = (commentIds) => {
    return commentIds.join(",");
};

export const hasComment = (commentIdString = "", commentId) => {
    return commentIdString.includes(commentId);
};

export const removeCommentFromIdString = (commentIdString = "", commentId) => {
    if (hasComment(commentIdString, commentId)) {
        const ids = decodeCommentIds(commentIdString);
        ids.splice(ids.indexOf(commentId), 1);

        return encodeCommentIds(ids);
    }

    return commentIdString;
};

export const addCommentToIdString = (commentIdString, commentId) => {
    if (!hasComment(commentIdString, commentId)) {
        return commentIdString?.length ? `${commentIdString},${commentId}` : `${commentId}`;
    }

    return commentIdString;
};

export const removeCommentFromContent = (editor, commentId) => {
    if (!editor) {
        return;
    }

    let tr = editor.state.tr;
    let changesMade = false;

    const handleInlineNode = (node, pos) => {
        node.marks.forEach((mark) => {
            if (mark.type.name === ReportDocumentSection.COMMENT.MARK_NAME) {
                const commentIds = decodeCommentIds(mark.attrs.comment);
                const updatedCommentIds = commentIds.filter((id) => id !== commentId);

                if (updatedCommentIds.length !== commentIds.length) {
                    const from = pos;
                    const to = pos + node.nodeSize;
                    const newAttrs = {
                        ...mark.attrs,
                        comment: encodeCommentIds(updatedCommentIds),
                    };

                    if (updatedCommentIds.length === 0) {
                        tr = tr.removeMark(from, to, mark.type);
                    } else {
                        tr = tr
                            .removeMark(from, to, mark.type)
                            .addMark(from, to, mark.type.create(newAttrs));
                    }
                    changesMade = true;
                }
            }
        });
    };

    const handleFullNodes = (node, pos) => {
        const commentIds = decodeCommentIds(node.attrs.comment);
        const updatedCommentIds = commentIds.filter((id) => id !== commentId);

        if (updatedCommentIds.length !== commentIds.length) {
            const newAttrs = {
                ...node.attrs,
                comment: encodeCommentIds(updatedCommentIds),
            };
            tr = tr.setNodeMarkup(pos, null, newAttrs);
            changesMade = true;
        }
    };

    editor.state.doc.descendants((node, pos) => {
        if (
            node.isInline &&
            node.marks.some((mark) => mark.type.name === ReportDocumentSection.COMMENT.MARK_NAME)
        ) {
            handleInlineNode(node, pos);
        } else if (
            node.attrs.comment &&
            ReportDocumentSection.COMMENT.FULL_NODES.includes(node.type.name)
        ) {
            handleFullNodes(node, pos);
        }
    });

    if (changesMade) {
        editor.view.dispatch(tr);
    }
};
