import { Tag } from "antd";
import React from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import Card from "@app/components/card/card";
import { MarkdownViewer } from "@app/components/markdown-editor/index";

import "./style/result.scoped.scss";

const Result = observer(({ event, className, keywords = [], ...rest }) => {
    const Title = observer(() => (
        <div className="title">
            <div>
                <Tag className="tag ant-tag-blue">{event.productCode}</Tag>
                <div className="label">
                    {event.brandName} [{event.reportNumber}]
                </div>
            </div>
            <div className="date">{event.dateReceived}</div>
        </div>
    ));

    const Snippet = observer(() => {
        const eventTextMarkdown = event.eventText
            .map(({ label, text }) => {
                const header = `# ${label}`;
                return header + "\n" + text;
            })
            .join("\n");

        if (!eventTextMarkdown) {
            return (
                <div className="text">
                    <div className="no-text">No event text</div>
                </div>
            );
        }

        return (
            <div className="text">
                <MarkdownViewer className="snippet" content={eventTextMarkdown} truncate={400} />
            </div>
        );
    });

    return (
        <Card className={classNames("result", className)} {...rest}>
            <Title />
            <div className="details">
                <span>Type: {event.eventType}</span>;&nbsp;
                <span>Manufacturer: {event.manufacturerName}</span>
                {event.modelNumber && (
                    <>
                        ;&nbsp;<span>Model: {event.modelNumber}</span>
                    </>
                )}
            </div>
            <div className="problems">
                <div>{event.deviceProblems.join(", ")}</div>
            </div>
            <Snippet />
        </Card>
    );
});

export default Result;
