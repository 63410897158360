import React from "react";
import { observer } from "mobx-react";
import { Form, Input } from "antd";

import Modal from "@app/components/modal";

import versionAliasStore from "@app/state/store/report-document/version-alias";

const CreateVersionModal = observer(() => {
    const {
        showCreateVersionAliasModal,
        onCloseCreateVersionModal,
        onSubmitCreateVersionForm,
        versionNames,
    } = versionAliasStore;
    const [form] = Form.useForm();
    const refReason = React.useRef(null);

    /**
     * Cancel the edit
     */
    const cancel = () => {
        onCloseCreateVersionModal();
        form.resetFields();
    };

    /**
     * Save the reason
     */
    const save = async ({ versionName }) => {
        await onSubmitCreateVersionForm({ versionName });
        form.resetFields();
    };

    return (
        <Modal
            title={"Create a Version"}
            visible={showCreateVersionAliasModal}
            okText="Create"
            onOk={form.submit.bind(form)}
            onCancel={cancel}
            width={600}
        >
            <div className="reason-form">
                <Form layout="vertical" form={form} onFinish={save}>
                    <p>You can create a version out of this history of changes.</p>
                    <Form.Item
                        label="Name"
                        name="versionName"
                        rules={[
                            {
                                required: true,
                                message: "Please enter the version name",
                                whitespace: true,
                            },
                            () => ({
                                validator(_, value) {
                                    const forbiddenWords = [...versionNames, "latest"];
                                    if (!value || !forbiddenWords.includes(value)) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error(
                                            "A version exists with this name. Please choose another.",
                                        ),
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input ref={refReason} />
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
});

export default CreateVersionModal;
