import React, { useState, useRef, useEffect } from "react";
import { observer } from "mobx-react";
import { Button } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import notify from "@app/components/notify/index";
import { CommentStatus } from "@app/constants";
import Details from "./chunks/details";
import Input from "./input";

import "./style/comment.scoped.scss";
import classNames from "classnames";

const Comment = observer(
    ({
        comment = {},
        onUpdate,
        onRemove,
        onResolve,
        onUnResolve,
        onAddReply,
        onUpdateReply,
        onRemoveReply,
        onFocus = () => {},
        className,
        focused = false,
        readonly = false,
        ...props
    }) => {
        const [replyFocus, setReplyFocus] = useState(focused);
        const hasReply = comment.replies?.length > 0;
        const ref = useRef();

        useEffect(() => {
            setReplyFocus(focused);
        }, [focused]);

        /**
         * save edited value
         */
        const save = async (value) => {
            if (value.trim() === "") {
                notify.error("Please enter any comment to save it");
                return;
            }
            const success = await onUpdate({ _id: comment._id, text: value });
            if (success) {
                comment.text = value;
            }
        };

        const addReply = async (text) => {
            if (onAddReply) {
                return await onAddReply({
                    comment,
                    text,
                });
            }
        };

        const updateReply = async ({ reply, text }) => {
            if (onUpdateReply) {
                await onUpdateReply({
                    comment,
                    reply,
                    replyId: reply._id,
                    text,
                });
            }
        };

        const removeReply = async (data) => {
            if (onRemoveReply) {
                await onRemoveReply({
                    ...data,
                    comment,
                });
            }
        };

        const removeComment = async () => {
            if (onRemove) {
                await onRemove();
            }
        };

        const onFocusComment = () => {
            if (comment.isFoundInDocument === false) {
                onFocus(comment._id, comment.section);
            } else {
                onFocus(comment._id);
            }
        };

        return (
            <div
                className={classNames("comment", { focused })}
                onClick={onFocusComment}
                {...props}
                tabIndex="-1"
                ref={ref}
                data-id={comment._id}
            >
                <Details
                    comment={comment}
                    onSave={save}
                    onRemove={removeComment}
                    readonly={readonly}
                />
                <Actions
                    comment={comment}
                    onResolve={onResolve}
                    onUnResolve={onUnResolve}
                    readonly={readonly}
                    onReplyFocus={() => {
                        setReplyFocus(true);
                        comment.expanded = true;
                    }}
                    onReplyAdd={addReply}
                    onReplySave={updateReply}
                    onReplyRemove={removeReply}
                    inputFocused={replyFocus}
                    onInputFocus={setReplyFocus}
                />

                {!hasReply && !readonly && (
                    <Input
                        className="controls"
                        onSave={addReply}
                        focus={replyFocus}
                        setFocus={setReplyFocus}
                    />
                )}
            </div>
        );
    },
);

const Actions = observer(
    ({
        comment,
        onResolve,
        onUnResolve,
        onReplyFocus,
        onReplyAdd,
        onReplySave,
        onReplyRemove,
        inputFocused,
        onInputFocus,
        readonly,
    }) => {
        const label =
            comment?.status === CommentStatus.PENDING ? "Mark as resolved" : "Mark as open";

        const hasReply = comment.replies?.length > 0;
        let replies = "No replies yet";

        if (hasReply) {
            replies =
                comment.replies?.length === 1 ? "1 Reply" : `${comment.replies?.length} replies`;
        }

        const toggleReplies = () => {
            comment.expanded = !comment.expanded;
        };

        const commentAction = () => {
            if (comment.status === CommentStatus.PENDING) {
                onResolve && onResolve();
            } else if (comment.status === CommentStatus.RESOLVED) {
                onUnResolve && onUnResolve();
            }
        };

        return (
            <>
                {!readonly && (
                    <div className="controls">
                        <span>
                            <Button type="link" onClick={onReplyFocus}>
                                Reply
                            </Button>
                        </span>
                        <span className="circle"></span>
                        <span className="action">
                            <Button type="link" onClick={commentAction}>
                                {label}
                            </Button>
                        </span>
                        <span className="count" onClick={toggleReplies}>
                            {hasReply && (
                                <>
                                    <Button type="link">{replies}</Button>
                                    <div className="toggle">
                                        <Button>
                                            {comment.expanded || readonly ? (
                                                <UpOutlined />
                                            ) : (
                                                <DownOutlined />
                                            )}
                                        </Button>
                                    </div>
                                </>
                            )}
                            {!hasReply && <div className="no-reply">{replies}</div>}
                        </span>
                    </div>
                )}

                {hasReply && (comment.expanded || readonly) && (
                    <Replies
                        replies={comment.replies}
                        onSave={onReplySave}
                        onRemove={onReplyRemove}
                        onAdd={onReplyAdd}
                        inputFocused={inputFocused}
                        onInputFocus={onInputFocus}
                        readonly={readonly}
                    />
                )}
            </>
        );
    },
);

// Handle All Replies
const Replies = observer(
    ({ replies, onAdd, onSave, onRemove, inputFocused, onInputFocus, readonly }) => {
        if (replies.length === 0) {
            return null;
        }

        return (
            <div className="replies">
                <>
                    {replies.map((reply) => {
                        return (
                            <div className="separator" key={reply._id}>
                                <Details
                                    comment={reply}
                                    onSave={(text) =>
                                        onSave({
                                            reply: reply,
                                            text,
                                        })
                                    }
                                    onRemove={() => {
                                        onRemove({ replyId: reply._id });
                                    }}
                                    readonly={readonly}
                                />
                            </div>
                        );
                    })}
                </>
                {!readonly && (
                    <Input
                        className="controls"
                        onSave={onAdd}
                        focus={inputFocused}
                        setFocus={onInputFocus}
                    />
                )}
            </div>
        );
    },
);

export default Comment;
