import React from "react";
import { observer } from "mobx-react";

import Alert from "@app/components/alert/alert";
import Document from "@app/state/model/document";

const PreviewStatus = observer(({ document }) => {
    if (Document.canPreview(document)) {
        return null;
    }

    if (document.previewStatus === "pending") {
        return <Alert status="info" description="We are generating a preview for this file." />;
    }

    if (document.previewStatus === "error" || document.previewStatus === "not_supported") {
        return (
            <Alert
                status="error"
                description="We failed to generate a preview for this file. Please upload preview PDF manually."
            />
        );
    }

    return null;
});

export default PreviewStatus;
