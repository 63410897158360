import React from "react";
import { observer } from "mobx-react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import classNames from "classnames";

import "./style/ordered.scoped.scss";

const List = observer(({ onChange, id = "list", children }) => {
    const onDragEnd = (item) => {
        // reordering was canceled
        if (!item.destination) {
            return;
        }

        let move = {
            id: item.draggableId,
            from: item.source.index,
            to: item.destination.index,
        };

        onChange && onChange(move);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={id}>
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        {children}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
});

List.Item = observer(({ index, id, disableDrag = false, children }) => {
    return (
        <Draggable key={id} draggableId={id} index={index} isDragDisabled={disableDrag}>
            {(provided, snapshot) => (
                <div
                    className={classNames("list-item", { dragging: snapshot.isDragging })}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    {children}
                </div>
            )}
        </Draggable>
    );
});

export default List;
