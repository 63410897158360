import React from "react";
import { observer } from "mobx-react";
import Modal from "@app/components/modal";

import "./legal.scoped.scss";

const TermsAndConditionsModal = observer(
    ({ visible, onOk, onCancel, closable = true, footer, width = "60rem", height = "50rem" }) => {
        return (
            <Modal
                bodyStyle={{ overflow: "auto", width, height }}
                title={`Terms & Conditions`}
                visible={visible}
                closable={closable}
                okText="Accept"
                cancelText="Decline"
                onOk={onOk}
                onCancel={onCancel}
                footer={footer}
                width={width}
                height={height}
            >
                <div className="legal">
                    <p>Last revised March 10, 2022</p>
                    <p>Welcome, and thank you for using Celegence’s CAPTIS platform.</p>
                    <p>
                        When you use our products and services (“Services”), you are agreeing to our
                        terms and conditions, you are agreeing to enter into a legally binding
                        contract with Celegence LLC (“Celegence”) (even if you are using our
                        Services on behalf of a client). If you do not agree to these Terms and
                        Conditions (“Terms”), do not click “Sign In” (or similar) and do not access
                        or otherwise use any of our Services. If you wish to terminate this
                        contract, at any time you can do so by closing your account and no longer
                        accessing or using our Services.
                    </p>
                    <p>
                        Celegence is the owner of web-based applications, platform and related
                        software applications, hardware, products and services for the life sciences
                        industry known as the CAPTIS Platform (the “<b>Platform</b>”) and Celegence
                        is granting access to you as the Customer the use of the Platform as
                        provided in this Agreement. To the extent additional rules or guidelines of
                        Celegence affect your use of our Platform, those rules and guidelines
                        (including the Privacy Policy) are hereby incorporated by reference into
                        these Terms. You may have signed an order for a subscription to the Platform
                        and a CAPTIS Platform Service Agreement, or other document with terms and
                        conditions (“Subscription Terms”). In the event of a conflict between these
                        Terms, our Privacy Policy or any applicable Subscription Terms, the
                        Subscription Terms shall control to the extent of such conflict.
                    </p>

                    <p>
                        In consideration of the mutual promises and agreements contained herein, the
                        parties (Celegence and you, “Customer”) agree as follows:
                    </p>

                    <p>
                        1. <u>Platform License</u>. In consideration for Customer’s usage of the
                        Platform, Celegence agrees to provide Customer access to the Platform
                        pursuant to this Agreement. Celegence hereby grants to Customer a limited,
                        non-transferable, non-sublicensable, revocable, limited license to use the
                        Platform in accordance with this Agreement. Celegence warrants that it has
                        full power and authority to grant the license hereunder. Celegence may
                        update or modify the Platform at any time. Customer hereby grants to
                        Celegence a perpetual, non-exclusive, nontransferable worldwide right to use
                        any data uploaded to the Platform by Customer or Users to carry out the
                        Purpose. Customer agrees to comply with applicable laws (including data
                        privacy laws) in connection with its use of the Platform.
                    </p>

                    <p>
                        2. <u>Restrictions on Use</u>. Customer shall not, and shall ensure that its
                        customers, vendors, authorized users or any third parties shall not, modify,
                        make derivative works of, disassemble, reverse compile, or reverse engineer
                        any part of the Platform, or access the Platform in order to build a similar
                        or competitive product or service. Customer will not copy, reproduce,
                        distribute, republish, download, display, post or transmit any party of the
                        Platform in any form or by any means. Customer acknowledges and agrees that
                        Celegence (or its applicable vendors or licensors) shall own all right,
                        title and interest in and to all intellectual property rights in the
                        Platform, and any feedback or suggestions provided by Customer relating to
                        the Platform or improvements made thereto. Customer shall not resell or
                        commercially exploit of any part of the Platform, or license, sell, rent,
                        transfer, assign, or make available, the Platform or any part thereof, to
                        any third party. Customer does not acquire any rights in the Platform other
                        than those expressly granted in this Agreement.
                    </p>

                    <p>
                        3. <u>Platform Use</u>. Customer is solely responsible for its agents and
                        employees (collectively “Users”) use of the Platform and will not allow its
                        Users to use the Platform in any way that violates or infringes upon the
                        intellectual property rights or the privacy or publicity rights of any
                        person or entity, or in any manner that is likely to damage or impair the
                        Platform. Neither Customer nor its Users will introduce any viruses, worms,
                        time bombs, Trojan horses and other malicious code, files, scripts, agents
                        or programs, or other malicious activity in the Platform.
                    </p>

                    <p>
                        4. <u>Disclaimers</u>. CELEGENCE DOES NOT REPRESENT THAT CUSTOMER’S USE OF
                        THE PLATFORM WILL BE SECURE, TIMELY, UNINTERRUPED OR ERROR FREE, OR THAT THE
                        PLATFORM WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THE PLATFORM
                        WILL OPERATE IN COMBINATION WITH OTHER HARDWARE, SOFTWARE, SYSTEMS OR DATA
                        NOT PROVIDED BY CELEGENCE OR THE OPERATION OF THE PLATFORM WILL BE SECURE OR
                        THAT CELEGENCE AND ITS THIRD-PARTY VENDORS WILL BE ABLE TO PREVENT THIRD
                        PARTIES FROM ACCESSING CUSTOMER’S DATA OR CONFIDENTIAL INFORMATION. THE
                        PLATFORM IS PROVIDED TO CUSTOMER ON AN “AS IS” AND “AS AVAILABLE” BASIS.
                    </p>

                    <p>
                        5. <u>Limitation of Liability</u>. IN NO EVENT SHALL CELEGENCE BE LIABLE FOR
                        ANY CONSEQUENTIAL, INDIRECT, EXEMPLARY, SPECIAL, PUNITIVE, OR INCIDENTAL
                        DAMAGES, INCLUDING WITHOUT LIMITATION, INTERRUPTION OF BUSINESS, LOST
                        PROFITS, LOST OR CORRUPTED DATA OR CONTENT, OR LOST REVENUE ARISING OUT OF
                        THIS AGREEMENT OR THE PLATFORM. CELEGENCE SHALL NOT BE LIABLE FOR ANY
                        DAMAGES RESULTING FROM THE LOSS OR CORRUPTION OF ANY DATA OR CONTENT WHETHER
                        RESULTING FROM DELAYS, NONDELIVERIES, MISDELIVERIES, SERVICE INTERRUPTIONS
                        OR OTHERWISE.
                    </p>

                    <p>
                        6. <u>Beta Usage</u>. Some CAPTIS platform may be in preview, testing, or
                        “beta” phase (each, a “Beta Offering”), for the purpose of evaluating
                        performance, identifying defects and obtaining feedback. Celegence LLC will
                        not be liable for any damages in connection with your use of any Beta
                        Offering. You are not required to use any Beta Offering, and we have no
                        obligation to release a final version of any Beta Offering.{" "}
                    </p>

                    <p>
                        7. <u>Confidentiality</u>. The parties shall keep the Terms of this
                        Agreement and any other information shared by either party hereunder
                        confidential and neither party may disclose the confidential information of
                        the other party to any third party; provided, however, Celegence may
                        disclose such information to its vendors to carry out the Purpose.
                    </p>

                    <p>
                        8. <u>Indemnity</u>. You agree to indemnify and hold the Celegence harmless
                        from any claim or demand, including reasonable attorney's fees and costs,
                        made by any third party due to or arising out of your use of the Platform in
                        a manner not permitted by these Terms, including without limitation your
                        actual or alleged violation of these Terms, or infringement of a third
                        party’s intellectual property or other rights by you, or another user of our
                        Platform using your computer, device or account.
                    </p>

                    <li>
                        9. <u>General</u>. This Agreement is the parties’ entire understanding and
                        agreement relating to the Platform. and supersedes any other conversations
                        or agreements between the parties related to the Platform. Each party is an
                        independent contractor of the other. Any rights not expressly granted herein
                        are reserved by Celegence. This Agreement is governed by Illinois law,
                        without giving effect to any choice of laws or conflicts of laws principles.
                        Exclusive venue for any dispute under this Agreement is the state and
                        federal courts located in Chicago, Illinois.
                    </li>
                </div>
            </Modal>
        );
    },
);

export default TermsAndConditionsModal;
