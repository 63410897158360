import React from "react";
import { observer } from "mobx-react";
import { Select } from "antd";
import classNames from "classnames";

export const TagPicker = observer(({ list, assigned, onChange, ...rest }) => {
    return (
        <div className={classNames("picker")} {...rest}>
            <Select
                mode="multiple"
                showArrow={true}
                placeholder="Please select categories"
                onChange={onChange}
                value={assigned}
            >
                {list?.map((category) => {
                    return (
                        <Select.Option key={category.name} assigned={category.name}>
                            <div>
                                <label>{category.name}</label>
                            </div>
                        </Select.Option>
                    );
                })}
            </Select>
        </div>
    );
});

export default TagPicker;
