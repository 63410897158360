import { observable } from "mobx";
import Model from "@app/state/model/base";

export default class NodePosition extends Model {
    @observable sectionId = undefined;
    @observable id = undefined;
    @observable type = undefined;
    @observable top = undefined;
    @observable left = undefined;
    @observable isInLandscape = false;
    @observable metadata = undefined;
}
