import { TextSelection } from "@tiptap/pm/state";
import { CellSelection, selectionCell, TableMap } from "@tiptap/pm/tables";

import TableStepper from "./table-stepper";

function cellsOverlapRectangle({ width, height, map }, rect) {
    let indexTop = rect.top * width + rect.left,
        indexLeft = indexTop;
    let indexBottom = (rect.bottom - 1) * width + rect.left,
        indexRight = indexTop + (rect.right - rect.left - 1);
    for (let i = rect.top; i < rect.bottom; i++) {
        if (
            (rect.left > 0 && map[indexLeft] === map[indexLeft - 1]) ||
            (rect.right < width && map[indexRight] === map[indexRight + 1])
        ) {
            return true;
        }
        indexLeft += width;
        indexRight += width;
    }
    for (let i = rect.left; i < rect.right; i++) {
        if (
            (rect.top > 0 && map[indexTop] === map[indexTop - width]) ||
            (rect.bottom < height && map[indexBottom] === map[indexBottom + width])
        ) {
            return true;
        }
        indexTop++;
        indexBottom++;
    }
    return false;
}

export function selectedRect(state) {
    const sel = state.selection;
    const $pos = selectionCell(state);
    const table = $pos.node(-1);
    const tableStart = $pos.start(-1);
    const map = TableMap.get(table);
    const rect =
        sel instanceof CellSelection
            ? map.rectBetween(sel.$anchorCell.pos - tableStart, sel.$headCell.pos - tableStart)
            : map.findCell($pos.pos - tableStart);
    return { ...rect, tableStart, map, table };
}

export const mergeCells = ({ state, dispatch, editor }) => {
    const sel = state.selection;

    if (!(sel instanceof CellSelection) || sel.$anchorCell.pos === sel.$headCell.pos) {
        return false;
    }

    const rect = selectedRect(state);

    const { map, table, left, right, top, bottom, tableStart } = rect;

    if (cellsOverlapRectangle(map, rect)) {
        return false;
    }

    const tableJSON = JSON.parse(JSON.stringify(table.toJSON()));

    const [cellToMergeToSlot] = [
        ...new TableStepper(tableJSON, {
            row: top,
            column: left,
            tableMode: "JSON",
        }),
    ];

    if (!cellToMergeToSlot) {
        return false;
    }

    const cellToMergeTo = cellToMergeToSlot.cell;

    const slots = [
        ...new TableStepper(tableJSON, {
            startRow: top,
            endRow: bottom - 1,
            startColumn: left,
            endColumn: right - 1,
            tableMode: "JSON",
        }),
    ];

    const cellsToDelete = [];

    for (const { cell, row, column } of slots) {
        if (cell !== cellToMergeTo) {
            if (row === cellToMergeToSlot.row) {
                cellToMergeTo.attrs.colspan = cellToMergeTo.attrs.colspan + cell.attrs.colspan;
                if (cell.attrs.colwidth) {
                    cellToMergeTo.attrs.colwidth = cellToMergeTo.attrs.colwidth.concat(
                        cell.attrs.colwidth,
                    );
                }
            } else if (column === cellToMergeToSlot.column) {
                cellToMergeTo.attrs.rowspan = cellToMergeTo.attrs.rowspan + cell.attrs.rowspan;
            }

            if (cell.content.length > 0) {
                cellToMergeTo.content = cellToMergeTo.content.concat(cell.content);
            }

            cellsToDelete.push(cell);
        }
    }

    const newTableContent = tableJSON.content.map((row) => {
        return { ...row, content: row.content.filter((cell) => !cellsToDelete.includes(cell)) };
    });

    let emptyRowCount = 0;

    for (let i = newTableContent.length - 1; i >= 0; i--) {
        const row = newTableContent[i];

        if (row.content.length === 0) {
            emptyRowCount++;
        } else if (emptyRowCount > 0) {
            // eslint-disable-next-line no-loop-func
            newTableContent[i].content = newTableContent[i].content.map((cell) => {
                return {
                    ...cell,
                    attrs: {
                        ...cell.attrs,
                        rowspan: cell.attrs.rowspan - emptyRowCount,
                    },
                };
            });

            emptyRowCount = 0;
        }
    }

    const newTableJSON = {
        ...tableJSON,
        content: newTableContent.filter((row) => row.content.length > 0),
    };

    const newTableNode = editor.schema.nodeFromJSON(newTableJSON);

    if (dispatch) {
        let tr = state.tr;
        tr.delete(tableStart - 1, tableStart + table.nodeSize - 1);
        tr.insert(tableStart - 1, newTableNode);
        tr.setSelection(TextSelection.create(tr.doc, state.selection.from));
        dispatch(tr);
    }

    return true;
};
