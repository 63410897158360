import React from "react";
import { observer } from "mobx-react";
import Filter from "@app/components/filter";
import Sidebar from "@app/components/filter/sidebar";
import format from "@app/lib/format";
import { Search, SearchDatabases, SearchType, SearchCategory } from "@app/constants";

const SearchFilter = observer(({ filter, config = {} }) => {
    let facets = filter.state.stats?.facets || {};
    let withSota = config.sota?.workflowEnabled;
    let source = facets.source || {};
    let tags = facets.tags || {};
    let type = facets.type || {};
    let category = facets.category || {};

    // get the list of available tags
    const searchTags = config.articleTags || [];

    const databases = [];
    for (const db of SearchDatabases) {
        if (config.search?.[db]) {
            databases.push({
                label: format.searchDb(db),
                value: db,
                count: source[db] ?? 0,
            });
        }
    }

    databases.push({
        label: format.searchDb(Search.OTHER),
        value: Search.OTHER,
        count: source[Search.OTHER] ?? 0,
    });

    return (
        <Sidebar filter={filter}>
            {databases.length > 1 && (
                <Filter.OptionGroup
                    filter={filter}
                    title="Database"
                    name="source"
                    groupKey="search.database"
                >
                    {databases.map((entry) => {
                        return (
                            <Filter.Option
                                key={entry.value}
                                label={entry.label}
                                value={entry.value}
                                count={entry.count}
                            />
                        );
                    })}
                </Filter.OptionGroup>
            )}

            {withSota && (
                <Filter.OptionGroup filter={filter} title="Type" name="type" groupKey="search.type">
                    <Filter.Option label="DUE" value={SearchType.SLR} count={type.slr ?? 0} />
                    <Filter.Option label="SoTA" value={SearchType.SOTA} count={type.sota ?? 0} />
                </Filter.OptionGroup>
            )}

            <Filter.OptionGroup
                filter={filter}
                title="Category"
                name="category"
                radio
                groupKey="search.category"
            >
                {Object.values(SearchCategory).map((key) => (
                    <Filter.Option key={key} label={key} value={key} count={category[key] ?? 0} />
                ))}
            </Filter.OptionGroup>

            {searchTags.length > 0 && (
                <Filter.OptionGroup filter={filter} title="Tags" name="tags" groupKey="search.tags">
                    {searchTags.map((tag) => {
                        return (
                            <Filter.Option
                                key={tag._id}
                                label={tag.label}
                                value={tag.label}
                                count={tags[tag.label] ?? 0}
                            />
                        );
                    })}
                </Filter.OptionGroup>
            )}

            <Filter.OptionGroup
                filter={filter}
                title="Comment Status"
                name={"commentStatus"}
                radio
                collapsed
                groupKey="search.comments"
            >
                <Filter.Option label="Resolved" value="resolved" />
                <Filter.Option label="Open" value="pending" />
            </Filter.OptionGroup>

            <Filter.OptionGroup
                filter={filter}
                title="Comments"
                name="subscribed"
                radio
                collapsed
                groupKey="search.subscription"
            >
                <Filter.Option label="My Discussions" value={true} />
            </Filter.OptionGroup>
        </Sidebar>
    );
});

export default SearchFilter;
