import React from "react";
import { observer } from "mobx-react";
import GroupContext from "../context";
import Group from "../group";

const OptionGroup = observer(
    ({ filter, name, title = "", radio = false, children, collapsed, groupKey }) => {
        let values = filter.state.filter[name] ?? [];

        if (!(values instanceof Array)) {
            values = [values];
        }

        /**
         * API interface used by the options to toggle and  check
         * their state
         */
        const api = {
            /**
             * Check if a value is selected
             * @param {String} value
             */
            isSelected(value) {
                return values.indexOf(value) > -1;
            },

            /**
             * Change group selection
             * @param {Object} item
             */
            select(item) {
                let updated = values.splice(0);

                if (item.selected) {
                    if (radio) {
                        updated = item.value;
                    } else {
                        updated.push(item.value);
                    }
                } else {
                    if (radio) {
                        updated = null;
                    } else {
                        let idx = updated.indexOf(item.value);
                        if (idx > -1) {
                            updated.splice(idx, 1);
                        }
                    }
                }

                filter.filter(name, updated);
            },
        };

        return (
            <GroupContext.Provider value={api}>
                <Group
                    className="option-group"
                    title={title}
                    hasActiveFilters={values.length}
                    collapsed={collapsed}
                    groupKey={groupKey ?? name}
                >
                    {children}
                </Group>
            </GroupContext.Provider>
        );
    },
);

export default OptionGroup;
