import React, { useEffect } from "react";
import { observer, useLocalStore } from "mobx-react-lite";
import classNames from "classnames";
import { Box } from "@app/components/box";
import Tags from "@app/components/tags/tags";
import moment from "moment";
import { Select, Input, Button, Alert, Switch } from "antd";
import {
    GlobalOutlined,
    SearchOutlined,
    SaveOutlined,
    QuestionCircleOutlined,
    DownOutlined,
    UpOutlined,
    FileDoneOutlined,
    DeleteOutlined,
    HistoryOutlined,
    FileSearchOutlined,
    PlusSquareOutlined,
    FullscreenExitOutlined,
} from "@ant-design/icons";
import notify from "@app/components/notify";
import Source, { SourceIcon } from "../source";
import Type from "../type";

import { Search, SearchType, MonitoredSystem, Status, SearchCategory } from "@app/constants";
import health from "@app/state/store/health";
import format from "@app/lib/format";

import { useLocation, useParams } from "react-router-dom";
import qs from "qs";
import confirm from "@app/components/confirm";
import { Copy } from "@app/components/clipboard";
import UploadButton from "@app/components/form/upload/button";
import FiltersSummary from "@app/components/search/summary";

import "../style/form.scoped.scss";
import Download from "@app/components/form/upload/download";

import report from "@app/state/store/report";
import query from "@app/state/store/report/query";

const Form = observer(({ className, onSearch, ...rest }) => {
    const refQuery = React.useRef(null);
    const refCategory = React.useRef(null);
    const config = report.config?.search || {};
    const withSota = report.config?.sota.workflowEnabled;
    const saved = query.data?.status === "saved";
    const saving = query.data?.status === "saving";
    const imported = query.data?.imported || !!query.data?.file;
    const editing = query.data?.editing;
    const state = useLocalStore(() => ({
        source: null,
        type: undefined,
        category: undefined,
        query: "",
        name: "",
        term: "",
        url: "",
        hasInfo: false,
        showInfo: false,
        showHistory: false,
        canSearch: true,
        needFile: true,
        canToggleImport: false,
        acceptFile: ".xlsx",
        file: null,
        healthy: true,
        tags: [],
        readonlyTags: [],
    }));

    let params = qs.parse(useLocation().search, { ignoreQueryPrefix: true });
    const { id } = useParams();

    // load the query and type values from the store
    useEffect(() => {
        let defaultSource = null;
        for (const db of Object.keys(config)) {
            if (!defaultSource && config[db]) {
                defaultSource = db;
            }
        }

        // load the source from the url parameter
        if (
            params.source === Search.OTHER ||
            (Object.keys(config).includes(params.source) && config[params.source] === true)
        ) {
            defaultSource = params.source;
        }

        state.source = query.data.source || defaultSource;
        query.filter.setSource(state.source);

        state.needFile = !!query.data.file;

        // flag embase as requiring a file
        if (state.source === Search.EMBASE) {
            state.needFile = true;
            state.acceptFile = ".xml";
        }

        // flag cochrane as requiring a file
        if (state.source === Search.COCHRANE) {
            state.needFile = true;
            state.acceptFile = ".ris";
        }

        // flag prospero as requiring a file
        if (state.source === Search.PROSPERO) {
            state.needFile = true;
            state.acceptFile = ".ris";
        }

        if (state.source === Search.PUBMED) {
            state.acceptFile = ".txt";
            state.canToggleImport = true;
        }

        // flag the import as requiring a file
        if (state.source === Search.OTHER) {
            if (query.data.file) {
                state.needFile = false;
                state.file = {
                    _id: query.data.file,
                };
            } else {
                state.needFile = true;
            }
            state.acceptFile = ".xls,.xlsx";
        }

        // hide the search button
        state.canSearch = !saved && !saving;

        // disable the search if the database has been disabled
        if (state.source && state.source !== Search.OTHER && !config[state.source]) {
            state.canSearch = false;
        }

        // set default search type when SoTA is disabled
        if (!withSota) {
            state.type = SearchType.SLR;
        }

        state.query = query.data.query || "";
        state.name = query.data.name || "";
        state.term = query.data.term || "";
        state.category = query.data.category;
        state.url = query.data.link || "";
        state.tags = query.data.tags || [];

        state.type = query.data.type || state.type;
        refQuery.current.focus();

        // load the history
        if (!state.needFile) {
            query.loadHistory(state.source);
        }

        query.upload = state.needFile;
    }, [state, config, params.source, saved, saving, withSota]);

    useEffect(() => {
        state.readonlyTags = query.data?.tags || [];
    }, []);

    // monitor health on change of source
    useEffect(() => {
        (async function () {
            if (Object.values(MonitoredSystem).includes(state.source)) {
                // always disable if saved
                if (saved) {
                    state.canSearch = false;
                    return;
                }

                // check if disabled
                if (!config[state.source]) {
                    state.canSearch = false;
                    return;
                }

                const status = await health.check(state.source);
                if (status !== Status.ONLINE) {
                    disable();
                } else {
                    enable();
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.source, saved]);

    if (!config) {
        return;
    }

    const disable = () => {
        state.healthy = false;
        state.canSearch = false;
    };

    const enable = () => {
        state.healthy = true;
        state.canSearch = true;
    };

    /**
     * Perform a search
     */
    const doSearch = async (saveHistory) => {
        if (!state.canSearch) {
            return;
        }

        if (state.query.trim() === "") {
            notify.error("Please enter a query to continue");
            refQuery.current.focus();
            return;
        }

        const { maxResults } = query.filter.value();

        if (
            maxResults !== undefined &&
            (maxResults <= 0 || maxResults?.toString().indexOf(".") !== -1)
        ) {
            notify.error(
                "Incorrect Limit results to value. Please provide a value more than zero (decimals not allowed)",
            );
            return;
        }

        if (!state.category) {
            notify.error("Please select a search category to continue");
            refCategory.current.focus();
            return;
        }

        if (!state.type) {
            notify.error("Please select a search type to continue");
            return;
        }

        if (state.needFile && !state.file?._id) {
            const proceed = await confirm(
                "You are trying to save a search without an import file. " +
                    "It will generate an empty search with zero results. Are you sure you want to continue?",
            );

            if (!proceed) {
                return;
            }
        }

        query.filter.filter("_id", id, { trigger: false });
        query.filter.filter("name", state.name, { trigger: false });
        query.filter.filter("query", state.query, { trigger: false });
        query.filter.filter("source", state.source, { trigger: false });
        query.filter.filter("type", state.type, { trigger: false });
        query.filter.filter("category", state.category, { trigger: false });
        query.filter.filter("file", state.file?._id, { trigger: false });
        query.filter.filter("tags", state.tags, { trigger: false });

        if (state.source !== Search.PUBMED) {
            query.filter.filter("excludeBooks", false, { trigger: false });
        }

        if (state.source !== Search.EUPMC) {
            query.filter.filter("expandSynonyms", false, { trigger: false });
        }

        // set the performedOn date for selected source with no field
        if ([Search.PUBMED, Search.EUPMC, Search.GOOGLE].includes(state.source)) {
            query.filter.state.filter.performedOn = moment().format("YYYY-MM-DD");
        }

        await query.search(saveHistory);

        // update some state details after the search
        state.name = query.data.name;
        state.term = query.data.term;
        state.url = query.data.link;

        // need to set fetching to false as PUBMED and EUPMC doesn't trigger fetching
        if (state.source === Search.PUBMED || state.source === Search.EUPMC) {
            query.fetching = false;
        }

        onSearch && onSearch();
    };

    /**
     * Perform a search
     */
    const onKeyDown = (event) => {
        if (event.key === "Enter") {
            if (!query.busy && state.canSearch) {
                event.preventDefault();
                doSearch(true);
            }
        }
    };

    /**
     * Toggle the info widget
     */
    const toggleInfo = () => {
        state.showInfo = !state.showInfo;
    };

    /**
     * Toggle the history widget
     */
    const toggleHistory = () => {
        state.showHistory = !state.showHistory;
    };

    /**
     * Change the search type
     */
    const onTypeChange = async (type) => {
        if (editing && type !== query.data.type) {
            const proceed = await confirm(
                "Changing the search type will reset all article actions " +
                    "to pending. Do you want to update the search type?",
            );

            if (!proceed) {
                return;
            }
        }

        state.type = type;
    };

    /**
     * Add a tag to the search
     */
    const onAddTag = (tag) => {
        state.tags = [...state.tags, tag];
    };

    /**
     * Add a tag to the search
     */
    const onRemoveTag = (tag) => {
        let tags = state.tags.filter((item) => item._id !== tag._id);
        state.tags = tags;
    };

    /**
     * Change the category
     */
    const onCategoryChange = (type) => {
        state.category = type;
    };

    /**
     * Handle file upload event
     */
    const uploadFile = (data) => {
        state.file = data;
    };

    const removeFile = () => {
        state.file = null;
    };

    const toggleImport = () => {
        if (state.needFile) {
            state.needFile = false;

            removeFile();
        } else {
            state.needFile = true;
        }

        query.upload = state.needFile;
    };

    const loadCriteria = (record) => {
        // we can not load history entries from other search databases
        if (record.source !== state.source) {
            return;
        }

        state.query = record.query;
        query.filter.filter("excludeBooks", record.excludeBooks, { trigger: false });
        query.filter.filter("humanOnly", record.humanOnly, { trigger: false });
        query.filter.filter("expandSynonyms", record.expandSynonyms, { trigger: false });
        query.filter.filter("minPublicationDate", record.minPublicationDate, { trigger: false });
        query.filter.filter("maxPublicationDate", record.maxPublicationDate, { trigger: false });
        query.filter.filter("publicationTypes", record.publicationTypes, { trigger: false });
        query.filter.filter("maxResults", record.maxResults, { trigger: false });
        query.filter.filter("medline", record.medline, { trigger: false });
        query.filter.filter("languageCode", record.languageCode, { trigger: false });
        query.filter.filter(
            "year",
            {
                min: record.minPublicationYear,
                max: record.maxPublicationYear,
            },
            { trigger: false },
        );

        // close the history drawer
        toggleHistory();

        if (!state.needFile) {
            doSearch(false);
        }
    };

    // determine if we have additional info to show
    const hasInfo = !!state.term && !imported;
    const hasHistory = !saved && query.history.records.length > 0;
    return (
        <Box className={classNames("search-form", className)} {...rest}>
            {!state.healthy && (
                <div className="message">
                    <Alert
                        description="Unfortunately this search database is offline, and we are unable to perform a search at this time. Please come back and check again after a few hours."
                        type="error"
                        className="alert"
                        showIcon
                    />
                </div>
            )}

            <div className="search-container items-center">
                <div className="col auto source mb-10">
                    {state.source && (
                        <div className="source-container">
                            <Source name={state.source} />
                        </div>
                    )}
                </div>
                <div className="items-center name-group search mb-10">
                    <div className="col auto label">Search Name</div>
                    <div className="col name">
                        <Input
                            value={state.name}
                            onChange={(event) => (state.name = event.target.value)}
                            className="name-input"
                            disabled={!state.canSearch}
                            placeholder="Search name (leave blank for a system generated name)"
                            allowClear
                        />
                    </div>
                </div>
            </div>
            <div className="row mb-10 items-center">
                <div className="col query ">
                    <Input.TextArea
                        value={state.query}
                        onChange={(event) => (state.query = event.target.value)}
                        autoSize={{ minRows: 1, maxRows: 3 }}
                        ref={refQuery}
                        onKeyDown={onKeyDown}
                        className="query-field"
                        disabled={!state.canSearch}
                        placeholder="Please enter search criteria"
                        allowClear
                    />
                </div>
            </div>
            {state.file && !query.data?.file && (
                <div className="file">
                    <div className="icon">
                        <FileDoneOutlined />
                    </div>
                    <div className="name">{state.file.name}</div>
                    <div className="remove">
                        <Button
                            type="icon"
                            className="btn"
                            icon={<DeleteOutlined />}
                            onClick={removeFile}
                        />
                    </div>
                </div>
            )}
            {query.data?.file && !editing && (
                <Download file={query.data.file}>
                    <div className="file download">
                        <div className="icon">
                            <FileDoneOutlined />
                        </div>
                        <div className="name">Imported File</div>
                    </div>
                </Download>
            )}
            <div className="row controls middle">
                {withSota && (
                    <div className="type col auto">
                        <Select
                            value={state.type}
                            onChange={onTypeChange}
                            style={{ width: 100 }}
                            disabled={!state.canSearch || editing}
                            placeholder="Type"
                            suffixIcon={!editing && <DownOutlined />}
                        >
                            <Select.Option value={SearchType.SLR}>
                                <Type value={SearchType.SLR} />
                            </Select.Option>

                            <Select.Option value={SearchType.SOTA}>
                                <Type value={SearchType.SOTA} />
                            </Select.Option>
                        </Select>
                    </div>
                )}

                <div className="grey col auto">
                    <Select
                        value={state.category}
                        onChange={onCategoryChange}
                        style={{ width: 170 }}
                        disabled={!state.canSearch}
                        ref={refCategory}
                        placeholder="Category"
                    >
                        <Select.Option value={SearchCategory.SYSTEMATIC_SEARCH}>
                            <FileSearchOutlined /> {SearchCategory.SYSTEMATIC_SEARCH}
                        </Select.Option>
                        <Select.Option value={SearchCategory.GREY_LITERATURE}>
                            <PlusSquareOutlined /> {SearchCategory.GREY_LITERATURE}
                        </Select.Option>
                        <Select.Option value={SearchCategory.FOCUSED_SEARCH}>
                            <FullscreenExitOutlined /> {SearchCategory.FOCUSED_SEARCH}
                        </Select.Option>
                    </Select>
                </div>

                {hasInfo && (
                    <div
                        className={classNames("info-toggle col auto", {
                            active: state.showInfo,
                        })}
                    >
                        <Button icon={<QuestionCircleOutlined />} onClick={toggleInfo}>
                            {state.showInfo ? <UpOutlined /> : <DownOutlined />}
                        </Button>
                    </div>
                )}

                {hasHistory && (
                    <div
                        className={classNames("history-toggle col auto", {
                            active: state.showHistory,
                        })}
                    >
                        <Button icon={<HistoryOutlined />} onClick={toggleHistory}>
                            {state.showHistory ? <UpOutlined /> : <DownOutlined />}
                        </Button>
                    </div>
                )}

                {state.canSearch && state.canToggleImport && (
                    <div className="import-toggle col auto">
                        <Switch
                            checked={state.needFile}
                            checkedChildren="Import"
                            unCheckedChildren="Import"
                            onChange={toggleImport}
                        />
                    </div>
                )}

                {state.canSearch && (
                    <div className="button col text-right">
                        <div className="button-group">
                            {state.needFile && (
                                <UploadButton onChange={uploadFile} accept={state.acceptFile} />
                            )}

                            <Button
                                type="primary"
                                onClick={() => doSearch(true)}
                                icon={state.needFile ? <SaveOutlined /> : <SearchOutlined />}
                            >
                                {state.needFile ? "Save" : "Search"}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            {hasInfo && state.showInfo && (
                <div className="info">
                    <div className="header">
                        Search String
                        <div className="actions">
                            <Copy text={state.term} icon={true} className="icon" />
                            {state.url && (
                                <a
                                    href={state.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="icon"
                                >
                                    <GlobalOutlined />
                                </a>
                            )}
                        </div>
                    </div>
                    <div className="body">
                        <div className="query">{state.term}</div>
                    </div>
                </div>
            )}
            {hasHistory && state.showHistory && (
                <div className="history">
                    <div className="header">Search History</div>
                    <div className="body">
                        {query.history.records.map((record) => {
                            return (
                                <div
                                    key={record._id}
                                    className={classNames("entry", {
                                        active: record.source === state.source,
                                    })}
                                    onClick={() => {
                                        loadCriteria(record);
                                    }}
                                >
                                    <div className="results">
                                        {record.results} {"result(s)"}
                                    </div>
                                    <div className="time">on {format.datetime(record.created)}</div>
                                    <div className="icons">
                                        <div className="icon source">
                                            <SourceIcon name={record.source} />
                                        </div>
                                        <div className="icon type">
                                            <Type value={record.type} iconOnly />
                                        </div>
                                    </div>
                                    <div className="query">Query: {record.query}</div>
                                    <FiltersSummary search={record} className="filters" />
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
            <SearchTags
                edit={state.canSearch}
                onAdd={onAddTag}
                onRemove={onRemoveTag}
                tags={state.tags}
                readonlyTags={state.readonlyTags}
            />
        </Box>
    );
});

const SearchTags = ({ onAdd, onRemove, edit = false, tags = [], readonlyTags, ...rest }) => {
    if (!edit && !tags.length) {
        return null;
    }

    return (
        <div className={classNames("search-tags")} {...rest}>
            <div className="head">Search Tags</div>
            <div className="tags">
                <Tags
                    assignedTags={tags}
                    availableTags={report.config?.articleTags}
                    edit={edit}
                    onRemove={onRemove}
                    onAdd={onAdd}
                    readonlyTags={readonlyTags}
                />
            </div>
        </div>
    );
};

export default Form;
