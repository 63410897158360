import { default as React, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { useLocation, useHistory } from "react-router-dom";
import { default as qs } from "qs";

import { AppendixSections as Sections } from "@app/constants";
import { getPageWidth } from "@app/components/report-document/editor/utils";

import documentStore from "@app/state/store/report-document/document";
import { removeInsDelMarkers } from "@app/components/report-document/editor/modules/diff/diff-utility";

import "./styles/section.scoped.scss";

export const Appendix = observer(({ size = "A4", appendix }) => {
    const history = useHistory();
    const location = useLocation();

    const appendixRef = useRef(null);
    const tablesRef = useRef(null);
    const figuresRef = useRef(null);

    const figures = documentStore.figures;
    const tables = documentStore.tables;

    const width = getPageWidth(size);
    const refs = {
        [Sections.APPENDIX]: appendixRef,
        [Sections.TABLES]: tablesRef,
        [Sections.FIGURES]: figuresRef,
    };

    /**
     * Scroll to an item
     */
    const scrollTo = (id) => {
        history.push({
            pathname: history.location.pathname,
            search: `?node=${id}`,
        });
    };

    useEffect(() => {
        const params = qs.parse(location.search, { ignoreQueryPrefix: true });
        if (params.section in refs) {
            const ref = refs[params.section];
            if (ref && ref.current) {
                ref.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        }
    }, [location]);

    if (!appendix.hasAppendix) {
        return null;
    }

    return (
        <div ref={appendixRef}>
            {appendix.hasTables && (
                <div className={"section"} style={{ width }} ref={tablesRef}>
                    <div className="header">
                        <div className="title">List of Tables</div>
                    </div>
                    <div className="section-content appendix-section">
                        {tables.map((table) => {
                            return (
                                <div
                                    className="list-entry"
                                    key={table.id}
                                    dangerouslySetInnerHTML={{
                                        __html: removeInsDelMarkers(table.fullCaption),
                                    }}
                                    onClick={() => scrollTo(table.id)}
                                ></div>
                            );
                        })}
                    </div>
                </div>
            )}

            {appendix.hasFigures && (
                <div className="section" style={{ width }} ref={figuresRef}>
                    <div className="header">
                        <div className="title">List of Figures</div>
                    </div>
                    <div className="section-content appendix-section">
                        {figures.map((figure) => {
                            return (
                                <div
                                    className="list-entry"
                                    key={figure.id}
                                    dangerouslySetInnerHTML={{
                                        __html: removeInsDelMarkers(figure.fullCaption),
                                    }}
                                    onClick={() => scrollTo(figure.id)}
                                ></div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
});

export default Appendix;
