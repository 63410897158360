import { observable, action } from "mobx";

const makeKeyPartFromArgs = (args) => {
    if (!args) {
        return;
    }

    if (Array.isArray(args) && args.length > 0) {
        return args.sort().map((arg) => makeKeyPartFromArgs(arg));
    }

    if (typeof args === "object") {
        return Object.keys(args)
            .sort()
            .map((key) => makeKeyPartFromArgs(args[key]));
    }

    return args;
};

export const LOADING_STATES = {
    LOADING: "loading",
    LOADED: "loaded",
    ERROR: "error",
};

class LoadingStore {
    @observable
    loadingStates = new Map();

    @observable
    errors = new Map();

    getKey(args) {
        return makeKeyPartFromArgs(args).join("\\");
    }

    @action
    setLoadingState(actionKey, state) {
        this.loadingStates.set(actionKey, state);
    }

    @action
    setError(actionKey, error) {
        this.loadingStates.set(actionKey, LOADING_STATES.ERROR);
        this.errors.set(actionKey, error);
    }

    @action
    deleteError(actionKey) {
        this.errors.delete(actionKey);
    }

    @action
    resetForKey(actionKey) {
        this.loadingStates.delete(actionKey);
        this.errors.delete(actionKey);
    }

    @action
    startLoading(actionKey) {
        this.setLoadingState(actionKey, LOADING_STATES.LOADING);
        this.errors.delete(actionKey);
    }
}

export default new LoadingStore();
