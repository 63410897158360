import React, { useEffect } from "react";
import { observer, useLocalStore } from "mobx-react";
import { Button } from "antd";
import { useHistory, useParams } from "react-router-dom";
import Page from "@app/components/page/page";
import UserPicker from "@app/components/user/picker";
import Group from "@app/components/setup/review/group";
import StageModal from "@app/components/setup/review/stage-modal";
import Loader from "@app/components/loader/content";
import confirm from "@app/components/confirm";
import notify from "@app/components/notify";

import project from "@app/state/store/projects/details";
import documentsStore from "@app/state/store/report-document/report-documents";
import { ReportDocumentStatus } from "@app/constants";

const Review = observer(() => {
    const history = useHistory();
    const { id, documentId } = useParams();
    const state = useLocalStore(() => ({
        showStageModal: false,
        showUserModal: false,
        stage: null,
        saving: false,
        complete: {},
        stages: [],
        role: [],
    }));

    const reportDocument = documentsStore.reportDocument;
    const config = reportDocument?.reviewConfig;
    const loaded = !!config;

    useEffect(() => {
        documentsStore.setProjectId(id);
        documentsStore.load(documentId);
    }, [id, documentId]);

    useEffect(() => {
        state.complete = {};

        if (config?.stages) {
            let activeStage = config.stages.findIndex(
                (stage) => stage._id === reportDocument.review?.stage?._id,
            );

            if (activeStage < 0 && reportDocument.status === ReportDocumentStatus.REVIEWED) {
                activeStage = config?.stages.length - 1;
            }

            config.stages.map((stage, i) => {
                if (i < activeStage) {
                    state.complete[stage._id] = true;
                }
            });
        }
    }, [reportDocument, config]);

    const handleClose = () => {
        history.push(`../${documentId}`);
    };

    /**
     * Handle the user add event
     */
    const addUsers = async (list) => {
        if (state.saving) {
            return;
        }

        state.stage.reviewers = [...state.stage.reviewers, ...list];
        await updateStage(state.stage);
        closePicker();
    };

    /**
     * Handle the user remove event
     */
    const removeUser = async (id) => {
        if (state.stage.reviewers.length === 1) {
            notify.warn(
                <p>
                    <span>You cannot remove the last user from the stage!</span>
                    <br />
                    <span>
                        If you want to replace this user, please add the new one before removing
                        this.
                    </span>
                    <br />
                    <span>Alternatively, you can remove the stage itself.</span>
                </p>,
            );
            return;
        }

        const proceed = await confirm("Are you sure you want to remove this user?");
        if (proceed) {
            const index = state.stage.reviewers.findIndex((item) => item._id === id);
            state.stage.reviewers.splice(index, 1);

            await updateStage(state.stage);
        }
    };

    const showPicker = () => {
        state.showUserModal = true;
    };

    const closePicker = () => {
        state.showUserModal = false;
    };

    const updateStage = async (data) => {
        let stage;

        // validate for duplicate title
        const duplicate = config.stages.find((stage) => {
            const sameTitle = String(stage.title).trim() === String(data.title).trim();
            return sameTitle && stage._id !== data._id;
        });
        if (duplicate) {
            notify.error("A stage with the same name already exists");
            return;
        }

        if (state.saving) {
            return;
        }

        state.saving = true;

        if (data._id) {
            stage = config.stages.find((item) => {
                return item._id === data._id;
            });
        }

        if (!stage) {
            stage = { ...data };
            config.stages.push(stage);
        }

        stage.title = data.title;

        await documentsStore.update(documentId, { reviewConfig: config });
        closeStageModal();
        state.saving = false;
    };

    const deleteStage = async (stage) => {
        const proceed = await confirm("Are you sure you want to remove this stage?");
        if (!proceed) {
            return;
        }

        config.stages = config.stages.filter((el) => el._id !== stage._id);
        await documentsStore.update(documentId, { reviewConfig: config });
    };

    const showStageModal = () => {
        state.showStageModal = true;
    };

    const closeStageModal = () => {
        state.showStageModal = false;
        state.stage = null;
    };

    return (
        <>
            <Page>
                <Page.Header title="Review Setup" closable onClose={handleClose}>
                    <Page.Header.Right>
                        {loaded && (
                            <Button
                                type="primary"
                                onClick={() => {
                                    state.stage = null;
                                    showStageModal();
                                }}
                            >
                                Add review stage
                            </Button>
                        )}
                    </Page.Header.Right>
                </Page.Header>
                <Page.Body maxWidth={1000}>
                    {loaded &&
                        config.stages.map((stage, i) => {
                            return (
                                <Group
                                    key={i}
                                    title={stage.title}
                                    users={stage.reviewers || []}
                                    complete={state.complete[stage._id]}
                                    onAdd={() => {
                                        state.stage = stage;
                                        showPicker();
                                    }}
                                    onEdit={() => {
                                        state.stage = stage;
                                        showStageModal();
                                    }}
                                    onDelete={() => deleteStage(stage)}
                                    onRemove={(id) => {
                                        state.stage = stage;
                                        removeUser(id);
                                    }}
                                />
                            );
                        })}

                    <StageModal
                        visible={state.showStageModal}
                        stage={state.stage}
                        onSave={updateStage}
                        onCancel={closeStageModal}
                    />

                    <UserPicker
                        onClose={closePicker}
                        onPick={addUsers}
                        visible={state.showUserModal}
                        role={state.role}
                        project={project.id}
                        client={project.data.client._id}
                        exclude={state.stage?.reviewers || []}
                        title="Add Users"
                        addButton="Add"
                        reportDocumentReview={true}
                    />

                    <Loader loading={!loaded} />
                </Page.Body>
            </Page>
        </>
    );
});

export default Review;
