export const getOffsetsFromText = (startDiv, selectedText) => {
    const startText = startDiv.textContent;

    // Initialize the offsets to be full text by default
    let startOffset = 0;
    let endOffset = startText.length;

    if (!selectedText.startsWith(startText)) {
        while (
            startOffset < startText.length &&
            !selectedText.startsWith(startText.substring(startOffset))
        ) {
            startOffset++;
        }
    }

    return { startOffset, endOffset };
};
