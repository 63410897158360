import React from "react";
import { useHistory } from "react-router-dom";
import { Page } from "@app/components/page";
import { Copy } from "@app/components/clipboard";
import { Box } from "@app/components/box";
import "../style/common.scoped.scss";

/**
 *
 * TO DO: This Wrapper can go one level above, if it still suits Reports for other CER Sections.
 * Currently, We are using this Wrapper only for SLR Report and hence inside slr folder.
 */

const ReportWrapper = ({ name = "DUE Report", component, maxWidth = 800 }) => {
    const history = useHistory();

    /**
     * Close the current report
     */
    const close = () => {
        history.push("../report");
    };

    return (
        <Page className="setup">
            <Page.Header title={name} closable onClose={close}>
                <Page.Header.Right>
                    <Copy component={component} className="copy-style" />
                </Page.Header.Right>
            </Page.Header>
            <Page.Body maxWidth={maxWidth}>
                <Box className="display-style">{component}</Box>
            </Page.Body>
        </Page>
    );
};

export default ReportWrapper;
