import React from "react";
import classNames from "classnames";
import { Tag, Button, Menu, Dropdown, Tooltip } from "antd";
import { TagsOutlined } from "@ant-design/icons";

import "./style/tags.scoped.scss";
import "./style/tags.scss";

const Tags = ({
    assignedTags = [],
    availableTags = [],
    className,
    edit = false,
    onRemove,
    onAdd,
    readonlyTags = [],
}) => {
    const canAdd = assignedTags.length < availableTags?.length;
    const MAX_LENGTH = 15;

    const onClose = (tag) => {
        onRemove && onRemove(tag);
    };

    const add = (data) => {
        const tag = availableTags.find((el) => el._id === data.key);

        if (tag) {
            onAdd && onAdd(tag);
        }
    };

    const menu = (
        <Menu onClick={add}>
            {availableTags
                ?.filter((tag) => {
                    return !assignedTags.find((item) => item._id === tag._id);
                })
                .map((tag) => {
                    return (
                        <Menu.Item key={tag._id}>
                            <div className={tag.color && `tag-menu-item ${tag.color}`}>
                                <label>{tag.label}</label>
                            </div>
                        </Menu.Item>
                    );
                })}
        </Menu>
    );

    return (
        <div className={classNames("tags", className)}>
            {assignedTags.map((tag) => {
                const { color, label = "" } = tag;
                if (!label) {
                    return;
                }
                const lengthyTag = label.length > MAX_LENGTH;
                const tagElement = (
                    <Tag
                        key={label}
                        color={color}
                        className="tag"
                        closable={edit && !readonlyTags.some((tag) => tag.label === label)}
                        visible={true}
                        onClose={() => {
                            onClose(tag);
                        }}
                    >
                        {lengthyTag ? `${label.slice(0, MAX_LENGTH)}...` : label}
                    </Tag>
                );

                return lengthyTag ? (
                    <Tooltip placement="left" title={label} key={label}>
                        {tagElement}
                    </Tooltip>
                ) : (
                    tagElement
                );
            })}

            {edit && canAdd && (
                <div className="add">
                    <Dropdown
                        placement="bottomRight"
                        overlay={menu}
                        trigger={["click"]}
                        overlayClassName="tag-picker"
                    >
                        <Button icon={<TagsOutlined />} size="small">
                            <span className="text">+</span>
                        </Button>
                    </Dropdown>
                </div>
            )}
        </div>
    );
};

export default Tags;
