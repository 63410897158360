import React from "react";
import { observer } from "mobx-react";

import "./style/report-document-sections.scoped.scss";

const ReportDocumentSections = observer(({ data }) => {
    return (
        <div className="sectionsContainer">
            {data.map((section) => (
                <div className="section">
                    <span>{`${section.displayPos} ${section.title}`}</span>
                    {section.toc && <b> TOC</b>}
                </div>
            ))}
        </div>
    );
});

export default ReportDocumentSections;
