import { action } from "mobx";
import { v4 as uuid } from "uuid";
import BaseStore from "../base";
import { events } from "@app/lib/store";
import http from "@app/lib/http";
import Client from "@app/state/model/client";
import FilterState from "@app/components/filter/state";

const STAGGER_ID = uuid();

/**
 * State management controlling the list of clients in
 * the client management sections
 */
export class ClientList extends BaseStore {
    filter = null;

    /**
     * Observable store data
     */
    observable() {
        return {
            list: [],
            loading: false,
        };
    }

    constructor() {
        super();

        // handle client events
        events.on("client.update", this.load.bind(this));
        events.on("client.delete", this.load.bind(this));

        this.filter = new FilterState({
            default: {
                search: "",
            },
        });

        this.filter.on("find", () => {
            this.load();
        });
    }

    /**
     * Load a list od clients from the backend
     */
    @action
    async load() {
        this.loading = true;

        let filter = this.filter.value();
        let { data } = await http.get("/clients", filter).stagger(STAGGER_ID);

        this.list = data.list.map((entry) => {
            return new Client(entry);
        });

        // update the query stats
        this.filter.stats(data.stats);
        this.loading = false;
    }

    /**
     * Remove the client
     */
    @action
    async remove(id) {
        this.loading = true;
        let { data } = await http.delete(`/client/${id}`);

        // emit the delete event
        let client = new Client(data);
        events.emit("client.delete", client);

        this.loading = true;
    }
}

export default new ClientList();
