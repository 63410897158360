import EventEmitter from "events";

// timeout before trying to restore the web socket connection
const RECONNECT_TIMEOUT = 5 * 1000;

class Socket extends EventEmitter {
    url = null;
    socket = null;
    reconnect = false;
    timeout = null;
    mock = false;

    /**
     * Create a web socket connection to the backend
     */
    connect(url) {
        if (url) {
            // append the current domain name to the url if no domain is specified
            if (!url.startsWith("http")) {
                let location = window.location;
                let base = `${location.protocol}//${location.host}`;
                url = base + url;
            }

            // determine the secure flag
            let secure = url.indexOf("https") === 0;
            let proto = secure ? "wss" : "ws";
            let address = url.replace(/^https?/, proto);

            // ave the url for reconnect attempts
            this.url = address;
        }

        // we are currently trying to connect
        if (this.socket || this.mock) {
            return;
        }

        this.reconnect = true;
        this.socket = new WebSocket(this.url);

        // handle incoming socket messages
        this.socket.onmessage = (e) => {
            var msg = JSON.parse(e.data);
            this.emit(msg.event, msg.data);
        };

        // Start the connection heartbeat when connected
        this.socket.onopen = () => {
            this.timeout = RECONNECT_TIMEOUT;
            this.emit("connect");
        };

        // Handle connection error and try to reconnect.
        this.socket.onclose = () => {
            this.emit("disconnect");

            // try to reconnect and increase the retry timeout
            if (this.reconnect) {
                setTimeout(this.connect.bind(this), this.timeout);
                this.timeout = this.timeout * 2;
            }

            this.socket = null;
        };
    }

    /**
     * Close the connection to the backend server
     */
    disconnect() {
        this.reconnect = false;

        if (this.socket) {
            this.socket.close();
            this.socket = null;
        }

        this.emit("disconnect");
    }

    /**
     * Send a message to the server
     */
    send(event, data = {}, args = {}) {
        var message = { event, data };

        try {
            this.socket.send(JSON.stringify(message));
        } catch (ex) {
            console.error(`[socket] Error sending message. State: ${this.socket.readyState}`);
            throw ex;
        }
    }
}

const ws = new Socket();
export { ws, Socket };
