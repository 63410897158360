import { computed } from "mobx";
import { startCase } from "lodash";

import BaseStore from "../base";
import CrossReference from "@app/state/model/report-document/cross-reference";
import { AppendixSections as Sections } from "@app/constants";
import documentStore from "./document";
import report from "@app/state/store/report";

export class CrossReferenceStore extends BaseStore {
    static staticSections = Object.values(Sections).map(
        (sectionName) =>
            new CrossReference({
                id: sectionName,
                type: CrossReference.SECTION,
                label: startCase(sectionName),
            }),
    );

    @computed
    get crossReferences() {
        const references = [];

        const styleConfig = report.config?.reportDocuments?.style ?? {};

        documentStore.sections.map((section) => {
            const ref = section.number
                ? `Section ${section.number.replace(/\.$/, "")}`
                : section.title;

            const label = section.number
                ? `Section ${section.number.replace(/\.$/, ":")} ${section.title}`
                : `Section: ${section.title}`;

            let text;
            if (styleConfig.sectionRef === "index") {
                text = section.number
                    ? `Section ${section.number.replace(/\.$/, "")}`
                    : section.title;
            } else if (styleConfig.sectionRef === "title") {
                text = section.title;
            } else if (styleConfig.sectionRef === "full") {
                text = section.number
                    ? `Section ${section.number.replace(/\.$/, "")}: ${section.title}`
                    : `Section: ${section.title}`;
            } else {
                text = section.number
                    ? `Section ${section.number.replace(/\.$/, "")}`
                    : section.title;
            }

            references.push(
                new CrossReference({
                    id: section._id,
                    type: CrossReference.SECTION,
                    label,
                    ref,
                    text,
                }),
            );
        });

        documentStore.tables.map((table) => {
            const ref = table.prefix;
            const label = table.fullCaption ?? "";

            let text;
            if (styleConfig.captionRef === "index") {
                text = table.prefix;
            } else if (styleConfig.captionRef === "title") {
                text = table.caption;
            } else if (styleConfig.captionRef === "full") {
                text = table.fullCaption;
            } else {
                text = table.prefix;
            }

            references.push(
                new CrossReference({
                    id: table.id,
                    type: CrossReference.TABLE,
                    label,
                    ref,
                    text,
                }),
            );
        });

        documentStore.figures.map((figure) => {
            const ref = figure.prefix;
            const label = figure.fullCaption ?? "";

            let text;
            if (styleConfig.captionRef === "index") {
                text = figure.prefix;
            } else if (styleConfig.captionRef === "title") {
                text = figure.caption;
            } else if (styleConfig.captionRef === "full") {
                text = figure.fullCaption;
            } else {
                text = figure.prefix;
            }

            references.push(
                new CrossReference({
                    id: figure.id,
                    type: CrossReference.FIGURE,
                    label,
                    ref,
                    text,
                }),
            );
        });

        return references;
    }

    @computed
    get crossReferencesMap() {
        return this.crossReferences.reduce((acc, ref) => ({ ...acc, [ref.id]: ref }), {});
    }
}

export default new CrossReferenceStore();
