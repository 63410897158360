import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import classNames from "classnames";
import { BookOutlined } from "@ant-design/icons";
import qs from "qs";

import "./style/preview.scoped.scss";

const BookmarkTab = ({ bookmark }) => {
    const location = useLocation();
    const history = useHistory();
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });

    const currentPath = location.pathname;

    const onClick = () => {
        const bookmarkId = params?.bid !== bookmark._id ? bookmark._id : "";

        history.push(`${currentPath}?${bookmarkId ? `bid=${bookmarkId}` : ""}`);
    };

    return (
        <div
            onClick={onClick}
            className={classNames({
                "bookmark-tab": true,
                selected: params?.bid === bookmark._id,
            })}
            style={{
                top: `${bookmark.areas[0].top}%`,
            }}
        >
            <BookOutlined />
        </div>
    );
};

export default BookmarkTab;
