import React from "react";
import { observer } from "mobx-react";
import { WarningOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import Box from "@app/components/box/box";

import "./style/section.scoped.scss";
import { Link, useLocation } from "react-router-dom";

const Section = observer(({ title, icon, link, warn = false }) => {
    const { search } = useLocation();

    return (
        <Link to={`${link}${search}`}>
            <Box className="section">
                <div className="wrapper">
                    <div className="icon">{icon}</div>
                    <div className="title">{title}</div>
                    {warn && (
                        <div className="warn">
                            <Tooltip title="This section requires configuration">
                                <WarningOutlined />
                            </Tooltip>
                        </div>
                    )}
                </div>
            </Box>
        </Link>
    );
});

export default Section;
