import React from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { Button } from "antd";
import notify from "@app/components/notify";

import Question from "./question";
import Summary from "./summary";
import confirm from "@app/components/confirm";

import "./style/review.scoped.scss";

import articleStore from "@app/state/store/report/article";
import reportStore from "@app/state/store/report";
import session from "@app/state/store/session";
import { Review } from "@app/constants";

const ReviewTab = observer(() => {
    const article = articleStore.article;
    const { type } = useParams();
    const config = reportStore.config?.[type].l2;
    const useScore = config?.enableScore;
    const review = article?.review?.l2;
    const readonly = !session.can("article.update") || reportStore.readOnly;
    const selection = {};

    // Ignore any questions that were removed from or added to the configured set.
    const questions = review?.questions?.filter((question) => {
        return (
            config.questions?.findIndex((configQuestion) => configQuestion._id === question._id) !==
            -1
        );
    });

    const reviewQuestions = (questions || [])
        .map((q, index) => {
            const configQuestion = config.questions.find((cq) => q._id === cq._id) || {};
            selection[`${q._id}-${q.answer?._id}`] = true;

            return {
                ...q,
                color: configQuestion.color,
                answers: configQuestion.answers,
                conditional: configQuestion.conditional,
                condition: configQuestion.condition,
            };
        })
        .filter((q, index) => {
            return !q.conditional || selection[`${q.condition.question}-${q.condition.answer}`];
        });

    // wait for the article to be loaded
    if (!review || !config) {
        return;
    }

    /**
     * Handle answer update and save it
     */
    const saveQuestion = async (question) => {
        await articleStore.answer({ question, type });
    };

    /**
     * Handle the answer update and save it
     */
    const complete = async () => {
        // validate that all the questions are answered
        const invalidQuestion = reviewQuestions.some((question) => !question.answer?._id);

        if (config.requireAnswers && invalidQuestion) {
            notify.error(
                "Some of the questions are not answered. " +
                    "Please answer all the questions before marking the review as complete.",
            );
            return;
        }

        await articleStore.completeL2({ type });
    };

    /**
     * Mark the article as passed/failed the review
     */
    const resolve = async (action, reason) => {
        if (
            (action === "pass" && article?.review?.l2?.status === "pass") ||
            (action === "fail" &&
                article?.review?.l2?.status === "fail" &&
                article?.review?.l2?.fail?.reason === reason)
        ) {
            return;
        }

        if (action === "pass") {
            await articleStore.pass({ stage: Review.L2, type });
            // generate ai suggestions
        } else if (action === "fail") {
            articleStore.fail(Review.L2, { type, reason });
        }
    };

    /**
     * Cancel the review process
     */
    const handleCancel = async () => {
        let result = await confirm(
            "Are you sure you want to stop this L2 review?  All L2 review data for this article will be removed.",
        );

        if (result) {
            articleStore.cancelL2({ type });
        }
    };

    /**
     * Edit the questions for a complete L2 review
     */
    const edit = async () => {
        await articleStore.editReview({ type });
    };

    /**
     * Render the sidebar content
     */
    const Content = observer(() => {
        // show the summary if the review is complete
        if (review.complete) {
            return (
                <Summary
                    type={type}
                    article={article}
                    questions={questions}
                    list={reviewQuestions}
                    score={useScore}
                    readonly={readonly}
                    onResolve={resolve}
                    onEdit={edit}
                />
            );
        }

        // return the pending message if the review is not started
        if (!review.started) {
            return (
                <div className="pending-message">
                    <p>
                        The review hasn't been started yet.&nbsp;
                        {!readonly && (
                            <>
                                You can start it by pressing on the &nbsp;
                                <b>Review</b> button above.
                            </>
                        )}
                    </p>
                </div>
            );
        } else {
            return reviewQuestions.map((item, idx) => {
                return (
                    <Question
                        question={item}
                        answers={item.answers}
                        onChange={saveQuestion}
                        readonly={readonly}
                        key={item._id}
                    />
                );
            });
        }
    });

    /**
     * Render the pending message
     */
    const Footer = observer(() => {
        if (!review.started || review.complete) {
            return undefined;
        }

        return (
            <div className="footer">
                <div className="col">
                    <Button onClick={handleCancel}>Cancel Review</Button>
                </div>
                <div className="col text-right">
                    <div className="button-group">
                        <Button type="primary" onClick={complete}>
                            Complete
                        </Button>
                    </div>
                </div>
            </div>
        );
    });

    return (
        <div className="review">
            <div className="content">
                <Content />
            </div>

            {!readonly && <Footer />}
        </div>
    );
});

export default ReviewTab;
