import React, { useState, useEffect, useRef } from "react";
import { observer, useObserver } from "mobx-react";
import { v4 as uuid } from "uuid";
import { Tooltip } from "antd";
import { NodeViewWrapper } from "@tiptap/react";
import { TextSelection } from "@tiptap/pm/state";
import cn from "classnames";

import commentStore from "@app/state/store/report-document/comment";

import "./style.scss";

export default observer(({ editor, node, extension, getPos }) => {
    const ref = useRef();
    const store = extension.storage.store;
    const readonly = extension.options.readonly;
    const nodeId = useState(uuid());
    const { comment } = node.attrs;
    const isHighlighted = comment && comment.includes(commentStore.focusedComment);
    const status =
        commentStore.focusedComment &&
        commentStore.comments.find((c) => c._id === commentStore.focusedComment)?.status;

    const { templateMode } = editor.view._props;

    const handleMouseDown = (event) => {
        event.preventDefault();

        const { view } = editor;
        const { tr } = view.state;

        const from = getPos();
        const to = getPos() + node.nodeSize;

        tr.setSelection(new TextSelection(tr.doc.resolve(from), tr.doc.resolve(to)));
        view.dispatch(tr);
    };

    // re-render the component upon store change
    useObserver(() => store.entries);

    useEffect(() => {
        if (!editor) {
            return;
        }

        editor.on("removeDictionary", remove);
        return () => editor.off("removeDictionary", remove);
    }, [editor]);

    useEffect(() => {
        if (ref.current) {
            const nodeRef = ref.current;
            nodeRef.addEventListener("contextmenu", handleContextMenu);
            nodeRef.addEventListener("mousedown", handleMouseDown);

            return () => {
                nodeRef.removeEventListener("contextmenu", handleContextMenu);
                nodeRef.removeEventListener("mousedown", handleMouseDown);
            };
        }
    }, [ref.current]);

    // do not render the node in case if missing id
    const key = node.attrs.key;
    const change = node.attrs?.["data-diff-node"];
    const diffId = node.attrs?.["data-diff-id"];

    if (!key) {
        return null;
    }

    // do not render the node if we don't have a dictionary entry
    const entry = store.use(key, nodeId);
    const isTemplateValue = templateMode || !entry?.text;
    const invalid = !entry;

    function handleContextMenu(event) {
        event.preventDefault();
        if (node.type.spec.selectable) {
            editor.chain().focus().setNodeSelection(getPos()).run();
        }
    }

    /**
     * Remove the entry
     */
    function remove({ node }) {
        if (node.attrs.key === key) {
            const from = getPos();
            const to = from + node.nodeSize;

            editor.chain().deleteRange({ from, to }).focus().run();
        }
    }

    const ChangeWrapper = ({ children, change }) => {
        if (change === "ins") {
            return <ins data-diff-id={diffId}>{children}</ins>;
        }

        if (change === "del") {
            return <del data-diff-id={diffId}>{children}</del>;
        }

        return children;
    };

    const Entry = observer(({ entry, change }) => {
        return (
            <Tooltip title={entry.name} placement="top">
                <ChangeWrapper change={change}>{entry.text || entry.name}</ChangeWrapper>
            </Tooltip>
        );
    });

    return (
        <>
            {invalid && (
                <NodeViewWrapper
                    ref={ref}
                    className={cn({
                        "dictionary-entry": true,
                        invalid: true,
                        "template-mode": isTemplateValue,
                        pending: isHighlighted && status === "pending",
                        resolved: isHighlighted && status === "resolved",
                    })}
                >
                    {key}
                </NodeViewWrapper>
            )}

            {!invalid && !readonly && (
                <NodeViewWrapper
                    ref={ref}
                    className={cn({
                        "dictionary-entry": true,
                        "template-mode": isTemplateValue,
                        pending: isHighlighted && status === "pending",
                        resolved: isHighlighted && status === "resolved",
                    })}
                >
                    <Entry entry={entry} change={change} />
                </NodeViewWrapper>
            )}

            {!invalid && readonly && (
                <NodeViewWrapper
                    ref={ref}
                    className={cn({
                        "dictionary-entry": true,
                        "template-mode": isTemplateValue,
                        pending: isHighlighted && status === "pending",
                        resolved: isHighlighted && status === "resolved",
                    })}
                >
                    <Entry entry={entry} change={change} />
                </NodeViewWrapper>
            )}
        </>
    );
});
