import React from "react";
import classes from "classnames";
import Header from "./header";
import Title from "./title";
import "./style/box.scoped.scss";

Box.Header = Header;
Box.Title = Title;

export default function Box({
    className = "",
    children,
    padding = true,
    focus = false,
    shadow = false,
    ...rest
}) {
    const WithTitle = () => {
        if (rest.title) {
            return (
                <Header>
                    <Title>{rest.title}</Title>
                </Header>
            );
        }

        return null;
    };

    return (
        <div
            className={classes({
                [className]: true,
                box: true,
                padding: padding,
                focus: focus,
                shadow: shadow,
            })}
            {...rest}
        >
            <WithTitle />
            {children}
        </div>
    );
}
