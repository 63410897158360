import { useEffect, useRef } from "react";
import { cloneDeep, isEmpty } from "lodash";

import { ReportDocumentSection } from "@app/constants";
import sectionsStore from "@app/state/store/report-document/report-document-sections";
import sessionStore from "@app/state/store/session";

import { addCommentToIdString, hasComment } from "./util";

function findImageNodePos(doc, id) {
    let imagePos = null;

    doc.descendants((node, pos) => {
        if (node.type.name === "image" && node.attrs.id === id) {
            imagePos = pos;
            return false; // stop the iteration
        }
    });

    return imagePos;
}

const findNodeStartPos = (doc, from, nodeType, id) => {
    const posNode = doc.resolve(from);
    let offset = 0;

    for (let index = 0; index < posNode.parent.childCount; index++) {
        const childNode = posNode.parent.child(index);
        if (childNode.type.name === nodeType) {
            if (!id) {
                return posNode.start() + offset;
            } else if (id === childNode.attrs.id) {
                return posNode.start() + offset;
            }
        }
        offset += childNode.nodeSize;
    }

    return null;
};

const focusCommentMark = (editor, tr, comments, commentId) => {
    const { state } = editor;

    state.doc.descendants((node, pos) => {
        if (!node) {
            console.warn("Node is undefined!", pos);

            return;
        }

        if (!node.isInline) {
            return;
        }

        node.marks.forEach((mark) => {
            const { type, attrs } = mark;
            if (type.name === ReportDocumentSection.COMMENT.MARK_NAME) {
                if (hasComment(attrs.comment, commentId) || attrs.focused) {
                    const nodeComments = comments.filter((el) => hasComment(attrs.comment, el._id));

                    const newAttrs = {
                        ...attrs,
                        focused: hasComment(attrs.comment, commentId),
                        resolved: nodeComments.some(({ status }) => status === "resolved"),
                    };

                    const from = pos;
                    const to = pos + node?.nodeSize || 0;

                    tr = tr.removeMark(from, to, type).addMark(from, to, type.create(newAttrs));
                }
            }
        });
    });
};

const handleImageNodes = (editor, from, id, imageCommentsMap) => {
    const node = editor.state.doc.nodeAt(from);
    const pos = findImageNodePos(editor.state.doc, node.attrs.id);

    if (pos !== null) {
        const updatedCommentAttr = addCommentToIdString(node.attrs.comment, id);

        if (imageCommentsMap.has(pos)) {
            const accumulatedComments = imageCommentsMap.get(pos);
            imageCommentsMap.set(
                pos,
                addCommentToIdString(accumulatedComments, updatedCommentAttr),
            );
        } else {
            imageCommentsMap.set(pos, updatedCommentAttr);
        }
    }
};

const handleSelectableNodes = (editor, tr, from, id) => {
    const node = editor.state.doc.nodeAt(from);

    const nodeStartPos = findNodeStartPos(editor.state.doc, from, node.type.name, node.attrs.id);
    const targetNode = editor.state.doc.nodeAt(nodeStartPos);

    if (targetNode && ReportDocumentSection.COMMENT.FULL_NODES.includes(targetNode.type.name)) {
        return tr.setNodeMarkup(nodeStartPos, null, {
            ...targetNode.attrs,
            comment: addCommentToIdString(targetNode.attrs.comment, id),
        });
    }
    return tr;
};

const handleMarkedNodes = (editor, tr, from, to, id, focusedComment, node) => {
    const markType = editor.schema.marks[ReportDocumentSection.COMMENT.MARK_NAME];

    const existingMark = node.marks.find((mark) => mark.type === markType);

    let newCommentIdString = id; // Start with the new comment by default

    if (existingMark) {
        const existingComment = existingMark.attrs.comment;
        newCommentIdString = addCommentToIdString(existingComment, id);
    }

    const newMarkAttrs = {
        comment: newCommentIdString,
        index: 0,
        focused: newCommentIdString.includes(focusedComment),
    };

    const newMark = markType.create(newMarkAttrs);

    // Remove the existing mark before adding the new one
    return tr.removeMark(from, to, markType).addMark(from, to, newMark);
};

const splitCommentRanges = (comments) => {
    const ranges = new Set();
    let parts;

    comments
        .sort((a, b) => parseInt(a.from, 10) - parseInt(b.from, 10))
        .map((comment) => {
            if (comment.from === comment.to) {
                let from = Number(comment.from);
                let to = Number(comment.to);

                from >= 2 && (from -= 2);
                to += 2;

                comment.from = String(from);
                comment.to = String(to);
            }

            return comment;
        })
        .forEach(({ from, to }) => {
            ranges.add(from);
            ranges.add(to);
        });

    parts = [...ranges].sort((a, b) => a - b);
    return parts
        .slice(1)
        .map(function (a, i, aa) {
            var from = i ? aa[i - 1] : parts[0],
                to = a,
                ids = comments
                    .filter((d) => Number(d.from) <= from && to <= Number(d.to))
                    .map(({ _id }) => _id);
            return { from, to, _id: ids.join(",") };
        })
        .filter(({ _id }) => _id.length);
};

const applyCommentMarks = (editor, tr, comments, focusedComment) => {
    const imageCommentsMap = new Map();
    const splitComments = splitCommentRanges(cloneDeep(comments));

    splitComments.forEach(({ from, to, _id: id }) => {
        if (from < 0) {
            from = 0;
        }

        if (to > editor.state.doc.content.size) {
            to = editor.state.doc.content.size - 1;
        }

        editor.state.doc.nodesBetween(from, to, (node, pos) => {
            const start = Math.max(pos, from);
            const end = Math.min(pos + node.nodeSize, to);

            if (node.type.name === "text" || node.isInline) {
                tr = handleMarkedNodes(editor, tr, start, end, id, focusedComment, node);
            } else if (node.type.name === "image" && node.attrs.id) {
                handleImageNodes(editor, pos, id, imageCommentsMap);
            } else if (ReportDocumentSection.COMMENT.FULL_NODES.includes(node.type.name)) {
                tr = handleSelectableNodes(editor, tr, pos, id);
            }
        });
    });

    imageCommentsMap.forEach((accumulatedComment, pos) => {
        const node = editor.state.doc.nodeAt(pos);
        tr = tr.setNodeMarkup(pos, null, {
            ...node.attrs,
            comment: accumulatedComment,
        });
    });
};

export const useManageCommentNodes = ({
    editor,
    sectionComments,
    focusedCommentId,
    needToBeApplied,
    section,
}) => {
    const prevFocusedCommentIdRef = useRef();
    const shouldRefocus = prevFocusedCommentIdRef.current !== focusedCommentId;
    prevFocusedCommentIdRef.current = focusedCommentId;

    useEffect(() => {
        if (!editor) {
            return;
        }

        const { sectionId, projectId } = editor.view._props;

        const checkAndSaveIfPossible = async () => {
            const lockedBy = await sectionsStore.isLocked(sectionId);

            if (isEmpty(lockedBy) || lockedBy?._id === sessionStore.user._id) {
                const content = editor.getJSON();

                await sectionsStore.saveContent(sectionId, content, {
                    projectId,
                    backgroundSave: true,
                    section,
                });
            }
        };

        if (editor?.view?.docView && needToBeApplied.length) {
            try {
                if (needToBeApplied.length) {
                    const tr = editor.state.tr;
                    applyCommentMarks(editor, tr, needToBeApplied, focusedCommentId);
                    editor.view.dispatch(tr);

                    checkAndSaveIfPossible();
                    // save content if section is not locked
                }
            } catch (e) {
                console.log(e);
            }
        }
    }, [needToBeApplied, editor, focusedCommentId]);

    useEffect(() => {
        if (!editor) {
            return;
        }

        if (!sectionComments.length) {
            return;
        }

        if (shouldRefocus) {
            const tr = editor.state.tr;

            focusCommentMark(editor, tr, sectionComments, focusedCommentId);

            try {
                editor.view.dispatch(tr);
            } catch (e) {
                console.log(e);
            }
        }
    }, [sectionComments, editor, focusedCommentId, shouldRefocus]);
};

export default useManageCommentNodes;
