import { useCallback, useState } from "react";
import { sessionStore } from "@app/lib/storage";

/**
 * useToggle hook
 * @param {Boolean} initialState
 * @returns {Array} [state, toggle]
 */
const useToggle = (initialState = false, persistanceKey) => {
    const isPersisted = !!persistanceKey;
    const storeKey = `toggle.${persistanceKey}`;

    const value = isPersisted ? sessionStore.get(storeKey, initialState) : initialState;
    const [state, setState] = useState(value);

    const toggle = useCallback(() => {
        setState((state) => {
            if (isPersisted) {
                sessionStore.set(storeKey, !state);
            }

            return !state;
        });
    }, []);

    return [state, toggle];
};

export default useToggle;
