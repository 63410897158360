import React from "react";
import { observer } from "mobx-react";
import { Switch } from "antd";

import Group from "../group";

const SotaToggle = observer(({ config, onChange, readonly = false }) => {
    /**
     * Toggle a value and send the change event
     */
    const toggle = (key, value) => {
        config[key] = value;

        const data = {
            enabled: config.enabled,
            workflowEnabled: config.workflowEnabled,
        };

        onChange && onChange(data);
    };

    return (
        <Group title="SoTA Configuration">
            <div className="toggle">
                <div className="row items-center mb-15 ml-15">
                    <Switch
                        checked={config.enabled}
                        onChange={(value) => {
                            toggle("enabled", value);
                        }}
                        disabled={readonly}
                    />
                    <div className="col ml-15">Enable SoTA</div>
                </div>
            </div>
            {config.enabled && (
                <div className="toggle">
                    <div className="row items-center mb-15 ml-15">
                        <Switch
                            checked={config.workflowEnabled}
                            onChange={(value) => {
                                toggle("workflowEnabled", value);
                            }}
                            disabled={readonly}
                        />
                        <div className="col ml-15">Enable Full SoTA Workflow</div>
                    </div>
                </div>
            )}
        </Group>
    );
});

export default SotaToggle;
