import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { DeleteOutlined, PlusSquareOutlined, OrderedListOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import SortableTree, {
    addNodeUnderParent,
    removeNodeAtPath,
    changeNodeAtPath,
    toggleExpandedForAll,
} from "react-sortable-tree";
import confirm from "@app/components/confirm";
import MaterialTheme from "@app/library/react-sortable-tree-theme-material-ui";
import ToggleButton from "@app/components/toggle-button";

import "./style.scoped.scss";

const Content = observer(({ sections, reportDocument, state }) => {
    const { setTreeData, convertTOCToTree } = state;

    useEffect(() => {
        if (sections) {
            let data = convertTOCToTree(sections);
            data = toggleExpandedForAll({ treeData: data, expanded: true });
            setTreeData(data);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sections]);

    return (
        <div className="structure-form">
            <Sections state={state} />
        </div>
    );
});

const Sections = observer(({ state }) => {
    const { treeData, setTreeData, currentPos, setCurrentPos } = state;
    let currentPosTimer;

    useEffect(() => {
        // focus on the current position input after it renders
        if (currentPos) {
            currentPosTimer = setTimeout(() => document.getElementById(currentPos)?.focus(), 300);
        }

        return () => {
            clearTimeout(currentPosTimer);
        };
    }, [currentPos]);

    const getDisplayPos = (treeLevel, currentPos) =>
        // number of previous siblings with display
        treeLevel.slice(0, currentPos).filter((d) => d.displayPos).length;

    const getSortKey = (index, parent) => {
        return `${parent ? parent + "/" : ""}` + String(index).padStart(3, "0");
    };

    const getNodeKey = ({ treeIndex }) => treeIndex;

    const getTreeDataChange = (treeData) => {
        const getTreeNodeChange = (node, pos, displayPos) => {
            node.displayPos = node?.displayPos ? displayPos : "";

            if (node?.children?.length > 0) {
                node.children = node.children.map((child, i) => {
                    child.toc = node.toc ? child.toc : false;
                    child.displayPos = node.displayPos ? child.displayPos : "";

                    return getTreeNodeChange(
                        child,
                        getSortKey(i, pos),
                        `${displayPos}${getDisplayPos(node.children, i) + 1}.`,
                    );
                });
            }

            if (node?.focusPos) {
                setCurrentPos(pos);
                node.focusPos = false;
            }

            return { ...node, pos };
        };

        return treeData.map((data, i) =>
            getTreeNodeChange(data, getSortKey(i), `${getDisplayPos(treeData, i) + 1}.`),
        );
    };

    const updateSubTree = (node, updateFn) => {
        updateFn(node);

        if (node?.children?.length > 0) {
            node.children.map((child) => {
                updateSubTree(child, updateFn);
            });
        }
    };

    const addNode = (parent = {}) => {
        const { parentKey, node } = parent;

        if (node) {
            setTreeData(
                getTreeDataChange(
                    addNodeUnderParent({
                        treeData,
                        parentKey,
                        expandParent: true,
                        getNodeKey,
                        newNode: {
                            title: "",
                            focusPos: true,
                            displayPos: node.displayPos || "",
                            toc: node.toc,
                        },
                        addAsFirstChild: false,
                    }).treeData,
                ),
            );
        } else {
            setTreeData(
                getTreeDataChange([
                    ...treeData,
                    {
                        title: "",
                        children: [],
                        focusPos: true,
                        displayPos: "last",
                        toc: true,
                    },
                ]),
            );
        }
    };

    const updateNode = (row, patchData = {}) => {
        const { node, path } = row;

        setTreeData(
            getTreeDataChange(
                changeNodeAtPath({
                    treeData,
                    path,
                    getNodeKey,
                    newNode: {
                        ...node,
                        ...patchData,
                    },
                }),
            ),
        );
    };

    const toggleNumber = (row) => {
        const { node } = row;
        updateSubTree(node, (child) => {
            child.displayPos = !child.displayPos ? child.pos : "";
        });

        updateNode(row);
    };

    const removeNode = async (row) => {
        let proceed = await confirm(
            "This will remove any children associated to it.  Do you wish to continue?",
        );
        if (proceed) {
            const { path } = row;
            setTreeData(
                getTreeDataChange(
                    removeNodeAtPath({
                        treeData: treeData,
                        path,
                        getNodeKey,
                    }),
                ),
            );
        }
    };

    return (
        <div>
            <div className="treeContainer">
                {treeData.length > 0 && (
                    <SortableTree
                        treeData={treeData}
                        onChange={(treeData) => setTreeData([...getTreeDataChange(treeData)])}
                        generateNodeProps={(row) => {
                            const { node, path, parentNode, treeIndex } = row;
                            const disableNumber = parentNode?.displayPos === "";

                            return {
                                className: node.invalid ? "invalid" : "",
                                title: (
                                    <div className="inputTitle">
                                        <span className="prefix">{node.displayPos}</span>
                                        <input
                                            id={node.pos}
                                            value={node.title}
                                            onChange={(event) => {
                                                updateNode(row, { title: event.target.value });
                                            }}
                                            onKeyDown={(event) => {
                                                if (event.key === "Enter") {
                                                    addNode({
                                                        node: parentNode,
                                                        parentKey: path[path.length - 2],
                                                    });
                                                }
                                            }}
                                        />
                                    </div>
                                ),
                                buttons: [
                                    <div key={treeIndex} className="inputButtons">
                                        <Tooltip placement="bottom" title="Toggle section number">
                                            <span className="tooltip-target">
                                                <ToggleButton
                                                    icon={<OrderedListOutlined />}
                                                    value={!!node.displayPos}
                                                    disabled={disableNumber}
                                                    onChange={() => toggleNumber(row)}
                                                />
                                            </span>
                                        </Tooltip>
                                        <Tooltip placement="bottom" title="Add child section">
                                            <Button
                                                icon={<PlusSquareOutlined />}
                                                onClick={() => {
                                                    addNode({
                                                        node,
                                                        parentKey: path[path.length - 1],
                                                    });
                                                }}
                                            />
                                        </Tooltip>

                                        <Tooltip placement="bottom" title="Delete this section">
                                            <Button
                                                icon={<DeleteOutlined />}
                                                onClick={() => removeNode(row)}
                                            />
                                        </Tooltip>
                                    </div>,
                                ],
                            };
                        }}
                        isVirtualized={false}
                        theme={MaterialTheme}
                    />
                )}
                <div className="newSectionButton">
                    <div onClick={() => addNode()}>
                        <PlusSquareOutlined />
                        <span>Click to add new section</span>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Content;
