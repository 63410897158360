import React from "react";

export default function Banner({ children }) {
    return (
        <div className="banner">
            <div className="name">
                MDR
                <br />
                Compliance Solution
            </div>
            <div className="text">
                Experience our industry recognized software compliance application
            </div>
        </div>
    );
}
