import React from "react";

export default function (props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
            {...props}
        >
            <path
                fill="currentColor"
                d="M11 22v-4.2l-1.6 1.6L8 18l4-4l4 4l-1.4 1.4l-1.6-1.6V22zm-7-9v-2h16v2zm8-3L8 6l1.4-1.4L11 6.2V2h2v4.2l1.6-1.6L16 6z"
            ></path>
        </svg>
    );
}
