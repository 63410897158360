import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Tabs } from "antd";
import {
    TagsOutlined,
    BarsOutlined,
    ProfileOutlined,
    DotChartOutlined,
    FileSearchOutlined,
    InfoCircleOutlined,
} from "@ant-design/icons";
import Wand from "@app/assets/icons/wand";
import { Page } from "@app/components/page";
import { useSplitPage } from "../page/split/context";
import { useSidebar } from "../page/sidebar";

import Review from "./sidebar/review/index";
import Highlights from "./sidebar/highlights";
import Comments from "@app/components/comments/sidebarComments";
import AdditionalData from "./sidebar/additionalData";
import AIChat from "@app/components/ai-chat";
import Activity from "@app/components/activity";
import { useLocation } from "react-router-dom";
import qs from "qs";

import articleState from "@app/state/store/report/article";

import "./style/sidebar.scoped.scss";
import classNames from "classnames";
import { Activities, AI_SOURCE_TYPE } from "@app/constants";

const AUTO_HIDE = 1000;
const REVIEW_TAB = "review";
const HIGHLIGHT_TAB = "highlights";
const COMMENTS_TAB = "comments"; // changes to this value will need adjustments to email notification's triggered on comments where users are tagged.
const ADDITIONAL_DATA_TAB = "additional data";
const AI_CHAT_TAB = "AI chat";
const ACTIVITY_TAB = "activity";

const ArticleSidebar = observer(
    ({
        article,
        state,
        handleFail,
        readonly,
        review = false,
        width = 400,
        enableAI = false,
        ...rest
    }) => {
        const split = useSplitPage({ check: false }) || {};
        const sidebar = useSidebar(state);
        let params = qs.parse(useLocation().search, { ignoreQueryPrefix: true });

        let activeTab = undefined;

        if (params?.highlight === "true") {
            activeTab = HIGHLIGHT_TAB;
        } else if (params?.comments === "true") {
            // To show comments tab from the link within the email notification
            activeTab = COMMENTS_TAB;
        }

        const [activeKey, setActiveKey] = React.useState(activeTab);

        const hasComments = articleState.comments.length > 0;

        const switchTab = (key) => {
            setActiveKey(key);
        };

        useEffect(() => {
            if (split.right) {
                if (split.right < AUTO_HIDE) {
                    sidebar.hide();
                    sidebar.setInline(false);
                } else {
                    sidebar.show();
                    sidebar.setInline(true);
                }
            }
        }, [sidebar, split.right, state]);

        return (
            <Page.Sidebar
                className="article-sidebar"
                sidebar={sidebar}
                width={width}
                padding={false}
                collapsible={true}
                {...rest}
            >
                <Tabs
                    type="card"
                    activeKey={activeKey}
                    onTabClick={switchTab}
                    destroyInactiveTabPane
                >
                    {review && (
                        <Tabs.TabPane tab={<BarsOutlined className="tab-icon" />} key={REVIEW_TAB}>
                            <Review article={article} handleFail={handleFail} readonly={readonly} />
                        </Tabs.TabPane>
                    )}

                    <Tabs.TabPane
                        tab={
                            <ProfileOutlined
                                className={classNames("tab-icon", { badge: hasComments })}
                            />
                        }
                        key={COMMENTS_TAB}
                    >
                        <Comments
                            state={articleState}
                            readonly={readonly}
                            noItemsContent={
                                <div className="empty">
                                    <div className="icon">
                                        <InfoCircleOutlined />
                                    </div>
                                    <div className="text">
                                        There are no comments available.
                                        <br />
                                        You can add a comment using the input field below.
                                    </div>
                                </div>
                            }
                        />
                    </Tabs.TabPane>

                    <Tabs.TabPane tab={<TagsOutlined className="tab-icon" />} key={HIGHLIGHT_TAB}>
                        <Highlights article={article} />
                    </Tabs.TabPane>

                    <Tabs.TabPane
                        tab={<DotChartOutlined className="tab-icon" />}
                        key={ADDITIONAL_DATA_TAB}
                    >
                        <AdditionalData readonly={readonly} />
                    </Tabs.TabPane>

                    {enableAI && articleState.article?.file && (
                        <Tabs.TabPane tab={<Wand colour="#595959" />} key={AI_CHAT_TAB}>
                            <AIChat
                                source={{
                                    _id: articleState.article?._id,
                                    type: AI_SOURCE_TYPE.ARTICLE,
                                    fileId: articleState.article?.file,
                                }}
                                enableDialogue={articleState.article?.file}
                                readonly={readonly}
                            />
                        </Tabs.TabPane>
                    )}

                    <Tabs.TabPane
                        tab={<FileSearchOutlined className="tab-icon" />}
                        key={ACTIVITY_TAB}
                    >
                        <Activity id={article} context={Activities.ARTICLE} view="sidebar" />
                    </Tabs.TabPane>
                </Tabs>
            </Page.Sidebar>
        );
    },
);

export default ArticleSidebar;
