import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { Box } from "@app/components/box";
import {
    ExclamationCircleOutlined,
    WarningOutlined,
    DownOutlined,
    UpOutlined,
} from "@ant-design/icons";
import { Button } from "antd";

import "../style/feedback.scoped.scss";

import query from "@app/state/store/report/query";

const LIMIT = 3;

const Feedback = observer(({ className, ...rest }) => {
    const [expanded, setExpanded] = useState(false);
    const search = query.data;

    // do not show when there is no feedback
    if (!search?.logs?.length) {
        return null;
    }

    // sort the log by severity
    const logs = search.logs.sort((a, b) => {
        if (a.type < b.type) {
            return -1;
        } else if (a.type > b.type) {
            return 1;
        } else {
            return 0;
        }
    });

    // check if the block is expandable
    const expandable = logs.length > LIMIT;
    const messages = expandable && !expanded ? logs.slice(0, LIMIT) : logs;

    return (
        <Box className={classNames("feedback", className)} {...rest}>
            <div className="intro">
                This search resulted in some warning/error messages listed below. Please review them
                and make sure some articles are not missing from the results due to errors.
            </div>

            {messages.map((entry, idx) => {
                return (
                    <div className={`entry ${entry.type}`} key={idx}>
                        <span className="icon">
                            {entry.type === "error" && <ExclamationCircleOutlined />}
                            {entry.type === "warn" && <WarningOutlined />}
                        </span>
                        {entry.message}
                    </div>
                );
            })}

            {expandable && (
                <div className={classNames("more", { expanded: expanded })}>
                    <Button
                        onClick={() => {
                            setExpanded(!expanded);
                        }}
                    >
                        {expanded && (
                            <>
                                <UpOutlined /> Show Less <UpOutlined />
                            </>
                        )}

                        {!expanded && (
                            <>
                                <DownOutlined /> Show More <DownOutlined />
                            </>
                        )}
                    </Button>
                </div>
            )}
        </Box>
    );
});

export default Feedback;
