import React from "react";
import { observer } from "mobx-react";

import Group from "../../group";
import "./style.scoped.scss";

const ReportDocumentMetadataOverview = observer(({ document, readonly, onEdit }) => {
    const { variables } = document;

    return (
        <Group title="Document Metadata" onEdit={!readonly && onEdit}>
            <div className="metadata">
                {variables.map((field, index) => (
                    <div className="variable" key={index}>
                        <div className="key">{field.label}</div>
                        <div className="value">{field.value}</div>
                    </div>
                ))}
            </div>
        </Group>
    );
});

export default ReportDocumentMetadataOverview;
