import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";

import { Button } from "antd";
import { SlidersOutlined } from "@ant-design/icons";

import "./style/toggle.scoped.scss";

const Toggle = observer(({ filter }) => {
    if (filter.ui.inline) {
        return;
    }

    /**
     * Toggle the filter sidebar visibility
     */
    const toggle = (event) => {
        filter.ui.toggle();

        event.stopPropagation();
        event.preventDefault();
    };

    /**
     * Draw the activity dot
     */
    const Active = observer(() => {
        let active = false;
        Object.values(filter.state.filter).map((value) => {
            if (value) {
                active = true;
            }
        });

        if (active) {
            return <div className="dot"></div>;
        }

        return null;
    });

    return (
        <div className={classNames("toggle", { opened: filter.ui.visible })} onClick={toggle}>
            <Button icon={<SlidersOutlined />} type="icon" />
            <Active />
        </div>
    );
});

export default Toggle;
