import React from "react";
import { observer, useLocalStore } from "mobx-react";
import { AlignLeftOutlined, FilePdfOutlined } from "@ant-design/icons";
import { Radio, Tooltip } from "antd";

import "./style/view-toggle.scoped.scss";

const ViewToggle = observer(({ article, viewState }) => {
    if (!article.file) {
        return null;
    }

    const changeView = (e) => {
        viewState.view = e.target.value;
    };

    return (
        <div className="view-toggle">
            <Radio.Group value={viewState.view} onChange={changeView}>
                <Tooltip placement="topLeft" title="Show the summary">
                    <Radio.Button type="icon" value="summary">
                        <AlignLeftOutlined />
                    </Radio.Button>
                </Tooltip>
                <Tooltip placement="topLeft" title="Open the article">
                    <Radio.Button type="icon" value="file">
                        <FilePdfOutlined />
                    </Radio.Button>
                </Tooltip>
            </Radio.Group>
        </div>
    );
});

/**
 * Return the view state
 */
export function useArticleViewState() {
    const store = useLocalStore(() => ({
        view: "summary",

        reset() {
            this.view = "summary";
        },
    }));

    return store;
}

export default ViewToggle;
