import React, { useState } from "react";
import { observer } from "mobx-react";
import Modal from "@app/components/modal";
import { Radio, Space, Select, Form } from "antd";
import reportStore from "@app/state/store/report";
import articleState from "@app/state/store/report/article";
import confirm from "@app/components/confirm";
import { Review, ReasonType } from "@app/constants";

import "./style/multi-action-modal.scoped.scss";

const MultiActionModal = observer(
    ({ modalOpen, setModalOpen, articleIds = [], afterSubmit, enableAction = false, values }) => {
        const [form] = Form.useForm();
        const [showValidationMessage, setShowValidationMessage] = useState(false);
        const [showExcludeReason, setShowExcludeReason] = useState(false);
        const config = reportStore.config;
        const { type } = values;

        const excludeReasons = config?.[type]?.l1?.excludeReasons;

        const closeModal = () => {
            form.resetFields();
            setShowExcludeReason(false);
            setShowValidationMessage(false);
            setModalOpen(false);
        };

        const isFieldsEmpty = (data) => {
            const { articleTags, action } = data;
            if (articleTags?.add?.length > 0 || articleTags?.remove?.length > 0 || action?.type) {
                return false;
            } else {
                return true;
            }
        };

        const onSubmit = async (data) => {
            const excludeReason = excludeReasons.find((r) => r._id === data?.action.excludeReason);
            if (isFieldsEmpty(data)) {
                setShowValidationMessage(true);
            } else if (ReasonType.DUPLICATE === excludeReason?.type) {
                afterSubmit({ showOriginalArticleModal: true });
                closeModal();
            } else {
                let articleTags = {};
                let action;

                let proceed = await confirm("Are you sure you want to update all the articles?");
                if (proceed) {
                    // extract articleTags
                    if (data.articleTags?.add?.length > 0) {
                        articleTags.add = data.articleTags.add.map((formTagId) =>
                            config.articleTags.find((configTag) => configTag._id === formTagId),
                        );
                    }

                    if (data.articleTags?.remove?.length > 0) {
                        articleTags.remove = data.articleTags.remove.map((formTagId) =>
                            config.articleTags.find((configTag) => configTag._id === formTagId),
                        );
                    }

                    if (data?.action?.type === Review.PASS) {
                        action = { type: Review.PASS };
                    } else if (data?.action?.type === Review.FAIL) {
                        action = {
                            type: Review.FAIL,
                            reason: excludeReasons.find(
                                (r) => r._id === data?.action.excludeReason,
                            ),
                        };
                    }

                    articleState.updateMany(articleIds, type, { articleTags, action });
                    afterSubmit();
                    closeModal();
                }
            }
        };

        return (
            <Modal
                title={`Update Article(s)`}
                visible={modalOpen}
                onOk={form.submit.bind(form)}
                onCancel={closeModal}
                width={1000}
            >
                <div className="header">
                    <div className="validationMessage">
                        {showValidationMessage && "Please select at least one option"}
                    </div>

                    <div>{`Number of articles to be updated: ${articleIds.length}`}</div>
                </div>
                <div className="form">
                    <Form layout="vertical" form={form} onFinish={onSubmit}>
                        <Form.Item label="Article Tags">
                            <div className="formRow">
                                <Form.Item name={["articleTags", "add"]} label="Add">
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        placeholder="Please select tag(s) to be added"
                                    >
                                        {config.articleTags.map((tag, i) => (
                                            <Select.Option key={`add-tag-${i}`} value={tag._id}>
                                                {tag.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item name={["articleTags", "remove"]} label="Remove">
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        placeholder="Please select tag(s) to be remove"
                                    >
                                        {config.articleTags.map((tag, i) => (
                                            <Select.Option key={`remove-tag-${i}`} value={tag._id}>
                                                {tag.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                        </Form.Item>
                        {enableAction && (
                            <Form.Item label="Action">
                                <Form.Item
                                    name={["action", "type"]}
                                    style={{ marginBottom: "5px" }}
                                >
                                    <Radio.Group
                                        onChange={(e) => {
                                            setShowExcludeReason(
                                                form.getFieldValue(["action", "type"]) ===
                                                    Review.FAIL,
                                            );
                                            form.setFieldsValue({
                                                action: { excludeReason: undefined },
                                            });
                                        }}
                                    >
                                        <Space direction="vertical">
                                            <Radio value={Review.PASS}>Include</Radio>
                                            <Radio value={Review.FAIL}>Exclude</Radio>
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                                {showExcludeReason && (
                                    <Form.Item
                                        name={["action", "excludeReason"]}
                                        style={{ marginLeft: "5px", marginBottom: "0px" }}
                                        rules={[
                                            {
                                                message: (
                                                    <span style={{ marginLeft: "28px" }}>
                                                        A reason is require
                                                    </span>
                                                ),
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder="Please select a reason"
                                            style={{ width: 300, marginLeft: "18px" }}
                                        >
                                            {excludeReasons?.map((reason, i) => (
                                                <Select.Option
                                                    key={`exclude-reason-${i}`}
                                                    value={reason._id}
                                                >
                                                    {reason.reason}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                )}
                            </Form.Item>
                        )}
                    </Form>
                </div>
            </Modal>
        );
    },
);

export default MultiActionModal;
