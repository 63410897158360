import React, { useEffect, useRef } from "react";
import { CopyOutlined } from "@ant-design/icons";

import "./style/menu.scoped.scss";
import { ReactComponent as Icon } from "@app/assets/icons/highlight.svg";

const OFFSET_X = 0;
const OFFSET_Y = 20;

export const HighlightMenu = ({ x, y, onClose, onClick, ...rest }) => {
    const ref = useRef(null);

    useEffect(() => {
        // attach to the document click event to hide the menu
        document.addEventListener("click", close, false);

        // cleanup
        return function () {
            document.removeEventListener("click", close, false);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Close handler
     */
    const close = (event) => {
        // if the user clicked a menu button don't close the menu
        // it will be closed after the action is sent
        if (ref.current && ref.current.contains(event.target)) {
            return;
        }

        onClose && onClose();
    };

    /**
     * Perform an action
     */
    const action = (event, action) => {
        onClick && onClick(action);
        onClose && onClose();

        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <div className="menu" style={{ top: y + OFFSET_Y, left: x + OFFSET_X }} {...rest} ref={ref}>
            <div
                className="button"
                onMouseDown={(event) => {
                    action(event, "copy");
                }}
            >
                <CopyOutlined />
            </div>
            <div
                className="button"
                onMouseDown={(event) => {
                    action(event, "highlight");
                }}
            >
                <Icon />
            </div>
        </div>
    );
};

export default HighlightMenu;
