import React from "react";
import { observer, useLocalStore } from "mobx-react-lite";
import classNames from "classnames";

import { Button } from "antd";
import { EditOutlined, DeleteOutlined, RightOutlined, DownOutlined } from "@ant-design/icons";

import Group from "../group";
import confirm from "@app/components/confirm";

import "./style/additionalData.scoped.scss";

const AdditionalDataCard = observer(
    ({ additionalData, index, readonly = false, onEdit, onRemove }) => {
        const state = useLocalStore(() => ({
            expanded: false,

            toggle() {
                this.expanded = !this.expanded;
            },
        }));

        /**
         * Handle edit button click
         * @param {MouseEvent} event
         */
        const edit = (event) => {
            event.preventDefault();
            onEdit && onEdit(additionalData, event);
        };

        /**
         * Handle delete button click
         * @param {MouseEvent} event
         */
        const remove = async (event) => {
            event.preventDefault();

            let proceed = await confirm(
                "Are you sure you want to remove this data extraction field?",
            );
            if (proceed) {
                onRemove && onRemove(additionalData, event);
            }
        };

        const hasOptions = additionalData?.options?.length > 0;

        return (
            <Group.Item id={additionalData._id || "new"} index={index} order={!readonly}>
                <div className={classNames("additionalData", { expanded: state.expanded })}>
                    <div className="row main">
                        {hasOptions && (
                            <div className="col expand">
                                <Button
                                    onClick={() => state.toggle()}
                                    type="icon"
                                    icon={state.expanded ? <DownOutlined /> : <RightOutlined />}
                                ></Button>
                            </div>
                        )}

                        <div className="col content">
                            <div className="title">{additionalData.title}</div>
                        </div>
                        {!readonly && (
                            <div className="col actions">
                                <Button onClick={edit} type="icon" icon={<EditOutlined />}></Button>
                                <Button
                                    onClick={remove}
                                    type="icon"
                                    danger
                                    icon={<DeleteOutlined />}
                                ></Button>
                            </div>
                        )}
                    </div>
                    <div className="options">
                        {hasOptions && <div className="options-title">Allowed Values</div>}
                        {additionalData.options.map((option, index) => {
                            return (
                                <div className="option row" key={index}>
                                    <div className="col text">{option.text}</div>
                                    <div className="col value">({option.value})</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Group.Item>
        );
    },
);

export default AdditionalDataCard;
