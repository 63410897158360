import React from "react";
import { observer } from "mobx-react";
import format from "@app/lib/format";

import "./style/default-view.scoped.scss";

const DefaultView = observer(({ data }) => {
    return <div className="default-view">{format.sanitize(`${data}`)}</div>;
});

export default DefaultView;
