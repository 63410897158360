import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import Modal from "@app/components/modal";
import { Input } from "antd";
import { ReportDocumentSection } from "@app/constants";
import notify from "@app/components/notify/index";

const Content = observer(({ editor }) => {
    const [showModal, setShowModal] = useState(false);
    const [url, setURL] = useState("");

    const onShowModal = useCallback((previousUrl) => {
        if (previousUrl) {
            setURL(previousUrl);
        }

        setShowModal(true);
    });

    useEffect(() => {
        if (!editor) {
            return;
        }

        editor.on("linkModal", onShowModal);

        return () => {
            editor.off("linkModal", onShowModal);
        };
    }, [editor]);

    const saveLink = async () => {
        if (!url.trim()) {
            editor.commands.unsetLink();
        } else {
            if (ReportDocumentSection.EXTERNAL_LINK.validate(url)) {
                editor.commands.setLink({ href: url });
            } else {
                notify.info("Please use a valid external link URL");
                return;
            }
        }

        cancel();
    };

    const cancel = () => {
        setURL("");
        setShowModal(false);
    };

    return (
        <Modal
            title={"External Link"}
            visible={showModal}
            okText="Set"
            onOk={saveLink}
            onCancel={cancel}
            width={400}
        >
            <Input placeholder="URL" value={url} onChange={(e) => setURL(e.target.value)} />
        </Modal>
    );
});

export default Content;
