import loadingStore, { LOADING_STATES } from "../store/loading-store";
import notify from "@app/components/notify";
import http from "@app/lib/http";

const delay = (ms, signal) => {
    return new Promise((resolve, reject) => {
        const timeoutId = setTimeout(resolve, ms);
        signal.addEventListener("abort", () => {
            clearTimeout(timeoutId); // Clear the timeout if the signal is aborted
            reject(new Error("ABORTED"));
        });
    });
};

const postErrorNotification = (error) => {
    if (http._isNetworkError(error)) {
        notify.error("Network error. Please check your connection and try again.");
    }
};

/**
 * Decorator for managing loading state, error handling, and retries for asynchronous operations.
 * @note The key is compromised of the store name, method name, and arguments. The arguments are sorted and concatenated.
 *
 * @param {Object} options - Options for configuring the auto loading state behavior.
 * @param {Function} [options.skipIf] - A function that, if it returns true, will skip the execution of the decorated method.
 * @param {Function} [options.onBefore] - A function to run before the asynchronous operation begins. Receives the store and arguments as parameters.
 * @param {Function} [options.onAfter] - A function to run after the asynchronous operation completes (whether successful or failed). Receives the store, result, and error (if any) as parameters.
 * @param {Function} [options.onSuccess] - A function to run after a successful operation. Receives the store, result, and error (null if successful) as parameters.
 * @param {Function} [options.onError] - A function to run when an error occurs during the operation. Receives the store, result (null if failed), and the error as parameters.
 * @param {boolean} [options.notifyOnError] - Whether to notify when an error occurs. If true, it will trigger notifications on error.
 * @param {number} [options.retryOnFailedConnectionDelay=500] - Delay in milliseconds between retry attempts when the connection fails. Default is 500 milliseconds.
 * @param {Function} [options.getKeyArgs] - A function that allows selecting arguments for use in the key. Receives the store and arguments as parameters.
 * @param {number} [options.maxRetries=10] - The maximum number of retry attempts in case of failed connections. Default is 10 retries.
 * @returns {Function} - A function that decorates the original method, adding loading state management, retries, and error handling.
 */
export function AutoLoadingState({
    skipIf,
    onBefore,
    onAfter,
    onSuccess,
    onError,
    notifyOnError,
    retryOnFailedConnectionDelay = 500,
    getKeyArgs,
    maxRetries = 10,
}) {
    return function (target, propertyKey, descriptor) {
        const originalMethod = descriptor.value;
        const ongoingRequests = new Map();

        descriptor.value = async function (...args) {
            const store = this;

            const key = loadingStore.getKey(getKeyArgs ? getKeyArgs(store, args) : args);

            if (skipIf && skipIf(store)) {
                return;
            }

            if (ongoingRequests.has(key)) {
                ongoingRequests.get(key).abort();
            }

            if (onBefore) {
                onBefore(store, args);
            }

            const abortController = new AbortController();
            ongoingRequests.set(key, abortController);

            loadingStore.startLoading(key);

            let data;
            let error;
            let attempts = 0;

            const executeWithRetry = async () => {
                while (attempts < maxRetries) {
                    try {
                        const result = await originalMethod.apply(this, args);
                        data = result?.data || result;
                        break;
                    } catch (e) {
                        error = e;
                        attempts++;

                        if (attempts < maxRetries) {
                            try {
                                await delay(retryOnFailedConnectionDelay, abortController.signal);
                            } catch (e) {
                                break;
                            }
                        } else {
                            break;
                        }
                    }
                }
            };

            await executeWithRetry();

            if (!error) {
                loadingStore.setLoadingState(key, LOADING_STATES.LOADED);
            } else {
                loadingStore.setError(key, error);

                if (notifyOnError) {
                    postErrorNotification(error);
                }
            }

            if (onAfter) {
                onAfter(store, data, error);
            }

            if (onSuccess && !error) {
                onSuccess(store, data, error);
            }

            if (onError && error) {
                onError(store, data, error);
            }
        };

        return descriptor;
    };
}
