import React from "react";

import { Card } from "antd";
import { EyeOutlined } from "@ant-design/icons";

import "./style/report.scoped.scss";
import { ReportTable } from "./report-table";

import prisma from "@app/assets/prisma.png";

const Preview = ({ view, layout, fontSize, preview, type }) => {
    const width = view?.value === "landscape" ? 1123 : 794;
    const fontSizePx = fontSize?.value === "small" ? 12 : 14;

    return (
        <Card
            style={{ width: width + 50 }}
            className="preview"
            title={
                <>
                    <EyeOutlined /> Report Preview
                </>
            }
        >
            <ReportPreview
                data={preview}
                width={width}
                fontSize={fontSizePx}
                layout={layout?.value}
                type={type}
            />
        </Card>
    );
};

const ReportPreview = ({ data, width, fontSize, type, layout }) => {
    if (type === "prisma-flow") {
        return <img src={prisma} />;
    } else if (type === "appendix") {
        type = "";
    }

    const validPreview = data && data.rows && data.columns;
    if (!validPreview) {
        return "No preview available";
    }

    return (
        <ReportTable
            config={{
                width,
                fontSize: fontSize,
                layout: layout || "horizontal",
                type,
            }}
            rows={data.rows}
            columns={data.columns}
        />
    );
};

export default Preview;
