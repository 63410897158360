import { Node, mergeAttributes } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import { v4 as uuid } from "uuid";
import { isInTable } from "prosemirror-tables";

import { ReportDocumentSection } from "@app/constants";

import { CustomObjectNodeView } from "./node-view";

export const CustomObject = Node.create({
    name: ReportDocumentSection.CUSTOM_OBJECT.NODE_NAME,
    group: "block",
    inline: false,
    selectable: true,
    atom: true,

    onBeforeCreate() {
        const readonly = !this.editor.options.editable;
        this.options.readonly = readonly;
    },

    addAttributes() {
        return {
            id: {
                default: uuid(),
                required: true,
                parseHTML: (node) => {
                    if (!node.hasAttribute("data-diff-mode") || !node.hasAttribute("id")) {
                        return uuid();
                    }

                    return node.getAttribute("id");
                },
            },
            "data-diff-node": {
                default: undefined,
            },
            "data-diff-id": {
                default: undefined,
                parseHTML: (node) => {
                    if (node.hasAttribute("data-diff-node")) {
                        return uuid();
                    }

                    return undefined;
                },
            },
            comment: {
                default: undefined,
            },
            metadata: {
                default: {},
                required: true,
                renderHTML: (node) => {
                    return {
                        metadata: JSON.stringify(node.metadata),
                    };
                },
                parseHTML: (node) => {
                    if (node.hasAttribute("metadata")) {
                        try {
                            return JSON.parse(node.getAttribute("metadata"));
                        } catch (e) {
                            return {};
                        }
                    }
                },
            },
        };
    },

    parseHTML() {
        return [{ tag: `div[data-type="${this.name}"]` }];
    },

    renderHTML({ HTMLAttributes }) {
        return ["div", mergeAttributes(HTMLAttributes, { "data-type": this.name })];
    },

    addNodeView() {
        return ReactNodeViewRenderer(CustomObjectNodeView);
    },

    addCommands() {
        return {
            addCustomObject: ({ metadata } = {}) => ({ editor, commands, dispatch }) => {
                const { selection } = editor.state;

                if (isInTable(editor.state)) {
                    return false;
                }

                if (dispatch) {
                    commands.insertContentAt(selection.to, {
                        type: this.name,
                        attrs: { id: uuid(), metadata },
                    });
                }

                return true;
            },

            selectCustomObject: () => async ({ editor }) => {
                editor.emit(ReportDocumentSection.CUSTOM_OBJECT.SHOW_SELECT_MODAL_EVENT);
            },

            editCustomObject: ({ id, metadata }) => async ({ state, tr }) => {
                const { doc } = state;

                let pos = null;
                doc.descendants((node, posFound) => {
                    if (node.type.name === this.name && node.attrs.id === id) {
                        pos = posFound;
                        return false;
                    }

                    return true;
                });

                if (pos !== null) {
                    const node = doc.nodeAt(pos);

                    const newNode = node.type.create(
                        {
                            ...node.attrs,
                            metadata,
                        },
                        node.content,
                        node.marks,
                    );

                    tr.replaceWith(pos, pos + node.nodeSize, newNode);

                    return true;
                } else {
                    return false;
                }
            },
        };
    },
});

export default CustomObject;
