import { action } from "mobx";
import { localStore } from "@app/lib/storage";
import BaseStore from "./base";

/**
 * Store user settings in the local storage so they can be loaded
 * the next time the user opens the system.
 */
export class Settings extends BaseStore {
    /**
     * Store data
     */
    observable() {
        return {
            keys: {},
        };
    }

    /**
     * Load the settings from the local storage when constructed
     */
    constructor() {
        super();
        this.keys = localStore.get("app.settings", {});
    }

    /**
     * Update a settings key
     */
    @action
    set(key, value) {
        this.keys[key] = value;
        localStore.set("app.settings", this.keys);
    }

    /**
     * Retrieve a settings key
     */
    get(key, fallback) {
        let value = this.keys[key];
        if (value === undefined && fallback !== undefined) {
            return fallback;
        }

        return value;
    }
}

const settings = new Settings();
export default settings;
