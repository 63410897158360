import React, { useEffect } from "react";
import { observer, useLocalStore } from "mobx-react";
import format from "@app/lib/format";
import { LoadingOutlined, HistoryOutlined } from "@ant-design/icons";
import { User } from "@app/constants";

import "./style/avatar.scoped.scss";

const Avatar = observer(({ className = "", user, size = 50, ...rest }) => {
    const state = useLocalStore(() => ({
        loading: true,
    }));

    let style = {
        width: size,
        height: size,
        minWidth: size,
        minHeight: size,
        fontSize: size / 3,
    };

    const url = user.avatar ? format.image(user.avatar, { width: 100, height: 100 }) : undefined;

    useEffect(() => {
        // preload the image
        const preload = () => {
            if (url) {
                let img = new window.Image();
                img.onload = () => {
                    state.loading = false;
                };
                img.src = url;
            } else {
                state.loading = false;
            }
        };

        preload();
    }, [state, url]);

    /*
     * Draw the user avatar photo or show a circle with their
     * initials
     */
    const Image = observer(() => {
        const initials = user.initials ?? format.initials(user.fullName ?? "");
        if (user._id === User.SYSTEM) {
            return (
                <div className="image">
                    <img src={require("@app/assets/user/system-user.svg")} />
                </div>
            );
        }

        if (user.status === "pending") {
            return (
                <div className="no-image pending">
                    <HistoryOutlined style={{ fontSize: size / 1.6 }} />
                </div>
            );
        }

        if (user.avatar) {
            if (state.loading) {
                return (
                    <div className="loading">
                        <LoadingOutlined className="icon" />
                    </div>
                );
            } else {
                return (
                    <div className="image">
                        <img src={url} />
                    </div>
                );
            }
        } else {
            return <div className="no-image">{initials}</div>;
        }
    });

    return (
        <div style={style} className={`avatar ${className}`} {...rest}>
            <Image />
        </div>
    );
});

export default Avatar;
