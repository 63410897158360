export const calculateModalPosition = (
    startPos,
    endPos,
    modalWidth,
    modalHeight,
    contentDivSelector,
    lineOffset = 4,
) => {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    const contentDiv = document.querySelector(contentDivSelector);
    const contentRect = contentDiv.getBoundingClientRect();

    const selectionMiddle = contentRect.left + contentRect.width / 2;

    let top = endPos.bottom + window.scrollY + lineOffset;

    let left = selectionMiddle - modalWidth / 2;

    if (left + modalWidth > viewportWidth) {
        left = viewportWidth - modalWidth - 20; // 20px margin from the right edge
    }

    if (left < 0) {
        left = 20; // 20px margin from the left edge
    }

    // Adjust if modal overflows the bottom edge of the viewport
    if (top + modalHeight > viewportHeight + window.scrollY) {
        // Place above the selection instead
        top = startPos.top - modalHeight - lineOffset; // Xpx above the selection
    }

    // Ensure top is not going into negative space
    if (top < 0) {
        top = 20; // 20px margin from the top edge
    }

    return { top, left };
};
