import React from "react";

/**
 * @param {function} callback to call on unmount
 **/
const useComponentWillUnmount = (cleanupCallback = () => {}) => {
    const callbackRef = React.useRef(cleanupCallback);
    callbackRef.current = cleanupCallback; // always up to date
    React.useEffect(() => {
        return () => callbackRef.current();
    }, []);
};

export default useComponentWillUnmount;
