import React from "react";
import { observer, useLocalStore } from "mobx-react";
import Modal from "@app/components/modal";
import { Spin } from "antd";

const MultiStepModal = observer(
    ({ steps = [], currentStepIndex = 0, loading = false, onCancel, width }) => {
        const state = useLocalStore(() => ({
            steps,
            currentStepIndex,
        }));

        const currentStep = state.steps[currentStepIndex];

        return (
            <Modal
                title={currentStep.title}
                visible={true}
                width={width}
                footer={currentStep.footer}
                onCancel={onCancel}
            >
                {loading ? (
                    <div style={{ textAlign: "center" }}>
                        <Spin />
                    </div>
                ) : (
                    currentStep.body
                )}
            </Modal>
        );
    },
);

export default MultiStepModal;
