import React from "react";
import format from "@app/lib/format";

import "./title.scoped.scss";

const Title = ({ title, updatedAt, isLatestVersion, hideTime = false }) => {
    const dateTime = hideTime ? format.date(updatedAt) : format.datetime(updatedAt);
    return (
        <div className="version-title">
            {title ? title : dateTime} {isLatestVersion && `(latest)`}
        </div>
    );
};

export default Title;
