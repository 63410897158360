import React from "react";
import { observer } from "mobx-react";

import nodePosition from "@app/state/store/report-document/node-position";

import BubbleContainer from "./bubble-container";

const BubbleList = observer(({ editor, sectionId }) => {
    const sectionBubbles = nodePosition.getGroupedSectionNodes(sectionId);
    const sectionComments = nodePosition.getSectionCommentNodes(sectionId);

    const positions = Object.keys(sectionBubbles);

    return (
        <>
            {sectionComments && (
                <BubbleContainer
                    pos={sectionComments.pos}
                    nodes={sectionComments.nodes}
                    editor={editor}
                />
            )}
            {positions.map((pos) => (
                <BubbleContainer key={pos} pos={pos} nodes={sectionBubbles[pos]} editor={editor} />
            ))}
        </>
    );
});

export default BubbleList;
