import React, { useEffect, useCallback } from "react";
import { observer, useLocalStore } from "mobx-react";
import { Input } from "antd";
import _ from "lodash";

import "./style/search.scoped.scss";

const Search = observer(({ filter, afterSearch, searchOnChange = false }) => {
    var state = useLocalStore(() => ({
        search: "",
    }));

    // update the search value when changed in the filter state
    useEffect(() => {
        state.search = filter.state.search;
    }, [state, filter.state.search]);

    const triggerSearchDebounced = useCallback(
        _.debounce((value) => {
            state.search = value;
            filter.search(value);
        }, 250),
        [],
    );

    // update the search string
    const onChange = (event) => {
        state.search = event.target.value;

        if (searchOnChange) {
            triggerSearchDebounced(event.target.value);
        }
    };

    /**
     * Trigger a search
     */
    const search = (value) => {
        filter.search(value);
        afterSearch && afterSearch();
    };

    return (
        <div className="search">
            <Input.Search
                placeholder="Search"
                onChange={onChange}
                value={state.search}
                onSearch={search}
                allowClear
            />
        </div>
    );
});

export default Search;
