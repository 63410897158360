import React from "react";

export default function () {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            preserveAspectRatio="xMidYMid meet"
        >
            <path
                d="M2 1.71429V6V10.2857H10.2857V1.71429H2ZM1.71429 0H10.2857C10.7404 0 11.1764 0.180612 11.4979 0.502103C11.8194 0.823594 12 1.25963 12 1.71429V10.2857C12 10.7404 11.8194 11.1764 11.4979 11.4979C11.1764 11.8194 10.7404 12 10.2857 12H1.71429C1.25963 12 0.823594 11.8194 0.502103 11.4979C0.180612 11.1764 0 10.7404 0 10.2857V1.71429C0 1.25963 0.180612 0.823594 0.502103 0.502103C0.823594 0.180612 1.25963 0 1.71429 0Z"
                fill="currentColor"
            />
            <path d="M2 6H6V8H2V6Z" fill="currentColor" />
        </svg>
    );
}
