import React from "react";
import { observer, useLocalStore } from "mobx-react";

import Modal from "@app/components/modal";
import { MarkdownEditor } from "@app/components/markdown-editor";

const NoteModal = observer(({ note, onChange, onCancel }) => {
    var state = useLocalStore(() => ({
        text: note,
    }));

    /**
     * Cancel the edit
     */
    const cancel = () => {
        onCancel && onCancel();
    };

    /**
     * Save the value
     */
    const save = () => {
        onChange && onChange(state.text);
    };

    /**
     * Handle the text change event while editing
     */
    const update = (text) => {
        state.text = text;
    };

    return (
        <Modal
            title="Add a note"
            visible={true}
            okText="Save"
            onOk={save}
            onCancel={cancel}
            width={800}
        >
            <div className="editor">
                <MarkdownEditor
                    content={state.text}
                    onChange={update}
                    style={{ height: 200, marginBottom: 40 }}
                />
            </div>
        </Modal>
    );
});

export default NoteModal;
