import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router-dom";
import EmptyList from "@app/components/list/empty";
import { Spin } from "antd";
import { Page } from "@app/components/page";
// import Report from "@app/components/report";
import { ExportMenu } from "./export";

import state from "@app/state/store/report/output/dynamic-report";

import "./dynamic-report.scoped.scss";
import { ReportTable } from "@app/components/setup/reports/report-table";

const DynamicReport = observer(() => {
    const history = useHistory();
    const { project, type, reportId } = useParams();
    const { data, loading } = state;

    useEffect(() => {
        state.load(type, reportId);
    }, [type, reportId]);

    if (loading) {
        return (
            <div className="spinner">
                <Spin size="large" tip="Loading..." />
            </div>
        );
    }

    const ReportView = () => {
        if (data?.reportType === "prisma-flow") {
            const time = Date.now();
            return (
                <img
                    src={`/api/project/${project}/reports/prisma/${type}?${time}`}
                    className="prisma-image"
                />
            );
        }

        const noRows = !(data?.rows?.length > 0);
        if (noRows) {
            return <EmptyList title="No Records Found" show={noRows && !loading}></EmptyList>;
        }

        return (
            <ReportTable
                config={{
                    width: data?.width,
                    fontSize: data?.fontSize,
                    layout: data?.layout,
                    type: data?.reportType,
                }}
                rows={data?.rows}
                columns={data?.columns}
            />
        );
    };

    return (
        <Page>
            <Page.Header closable={true} onClose={() => history.push({ pathname: `../${type}` })}>
                <Page.Header.Left shrink>
                    <Page.Title>{data?.reportName}</Page.Title>
                </Page.Header.Left>
                <Page.Header.Right>
                    <ExportMenu
                        project={project}
                        type={type}
                        reportIds={[reportId]}
                        options={{ export: data?.reportType === "prisma-flow" ? "word" : "excel" }}
                    />
                </Page.Header.Right>
            </Page.Header>
            <Page.Body>
                <ReportView />
            </Page.Body>
        </Page>
    );
});

export default DynamicReport;
