import React, { useEffect } from "react";
import { observer } from "mobx-react";
import Filter from "@app/components/filter";

import store from "@app/state/store/dashboard";

const DashboardFilter = observer(({ filter }) => {
    const facets = {};
    const clients = store.clients;
    facets.myFavorites = filter.state.stats?.facets?.myFavorites || {};
    facets.myAssignments = filter.state.stats?.facets?.myAssignments || {};
    facets.status = filter.state.stats?.facets?.status || {};
    facets.client = filter.state.stats?.facets?.client || {};

    useEffect(() => {
        store.loadClients();
    }, []);

    return (
        <Filter.Sidebar filter={filter}>
            <Filter.OptionGroup
                radio
                filter={filter}
                title="My Projects"
                name="my"
                groupKey="project.my"
            >
                <Filter.Option
                    label="Favorites"
                    value="favorites"
                    count={facets.myFavorites.true ?? 0}
                />
                <Filter.Option
                    label="Assignments"
                    value="assignments"
                    count={facets.myAssignments.true ?? 0}
                />
            </Filter.OptionGroup>

            <Filter.OptionGroup
                filter={filter}
                title="Status"
                name="status"
                groupKey="project.status"
            >
                <Filter.Option label="Draft" value="draft" count={facets.status.draft ?? 0} />
                <Filter.Option label="Active" value="active" count={facets.status.active ?? 0} />
                <Filter.Option
                    label="Complete"
                    value="complete"
                    count={facets.status.complete ?? 0}
                />
                <Filter.Option label="On Hold" value="onhold" count={facets.status.onhold ?? 0} />
            </Filter.OptionGroup>

            {clients?.length > 1 && (
                <Filter.OptionGroup
                    filter={filter}
                    title="Client"
                    name="client"
                    groupKey="project.client"
                >
                    {clients.map((client) => {
                        const count = facets.client[client._id] ?? 0;

                        return (
                            <Filter.Option
                                key={client._id}
                                label={client.name}
                                value={client._id}
                                count={count}
                            ></Filter.Option>
                        );
                    })}
                </Filter.OptionGroup>
            )}
        </Filter.Sidebar>
    );
});

export default DashboardFilter;
