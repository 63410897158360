import { v4 as uuid } from "uuid";
import _ from "lodash";
// import { Plugin, PluginKey } from "prosemirror-state";
import { ReactNodeViewRenderer } from "@tiptap/react";
import Image from "@tiptap/extension-image";
import Component from "./node";

import imageStore from "@app/state/store/report-document/image";
import documentStore from "@app/state/store/report-document/document";
import tr from "../utils/transaction";

const ImageNode = Image.extend({
    group: "block",
    allowBase64: true,
    inline: false,

    onBeforeCreate() {
        const readonly = !this.editor.options.editable;
        this.options.readonly = readonly;
        this.type.spec.selectable = !readonly;

        this.storage.store = this.options.store || {};
    },

    onTransaction(args) {
        let update = false;
        const editor = args.editor;

        if (!tr.isEdit(args)) {
            return;
        }

        const added = tr.addedNodes(args, { type: this.name });
        if (added.length) {
            update = true;
        }

        const removed = tr.removedNodes(args, { type: this.name });
        if (removed.length) {
            update = true;
        }

        if (update && editor.sectionId) {
            const content = editor.getJSON();
            documentStore.processSection(editor.sectionId, content);
        }
    },

    addOptions() {
        return {
            readonly: false,
            store: {},
            metadata: {},
            processImage: _.debounce(async (image, cb) => {
                const newImage = await imageStore.formatImage(image);
                cb(newImage);
            }, 250),
        };
    },

    addStorage() {
        return {
            store: {},
        };
    },

    addAttributes() {
        return {
            id: {
                default: "",
                parseHTML: (image) => {
                    if (!image.hasAttribute("data-diff-mode") || !image.hasAttribute("id")) {
                        return uuid();
                    }

                    return image.getAttribute("id");
                },
            },

            processed: {
                default: false,
            },

            src: {
                default: null,
            },

            width: {
                renderHTML: (attributes) => {
                    return {
                        width: attributes.width,
                    };
                },
            },

            height: {
                renderHTML: (attributes) => {
                    return {
                        height: attributes.height,
                    };
                },
            },
            caption: {
                default: "",
            },
            invalid: {
                default: false,
            },
            "data-diff-node": {
                default: undefined,
            },
            "data-diff-id": {
                default: undefined,
                parseHTML: (img) => {
                    if (img.hasAttribute("data-diff-node")) {
                        return uuid();
                    }

                    return undefined;
                },
            },
            comment: {
                default: undefined,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: "img[src]",
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ["img", HTMLAttributes];
    },

    addNodeView() {
        return ReactNodeViewRenderer(Component);
    },

    addCommands() {
        return {
            /**
             * Adds a new image entry to the content
             */
            addImageEntry: (options) => ({ commands }) => {
                if (options) {
                    commands.insertContent({
                        type: this.name,
                        attrs: {
                            id: uuid(),
                            ...options.entry,
                        },
                    });
                }

                return true;
            },

            /**
             * Show an image picker
             */
            showImagePicker: () => async ({ editor }) => {
                editor.emit("imagePicker");
            },
            editImageCaption: () => ({ editor }) => {
                if (editor.state.selection) {
                    const node = editor.state.selection.node;
                    if (node?.type?.name === this.name) {
                        return true;
                    }
                }

                return false;
            },
        };
    },

    marks: "commentMark",

    // addProseMirrorPlugins() {
    //     return [
    //         new Plugin({
    //             key: new PluginKey("imagePasteHandler"),
    //             props: {
    //                 transformPastedHTML: (html) => {
    //                     if (html.includes("<img ")) {
    //                         const div = document.createElement("div");
    //                         div.innerHTML = html;
    //
    //                         const images = div.querySelectorAll("img");
    //                         for (const image of images) {
    //                             image.removeAttribute("id");
    //                         }
    //
    //                         html = div.innerHTML;
    //                         div.remove();
    //                     }
    //
    //                     return html;
    //                 },
    //             },
    //         }),
    //     ];
    // },
});

export default ImageNode;
