import React from "react";
import classes from "classnames";
import "./style/hbox.scoped.scss";

export default function HBox({ className = "", children, ...rest }) {
    return (
        <div className={`hbox ${className}`} {...rest}>
            {children}
        </div>
    );
}

HBox.Left = function ({ className = "", shrink = false, children, ...props }) {
    let classNames = classes({
        left: true,
        shrink: shrink,
        [className]: true,
    });

    return (
        <div className={classNames} {...props}>
            {children}
        </div>
    );
};

HBox.Right = function ({ className = "", shrink = false, children, ...props }) {
    let classNames = classes({
        right: true,
        shrink: shrink,
        [className]: true,
    });

    return (
        <div className={classNames} {...props}>
            {children}
        </div>
    );
};

HBox.Center = function ({ className = "", shrink = false, children, ...props }) {
    let classNames = classes({
        center: true,
        shrink: shrink,
        [className]: true,
    });

    return (
        <div className={classNames} {...props}>
            {children}
        </div>
    );
};
