import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    useLocation,
    useHistory,
} from "react-router-dom";

import { observer } from "mobx-react";
import { Modal } from "antd";

import { NestedContent } from "@app/components/page/nested";

import MainLayout from "./components/layout/main/main";
import FullPageLayout from "./components/layout/full-page";
import LoginLayout from "./components/layout/login/login";
import Loader from "./components/loader/app";

import Login from "./pages/auth/login";
import MFA from "./pages/auth/mfa";
import Password from "./pages/auth/password";
import PasswordReset from "./pages/auth/reset";
import SignUp from "./pages/auth/signup";
import Dashboard from "./pages/dashboard/dashboard";

import ProfileView from "./pages/profile/view";
import ProfileEdit from "./pages/profile/edit";

import UsersList from "./pages/users/list";
import UsersView from "./pages/users/view";
import UsersEdit from "./pages/users/edit";
import UserInvite from "./pages/users/invite";

import ClientList from "./pages/clients/list";
import ClientView from "./pages/clients/view";
import ClientEdit from "./pages/clients/edit";

import ConfigurationEdit from "./pages/clients/configurations/edit";
import ConfigurationSetup from "./pages/clients/configurations/setup";
import ConfigurationGeneral from "./pages/clients/configurations/setup/general";
import ConfigurationSLR from "./pages/clients/configurations/setup/slr";
import ConfigurationSOTA from "./pages/clients/configurations/setup/sota";
import ConfigurationReports from "./pages/clients/configurations/setup/reports";
import ConfigurationReportConfig from "./pages/clients/configurations/setup/reports/config";
import ConfigurationReportDocuments from "./pages/clients/configurations/setup/report-documents";
import ConfigurationReportDocumentStyles from "./pages/clients/configurations/setup/report-documents/styles";

import ClientReportDocumentSummary from "./pages/clients/report-documents/summary";
import ClientReportDocumentStructure from "./pages/clients/report-documents/structure";
import ClientReportDocumentTemplate from "./pages/clients/report-documents/template/index";
import ClientReportDocumentMetadata from "./pages/clients/report-documents/metadata";

import ProjectsList from "./pages/projects/list";
import ProjectsEdit from "./pages/projects/edit";

import ReportWrapper from "./pages/report/wrapper";
import ProjectOverview from "./pages/report/overview/project-overview";

import Setup from "./pages/projects/setup";
import SetupTeam from "./pages/projects/setup/team";
import SetupGeneral from "./pages/projects/setup/general";
import SetupSLR from "./pages/projects/setup/slr";
import SetupSota from "./pages/projects/setup/sota";
import SetupRiskAnalysis from "./pages/projects/setup/risk";
import SetupSourceDocuments from "./pages/projects/setup/source-documents";
import SetupReports from "./pages/projects/setup/reports";
import SetupReportConfig from "./pages/projects/setup/reports/config";
import SetupReportDocuments from "./pages/projects/setup/report-documents/list";
import SetupReportDocumentSummary from "./pages/projects/setup/report-documents/summary";
import SetupReportDocumentMetadata from "./pages/projects/setup/report-documents/metadata";
import SetupReportDocumentStructure from "./pages/projects/setup/report-documents/structure";
import SetupReportDocumentReview from "./pages/projects/setup/report-documents/review";
import SetupReportDocumentStyles from "./pages/projects/setup/report-documents/styles";

import Search from "./pages/report/search";
import SearchQuery from "./pages/report/search/query";

import L1 from "./pages/report/l1";
import L2 from "./pages/report/l2/list";
import L2Article from "./pages/report/l2/article";

import Output from "./pages/report/output/slr";
import Exclusion from "./pages/report/output/slr/exclusion";
import Performance from "./pages/report/output/slr/performance";
import ArticleFlow from "./pages/report/output/slr/article-flow";
import Articles from "./pages/report/output/slr/articles";
import Searches from "./pages/report/output/slr/searches";
import Scoring from "./pages/report/output/slr/scoring";
import Highlights from "./pages/report/output/highlights";

import ReportList from "./pages/report/reportList";
import DynamicReport from "./pages/report/output/dynamicReport";
import PrismaDiagram from "./pages/report/output/prisma";

import ReportDocumentList from "./pages/report/report-document-list";
import ReportDocument from "./pages/report/output/report-document";
import ReportDocumentHistory from "./pages/report/output/report-document-history";

import AdverseEvents from "./pages/report/risk/maude/adverse-events";
import EventDetails from "./pages/report/risk/maude/event-details";
import TplcReport from "./pages/report/risk/tplc/tplc";
import TplcSetup from "./pages/report/risk/tplc/configure";
import PatientProblems from "./pages/report/risk/patient-problems/report";
import PatientProblemsSetup from "./pages/report/risk/patient-problems/configure";

import DocumentCategories from "./pages/report/documents/categories";
import DocumentList from "./pages/report/documents/index";
import DocumentPreview from "./pages/report/documents/preview";
import DocumentBookmarks from "./pages/report/documents/bookmarks";
import ActivityLogs from "./pages/report/activity-list";

import Dictionary from "./pages/report/dictionary";
import Abbreviation from "./pages/report/abbreviation";
import Citation from "./pages/report/citation";

import session from "./state/store/session";
import DeletedProject from "./pages/report/error/page";
import { SearchType } from "./constants";

import { LoadingStateContextProvider } from "./hooks/useAutoLoadingState";

const typeBreadcrumb = (type) => (type === SearchType.SOTA ? "SoTA" : "DUE");

// prettier-ignore
export const routes = [
    { path: "/auth/login", component: Login, layout: LoginLayout, public: true },
    { path: "/auth/mfa", component: MFA, layout: LoginLayout, public: true },
    { path: "/auth/password", component: Password, layout: LoginLayout, public: true },
    { path: "/auth/reset", component: PasswordReset, layout: LoginLayout, public: true },
    { path: "/auth/signup", component: SignUp, layout: LoginLayout, public: true },

    { path: "/", exact: true, component: Dashboard, layout: MainLayout, breadcrumb: "Home" },

    {
        path: "/profile", component: ProfileView, layout: MainLayout, breadcrumb: "Profile", children: [
            { path: "/profile/edit", component: ProfileEdit, breadcrumb: "Edit Profile" }
        ]
    },

    {
        path: "/projects/:id/setup", component: Setup, breadcrumb: "Setup", layout: MainLayout, children: [
            { path: "/projects/:id/setup/team", component: SetupTeam, breadcrumb: "Team" },
            { path: "/projects/:id/setup/general", component: SetupGeneral, breadcrumb: "General" },
            { path: "/projects/:id/setup/slr", component: SetupSLR, breadcrumb: "DUE" },
            { path: "/projects/:id/setup/sota", component: SetupSota, breadcrumb: "SoTA" },
            { path: "/projects/:id/setup/source-documents", component: SetupSourceDocuments, breadcrumb: "Documents" },
            { path: "/projects/:id/setup/risk", component: SetupRiskAnalysis, breadcrumb: "Risk Analysis" },
            { path: "/projects/:id/setup/reports/:type(slr|sota)/:reportId", exact: true, component: SetupReportConfig, breadcrumb: "Edit report config" },
            { path: "/projects/:id/setup/reports", component: SetupReports, breadcrumb: "Reports" },
            {
                path: "/projects/:id/setup/report-documents", component: SetupReportDocuments, breadcrumb: "Report Documents", children: [
                    { path: "/projects/:id/setup/report-documents/styles", exact: true, component: SetupReportDocumentStyles, breadcrumb: "Document Styles" },
                    { path: "/projects/:id/setup/report-documents/:documentId", exact: true, component: SetupReportDocumentSummary, breadcrumb: "Summary" },
                    { path: "/projects/:id/setup/report-documents/:documentId/metadata", exact: true, component: SetupReportDocumentMetadata, breadcrumb: "Document Metadata" },
                    { path: "/projects/:id/setup/report-documents/:documentId/structure", exact: true, component: SetupReportDocumentStructure, breadcrumb: "Document Structure" },
                    { path: "/projects/:id/setup/report-documents/:documentId/review", exact: true, component: SetupReportDocumentReview, breadcrumb: "Review Setup" },
                ]
            },
        ]
    },

    {
        path: "/projects", component: ProjectsList, layout: MainLayout, breadcrumb: "Projects", children: [
            { path: "/projects/add", exact: true, component: ProjectsEdit, breadcrumb: "Add Project" },
            { path: "/projects/:id/edit/:step?", exact: true, component: ProjectsEdit, breadcrumb: "Edit" },
        ]
    },

    // Full Page layout
    {
        path: "/fp/r/:project", component: ReportWrapper, layout: FullPageLayout, children: [
            { path: "/fp/r/:project/document/bookmarks/:document?", component: DocumentBookmarks },
        ]
    },

    {
        path: "/r/:project", component: ReportWrapper, layout: MainLayout, children: [
            { path: "/r/:project/404", component: DeletedProject, breadcrumb: "Project Deleted" },
            { path: "/r/:project/", exact: true, component: ProjectOverview },
            { path: "/r/:project/search", exact: true, component: Search, breadcrumb: "Data Gathering" },
            { path: "/r/:project/search/new", component: SearchQuery, breadcrumb: "Search" },
            { path: "/r/:project/search/:id", component: SearchQuery, breadcrumb: "Search" },

            // L1
            // Route also used for email notifications
            { path: "/r/:project/:type(slr|sota)/l1/:article?", component: L1, breadcrumb: "L1" },

            // L2
            // Route also used for email notifications
            {
                path: "/r/:project/:type(slr|sota)/l2", component: L2, breadcrumb: "L2", children: [
                    { path: "/r/:project/:type/l2/:article", component: L2Article, breadcrumb: "Review" },
                ]
            },

            // Reports
            {
                path: "/r/:project/:type(slr|sota)/report", component: Output, breadcrumb: typeBreadcrumb, children: [
                    { path: "/r/:project/:type/report/exclusion", exact: true, component: Exclusion, breadcrumb: "L1 Exclusion Summary" },
                    { path: "/r/:project/:type/report/scoring", exact: true, component: Scoring, breadcrumb: "L2 Screening Summary" },
                    { path: "/r/:project/:type/report/performance", exact: true, component: Performance, breadcrumb: "Safety and Performance Summary" },
                    { path: "/r/:project/:type/report/article-flow", exact: true, component: ArticleFlow, breadcrumb: "Article Flow" },
                    { path: "/r/:project/:type/report/searches", exact: true, component: Searches, breadcrumb: "Search Parameters" },
                    { path: "/r/:project/:type/report/articles", exact: true, component: Articles, breadcrumb: "Literature Search Results" },
                    { path: "/r/:project/:type/report/highlights", exact: true, component: Highlights, breadcrumb: "Article Snippets" },
                ]
            },

            // Dynamic reports
            {
                path: "/r/:project/reports/:type(slr|sota)", component: ReportList, breadcrumb: typeBreadcrumb, children: [
                    { path: "/r/:project/reports/:type/prisma", exact: true, component: PrismaDiagram, breadcrumb: "Prisma Diagram" },
                    { path: "/r/:project/reports/:type/:reportId", exact: true, component: DynamicReport, breadcrumb: "Dynamic Report" },
                ]
            },

            // Report documents
            {
                path: "/r/:project/reports/documents", component: ReportDocumentList, breadcrumb: 'Report Documents', children: [
                    { path: "/r/:project/reports/documents/:reportDocumentId", exact: true, component: ReportDocument },
                    { path: "/r/:project/reports/documents/:reportDocumentId/history", exact: true, component: ReportDocumentHistory, breadcrumb: 'History' },
                ]
            },


            // List and details views of SoTA articles when there is no SoTA workflow
            {
                path: "/r/:project/:type(slr|sota)/:stage", component: L1, breadcrumb: "List", children: [
                    { path: "/r/:project/:type(slr|sota)/:stage/:article", component: L2Article, breadcrumb: "Review" },
                ]
            },

            // Risk
            {
                path: "/r/:project/adverse-events", component: AdverseEvents, breadcrumb: "MAUDE", children: [
                    { path: "/r/:project/adverse-events/:id", component: EventDetails, breadcrumb: "Report Detail" },
                ]
            },
            { path: "/r/:project/tplc", exact: true, component: TplcReport, breadcrumb: "TPLC" },
            { path: "/r/:project/tplc/setup", exact: true, component: TplcSetup, breadcrumb: "Configure" },
            { path: "/r/:project/patient-problems", exact: true, component: PatientProblems, breadcrumb: "Patient Problems" },
            { path: "/r/:project/patient-problems/setup", exact: true, component: PatientProblemsSetup, breadcrumb: "Configure" },

            // Documents
            // Until tests are added, if the below route changes, a change is also necessary in the backend for excel export
            {
                path: "/r/:project/document/categories", component: DocumentCategories, breadcrumb: "Categories", children: [
                    // Route also used for email notifications
                    { path: "/r/:project/document/categories/:category/:document?", exact: true, component: DocumentList, breadcrumb: "Documents" },
                    { path: "/r/:project/document/categories/:category/:document/open", component: DocumentPreview, breadcrumb: "Preview" },
                ]
            },
            { path: "/r/:project/document/bookmarks/:document?", component: DocumentBookmarks, breadcrumb: "Bookmarks" },
            { path: "/r/:project/activity-logs", component: ActivityLogs, breadcrumb: "Activity Logs" },

            { path: "/r/:project/dictionary", exact: true, component: Dictionary, breadcrumb: "Dictionary" },
            { path: "/r/:project/abbreviation", exact: true, component: Abbreviation, breadcrumb: "Abbreviation" },
            { path: "/r/:project/citation", exact: true, component: Citation, breadcrumb: "References" },
        ]
    },

    {
        path: "/users", component: UsersList, layout: MainLayout, breadcrumb: "Users", children: [
            { path: "/users/invite", exact: true, component: UserInvite, breadcrumb: "Invite" },
            { path: "/users/:id", exact: true, component: UsersView, breadcrumb: "User Details" },
            { path: "/users/:id/edit", component: UsersEdit, breadcrumb: "Edit User" },
        ]
    },

    {
        path: "/clients", component: ClientList, layout: MainLayout, breadcrumb: "Clients", children: [
            { path: "/clients/add", exact: true, component: ClientEdit, breadcrumb: "Add Client" },
            { path: "/clients/:id/edit", exact: true, component: ClientEdit, breadcrumb: "Edit Client" },
            {
                path: "/clients/:id/view/:view?", component: ClientView, breadcrumb: "Client Details", children: [
                    { path: "/clients/:id/view/config/add", exact: true, component: ConfigurationEdit, breadcrumb: "Add Configuration" },
                    { path: "/clients/:id/view/config/:configuration/edit", exact: true, component: ConfigurationEdit, breadcrumb: "Edit Configuration" },
                    {
                        path: "/clients/:id/view/config/:configuration/setup", component: ConfigurationSetup, breadcrumb: "Setup", children: [
                            { path: "/clients/:id/view/config/:configuration/setup/general", component: ConfigurationGeneral, breadcrumb: "General" },
                            { path: "/clients/:id/view/config/:configuration/setup/slr", component: ConfigurationSLR, breadcrumb: "DUE" },
                            { path: "/clients/:id/view/config/:configuration/setup/sota", component: ConfigurationSOTA, breadcrumb: "SoTA" },
                            { path: "/clients/:id/view/config/:configuration/setup/reports/:type(slr|sota)/:reportId", exact: true, component: ConfigurationReportConfig, breadcrumb: "Edit report config" },
                            { path: "/clients/:id/view/config/:configuration/setup/reports", component: ConfigurationReports, breadcrumb: "Reports" },
                            {
                                path: "/clients/:id/view/config/:configuration/setup/report-documents", component: ConfigurationReportDocuments, breadcrumb: "Report Documents", children: [
                                    { path: "/clients/:id/view/config/:configuration/setup/report-documents/styles", component: ConfigurationReportDocumentStyles, breadcrumb: "Document Styles" }
                                ]
                            },
                        ]
                    },
                    { path: "/clients/:id/view/report-documents/:documentId", exact: true, component: ClientReportDocumentSummary, breadcrumb: "Document Summary" },
                    { path: "/clients/:id/view/report-documents/:documentId/structure", exact: true, component: ClientReportDocumentStructure, breadcrumb: "Document Structure" },
                    { path: "/clients/:id/view/report-documents/:documentId/template", exact: true, component: ClientReportDocumentTemplate, breadcrumb: "Document Template" },
                    { path: "/clients/:id/view/report-documents/:documentId/metadata", exact: true, component: ClientReportDocumentMetadata, breadcrumb: "Document Metadata" },
                ]
            },
        ]
    },
];

/**
 * Define a list with all the routes and build the routing
 */
const Routes = observer((props) => {
    const history = useHistory();
    history.listen((path) => {
        if (path) {
            // close all modals on change of url
            Modal.destroyAll();
        }
    });

    /**
     * Render an array of route configurations to actual routes
     */
    const RenderRoutes = ({ routes = [] }) => {
        return (
            <Switch>
                {routes.map((route) => {
                    return (
                        <Page
                            path={route.path}
                            key={route.path}
                            component={route.component}
                            layout={route.layout}
                            isPublic={route.public}
                            exact={route.exact}
                        >
                            {route.children && (
                                <Switch>
                                    <RenderRoutes routes={route.children} />
                                </Switch>
                            )}
                        </Page>
                    );
                })}
            </Switch>
        );
    };

    return (
        <Switch>
            <RenderRoutes routes={routes} />
        </Switch>
    );
});

/**
 * A router wrapper component to render a different layout
 * with different pages. Also performs an authentication check when
 * accessing a page
 */
const Page = observer(({ component: Component, layout: Layout, isPublic, ...rest }) => {
    let location = useLocation();

    // Check if the user is authenticated. If not and they are requesting a
    // private route they will be redirected to the login page
    //
    // Save the requested route before redirect to the login page.
    // After the user is authenticated will redirect them back to the
    // initially requested page

    if (!session.loggedIn && !isPublic) {
        if (session.mfaPending) {
            return <Redirect to="/auth/mfa" />;
        } else if (session.signOut) {
            session.signOut = false;
        } else if (location.pathname !== "/") {
            session.redirect = location;
        }

        return <Redirect to="/auth/login" />;
    }

    let nested = rest.children;
    delete rest.children;

    if (Layout) {
        // render the page with layout
        return (
            <Route
                {...rest}
                render={(props) => (
                    <Layout {...props}>
                        <NestedContent.Provider value={nested}>
                            <Component {...props} children={nested} />
                        </NestedContent.Provider>
                    </Layout>
                )}
            />
        );
    } else {
        return (
            <Route
                {...rest}
                render={(props) => {
                    return (
                        <NestedContent.Provider value={nested}>
                            <Component {...props} children={nested} />
                        </NestedContent.Provider>
                    );
                }}
            />
        );
    }
});

/**
 * Apply the routes to the react router
 */
const AppRouter = observer((props) => {
    // check the user session before proceeding
    if (!session.checked) {
        if (!session.loading) {
            session.check();
        }

        return <Loader />;
    }

    return (
        <LoadingStateContextProvider>
            <Router>
                <Routes />
            </Router>
        </LoadingStateContextProvider>
    );
});

export default AppRouter;
