import React, { useEffect } from "react";
import { observer, useLocalStore } from "mobx-react";
import format from "@app/lib/format";
import { LoadingOutlined } from "@ant-design/icons";

import "./style/logo.scoped.scss";

const Logo = observer(({ className = "", client, size = 50, ...rest }) => {
    const state = useLocalStore(() => ({
        loading: true,
    }));

    let style = {
        width: size,
        height: size,
        fontSize: size / 3,
    };

    let url = client.logo ? format.image(client.logo, { width: 100, height: 100 }) : undefined;

    useEffect(() => {
        // preload the image
        const preload = () => {
            if (url) {
                let img = new window.Image();
                img.onload = () => {
                    state.loading = false;
                };
                img.src = url;
            } else {
                state.loading = false;
            }
        };

        preload();
    }, [state, url]);

    /*
     * Draw the client logo or show a circle with their initials
     */
    const Image = observer(() => {
        if (client.logo) {
            if (state.loading) {
                return (
                    <div className="loading">
                        <LoadingOutlined className="icon" />
                    </div>
                );
            } else {
                return (
                    <div className="image">
                        <img src={url} />
                    </div>
                );
            }
        } else {
            const initial = client?.name?.charAt(0)?.toUpperCase();
            return <div className="no-image">{initial}</div>;
        }
    });

    return (
        <div style={style} className={`logo ${className}`} {...rest}>
            <Image />
        </div>
    );
});

export default Logo;
