import React from "react";
import { LoadingOutlined } from "@ant-design/icons";

import "./styles.scoped.scss";

const Mask = () => {
    return (
        <div className="loading-mask">
            <div className="backdrop"></div>
            <div className="icon">
                <LoadingOutlined />
            </div>
        </div>
    );
};

export default Mask;
