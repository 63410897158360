import React from "react";
import classNames from "classnames";
import Breadcrumbs from "@app/components/breadcrumbs/breadcrumbs";

export default function Title({ breadcrumbs = true, className, children, ...rest }) {
    return (
        <div className={classNames("page-title", className)} {...rest}>
            <em>{children}</em>
            {breadcrumbs && <Breadcrumbs />}
        </div>
    );
}
