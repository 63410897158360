import { observable } from "mobx";
import Model from "@app/state/model/base";

export default class AbbreviationEntry extends Model {
    @observable _id = undefined;
    @observable name = undefined;
    @observable text = undefined;
    @observable lastUpdated = undefined;
    @observable created = undefined;
    @observable documentId = undefined;
    @observable isTemplateValue = undefined;
}
