import React, { useEffect } from "react";
import { observer, useLocalStore } from "mobx-react";
import { v4 as uuid } from "uuid";

import List from "../../list/ordered";
import CopyConfig from "../../config-clipboard/copy";
import PasteConfig from "../../config-clipboard/paste";
import Group from "../group";
import AdditionalDataCard from "./card";
import Form from "./form";

import configClipboard from "@app/state/store/config-clipboard";
import aiStore from "@app/state/store/ai";

const AdditionalDataGroup = observer(({ list, onChange, readonly = false }) => {
    const state = useLocalStore(() => ({
        edit: false,
        additionalData: undefined,
    }));

    useEffect(() => {
        aiStore.load();
    }, []);

    /**
     * Handle the reorder event and save the list
     */
    const reorder = (action) => {
        let [item] = list.splice(action.from, 1);

        list.splice(action.to, 0, item);
        save();
    };

    /**
     * Handle the remove event and save the list
     */
    const remove = (additionalData) => {
        const index = list.findIndex((entry) => {
            return entry._id === additionalData._id;
        });

        if (index !== -1) {
            list.splice(index, 1);
            save();
        }
    };

    /**
     * Handle the edit canceled event and hide the form
     */
    const onCancel = () => {
        state.edit = false;
        state.additionalData = undefined;
    };

    /**
     * Handle the save event from an edit or an add operation
     */
    const onSave = (entry) => {
        state.edit = false;
        state.additionalData = undefined;

        // add operation
        if (!entry._id) {
            list.push(entry);
        } else {
            const existing = list.find((el) => el._id === entry._id);
            Object.assign(existing, entry);
        }

        save();
    };

    /**
     * Do the actual configuration update
     */
    const save = () => {
        onChange && onChange(list);
    };

    const copy = () => {
        configClipboard.copy("additionalData", list);
    };

    const paste = () => {
        list.push(
            ...configClipboard.paste("additionalData", (entry) =>
                list.find((li) => li.name === entry.name),
            ),
        );
        save();
    };

    /**
     * Allow adding additional data only if the widget is not in read-only mode
     */
    const addAdditionalData = readonly
        ? undefined
        : () => {
              state.edit = true;
              state.additionalData = undefined;
          };

    return (
        <>
            <Group
                title="Data Extraction Fields (Optional)"
                className="additionalData"
                onAdd={addAdditionalData}
                onCopyButton={
                    <CopyConfig
                        onClick={copy}
                        tooltip={"Copy Data Extraction Fields"}
                        disabled={!list.length}
                    />
                }
                onPasteButton={
                    !readonly && configClipboard.additionalData.length ? (
                        <PasteConfig onClick={paste} tooltip={"Paste Data Extraction Fields"} />
                    ) : null
                }
            >
                {list.length === 0 && (
                    <Group.Empty>
                        No data extraction fields has been configured yet.
                        <br />
                        You can use the add button to add some.
                    </Group.Empty>
                )}

                <List onChange={reorder}>
                    {list.map((entry, index) => {
                        const id = entry._id || "new_" + uuid();

                        return (
                            <AdditionalDataCard
                                additionalData={entry}
                                index={index}
                                key={id}
                                value={entry}
                                onSave={save}
                                onRemove={remove}
                                onEdit={() => {
                                    state.edit = true;
                                    state.additionalData = entry;
                                }}
                                readonly={readonly}
                            />
                        );
                    })}
                </List>
            </Group>

            {state.edit && (
                <Form
                    additionalData={state.additionalData}
                    existing={list}
                    onSave={onSave}
                    onCancel={onCancel}
                />
            )}
        </>
    );
});

export default AdditionalDataGroup;
