import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { Progress, Table, Tooltip } from "antd";
import { DownOutlined, UpOutlined, StarFilled } from "@ant-design/icons";
import format from "@app/lib/format";
import Avatar from "@app/components/user/avatar";
import { Role } from "@app/constants";
import ReportDocumentsOverview from "@app/components/report-document/overview";
import NewTab from "@app/assets/icons/new-tab";

import reportDocumentsStore from "@app/state/store/report-document/report-documents";
import assignedStore from "@app/state/store/projects/assigned";

import "./style/projects.scoped.scss";

const TABLE_COLUMN = {
    PROJECT_NAME: "Project Name",
    FAVORITE: "Favorite",
    STATUS: "Status",
    DUE_DATE: "Due Date",
    DOCUMENT_PROGRESS: "Document Progress",
    REVIEW_PROGRESS: "Review Progress",
    TEAM: "Team",
    LINK: "Link",
};

const Projects = observer(({ projects, user, ...rest }) => {
    const [tableData, setTableData] = useState([]);

    const favoriteProjects = user?.favorite?.projects || [];

    useEffect(() => {
        if (projects) {
            setTableData(buildTableData(projects));
        }
    }, [projects]);

    const buildTableData = (projects) => {
        return projects.map((project) => {
            return {
                key: project._id,
                [TABLE_COLUMN.PROJECT_NAME]: project,
                [TABLE_COLUMN.FAVORITE]: project,
                [TABLE_COLUMN.STATUS]: project.status,
                [TABLE_COLUMN.DUE_DATE]: project.endDate,
                [TABLE_COLUMN.DOCUMENT_PROGRESS]: project.metaData.reportDocuments,
                [TABLE_COLUMN.REVIEW_PROGRESS]: project.metaData.literature,
                [TABLE_COLUMN.TEAM]: project.team,
                [TABLE_COLUMN.LINK]: project,
            };
        });
    };

    const renderProjectName = (project) => {
        return project.name;
    };

    const renderFavorite = (project) => {
        const isFavorite = favoriteProjects.includes(project._id);
        return isFavorite ? (
            <Tooltip placement="top" title="In your favorite list">
                <div className="favorite">
                    <StarFilled />
                </div>
            </Tooltip>
        ) : null;
    };

    const renderDueDate = (dueDate) => {
        const pastDue = new Date(dueDate) < new Date() ? "pastDue" : null;
        return <div className={`dueDate ${pastDue}`}>{format.date(dueDate)}</div>;
    };

    const renderDocumentProgress = (metadata) => {
        return (
            <div className="documentProgress">
                {metadata.reviewed}/{metadata.total} documents completed
            </div>
        );
    };

    const renderReviewProgress = (metadata) => {
        const percentage = metadata.totalArticles
            ? Math.round((metadata.articlesReviewed / metadata.totalArticles) * 100)
            : 0;

        return percentage === 100 ? (
            <span style={{ color: "#52c41a" }}>Completed</span>
        ) : (
            <Progress percent={percentage} size="small" strokeColor="#52c41a" />
        );
    };

    const renderTeam = (team = []) => {
        const users = assignedStore
            .getAllUsers(team)
            .filter(
                (user) =>
                    user._id &&
                    user.projectRoles.find((projectRole) =>
                        [Role.PROJECT_MANAGER].includes(projectRole),
                    ),
            )
            .splice(0, 4);

        return (
            <div className="team">
                {users.map((user) => (
                    <Tooltip title={user.fullName} key={user._id} id={user._id}>
                        <Avatar className={`avatar`} user={user} size={30} />
                    </Tooltip>
                ))}
            </div>
        );
    };

    const renderLink = (project) => {
        const path = `/r/${project._id}/`;
        return (
            <Link to={path} key={path}>
                <NewTab />
            </Link>
        );
    };

    const RenderExpandedRow = ({ record, expanded }) => {
        const projectId = record.key;
        const [reportDocuments, setReportDocuments] = useState();
        const [projectUsers, setProjectUsers] = useState();

        useEffect(() => {
            const load = async () => {
                const reports = await reportDocumentsStore.getReportDocuments({
                    projectId,
                    metaData: "true",
                });

                if (reports) {
                    setReportDocuments(reports);
                    setProjectUsers(assignedStore.getAllUsers(record[TABLE_COLUMN.TEAM]));
                }
            };

            load();
        }, []);

        // dynamically apply style to parent row
        const parentTr = document.querySelector(`tr[data-row-key="${record.key}"]`);
        if (parentTr) {
            const styleClass = "expandedParent";
            if (expanded) {
                parentTr.classList.add(styleClass);
            } else {
                parentTr.classList.remove(styleClass);
            }
        }

        return expanded ? (
            <div className="expandedContainer">
                {reportDocuments &&
                    projectUsers &&
                    (reportDocuments?.length > 0 ? (
                        <ReportDocumentsOverview
                            project={projectId}
                            reportDocuments={reportDocuments}
                            projectUsers={projectUsers}
                        />
                    ) : (
                        <div>No Report Documents</div>
                    ))}
            </div>
        ) : null;
    };

    const columns = [
        {
            title: (
                <div style={{ position: "absolute", left: "-15px" }}>
                    {TABLE_COLUMN.PROJECT_NAME}
                </div>
            ),
            dataIndex: TABLE_COLUMN.PROJECT_NAME,
            width: "35%",
            render: renderProjectName,
        },
        {
            title: "",
            dataIndex: TABLE_COLUMN.FAVORITE,
            width: "2%",
            render: renderFavorite,
        },
        {
            title: TABLE_COLUMN.STATUS,
            dataIndex: TABLE_COLUMN.STATUS,
            width: "8%",
        },
        {
            title: TABLE_COLUMN.DUE_DATE,
            dataIndex: TABLE_COLUMN.DUE_DATE,
            width: "10%",
            render: renderDueDate,
        },
        {
            title: TABLE_COLUMN.DOCUMENT_PROGRESS,
            dataIndex: TABLE_COLUMN.DOCUMENT_PROGRESS,
            width: "15%",
            render: renderDocumentProgress,
        },
        {
            title: TABLE_COLUMN.REVIEW_PROGRESS,
            dataIndex: TABLE_COLUMN.REVIEW_PROGRESS,
            width: "15%",
            render: renderReviewProgress,
        },
        {
            title: TABLE_COLUMN.TEAM,
            dataIndex: TABLE_COLUMN.TEAM,
            width: "10%",
            render: renderTeam,
        },
        {
            title: "",
            dataIndex: TABLE_COLUMN.LINK,
            width: "5%",
            render: renderLink,
        },
    ];

    if (!projects) {
        return null;
    }

    return (
        <div className="projects" {...rest}>
            <Table
                columns={columns}
                dataSource={tableData}
                pagination={false}
                expandable={{
                    expandRowByClick: true,
                    expandIcon: ({ expanded, onExpand, record }) => (
                        <div className="expandIcon">
                            {expanded ? (
                                <UpOutlined onClick={(e) => onExpand(record, e)} />
                            ) : (
                                <DownOutlined onClick={(e) => onExpand(record, e)} />
                            )}
                        </div>
                    ),
                    expandedRowRender: (record, index, indent, expanded) => (
                        <RenderExpandedRow record={record} expanded={expanded} />
                    ),
                }}
            />
        </div>
    );
});

export default Projects;
