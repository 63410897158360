import { computed, action } from "mobx";
import { v4 as uuid } from "uuid";
import { events } from "@app/lib/store";
import BaseStore from "@app/state/store/base";
import http from "@app/lib/http";

import Article from "@app/state/model/project/article";
import Filter from "@app/components/filter/state";

// report wrapper state
import report from "../report";
import { SearchType } from "@app/constants";

import { ws } from "@app/lib/socket";
import ArticlePager from "@app/state/store/report/article/pager";
import ArticleSearches from "@app/state/store/report/article/searches";

const STGR_LOAD = uuid();

export class L1Store extends BaseStore {
    /**
     * Observable store data
     */
    observable() {
        return {
            list: [],
            totalList: [],
            searches: [],
            loading: false,
            saving: false,
            article: undefined,
        };
    }

    @computed get busy() {
        return this.loading;
    }

    /**
     * Return the project id if the currently loaded project
     */
    @computed get project() {
        return report.id;
    }

    /**
     * Return the SoTA config status of the currently loaded project
     */
    @computed get sotaEnabled() {
        return report.config?.sota?.enabled;
    }

    @computed get sotaWorkflowEnabled() {
        return report.config?.sota?.workflowEnabled;
    }

    constructor() {
        super();

        this.type = SearchType.SLR;

        ArticlePager.use(this);
        ArticleSearches.use(this);

        // reload the list on article update
        events.on("article.update", () => this.load());
        events.on("project.unload", () => {
            this.resetFilters();
            this.reset();
        });

        // reload the searches upon change
        events.on("search.update", () => {
            this.loadSearches();
        });

        ws.on("article.changed", (id) => {
            const count = this.list?.length;
            if (count) {
                let fetch = false;
                for (let i = count - 1; i >= 0; i--) {
                    if (this.list[i]._id === id) {
                        fetch = true;
                        break;
                    }
                }
                fetch && this.load();
            }
        });

        // results filters; keep SLR and SoTA separate
        this.resetFilters();
    }

    @action
    resetFilters() {
        this.filter = {};
        [SearchType.SLR, SearchType.SOTA].map((searchType) => {
            this.filter[searchType] = new Filter({
                rows: 40,
                sort: {
                    title: { label: "Title", order: 1 },
                    id: { label: "ID", order: 1 },
                    publicationDate: { label: "Published", order: 1 },
                },
                default: {
                    sort: { id: 1 },
                },
            });

            this.filter[searchType].on("find", () => {
                this.load();
            });
        });
    }

    /**
     * Load the store
     */
    @action
    async load(type) {
        // reset the list when we change the type
        if (type && type !== this.type) {
            this.list = [];
            this.searches = [];
        }

        // save the current type in the object
        this.type = type ?? this.type;

        await this.loadArticles();
        await this.loadSearches();
    }

    /**
     * Load a list with articles from the backend
     */
    @action
    async loadArticles(filter) {
        if (!this.project) {
            return;
        }

        this.loading = true;

        let selectedFilter = filter ? filter[this.type].value() : this.filter[this.type].value();
        selectedFilter.type = this.type; // Filter articles by type in fetch below

        let { data } = await http
            .get(`/project/${this.project}/articles/l1`, selectedFilter)
            .stagger(STGR_LOAD);

        this.list = data.list.map((entry) => {
            return new Article(entry);
        });

        this.totalList = data.totalList;

        // update the query stats
        this.filter[this.type].stats(data.stats);
        this.loading = false;
    }
}

export default new L1Store();
