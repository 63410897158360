import http from "@app/lib/http";
import { v4 as uuid } from "uuid";
import Search from "@app/state/model/search";

const STGR_SEARCH = uuid();

export default class ArticleSearches {
    static use(instance) {
        instance.loadSearches = this.loadSearches.bind(instance);
    }

    static async loadSearches() {
        if (!this.project) {
            return;
        }

        try {
            this.loading = true;
            let { data } = await http
                .get(`/project/${this.project}/searches`, { type: this.type })
                .stagger(STGR_SEARCH);

            this.searches = data.list.map((entry) => {
                return new Search(entry);
            });
        } finally {
            this.loading = false;
        }
    }
}
