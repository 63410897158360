import React from "react";

export default function () {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 36 36"
            className="icon"
        >
            <path
                fill="currentColor"
                d="M6 10.2h25.75a1.1 1.1 0 1 0 0-2.2H6a1.1 1.1 0 1 0 0 2.2Z"
            />
            <path
                fill="currentColor"
                d="M31.75 14H6a1.1 1.1 0 1 0 0 2.2h25.75a1.1 1.1 0 1 0 0-2.2Z"
            />
            <path
                fill="currentColor"
                d="M31.12 20H6.62a1.1 1.1 0 1 0 0 2.2h24.5a1.1 1.1 0 1 0 0-2.2Z"
            />
            <path
                fill="currentColor"
                d="M30.45 25.83H6.6a1.1 1.1 0 0 0 0 2.2h23.85a1.1 1.1 0 0 0 0-2.2Z"
            />
            <path fill="none" d="M0 0h36v36H0z" />
        </svg>
    );
}
