import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Dropdown, Menu } from "antd";
import { EditOutlined, DownOutlined } from "@ant-design/icons";

import Page from "@app/components/page/page";
import Loader from "@app/components/loader/content";
import MetadataOverview from "@app/components/setup/report-documents/metadata/overview";
import StructureOverview from "@app/components/setup/report-documents/structure/overview";

import documentsStore from "@app/state/store/report-document/report-documents";

const DropdownMenu = ({ onEditTemplate, onEditSections }) => {
    const menu = (
        <Menu>
            <Menu.Item key="editTemplate" onClick={onEditTemplate}>
                Edit Template
            </Menu.Item>
            <Menu.Item key="editSections" onClick={onEditSections}>
                Edit Structure
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu}>
            <Button>
                <EditOutlined />
                <DownOutlined />
            </Button>
        </Dropdown>
    );
};

const SetupReportDocuments = observer(() => {
    const history = useHistory();
    const { id, documentId } = useParams();
    const reportDocument = documentsStore.reportDocument;

    useEffect(() => {
        (async () => {
            documentsStore.setClientId(id);
            await documentsStore.load(documentId);
        })();

        return () => {
            documentsStore.unload();
        };
    }, [id, documentId]);

    const close = () => {
        history.push("../report-documents");
    };

    const onStructureEdit = () => {
        history.push(`./${documentId}/structure`);
    };

    const onEditTemplate = () => {
        history.push(`./${documentId}/template`);
    };

    const onMetadataEdit = () => {
        history.push(`./${documentId}/metadata`);
    };

    return (
        <Page>
            <Page.Header closable onClose={close}>
                <Page.Header.Left shrink>
                    <Page.Title>{reportDocument?.name ?? "Loading..."}</Page.Title>
                </Page.Header.Left>

                <Page.Header.Right></Page.Header.Right>
            </Page.Header>

            <Page.Body maxWidth={1000}>
                {reportDocument && (
                    <>
                        <MetadataOverview document={reportDocument} onEdit={onMetadataEdit} />
                        <StructureOverview
                            document={reportDocument}
                            sections={documentsStore.reportDocumentSections}
                            right={
                                <DropdownMenu
                                    onEditTemplate={onEditTemplate}
                                    onEditSections={onStructureEdit}
                                />
                            }
                        />
                    </>
                )}

                <Loader loading={!reportDocument} />
            </Page.Body>
        </Page>
    );
});

export default SetupReportDocuments;
