import React from "react";
import { Tooltip, Button } from "antd";
import { CopyOutlined } from "@ant-design/icons";

import "./style/copy.scoped.scss";

const CopyConfig = ({ tooltip, ...buttonProps }) => {
    return (
        <Tooltip title={tooltip} mouseEnterDelay={1.5}>
            <Button
                {...buttonProps}
                type={"icon"}
                icon={<CopyOutlined />}
                className={"copy-button"}
            />
        </Tooltip>
    );
};

export default CopyConfig;
