import React from "react";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";
import qs from "qs";
import featuresStore from "@app/state/store/features";

import "./style/full-page.scoped.scss";

const FullPageLayout = observer(({ children }) => {
    const { search } = useLocation();

    const urlParams = qs.parse(search, {
        ignoreQueryPrefix: true,
    });
    featuresStore.setFeaturesInSession(urlParams.features);

    return (
        <div className="layout fullPage">
            <div className="body">
                <div className="content">{children}</div>
            </div>
        </div>
    );
});

export default FullPageLayout;
