import React, { useEffect } from "react";
import { observer, useLocalStore } from "mobx-react";
import { Button } from "antd";
import { useLocation } from "react-router-dom";
import { FileTextOutlined, CloseCircleOutlined, CloudSyncOutlined } from "@ant-design/icons";
import { Box } from "@app/components/box";
import { PdfViewer } from "@app/components/pdf-viewer";
import DropArea from "@app/components/form/upload/drop/area";
import ReuseArticleL1Modal from "@app/components/article/reuse-article-l1-modal";
import ReuseArticleL2Modal from "@app/components/article/reuse-article-l2-modal";

import Summary from "./chunks/summary";
import "./style/details.scoped.scss";

import articleStore from "@app/state/store/report/article";
import session from "@app/state/store/session";
import reportStore from "@app/state/store/report";

import { Review } from "@app/constants";
import qs from "qs";

/**
 * Handle the add highlight event
 */
const addHighlight = (hl) => {
    articleStore.saveHighlight(hl);
};

/**
 * Handle the add highlight event
 */
const removeHighlight = (hl) => {
    articleStore.removeHighlight(hl);
};

/**
 * Change the article type SLR -> SOTA and vice versa
 */
const onChangeType = (type) => {
    articleStore.addType(type);
};

const Details = observer(
    ({ article, stage, type, viewState, switchArticleType, keywords, onUpload }) => {
        const view = viewState && viewState.view === "file" ? "file" : "summary";
        const config = reportStore.config || {};
        const readonly = !session.can("article.update") || reportStore.readOnly;
        const review = (stage === Review.L2 ? article.review?.l2 : article.review?.l1) || {};
        const location = useLocation();
        const params = qs.parse(location.search, { ignoreQueryPrefix: true });

        const area = params.area ? JSON.parse(params.area) : undefined;

        var state = useLocalStore(() => ({
            reusableArticles: [],
            showReuseArticleModal: false,
        }));

        useEffect(() => {
            const getReusableArticles = async () => {
                if (article._id) {
                    if (!review.status) {
                        state.reusableArticles = await articleStore.getReusableArticles(
                            article._id,
                            { stage, type },
                        );
                    }
                }
            };

            getReusableArticles();
        }, [article._id]);
        /**
         * Save the article changes
         */
        const onChange = (data) => {
            articleStore.update(data);
        };

        /**
         * Render the missing file view
         */
        const MissingWarning = observer(() => {
            // only show this if article is missing a file
            const { file, isDownloading } = article;
            let message = "The full article is missing.";
            let missingOrDownloading = "missing-file";
            let icon = (
                <>
                    <FileTextOutlined className="file-icon" />
                    <CloseCircleOutlined className="close-icon" />
                </>
            );
            if (file || stage === Review.L1) {
                return;
            }
            if (isDownloading) {
                message = "The full article is queued for download. Please come back later.";
                icon = <CloudSyncOutlined className="file-icon" />;
                missingOrDownloading += " download-pending";
            }
            return (
                <div className={missingOrDownloading}>
                    <div className="icon">
                        <div className="wrapper">{icon}</div>
                    </div>
                    <div className="content">{message}</div>
                </div>
            );
        });

        const UsedInProjectInfo = observer(() => {
            const showUsedInProjectInfo = () => {
                let show = false;
                if (stage === Review.L1) {
                    show = !review.status;
                } else {
                    show = !review.started;
                }

                return show && state.reusableArticles.length > 0;
            };

            return (
                showUsedInProjectInfo() && (
                    <div className="usedInProjectInfo">
                        <label>This result was previously reviewed in another project</label>
                        <Button onClick={() => (state.showReuseArticleModal = true)}>
                            Details
                        </Button>
                    </div>
                )
            );
        });

        /**
         * Render the summary
         */
        const SummaryView = observer(() => {
            const switchArticleType = config.sota?.enabled;

            return (
                <Box>
                    <DropArea onChange={onUpload} disabled={readonly} accept=".pdf,application/pdf">
                        <Summary
                            article={article}
                            onChange={onChange}
                            onAddHighlight={addHighlight}
                            onChangeType={onChangeType}
                            refStyle={config.reference?.style}
                            readonly={readonly}
                            warning={<MissingWarning />}
                            switchArticleType={switchArticleType}
                            stage={stage}
                            keywords={keywords}
                        />
                    </DropArea>
                </Box>
            );
        });

        const reuseArticle = !readonly && (
            <>
                <UsedInProjectInfo />
                {state.showReuseArticleModal && stage === Review.L1 && (
                    <ReuseArticleL1Modal
                        article={article}
                        articles={state.reusableArticles}
                        stageConfig={config[type].l1}
                        onFinish={async ({ selectedArticle, excludeReason }) => {
                            if (selectedArticle) {
                                await articleStore.reuseArticle({
                                    sourceId: selectedArticle._id,
                                    targetId: article._id,
                                    stage: "l1",
                                    type,
                                    excludeReason,
                                });
                            }
                        }}
                        onCancel={() => (state.showReuseArticleModal = false)}
                    />
                )}
                {state.showReuseArticleModal && stage === Review.L2 && (
                    <ReuseArticleL2Modal
                        article={article}
                        articles={state.reusableArticles}
                        stageConfig={config[type].l2}
                        configAdditionalData={config[type].additionalData}
                        onFinish={async ({
                            selectedArticle,
                            excludeReason,
                            questions,
                            additionalData,
                            completeReview,
                        }) => {
                            if (selectedArticle) {
                                await articleStore.reuseArticle({
                                    sourceId: selectedArticle._id,
                                    targetId: article._id,
                                    stage: "l2",
                                    type,
                                    excludeReason,
                                    questions,
                                    additionalData,
                                    completeReview,
                                });
                            }
                        }}
                        onCancel={() => (state.showReuseArticleModal = false)}
                    />
                )}
            </>
        );

        return (
            <div className="article-view">
                {reuseArticle}
                {view === "summary" && <SummaryView />}
                {view === "file" && (
                    <FileView
                        file={article.file}
                        highlights={articleStore.highlights}
                        readonly={readonly}
                        goto={area}
                    />
                )}
            </div>
        );
    },
);

/**
 * Render the view view
 */
const FileView = observer(({ file, highlights, goto, readonly }) => {
    // only show this if article is having a file
    if (!file) {
        return null;
    }

    return (
        <PdfViewer
            fileId={file}
            highlights={highlights}
            onAddHighlight={addHighlight}
            onRemoveHighlight={removeHighlight}
            goto={goto}
            preventCopy
        />
    );
});
export default Details;
