import React from "react";
import { observer } from "mobx-react";
import { Switch, Button, Tooltip } from "antd";
import "./style/categories.scoped.scss";
import Box from "@app/components/box/box";
import Form from "./form";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import confirm from "@app/components/confirm/index";

const Categories = observer(
    ({
        categories,
        onChange,
        add,
        onCancel,
        save,
        onEdit,
        onDelete,
        category,
        readonly = false,
    }) => {
        const update = async (data) => {
            if (data.documents?.count > 0 && data.enabled === false) {
                const proceed = await confirm(
                    `There are documents tagged under this category. Do you want to continue and disable this category?`,
                );
                if (!proceed) {
                    return;
                }
            }
            onChange(data);
        };

        const addCategory = (data) => {
            save && save(data);
        };

        const edit = (data) => {
            onEdit && onEdit(data);
        };

        const remove = (data) => {
            onDelete && onDelete(data);
        };

        const Controls = ({ category }) => {
            return (
                <div className="actions">
                    {!category.system && (
                        <>
                            <Button
                                onClick={() => edit(category)}
                                type="text"
                                icon={<EditOutlined />}
                            ></Button>

                            {category.documents?.count > 0 ? (
                                <Tooltip title="To delete please untag this category from respective documents.">
                                    <Button
                                        type="text"
                                        danger
                                        icon={<DeleteOutlined />}
                                        disabled={true}
                                    ></Button>
                                </Tooltip>
                            ) : (
                                <Button
                                    onClick={() => remove(category)}
                                    type="text"
                                    danger
                                    icon={<DeleteOutlined />}
                                ></Button>
                            )}
                        </>
                    )}
                </div>
            );
        };

        return (
            <>
                <div className="list">
                    {(categories ?? []).map((category, index) => {
                        return (
                            <Box className="card" key={index}>
                                {!readonly && <Controls category={category} />}
                                <div className="row items-center mb-15 unset">
                                    <div className="switch">
                                        <Switch
                                            checked={category.enabled}
                                            onChange={(value) =>
                                                update({ ...category, enabled: value }, index)
                                            }
                                            disabled={readonly}
                                        />
                                    </div>
                                    <div className="name">{category.name}</div>
                                </div>
                            </Box>
                        );
                    })}
                </div>
                {add && (
                    <Form
                        onSave={addCategory}
                        onCancel={onCancel}
                        categories={categories}
                        category={category}
                        onEdit={onEdit}
                    />
                )}
            </>
        );
    },
);

export default Categories;
