import React from "react";
import classNames from "classnames";
import { observer } from "mobx-react";

import "./style/count.scoped.scss";

const Count = observer(({ className, filter, ...rest }) => {
    const state = filter.state;
    const from = state.from + 1;
    const to = state.to;
    const total = state.stats.count;

    if (state.to === 0) {
        return null;
    }

    const classes = classNames("count", className);
    return (
        <div className={classes} {...rest}>
            {from} to {to} of {total}
        </div>
    );
});

export default Count;
