import { Mark } from "@tiptap/core";
import cn from "classnames";
import { v4 as uuid } from "uuid";
import { ReportDocumentSection } from "@app/constants";
import "./style.scss";

const DEFAULT_CLASS = "sample-text";

const generateAttributes = () => ({
    id: {
        default: uuid(),
        parseHTML: (element) => element.getAttribute("id"),
    },
    section: {
        default: null,
    },
    class: {
        default: undefined,
        renderHTML: () => ({
            class: cn({ [DEFAULT_CLASS]: true }),
        }),
    },
});

export const SampleTextMark = Mark.create({
    name: ReportDocumentSection.SAMPLE_TEXT.MARK_NAME,
    inline: true,
    inclusive: false,
    inclusiveRight: false,

    addAttributes: generateAttributes,

    parseHTML() {
        return [
            {
                tag: `span.${ReportDocumentSection.SAMPLE_TEXT.MARK_NAME}`,
                getAttrs: (node) => ({
                    id: uuid(),
                    section: node.getAttribute("section"),
                }),
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ["span", HTMLAttributes, 0];
    },

    addCommands() {
        return {};
    },
});

export default SampleTextMark;
