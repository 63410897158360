import React from "react";
import { observable, action } from "mobx";

var uuid = 1;

export class DrawerState {
    // a flag to re-render the page
    @observable _render = 0;

    drawers = [];

    /**
     * Add a new drawer to the page
     */
    @action
    add(drawer) {
        const drawers = this.drawers;

        // create the drawer entry
        const entry = {
            id: `drawer-${uuid++}`,
            drawer: drawer,
        };

        // add the drawer to the list
        drawers.push(entry);

        return {
            /**
             * Update the drawer children
             */
            update: (drawer) => {
                entry.drawer = drawer;

                // re-render the page
                this.render();
            },

            /**
             * Change a drawer ordering to the bottom of the list
             */
            bump: () => {
                let el = drawers.find((item) => {
                    return item.id === entry.id;
                });

                if (el) {
                    let idx = drawers.indexOf(el);
                    drawers.splice(idx, 1);
                    drawers.push(el);
                }

                // re-render the page
                this.render();
            },

            /**
             * Remove the drawer
             */
            remove: () => {
                let el = drawers.find((item) => {
                    return item.id === entry.id;
                });

                if (el) {
                    let idx = drawers.indexOf(el);
                    drawers.splice(idx, 1);
                }

                // re-render the page
                this.render();
            },
        };
    }

    /**
     * Increment the _render variable so a re-rendering of the drawer
     * container is initiated
     */
    @action
    render() {
        this._render++;
    }
}

/**
 * The page context definition
 */
export const DrawerContext = React.createContext(null);

/**
 * Export an instance of the drawer state
 */
export const drawerState = new DrawerState();
