import { Plugin, PluginKey } from "@tiptap/pm/state";

import { ReportDocumentSection } from "@app/constants";

const sourceDocumentTag = ReportDocumentSection.CAPTIS_LINK.SOURCE_DOCUMENT_LINK;
const sourceDocumentType = ReportDocumentSection.CAPTIS_LINK.TYPE.SOURCE_DOCUMENT;
const sourceDocumentArticle = ReportDocumentSection.ARTICLE_TEXT.ARTICLE_TEXT;

export const pasteHandler = (options) => {
    return new Plugin({
        key: new PluginKey("handlePasteCaptisLink"),
        props: {
            handlePaste: (_view, event) => {
                let html;
                const clipboardData = event.clipboardData || window.event.clipboardData;
                if (!clipboardData) {
                    return true;
                }

                if (options.editor.isActive(options.type.name)) {
                    return true;
                }

                html = clipboardData.getData("text/html");

                const hasTag = html?.indexOf(sourceDocumentTag) > -1;
                const hasArticleTag = html?.indexOf(sourceDocumentArticle) > -1;

                if (!hasTag && !hasArticleTag) {
                    return false;
                }

                const text = clipboardData.getData("text/plain");
                const link = getSourceDocumentAttrs(
                    html,
                    hasArticleTag ? sourceDocumentArticle : sourceDocumentTag,
                );

                if (link && text) {
                    options.editor.commands.insertCaptisLink({
                        ...link,
                        type: sourceDocumentType,
                        target: "_blank",
                        text,
                    });

                    return true;
                }

                return false;
            },
        },
    });
};

export const getSourceDocumentAttrs = (html, tag = sourceDocumentTag) => {
    let project, documentId, area, excel;
    const hasTag = html?.indexOf(tag) > -1;

    if (hasTag) {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(html, "text/html");
        const el = htmlDoc.getElementsByTagName(tag)?.[0];

        if (el) {
            project = el?.getAttribute("project");
            documentId = el?.getAttribute("documentId");
            area = el?.getAttribute("area");
            excel = el?.getAttribute("excel");
        }
    }

    if (project && (area || excel)) {
        return { project, documentId, area, excel, isArticle: tag === sourceDocumentArticle };
    }
};
