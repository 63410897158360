import { isInTable, selectionCell } from "@tiptap/pm/tables";
import { TextSelection } from "@tiptap/pm/state";
import { findTable } from "prosemirror-utils";

import TableStepper from "./table-stepper";

export default class TableUtils {
    editor;
    state;
    doc;
    tr;
    resolvedCelPos;
    table;
    tableJSON;
    tableCell;
    canSplit = true;
    tablePos;
    tableStart;
    tableSize;
    tableRow;
    splitCellRow;
    splitCellPosition;
    maxTotalWidth = 650;

    constructor(api) {
        const { editor, state, tr } = api;

        this.editor = editor;
        this.state = state;
        this.doc = editor.state.doc;
        this.tr = tr;

        this.init();
    }

    init() {
        if (!isInTable(this.state)) {
            this.canSplit = false;

            return;
        }

        this.resolvedCelPos = selectionCell(this.state);

        if (!this.resolvedCelPos) {
            this.canSplit = false;

            return;
        }

        const { pos, start, node } = findTable(this.state.selection) || {};

        if (!node) {
            this.canSplit = false;

            return;
        }

        this.table = node.copy(node.content);
        this.tablePos = pos;
        this.tableStart = start;
        this.tableSize = node.nodeSize;

        const { cellNode, rowIndex } = this._findCellAndRowInfo();

        this.tableCell = cellNode;
        this.tableRow = this.table.content.content[rowIndex];
        this.splitCellRow = rowIndex;
        this.splitCellPosition = this._getChildIndex(this.tableCell, this.tableRow);

        this._copyValues();

        this.tableJSON = this.table.toJSON();
    }

    /*
     ** Copies the values from the tableNode to a new table node to not affect the original table node.
     */
    _copyValues() {
        const tableJSON = JSON.parse(JSON.stringify(this.table.toJSON()));
        this.table = this.editor.schema.nodeFromJSON(tableJSON);
        this.tableRow = this.table.content.content[this.splitCellRow];
        this.tableCell = this.tableRow?.content?.content?.[this.splitCellPosition];

        if (!this.tableCell) {
            this.canSplit = false;
        }
    }

    _findCell() {
        let cellNode;

        this.state.doc.descendants((node, pos) => {
            if (
                ["tableHeader", "tableCell"].includes(node.type.name) &&
                this.state.selection.from >= pos &&
                this.state.selection.to <= pos + node.nodeSize
            ) {
                cellNode = node;
                return false; // Stop the traversal when the node is found
            }
        });

        if (!cellNode) {
            this.canSplit = false;
        }

        return cellNode;
    }

    _findCellAndRowInfo() {
        let cellNode;
        let rowIndex = 0;
        let rowCounter = 0;
        let found = false;

        this.state.doc.descendants((node, pos) => {
            if (node.type.name === "table") {
                rowCounter = 0;
                found = false;
            }

            if (!found && node.type.name === "tableRow") {
                rowCounter++;
            }

            if (
                !found &&
                ["tableHeader", "tableCell"].includes(node.type.name) &&
                this.state.selection.from >= pos &&
                this.state.selection.to <= pos + node.nodeSize
            ) {
                cellNode = node;
                rowIndex = rowCounter;
                found = true;
                return false;
            }
        });

        if (!cellNode) {
            this.canSplit = false;
            return {};
        }

        return { cellNode, rowIndex: rowIndex - 1 };
    }

    _getChildIndex(node, parentNode) {
        if (!node || !parentNode) {
            this.canSplit = false;

            return;
        }

        let index = 0;

        parentNode.content.content.forEach((n, i) => {
            if (n === node) {
                index = i;
            }
        });

        return index;
    }

    // working with JSON table representation and the path to the row
    insertCellsInRow(row, cells, index) {
        row.content.splice(index, 0, ...cells);
    }

    _cancel() {
        return { tr: this.tr, result: false };
    }

    _fixColumnWidths() {
        const columnCount = this.tableJSON.content[0].content.reduce(
            (acc, cell) => acc + cell.attrs.colspan,
            0,
        );
        const maxColumnWidth = Math.floor(this.maxTotalWidth / columnCount);

        this.tableJSON.content.forEach((row) => {
            row.content.forEach((cell) => {
                const colspan = cell.attrs.colspan || 1;

                cell.attrs.colwidth = new Array(colspan).fill(maxColumnWidth);
            });
        });
    }

    _executeSplit() {
        this._fixColumnWidths();
        const newTable = this.editor.schema.nodeFromJSON(this.tableJSON);

        const { from } = this.state.selection;

        this.tr = this.tr.replaceWith(this.tablePos, this.tablePos + this.tableSize, newTable);
        this.tr = this.tr.setSelection(TextSelection.create(this.tr.doc, from));

        return { tr: this.tr, result: true };
    }

    splitCellVertically(numberOfCells = 2) {
        if (!this.canSplit) {
            return this._cancel();
        }

        const { rowspan, colspan } = this.tableCell?.attrs || { rowspan: 1, colspan: 1 };

        // First check - the cell spans over multiple rows so before doing anything else just split this cell.
        if (colspan > 1) {
            // Get spans of new (inserted) cells and span to update of split cell.
            const { newCellsSpan, updatedSpan } = this.breakSpanEvenly(colspan, numberOfCells);

            this.tableCell.attrs.colspan = updatedSpan;

            // Each inserted cell will have the same attributes:
            const newCellsAttributes = { colspan: 1, rowspan: 1 };

            // Do not store default value in the model.
            if (newCellsSpan > 1) {
                newCellsAttributes.colspan = newCellsSpan;
            }

            // Copy rowspan of split cell.
            if (rowspan > 1) {
                newCellsAttributes.rowspan = rowspan;
            }

            const cellsToInsert = colspan > numberOfCells ? numberOfCells - 1 : colspan - 1;

            // insert in the same row after the cell we are splitting
            const cells = this.createCells(
                cellsToInsert,
                this.tableCell.type.name,
                newCellsAttributes,
            );

            this.insertCellsInRow(
                this.tableJSON.content[this.splitCellRow],
                cells,
                this.splitCellPosition + 1,
            );
        }

        // Second check - the cell has colspan of 1 or we need to create more cells then the currently one spans over.
        if (colspan < numberOfCells) {
            const cellsToInsert = numberOfCells - colspan;

            // First step: expand cells on the same column as split cell.
            const tableMap = [...new TableStepper(this.table)];

            // Get the column index of split cell.
            const { column: splitCellColumn } = tableMap.find(
                ({ cell }) => cell === this.tableCell,
            );

            // Find cells which needs to be expanded vertically - those on the same column or those that spans over split cell's column.
            const cellsToUpdate = tableMap.filter(({ cell, cellWidth, column }) => {
                const isOnSameColumn = cell !== this.tableCell && column === splitCellColumn;
                const spansOverColumn =
                    column < splitCellColumn && column + cellWidth > splitCellColumn;

                return isOnSameColumn || spansOverColumn;
            });

            // Expand cells vertically.
            for (const { cell, cellWidth } of cellsToUpdate) {
                cell.attrs.colspan = cellWidth + cellsToInsert;
            }

            // Second step: create columns after split cell.

            // Each inserted cell will have the same attributes:
            const newCellsAttributes = { colspan: 1, rowspan: 1 };

            // Copy rowspan of split cell.
            if (rowspan > 1) {
                newCellsAttributes.rowspan = rowspan;
            }

            // insert in the same row after the cell we are splitting
            const cells = this.createCells(
                cellsToInsert,
                this.tableCell.type.name,
                newCellsAttributes,
            );

            this.insertCellsInRow(
                this.tableJSON.content[this.splitCellRow],
                cells,
                this.splitCellPosition + 1,
            );
        }

        return this._executeSplit();
    }

    splitCellHorizontally(numberOfCells = 2) {
        if (!this.canSplit) {
            return this._cancel();
        }

        const { rowspan, colspan } = this.tableCell?.attrs || { rowspan: 1, colspan: 1 };

        // First check - the cell spans over multiple rows so before doing anything else just split this cell.
        if (rowspan > 1) {
            // Cache table map before updating table.
            const tableMap = [
                ...new TableStepper(this.table, {
                    startRow: this.splitCellRow,
                    endRow: this.splitCellRow + rowspan - 1,
                    includeAllSlots: true,
                }),
            ];

            // Get spans of new (inserted) cells and span to update of split cell.
            const { newCellsSpan, updatedSpan } = this.breakSpanEvenly(rowspan, numberOfCells);

            this.tableCell.attrs.rowspan = updatedSpan;

            const { column: cellColumn } = tableMap.find(({ cell }) => cell === this.tableCell);

            // Each inserted cell will have the same attributes:
            const newCellsAttributes = {};

            // Do not store default value in the model.
            if (newCellsSpan > 1) {
                newCellsAttributes.rowspan = newCellsSpan;
            }

            // Copy colspan of split cell.
            if (colspan > 1) {
                newCellsAttributes.colspan = colspan;
            }

            for (const tableSlot of tableMap) {
                const { column, row, cell } = tableSlot;

                // As both newly created cells and the split cell might have rowspan,
                // the insertion of new cells must go to appropriate rows:
                //
                // 1. It's a row after split cell + it's height.
                const isAfterSplitCell = row >= this.splitCellRow + updatedSpan;
                // 2. Is on the same column.
                const isOnSameColumn = column === cellColumn;
                // 3. And it's row index is after previous cell height.
                const isInEvenlySplitRow =
                    (row + this.splitCellRow + updatedSpan) % newCellsSpan === 0;

                if (isAfterSplitCell && isOnSameColumn && isInEvenlySplitRow) {
                    const cells = this.createCells(1, cell.type.name, newCellsAttributes);

                    this.tableJSON.content[row].content.splice(column, 0, cells[0]);
                }
            }
        }

        // Second check - the cell has rowspan of 1 or we need to create more cells than the current cell spans over.
        if (rowspan < numberOfCells) {
            // We already split the cell in check one so here we split to the remaining number of cells only.
            const cellsToInsert = numberOfCells - rowspan;

            // This check is needed since we need to check if there are any cells from previous rows than spans over this cell's row.
            const tableMap = [
                ...new TableStepper(this.table, { startRow: 0, endRow: this.splitCellRow }),
            ];

            // First step: expand cells.
            for (const { cell, cellHeight, row } of tableMap) {
                // Expand rowspan of cells that are either:
                // - on the same row as current cell,
                // - or are below split cell row and overlaps that row.
                if (cell !== this.tableCell && row + cellHeight > this.splitCellRow) {
                    const rowspanToSet = cellHeight + cellsToInsert;

                    cell.attrs.rowspan = rowspanToSet;
                }
            }

            // Second step: create rows with single cell below split cell.
            const newCellsAttributes = {};

            // Copy colspan of split cell.
            if (colspan > 1) {
                newCellsAttributes.colspan = colspan;
            }

            this.createEmptyRows(
                this.splitCellRow + 1,
                cellsToInsert,
                1,
                this.tableCell.type.name,
                newCellsAttributes,
            );
        }

        return this._executeSplit();
    }

    /**
     * Creates an empty table cell in the JSON format
     *
     * @param type The type of cell to create. tableHeader or tableCell.
     * @param attributes The attributes of the cells to create (colspan/rowspan).
     * @returns {Object} An empty table cell.
     */
    createEmptyTableCell(type = "tableCell", attrs = {}) {
        return {
            type,
            attrs,
            content: [{ type: "paragraph", attrs: { textAlign: "justify" } }],
        };
    }

    /**
     * Creates given number of empty cells.
     *
     * @param cellsCount The number of cells to create
     * @param type The type of cell to create. tableHeader or tableCell.
     * @param attributes The attributes of the cells to create (colspan/rowspan).
     * @returns {Array<Object>} An array of empty cells.
     */
    createCells(cellCount, type, attributes = {}) {
        const cells = [];

        for (let i = 0; i < cellCount; i++) {
            cells.push(this.createEmptyTableCell(type, attributes));
        }

        return cells;
    }

    /**
     * Creates empty rows at the given index in an existing table.
     *
     * @param insertAt The row index of row insertion.
     * @param rows The number of rows to create.
     * @param tableCellsToInsert The number of cells to insert in each row.
     * @param type The type of cell to create. tableHeader or tableCell.
     * @param attributes The attributes of the cells to create (colspan/rowspan).
     */
    createEmptyRows(insertAt, rows, tableCellsToInsert, type, attributes = {}) {
        const content = this.createCells(tableCellsToInsert, type, attributes);

        for (let i = 0; i < rows; i++) {
            const tableRow = {
                type: "tableRow",
                content,
            };

            this.tableJSON.content.splice(insertAt, 0, tableRow);
        }
    }

    /**
     * Breaks the existing span evenly into multiple cells.
     *
     * @param {*} span - existing span
     * @param {*} numberOfCells - number of cells into which to split
     * @returns {newCellsSpan: number, updatedSpan: number}
     */
    breakSpanEvenly(span, numberOfCells) {
        if (span < numberOfCells) {
            return { newCellsSpan: 1, updatedSpan: 1 };
        }

        const newCellsSpan = Math.floor(span / numberOfCells);
        const updatedSpan = span - newCellsSpan * numberOfCells + newCellsSpan;

        return { newCellsSpan, updatedSpan };
    }
}
