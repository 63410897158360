import { Plugin, PluginKey } from "@tiptap/pm/state";
import { DOMSerializer } from "@tiptap/pm/model";

const copyParserPlugin = ({ editor }) => {
    return new Plugin({
        key: new PluginKey("copyParserPlugin"),
        props: {
            handleDOMEvents: {
                async copy(view, event) {
                    event.preventDefault();

                    const { state } = view;
                    const { from, to } = state.selection;
                    const slice = state.doc.slice(from, to);

                    const fragment = DOMSerializer.fromSchema(state.schema).serializeFragment(
                        slice.content,
                    );

                    const div = document.createElement("div");
                    div.appendChild(fragment);

                    const removeCommentAttribute = (node) => {
                        if (node.hasAttribute("comment")) {
                            node.removeAttribute("comment");
                        }
                    };

                    div.querySelectorAll("*[comment]").forEach((node) => {
                        removeCommentAttribute(node);
                    });

                    div.querySelectorAll('span[data-type="dictionary"]').forEach((node) => {
                        const key = node.getAttribute("data-key");
                        const content = editor.storage.dictionary.store.use(key)?.text ?? key;

                        node.textContent = content;
                    });

                    div.querySelectorAll('span[data-type="abbreviation"]').forEach((node) => {
                        const key = node.getAttribute("data-key");
                        const content = editor.storage.abbreviation.store.use(key)?.name ?? key;

                        node.textContent = content;
                    });

                    div.querySelectorAll("span[data-cross-reference").forEach((node) => {
                        const crossReferencesMap =
                            editor.storage["cross-reference"].store.crossReferencesMap;
                        const key = node.getAttribute("id");
                        const crossReference = crossReferencesMap[key];
                        const content = crossReference
                            ? `${crossReference.ref}`
                            : `\u26A0 Missing Reference`;

                        node.textContent = content;
                    });

                    const clipboardData = event.clipboardData || window.clipboardData;

                    clipboardData.setData("text/html", div.innerHTML);
                    clipboardData.setData("text/plain", div.innerText);

                    return true;
                },
            },
        },
    });
};

export default copyParserPlugin;
