import React, { useRef } from "react";
import { observer, useLocalStore } from "mobx-react-lite";

import Menu from "./menu";
import "./style/highlighter.scoped.scss";

export const Highlighter = observer(
    ({ onAddHighlight, className, children, disable = false, ...rest }) => {
        const ref = useRef(null);
        const state = useLocalStore(() => ({
            selection: null,
            menu: {
                show: false,
                x: 0,
                y: 0,
            },
        }));

        /**
         * Handle the mouse up event to show the context menu if there is a selection
         */
        const onMouseUp = (event) => {
            // do not precess if the node is not rendered
            if (!ref.current) {
                return;
            }

            // make sure any text is selected
            const selection = window.getSelection();
            if (!disable && selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                const string = range.toString();

                if (string && string !== state.selection) {
                    state.selection = string;

                    // calculate the menu position
                    const box = ref.current.getBoundingClientRect();
                    const x = event.clientX - box.x;
                    const y = event.clientY - box.y;

                    // show the menu in the next tick. wait for the close event to
                    // fire before showing it.
                    setTimeout(() => {
                        state.menu = {
                            show: true,
                            x,
                            y,
                        };
                    }, 0);
                }
            }
        };

        /**
         * Clear the current selection
         */
        const clearSelection = () => {
            window.getSelection().removeAllRanges();
            state.selection = null;
        };

        /**
         * Handle the choice of action from the menu
         */
        const onAction = (action) => {
            if (action === "copy") {
                document.execCommand("copy");
            } else if (action === "highlight") {
                onAddHighlight(state.selection);
                clearSelection();
            }
        };

        return (
            <div
                className={`highlighter ${className ?? ""}`}
                onMouseUp={onMouseUp}
                {...rest}
                ref={ref}
            >
                {children}
                {state.menu.show && (
                    <Menu
                        x={state.menu.x}
                        y={state.menu.y}
                        onClose={() => {
                            state.menu.show = false;
                        }}
                        onClick={onAction}
                    />
                )}
            </div>
        );
    },
);

export default Highlighter;
