import React, { useEffect, useState } from "react";
import { useQuill } from "react-quilljs";
import MarkdownIt from "markdown-it";
import Turndown from "turndown";
import format from "@app/lib/format";

import "quill/dist/quill.snow.css";
import "./style/editor.scss";

const defaultOptions = {
    theme: "snow",
    modules: {
        toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ header: [1, false] }],
            [{ list: "ordered" }, { list: "bullet" }],
        ],
        clipboard: {
            matchVisual: false,
        },
    },
    formats: ["bold", "italic", "underline", "strike", "align", "list", "indent", "header"],
    placeholder: "Enter abstract details...",
};

// Configure markdown
const md = new MarkdownIt();
const td = new Turndown({ headingStyle: "atx" });

// Add custom style rules
td.addRule("underline", {
    filter: ["u"],
    replacement: function (content) {
        return "<u>" + content + "</u>";
    },
});
td.addRule("strikethrough", {
    filter: ["del", "s", "strike"],
    replacement: function (content) {
        return "~~" + content + "~~";
    },
});

md.set({ html: true });

const QuillEditor = ({
    content = "",
    options,
    onChange,
    doubleLineBreaks = false,
    children,
    ...rest
}) => {
    const { quill, quillRef } = useQuill(options ?? defaultOptions);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (quill && !loaded) {
            let renderContent = doubleLineBreaks ? content.replace(/\n/g, "\n\n") : content;
            renderContent = format.sanitize(renderContent);

            const mdContent = md.render(renderContent);
            quill.clipboard.dangerouslyPasteHTML(`${mdContent}\n`);

            quill.on("text-change", () => {
                const html = quill.container.firstChild.innerHTML;
                onChange && onChange(td.turndown(html));
            });

            setLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quill, content, loaded, onChange]);

    return (
        <div {...rest}>
            <div ref={quillRef} />
            {children}
        </div>
    );
};

export default QuillEditor;
