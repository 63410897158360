import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Tabs } from "antd";
import { CommentOutlined, InfoCircleOutlined, FilterOutlined } from "@ant-design/icons";
import { Page } from "@app/components/page";
import { SidebarTabKeys } from "@app/constants";
import { useSidebar } from "@app/components/page/sidebar";
import Comments from "@app/components/comments/sidebarComments";
import { scrollToSelection, scrollToSection } from "@app/components/report-document/editor/utils";
import { CitationFilter } from "@app/components/citation";

import "./styles/citation.scoped.scss";

const Sidebar = observer(({ state, filter, category, width = 400, readonly = false, ...rest }) => {
    const sidebar = useSidebar(null);
    const [activeKey, setActiveKey] = useState(SidebarTabKeys.COMMENTS_TAB);

    useEffect(() => {
        if (state.focusedComment) {
            sidebar.show();
            sidebar.setInline(true);
        }
    }, [sidebar, state.focusedComment, state.comments]);

    const switchTab = (key) => {
        setActiveKey(key);
    };

    return (
        <Page.Sidebar
            className="sidebar"
            sidebar={sidebar}
            width={width}
            padding={false}
            collapsible
            {...rest}
        >
            <Tabs
                type="card"
                activeKey={activeKey}
                defaultActiveKey={SidebarTabKeys.FILTER_TAB}
                onTabClick={switchTab}
                className="ant-tabs"
            >
                <Tabs.TabPane
                    tab={<FilterOutlined className="tab-icon" />}
                    key={SidebarTabKeys.FILTER_TAB}
                >
                    <CitationFilter filter={filter} />
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={<CommentOutlined className="tab-icon" />}
                    key={SidebarTabKeys.COMMENTS_TAB}
                >
                    <CommentsTab readonly={readonly} state={state} category={category} />
                </Tabs.TabPane>
            </Tabs>
        </Page.Sidebar>
    );
});

const CommentsTab = observer(({ readonly = false, state, category }) => {
    const onCommentFocus = (commentId, sectionId) => {
        if (!sectionId) {
            scrollToSelection(commentId);
        } else {
            scrollToSection(sectionId);
        }
    };

    return (
        <Comments
            state={state}
            readonly={readonly}
            category={category}
            onCommentFocus={onCommentFocus}
            noItemsContent={
                <div className="empty">
                    <div className="icon">
                        <InfoCircleOutlined />
                    </div>
                    <div className="text">
                        There are no comments available.
                        <br />
                        You can add a comment using the input field below.
                    </div>
                </div>
            }
        />
    );
});

export default Sidebar;
