import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";
import qs from "qs";
import featuresStore from "@app/state/store/features";
import applicationStore from "@app/state/store/application";

import DrawerContainer from "@app/components/drawer/container";
import TermsAndConditionsModal from "../login/modal/terms-and-conditions-modal";

import "./style/main.scoped.scss";

import Header from "./header";
import Loader from "../../loader/http";
import Navigation from "./navigation";

import session from "@app/state/store/session";
import profile from "@app/state/store/profile";

const MainLayout = observer(({ children }) => {
    const { search } = useLocation();
    const [termsModalIsOpen, setTermsModalIsOpen] = useState(true);

    const urlParams = qs.parse(search, {
        ignoreQueryPrefix: true,
    });

    useEffect(() => {
        featuresStore.setFeaturesInSession(urlParams.features);
        applicationStore.load();
        profile.load();
    }, []);

    if (!session.user?.termsAccepted) {
        return (
            <TermsAndConditionsModal
                visible={termsModalIsOpen}
                closable={false}
                onOk={() => {
                    profile.save({ ...profile.data, termsAccepted: true });
                    setTermsModalIsOpen(false);
                }}
                onCancel={() => {
                    session.logout();
                }}
            />
        );
    }

    return (
        <div className="layout main">
            <Loader />
            <Navigation />

            <div className="body">
                <Header />
                <div className="content">{children}</div>
            </div>

            <DrawerContainer />
        </div>
    );
});

export default MainLayout;
