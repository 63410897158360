import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Form, Button, Input } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import Group from "@app/components/setup/group";
import notify from "@app/components/notify";
import confirm from "@app/components/confirm";

import "./style.scoped.scss";

const Content = observer(({ form, reportDocument, onSave }) => {
    useEffect(() => {
        if (form && reportDocument) {
            form.setFieldsValue({ variables: reportDocument.variables ?? [] });
        }
    }, [reportDocument, form]);

    function addField() {
        const { variables } = form.getFieldsValue();
        const list = [
            ...variables,
            {
                label: "",
                key: "",
                value: "",
            },
        ];

        form.setFieldsValue({ variables: list });
    }

    function onFinish(data) {
        let variables = data.variables ?? [];

        variables = variables
            .map((field) => {
                field.label = field.label.trim();
                field.value = field.value.trim();
                field.key = field.key.trim().replace(/\W+/g, "").replace(/\s+/, "_").toUpperCase();

                return field;
            })
            .filter((field) => {
                return field.key && field.label;
            })
            .sort((a, b) => {
                if (a.label < b.label) {
                    return -1;
                } else if (a.label > b.label) {
                    return 1;
                } else {
                    return 0;
                }
            });

        onSave && onSave({ variables });
    }

    /**
     * Show the validation error
     */
    const validationError = () => {
        notify.error("Please fill all required fields to continue");
    };

    function normalizeKey(key = "") {
        key = key.toUpperCase().replace(/[^A-Z0-9]/gi, "_");
        return key;
    }

    const removeRow = async (remove) => {
        let proceed = await confirm("Are you sure you want to remove this row?");
        if (proceed) {
            await remove();
        }
    };

    return (
        <>
            <Form
                layout="vertical"
                form={form}
                onFinishFailed={validationError}
                onFinish={onFinish}
            >
                <Group title="Metadata" onAdd={addField}>
                    <div className="metadata-form">
                        <div className="field header">
                            <div className="col label">Name</div>
                            <div className="col key">Template</div>
                            <div className="col value">Value</div>
                        </div>

                        <Form.List name="variables">
                            {(fields, { remove }) => (
                                <div>
                                    {fields.map((field) => (
                                        <div className="field" key={field.name}>
                                            <div className="col label">
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, "label"]}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: "Please enter name",
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder="Label" />
                                                </Form.Item>
                                            </div>
                                            <div className="col key">
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, "key"]}
                                                    normalize={normalizeKey}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: "Please enter key",
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder="Key" />
                                                </Form.Item>
                                            </div>
                                            <div className="col value">
                                                <Form.Item {...field} name={[field.name, "value"]}>
                                                    <Input placeholder="Value" />
                                                </Form.Item>
                                            </div>
                                            <div className="col auto">
                                                <Button
                                                    type="icon"
                                                    icon={<DeleteOutlined />}
                                                    onClick={() =>
                                                        removeRow(() => remove(field.name))
                                                    }
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </Form.List>
                    </div>
                </Group>
            </Form>
        </>
    );
});

export default Content;
