import React from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import Page from "@app/components/page/page";
import Loader from "@app/components/loader/content";
import { Form, Button } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { UploadContextProvider } from "@app/components/form/upload/context";

import setup from "@app/state/store/projects/setup";
import StylesForm from "@app/components/setup/report-documents/styles/edit";

const Styles = observer(() => {
    const history = useHistory();
    const [form] = Form.useForm();

    const project = setup.project;
    const config = setup.config;
    const loaded = !!config;

    const handleClose = () => {
        history.push(`../report-documents`);
    };

    // Update Project Configuration - Enable/Disable Source Documents.
    const updateConfig = async (data) => {
        const updates = {
            reportDocuments: {
                ...config.reportDocuments,
                style: data,
            },
        };

        config.set(updates);
        await setup.updateConfig(updates);
    };

    return (
        <Page>
            <Page.Header title="Document Styles" closable onClose={handleClose} />
            <Page.Body maxWidth={1000}>
                {loaded && (
                    <UploadContextProvider projectId={project._id} clientId={project.client?._id}>
                        <StylesForm config={config} form={form} onSave={updateConfig} />
                    </UploadContextProvider>
                )}

                <Loader loading={!loaded} />
            </Page.Body>
            <Page.Footer>
                {loaded && (
                    <Page.Footer.Right>
                        <Button
                            icon={<SaveOutlined />}
                            type="primary"
                            onClick={() => form.submit()}
                        >
                            Save
                        </Button>
                        <Button onClick={handleClose}>Close</Button>
                    </Page.Footer.Right>
                )}
            </Page.Footer>
        </Page>
    );
});

export default Styles;
