import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { UploadContextProvider } from "@app/components/form/upload/context";

import report from "@app/state/store/report";

const Wrapper = observer((props) => {
    const { project } = useParams();
    const history = useHistory();
    const pathname = history.location.pathname;

    const isNotfound = pathname.includes("/r/404");

    React.useEffect(() => {
        // reset the project when project id changes
        if (isNotfound) {
            return;
        }

        if (report.id && report.id !== project) {
            report.unload();
        }

        report.load(project);

        return function () {
            report.unload();
        };
    }, [project]);

    React.useEffect(() => {
        // redirect to 404 if the project is deleted
        if (report.project && report.project.deleted && !isNotfound) {
            history.push(`/r/${project}/404`);
        }
    }, [report.project, pathname, history]);

    // do not render anything untill the project is loaded
    if (report.id !== project) {
        return null;
    }

    if (!report.loaded || !props.children) {
        return null;
    }

    return (
        <UploadContextProvider projectId={project} clientId={report.project?.client?._id}>
            {props.children}
        </UploadContextProvider>
    );
});

export default Wrapper;
