import React from "react";
import { Button, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined, CopyOutlined } from "@ant-design/icons";

import Card from "@app/components/card/card";
import "../style/config-card.scoped.scss";

export default function ConfigurationCard({
    configuration,
    onEdit,
    onRemove,
    onCopy,
    children,
    ...rest
}) {
    /**
     * Handle edit button click
     * @param {MouseEvent} event
     */
    const edit = (event) => {
        event.preventDefault();
        if (onEdit) {
            onEdit(configuration, event);
        }
    };

    /**
     * Handle delete button click
     * @param {MouseEvent} event
     */
    const remove = (event) => {
        event.preventDefault();
        if (onRemove) {
            onRemove(configuration, event);
        }
    };

    const copy = (event) => {
        event.preventDefault();
        if (onCopy) {
            onCopy(configuration._id);
        }
    };

    return (
        <Card className="config-card" {...rest}>
            <div className="details">
                <div className="name">{configuration.name}</div>
            </div>
            <div className="actions">
                <Tooltip title="Copy this configuration">
                    <Button onClick={copy} type="icon" icon={<CopyOutlined />}></Button>
                </Tooltip>
                <Button onClick={edit} type="icon" icon={<EditOutlined />}></Button>
                <Button onClick={remove} type="icon" danger icon={<DeleteOutlined />}></Button>
            </div>
        </Card>
    );
}
