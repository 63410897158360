import React from "react";
import { observer } from "mobx-react";
import { Button } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { Review } from "@app/constants";

import "./style/reuse-article.scoped.scss";

const ReuseArticleList = observer(({ articles = [], onUse }) => {
    const formatExclusionReason = (status) => {
        if (Review.PASS === status) {
            return <td style={{ color: "#4ECB71" }}>Included</td>;
        } else if (Review.FAIL === status) {
            return <td style={{ color: "#EC7C7A" }}>Excluded</td>;
        } else {
            return <td>-</td>;
        }
    };

    const showExclusionReason = (review) => {
        const currentReview = (review.stage === Review.L2 ? review?.l2 : review?.l1) || {};

        if (Review.FAIL === currentReview.status) {
            return <td>{currentReview.fail.reason}</td>;
        } else {
            return <td>-</td>;
        }
    };

    return (
        articles.length > 0 && (
            <div className="reuseArticleList">
                <table>
                    <thead>
                        <tr>
                            <td>Project name</td>
                            <td>L1</td>
                            <td>L2</td>
                            <td>Exclusion reason</td>
                            <td></td>
                        </tr>
                    </thead>

                    <tbody>
                        {articles.map((article, i) => {
                            return (
                                <tr key={i}>
                                    <td>
                                        <b>{article.projectName}</b>
                                    </td>
                                    {formatExclusionReason(article.review.l1?.status)}
                                    {formatExclusionReason(article.review.l2?.status)}
                                    {showExclusionReason(article.review)}
                                    <td className="actions">
                                        <Button
                                            type="primary"
                                            icon={<CheckOutlined />}
                                            onClick={async () => await onUse(article)}
                                        >
                                            Reuse
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        )
    );
});

export default ReuseArticleList;
