import { computed, action } from "mobx";
import http from "@app/lib/http";
import { events } from "@app/lib/store";
import BaseStore from "./base";
import Project from "@app/state/model/project";
import Client from "@app/state/model/client";
import FilterState from "@app/components/filter/state";

/**
 * State management controlling the project details
 */
export class Dashboard extends BaseStore {
    /**
     * observable store data
     */
    observable() {
        return {
            loading: false,
            projects: [],
            clients: [],
        };
    }

    constructor() {
        super();

        events.on("project.update", this.reload.bind(this));
        events.on("project.delete", this.reload.bind(this));
        events.on("project.clone", this.reload.bind(this));
        events.on("client.update", this.loadClients.bind(this));
        events.on("client.delete", this.loadClients.bind(this));

        this.filter = new FilterState({
            default: {
                my: "favorites",
            },
            rows: 20,
        });

        this.filter.on("find", () => {
            this.load();
        });
    }

    @computed get busy() {
        return this.loading;
    }

    /**
     * Load all dashboard related data
     */
    @action
    async load() {
        if (this.loading) {
            return;
        }

        await this.loadProjects();
    }

    /**
     * Reload the list
     */
    @action
    async reload() {
        return this.load();
    }

    /**
     * Load project lists
     */
    @action
    async loadProjects() {
        this.loading = true;

        let filter = this.filter.value();

        let { data } = await http.get("/projects/owned", filter);

        this.projects = data.list.map((entry) => {
            return new Project(entry);
        });

        this.filter.stats(data.stats);
        this.loading = false;
    }

    /**
     * Load the list of clients
     */
    @action
    async loadClients() {
        let { data } = await http.get("/clients");
        this.clients = data.list.map((entry) => {
            return new Client(entry);
        });
    }
}

export default new Dashboard();
