import { Plugin } from "prosemirror-state";

import { ReportDocumentSection } from "@app/constants";

export const notifyOnHelpTextModificationPlugin = ({ editor }) => {
    return new Plugin({
        filterTransaction(tx, state) {
            const {
                view: {
                    _props: { templateMode },
                },
                isEditable,
            } = editor;
            const { isVisible } = editor.storage[ReportDocumentSection.HELP_TEXT.MARK_NAME].store;

            if (isVisible || templateMode || !isEditable) {
                return true;
            }

            let helpTextModified = false;
            let isWholeDocReplacement = false;

            if (tx.docChanged && !tx.getMeta("addToHistory")) {
                const replaceSteps = [];

                tx.steps.forEach((step, index) => {
                    if (step.jsonID === "replace") {
                        replaceSteps.push(index);
                    }
                });

                replaceSteps.forEach((index) => {
                    const map = tx.mapping.maps[index];
                    const ranges = map.ranges;

                    const oldStart = ranges[0];
                    const oldEnd = ranges[0] + ranges[1];

                    const docSize = state.doc.content.size;

                    isWholeDocReplacement = oldStart === 0 && oldEnd === docSize;

                    state.doc.nodesBetween(oldStart, oldEnd, (node) => {
                        if (
                            node.attrs.helpText ||
                            node?.marks.some((mark) => mark.type.name === "helpTextMark")
                        ) {
                            helpTextModified = true;
                        }
                    });
                });
            }

            if (isWholeDocReplacement) {
                return true;
            }

            if (helpTextModified) {
                editor.emit("helpTextDeleteWarning");

                return false;
            }

            return true;
        },
    });
};

export default notifyOnHelpTextModificationPlugin;
