import { observable, action, computed } from "mobx";

export default class UploadState {
    @observable visible = false;
    @observable hasError = false;
    @observable files = [];

    /**
     * Get the number of files in the queue
     */
    @computed
    get count() {
        return this.files.length;
    }

    /**
     * Get the overall progress of the upload
     */
    @computed
    get progress() {
        if (!this.files.length) {
            return 0;
        }

        const complete = this.files.reduce((sum, file) => sum + file.progress, 0);
        return Math.round(complete / this.files.length);
    }

    /**
     * Add a new file to the queue
     */
    @action
    addFile(file) {
        this.visible = true;
        this.files.push(file);

        // remove the file on cancel
        file.on("cancel", () => {
            let idx = this.files.indexOf(file);
            this.files.splice(idx, 1);

            if (this.files.length === 0) {
                this.visible = false;
            }
        });

        // flag the upload as having errors
        file.on("error", () => {
            this.hasError = true;
        });
    }

    /**
     * Clear uploaded or failed files from the queue
     */
    @action
    clear() {
        this.files = this.files.filter((file) => !file.complete && !file.error);

        if (this.files.length === 0) {
            this.visible = false;
            this.hasError = false;
        }
    }
}
