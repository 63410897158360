import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

import BaseModal from "@app/components/modal";
import CaptionInput from "@app/components/caption/input";
import { Form } from "antd";
import { Select } from "antd";
import setup from "@app/state/store/projects/setup";

import "./style.scss";
import { ReportTable } from "@app/components/setup/reports/report-table";

const PreviewReportModal = observer(({ editor }) => {
    const [report, setReport] = React.useState(null);
    const { project } = useParams();

    useEffect(() => {
        if (editor) {
            editor.on("previewReport", openModal);

            return () => {
                editor.off("previewReport", openModal);
            };
        }
    }, [editor]);

    if (!editor) {
        return null;
    }

    const openModal = (report) => {
        setReport(report);
    };

    const closeModal = () => {
        setReport(null);
    };

    const ReportView = () => {
        if (report?.reportType === "prisma-flow") {
            const time = Date.now();
            return (
                <img
                    src={`/api/project/${project}/reports/prisma/${report.type}?${time}`}
                    style={{
                        display: "block",
                        margin: "auto",
                    }}
                />
            );
        }

        return (
            <ReportTable
                config={{
                    width: report?.width,
                    fontSize: report?.fontSize,
                    layout: report?.layout,
                    type: report?.reportType,
                }}
                rows={report?.rows}
                columns={report?.columns}
            />
        );
    };

    return (
        <BaseModal
            title="Preview Report"
            visible={report}
            width={report?.width + 100}
            onOk={closeModal}
            onCancel={closeModal}
            cancelButtonProps={{ style: { display: "none" } }}
        >
            <ReportView />
        </BaseModal>
    );
});

const InsertReportModal = observer(({ editor }) => {
    const [open, setOpen] = React.useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (editor) {
            editor.on("selectReport", openModal);

            return () => {
                editor.off("selectReport", openModal);
            };
        }
    }, [editor]);

    if (!editor || !setup.config) {
        return null;
    }

    function openModal() {
        setOpen(true);
    }

    function closeModal() {
        setOpen(false);
    }

    function onSubmit() {
        const reportId = form.getFieldValue("reportId");
        const caption = form.getFieldValue("caption");
        let report, type;

        // find the report
        for (type of ["slr", "sota"]) {
            const reports = setup.config.reports[type];
            report = reports.find((entry) => entry._id === reportId);
            if (report) {
                break;
            }
        }

        if (report) {
            editor.commands.insertReport({
                report: reportId,
                type,
                caption,
                dataType: report.dataType,
                reportType: report.reportType,
            });
            closeModal();
            form.resetFields();
        }
    }

    const due = setup.config.reports?.["slr"]?.map((report) => ({
        name: `${report.reportName} (DUE)`,
        value: report._id,
    }));
    let options = [...due];

    if (setup.config.sota.enabled) {
        const sota = setup.config.reports?.["sota"]?.map((report) => ({
            name: `${report.reportName} (SoTA)`,
            value: report._id,
        }));

        options.push(...sota);
    }

    return (
        <BaseModal
            title="Insert Report"
            visible={open}
            okText="Insert Report"
            onOk={onSubmit}
            onCancel={closeModal}
            bodyStyle={{ overflow: "visible" }}
        >
            <Form form={form} layout="vertical" name="insertReportForm">
                <Form.Item label="Report" name="reportId">
                    <Select>
                        {options.map((option) => (
                            <Select.Option key={option.value} value={option.value}>
                                {option.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="Caption" name="caption">
                    <CaptionInput />
                </Form.Item>
            </Form>
        </BaseModal>
    );
});

const InsertReportWrapper = ({ editor }) => {
    if (!setup.config && !editor) {
        return null;
    }

    return (
        <>
            <PreviewReportModal editor={editor} />
            <InsertReportModal editor={editor} />
        </>
    );
};

export default InsertReportWrapper;
