import React, { useState } from "react";
import { observer } from "mobx-react";
import { Button, Tooltip, Input } from "antd";
import { LoadingOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import ArrowRight from "@app/assets/icons/arrow-right.js";

import "./style/ai-chat.scoped.scss";

const AIChatDialogue = observer(({ onSubmit, loading }) => {
    const [text, setText] = useState("");

    const submit = async () => {
        if (!loading) {
            const message = text?.trim();

            if (message) {
                onSubmit && (await onSubmit(message));
                setText("");
            }
        }
    };

    return (
        <div className="dialogueContainer">
            <div className="dialogueHeader">
                <div>CAPTIS Copilot Chat</div>
                <div>
                    <Tooltip
                        title="Ask your question to CAPTIS Copilot on the file attached to the article"
                        placement="topLeft"
                    >
                        <QuestionCircleOutlined />
                    </Tooltip>
                </div>
            </div>
            <div className="dialogueInput">
                <Input.TextArea
                    allowClear
                    autoSize={{ minRows: 3, maxRows: 3 }}
                    placeholder="Type your question to CAPTIS Copilot here..."
                    onChange={(e) => setText(e.currentTarget.value)}
                    onPressEnter={(e) => {
                        e.preventDefault();
                        submit();
                    }}
                    value={text}
                />
                <span className="dialogueSubmit">
                    {loading ? (
                        <Button type="icon" icon={<LoadingOutlined />} />
                    ) : (
                        <Button type="icon" icon={<ArrowRight />} onClick={submit} />
                    )}
                </span>
            </div>
        </div>
    );
});

export default AIChatDialogue;
