import React from "react";
import classNames from "classnames";
import { MarkdownViewer } from "@app/components/markdown-editor";

import "./style/snippets.scoped.scss";

/**
 * Render the id list
 */
const Snippets = ({ article, includeAbstract = true, keywords = [], className, ...rest }) => {
    let snippets = [];

    // add the abstract to the snippets
    if (article.abstract && includeAbstract) {
        snippets.push(article.abstract);
    }

    // add the abstract to the snippets
    if (article.snippet) {
        snippets.push(article.snippet);
    }

    // add the snippets to the snippets
    snippets = snippets.concat(article.snippets);

    // return a missing abstract message when no abstract/snippets to show
    if (snippets.length === 0) {
        return (
            <div className={classNames("snippets", className)} {...rest}>
                <div className="no-text">No abstract available</div>
            </div>
        );
    }

    return (
        <div className={classNames("snippets", className)} {...rest}>
            {snippets.map((snippet, index) => {
                return (
                    <div className="snippet" key={index}>
                        <MarkdownViewer
                            className="card"
                            content={snippet}
                            keywords={keywords}
                            truncate={400}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default Snippets;
