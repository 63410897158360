import React from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { SplitPage } from "@app/components/page";

import List from "./list";
import Article from "./article";

import "./style/l1.scoped.scss";

const L1 = observer(() => {
    const { article } = useParams();
    return <SplitPage className="l1" left={<List />} right={article ? <Article /> : null} />;
});

export default L1;
