import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { Button, Tooltip } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import Avatar from "@app/components/user/avatar";
import format from "@app/lib/format";
import logo from "@app/assets/logo-icon2.svg";

import "./style/ai-chat.scoped.scss";

const AIChatHistory = observer(({ history = [] }) => {
    const bottomRef = useRef(null);

    useEffect(() => {
        if (bottomRef && bottomRef.current) {
            bottomRef.current.scrollIntoView({
                behavior: "smooth",
            });
        }
    }, [history.length]);

    const ChatItem = ({ item, showCopy = false }) => {
        const defaultUser = {
            fullName: "CAPTIS Copilot",
        };
        const user = item.user || defaultUser;

        const copy = () => {
            const data = {};
            data["text/plain"] = new Blob([item.content], { type: "text/plain" });
            navigator.clipboard.write([new window.ClipboardItem(data)]);
        };

        return (
            <div className="chatItem">
                <div className="chatSignature">
                    <Tooltip title={user.fullName} placement="topLeft">
                        {item.user ? (
                            <Avatar className={`avatar`} user={user} size={40} />
                        ) : (
                            <img src={logo} className="logo" />
                        )}
                    </Tooltip>
                    <div>
                        <div>{user?.fullName}</div>
                        <div className="dateTime">{format.datetime(item.created)}</div>
                    </div>
                    {showCopy && (
                        <Button
                            type="icon"
                            icon={<CopyOutlined />}
                            className="copy"
                            onClick={copy}
                        />
                    )}
                </div>
                <div className="chatContent">{item.content}</div>
            </div>
        );
    };

    return (
        <div className="historyContainer">
            {history.map((item) => {
                return (
                    <div key={item._id} className="historyGroup">
                        <ChatItem item={item} />
                        {item.answer && (
                            <div className="answer">
                                <ChatItem item={item.answer} showCopy={true} />
                            </div>
                        )}
                    </div>
                );
            })}
            <div ref={bottomRef}></div>
        </div>
    );
});

export default AIChatHistory;
