import * as React from "react";

import { HighlightRect } from "./highlight-rect";
import { HighlightSelectionState, NO_SELECTION_STATE, SelectedState } from "./highlight-state";
import { getCssProperties } from "./transform-area";
import { useRotation } from "./useRotation";

import { PulseCircle } from "./pulse-circle";

export const HighlightAreaList = ({
    pageIndex,
    renderHighlightContent,
    renderHighlightTarget,
    renderHighlights,
    store,
}) => {
    const [highlightState, setHighlightState] = React.useState(store.get("highlightState"));
    const { rotation } = useRotation(store);

    const handleHighlightState = (s) => setHighlightState(s);

    // Cancel the selection
    const cancel = () => {
        window.getSelection().removeAllRanges();
        store.update("highlightState", NO_SELECTION_STATE);
    };

    React.useEffect(() => {
        store.subscribe("highlightState", handleHighlightState);

        return () => {
            store.unsubscribe("highlightState", handleHighlightState);
        };
    }, []);

    // Filter the selections
    const listAreas =
        highlightState instanceof HighlightSelectionState
            ? highlightState.highlightAreas.filter((s) => s.pageIndex === pageIndex && !s.isPoint)
            : [];
    const pointArea = highlightState.highlightAreas.find(
        (s) => s.pageIndex === pageIndex && s.isPoint,
    );

    return (
        <>
            {renderHighlightTarget &&
                highlightState instanceof SelectedState &&
                highlightState.selectionRegion.pageIndex === pageIndex &&
                renderHighlightTarget({
                    highlightAreas: highlightState.highlightAreas,
                    selectedText: highlightState.selectedText,
                    selectionRegion: highlightState.selectionRegion,
                    selectionData: highlightState.selectionData,
                    cancel,
                    toggle: () => {
                        store.update(
                            "highlightState",
                            new HighlightSelectionState(
                                highlightState.selectedText,
                                highlightState.highlightAreas,
                                highlightState.selectionData,
                                highlightState.selectionRegion,
                            ),
                        );
                        window.getSelection().removeAllRanges();
                    },
                })}
            {renderHighlightContent &&
                highlightState instanceof HighlightSelectionState &&
                highlightState.selectionRegion.pageIndex === pageIndex &&
                renderHighlightContent({
                    highlightAreas: highlightState.highlightAreas,
                    selectedText: highlightState.selectedText,
                    selectionRegion: highlightState.selectionRegion,
                    selectionData: highlightState.selectionData,
                    cancel,
                })}
            {listAreas.length > 0 && (
                <div>
                    {listAreas.map((area, idx) => (
                        <HighlightRect key={idx} area={area} rotation={rotation} />
                    ))}
                </div>
            )}
            {pointArea && (
                <PulseCircle
                    style={{
                        ...getCssProperties(pointArea, rotation),
                        position: "absolute",
                    }}
                />
            )}
            {renderHighlights &&
                renderHighlights({
                    pageIndex,
                    rotation,
                    getCssProperties,
                })}
        </>
    );
};
