import { action, computed } from "mobx";
import http from "@app/lib/http";

import BaseStore from "@app/state/store/base";

import report from "../../../report";

export class AppendixStore extends BaseStore {
    observable() {
        return {
            loading: false,
            list: [],
        };
    }

    @computed get busy() {
        return this.loading;
    }

    @computed get project() {
        return report.id;
    }

    process(data) {
        const searchResults = {};

        // find count per search
        data.map((article, index) => {
            if (searchResults[article.search] === undefined) {
                searchResults[article.search] = 1;
                article.firstInstance = true;
            } else {
                searchResults[article.search]++;
            }
        });

        // Apply rowSpan Property only for the first query.
        data.filter((article) => {
            return article.firstInstance === true;
        }).map((article) => {
            article.rowSpan = searchResults[article.search];
        });

        return data;
    }

    @action
    async load(type) {
        this.loading = true;
        let { data } = await http.get(`/project/${this.project}/reports/articles/${type}`);
        this.list = this.process(data);
        this.loading = false;
    }
}

export default new AppendixStore();
