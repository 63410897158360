import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

import project from "@app/state/store/projects/details";

import GeneralStep from "./edit/general";

const Details = observer(() => {
    const { id } = useParams();

    // initialize the state when component mounts. It is triggered
    // only when the id parameter changes so it will load the
    // project details corresponding to the passed id
    useEffect(() => {
        if (id) {
            project.load(id);
        } else {
            project.create();
        }
    }, [id]);

    // return empty page while loading
    if (project.id !== id) {
        return null;
    }

    return <GeneralStep />;
});

export default Details;
