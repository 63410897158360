import React from "react";
import { observer } from "mobx-react";
import Filter from "@app/components/filter";
import Sidebar from "@app/components/filter/sidebar";
import { CustomCategories, DocumentStatus } from "@app/constants";

const DocumentFilter = observer(({ filter, config, categoryScreen, allCategories }) => {
    config = config || {};
    const facets = filter.state.stats?.facets || {};

    const status = facets.status || {};
    const documentTags = facets.documentTags || {};
    const tags = config.documentTags || [];
    const facetCategories = facets.categories || {};

    return (
        <Sidebar filter={filter}>
            <Filter.OptionGroup
                filter={filter}
                title="Document Status"
                radio
                name="status"
                groupKey="document.status"
            >
                <Filter.Option
                    label="Accepted"
                    value={DocumentStatus.ACCEPTED}
                    count={status.accepted ?? 0}
                />
                <Filter.Option
                    label="Rejected"
                    value={DocumentStatus.REJECTED}
                    count={status.rejected ?? 0}
                />
                <Filter.Option
                    label="Pending"
                    value={DocumentStatus.PENDING}
                    count={status.pending ?? 0}
                />
            </Filter.OptionGroup>

            {categoryScreen === CustomCategories.ALL && (
                <Filter.MultiSelect
                    filter={filter}
                    title="Category"
                    name="category"
                    groupKey="document.category"
                    options={allCategories?.map((category) => {
                        return {
                            label: `(${facetCategories[category._id] ?? 0}) ${category.name}`,
                            value: category._id,
                        };
                    })}
                />
            )}

            {tags.length > 0 && (
                <Filter.OptionGroup
                    filter={filter}
                    title="Tags"
                    name="documentTags"
                    groupKey="document.tags"
                >
                    {tags.map((tag) => {
                        return (
                            <Filter.Option
                                key={tag._id}
                                label={tag.label}
                                value={tag.label}
                                count={documentTags[tag.label] ?? 0}
                            />
                        );
                    })}
                </Filter.OptionGroup>
            )}
        </Sidebar>
    );
});

export default DocumentFilter;
