import _ from "lodash";
import React, { useEffect } from "react";
import { v4 as uuid } from "uuid";
import { observer, useLocalStore } from "mobx-react-lite";
import { Form, Input, Select, Button, Switch, Tooltip } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import classNames from "classnames";
import notify from "@app/components/notify/index";
import List from "@app/components/list/ordered";
import Modal from "@app/components/modal";
import { ReactComponent as DragIcon } from "@app/assets/icons/drag-handle.svg";
import { MarkdownEditor, MarkdownViewer } from "@app/components/markdown-editor";
import ColorPicker from "@app/components/form/color-picker";

import "./style/form.scoped.scss";
import confirm from "@app/components/confirm/index";

const markdownOptions = {
    theme: "snow",
    modules: {
        toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
        ],
        clipboard: {
            matchVisual: false,
        },
    },
    formats: ["bold", "italic", "underline", "strike", "align", "list", "indent", "header"],
};

const QuestionForm = observer(({ question, existing, useScore = false, onSave, onCancel }) => {
    const [form] = Form.useForm();
    const state = useLocalStore(() => ({
        question: null,
        contribution: null,
        color: null,
        conditional: false,
        condition: {
            question: null,
            answer: null,
        },
        answers: [],
        conditionAnswers: [],
        requireScore: true,
    }));
    const dependencyQuestions = existing?.filter((q) => question?._id !== q._id) ?? [];

    useEffect(() => {
        if (question) {
            state.question = question.question;
            state.contribution = question.contribution;
            state.color = question.color;
            state.conditional = question.conditional;
            state.condition = question.condition ?? {};
            state.answers = _.cloneDeep(question.answers).map((el) => {
                el.key = el._id || uuid();
                el.$error = {};

                return el;
            });
            state.requireScore = question.requireScore;
        } else {
            state.question = "";
            state.contribution = "";
            state.color = undefined;
            state.conditional = false;
            state.condition = {};
            state.answers = [];
            state.requireScore = true;

            addAnswer();
        }

        if (state.condition?.question) {
            onPickQuestion(state.condition?.question);
        }

        form.setFieldsValue({
            question: state.question,
            contribution: state.contribution,
            color: state.color,
            conditional: state.conditional,
            conditionQuestion: state.condition?.question,
            conditionAnswer: state.condition?.answer,
            requireScore: state.requireScore,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, question, state]);

    /**
     * Checks if the answer is empty
     */
    const isEmpty = (answer) => {
        let required = ["answer", "grade"];
        if (useScore) {
            required = ["answer", "grade", "score"];
        }

        for (let prop of required) {
            if (answer[prop]) {
                return false;
            }
        }

        return true;
    };

    /**
     * Show the validation error
     */
    const validationError = () => {
        notify.error("Please fill all required fields to continue");
    };

    /**
     * Add a new answer to the list
     */
    const addAnswer = () => {
        state.answers.push({
            key: uuid(),
            $error: {},
        });
    };

    /**
     * Remove an answer from the list
     */
    const removeAnswer = async (answer) => {
        let proceed = await confirm("Are you sure you want to remove the answer?");
        if (proceed) {
            let idx = state.answers.indexOf(answer);
            state.answers.splice(idx, 1);
        }
    };

    /**
     * Handle the conditional checkbox change event
     */
    const onConditionalToggle = () => {
        state.conditional = !state.conditional;
    };

    // Handle the requireScore toggle change event
    const onRequireScoreToggle = () => {
        state.requireScore = !state.requireScore;
    };

    /**
     * Change the list of conditional answers based on the picked question
     */
    const onPickQuestion = (id) => {
        const question = existing.find((q) => q._id === id);
        if (question) {
            state.conditionAnswers = question.answers.map((answer) => {
                return {
                    value: answer._id,
                    label: `${answer.grade} - ${answer.answer}`,
                };
            });
        } else {
            state.conditionAnswers = [];
        }

        form.setFieldsValue({
            conditionAnswer: undefined,
        });
    };

    /**
     * Validate the answers on save
     */
    const save = (data) => {
        state.question = data.question;
        state.contribution = data.contribution;
        state.color = data.color;
        state.condition.question = data.conditionQuestion;
        state.condition.answer = data.conditionAnswer;

        // validate Questions and Data Contributions
        let validationError = false;
        _.cloneDeep(existing).map((entry) => {
            const id = question?._id;
            if (
                entry.question.toLowerCase().trim() === data.question.toLowerCase().trim() &&
                entry._id !== id
            ) {
                notify.error(
                    "The entered question already exists in the project. " +
                        "Please modify your input and try again",
                );
                validationError = true;
                return;
            }
            if (
                entry.contribution.toLowerCase().trim() ===
                    data.contribution.toLowerCase().trim() &&
                entry._id !== id
            ) {
                notify.error(
                    "The entered report label already exists in the project. " +
                        "Please modify your input and try again",
                );
                validationError = true;
                return;
            }
        });

        if (validationError) {
            return;
        }

        // validate the answers
        let valid = true;
        let required = ["answer", "grade"];
        if (useScore && state.requireScore) {
            required = ["answer", "grade", "score"];
        }

        // filter out all answers without values
        const answers = state.answers.filter((answer) => {
            return !isEmpty(answer);
        });

        // validate the answers
        answers.forEach((answer, index) => {
            answer.$error = {};

            for (let prop of required) {
                let value = answer[prop];

                if (prop === "score") {
                    if (value === undefined || Number(value) < 0) {
                        answer.$error[prop] = true;
                        valid = false;
                    }
                } else if (!value) {
                    answer.$error[prop] = true;
                    valid = false;
                } else if (typeof value === String && value.match(/^\s*$/)) {
                    answer.$error[prop] = true;
                    valid = false;
                }
            }

            // convert the score to number
            if (answer.score !== undefined) {
                answer.score = Number(answer.score);
            } else if (!useScore) {
                answer.score = index + 1;
            }
        });

        // check for valid answers
        if (!valid) {
            return notify.error(
                "Some answers are missing required values or have invalid values. Please correct them to continue",
            );
        }

        // should have at least two answers
        if (answers.length < 2) {
            notify.error("Please add at least two answers");
            return;
        }

        onSave({
            _id: question?._id,
            question: state.question,
            contribution: state.contribution,
            color: state.color,
            conditional: state.conditional,
            condition: state.condition,
            answers: answers.map((answer) => {
                return {
                    ...answer,
                };
            }),
            requireScore: state.requireScore,
        });

        if (!question) {
            state.question = "";
            state.contribution = "";
            state.color = undefined;
            state.conditional = false;
            state.condition = {};
            state.answers = [];
            state.requireScore = true;

            addAnswer();
        }
    };

    /**
     * Reorder the answers
     */
    const reorder = (action) => {
        let [item] = state.answers.splice(action.from, 1);
        state.answers.splice(action.to, 0, item);
    };

    /**
     * Update a field value
     */
    const setValue = (answer, field, value) => {
        answer[field] = value;
        answer.$error[field] = false;

        let idx = state.answers.indexOf(answer);
        if (idx === state.answers.length - 1) {
            addAnswer();
        }
    };

    /**
     * Cancel the edit
     */
    const cancel = () => {
        onCancel && onCancel();
        form.resetFields();
    };

    const title = question ? "Edit question" : "Add question";

    return (
        <Modal
            title={title}
            visible={true}
            okText="Save"
            onOk={form.submit.bind(form)}
            onCancel={cancel}
            width={1000}
        >
            <div className="qform">
                <Form
                    layout="vertical"
                    form={form}
                    onFinishFailed={validationError}
                    onFinish={save}
                >
                    <Form.Item
                        label="Review Question"
                        name="question"
                        rules={[
                            {
                                required: true,
                                message: "Please enter the question",
                                whitespace: true,
                            },
                        ]}
                    >
                        <MarkdownEditor options={markdownOptions} content={question?.question} />
                    </Form.Item>

                    <Form.Item
                        label="Report Label"
                        name="contribution"
                        rules={[
                            {
                                required: true,
                                message: "Please enter the label",
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item label="Color" name="color">
                        <ColorPicker />
                    </Form.Item>

                    <Form.Item name="conditional" valuePropName="checked">
                        <Tooltip
                            placement="topLeft"
                            title="Conditional questions allow you to show or hide some questions
                               based on the answers. Marking a question as conditional means that
                               its visibility in the final L2 questionnaire is dependent on the
                               selection of a particular answer from a previous question."
                        >
                            <div className="row inline toggle items-center">
                                <div className="col auto">
                                    <Switch
                                        onChange={onConditionalToggle}
                                        checked={state.conditional}
                                    />
                                </div>
                                <div className="col ml-15 clickable" onClick={onConditionalToggle}>
                                    Conditional question
                                </div>
                            </div>
                        </Tooltip>
                    </Form.Item>

                    {state.conditional && (
                        <>
                            <Form.Item
                                label="Question (Condition)"
                                name="conditionQuestion"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please pick a question",
                                    },
                                ]}
                            >
                                <Select
                                    optionFilterProp="label"
                                    placeholder="Please select a question"
                                    onChange={onPickQuestion}
                                >
                                    {dependencyQuestions.map((q) => {
                                        return (
                                            <Select.Option
                                                key={q._id}
                                                value={q._id}
                                                label={q.question}
                                            >
                                                {
                                                    <MarkdownViewer
                                                        className="pt-5"
                                                        content={q.question}
                                                    />
                                                }
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Answer (Condition)"
                                name="conditionAnswer"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please pick an answer",
                                    },
                                ]}
                            >
                                <Select
                                    optionFilterProp="label"
                                    placeholder="Please select an answer"
                                >
                                    {state.conditionAnswers.map((answer) => {
                                        return (
                                            <Select.Option
                                                key={answer.value}
                                                value={answer.value}
                                                label={answer.label}
                                            >
                                                {answer.label}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </>
                    )}

                    {useScore && (
                        <Form.Item name="requireScore" valuePropName="checked">
                            <div className="row inline toggle items-center">
                                <div className="col auto">
                                    <Switch
                                        onChange={onRequireScoreToggle}
                                        checked={!state.requireScore}
                                    />
                                </div>
                                <div className="col ml-15 clickable" onClick={onRequireScoreToggle}>
                                    Question does not require a score
                                </div>
                            </div>
                        </Form.Item>
                    )}

                    <div className="answers">
                        <div className="header">
                            <div className="title">Answers</div>
                            <div className="buttons">
                                <Button icon={<PlusOutlined />} type="icon" onClick={addAnswer} />
                            </div>
                        </div>
                        <div className="body">
                            <div className="row answer labels">
                                <div className="col handle"></div>
                                <div className="col grade">Grade</div>
                                <div className="col answer">Answer</div>
                                {useScore && state.requireScore && (
                                    <div className="col score">Score</div>
                                )}
                                <div className="col remove"></div>
                            </div>
                            <List onChange={reorder}>
                                {state.answers.map((entry, index) => {
                                    return (
                                        <List.Item key={entry.key} id={entry.key} index={index}>
                                            <div className="row answer">
                                                <div className="col handle">
                                                    <DragIcon />
                                                </div>

                                                <div
                                                    className={classNames("col grade", {
                                                        invalid: entry.$error?.grade,
                                                    })}
                                                >
                                                    <Input
                                                        value={entry.grade}
                                                        onChange={(e) =>
                                                            setValue(entry, "grade", e.target.value)
                                                        }
                                                    />
                                                </div>

                                                <div
                                                    className={classNames("col answer", {
                                                        invalid: entry.$error?.answer,
                                                    })}
                                                >
                                                    <Input
                                                        value={entry.answer}
                                                        onChange={(e) =>
                                                            setValue(
                                                                entry,
                                                                "answer",
                                                                e.target.value,
                                                            )
                                                        }
                                                    />
                                                </div>

                                                {useScore && state.requireScore && (
                                                    <div
                                                        className={classNames("col score value", {
                                                            invalid: entry.$error?.score,
                                                        })}
                                                    >
                                                        <Input
                                                            type="number"
                                                            min={0}
                                                            value={entry.score}
                                                            onChange={(e) =>
                                                                e.target.value >= 0 &&
                                                                setValue(
                                                                    entry,
                                                                    "score",
                                                                    e.target.value,
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                )}

                                                <div className="col remove">
                                                    <Button
                                                        type="icon"
                                                        icon={<DeleteOutlined />}
                                                        onClick={() => removeAnswer(entry)}
                                                    />
                                                </div>
                                            </div>
                                        </List.Item>
                                    );
                                })}
                            </List>
                        </div>
                    </div>
                </Form>
            </div>
        </Modal>
    );
});

export default QuestionForm;
