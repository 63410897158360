import React, { useState } from "react";
import { observer } from "mobx-react";
import { Form, Select } from "antd";

import Modal from "@app/components/modal";

import versionAliasStore from "@app/state/store/report-document/version-alias";

const CompareVersionsModal = observer(() => {
    const {
        aliases,
        showCompareVersionsModal,
        onHideCompareVersionsModal,
        versionsToCompareOptions,
        onCompareVersions,
    } = versionAliasStore;
    const [form] = Form.useForm();
    const [versionA, setVersionA] = useState(null);

    const latestVersionAsOption =
        {
            value: "latest",
            label: "latest",
        } || {};

    /**
     * Cancel the edit
     */
    const cancel = () => {
        onHideCompareVersionsModal();
        setVersionA(null);
        form.resetFields();
    };

    /**
     * Save the reason
     */
    const save = async ({ versionA, versionB }) => {
        await onCompareVersions(versionA, versionB);
        cancel();
    };

    let versionBOptions = versionsToCompareOptions;

    if (versionA) {
        const selectedVersionA = aliases.find((v) => v._id === versionA);

        versionBOptions = aliases
            .filter(
                ({ versionDate, _id }) =>
                    new Date(versionDate) >= new Date(selectedVersionA.versionDate) &&
                    _id !== selectedVersionA._id,
            )
            .map(({ _id, versionName }) => ({
                label: versionName,
                value: _id,
            }));
    }

    return (
        <Modal
            title={"Compare Versions"}
            visible={showCompareVersionsModal}
            okText="Compare"
            onOk={form.submit.bind(form)}
            onCancel={cancel}
            width={600}
        >
            <div className="reason-form">
                <Form layout="vertical" form={form} onFinish={save}>
                    <Form.Item
                        label="Select version A:"
                        name="versionA"
                        rules={[
                            {
                                required: true,
                                message: "Please select from the list...",
                                whitespace: true,
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Please first select version A from the list..."
                            optionFilterProp="label"
                            filterOption={(input, option) => (option?.label ?? "").includes(input)}
                            options={versionsToCompareOptions}
                            onSelect={(d) => setVersionA(d)}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Select version B:"
                        name="versionB"
                        rules={[
                            {
                                required: true,
                                message: "Please select from the list...",
                                whitespace: true,
                            },
                        ]}
                        initialValue={latestVersionAsOption.value}
                    >
                        <Select
                            disabled={!versionA}
                            showSearch
                            optionFilterProp="label"
                            filterOption={(input, option) => (option?.label ?? "").includes(input)}
                            options={[latestVersionAsOption, ...versionBOptions]}
                        />
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
});

export default CompareVersionsModal;
