import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Button } from "antd";
import { InfoCircleOutlined, LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";

import versionStore from "@app/state/store/report-document/version";

import "./version-navigator.scoped.scss";

function getDiffIds() {
    const elements = document.querySelectorAll("[data-diff-id]");
    const ids = Array.from(elements).map((element) => element.getAttribute("data-diff-id"));

    return ids;
}

function scrollToElement(id) {
    const focusedElement = document.querySelector(".focused[data-diff-id]");
    if (focusedElement) {
        focusedElement.classList.remove("focused");
    }

    const element = document.querySelector(`[data-diff-id="${id}"]`);
    if (element) {
        element.classList.add("focused");
        setTimeout(() => {
            element.scrollIntoView({ behavior: "smooth", block: "center" });
        }, 0);
    }
}

const VersionNavigator = observer(() => {
    const { viewMode, versionId, lhsId, rhsId, lastUpdated } = versionStore || {};
    const [ids, setIds] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const diffIds = getDiffIds();
        setIds(diffIds);
        if (diffIds.length > 0) {
            scrollToElement(diffIds[0]);
        }
    }, [viewMode, versionId, lhsId, rhsId, lastUpdated]);

    const onNextClick = () => {
        if (currentIndex < ids.length - 1) {
            setCurrentIndex(currentIndex + 1);
            scrollToElement(ids[currentIndex + 1]);
        }
    };

    const onPrevClick = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
            scrollToElement(ids[currentIndex - 1]);
        }
    };

    if (!ids.length) {
        return null;
    }

    return (
        <div className="version-navigator">
            <InfoCircleOutlined style={{ fontSize: "18px", marginRight: "10px" }} /> {ids.length}{" "}
            differences found{" "}
            <Button
                style={{ marginLeft: "10px" }}
                icon={<LeftCircleOutlined style={{ fontSize: "18px" }} />}
                onClick={onPrevClick}
                disabled={currentIndex === 0}
                shape="circle"
                type="link"
            />
            <Button
                icon={<RightCircleOutlined style={{ fontSize: "18px" }} />}
                onClick={onNextClick}
                disabled={currentIndex === ids.length - 1}
                shape="circle"
                type="link"
            />
        </div>
    );
});

export default VersionNavigator;
