import React from "react";

export default function ({ colour }) {
    const fillColour1 = colour || "#7284BD";
    const fillColour2 = colour || "#84C8D2";

    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17 9.50042L17.5 9.00042L17.9682 8.43792L19.687 6.56292C20.0932 6.09417 20.0932 5.37542 19.6245 4.90667L19.0932 4.37542C18.6245 3.90667 17.9057 3.90667 17.437 4.31292L8.90573 12.0004L8.99948 11.9159L6.99948 13.7504L4.53073 15.9692C4.18698 16.2504 3.99948 16.6879 3.99948 17.1567C3.96823 17.5942 4.15573 18.0317 4.46823 18.3442L5.65573 19.5317C5.93698 19.8442 6.37448 20.0004 6.81198 20.0004C7.49948 20.0004 7.93698 19.5942 8.03073 19.4692L16.562 10.0004L17 9.50042ZM6.93698 18.4692C6.90573 18.5004 6.78073 18.5629 6.71823 18.4692L5.53073 17.3129C5.43698 17.1879 5.49948 17.0942 5.53073 17.0629L18.2182 5.62542L18.3745 5.78167L6.93698 18.4692Z"
                fill={fillColour1}
            />
            <path
                d="M3.67304 12.8026L4.86678 10.2965L7.34609 9.01974L4.86678 7.7903L3.67304 5.23684L2.43339 7.7903L0 9.01974L2.43339 10.2965L3.67304 12.8026ZM18.327 14.4342L17.1332 16.9877L14.6539 18.2171L17.1332 19.4938L18.327 22L19.5666 19.4938L22 18.2171L19.5666 16.9877L18.327 14.4342ZM10.5078 5.53947L11.2424 4.02632L12.7117 3.26974L11.2424 2.51316L10.5078 1L9.77322 2.51316L8.304 3.26974L9.77322 4.02632L10.5078 5.53947Z"
                fill={fillColour2}
            />
        </svg>
    );
}
