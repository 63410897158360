import { observable } from "mobx";
import Model from "@app/state/model/base";

export default class History extends Model {
    @observable _id = undefined;
    @observable date = undefined;
    @observable query = undefined;
    @observable source = undefined;
    @observable type = undefined;
    @observable results = 0;
    @observable created = undefined;
    @observable minPublicationYear = undefined;
    @observable maxPublicationYear = undefined;
    @observable minPublicationDate = undefined;
    @observable maxPublicationDate = undefined;
    @observable excludeBooks = undefined;
    @observable humanOnly = undefined;
    @observable expandSynonyms = undefined;
    @observable maxResults = undefined;
    @observable publicationTypes = [];
}
