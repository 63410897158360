import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Page } from "@app/components/page";
import Filter from "@app/components/filter";
import EmptyList from "@app/components/list/empty";
import ActivityFilter from "@app/components/activity/filter";
import List from "@app/components/activity/list";
import useHistoryFilters from "@app/components/hooks/useHistoryFilters";
import { useParams } from "react-router-dom";
import activity from "@app/state/store/activity";
import { ActivityLogView, Activities } from "@app/constants";

const ActivityLog = observer(() => {
    const { project } = useParams();

    useEffect(() => {
        activity.load({ id: project, view: ActivityLogView.Main, context: Activities.PROJECT });
    }, []);

    useHistoryFilters(activity.filter);

    return (
        <Page className="activity-logs">
            <Page.Header title="Activity Logs" />
            <Filter.Header filter={activity.filter} />

            <Page.Layout>
                <Page.Body>
                    <EmptyList
                        title="No Activities Found"
                        show={activity.list.length === 0 && !activity.loading}
                    />

                    <List activities={activity.list} context={Activities.PROJECT} view="main" />
                </Page.Body>
                <ActivityFilter filter={activity.filter} />
            </Page.Layout>

            <Page.Footer>
                <Filter.Pager filter={activity.filter} />
            </Page.Footer>
        </Page>
    );
});

export default ActivityLog;
