import { Mark } from "@tiptap/core";

import { ReportDocumentSection } from "@app/constants";

import "./style.scss";

import HelpTextPlugin from "./help-text-plugin";
import PreventHelpTextDeletionPlugin from "./prevent-help-text-deletion-plugin";

export const HelpTextMark = Mark.create({
    name: ReportDocumentSection.HELP_TEXT.MARK_NAME,
    inline: true,
    inclusive: false,
    inclusiveRight: false,

    addAttributes() {
        return {
            class: {
                default: ReportDocumentSection.HELP_TEXT.MARK_NAME,
            },
        };
    },

    addStorage() {
        return {
            store: {
                isVisible: true,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: "span",
                getAttrs: (element) => {
                    const hasHelpTextClass = element.classList.contains(
                        ReportDocumentSection.HELP_TEXT.MARK_NAME,
                    );
                    return hasHelpTextClass ? {} : false;
                },
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ["span", HTMLAttributes, 0];
    },

    addCommands() {
        return {
            toggleHelpTextVisibility: () => ({ editor }) => {
                const { isVisible } = editor.storage[
                    ReportDocumentSection.HELP_TEXT.MARK_NAME
                ].store;
                editor.storage[
                    ReportDocumentSection.HELP_TEXT.MARK_NAME
                ].store.isVisible = !isVisible;

                editor.emit(
                    "toggleHelpTextVisibility",
                    editor.storage[ReportDocumentSection.HELP_TEXT.MARK_NAME].store.isVisible,
                );
            },
            setHelpTextVisibility: (visible) => ({ editor }) => {
                editor.storage[ReportDocumentSection.HELP_TEXT.MARK_NAME].store.isVisible = visible;

                editor.emit("toggleHelpTextVisibility", visible);
            },
        };
    },

    addProseMirrorPlugins() {
        return [HelpTextPlugin(), PreventHelpTextDeletionPlugin({ editor: this.editor })];
    },
});

export default HelpTextMark;
