import { observable, computed, action } from "mobx";
import _ from "lodash";
import { events } from "@app/lib/store";

import settings from "@app/state/store/settings";

const THROTTLE = 200;
const SCREEN_SIZE = 992;

/**
 * Handle the navigation state
 */
class State {
    @observable toggled = false;
    @observable isMobile = false;
    @observable links = [];
    @observable back = null;
    @observable openKeys = null;

    constructor() {
        let resize = _.throttle(this.resize.bind(this), THROTTLE, { leading: true });

        window.addEventListener("resize", resize);
        this.resize();

        // load the navigation state from the
        if (!this.isMobile) {
            this.toggled = settings.get("navigation.toggled", false);
        }
    }

    /**
     * Flag indicating the collapsed state of the navigation
     */
    @computed get collapsed() {
        return this.toggled && !this.isMobile;
    }

    /**
     * Handle resize events
     */
    resize() {
        this.isMobile = window.innerWidth <= SCREEN_SIZE;
    }

    /**
     * Toggle the navigation state
     */
    toggle(value) {
        this.toggled = value !== undefined ? value : !this.toggled;

        // persist the state if a desktop user
        if (!this.isMobile) {
            settings.set("navigation.toggled", this.toggled);
        }
        events.emit("navigation.toggle", this.toggled);
    }

    /**
     * Handle when the menu item is clicked. Hide the menu
     * if we are in mobile mode
     */
    onClick() {
        if (this.isMobile) {
            this.toggle();
        }
    }

    @action
    onOpenChange(openKeys) {
        this.openKeys = [...openKeys];
    }
}

const state = new State();
export default state;
