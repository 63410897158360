import React from "react";
import { observer, useLocalStore } from "mobx-react-lite";
import { DownOutlined, UpOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";
import classNames from "classnames";
import UserChip from "@app/components/user/chip";
import session from "@app/state/store/session";

import "./style/group.scoped.scss";

const Group = observer(({ title, role, users, onAdd, onRemove }) => {
    const state = useLocalStore(() => ({
        opened: true,

        toggle() {
            this.opened = !this.opened;
        },
    }));

    /**
     * Handle the add button click event
     */
    const addUser = (event) => {
        event.preventDefault();
        event.stopPropagation();

        onAdd(role);
    };

    /**
     * Remove a user from the project
     */
    const removeUser = (id) => {
        onRemove(id);
    };

    const count = users.length;
    const hasAny = count > 0;
    const classes = classNames("group", {
        expanded: state.opened,
    });

    return (
        <div className={classes}>
            <div className="header" onClick={() => state.toggle()}>
                <div className="toggle">{state.opened ? <UpOutlined /> : <DownOutlined />}</div>
                <div className="role">
                    {title}
                    <em className={classNames({ any: hasAny })}>({count})</em>
                </div>

                <div className="add">
                    <Button icon={<PlusOutlined />} onClick={addUser} type="icon" />
                </div>
            </div>
            <div className="entries">
                {users.map((user) => {
                    return (
                        <div className="user" key={user._id}>
                            <UserChip user={user} />

                            <div className="buttons">
                                <Button
                                    onClick={() => removeUser(user._id)}
                                    type="text"
                                    danger
                                    icon={<DeleteOutlined />}
                                    disabled={user._id === session?.user?._id}
                                ></Button>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
});

export default Group;
