import { observable } from "mobx";
import Model from "@app/state/model/base";

export default class Version extends Model {
    @observable _id = undefined;
    @observable title = undefined;
    @observable documentId = undefined;
    @observable sectionIds = [];
    @observable v = undefined;
    @observable createdBy = {
        _id: undefined,
        fullName: undefined,
        avatar: undefined,
    };
    @observable updatedAt = undefined;
}
