import React from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import Page from "@app/components/page/page";

import RiskToggle from "@app/components/setup/risk/toggle";
import GeneralConfig from "@app/components/setup/risk/general/index";

import setup from "@app/state/store/projects/setup";
import report from "@app/state/store/report";

const RiskAnalysis = observer(() => {
    const history = useHistory();
    const config = setup.config;
    const readonly = report.readOnly || setup.isComplete;

    if (!config) {
        return;
    }

    /**
     * Update the config value
     */
    const update = async (data) => {
        const updates = {
            risk: {
                ...config.risk,
                ...data,
            },
        };

        config.set(updates);
        await setup.updateConfig({
            ...config,
            ...updates,
        });
    };

    /**
     * Close the page
     */
    const close = async (args) => {
        history.push("../setup");
    };

    /**
     * Render the configuration page
     */
    return (
        <Page>
            <Page.Header title="Risk Analysis" closable onClose={close} />
            <Page.Body maxWidth={1000}>
                <RiskToggle
                    config={config.risk}
                    onChange={(data) => {
                        update(data);
                    }}
                    readonly={readonly}
                />

                {config.risk.enabled && (
                    <>
                        <GeneralConfig
                            config={config.risk}
                            onChange={(data) => {
                                update(data);
                            }}
                            readonly={readonly}
                        />
                    </>
                )}
            </Page.Body>
        </Page>
    );
});

export default RiskAnalysis;
