import React from "react";
import { observer } from "mobx-react";
import Filter from "@app/components/filter";
import Sidebar from "@app/components/filter/sidebar";
import format from "@app/lib/format";
import { Review, Search, SearchDatabases, SearchType, SearchCategory } from "@app/constants";

import "./style/filter.scoped.scss";

const ArticleFilter = observer(
    ({ stage = Review.L1, type = SearchType.SLR, filter, config, project, searches = [] }) => {
        config = config || {};
        project = project || {};
        let facets = filter.state.stats?.facets || {};
        let status = facets.status || {};
        let source = facets.source || {};
        let article = facets.article || {};
        let abstract = facets.abstract || {};
        let publicationSource = facets.publicationSource || {};
        let reason = facets.reason || {};
        let searchCategory = facets.searchCategory || {};
        let articleTags = facets.articleTags || {};
        let searchFacets = facets.search || {};
        let crossChannel = facets.crossChannel || {};
        let moved = facets.moved || {};
        let note = facets.note || {};
        const appraisalScoreRange_include = facets.appraisalScoreRange_include || {};
        const appraisalScoreRange_maybe = facets.appraisalScoreRange_maybe || {};
        const appraisalScoreRange_exclude = facets.appraisalScoreRange_exclude || {};

        let l2 = stage === Review.L2;
        const l2Config = config?.[type]?.l2;

        const databases = [];
        for (const db of SearchDatabases) {
            if (config.search?.[db]) {
                databases.push({
                    label: format.searchDb(db),
                    value: db,
                    count: source[db] ?? 0,
                });
            }
        }

        databases.push({
            label: format.searchDb(Search.OTHER),
            value: Search.OTHER,
            count: source[Search.OTHER] ?? 0,
        });

        const keywords = [];
        for (const keyword of config[type].keywords) {
            keywords.push({
                _id: keyword._id,
                label: keyword.label,
                value: keyword.label,
                positive: keyword.positive,
            });
        }

        // get the list of available tags
        const tags = config.articleTags || [];

        // get the list of exclusion reasons
        const typeConfig = type === SearchType.SOTA ? config.sota : config.slr;
        const stageConfig = l2 ? typeConfig?.l2 : typeConfig?.l1;
        const reasons = stageConfig?.excludeReasons ?? [];
        const sotaEnabled = config.sota.enabled;

        return (
            <Sidebar filter={filter}>
                <Filter.OptionGroup
                    filter={filter}
                    title="Status"
                    name="status"
                    radio
                    groupKey="article.status"
                >
                    <Filter.Option
                        label="Actions Pending"
                        value="pending"
                        count={status.pending ?? 0}
                    />
                    {l2 && (
                        <Filter.Option
                            label="In Progress"
                            value="inProgress"
                            count={status.inProgress ?? 0}
                        />
                    )}
                    <Filter.Option label="Included" value="pass" count={status.pass ?? 0} />
                    <Filter.Option label="Excluded" value="fail" count={status.fail ?? 0} />
                </Filter.OptionGroup>

                {!l2 && (
                    <Filter.OptionGroup
                        filter={filter}
                        title="Abstract"
                        name="abstract"
                        radio
                        groupKey="article.abstract"
                    >
                        <Filter.Option
                            label="Has Abstract"
                            value={true}
                            count={abstract.true ?? 0}
                        />
                        <Filter.Option
                            label="Missing Abstract"
                            value={false}
                            count={abstract.false ?? 0}
                        />
                    </Filter.OptionGroup>
                )}

                {l2 && (
                    <Filter.OptionGroup
                        filter={filter}
                        title="Article"
                        name="article"
                        radio
                        collapsed
                        groupKey="article.full_text"
                    >
                        <Filter.Option label="Has Article" value={true} count={article.true ?? 0} />
                        <Filter.Option
                            label="Missing Article"
                            value={false}
                            count={article.false ?? 0}
                        />
                    </Filter.OptionGroup>
                )}

                {databases.length > 1 && (
                    <Filter.OptionGroup
                        filter={filter}
                        title="Database"
                        name="source"
                        collapsed
                        groupKey="article.source"
                    >
                        {databases.map((entry) => {
                            return (
                                <Filter.Option
                                    key={entry.value}
                                    label={entry.label}
                                    value={entry.value}
                                    count={entry.count}
                                />
                            );
                        })}
                    </Filter.OptionGroup>
                )}

                {tags.length > 0 && (
                    <Filter.OptionGroup
                        filter={filter}
                        title="Tags"
                        name="articleTags"
                        collapsed
                        groupKey="article.tags"
                    >
                        {tags.map((tag) => {
                            return (
                                <Filter.Option
                                    key={tag._id}
                                    label={tag.label}
                                    value={tag.label}
                                    count={articleTags[tag.label] ?? 0}
                                />
                            );
                        })}
                    </Filter.OptionGroup>
                )}

                {reasons.length > 0 && (
                    <Filter.OptionGroup
                        filter={filter}
                        title="Exclusion Reason"
                        name="reason"
                        collapsed
                        groupKey="article.exclusions"
                    >
                        {reasons.map((item) => {
                            return (
                                <Filter.Option
                                    key={item._id}
                                    label={item.reason}
                                    value={item.reason}
                                    count={reason[item.reason] ?? 0}
                                />
                            );
                        })}
                    </Filter.OptionGroup>
                )}

                {searches.length > 0 && (
                    <Filter.OptionGroup
                        filter={filter}
                        title="Searches"
                        name="searchId"
                        collapsed
                        groupKey="article.search"
                    >
                        {searches.map((search) => {
                            return (
                                <Filter.Option
                                    key={search._id}
                                    value={search._id}
                                    label={search.name}
                                    count={searchFacets[search._id] ?? 0}
                                />
                            );
                        })}
                    </Filter.OptionGroup>
                )}

                {keywords.length > 0 && (
                    <Filter.OptionGroup
                        filter={filter}
                        title="Keywords"
                        name="keyword"
                        collapsed
                        groupKey="article.keywords"
                    >
                        {keywords.map((keyword) => {
                            return (
                                <Filter.Option key={keyword._id} value={keyword.value}>
                                    <div className="keyword">
                                        <div
                                            className={`color ${
                                                keyword.positive ? "positive" : "negative"
                                            }`}
                                        ></div>
                                        <label>{keyword.label}</label>
                                    </div>
                                </Filter.Option>
                            );
                        })}
                    </Filter.OptionGroup>
                )}

                <Filter.OptionGroup
                    filter={filter}
                    title="Publication Source"
                    name="publicationSource"
                    radio
                    collapsed
                    groupKey="article.source"
                >
                    <Filter.Option
                        label="Has Source"
                        value={true}
                        count={publicationSource.true ?? 0}
                    />
                    <Filter.Option
                        label="Missing Source"
                        value={false}
                        count={publicationSource.false ?? 0}
                    />
                </Filter.OptionGroup>

                <Filter.OptionGroup
                    filter={filter}
                    title="Search Category"
                    name="searchCategory"
                    radio
                    collapsed
                    groupKey="article.category"
                >
                    {Object.values(SearchCategory)?.map((category) => (
                        <Filter.Option
                            key={category}
                            label={category}
                            value={category}
                            count={searchCategory[category] ?? 0}
                        />
                    ))}
                </Filter.OptionGroup>

                <Filter.OptionGroup
                    filter={filter}
                    title="Comment Status"
                    name={"commentStatus"}
                    radio
                    collapsed
                    groupKey="article.comments"
                >
                    <Filter.Option label="Resolved" value="resolved" />
                    <Filter.Option label="Open" value="pending" />
                </Filter.OptionGroup>

                <Filter.OptionGroup
                    filter={filter}
                    title="Comments"
                    name="subscribed"
                    radio
                    collapsed
                    groupKey="article.subscription"
                >
                    <Filter.Option label="My Discussions" value={true} />
                </Filter.OptionGroup>

                <Filter.OptionGroup filter={filter} title="Note" name="note" radio collapsed>
                    <Filter.Option label="Has Note" value={true} count={note.true ?? 0} />
                    <Filter.Option label="Missing Note" value={false} count={note.false ?? 0} />
                </Filter.OptionGroup>

                {!l2 && sotaEnabled && (
                    <Filter.OptionGroup
                        filter={filter}
                        title="Cross Channel Articles"
                        name="crossChannel"
                        collapsed
                        groupKey="article.channel"
                    >
                        <Filter.Option
                            label="In DUE & SoTA"
                            value="dueAndSota"
                            count={crossChannel.true ?? 0}
                        />
                        <Filter.Option
                            label="Moved Articles"
                            value="moved"
                            count={moved.true ?? 0}
                        />
                    </Filter.OptionGroup>
                )}

                <Filter.DateRange
                    filter={filter}
                    title="Published"
                    startDateName="minPublicationDate"
                    endDateName="maxPublicationDate"
                    picker="month"
                    collapsed
                    groupKey="article.publishDate"
                />

                {l2Config?.enableScore && (
                    <Filter.OptionGroup
                        filter={filter}
                        title="Appraisal Score Range"
                        name="appraisalScoreRange"
                        collapsed
                        radio
                        groupKey="article.appraisalScoreRange"
                    >
                        {l2Config.criteria.include.min !== undefined &&
                            l2Config.criteria.include.max !== undefined && (
                                <Filter.Option
                                    label={`${l2Config.criteria.include.label} (${l2Config.criteria.include.min} - ${l2Config.criteria.include.max})`}
                                    value="include"
                                    count={appraisalScoreRange_include?.true ?? 0}
                                />
                            )}

                        {l2Config.criteria.maybe.enabled &&
                            l2Config.criteria.maybe.min !== undefined &&
                            l2Config.criteria.maybe.max !== undefined && (
                                <Filter.Option
                                    label={`${l2Config.criteria.maybe.label} (${l2Config.criteria.maybe.min} - ${l2Config.criteria.maybe.max})`}
                                    value="maybe"
                                    count={appraisalScoreRange_maybe?.true ?? 0}
                                />
                            )}

                        {l2Config.criteria.exclude.min !== undefined &&
                            l2Config.criteria.exclude.max !== undefined && (
                                <Filter.Option
                                    label={`${l2Config.criteria.exclude.label} (${l2Config.criteria.exclude.min} - ${l2Config.criteria.exclude.max})`}
                                    value="exclude"
                                    count={appraisalScoreRange_exclude?.true ?? 0}
                                />
                            )}
                    </Filter.OptionGroup>
                )}
            </Sidebar>
        );
    },
);

export default ArticleFilter;
