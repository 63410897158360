import moment from "moment";
import React from "react";
import { observer } from "mobx-react";
import { DatePicker } from "antd";
import Group from "./group";

import "./style/date.scoped.scss";

const DateFilter = observer(({ filter, name, title, disabled, collapsed, groupKey }) => {
    const date = filter.state.filter[name];

    /**
     * Update the date
     */
    const update = (updated, string) => {
        filter.filter(name, updated ? string : null);
    };

    return (
        <Group
            className="date"
            title={title}
            hasActiveFilters={date}
            collapsed={collapsed}
            groupKey={groupKey ?? name}
        >
            <div className="date-field">
                <DatePicker
                    value={date ? moment(date) : undefined}
                    onChange={update}
                    className="input"
                    disabled={disabled}
                />
            </div>
        </Group>
    );
});

export default DateFilter;
