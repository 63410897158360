import { computed, action } from "mobx";
import { events } from "@app/lib/store";
import http from "@app/lib/http";
import BaseStore from "./base";
import User from "@app/state/model/user";
import notify from "@app/components/notify";

/**
 * User profile global state.
 */
export class Profile extends BaseStore {
    /**
     * observable store data
     */
    observable() {
        return {
            loading: false,
            saving: false,
            data: null,
        };
    }

    @computed get busy() {
        return this.loading || this.saving;
    }

    constructor() {
        super();

        /**
         * Handle user change events and reload the session if the
         * changed user was ours
         */
        const onUserChange = (user) => {
            if (user._id === this.data?._id) {
                this.reload();
            }
        };

        // handle user events
        events.on("user.update", onUserChange);
        events.on("user.delete", onUserChange);
    }

    /**
     * Load the profile data from the backend
     */
    @action
    async load(options = {}) {
        if (this.loading) {
            return;
        }

        if (this.data && !options.force) {
            return;
        }

        this.loading = true;

        try {
            let { data } = await http.get("/profile");
            this.data = new User(data);
        } catch (ex) {} //eslint-disable-line

        this.loading = false;
    }

    /**
     * Load the profile data from the backend
     */
    @action
    async reload() {
        this.load({ force: true });
    }

    /**
     * Update the profile
     */
    @action
    async save(params) {
        if (!this.data) {
            return;
        }

        this.saving = true;
        try {
            let result = await http.post(`/profile`, params);

            this.data = new User(result.data);
            events.emit("user.update", this.data);

            return true;
        } catch (ex) {
            notify.error(
                ex.response?.data?.error || "An error occurred while trying to update the details",
            );
            return false;
        } finally {
            this.saving = false;
        }
    }

    /**
     * Update the password
     */
    @action
    async passwd(params) {
        if (!this.data) {
            return;
        }

        this.saving = true;
        const { data } = await http.put(`/profile/password`, params);
        this.saving = false;

        return !data.error;
    }
}

const profile = new Profile();
export default profile;
