import * as React from "react";

export const useRotation = (store) => {
    const [rotation, setRotation] = React.useState(store.get("rotation") || 0);

    const handleRotationChanged = (currentRotation) => setRotation(currentRotation);

    React.useEffect(() => {
        store.subscribe("rotation", handleRotationChanged);

        return () => {
            store.unsubscribe("rotation", handleRotationChanged);
        };
    }, []);

    return { rotation };
};
