import BaseStore from "../base";
import reportDocuments from "@app/state/store/report-document/report-documents";

export class TableStore extends BaseStore {
    /**
     * Get a unique list of table uuids that have been used within the report document
     */
    get tables() {
        return reportDocuments.tableSet;
    }
}

export default new TableStore();
