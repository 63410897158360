import React from "react";
import { observer } from "mobx-react";
import { MenuOutlined, MessageOutlined as QuestionCircleOutlined } from "@ant-design/icons";

import "./style/header.scoped.scss";
import logo from "@app/assets/logo-icon.svg";

import User from "./header/user";
import Project from "./header/project";
import { Button } from "antd";
import HelpIcon from "../../help/icon";
import navigation from "./navigation/state";
import Notification from "@app/components/notification";
import { Lock as ReportDocumentSectionLock } from "@app/components/report-document/section";

const Header = observer(() => {
    return (
        <div className="header">
            <div className="menu-button" onClick={() => navigation.toggle()}>
                <img src={logo} className="logo" />
                <MenuOutlined className="icon" />
            </div>

            <div className="left">
                <Project />
            </div>
            <div className="right">
                <HelpIcon />
                <a
                    href="https://celegence.atlassian.net/servicedesk/customer/portal/1"
                    target="_blank"
                    rel="noreferrer"
                >
                    <Button
                        type="icon"
                        className="support-button"
                        icon={<QuestionCircleOutlined />}
                    >
                        <div className="button-text">
                            <div className="line1">Need Support?</div>
                            <div className="line2">Send us a message</div>
                        </div>
                    </Button>
                </a>
                <div className="notifications">
                    <Notification />
                </div>
                <User />
            </div>

            <ReportDocumentSectionLock />
        </div>
    );
});

export default Header;
