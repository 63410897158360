import React, { useEffect } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Tabs } from "antd";
import {
    BookOutlined,
    ProfileOutlined,
    HistoryOutlined,
    FileSearchOutlined,
    InfoCircleOutlined,
} from "@ant-design/icons";
import Wand from "@app/assets/icons/wand";
import { Page } from "@app/components/page";
import { useSidebar } from "../../page/sidebar";

//import Comments from "./comments/index";
import Comments from "@app/components/comments/sidebarComments";
import Bookmarks from "./bookmarks";
import Versions from "./version/index";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import Activity from "@app/components/activity";
import { Activities, AI_SOURCE_TYPE } from "@app/constants";
import AIChat from "@app/components/ai-chat";
import { scrollToComment } from "@app/components/report-document/editor/utils";

import state from "@app/state/store/report/document/details";

import "./style/sidebar.scoped.scss";

const COMMENTS_TAB = "comments"; // changing this value will need adjustments to email notification's triggered when users are tagged in comments.
const BOOKMARKS_TAB = "bookmarks";
const VERSIONS_TAB = "versions";
const AI_CHAT_TAB = "AI chat";
const ACTIVITY_TAB = "activity";

const Sidebar = observer(
    ({
        width = 400,
        document,
        onRestore,
        readonly = false,
        enableAI = false,
        keepBookmarksOnUnmount,
        ...rest
    }) => {
        const location = useLocation();
        const history = useHistory();
        const params = qs.parse(location.search, { ignoreQueryPrefix: true });

        const activeTab =
            params?.comments === "true" // To open comments tab from the email notification link.
                ? COMMENTS_TAB
                : undefined;
        const activeComment = params?.comment;

        const [activeKey, setActiveKey] = React.useState(activeTab);
        const sidebar = useSidebar();
        const hasComments = state.comments.length > 0;
        const pdfFileId = document?.isPdf;

        const switchTab = (key) => {
            setActiveKey(key);
        };

        const onCommentFocus = (id) => {
            if (params.comment === id) {
                return;
            }

            const comment = state.filteredComments.find((comment) => comment._id === id);
            if (!comment) {
                return;
            }

            let link = location.pathname + `?comment=${comment._id}`;
            if (comment.areas?.length) {
                link += `&area=${JSON.stringify(comment.areas[0])}`;
            } else if (comment.excel) {
                link += `&excel=${JSON.stringify(comment.excel)}`;
            }

            state.focusedComment = activeComment;
            history.push(link);
        };

        useEffect(() => {
            if (activeComment && activeKey !== COMMENTS_TAB) {
                setActiveKey(COMMENTS_TAB);
            }

            state.focusedComment = activeComment;

            setTimeout(async () => {
                scrollToComment(activeComment);
            }, 500);
        }, [activeComment]);

        return (
            <Page.Sidebar
                className="document-sidebar"
                sidebar={sidebar}
                width={width}
                padding={false}
                collapsible={true}
                {...rest}
            >
                <Tabs
                    type="card"
                    activeKey={activeKey}
                    onTabClick={switchTab}
                    destroyInactiveTabPane
                >
                    <Tabs.TabPane tab={<BookOutlined className="tab-icon" />} key={BOOKMARKS_TAB}>
                        <Bookmarks
                            readonly={readonly}
                            keepBookmarksOnUnmount={keepBookmarksOnUnmount}
                        />
                    </Tabs.TabPane>

                    <Tabs.TabPane
                        tab={
                            <ProfileOutlined
                                className={classNames("tab-icon", { badge: hasComments })}
                            />
                        }
                        key={COMMENTS_TAB}
                    >
                        <Comments
                            state={state}
                            onCommentFocus={onCommentFocus}
                            noItemsContent={
                                <div className="empty">
                                    <div className="icon">
                                        <InfoCircleOutlined />
                                    </div>
                                    <div className="text">
                                        There are no comments available.
                                        <br />
                                        You can add a comment by selecting some text.
                                        <br />
                                        You can also tag and notify other users using @.
                                    </div>
                                </div>
                            }
                        />
                    </Tabs.TabPane>

                    <Tabs.TabPane tab={<HistoryOutlined className="tab-icon" />} key={VERSIONS_TAB}>
                        <Versions document={document} onRestore={onRestore} />
                    </Tabs.TabPane>

                    {enableAI && pdfFileId && (
                        <Tabs.TabPane tab={<Wand colour="#595959" />} key={AI_CHAT_TAB}>
                            <AIChat
                                source={{
                                    _id: document?._id,
                                    type: AI_SOURCE_TYPE.SOURCE_DOCUMENT,
                                    fileId: pdfFileId,
                                }}
                                enableDialogue={pdfFileId}
                            />
                        </Tabs.TabPane>
                    )}

                    <Tabs.TabPane
                        tab={<FileSearchOutlined className="tab-icon" />}
                        key={ACTIVITY_TAB}
                    >
                        <Activity id={document._id} context={Activities.Document} view="sidebar" />
                    </Tabs.TabPane>
                </Tabs>
            </Page.Sidebar>
        );
    },
);

export default Sidebar;
