import React from "react";

export default function () {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 36 36"
            className="icon"
        >
            <path
                fill="currentColor"
                d="m14.55 18l6.8 8.6a1.17 1.17 0 0 1-.92 1.9a1.17 1.17 0 0 1-.92-.44L13 19.91L6.6 28a1.17 1.17 0 0 1-.92.44a1.17 1.17 0 0 1-.92-1.9L11.55 18l-6.8-8.6a1.17 1.17 0 0 1 .92-1.9a1.17 1.17 0 0 1 .96.5l6.44 8.13L19.5 8a1.17 1.17 0 0 1 .92-.44a1.17 1.17 0 0 1 .92 1.9Z"
            />
            <path
                fill="currentColor"
                d="m23 31.8l4.49-3.8a9.9 9.9 0 0 0 1.88-2.05A3.44 3.44 0 0 0 30 24a2.35 2.35 0 0 0-.35-1.27a2.44 2.44 0 0 0-1-.84a2.9 2.9 0 0 0-1.26-.28a3.36 3.36 0 0 0-1.83.5a5.64 5.64 0 0 0-1.48 1.42l-1-.81a5.11 5.11 0 0 1 4.36-2.37a4.35 4.35 0 0 1 2 .45a3.43 3.43 0 0 1 2 3.18a4.45 4.45 0 0 1-.68 2.35a10.9 10.9 0 0 1-2.24 2.46l-3.24 2.81h6.22V33H23Z"
            />
            <path fill="none" d="M0 0h36v36H0z" />
        </svg>
    );
}
