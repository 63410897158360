import React from "react";
import { Tooltip, Button } from "antd";
import { SnippetsOutlined } from "@ant-design/icons";

const PasteConfig = (props) => {
    return (
        <Tooltip title={props.tooltip} mouseEnterDelay={1.5}>
            <Button type={"icon"} icon={<SnippetsOutlined />} onClick={props.onClick} />
        </Tooltip>
    );
};

export default PasteConfig;
