import React from "react";
import { EllipsisOutlined } from "@ant-design/icons";
import { Dropdown, Button } from "antd";

import ReasonsMenu from "./reasons-menu";
import "./version.scoped.scss";

const VersionMenu = ({ onCreateVersion }) => {
    return (
        <div className="version-menu">
            <Dropdown
                overlay={ReasonsMenu(onCreateVersion)}
                trigger="click"
                placement="bottomRight"
            >
                <Button type="text">
                    <EllipsisOutlined />
                </Button>
            </Dropdown>
        </div>
    );
};

export default VersionMenu;
