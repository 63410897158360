import React from "react";
import classes from "classnames";
import { Tag, Button } from "antd";
import { useParams } from "react-router-dom";

import { ReadOutlined, CloudUploadOutlined, CloudDownloadOutlined } from "@ant-design/icons";

import format from "@app/lib/format";
import report from "@app/state/store/report";

import "./style/popover.scoped.scss";

const OpenArticle = ({ citation }) => {
    const { type, _id } = citation;
    const { project } = useParams();

    let link;

    if (type === "document") {
        link = `/r/${project}/document/categories/all/${_id}`;
    } else if (["sota", "due"].includes(type)) {
        link = `/r/${project}/${type === "sota" ? "sota" : "slr"}/l2/${_id}`;
    }

    if (link) {
        return (
            <a href={link} target="_blank" rel="noreferrer">
                <Button type="outline" icon={<ReadOutlined />}>
                    Show details
                </Button>
            </a>
        );
    }

    return null;
};

const OpenPdf = ({ citation }) => {
    if (!citation.file) {
        return null;
    }

    return (
        <a href={format.file(citation.file)} target="_blank" rel="noreferrer">
            <Button type="outline" icon={<CloudDownloadOutlined />}>
                Open file
            </Button>
        </a>
    );
};

const HandleMissingPdf = ({ citation }) => {
    const { type, _id, file } = citation;
    const { project } = useParams();

    if (file) {
        return false;
    }

    if (type === "custom") {
        const link = `/r/${project}/citation?id=${_id}`;

        return (
            <a href={link} target="_blank" rel="noreferrer">
                <Button type="outline" icon={<CloudUploadOutlined />}>
                    Missing pdf. Upload here.
                </Button>
            </a>
        );
    }

    return (
        <Tag className="" color="red">
            Missing Full Text
        </Tag>
    );
};

export const CitationPopover = ({ citation, className }) => {
    const refStyle = report?.config?.reference?.style;
    const formatted = format.reference(citation, refStyle);

    return (
        <div className={classes("citation-popover", className)}>
            <div
                className="reference"
                dangerouslySetInnerHTML={{
                    __html: formatted,
                }}
            />
            <div className="tags">
                <HandleMissingPdf citation={citation} />
                <OpenPdf citation={citation} />
                <OpenArticle citation={citation} />
            </div>
        </div>
    );
};
