import { computed, action } from "mobx";
import http from "@app/lib/http";
import BaseStore from "./base";
import Project from "@app/state/model/project";
import ProjectConfig from "@app/state/model/project/config";
import { events } from "@app/lib/store";
import { ws } from "@app/lib/socket";
import { ProjectStatus } from "@app/constants";

/**
 * State management controlling the currently active report details
 */
export class Report extends BaseStore {
    /**
     * observable store data
     */
    observable() {
        return {
            id: null,
            project: null,
            config: null,
            loaded: false,
            loading: false,
            temp: null,
        };
    }
    constructor() {
        super();
        ws.on("project.config.changed", () => {
            this.loadConfig();
        });
    }

    @computed get busy() {
        return this.loading;
    }

    /**
     * Load the report details
     */
    @action
    async load(id) {
        // do not load the data twice
        if (this.id === id || this.loading) {
            return;
        }

        this.loading = true;
        this.id = id;

        await this.loadProject();
        await this.loadConfig();

        this.loaded = true;
        this.loading = false;

        // events.emit("project.load"); // Placeholder. For future usage
    }

    /**
     * Load the project
     */
    @action
    async loadProject() {
        if (!this.id) {
            return;
        }

        let response = await http.get(`/project/${this.id}`);

        const { data } = response;

        if (response.status === 204) {
            this.project = new Project({
                _id: this.id,
                deleted: true,
            });
            return;
        }

        this.project = null;
        if (data._id) {
            this.project = new Project(data);
        }
    }

    /**
     * Load the project configuration
     */
    @action
    async loadConfig() {
        if (!this.id) {
            return;
        }

        let { data } = await http.get(`/project/${this.id}/config`);

        if (data) {
            this.config = new ProjectConfig(data);
        }
    }

    /**
     * Unload the report details
     */
    @action
    async unload() {
        this.project = null;
        this.config = null;
        this.id = null;
        this.temp = null;
        this.loaded = false;

        events.emit("project.unload");
    }

    @computed get readOnly() {
        return this.project?.status === ProjectStatus.COMPLETE;
    }

    @action
    addToTemp(name, value) {
        if (!name) {
            return;
        }

        this.temp = {
            ...this.temp,
            [name]: value,
        };
    }
}

export default new Report();
