import React from "react";
import { Menu } from "antd";

const ReasonsMenu = (onCreateVersion) => (
    <Menu>
        <Menu.Item onClick={onCreateVersion}>Create a version</Menu.Item>
    </Menu>
);

export default ReasonsMenu;
