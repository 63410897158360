const MAX_RETRY = 3;

export async function scrollTo(selector, { position = "start", attempt = 0 } = {}) {
    const node = document.querySelector(selector);
    if (!node) {
        return;
    }

    // check if the node is already into view
    const inView = await isInScrollView(node);
    if (inView) {
        return;
    }

    // scroll and verify that we scrolled successfully
    node.scrollIntoView({ behavior: "smooth", block: position });

    if (attempt <= MAX_RETRY) {
        await onScrollStop(node);
        const inView = await isInScrollView(node);
        if (!inView) {
            return scrollTo(selector, { position, attempt: attempt + 1 });
        }
    }

    return;
}

async function isInScrollView(node) {
    return new Promise((resolve) => {
        const observer = new IntersectionObserver((entries, observerItself) => {
            observerItself.disconnect();
            resolve(entries[0].intersectionRatio === 1);
        });
        observer.observe(node);
    });
}

function getScrollParent(node) {
    if (node == null) {
        return null;
    }

    if (node.scrollHeight > node.clientHeight) {
        return node;
    } else {
        return getScrollParent(node.parentNode);
    }
}

async function onScrollStop(node) {
    const parent = getScrollParent(node);
    const timeout = 100;
    let timer;

    if (!parent) {
        return;
    }

    return new Promise((resolve) => {
        const handler = function () {
            clearTimeout(timer);
            timer = setTimeout(() => {
                parent.removeEventListener("scroll", handler);
                resolve();
            }, timeout);
        };

        parent.addEventListener("scroll", handler);
        handler();
    });
}

export default {
    scrollTo,
};
