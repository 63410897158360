import { Role } from "@app/constants";

const AdminRoles = [Role.ADMIN, Role.ACCOUNT_ADMIN];
const ManagingRoles = [Role.PROJECT_MANAGER, Role.ADMIN, Role.ACCOUNT_ADMIN];
const InternalRoles = [Role.USER, Role.PROJECT_MANAGER, Role.ADMIN, Role.ACCOUNT_ADMIN];

const Config = {
    backend: "/api",

    acl: {
        "system.admin": [Role.ADMIN],
        "system.manage": AdminRoles,
        "project.manage": ManagingRoles,
        "project.search": InternalRoles,
        "client.manage": ManagingRoles,
        "client.edit": ManagingRoles,
        "client.remove": AdminRoles,
        "client.filter": InternalRoles,
        "article.update": InternalRoles,
        "tplc.update": InternalRoles,
        "patients.update": InternalRoles,
        "sourceDocuments.review": InternalRoles,
        "section.revert": InternalRoles,
        "section.edit": InternalRoles,
        copilot: InternalRoles,
    },

    roles: [
        { label: "Celegence Admin", value: Role.ADMIN, viewOnly: true },
        { label: "Client", value: Role.CLIENT },
        { label: "Account Admin", value: Role.ACCOUNT_ADMIN },
        { label: "Project Manager", value: Role.PROJECT_MANAGER },
        { label: "User", value: Role.USER },
    ],

    projectRoles: [
        { label: "Project Manager", value: Role.PROJECT_MANAGER },
        { label: "Lead Author", value: Role.LEAD_AUTHOR },
        { label: "Secondary Author", value: Role.AUTHOR },
        { label: "Client Access", value: Role.CLIENT },
        { label: "Reviewer", value: Role.REVIEWER },
        // { label: "Subject Matter Expert", value: Role.EXPERT },
    ],

    storage: {
        namespace: "mdr",
    },

    colors: [
        { name: "default", color: "#fafafa" },
        { name: "yellow", color: "#fdffc6" },
        { name: "cyan", color: "#e6fffb" },
        { name: "purple", color: "#f9f0ff" },
        { name: "magenta", color: "#fff0f6" },
        { name: "blue", color: "#6d84c2" },
        { name: "green", color: "#219653" },
        { name: "orange", color: "#fa8c16" },
        { name: "red", color: "#ec7c7a" },
        { name: "grey", color: "#8c8c8c" },
    ],
    cookie: {
        maxAge: 60 * 60 * 1000, // 60 minutes
    },
};

export default Config;
