import moment from "moment";
import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Form, Select, DatePicker } from "antd";
import { MaudeProductCodes } from "@app/constants";

import Modal from "@app/components/modal";
import notify from "@app/components/notify";

const ReasonForm = observer(({ config, onSave, onCancel }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            startDate: config.startDate ? moment(config.startDate) : undefined,
            endDate: config.endDate ? moment(config.endDate) : undefined,
            productCodes: config.productCodes,
        });
    }, [form, config]);

    /**
     * Show the validation error
     */
    const error = () => {
        notify.error("Please fill all required fields to continue");
    };

    /**
     * Cancel the edit
     */
    const cancel = () => {
        onCancel && onCancel();
        form.resetFields();
    };

    /**
     * Save the config
     */
    const save = (data) => {
        onSave &&
            onSave({
                startDate: data.startDate.format("YYYY-MM-DD"),
                endDate: data.endDate.format("YYYY-MM-DD"),
                productCodes: data.productCodes,
            });
    };

    return (
        <Modal
            title="Edit report dates & product codes"
            visible={true}
            okText="Save"
            onOk={form.submit.bind(form)}
            onCancel={cancel}
            width={600}
        >
            <div className="form">
                <Form layout="vertical" form={form} onFinishFailed={error} onFinish={save}>
                    <Form.Item
                        label="Product Codes"
                        name="productCodes"
                        rules={[
                            {
                                type: "array",
                                required: true,
                                message: "Please pick product codes",
                            },
                        ]}
                    >
                        <Select mode="multiple" allowClear>
                            {MaudeProductCodes.map((code) => {
                                return (
                                    <Select.Option key={code} value={code}>
                                        {code}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>

                    <div className="row gap">
                        <div className="col">
                            <Form.Item
                                label="Start Date"
                                name="startDate"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please pick a start date",
                                    },
                                ]}
                            >
                                <DatePicker
                                    style={{ width: "100%" }}
                                    disabledDate={(date) =>
                                        date.isAfter(form.getFieldValue("endDate"))
                                    }
                                />
                            </Form.Item>
                        </div>
                        <div className="col">
                            <Form.Item
                                label="End Date"
                                name="endDate"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please pick an end date",
                                    },
                                ]}
                            >
                                <DatePicker
                                    style={{ width: "100%" }}
                                    disabledDate={(date) =>
                                        date.isBefore(form.getFieldValue("startDate"))
                                    }
                                />
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </div>
        </Modal>
    );
});

export default ReasonForm;
