import React from "react";
import classNames from "classnames";
import {
    ExclamationCircleOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    InfoCircleOutlined,
} from "@ant-design/icons";

import "./style/alert.scoped.scss";

/**
 * `Alert` is a functional component that displays an alert message with a corresponding icon based on the status provided.
 *
 * @function
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {React.ReactNode} [props.icon] - An optional icon to display alongside the alert. If not provided, the icon is determined by the status.
 * @param {string} props.description - The message or description to display in the alert.
 * @param {"error"|"success"|"warn"|"info"|string} props.status - The status of the alert which determines the icon and styling. Can be "error", "success", "warn", or other custom statuses.
 * @returns {React.ReactNode} The `Alert` component.
 */
export const Alert = ({ icon, description, status }) => {
    let iconComponent;

    switch (status) {
        case "error":
            iconComponent = <CloseCircleOutlined className="icon" />;
            break;
        case "success":
            iconComponent = <CheckCircleOutlined className="icon" />;
            break;
        case "warn":
            iconComponent = <ExclamationCircleOutlined className="icon" />;
            break;
        case "info":
            iconComponent = <InfoCircleOutlined className="icon" />;
            break;
        default:
            iconComponent = null;
            break;
    }

    return (
        <div
            className={classNames("alert", {
                error: status === "error",
                success: status === "success",
                warn: status === "warn",
            })}
        >
            {(iconComponent || icon) && <div className="icon">{icon || iconComponent}</div>}
            <div className="content">{description}</div>
        </div>
    );
};

export default Alert;
