import { Plugin, PluginKey } from "prosemirror-state";
import { DecorationSet } from "prosemirror-view";

const keepSelectionPlugin = () => {
    return new Plugin({
        key: new PluginKey("keepSelection"),

        state: {
            init() {
                return DecorationSet.empty;
            },
            apply(transaction, oldState) {
                if (transaction.docChanged || transaction.selectionSet) {
                    return DecorationSet.empty;
                }
                return oldState;
            },
        },

        props: {
            handleDOMEvents: {
                mousedown(view, event) {
                    const { target } = event;

                    if (
                        event.button === 2 &&
                        target &&
                        target.parentElement &&
                        target.parentElement.className === "selectedCell"
                    ) {
                        event.preventDefault();
                        return true;
                    }

                    return false;
                },
                contextmenu(view, event) {
                    const { state } = view;
                    const { selection } = state;

                    const pos = view.posAtCoords({ left: event.clientX, top: event.clientY });
                    if (!pos) {
                        return false;
                    }

                    const $pos = view.state.doc.resolve(pos.pos);
                    const node = $pos.nodeAfter;

                    if (node && node.type.spec.tableRole === "cell") {
                        event.preventDefault();

                        view.dispatch(view.state.tr.setSelection(selection));

                        return true;
                    }

                    return false;
                },
            },
        },

        view() {
            return {
                update(view, prevState) {
                    const { state } = view;

                    if (state.doc !== prevState.doc || state.selection !== prevState.selection) {
                        const decorations = DecorationSet.create(state.doc, []);
                        view.dispatch(view.state.tr.setMeta(this, { add: decorations }));
                    }
                },
            };
        },
    });
};

export default keepSelectionPlugin;
