import React, { useState, useEffect } from "react";
import { Button, Tooltip, Dropdown } from "antd";
import {
    LinkOutlined,
    BorderVerticleOutlined,
    BorderHorizontalOutlined,
    CaretDownOutlined,
    HighlightOutlined,
    ProfileOutlined,
} from "@ant-design/icons";

import Copy from "./icons/copy";
import Paste from "./icons/paste";
import Cut from "./icons/cut";
import Bold from "./icons/bold";
import Italic from "./icons/italic";
import Underline from "./icons/underline";
import Subscript from "./icons/subscript";
import Superscript from "./icons/superscript";
import BulletList from "./icons/bullet-list";
import NumberList from "./icons/number-list";
import Undo from "./icons/undo";
import Redo from "./icons/redo";
import Dictionary from "./icons/dictionary";
import Image from "./icons/image";
import Table from "./icons/table";
import TableMergeCells from "./icons/table-cell-merge";
import TableSplitCells from "./icons/table-cell-split";
import PageBreak from "./icons/page-break";
import Abbreviation from "./icons/abbreviation";
import Landscape from "./icons/landscape";
import TextLeft from "./icons/text-left";
import TextRight from "./icons/text-right";
import TextCenter from "./icons/text-center";
import TextJustify from "./icons/text-justify";
import VerticalAlignTop from "./icons/vertical-align-top";
import VerticalAlignMiddle from "./icons/vertical-align-center";
import VerticalAlignDown from "./icons/vertical-align-down";
import DocumentLink from "./icons/document-link";
import Reference from "./icons/reference";
import CrossReference from "./icons/cross-reference";
import Caption from "./icons/caption";
import HelpText from "./icons/help-text";
import SampleText from "./icons/sample-text";
import HelpTextVisibility from "./icons/help-text-visibility";
import SampleTextComplete from "./icons/sample-text-complete";

import SymbolsMenu from "@app/components/report-document/editor/modules/symbol-picker/symbol-menu";
import HighlightMenu from "@app/components/report-document/editor/modules/highlight/menu";

import { ReportDocumentSection } from "@app/constants";
import versionStore from "@app/state/store/report-document/version";
import documentStore from "@app/state/store/report-document/document";

import "./styles/toolbar.scoped.scss";
import { FORMATTING_TYPES, applyFormatting, validElements } from "./utils";

const Toolbar = ({ editor, templateMode, ...rest }) => {
    const readonly = !editor.options.editable;
    const [isHelpTextVisible, setIsHelpTextVisible] = useState(
        editor.storage[ReportDocumentSection.HELP_TEXT.MARK_NAME].store.isVisible,
    );

    useEffect(() => {
        if (editor) {
            editor.on("toggleHelpTextVisibility", setIsHelpTextVisible);

            return () => {
                editor.off("toggleHelpTextVisibility", setIsHelpTextVisible);
            };
        }
    }, [editor]);

    if (readonly || versionStore.versionId) {
        return null;
    }

    const metaKey = navigator.platform.indexOf("Mac") ? "Cmd" : "Ctrl";

    const AlignMenu = (
        <div className="popover horizontal">
            <Button
                icon={<TextLeft />}
                onClick={() => applyFormatting(editor, FORMATTING_TYPES.TEXT_ALIGN_LEFT)}
            />
            <Button
                icon={<TextRight />}
                onClick={() => applyFormatting(editor, FORMATTING_TYPES.TEXT_ALIGN_RIGHT)}
            />
            <Button
                icon={<TextCenter />}
                onClick={() => applyFormatting(editor, FORMATTING_TYPES.TEXT_ALIGN_CENTER)}
            />
            <Button
                icon={<TextJustify />}
                onClick={() => applyFormatting(editor, FORMATTING_TYPES.TEXT_ALIGN_JUSTIFY)}
            />
        </div>
    );

    const verticalAlignMenu = (
        <div className="popover horizontal">
            <Button
                icon={<VerticalAlignTop />}
                onClick={() =>
                    editor
                        .chain()
                        .focus()
                        .setVerticalAlign(FORMATTING_TYPES.VERTICAL_ALIGN_TOP)
                        .run()
                }
            />
            <Button
                icon={<VerticalAlignMiddle />}
                onClick={() =>
                    editor
                        .chain()
                        .focus()
                        .setVerticalAlign(FORMATTING_TYPES.VERTICAL_ALIGN_MIDDLE)
                        .run()
                }
            />
            <Button
                icon={<VerticalAlignDown />}
                onClick={() =>
                    editor
                        .chain()
                        .focus()
                        .setVerticalAlign(FORMATTING_TYPES.VERTICAL_ALIGN_BOTTOM)
                        .run()
                }
            />
        </div>
    );

    const SplitCellMenu = (
        <div className="popover horizontal">
            <Tooltip title="Split Cells Vertically" placement="top">
                <Button
                    icon={<BorderHorizontalOutlined />}
                    onClick={() => editor.chain().focus().splitCellCustom("vertical").run()}
                />
            </Tooltip>
            <Tooltip title="Split Cells Horizontally" placement="top">
                <Button
                    icon={<BorderVerticleOutlined />}
                    onClick={() => editor.chain().focus().splitCellCustom("horizontal").run()}
                />
            </Tooltip>
        </div>
    );

    const CurrentAlignment = () => {
        if (editor.isActive({ textAlign: "left" })) {
            return <TextLeft />;
        }

        if (editor.isActive({ textAlign: "right" })) {
            return <TextRight />;
        }

        if (editor.isActive({ textAlign: "center" })) {
            return <TextCenter />;
        }

        return <TextJustify />;
    };

    const CurrentVerticalAlignment = () => {
        const icons = {
            [FORMATTING_TYPES.VERTICAL_ALIGN_TOP]: <VerticalAlignTop />,
            [FORMATTING_TYPES.VERTICAL_ALIGN_MIDDLE]: <VerticalAlignMiddle />,
            [FORMATTING_TYPES.VERTICAL_ALIGN_BOTTOM]: <VerticalAlignDown />,
        };

        const currentActiveElementName = validElements.find((element) => editor.isActive(element));

        if (!currentActiveElementName) {
            return icons[FORMATTING_TYPES.VERTICAL_ALIGN_TOP];
        }

        const alignment = editor.getAttributes(currentActiveElementName).verticalAlign;

        return icons[alignment || FORMATTING_TYPES.VERTICAL_ALIGN_TOP];
    };

    const addReference = () => {
        let trigger = "@";
        editor.chain().focus().insertContent(trigger).run();
    };

    const addCrossReference = () => {
        let trigger = "#";
        editor.chain().focus().insertContent(trigger).run();
    };

    const addCustomObject = () => {
        editor.chain().focus().selectCustomObject().run();
    };

    return (
        <div className="toolbar" {...rest}>
            <div className="group">
                <Tooltip title={`Cut (${metaKey}+X)`} placement="bottom">
                    <Button icon={<Cut />} onClick={() => editor.chain().focus().cut().run()} />
                </Tooltip>
                <Tooltip title={`Copy (${metaKey}+C)`} placement="bottom">
                    <Button icon={<Copy />} onClick={() => editor.chain().focus().copy().run()} />
                </Tooltip>
                <Tooltip title={`Paste (${metaKey}+V)`} placement="bottom">
                    <Button icon={<Paste />} onClick={() => editor.commands.paste()} />
                </Tooltip>
            </div>
            <div className="group">
                <Tooltip title={`Undo (${metaKey}+Z)`} placement="bottom">
                    <Button
                        icon={<Undo />}
                        disabled={!editor.can().undo()}
                        onClick={() => editor.chain().focus().undo().run()}
                    />
                </Tooltip>
                <Tooltip title={`Redo (${metaKey}+Y)`} placement="bottom">
                    <Button
                        icon={<Redo />}
                        disabled={!editor.can().redo()}
                        onClick={() => editor.chain().focus().redo().run()}
                    />
                </Tooltip>
            </div>
            <div className="group">
                <Tooltip title={`Bold (${metaKey}+B)`} placement="bottom">
                    <Button
                        icon={<Bold />}
                        onClick={() => applyFormatting(editor, FORMATTING_TYPES.BOLD)}
                        className={editor.isActive("bold") ? "active" : ""}
                    />
                </Tooltip>
                <Tooltip title={`Italic (${metaKey}+I)`} placement="bottom">
                    <Button
                        icon={<Italic />}
                        onClick={() => applyFormatting(editor, FORMATTING_TYPES.ITALIC)}
                        className={editor.isActive("italic") ? "active" : ""}
                    />
                </Tooltip>
                <Tooltip title={`Underline (${metaKey}+U)`} placement="bottom">
                    <Button
                        icon={<Underline />}
                        onClick={() => applyFormatting(editor, FORMATTING_TYPES.UNDERLINE)}
                        className={editor.isActive("underline") ? "active" : ""}
                    />
                </Tooltip>
                <Tooltip title={`Highlight (${metaKey}+Shift+H)`} placement="top">
                    <Dropdown.Button
                        className="highlight"
                        icon={<CaretDownOutlined />}
                        overlay={<HighlightMenu editor={editor} />}
                        onClick={() =>
                            applyFormatting(editor, FORMATTING_TYPES.HIGHLIGHT, {
                                color: documentStore.highlight,
                            })
                        }
                    >
                        <div>
                            <HighlightOutlined />
                            <div
                                style={{
                                    backgroundColor: documentStore.highlight,
                                }}
                            ></div>
                        </div>
                    </Dropdown.Button>
                </Tooltip>
            </div>
            <div className="group">
                <Dropdown overlay={AlignMenu} trigger={["click"]}>
                    <Tooltip title="Text alignment" placement="top">
                        <Button icon={<CurrentAlignment />} className="dropdown" />
                    </Tooltip>
                </Dropdown>
                <Dropdown
                    overlay={verticalAlignMenu}
                    trigger={["click"]}
                    disabled={validElements.every((element) => !editor.isActive(element))}
                >
                    <Tooltip title="Vertical alignment" placement="top">
                        <Button
                            disabled={validElements.every((element) => !editor.isActive(element))}
                            icon={<CurrentVerticalAlignment />}
                            className="dropdown"
                        />
                    </Tooltip>
                </Dropdown>

                <Tooltip title="Bullets" placement="bottom">
                    <Button
                        icon={<BulletList />}
                        onClick={() => applyFormatting(editor, FORMATTING_TYPES.BULLET_LIST)}
                        className={editor.isActive("bulletList") ? "active" : ""}
                    />
                </Tooltip>
                <Tooltip title="Numbering" placement="bottom">
                    <Button
                        icon={<NumberList />}
                        onClick={() => applyFormatting(editor, FORMATTING_TYPES.ORDERED_LIST)}
                        className={editor.isActive("orderedList") ? "active" : ""}
                    />
                </Tooltip>
            </div>
            <div className="group">
                <Tooltip title="Subscript" placement="bottom">
                    <Button
                        icon={<Subscript />}
                        onClick={() => {
                            if (editor.isActive("superscript")) {
                                applyFormatting(editor, FORMATTING_TYPES.SUPERSCRIPT);
                                applyFormatting(editor, FORMATTING_TYPES.SUBSCRIPT);
                            } else {
                                applyFormatting(editor, FORMATTING_TYPES.SUBSCRIPT);
                            }
                        }}
                        className={editor.isActive("subscript") ? "active" : ""}
                    />
                </Tooltip>
                <Tooltip title="Superscript" placement="bottom">
                    <Button
                        icon={<Superscript />}
                        onClick={() => {
                            if (editor.isActive("subscript")) {
                                applyFormatting(editor, FORMATTING_TYPES.SUBSCRIPT);
                                applyFormatting(editor, FORMATTING_TYPES.SUPERSCRIPT);
                            } else {
                                applyFormatting(editor, FORMATTING_TYPES.SUPERSCRIPT);
                            }
                        }}
                        className={editor.isActive("superscript") ? "active" : ""}
                    />
                </Tooltip>
            </div>
            <div className="group">
                <Dropdown overlay={<SymbolsMenu editor={editor} />} trigger={["click"]}>
                    <Tooltip title="Symbol Picker" placement="top">
                        <Button className="dropdown">Ω</Button>
                    </Tooltip>
                </Dropdown>
            </div>
            <div className="group">
                <Tooltip title="Dictionary (Alt+/)" placement="bottom">
                    <Button
                        icon={<Dictionary />}
                        onClick={() => editor.can().showDictionaryPicker()}
                    />
                </Tooltip>
                <Tooltip title="Abbreviation" placement="bottom">
                    <Button
                        icon={<Abbreviation />}
                        onClick={() => editor.can().showAbbreviationPicker()}
                    />
                </Tooltip>
                {!templateMode && (
                    <Tooltip title="Reference (@)" placement="bottom">
                        <Button icon={<Reference />} onClick={() => addReference()} />
                    </Tooltip>
                )}
                <Tooltip title="Cross Reference (#)" placement="bottom">
                    <Button icon={<CrossReference />} onClick={() => addCrossReference()} />
                </Tooltip>
                {!templateMode && (
                    <Tooltip title="Source Document Link" placement="bottom">
                        <Button
                            icon={<DocumentLink />}
                            onClick={() => editor.chain().focus().toggleCaptisLink().run()}
                            className={
                                editor.isActive(ReportDocumentSection.CAPTIS_LINK.MARK_NAME)
                                    ? "active"
                                    : ""
                            }
                        />
                    </Tooltip>
                )}
                <Tooltip title="Custom Object" placement="bottom">
                    <Button
                        icon={<ProfileOutlined />}
                        onClick={() => addCustomObject()}
                        disabled={!editor.can().addCustomObject()}
                    />
                </Tooltip>
            </div>
            {/* template actions (when editing a template) */}
            {templateMode && (
                <div className="group">
                    <Tooltip title="Help Text" placement="bottom">
                        <Button
                            icon={<HelpText />}
                            onClick={() =>
                                editor.commands.toggleMark(
                                    ReportDocumentSection.HELP_TEXT.MARK_NAME,
                                )
                            }
                            className={
                                editor.isActive(ReportDocumentSection.HELP_TEXT.MARK_NAME)
                                    ? "active"
                                    : ""
                            }
                        />
                    </Tooltip>
                    <Tooltip title="Sample text" placement="bottom">
                        <Button
                            icon={<SampleText />}
                            onClick={() =>
                                editor.commands.toggleMark(
                                    ReportDocumentSection.SAMPLE_TEXT.MARK_NAME,
                                )
                            }
                            className={
                                editor.isActive(ReportDocumentSection.SAMPLE_TEXT.MARK_NAME)
                                    ? "active"
                                    : ""
                            }
                        />
                    </Tooltip>
                </div>
            )}

            {/* template actions (when editing a document) */}
            {!templateMode && (
                <div className="group">
                    <Tooltip title="Show/hide help text" placement="bottom">
                        <Button
                            icon={<HelpTextVisibility isHidden={!isHelpTextVisible} />}
                            onClick={() => editor.commands.toggleHelpTextVisibility()}
                            className={!isHelpTextVisible ? "active" : ""}
                        />
                    </Tooltip>
                    <Tooltip title="Sample text filled" placement="bottom">
                        <Button
                            icon={<SampleTextComplete />}
                            onClick={() =>
                                editor.commands.unsetMark(
                                    ReportDocumentSection.SAMPLE_TEXT.MARK_NAME,
                                )
                            }
                        />
                    </Tooltip>
                </div>
            )}

            <div className="group">
                <Tooltip title="External Link" placement="bottom">
                    <Button
                        icon={<LinkOutlined />}
                        onClick={() => editor.chain().focus().toggleExternalLink().run()}
                        className={editor.isActive("link") ? "active" : ""}
                    />
                </Tooltip>
                <Tooltip title="Image" placement="bottom">
                    <Button
                        icon={<Image />}
                        onClick={() => editor.can().showImagePicker()}
                        disabled={!editor.can().addImageEntry()}
                    />
                </Tooltip>
                <Tooltip title="Page Break" placement="bottom">
                    <Button
                        icon={<PageBreak />}
                        onClick={() => editor.chain().focus().addPageBreakEntry().run()}
                        disabled={!editor.can().addPageBreakEntry()}
                    />
                </Tooltip>
            </div>
            <div className="group">
                <Tooltip title="Table" placement="bottom">
                    <Button
                        icon={<Table />}
                        onClick={() => editor.chain().focus().showInsertTable()}
                        disabled={!editor.can().insertTable()}
                    />
                </Tooltip>
                <Tooltip title="Merge Cells" placement="bottom">
                    <Button
                        icon={<TableMergeCells />}
                        onClick={() => editor.chain().focus().mergeCellsDirectly().run()}
                        disabled={!editor.can().mergeCellsDirectly()}
                    />
                </Tooltip>
                <Dropdown overlay={SplitCellMenu} trigger={["click"]}>
                    <Tooltip title="Split Cells" placement="top">
                        <Button
                            icon={<TableSplitCells />}
                            className="dropdown"
                            disabled={!editor.can().splitCellCustom("horizontal")}
                        />
                    </Tooltip>
                </Dropdown>
            </div>
            <div className="group">
                <Tooltip title="Toggle landscape section" placement="bottom">
                    <Button
                        icon={<Landscape />}
                        className={editor.isActive("landscape") ? "active" : ""}
                        onClick={() => editor.chain().focus().toggleLandscape().run()}
                    />
                </Tooltip>
                <Tooltip title="Toggle caption" placement="bottom">
                    <Button
                        icon={<Caption />}
                        onClick={() => editor.chain().focus().editCaption().run()}
                        disabled={!editor.can().editCaption(false)}
                    />
                </Tooltip>
            </div>
        </div>
    );
};

export default Toolbar;
