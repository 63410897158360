import React from "react";
import { observer } from "mobx-react";
import { Select } from "antd";
import classNames from "classnames";

import report from "@app/state/store/report";
import "../style/tags.scoped.scss";

export const TagPicker = observer(({ tags, value, onChange, ...rest }) => {
    const options = tags ?? report.config?.contentTags;

    return (
        <div className={classNames("picker")} {...rest}>
            <Select
                mode="multiple"
                showArrow={true}
                placeholder="Please select tags"
                onChange={onChange}
                value={value}
            >
                {options.map((tag) => {
                    return (
                        <Select.Option key={tag.label} value={tag.label}>
                            <div className={`tag-option ${tag.color}`}>
                                <label>{tag.label}</label>
                            </div>
                        </Select.Option>
                    );
                })}
            </Select>
        </div>
    );
});

export default TagPicker;
