import { action, computed } from "mobx";
import BaseStore from "../../base";
import report from "../../report";
import http from "@app/lib/http";
import notify from "@app/components/notify";
import { v4 as uuid } from "uuid";
import Document from "@app/state/model/document";
import events from "@app/lib/store/events";
import Filter from "@app/components/filter/state";
import { CustomCategories } from "@app/constants";
import { ws } from "@app/lib/socket";

const STGR_LOAD = uuid();

export class DocumentStore extends BaseStore {
    constructor() {
        super();

        this.filter = new Filter({
            sort: {
                name: { label: "Name", order: 1 },
                created: { label: "Upload Date", order: 1 },
            },
            default: {
                sort: { created: 1 },
            },
        });
        this.filter.on("find", () => {
            this.load(this.category);
        });

        events.on("document.update", () => {
            if (this.category) {
                this.load(this.category);
            }
        });

        events.on("document.delete", () => {
            this.load(this.category);
        });

        events.on("project.unload", () => {
            this.resetFilters();
            this.reset();
        });

        ws.on("document.previewStatus", ({ documentId, status, preview }) => {
            const document = this.list.find(({ _id }) => _id === documentId);

            if (document) {
                document.previewStatus = status;
                document.preview = preview;
            }
        });
    }

    observable() {
        return {
            category: undefined,
            loading: false,
            list: [],
        };
    }

    @computed get project() {
        return report.id;
    }

    @action
    async load(categoryId) {
        try {
            this.category = categoryId;
            this.loading = true;

            let filter = this.filter.value();
            let url = `/project/${this.project}/documents`;

            let { data } = await http
                .get(url, { category: categoryId, ...filter })
                .stagger(STGR_LOAD);

            this.list = data.list.map((entry) => {
                return new Document(entry);
            });

            this.filter.stats(data.stats);
        } catch (ex) {
            notify.error(ex.response?.data?.error);
        } finally {
            this.loading = false;
        }
    }

    @action
    async save(file, category = undefined) {
        const payload = {};
        payload.file = file._id;
        payload.name = file.name;
        payload.fileName = file.name;
        payload.project = this.project;

        const splCategory = [
            CustomCategories.ALL,
            CustomCategories.UNCATEGORIZED,
            undefined,
        ].includes(category);

        payload.category = splCategory ? undefined : category;

        try {
            this.loading = true;

            const { data } = await http.post(`/project/${this.project}/document`, payload);
            events.emit("document.update", data);
        } catch (ex) {
            notify.error(ex.response?.data?.error);
        } finally {
            this.loading = false;
        }
    }

    @action
    async update(_id, payload) {
        payload.category = this.category;

        try {
            this.loading = true;

            await http.put(`/project/${this.project}/document/${_id}`, payload).stagger(STGR_LOAD);
            events.emit("document.update", this.category);
        } catch (ex) {
            notify.error(ex.response?.data?.error);
        } finally {
            this.loading = false;
        }
    }

    /**
     * Delete a document
     */
    @action
    async delete(id) {
        try {
            this.loading = true;

            await http.delete(`/project/${this.project}/document/${id}`).stagger(STGR_LOAD);
            events.emit("document.delete");
        } catch (ex) {
            notify.error(ex.response?.data?.error);
        } finally {
            this.loading = false;
        }
    }

    @action
    resetFilters() {
        this.filter = new Filter({
            sort: {
                name: { label: "Name", order: 1 },
                created: { label: "Upload Date", order: 1 },
            },
            default: {
                sort: { id: 1 },
            },
        });
        this.filter.on("find", () => {
            this.load(this.category);
        });
    }
}

export default new DocumentStore();
