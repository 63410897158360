import React from "react";
import { observer } from "mobx-react";
import { Menu, Dropdown } from "antd";
import { Link } from "react-router-dom";
import { LogoutOutlined, UserOutlined, DownOutlined } from "@ant-design/icons";
import Avatar from "@app/components/user/avatar";
import expiry from "@app/state/store/expiry";

import session from "@app/state/store/session";

import "./style/user.scoped.scss";
import OnlineStatus from "./online-status";
import SessionExpiredModal from "./session-expired-modal";

const User = observer(() => {
    const { hasExpired } = expiry;
    /**
     * Perform the logout action
     */
    const logOut = async () => {
        await session.logout();
    };

    const UserMenu = (
        <Menu>
            <Menu.Item icon={<UserOutlined />} key="profile">
                <Link to="/profile">Profile</Link>
            </Menu.Item>
            <Menu.Item onClick={logOut} icon={<LogoutOutlined />} key="logout">
                Log Out
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <Dropdown overlay={UserMenu} trigger="click">
                <div className="user">
                    <Avatar user={session.user} size={40} className="avatar" />
                    <OnlineStatus />

                    <div className="name">{session.user.firstName}</div>
                    <div className="more">
                        <DownOutlined />
                    </div>
                </div>
            </Dropdown>
            <SessionExpiredModal visible={hasExpired} />
        </>
    );
});

export default User;
