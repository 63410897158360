import React from "react";

export default function () {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon"
            width="20"
            height="20"
            viewBox="0 0 20 20"
        >
            <path
                fill="currentColor"
                d="M6.957 3.836a.5.5 0 0 0-.94-.013L3.293 11h-.02v.054l-1.24 3.269a.5.5 0 0 0 .935.354L3.984 12h4.754l.926 2.664a.5.5 0 1 0 .945-.328l-3.652-10.5ZM4.363 11l2.1-5.537L8.39 11H4.363ZM11.5 3.75a.5.5 0 0 0-.5.5V14.5a.5.5 0 0 0 .5.5h2.75a3.25 3.25 0 0 0 1.293-6.233a2.875 2.875 0 0 0-1.918-5.017H11.5Zm4 2.875c0 1.036-.84 1.875-1.875 1.875H12V4.75h1.625c1.036 0 1.875.84 1.875 1.875ZM14.25 14H12V9.5h2.25a2.25 2.25 0 0 1 0 4.5Z"
            />
        </svg>
    );
}
