import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import Picker from "@app/components/captis-link/picker";

const Content = observer(({ editor }) => {
    const [showPicker, setShowPicker] = useState(false);

    useEffect(() => {
        if (!editor) {
            return;
        }

        editor.on("showCaptisLinkPicker", onShowPicker);
        return () => {
            editor.off("showCaptisLinkPicker", onShowPicker);
        };
    }, [editor]);

    const onShowPicker = useCallback(() => {
        setShowPicker(true);
    });

    const closePicker = () => {
        setShowPicker(false);
    };

    const pick = (href, type) => {
        const link = editor.commands.extractSourceDocumentLink(href);
        editor
            .chain()
            .focus()
            .insertCaptisLink({ ...link, type })
            .run();
        closePicker();
    };

    return showPicker && <Picker editor={editor} onClose={closePicker} onPick={pick} />;
});

export default Content;
