import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { useParams } from "react-router-dom";

import Box from "@app/components/box/box";
import ImageUpload from "@app/components/form/upload/image";
import { Page } from "@app/components/page";
import notify from "@app/components/notify";

import client from "@app/state/store/clients/details";

import { Role } from "@app/constants";
import Group from "@app/components/client/group";
import UserPicker from "@app/components/user/picker";
import users from "@app/state/store/users/picker";
import session from "@app/state/store/session";

const Details = observer((props) => {
    const [form] = Form.useForm();
    const { id } = useParams();

    const [pick, setPick] = React.useState(false);
    const [exclude, setExclude] = React.useState([]);
    const [list, setList] = React.useState([]);

    /**
     * Save the client
     * @param {Object} data
     */
    const save = async (data) => {
        const users = list.map((user) => user._id);
        const success = await client.update({ ...data, name: data.name.trim(), owners: users });

        if (success) {
            close();
        }
    };

    /**
     * Close the page
     */
    const close = () => {
        props.history.push("/clients");
    };

    /**
     * Show the validation error
     */
    const validationError = () => {
        notify.error("Please fill all required fields to continue");
    };

    const addUsers = (data) => {
        setPick(false);

        setExclude([...exclude, ...data]);

        setList([...list, ...data]);
    };

    const removeUser = (id) => {
        const updatedList = list.filter((item) => item._id !== id);
        setExclude(exclude.filter((item) => item._id !== id));
        setList(updatedList);
    };

    const showPicker = () => {
        setPick(true);
    };

    const assignDefaultUsers = () => {
        const isPM = session.user.roles.includes("pm");
        if (isPM) {
            addUsers([session.user]);
        }
    };

    useEffect(() => {
        (async () => {
            if (id) {
                await client.load(id);

                setList(client.users);
                setExclude(client.users);
            } else {
                await client.create();
                assignDefaultUsers();
            }

            // update the form fields after the load is complete
            form.setFieldsValue(client.data);
        })();

        return function () {
            client.unload();
        };
    }, [id, form]);

    useEffect(() => {
        if (pick) {
            users.load(Role.PROJECT_MANAGER);
        }
    }, [pick]);

    // return empty page while loading
    if (client.data?._id !== id) {
        return null;
    }

    return (
        <Page className="edit">
            <Page.Header>
                <Page.Header.Left>
                    <Page.Title>{id ? "Edit Client" : "Add New Client"}</Page.Title>
                </Page.Header.Left>

                <Page.Header.Right>
                    <Button
                        type="icon"
                        loading={client.busy}
                        onClick={close}
                        icon={<CloseOutlined />}
                    ></Button>
                </Page.Header.Right>
            </Page.Header>

            <Page.Body maxWidth={500}>
                <Box className="content-box">
                    <Form
                        layout="vertical"
                        initialValues={client.data}
                        form={form}
                        onFinishFailed={validationError}
                        onFinish={save}
                    >
                        <Form.Item name="logo">
                            <ImageUpload
                                file={client.data?.avatar}
                                className="m-auto"
                                share="true"
                            />
                        </Form.Item>

                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter the client's name",
                                    whitespace: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Form>

                    <Group
                        title="Project Manager"
                        role={Role.PROJECT_MANAGER}
                        key={Role.PROJECT_MANAGER}
                        users={list}
                        onAdd={showPicker}
                        onRemove={(id) => removeUser(id, Role.PROJECT_MANAGER)}
                    />

                    <UserPicker
                        onClose={() => setPick(false)}
                        onPick={addUsers}
                        visible={pick}
                        role={Role.PROJECT_MANAGER}
                        exclude={exclude}
                        title="Add Users"
                        addButton="Add"
                    />
                </Box>
            </Page.Body>

            <Page.Footer>
                <Page.Footer.Left>
                    <div className="button-group">
                        <Button type="primary" loading={client.busy} onClick={() => form.submit()}>
                            Save
                        </Button>
                        <Button onClick={close}>Cancel</Button>
                    </div>
                </Page.Footer.Left>
            </Page.Footer>
        </Page>
    );
});

export default Details;
