import React from "react";
import { observer } from "mobx-react";
import format from "@app/lib/format";

import "./style/reference.scoped.scss";

const Reference = observer(({ data }) => {
    return (
        <div className="reference">
            {data.file && (
                <div>
                    <b>File name:</b> {data.fileName}
                </div>
            )}
            <div>
                <b>Title:</b> {data.title}
            </div>
            <div>
                <u>Publication Details</u>
            </div>
            <div>
                <b>Journam Name:</b> {data.journalName}
            </div>
            <div>
                <b>Journam Volume:</b> {data.journalVolume}
            </div>
            <div>
                <b>Journam Issue:</b> {data.journalIssue}
            </div>
            <div>
                <b>Publication Year:</b> {data.publicationYear}
            </div>
            <div>
                <b>Publication Month:</b> {format.parseMonth(data.publicationMonth)}
            </div>
            <div>
                <b>Page Numbers:</b> {data.pageNumbers}
            </div>
            {data.authors?.length > 0 && (
                <>
                    <div>
                        <u>Authors:</u>
                    </div>
                    <div>
                        {data.authors?.map(({ firstName, lastName }) => (
                            <div className="author">{`${firstName} ${lastName}`}</div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
});

export default Reference;
