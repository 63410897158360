export default class Storage {
    /**
     * Create a new storage object
     * @param {*} store
     */
    constructor(store, options = {}) {
        this.store = store;
        this.namespace = options.namespace || "vs";
    }

    /**
     * Save an entry in the storage
     * @param {string} key
     * @param {any} value
     */
    set(key, value) {
        let _key = `${this.namespace}:${key}`;
        this.store.set(_key, value);
    }

    /**
     * Load a value from the storage
     * @param {string} key
     * @param {any} fallback
     */
    get(key, fallback) {
        let _key = `${this.namespace}:${key}`;

        let value = this.store.get(_key);
        if (value === undefined && fallback !== undefined) {
            return fallback;
        }

        return value;
    }

    /**
     * Remove a value from the storage
     * @param {string} key
     */
    remove(key) {
        let _key = `${this.namespace}:${key}`;
        this.store.remove(_key);
    }

    /**
     * Remove all values from the storage
     */
    reset() {
        this.store.clearAll();
    }
}
