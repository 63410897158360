import _ from "lodash";
import React, { useEffect } from "react";
import { v4 as uuid } from "uuid";
import { observer, useLocalStore } from "mobx-react-lite";
import { Form, Input, Button, Select } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import classNames from "classnames";
import notify from "@app/components/notify/index";
import List from "@app/components/list/ordered";
import Modal from "@app/components/modal";
import format from "@app/lib/format";
import confirm from "@app/components/confirm/index";

import aiStore from "@app/state/store/ai";
import applicationStore from "@app/state/store/application";

import { ReactComponent as DragIcon } from "@app/assets/icons/drag-handle.svg";
import "./style/form.scoped.scss";

const AdditionalDataForm = observer(({ additionalData, existing, onSave, onCancel }) => {
    const [form] = Form.useForm();
    const state = useLocalStore(() => ({
        _id: undefined,
        title: null,
        name: null,
        type: "text",
        options: [],
        helpMessage: undefined,
        aiQuestionSelection: undefined,
        aiQuestion: undefined,
    }));

    const customAIOption = "Custom";

    useEffect(() => {
        if (additionalData) {
            state._id = additionalData._id;
            state.title = additionalData.title;
            state.name = additionalData.name;
            state.type = additionalData.type || "text";
            state.options = _.cloneDeep(additionalData.options).map((el) => {
                el.key = el._id || uuid();
                el.$error = {};

                return el;
            });
            state.helpMessage = additionalData.helpMessage;

            // Check if the question is custom
            if (additionalData.ai?.question) {
                if (aiStore.questions.includes(additionalData.ai.question)) {
                    state.aiQuestionSelection = additionalData.ai.question;
                } else {
                    if ("text" === additionalData.type) {
                        state.aiQuestionSelection = customAIOption;
                    } else {
                        state.aiQuestionSelection = additionalData.ai.question;
                    }
                }
            }

            state.aiQuestion = additionalData.ai?.question;
        } else {
            state._id = undefined;
            state.title = "";
            state.name = "";
            state.type = "text";
            state.options = [];
            state.helpMessage = undefined;
            state.aiQuestionSelection = undefined;
            state.aiQuestion = undefined;
            addOption();
        }

        form.setFieldsValue({
            title: state.title,
            type: state.type,
            helpMessage: state.helpMessage,
            aiQuestionSelection: state.aiQuestionSelection,
            aiQuestion: state.aiQuestion,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, additionalData, state]);

    const editMode = !!state._id;

    /**
     * Checks if the option is empty
     */
    const isEmpty = (option) => {
        let required = ["text", "value"];

        for (let prop of required) {
            if (option[prop]) {
                return false;
            }
        }

        return true;
    };

    /**
     * Show the validation error
     */
    const validationError = () => {
        notify.error("Please fill all required fields to continue");
    };

    /**
     * Add a new option to the list
     */
    const addOption = () => {
        state.options.push({
            key: uuid(),
            $error: {},
        });
    };

    /**
     * Remove an option from the list
     */
    const removeOption = async (option) => {
        let proceed = await confirm("Are you sure you want to remove the option?");
        if (proceed) {
            let idx = state.options.indexOf(option);
            state.options.splice(idx, 1);
        }
    };

    /**
     * Validate the options on save
     */
    const save = (data) => {
        const title = data.title?.trim();
        state.title = title;
        state.name = title;
        state.helpMessage = data.helpMessage?.trim();
        state.type = data.type;

        // validate additionalDatas
        let validationError = false;
        existing.map((entry) => {
            // do not compare with itself
            if (state._id === entry._id) {
                return;
            }

            if (entry.title.toLowerCase() === state.title.toLowerCase()) {
                notify.error("This title already exists. Please change it and try again");
                validationError = true;
            }
        });

        if (validationError) {
            return;
        }

        // validate the options
        let valid = true;
        let required = ["value"];

        // filter out all options without values
        const options = state.options.filter((option) => {
            return !isEmpty(option);
        });

        // validate the options
        options.forEach((option) => {
            option.$error = {};

            for (let prop of required) {
                let value = option[prop];

                if (!value) {
                    option.$error[prop] = true;
                    valid = false;
                } else if (typeof value === String && value.match(/^\s*$/)) {
                    option.$error[prop] = true;
                    valid = false;
                }
            }
        });

        // check for valid options
        if (!valid) {
            return notify.error(
                "Some options are missing required values. Please fill them or remove the option to continue",
            );
        }

        // should have at least two answers
        if (state.type === "select" && options.length < 2) {
            return notify.error("Please add at least two options");
        }

        onSave({
            _id: state._id,
            title: state.title,
            name: state.name,
            helpMessage: state.helpMessage,
            type: state.type,
            options: options.map(({ _id, value }) => ({ _id, value })),
            ai: { question: state.aiQuestion },
        });

        if (!additionalData) {
            state.title = "";
            state.name = "";
            state.helpMessage = "";
            state.options = [];

            addOption();
        }
    };

    /**
     * Reorder the options
     */
    const reorder = (action) => {
        let [item] = state.options.splice(action.from, 1);
        state.options.splice(action.to, 0, item);
    };

    /**
     * Update a field value
     */
    const setValue = (option, field, value) => {
        option[field] = value;
        option.$error[field] = false;

        let idx = state.options.indexOf(option);
        if (idx === state.options.length - 1) {
            addOption();
        }

        // extract the score from the grade
        if (field === "grade") {
            let match = value.match(/^.*?(\d+)$/);
            if (match) {
                setValue(option, "score", match[1]);
            }
        }
    };

    const setType = (value) => {
        form.setFieldsValue({ type: value });
        state.type = value;
        form.setFieldsValue({ aiQuestion: null });
        state.aiQuestion = null;
    };

    const setAIQuestionSelection = (value) => {
        state.aiQuestionSelection = value;
        form.setFieldsValue({ aiQuestionSelection: value });
        if (customAIOption !== value) {
            form.setFieldsValue({ aiQuestion: value });
            state.aiQuestion = value;
        } else {
            form.setFieldsValue({ aiQuestion: undefined });
            state.aiQuestion = undefined;
        }
    };

    /**
     * Cancel the edit
     */
    const cancel = () => {
        onCancel && onCancel();
        form.resetFields();
    };

    const title = state._id ? "Edit Data Extraction Field" : "Add Data Extraction Field";

    return (
        <Modal
            title={title}
            visible={true}
            okText="Save"
            onOk={form.submit.bind(form)}
            onCancel={cancel}
            width={600}
        >
            <div className="qform">
                <Form
                    layout="vertical"
                    form={form}
                    onFinishFailed={validationError}
                    onFinish={save}
                >
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: "Please enter the title",
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={
                            <div>
                                Help Message <span className="optional">{`(Optional)`}</span>
                            </div>
                        }
                        name="helpMessage"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item label="Field Type" name="type" className="additional-data-type">
                        {!editMode ? (
                            <Select value={state.type} onChange={(value) => setType(value)}>
                                <Select.Option value="text">Text</Select.Option>
                                <Select.Option value="select">Select</Select.Option>
                            </Select>
                        ) : (
                            <p>{format.properCase(state.type)}</p>
                        )}
                    </Form.Item>

                    {state.type === "select" && (
                        <>
                            {applicationStore?.settings?.ai?.enabled && (
                                <Form.Item
                                    label={
                                        <div>
                                            AI Question{" "}
                                            <span className="optional">{`(Optional)`}</span>
                                        </div>
                                    }
                                    name="aiQuestionSelection"
                                >
                                    <Input
                                        onChange={(e) => {
                                            state.aiQuestion = e.currentTarget.value;
                                        }}
                                    />
                                </Form.Item>
                            )}
                            <div className="options">
                                <div className="header">
                                    <div className="title">Options</div>
                                    <div className="buttons">
                                        <Button
                                            icon={<PlusOutlined />}
                                            type="icon"
                                            onClick={addOption}
                                        />
                                    </div>
                                </div>
                                <div className="body">
                                    <div className="row option labels">
                                        <div className="col handle"></div>
                                        <div className="col value">Value</div>
                                        <div className="col remove"></div>
                                    </div>
                                    <List onChange={reorder}>
                                        {state.options.map((entry, index) => {
                                            return (
                                                <List.Item
                                                    key={entry.key}
                                                    id={entry.key}
                                                    index={index}
                                                >
                                                    <div className="row option">
                                                        <div className="col handle">
                                                            <DragIcon />
                                                        </div>

                                                        <div
                                                            className={classNames("col value", {
                                                                invalid: entry.$error?.value,
                                                            })}
                                                        >
                                                            <Input
                                                                value={entry.value}
                                                                onChange={(e) =>
                                                                    setValue(
                                                                        entry,
                                                                        "value",
                                                                        e.target.value,
                                                                    )
                                                                }
                                                            />
                                                        </div>

                                                        <div className="col remove">
                                                            <Button
                                                                type="icon"
                                                                icon={<DeleteOutlined />}
                                                                onClick={() => removeOption(entry)}
                                                            />
                                                        </div>
                                                    </div>
                                                </List.Item>
                                            );
                                        })}
                                    </List>
                                </div>
                            </div>
                        </>
                    )}

                    {state.type === "text" && applicationStore?.settings?.ai?.enabled && (
                        <>
                            <Form.Item
                                label={
                                    <div>
                                        AI Question <span className="optional">{`(Optional)`}</span>
                                    </div>
                                }
                                name="aiQuestionSelection"
                            >
                                <Select
                                    value={state.aiQuestionSelection}
                                    onChange={(value) => {
                                        setAIQuestionSelection(value);
                                    }}
                                    allowClear
                                >
                                    {aiStore.questions?.map((question) => (
                                        <Select.Option value={question}>{question}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            {state.aiQuestionSelection === customAIOption && (
                                <Form.Item
                                    label={"Custom AI Question"}
                                    name="aiQuestion"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter your custom question",
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <Input
                                        onChange={(e) => {
                                            state.aiQuestion = e.currentTarget.value;
                                        }}
                                    />
                                </Form.Item>
                            )}
                        </>
                    )}
                </Form>
            </div>
        </Modal>
    );
});

export default AdditionalDataForm;
