import React from "react";
import { observer } from "mobx-react";
import cn from "classnames";

import documentStore from "@app/state/store/report-document/document";

const ReferencesList = ({ referencesToShow }) => {
    if (!referencesToShow.length) {
        return <div className="empty">No references used in document</div>;
    }

    return (
        <div>
            {referencesToShow.map((ref) => {
                return (
                    <div
                        key={ref.id}
                        className={cn("list-entry", { invalid: !ref.file })}
                        dangerouslySetInnerHTML={{
                            __html: ref.formatted,
                        }}
                    ></div>
                );
            })}
        </div>
    );
};

const References = observer(({ metadata }) => {
    const references = documentStore.refs;

    return (
        <div className="content-list">
            <ReferencesList referencesToShow={references} />
        </div>
    );
});

export default References;
