import React from "react";
import { observer } from "mobx-react-lite";
import { Tag } from "antd";
import classNames from "classnames";
import Card from "@app/components/card/card";
import Title from "../chunks/title";
import Authors from "../chunks/authors";
import MissingAbstract from "../chunks/missing-abstract";
import CommentsTag from "@app/components/comments/chunks/comments-tag";
import HBox from "@app/components/container/hbox/hbox";
import DropArea from "@app/components/form/upload/drop/area";
import { SearchType } from "@app/constants";
import ArticleTags from "../chunks/article-tags";
import Source from "../chunks/source";
import { MultiSelectCheckBox } from "@app/components/multi-select/multi-select";
import { useContext } from "@app/components/multi-select/context";

import Menu from "./menu";
import state from "@app/state/store/report/article";

import "./style/card.scoped.scss";

const L1Card = observer(
    ({
        article,
        type,
        refStyle,
        keywords = [],
        checkedBox,
        className,
        readonly = false,
        ...rest
    }) => {
        /**
         * Render the article's L1 status
         */

        const { context } = useContext();
        const { isChecked, toggle } = context;

        const Status = () => {
            const l1Complete =
                type === SearchType.SLR ? article.slrL1Complete : article.sotaL1Complete;
            const l1Passed = type === SearchType.SLR ? article.slrL1Passed : article.sotaL1Passed;

            if (!l1Complete) {
                return <Tag>Pending</Tag>;
            }

            if (l1Passed) {
                return <Tag color="green">Included</Tag>;
            }

            return <Tag color="red">Excluded</Tag>;
        };

        const reason = article.review?.l1?.fail?.reason;

        const onUpload = async (file) => {
            state.updateArticleById(article._id, { file: file._id });
        };

        return (
            <Card
                className={classNames("result", className)}
                scrollIntoView
                {...rest}
                selected={isChecked ? isChecked(article._id) : false}
                onClick={(e) => {
                    if (e.ctrlKey || e.metaKey) {
                        e.preventDefault();
                        toggle(article._id);
                    }

                    if (e.shiftKey) {
                        e.preventDefault();
                        toggle(article._id, { range: true });
                    }
                }}
            >
                <DropArea
                    onChange={onUpload}
                    disabled={readonly}
                    accept=".pdf,application/pdf"
                    className="small"
                >
                    {!readonly && <Menu article={article} onUpload={onUpload} className="menu" />}
                    {checkedBox && <MultiSelectCheckBox id={article._id} />}
                    <div className={`${checkedBox && "shiftRight"}`}>
                        <Title article={article} keywords={keywords} />
                        <Authors article={article} trim />
                    </div>
                    <Source article={article} refStyle={refStyle} trim />
                    {reason && <div className="reason">{reason}</div>}

                    <HBox className="mt-20">
                        <HBox.Left className="vtop">
                            <ArticleTags article={article} />
                            {article.totalComments > 0 && (
                                <CommentsTag count={article.totalComments} />
                            )}
                        </HBox.Left>
                        <HBox.Right shrink={true} className="vtop">
                            <MissingAbstract article={article} />
                            <Status />
                        </HBox.Right>
                    </HBox>
                </DropArea>
            </Card>
        );
    },
);

export default L1Card;
