import React from "react";
import { observer, useLocalStore } from "mobx-react";

import format from "@app/lib/format";
import Group from "../../group";
import Form from "./form";

const Tplc = observer(({ config, onChange, readonly = false }) => {
    config = config || {};

    const state = useLocalStore(() => ({
        edit: false,
    }));

    const save = (data) => {
        state.edit = false;
        onChange && onChange(data);
    };

    // determine if we have the edit enabled
    const onEdit = readonly
        ? undefined
        : () => {
              state.edit = true;
          };

    const isEmpty = !config.startDate || config.productCodes.length === 0;

    return (
        <>
            <Group title="Device Classification & Date Range" onEdit={onEdit}>
                {isEmpty && (
                    <Group.Empty warn={true}>
                        Please configure the risk analysis parameters.
                    </Group.Empty>
                )}

                {!isEmpty && (
                    <div className="form-data">
                        <div className="entry">
                            <label>Product Codes</label>
                            <div>{config.productCodes.join(", ")}</div>
                        </div>
                        <div className="entry">
                            <label>Date Range</label>
                            <div>
                                {format.date(config.startDate)} - {format.date(config.endDate)}
                            </div>
                        </div>
                    </div>
                )}
            </Group>

            {state.edit && (
                <Form
                    config={config}
                    onSave={save}
                    onCancel={() => {
                        state.edit = false;
                    }}
                />
            )}
        </>
    );
});

export default Tplc;
