import { computed, observable } from "mobx";
import { default as Model } from "@app/state/model/base";

export const CitationType = {
    CUSTOM: "custom",
    DOCUMENT: "document",
    DUE: "due",
    SOTA: "sota",
    PREVIOUS_DUE: "previous due",
    PREVIOUS_SOTA: "previous sota",
};

export class Citation extends Model {
    isCustom = true;

    @observable _id = undefined;
    @observable type = undefined;
    @observable title = undefined;
    @observable file = undefined;
    @observable authors = [];
    @observable publicationYear = undefined;
    @observable publicationMonth = undefined;
    @observable journalIssue = undefined;
    @observable abbrJournalName = undefined;
    @observable journalName = undefined;
    @observable journalVolume = undefined;
    @observable pageNumbers = undefined;

    /**
     * Return formatted value of the authors of the article.
     */
    @computed
    get team() {
        return this.authors.map(({ firstName, lastName }) => `${firstName} ${lastName}`).join(", ");
    }

    @computed
    get tagLabel() {
        if (this.type === CitationType.CUSTOM) {
            return "Custom";
        }

        if (this.type === CitationType.DOCUMENT) {
            return "Source Document";
        }

        if (this.type === CitationType.DUE) {
            return "DUE";
        }

        if (this.type === CitationType.SOTA) {
            return "SoTA";
        }

        if (this.type === CitationType.PREVIOUS_DUE) {
            return "Previous DUE";
        }

        if (this.type === CitationType.PREVIOUS_SOTA) {
            return "Previous SoTA";
        }

        return "";
    }
}
