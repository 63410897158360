import React, { useEffect } from "react";
import { observer, useLocalStore } from "mobx-react";
import { Button, Tooltip } from "antd";
import { PlusSquareOutlined, FileTextFilled, SnippetsOutlined } from "@ant-design/icons";

import List from "../../list/ordered";
import Group from "../group";
import Card from "./card";
import Form from "./form";

import "./style/report-documents.scoped.scss";

const ReportDocumentsGroup = observer(
    ({
        list = [],
        title,
        onChange,
        onCopy,
        onPaste,
        copied = false,
        clientTemplates,
        readonly = false,
        className,
    }) => {
        const createEditState = (list) => {
            const editState = {};
            list.forEach((item) => {
                editState[item._id] = false;
            });
            return editState;
        };

        const updateEditState = (id, editState) => {
            const updatedState = {};
            Object.entries(editState).forEach(([key, value]) => {
                if (id === key) {
                    updatedState[key] = !value;
                } else {
                    updatedState[key] = false;
                }
            });
            return updatedState;
        };

        const state = useLocalStore(() => ({
            create: false,
            edit: {},
        }));

        useEffect(() => {
            state.edit = createEditState(list);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [list]);

        /**
         * Handle the edit canceled event and hide the form
         */
        const onCancel = () => {
            state.create = false;
        };

        /**
         * Do the actual configuration update
         */
        const save = (type, entry) => {
            onChange && onChange(type, entry);
        };

        /**
         * handle onAdd of new report document config
         */
        const onAdd = readonly
            ? undefined
            : () => {
                  state.create = true;
              };

        const onAddButton = (
            <Button type="primary" icon={<PlusSquareOutlined />} onClick={onAdd}>
                Create New
            </Button>
        );
        const onPasteButton = copied ? (
            <Tooltip title="Paste Report">
                <Button icon={<SnippetsOutlined />} onClick={onPaste} className="paste-button">
                    Paste
                </Button>
            </Tooltip>
        ) : null;

        return (
            <>
                <Group
                    title={title}
                    className={`reportDocumentsGroup ${className}`}
                    onAddButton={!readonly && onAddButton}
                    onPasteButton={!readonly && onPasteButton}
                >
                    {list.length === 0 && (
                        <Group.Empty>
                            <FileTextFilled
                                style={{
                                    color: "#66BBC740",
                                    fontSize: "38px",
                                }}
                            />
                            <br />
                            It's empty here!
                            <br />
                            Create a new report document
                        </Group.Empty>
                    )}

                    <List>
                        {list.map((entry, index) => {
                            const id = entry._id || "new";

                            return (
                                <Card
                                    index={index}
                                    key={id}
                                    value={entry}
                                    onRemove={() => {
                                        save("delete", entry);
                                    }}
                                    onEdit={(fieldValue) => {
                                        save("update", { _id: entry._id, name: fieldValue });
                                    }}
                                    onCopy={onCopy}
                                    edit={state.edit[entry._id]}
                                    toggleEditView={() => {
                                        state.edit = updateEditState(entry._id, state.edit);
                                    }}
                                    readonly={readonly}
                                />
                            );
                        })}
                    </List>
                </Group>

                {state.create && (
                    <Form
                        onSave={(entry) => save("create", entry)}
                        onCancel={onCancel}
                        clientTemplates={clientTemplates}
                    />
                )}
            </>
        );
    },
);

export default ReportDocumentsGroup;
