import _ from "lodash";
import classNames from "classnames";
import React, { useState, useCallback, useEffect, useRef } from "react";
import { useLocalStore } from "mobx-react";
import format from "@app/lib/format";

import "./report-table.scoped.scss";

const createHeaders = (columns, layout) => {
    const createItem = (key, text) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return { key, text, ref: useRef() };
    };

    if (layout === "vertical") {
        return [createItem("vHeader", ""), createItem("vValue", "")];
    } else {
        return Object.values(columns).map((item) => createItem(item.key, item.title));
    }
};

export const ReportTable = ({
    rows,
    columns,
    config,
    resizeable,
    headersWidth,
    updateHeadersWidth,
}) => {
    const { width, layout, fontSize, type } = config;
    const [tableHeight, setTableHeight] = useState("auto");
    const [activeIndex, setActiveIndex] = useState(null);
    const tableElement = useRef(null);

    const colHeaders = createHeaders(columns, layout);
    const minHeaderWidth = 25;

    const state = useLocalStore(() => ({
        initialCursorX: null,
    }));

    const setDefaultHeaderWidth = () => {
        const columnsChanged =
            colHeaders.length !== Object.keys(headersWidth).length ||
            colHeaders.find((col) => !headersWidth?.[col.key]);

        if (!columnsChanged) {
            colHeaders.forEach((col) => {
                if (col.ref.current) {
                    const initialHeaderWidth = headersWidth?.[col.key];
                    if (initialHeaderWidth) {
                        col.ref.current.style.width = initialHeaderWidth + "px";
                    }
                }
            });
        }
    };

    useEffect(() => {
        setTableHeight(tableElement.current?.offsetHeight);
        setDefaultHeaderWidth();
    }, []);

    const getRefWidth = (ref) => {
        return Math.round(parseFloat(getComputedStyle(ref).width));
    };

    const mouseDown = (e, index) => {
        setActiveIndex(index);
        state.initialCursorX = e.clientX;
    };

    const mouseMove = useCallback(
        (e) => {
            const currentCol = colHeaders[activeIndex].ref.current;
            const moveDistance = e.clientX - state.initialCursorX;

            const newWidth = getRefWidth(currentCol) + moveDistance;

            if (newWidth > minHeaderWidth) {
                const nextCol = colHeaders[activeIndex + 1].ref.current;
                const nextColNewWidth = getRefWidth(nextCol) - moveDistance;
                if (nextColNewWidth > minHeaderWidth) {
                    requestAnimationFrame(() => {
                        currentCol.style.width = newWidth + "px";
                        nextCol.style.width = nextColNewWidth + "px";
                    });
                }
            }

            state.initialCursorX = e.clientX;
            setTableHeight(tableElement.current.offsetHeight);
        },
        [activeIndex, colHeaders, minHeaderWidth],
    );

    const removeListeners = useCallback(() => {
        window.removeEventListener("mousemove", mouseMove);
        window.removeEventListener("mouseup", mouseUp);
    }, [mouseMove]);

    const saveHeadersWidth = _.debounce(() => {
        const headersWidth = {};
        colHeaders.forEach((col) => {
            headersWidth[col.key] = getRefWidth(col.ref.current);
        });
        updateHeadersWidth(headersWidth);
    }, 100);

    const mouseUp = useCallback(() => {
        saveHeadersWidth();

        setActiveIndex(null);
        removeListeners();
    }, [setActiveIndex, removeListeners]);

    useEffect(() => {
        if (activeIndex !== null) {
            window.addEventListener("mousemove", mouseMove);
            window.addEventListener("mouseup", mouseUp);
        }

        return () => {
            removeListeners();
        };
    }, [activeIndex, mouseMove, mouseUp, removeListeners]);

    if (config.type === "appendix") {
        return (
            <GroupTable
                rows={rows}
                columns={columns}
                config={config}
                resizeable={resizeable}
                headersWidth={headersWidth}
                updateHeadersWidth={updateHeadersWidth}
            />
        );
    }

    const constructHorizontalTableData = () => {
        return [
            ...rows.map((row) => {
                return Object.values(columns).map((column) => {
                    let className = column.key;
                    if (row.$totals) {
                        className += " totals";
                    }

                    return {
                        key: `${row._id}-${column.key}`,
                        className,
                        value: row[column.key],
                        isHTML: !!column.isHTML,
                    };
                });
            }),
        ];
    };

    const constructVerticalTableData = () => {
        const result = [];

        rows.map((row) => {
            const values = {};
            Object.entries(row).map(([key, cell]) => (values[key] = cell));

            const entries = Object.values(columns).map((column) => {
                const key = column.key;
                return [
                    {
                        key: `head-${key}-v-${row._id}`,
                        className: `th ${key}`,
                        value: column.title,
                    },
                    {
                        key: `${key}-v-${row._id}`,
                        className: key,
                        value: values[key],
                        isHTML: !!column.isHTML,
                    },
                ];
            });

            result.push(...entries);
            result.push({ $spacer: true });
        });

        return result;
    };

    const constructTableData = () => {
        if (layout === "horizontal") {
            return constructHorizontalTableData();
        } else if (layout === "vertical") {
            return constructVerticalTableData();
        }
        return [];
    };

    return (
        <table
            ref={tableElement}
            style={{ width, fontSize }}
            className={classNames("report-table", type)}
        >
            <thead>
                <tr>
                    {colHeaders.map(({ ref, key, text }, i) => (
                        <th ref={ref} key={key} className={`th ${key}`}>
                            {text}
                            {resizeable && i !== colHeaders.length - 1 && (
                                <div
                                    style={{ height: tableHeight }}
                                    onMouseDown={(e) => mouseDown(e, i)}
                                    className={`resize-handle ${
                                        activeIndex === i ? "active" : "idle"
                                    }`}
                                />
                            )}
                        </th>
                    ))}
                </tr>
            </thead>

            <tbody>
                {constructTableData().map((row, index) => {
                    if (row.$spacer) {
                        return (
                            <tr className="spacer" key={index}>
                                <td />
                            </tr>
                        );
                    }

                    return (
                        <tr key={index}>
                            {row.map((cell) => {
                                if (cell.isHTML) {
                                    return (
                                        <td
                                            key={cell.key}
                                            className={`htmlCell ${cell.className}`}
                                            dangerouslySetInnerHTML={{
                                                __html: format.sanitize(cell.value, {
                                                    p: ["class"],
                                                    ul: ["class"],
                                                    ol: ["class"],
                                                    li: ["class"],
                                                    br: [],
                                                }),
                                            }}
                                        />
                                    );
                                } else {
                                    return (
                                        <td key={cell.key} className={cell.className}>
                                            {cell.value}
                                        </td>
                                    );
                                }
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

const GroupTable = ({ rows, columns, config, resizeable, headersWidth, updateHeadersWidth }) => {
    return rows.map((group, idx) => (
        <div className="report-table-group" style={{ width: config?.width }} key={`group-${idx}`}>
            <div className="report-table-group-stats">
                <div>
                    <span>Search </span> <span>{group.meta.query}</span>
                </div>
                {group.meta.source && (
                    <div>
                        <span>Database </span> <span>{group.meta.source}</span>
                    </div>
                )}
                <div>
                    <span>Results </span> <span>{group.meta.results}</span>
                </div>
            </div>

            <ReportTable
                key={group.meta.name}
                rows={group.rows}
                columns={columns}
                config={{ ...config, type: "" }}
                resizeable={resizeable}
                headersWidth={headersWidth}
                updateHeadersWidth={updateHeadersWidth}
            />
        </div>
    ));
};
