import React from "react";
import { observer } from "mobx-react";
import Logo from "@app/components/client/logo";

import "./style/project.scoped.scss";

import report from "@app/state/store/report";

const Project = observer(() => {
    if (!report.project || report.project.deleted) {
        return;
    }

    return (
        <div className="project">
            <div className="row">
                <div className="logo">
                    <Logo client={report.project.client} size={30} />
                </div>
                <div className="details">
                    {/* <div className="label">Project</div> */}
                    <div className="name">{report.project.name}</div>
                    <div className="client">for {report.project.client.name}</div>
                </div>
            </div>
        </div>
    );
});

export default Project;
