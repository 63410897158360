import React, { useState } from "react";
import { observer } from "mobx-react";
import { Tabs } from "antd";
import { HistoryOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Page } from "@app/components/page";

import { SidebarTabKeys } from "@app/constants";

import { useSidebar } from "../../page/sidebar";

import versionStore from "@app/state/store/report-document/version";
import commentStore from "@app/state/store/report-document/comment";

import Versions from "@app/components/versions/sidebar";
import VersionAliasSidebar from "@app/components/versions/alias/sidebar";

import "./styles/sidebar.scoped.scss";

const ReportDocumentHistorySidebar = observer(({ handleFail, width = 400, readonly, ...rest }) => {
    const sidebar = useSidebar(null);
    const [activeKey, setActiveKey] = useState(SidebarTabKeys.HISTORY_TAB);

    const switchTab = (key) => {
        setActiveKey(key);
        commentStore.resetFocus();
    };

    return (
        <Page.Sidebar
            className="report-document-sidebar"
            sidebar={sidebar}
            width={width}
            padding={false}
            collapsible
            {...rest}
            style={{ paddingBottom: 0 }}
        >
            <Tabs
                type="card"
                activeKey={activeKey}
                defaultActiveKey={SidebarTabKeys.COMMENTS_TAB}
                onTabClick={switchTab}
                className="ant-tabs"
            >
                <Tabs.TabPane
                    tab={
                        <>
                            <HistoryOutlined className="tab-icon" /> History
                        </>
                    }
                    key={SidebarTabKeys.HISTORY_TAB}
                >
                    {activeKey === SidebarTabKeys.HISTORY_TAB && (
                        <Versions state={versionStore} readonly={readonly} />
                    )}
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={
                        <>
                            <UnorderedListOutlined className="tab-icon" /> Versions
                        </>
                    }
                    key={SidebarTabKeys.VERSIONS_TAB}
                >
                    {activeKey === SidebarTabKeys.VERSIONS_TAB && (
                        <VersionAliasSidebar readonly={readonly} />
                    )}
                </Tabs.TabPane>
            </Tabs>
        </Page.Sidebar>
    );
});

ReportDocumentHistorySidebar.displayName = "ReportDocumentHistorySidebar";

export default ReportDocumentHistorySidebar;
