import React from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import ReportWrapper from "./report-wrapper";
import EmptyList from "@app/components/list/empty";
import state from "@app/state/store/report/output/slr/articles";
import _ from "lodash";
import format from "@app/lib/format";
import { Box } from "@app/components/box";

import "../style/articles.scoped.scss";

const ReportPage = () => (
    <ReportWrapper
        name="Literature Search Results and Analysis"
        component={<Report />}
        maxWidth={1400}
    />
);

const Report = observer(() => {
    const { type } = useParams();

    React.useEffect(() => {
        state.load(type);
    }, [type]);

    const { list, loading } = state;
    const searches = _.groupBy(list, "database");

    return (
        <>
            <EmptyList title="No Records Found" show={list.length === 0 && !loading}></EmptyList>
            {Object.keys(searches).length !== 0 &&
                Object.keys(searches)?.map((db, index) => {
                    return (
                        <>
                            <table key={index}>
                                <colgroup>
                                    <col width="200" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>Search Term</th>
                                        <th>Results</th>
                                        <th>#</th>
                                        <th>Literature ID</th>
                                        <th>Database</th>
                                        <th>Author(s)</th>
                                        <th>Year</th>
                                        <th>Title</th>
                                        <th>Source</th>
                                        <th>Include Article? Yes/No</th>
                                        <th>Reason for Exclusion (If Applicable)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {searches[db].map((article, idx) => {
                                        return (
                                            <tr key={article._id}>
                                                {
                                                    article.firstInstance === true ? (
                                                        <>
                                                            <td rowSpan={article.rowSpan}>
                                                                <div className="report-query">
                                                                    {article.query}
                                                                </div>
                                                            </td>
                                                            <td rowSpan={article.rowSpan}>
                                                                {article.results}
                                                            </td>
                                                        </>
                                                    ) : null // Since there is a rowSpan already applied, render null
                                                }
                                                <td>{idx + 1}</td>
                                                <td>{format.cid(article.mdrId)}</td>
                                                <td>{format.searchDb(article.database)}</td>
                                                <td>{article.authors}</td>
                                                <td>{article.publicationYear}</td>
                                                <td>{article.title}</td>
                                                <td>{article.source}</td>
                                                <td>{article.included ? "Yes" : "No"}</td>
                                                <td>{article.reason}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <Box className="separator"></Box>
                        </>
                    );
                })}
        </>
    );
});

export default ReportPage;
