import React from "react";
import classNames from "classnames";
import { ReadOutlined } from "@ant-design/icons";
import format from "@app/lib/format";

import "./style/source.scoped.scss";

/**
 * Render the article source
 */
const Source = ({ article, refStyle, trim, ...rest }) => {
    const source = format.publicationSource(article, refStyle);

    return (
        <div className={classNames("source", { trim })} {...rest}>
            <div className="source-details">
                <ReadOutlined className="icon" />

                {source ? (
                    <span className="reference" dangerouslySetInnerHTML={{ __html: source }} />
                ) : (
                    <span className="warn">The source information is missing</span>
                )}
            </div>
        </div>
    );
};

export default Source;
