import React from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { Button, Tooltip } from "antd";
import { EyeOutlined, UndoOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import format from "@app/lib/format";
import { download } from "@app/lib/file";
import { Link, useLocation } from "react-router-dom";

import "./style/card.scoped.scss";
import confirm from "@app/components/confirm/index";
import Document from "@app/state/model/document";

const VersionCard = observer(({ history, className, onRestore, ...rest }) => {
    const { pathname } = useLocation();

    const created = format.date(history.created);
    const version = history.version;
    const canPreview = Document.canPreview(history);

    const restore = async () => {
        const proceed = await confirm(`Do you want to restore version ${version} from ${created}?`);
        if (proceed) {
            await onRestore(history);
        }
    };

    return (
        <div className={classNames("history", className)} {...rest}>
            <div className="meta">
                <div className="user">{history.author?.fullName}</div>
                <div className="date">{created}</div>

                <div className="icons">
                    {canPreview && (
                        <Tooltip title="Preview this version">
                            <Link to={`${pathname}/open?version=${history.version}`}>
                                <Button icon={<EyeOutlined />} type="icon" />
                            </Link>
                        </Tooltip>
                    )}

                    <Button
                        type="icon"
                        icon={<CloudDownloadOutlined />}
                        onClick={() => download(history.file?._id)}
                    />

                    <Tooltip title="Restore this version">
                        <Button icon={<UndoOutlined />} type="icon" onClick={restore} />
                    </Tooltip>
                </div>
            </div>
            <div className="version">Version: {history.version}</div>
        </div>
    );
});

export default VersionCard;
