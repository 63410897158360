import confirm from "@app/components/confirm";
import setup from "@app/state/store/projects/setup";

/**
 * Show the warning for the L2
 */
export default async function liveWarn(message, fn) {
    const live = setup.project?.status === "draft" ? false : true;

    if (live) {
        const proceed = await confirm(message);
        if (!proceed) {
            return;
        }
    }

    fn();
}

// standard error messages
liveWarn.review =
    "Changing this configuration will result in all complete L2 articles " +
    "getting reverted to in-progress. You will keep the original answers/values but the " +
    "review results should be re-validated. Are you sure you want to continue?";
