import { observable, computed } from "mobx";
import Model from "@app/state/model/base";
import format from "@app/lib/format";
import { ProjectStatus } from "@app/constants";

export default class Project extends Model {
    @observable _id = undefined;
    @observable name = undefined;
    @observable client = {
        _id: undefined,
        name: undefined,
        logo: undefined,
    };
    @observable description = undefined;
    @observable deviceNames = [];
    @observable manufacturerNames = [];
    @observable status = undefined;
    @observable plan = undefined;
    @observable endDate = undefined;
    @observable metaData = undefined;
    @observable deleted = undefined;

    @computed get statusLabel() {
        return this.status === ProjectStatus.HOLD ? "On Hold" : format.properCase(this.status);
    }
}
