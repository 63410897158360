import React from "react";
import { observer } from "mobx-react";
import format from "@app/lib/format";
import Box from "@app/components/box/box";

import profile from "@app/state/store/profile";

const General = observer(({ className, ...props }) => {
    let data = profile.data;

    return (
        <Box title="Profile Details" className={`general ${className}`} {...props}>
            <div className="data-entry">
                <label>Name</label>
                <span>{data.fullName}</span>
            </div>
            <div className="data-entry">
                <label>Email</label>
                <span>{data.email}</span>
            </div>
            <div className="data-entry">
                <label>Joined</label>
                <span>{format.date(data.created)}</span>
            </div>
        </Box>
    );
});

export default General;
