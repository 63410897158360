import React from "react";

export default function () {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            width="1em"
            height="1em"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 16 16"
        >
            <path
                fill="currentColor"
                d="M4.085 2H3.5A1.5 1.5 0 0 0 2 3.5v10A1.5 1.5 0 0 0 3.5 15h2.612v-1H3.5a.5.5
                0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h.585A1.5 1.5 0 0 0 5.5 4h3a1.5 1.5 0 0 0
                1.415-1h.585a.5.5 0 0 1 .5.5V5h1V3.5A1.5 1.5 0 0 0 10.5 2h-.585A1.5 1.5 0 0
                0 8.5 1h-3a1.5 1.5 0 0 0-1.415 1ZM5 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0
                1h-3a.5.5 0 0 1-.5-.5ZM8.5 6A1.5 1.5 0 0 0 7 7.5v6A1.5 1.5 0 0 0 8.5 15h4a1.5
                1.5 0 0 0 1.5-1.5v-6A1.5 1.5 0 0 0 12.5 6h-4ZM8 7.5a.5.5 0 0 1 .5-.5h4a.5.5 0
                0 1 .5.5v6a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-6Z"
            />
        </svg>
    );
}
