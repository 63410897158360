import React from "react";
import {
    EditOutlined,
    FileOutlined,
    DeploymentUnitOutlined,
    ReadOutlined,
} from "@ant-design/icons";

import { CitationType } from "@app/state/model/citation";

const CitationIcon = ({ citation }) => {
    if (citation.type === CitationType.CUSTOM) {
        return <EditOutlined />;
    }

    if (citation.type === CitationType.DOCUMENT) {
        return <FileOutlined />;
    }

    if (citation.type === CitationType.DUE || citation.type === CitationType.PREVIOUS_DUE) {
        return <ReadOutlined />;
    }

    if (citation.type === CitationType.SOTA || citation.type === CitationType.PREVIOUS_SOTA) {
        return <DeploymentUnitOutlined />;
    }

    return null;
};

export default CitationIcon;
