import React, { useEffect } from "react";
import { observer, useLocalStore } from "mobx-react";
import { Link, useHistory, useParams } from "react-router-dom";
import { CloseOutlined, PlusOutlined, SnippetsOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import classes from "classnames";

import { Page } from "@app/components/page";
import Box from "@app/components/box/box";
import Logo from "@app/components/client/logo";
import ConfigurationCard from "@app/components/client/configuration/card";
import ReportDocumentsConfig from "./report-documents/list";

import confirm from "@app/components/confirm";
import format from "@app/lib/format";

import "./style/client.scoped.scss";

import client from "@app/state/store/clients/details";
import HBox from "@app/components/container/hbox/hbox";

const View = observer((props) => {
    const { id, view } = useParams();
    const section = view || "general";

    // load the list when the component mounts
    useEffect(() => {
        client.load(id);
    }, [id]);

    // return empty page while loading
    if (client.data?._id !== id) {
        return null;
    }

    /**
     * Close the page
     */
    const close = () => {
        props.history.push("/clients");
    };

    /**
     * Return the correct section based on the parameters
     */
    const Section = (props) => {
        if (section === "config") {
            return <Configs {...props} />;
        } else if (section === "general") {
            return <General {...props} />;
        } else if (section === "report-documents") {
            return <ReportDocumentsConfig {...props} />;
        }
    };

    return (
        <Page className="client">
            <Page.Header>
                <Page.Header.Left>
                    <Page.Title>Client Details</Page.Title>
                </Page.Header.Left>
                <Page.Header.Right>
                    <Button
                        type="icon"
                        loading={client.busy}
                        onClick={close}
                        icon={<CloseOutlined />}
                    ></Button>
                </Page.Header.Right>
            </Page.Header>
            <Page.Body maxWidth={1000}>
                <div className="wrapper">
                    <Box className="navigation" padding={false}>
                        <Logo className="avatar" client={client.data} size={120} />
                        <div className="links">
                            <Link
                                to={`/clients/${client.data._id}/view/general`}
                                className={classes({ active: section === "general" })}
                            >
                                Profile
                            </Link>
                            <Link
                                to={`/clients/${client.data._id}/view/config`}
                                className={classes({ active: section === "config" })}
                            >
                                Configurations
                            </Link>
                            <Link
                                to={`/clients/${client.data._id}/view/report-documents`}
                                className={classes({ active: section === "report-documents" })}
                            >
                                Document Templates
                            </Link>
                        </div>
                    </Box>
                    <Section className="section" />
                </div>
            </Page.Body>
        </Page>
    );
});

const General = observer(({ className, ...props }) => {
    let data = client.data;

    return (
        <Box title="Client Details" className={`general ${className}`} {...props}>
            <div className="data-entry">
                <label>Name</label>
                <span>{data.name}</span>
            </div>
            <div className="data-entry">
                <label>Status</label>
                <span>{data.deleted === true ? "Deleted" : "Active"}</span>
            </div>
            <div className="data-entry">
                <label>Joined</label>
                <span>{format.date(data.created)}</span>
            </div>
        </Box>
    );
});

const Configs = observer(({ className, ...props }) => {
    const history = useHistory();
    const state = useLocalStore(() => ({ focus: null }));
    const configurations = client?.data?.configurations;
    const clientId = client?.data?._id;

    const focus = (id) => {
        state.focus = id;
    };

    const onEdit = (configuration) => {
        focus(configuration._id);
        history.push(`/clients/${clientId}/view/config/${configuration._id}/edit`);
    };

    const onRemove = async (configuration) => {
        let proceed = await confirm("Are you sure you want to remove this configuration?");
        if (proceed) {
            client.deleteConfiguration(configuration._id);
        }
    };

    const pasteConfig = async () => {
        await client.pasteConfig(clientId);
    };

    const copyConfig = (configId) => {
        client.copyConfig(configId);
    };

    return (
        <Box className={`config ${className}`} {...props}>
            <Box.Header>
                <HBox>
                    <HBox.Left>
                        <Box.Title>Configurations</Box.Title>
                    </HBox.Left>
                    <HBox.Right>
                        {client.isConfigCopied && (
                            <Tooltip title="Paste configuration">
                                <Button
                                    icon={<SnippetsOutlined />}
                                    onClick={pasteConfig}
                                    className="button"
                                >
                                    Paste
                                </Button>
                            </Tooltip>
                        )}
                        <Link to={`/clients/${clientId}/view/config/add`}>
                            <Button icon={<PlusOutlined />}>Add</Button>
                        </Link>
                    </HBox.Right>
                </HBox>
            </Box.Header>

            {configurations?.map((config) => {
                return (
                    <Link
                        key={config._id}
                        onClick={() => focus(config._id)}
                        to={`/clients/${clientId}/view/config/${config._id}/setup`}
                    >
                        <ConfigurationCard
                            onEdit={onEdit}
                            focus={config._id === state.focus}
                            onRemove={onRemove}
                            configuration={config}
                            onCopy={copyConfig}
                        />
                    </Link>
                );
            })}
        </Box>
    );
});

export default View;
