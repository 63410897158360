import Link from "@tiptap/extension-link";

const LinkNode = Link.extend({
    addCommands() {
        return {
            ...this.parent?.(),

            /**
             * Toggle a link
             */
            toggleExternalLink: () => async ({ editor, commands }) => {
                const { selection, doc } = editor.state;
                const text = doc.textBetween(selection.from, selection.to);

                if (text.trim() !== "") {
                    const previousUrl = editor.getAttributes("link").href;
                    editor.emit("linkModal", previousUrl);
                }
            },
        };
    },
});

export default LinkNode;
