import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";
import classNames from "classnames";

import { drawerState as container } from "./context";

import "./style/drawer.scoped.scss";

const Drawer = observer(
    ({
        title,
        header,
        visible,
        width = 300,
        padding = true,
        side = "right",
        footer,
        onClose,
        afterClose,
        className,
        children,
        ...rest
    }) => {
        const [state, setState] = useState(null);

        // Build the drawer header. It is visible only of the title
        // property is provided or if the closable property is set
        // to true
        const Header = observer(() => {
            return (
                <div className="header">
                    <div className="left">
                        {title && <div className="title">{title}</div>}
                        {header}
                    </div>
                    <div className="right">
                        <Button
                            icon={<CloseOutlined />}
                            type="icon"
                            className="close"
                            onClick={onClose}
                        />
                    </div>
                </div>
            );
        });

        // Render the drawer footer depending on the visibility state.
        // The content is rendered only if the drawer is visible and there
        // is a footer content
        const Footer = observer(() => {
            if (!footer) {
                return null;
            }

            return <div className="footer">{footer}</div>;
        });

        // Render the drawer content depending on the visibility state.
        // the drawer itself is always in the DOM but the content is rendered
        // only if the drawer is visible
        const Content = observer(() => {
            if (visible) {
                return (
                    <>
                        <Header />
                        <div className="body">{children}</div>
                        <Footer />
                    </>
                );
            }

            return null;
        });

        // render the drawer component
        const Drawer = () => {
            return (
                <div
                    className={classNames("drawer-group", className, {
                        visible: visible,
                        hidden: !visible,
                    })}
                >
                    <div className="backdrop" onClick={onClose}></div>
                    <div
                        className={classNames("drawer", side, { padding: padding })}
                        style={{ width: width }}
                    >
                        <Content />
                    </div>
                </div>
            );
        };

        // bump the z-index when showing
        useEffect(() => {
            // the drawer is visible but not added to the container so add it
            if (visible && !state) {
                const drawer = container.add(<Drawer />);
                setState(drawer);
            }

            // the drawer is hidden but not yet destroyed so remove it from container
            else if (!visible && state) {
                state.remove();
                setState(null);
            }
        }, [state, visible]);

        // update the drawer on every render
        useEffect(() => {
            if (state) {
                state.update(<Drawer />);
            }
        });

        // register the component cleanup function
        useEffect(() => {
            return function cleanup() {
                if (state) {
                    state.remove();
                    setState(null);
                }
            };

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return;
    },
);

export default Drawer;
