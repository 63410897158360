import { observable } from "mobx";
import Model from "@app/state/model/base";
import format from "@app/lib/format";
import { CommentStatus } from "@app/constants";

export default class Comment extends Model {
    @observable _id = undefined;
    @observable author = {
        _id: undefined,
        fullName: undefined,
        avatar: undefined,
    };
    @observable text = undefined;
    @observable category = undefined;
    @observable created = undefined;
    @observable replies = [];
    @observable status = undefined;
    @observable resolvedOn = undefined;
    @observable resolvedBy = undefined;
    @observable subscribers = [];
    @observable expanded = undefined;
    @observable isFoundInDocument = undefined;
    @observable isSectionComment = undefined;

    /**
     * Overwrite the set method
     */
    set(data) {
        super.set(data);
        this.author.initials = format.initials(this.author.fullName);
        this.expanded = this.status === CommentStatus.PENDING;
    }
}
