import React from "react";
import { observer } from "mobx-react";
import HtmlViewer from "@app/components/html/viewer";

import "./style/additional-data.scoped.scss";

const AdditionalData = observer(({ data }) => {
    return (
        <div className="fieldContainer">
            <div className="fieldLabel">
                <span className="text">{data?.title}</span>
            </div>

            {data?.value && (
                <div className="fieldContent">
                    <HtmlViewer html={data.value} />
                </div>
            )}

            {data?.ai?.suggestion && (
                <div className="fieldContent">
                    <br />
                    <span className="text">
                        <b>AI Suggestion: </b>
                        {data.ai.suggestion}
                    </span>
                </div>
            )}
        </div>
    );
});

export default AdditionalData;
