import React from "react";
import { observer, useLocalStore } from "mobx-react-lite";
import { DownOutlined, UpOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";
import UserChip from "@app/components/user/chip";

import Group from "@app/components/setup/group";
import session from "@app/state/store/session";
import confirm from "@app/components/confirm";

import "./group.scoped.scss";

const RoleGroup = observer(({ title, role, users, disabled, onAdd, onRemove, onCloseProject }) => {
    const state = useLocalStore(() => ({
        opened: true,

        toggle() {
            this.opened = !this.opened;
        },
    }));

    /**
     * Handle the add button click event
     */
    const addUser = (event) => {
        event.preventDefault();
        event.stopPropagation();

        onAdd(role);
    };

    /**
     * Remove a user from the project
     */
    const removeUser = async (id, args = {}) => {
        const proceed = await confirm(
            "Are you sure you want to remove this user from the project? " +
                "They will loose access to this project!",
        );
        if (proceed) {
            onRemove(id);
        }
    };

    const count = users.length;

    return (
        <Group
            title={
                <div className="expand-header">
                    <div className="expand-toggle" onClick={state.toggle}>
                        <div className="toggle">
                            {state.opened ? <UpOutlined /> : <DownOutlined />}
                        </div>
                    </div>
                    {title} ({count})
                </div>
            }
            onAdd={!disabled && addUser}
        >
            {state.opened &&
                users.map((user) => {
                    return (
                        <div className="user" key={user._id}>
                            <UserChip user={user} />
                            <div className="buttons">
                                {!disabled && (
                                    <Button
                                        onClick={() => removeUser(user._id)}
                                        type="text"
                                        danger
                                        icon={<DeleteOutlined />}
                                        disabled={user._id === session?.user?._id}
                                    ></Button>
                                )}
                            </div>
                        </div>
                    );
                })}
        </Group>
    );
});

export default RoleGroup;
