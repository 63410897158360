import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router-dom";
import Loader from "@app/components/loader/content";
import { SaveOutlined } from "@ant-design/icons";
import { Form, Button } from "antd";

import Page from "@app/components/page/page";
import Edit from "@app/components/setup/report-documents/metadata/edit";

import documentsStore from "@app/state/store/report-document/report-documents";

const EditMetadata = observer(() => {
    const [form] = Form.useForm();
    const history = useHistory();
    const { id, documentId } = useParams();
    const reportDocument = documentsStore.reportDocument;

    useEffect(() => {
        documentsStore.setProjectId(id);
        documentsStore.load(documentId);
    }, [id, documentId]);

    const close = () => {
        history.push(`../${documentId}`);
    };

    const save = async (data) => {
        await documentsStore.update(documentId, data);
        close();
    };

    return (
        <Page>
            <Page.Header closable onClose={close}>
                <Page.Header.Left shrink>
                    <Page.Title>{reportDocument?.name ?? "Loading..."}</Page.Title>
                </Page.Header.Left>

                <Page.Header.Right></Page.Header.Right>
            </Page.Header>

            <Page.Body maxWidth={1000}>
                {reportDocument && (
                    <Edit reportDocument={reportDocument} form={form} onSave={save} />
                )}

                <Loader loading={!reportDocument} />
            </Page.Body>
            <Page.Footer>
                {reportDocument && (
                    <Page.Footer.Right>
                        <Button
                            icon={<SaveOutlined />}
                            type="primary"
                            onClick={() => form.submit()}
                        >
                            Save
                        </Button>
                        <Button onClick={close}>Close</Button>
                    </Page.Footer.Right>
                )}
            </Page.Footer>
        </Page>
    );
});

export default EditMetadata;
