import { observer } from "mobx-react";

import { emptyContent, removeCommentReferences } from "../diff-utility";
import { useLoadVersionedContent, useDiff } from "../hooks";

export const CompareVersionsView = observer(({ editor, sectionId, versionA, versionB }) => {
    const { hasLoaded: versionALoaded, content: versionAContent } = useLoadVersionedContent({
        versionId: versionA,
        sectionId,
    });
    const { hasLoaded: versionBLoaded, content: versionBContent } = useLoadVersionedContent({
        versionId: versionB,
        sectionId,
    });

    const hasLoaded = versionALoaded && versionBLoaded;

    const lhs = removeCommentReferences(versionAContent?.rhs);
    const rhs = removeCommentReferences(versionBContent?.rhs);

    const left =
        lhs[sectionId] && Array.isArray(lhs[sectionId]?.content) ? lhs[sectionId] : emptyContent;
    const right =
        rhs[sectionId] && Array.isArray(rhs[sectionId]?.content) ? rhs[sectionId] : emptyContent;

    useDiff({ editor, left, right, sectionId, hasLoaded });
});

export default CompareVersionsView;
