import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Tag } from "antd";
import Modal from "@app/components/modal";
import format from "@app/lib/format";

import state from "@app/state/store/report/document/bookmarks";

import "./style/modal.scoped.scss";

export const BookmarkDetailsModal = observer(({ visible, bookmark, onDiscard }) => {
    useEffect(() => {
        if (state.categories === null && !state.loading) {
            state.loadCategories();
        }
    }, [bookmark]);

    if (state.categories === null) {
        return;
    }

    const categoriesMap = {};
    state.categories.forEach((category) => (categoriesMap[category._id] = category));

    const cancel = () => {
        onDiscard && onDiscard();
    };

    return (
        <Modal
            title={"Bookmark details"}
            visible={visible}
            onCancel={cancel}
            width={600}
            footer={null}
        >
            <div className="bookmark">
                <div>
                    <label>Bookmark name</label>
                    <div className="value">{bookmark.name}</div>
                </div>
                <div>
                    <label>Bookmarked by</label>
                    <div className="value">{bookmark.author?.fullName}</div>
                </div>
                <div>
                    <label>Categories</label>
                    <div className="categories">
                        {bookmark.categories.map((categoryId) => (
                            <Tag key={categoryId}>{categoriesMap[categoryId]?.name}</Tag>
                        ))}
                    </div>
                </div>
                <div className="bookmarkedOn">
                    Bookmarked on {format.datetime(bookmark.created)}
                </div>
            </div>
        </Modal>
    );
});

export default BookmarkDetailsModal;
