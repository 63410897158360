import { useEffect } from "react";

import notify from "@app/components/notify";

const HelpTextDeleteWarning = ({ editor }) => {
    const notifyOnDeleteHiddenHelpText = () => {
        notify.warn(
            "There is a hidden help text which can not be deleted. Please toggle the help text visibility icon first and repeat the action if you want to delete the content.",
        );
    };

    useEffect(() => {
        if (editor) {
            editor.on("helpTextDeleteWarning", notifyOnDeleteHiddenHelpText);

            return () => {
                editor.off("helpTextDeleteWarning", notifyOnDeleteHiddenHelpText);
            };
        }
    }, [editor]);

    return null;
};

export default HelpTextDeleteWarning;
