import React, { useEffect } from "react";
import { observer, useLocalStore } from "mobx-react";
import { Form, Input, Select, Button } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import classNames from "classnames";
import _ from "lodash";
import { v4 as uuid } from "uuid";

import Modal from "@app/components/modal";
import List from "@app/components/list/ordered";
import notify from "@app/components/notify";
import confirm from "@app/components/confirm/index";

import { ReactComponent as DragIcon } from "@app/assets/icons/drag-handle.svg";
import "./style/article.scoped.scss";

const MIN_YEAR = 1800;
const MAX_YEAR = new Date().getFullYear();

const EditModal = observer(({ article, onChange, onCancel }) => {
    const [form] = Form.useForm();
    const state = useLocalStore(() => ({
        authors: [],
    }));

    useEffect(() => {
        // combine some list fields into coma separated list
        const keywords = (article.keywords ?? []).join("; ");
        const descriptors = (article.descriptors ?? []).join("; ");
        const devices = (article.devices ?? []).join("; ");

        form.setFieldsValue({
            title: article.title,
            doi: article.ids?.doi,
            pubmed: article.ids?.pubmed,
            link: article.link,
            journalName: article.journalName,
            journalIssue: article.journalIssue,
            journalVolume: article.journalVolume,
            publicationYear: article.publicationYear,
            publicationMonth: article.publicationMonth,
            pageNumbers: article.pageNumbers,
            keywords,
            descriptors,
            devices,
        });

        state.authors = _.cloneDeep(article.authors).map((el) => {
            el.$key = uuid();
            el.$error = {};

            return el;
        });

        // add an empty row
        addAuthor();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, article]);

    /**
     * Show the validation error
     */
    const error = () => {
        notify.error("Please fill all required fields to continue");
    };

    /**
     * Cancel the edit
     */
    const cancel = () => {
        onCancel && onCancel();

        form.resetFields();
        state.authors = [];
    };

    /**
     * Save the value
     */
    const save = (data) => {
        let valid = true;
        const changed = {};

        // process the title
        changed.title = (data.title || "").replace(/\s{2,}/g, " ").replace(/\n/, " ");

        // process doi and pmid
        changed.ids = {
            ...article.ids,
            doi: (data.doi || "").trim(),
            pubmed: (data.pubmed || "").trim(),
        };

        // process link
        changed.link = (data.link || "").trim();

        // process the source information
        changed.journalName = (data.journalName || "").trim();
        changed.journalIssue = (data.journalIssue || "").trim();
        changed.journalVolume = (data.journalVolume || "").trim();
        changed.pageNumbers = (data.pageNumbers || "").trim();
        changed.publicationYear = data.publicationYear ? Number(data.publicationYear) : null;
        changed.publicationMonth = data.publicationMonth ? Number(data.publicationMonth) : null;

        // validate the values
        changed.authors = state.authors
            .map((entry) => {
                entry.firstName = entry.firstName ? entry.firstName.trim() : "";
                entry.lastName = entry.lastName ? entry.lastName.trim() : "";
                return entry;
            })

            // remove the empty values
            .filter((entry) => {
                return entry.firstName || entry.lastName;
            })

            // check for valid properties
            .map((entry) => {
                entry.$error = {};

                for (let prop of ["firstName", "lastName"]) {
                    let value = entry[prop];

                    if (!value) {
                        entry.$error[prop] = true;
                        valid = false;
                    }
                }

                return entry;
            });

        // check for valid authors
        if (!valid) {
            return error();
        }

        // process the list items
        changed.keywords = (data.keywords || "")
            .trim()
            .split(/\s*;\s*/)
            .filter((el) => el !== "");

        changed.descriptors = (data.descriptors || "")
            .trim()
            .split(/\s*;\s*/)
            .filter((el) => el !== "");

        changed.devices = (data.devices || "")
            .trim()
            .split(/\s*;\s*/)
            .filter((el) => el !== "");

        // should have at least one author
        if (changed.authors.length < 1) {
            notify.error("Please add at least one author to continue");
            return;
        }

        onChange && onChange(changed);
    };

    /**
     * Add a new author to the list
     */
    const addAuthor = () => {
        state.authors.push({
            $key: uuid(),
            $new: true,
            $error: {},
        });
    };

    /**
     * Remove an entry from the list
     */
    const removeAuthor = async (entry) => {
        let proceed = true;

        // Confirm it only if the user removes existing author. In order to reduce
        // the noise with constant confirmations, we are confirming only when the user
        // tries to delete one of existing authors so they don;t remove it by mistake.
        if (!entry.$new) {
            proceed = await confirm("Are you sure you want to remove the author?");
        }

        if (proceed) {
            let idx = state.authors.indexOf(entry);
            state.authors.splice(idx, 1);
        }
    };

    /**
     * Reorder the list
     */
    const reorderAuthors = (action) => {
        let [item] = state.authors.splice(action.from, 1);
        state.authors.splice(action.to, 0, item);
    };

    /**
     * Update a field value
     */
    const setAuthorValue = (entry, field, value) => {
        entry[field] = value;
        entry.$error[field] = false;

        let idx = state.authors.indexOf(entry);
        if (idx === state.authors.length - 1) {
            addAuthor();
        }
    };

    return (
        <Modal
            title="Edit article details"
            visible={true}
            okText="Save"
            onOk={form.submit.bind(form)}
            onCancel={cancel}
            width={800}
            className="article-edit-modal"
        >
            <div className="edit-article">
                <Form layout="vertical" form={form} onFinishFailed={error} onFinish={save}>
                    <div className="section">Title</div>
                    <Form.Item
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: "Please enter article title",
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input.TextArea />
                    </Form.Item>

                    <div className="section">External Sources</div>
                    <div className="row gap">
                        <div className="col">
                            <Form.Item
                                label="DOI"
                                name="doi"
                                rules={[
                                    {
                                        pattern: /^10.\d+\/.*$/i,
                                        message: "Please enter a valid DOI",
                                    },
                                ]}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </div>
                        <div className="col">
                            <Form.Item label="PMID" name="pubmed">
                                <Input allowClear />
                            </Form.Item>
                        </div>
                    </div>
                    <Form.Item label="Link" name="link">
                        <Input allowClear />
                    </Form.Item>

                    <div className="section">Keywords & Manufacturer</div>
                    <Form.Item label="Author Keywords" name="keywords">
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item label="Descriptors" name="descriptors">
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item label="Manufacturer & Device Keywords" name="devices">
                        <Input.TextArea />
                    </Form.Item>

                    <div className="section">Publication Details</div>
                    <Form.Item label="Journal Name (publication source)" name="journalName">
                        <Input />
                    </Form.Item>

                    <div className="row gap">
                        <div className="col">
                            <Form.Item label="Journal Volume" name="journalVolume">
                                <Input />
                            </Form.Item>
                        </div>
                        <div className="col">
                            <Form.Item label="Journal Issue" name="journalIssue">
                                <Input />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="row gap">
                        <div className="col">
                            <Form.Item
                                label="Publication Year"
                                name="publicationYear"
                                rules={[
                                    {
                                        message: "Invalid publication year",
                                        validator(_, value) {
                                            // do not allow empty value
                                            if (!value) {
                                                return Promise.resolve();
                                            }

                                            // convert to number and check if within the range
                                            value = Number(value);
                                            if (value < MIN_YEAR || value > MAX_YEAR) {
                                                return Promise.reject(
                                                    new Error(
                                                        `The value should be between ${MIN_YEAR} and ${MAX_YEAR}!`,
                                                    ),
                                                );
                                            }

                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                            >
                                <Input type="number" />
                            </Form.Item>
                        </div>
                        <div className="col">
                            <Form.Item label="Publication Month" name="publicationMonth">
                                <Select allowClear>
                                    <Select.Option value={1}>January</Select.Option>
                                    <Select.Option value={2}>February</Select.Option>
                                    <Select.Option value={3}>March</Select.Option>
                                    <Select.Option value={4}>April</Select.Option>
                                    <Select.Option value={5}>May</Select.Option>
                                    <Select.Option value={6}>June</Select.Option>
                                    <Select.Option value={7}>July</Select.Option>
                                    <Select.Option value={8}>August</Select.Option>
                                    <Select.Option value={9}>September</Select.Option>
                                    <Select.Option value={10}>October</Select.Option>
                                    <Select.Option value={11}>November</Select.Option>
                                    <Select.Option value={12}>December</Select.Option>
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col">
                            <Form.Item label="Page Numbers" name="pageNumbers">
                                <Input />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="section">Authors</div>
                    <div className="authors">
                        <div className="header">
                            <div className="col empty"></div>
                            <div className="col first">First Name</div>
                            <div className="col last">Last Name</div>
                            <div className="buttons">
                                <Button icon={<PlusOutlined />} type="icon" onClick={addAuthor} />
                            </div>
                        </div>
                        <div className="body">
                            <List onChange={reorderAuthors}>
                                {state.authors.map((author, index) => {
                                    return (
                                        <List.Item key={author.$key} id={author.$key} index={index}>
                                            <div className="row entry" key={author.$key}>
                                                <div className="col handle">
                                                    <DragIcon />
                                                </div>
                                                <div
                                                    className={classNames("col first", {
                                                        invalid: author.$error?.firstName,
                                                    })}
                                                >
                                                    <Input
                                                        value={author.firstName}
                                                        onChange={(e) =>
                                                            setAuthorValue(
                                                                author,
                                                                "firstName",
                                                                e.target.value,
                                                            )
                                                        }
                                                    />
                                                </div>

                                                <div
                                                    className={classNames("col last", {
                                                        invalid: author.$error?.lastName,
                                                    })}
                                                >
                                                    <Input
                                                        value={author.lastName}
                                                        onChange={(e) =>
                                                            setAuthorValue(
                                                                author,
                                                                "lastName",
                                                                e.target.value,
                                                            )
                                                        }
                                                    />
                                                </div>

                                                <div className="col remove">
                                                    <Button
                                                        type="icon"
                                                        icon={<DeleteOutlined />}
                                                        onClick={() => removeAuthor(author)}
                                                    />
                                                </div>
                                            </div>
                                        </List.Item>
                                    );
                                })}
                            </List>
                        </div>
                    </div>
                </Form>
            </div>
        </Modal>
    );
});

export default EditModal;
