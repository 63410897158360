import { computed, action } from "mobx";
import http from "@app/lib/http";
import BaseStore from "../base";
import ArticleActionModel from "@app/state/model/action/article";
import report from "@app/state/store/report";
import { events } from "@app/lib/store";
import notify from "@app/components/notify/index";
import { ActionType } from "@app/constants";

export class ArticleAction extends BaseStore {
    constructor() {
        super();

        events.on("project.unload", () => this.reset());
        events.on("article.update", (article) => this.update(article));
    }
    observable() {
        return {
            loading: false,
            data: {},
            meta: undefined,
        };
    }

    @computed get busy() {
        return this.loading;
    }

    @computed get project() {
        return report.id;
    }

    @action
    async update(article) {
        if (!this.meta || !this.project) {
            return;
        }

        try {
            const body = {
                type: ActionType.ARTICLE,
                project: this.project,
                payload: {
                    id: article._id,
                    name: article.title,
                    key: article?.mdrId,
                    meta: this.meta,
                },
            };

            this.loading = true;
            let { data } = await http.post(`/project/${this.project}/action`, body);

            if (data) {
                this.data = new ArticleActionModel(data);
            }
        } catch (error) {
            notify.error(error.response?.data?.error);
        } finally {
            this.loading = false;
        }
    }

    @action
    setContext(meta) {
        this.meta = meta;
    }

    @action
    async load(type) {
        if (!this.project) {
            return;
        }

        try {
            this.loading = true;

            let { data } = await http.get(`/project/${this.project}/actions/${type}`);
            if (data) {
                this.data = new ArticleActionModel(data);
            }
        } catch (error) {
            notify.error(error.response?.data?.error);
        } finally {
            this.loading = false;
        }
    }
}

export default new ArticleAction();
