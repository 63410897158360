import React from "react";
import { observer } from "mobx-react";
import { CommentOutlined } from "@ant-design/icons";

import BubbleItemContainer from "./bubble-item-container";
import { scrollToComment } from "../../../utils";

import commentStore from "@app/state/store/report-document/comment";

const CommentBubble = observer(({ id, isInLandscape, index, zIndex, mouseIn }) => {
    const comments = commentStore.comments;
    const { status } = comments.find((c) => c._id === id) || { status: "deleted" };

    const focus = () => {
        commentStore.focus(id, true);
        scrollToComment(id);
    };

    if (status === "deleted") {
        return null;
    }

    const attrs = {};
    attrs[`data-icon-${id}`] = true;

    return (
        <BubbleItemContainer
            onClick={focus}
            isInLandscape={isInLandscape}
            index={index}
            zIndex={zIndex}
            mouseIn={mouseIn}
            focused={commentStore.focusedComment === id}
            classNames={{
                "comment-bubble": true,
                pending: status === "pending",
                resolved: status === "resolved",
            }}
            attrs={attrs}
        >
            <CommentOutlined />
        </BubbleItemContainer>
    );
});

export default CommentBubble;
