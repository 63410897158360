import React from "react";
import qs from "qs";
import { observer, useLocalStore } from "mobx-react";
import { Link } from "react-router-dom";

import Feedback from "./signup/feedback";
import Success from "./signup/success";
import Form from "./signup/form";

import "./style/auth.scoped.scss";

import session from "@app/state/store/session";

const Signup = observer(({ location }) => {
    var state = useLocalStore(() => ({
        error: false,
        success: false,
        loading: false,
        verified: false,
        allowed: false,
    }));

    let params = qs.parse(location.search, { ignoreQueryPrefix: true });
    let token = params.token;

    /**
     * Verify the token
     */
    const verify = async () => {
        let allowed = await session.verifyToken({
            token: token,
            action: "register",
        });
        state.verified = true;
        state.allowed = allowed;
    };

    // show empty page while verifying the token
    if (!state.verified) {
        verify();
        return null;
    }

    const Content = observer(() => {
        if (state.success) {
            return <Success state={state} />;
        } else if (state.allowed && !state.error) {
            return <Form state={state} token={token} />;
        } else {
            return null;
        }
    });

    // render the page
    return (
        <div className="signup auth">
            <div className="logo">
                <img src={require("@app/assets/logo-name.svg")} />
            </div>

            <div className="title">Sign Up</div>

            <div className="alert">
                <Feedback state={state} />
            </div>

            <div className="mt-30">
                <Content />
            </div>

            <div className="mt-30">
                <Link to="./login">Back to the login page</Link>
            </div>
        </div>
    );
});

export default Signup;
