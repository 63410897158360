import React from "react";
import classNames from "classnames";
import format from "@app/lib/format";
import { observer } from "mobx-react";
import { Button, Divider, Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
    SaveOutlined,
    DeleteOutlined,
    WarningOutlined,
    DisconnectOutlined,
} from "@ant-design/icons";
import sectionsStore from "@app/state/store/report-document/report-document-sections";

import "./styles/statusbar.scoped.scss";

const StatusBar = observer(({ editor, onSave, onClose, sectionId }) => {
    const {
        localSectionIds,
        discardLocal,
        lastSave,
        saving,
        saved,
        divergedSectionIds,
    } = sectionsStore;
    const isStoredLocally = localSectionIds.includes(sectionId);
    const isDiverged = divergedSectionIds.includes(sectionId);

    const onDiscardLocalCopy = () => {
        discardLocal(sectionId);
    };

    return (
        <div className="statusbar">
            <div className="left"></div>
            <div className="right">
                {editor && (
                    <>
                        <Divider type="vertical" className="divider" />
                        <SaveStatus
                            saved={saved}
                            saving={saving}
                            lastSave={lastSave}
                            isStoredLocally={isStoredLocally}
                            isDiverged={isDiverged}
                        />
                        <Divider type="vertical" className="divider" />
                        <div className="actions">
                            {isDiverged && (
                                <Button
                                    type="danger"
                                    icon={<DeleteOutlined />}
                                    onClick={onDiscardLocalCopy}
                                >
                                    Discard Local Copy
                                </Button>
                            )}
                            <Button
                                type="primary"
                                icon={<SaveOutlined />}
                                onClick={() => onSave(isDiverged)}
                            >
                                {isDiverged ? "Overwrite" : "Save"}
                            </Button>
                            <Button onClick={onClose}>Close</Button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
});

const SaveStatus = observer(({ saved, saving, lastSave, isStoredLocally, isDiverged }) => {
    if (isDiverged) {
        return (
            <Tooltip
                placement="top"
                title="Unable to sync locally stored changes. There is a newer version of content on server. Please copy your changes, discard local version and refresh the page."
            >
                <div className={classNames("save-status", "diverged")}>
                    <div className="icon">
                        <WarningOutlined />
                    </div>
                    <div className="label">
                        <div className="status">Diverged</div>
                    </div>
                </div>
            </Tooltip>
        );
    }

    if (isStoredLocally && !saving) {
        return (
            <Tooltip
                placement="top"
                title="Network connection lost. Storing changes locally. Will save to the server once connection is restored."
            >
                <div className={classNames("save-status", "locally")}>
                    <div className="icon">
                        <DisconnectOutlined />
                    </div>
                    <div className="label">
                        <div className="status">Stored locally</div>
                        {lastSave && <div className="time">{format.elapsed(lastSave)} ago</div>}
                    </div>
                </div>
            </Tooltip>
        );
    }

    return (
        <div
            className={classNames("save-status", {
                saving: saving,
                draft: !saved,
                saved: saved,
            })}
        >
            <div className="icon">{saving ? <LoadingOutlined /> : <SaveOutlined />}</div>
            <div className="label">
                <div className="status">
                    {saving && "Saving..."}
                    {!saving && saved ? "Saved" : "Draft"}
                </div>
                {!saving && saved && lastSave && (
                    <div className="time">{format.elapsed(lastSave)} ago</div>
                )}
            </div>
        </div>
    );
});

export default StatusBar;
