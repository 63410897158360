import React from "react";
import { observer } from "mobx-react";

import "./style/review-stage.scoped.scss";

const ReviewStage = observer(({ data }) => {
    return (
        <div className="reviewStage">
            <div>
                <b>Title:</b> {data.title}
            </div>
            {data.reviewers?.length > 0 && (
                <>
                    <div>
                        <u>Reviewers:</u>
                    </div>
                    <div>
                        {data.reviewers?.map(({ fullName }) => (
                            <div className="reviewer">{`${fullName}`}</div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
});

export default ReviewStage;
