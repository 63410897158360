import React, { useState } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { LeftOutlined, PushpinOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { createState } from "@app/components/setup/report-documents/structure/context";
import { ReportDocumentSectionStatus, ReportDocumentStatus } from "@app/constants";
import StatusIcon from "./status-icon";

import reportDocumentsStore from "@app/state/store/report-document/report-documents";

import "../styles/toc.scoped.scss";

const TOC = observer(({ sections, appendix }) => {
    const [minimized, setMinimized] = useState(true);
    const [expanded, setExpanded] = useState(false);
    const tocState = createState();
    const tree = tocState.convertTOCToTree(sections);

    if (!sections) {
        return;
    }

    /**
     * Show the minimized table of contents
     */
    const onMouseOver = () => {
        if (minimized) {
            setExpanded(true);
        }
    };

    /**
     * Hide the  minimized table of contents
     */
    const onMouseOut = () => {
        if (minimized) {
            setExpanded(false);
        }
    };

    /**
     * Toggle between minimized/maximized state
     */
    const toggle = () => {
        setMinimized(!minimized);
        setExpanded(false);
    };

    return (
        <div
            className={classNames("toc", { minimized, expanded })}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
        >
            <div className="content">
                <div className="header">
                    <em>Document Sections</em>
                    <div className="toggle">
                        <Button
                            icon={minimized ? <PushpinOutlined /> : <LeftOutlined />}
                            className="ant-btn-icon"
                            onClick={toggle}
                        />
                    </div>
                </div>

                <div className="sections">
                    {tree?.map((data, i) => (
                        <RenderSection key={i} root={true} data={data} />
                    ))}

                    {appendix.hasAppendix ? (
                        <>
                            <div className="appendix-separator" />
                            <RenderSection
                                key={"appendix"}
                                root={true}
                                data={{
                                    _id: "appendix",
                                    pos: `${tree?.length + 1}`,
                                    showStatus: false,
                                    title: "Generated Content",
                                    children: [
                                        appendix.hasTables
                                            ? {
                                                  _id: "tables",
                                                  pos: `${tree?.length + 1}.1`,
                                                  showStatus: false,
                                                  title: "List of Tables",
                                              }
                                            : null,
                                        appendix.hasFigures
                                            ? {
                                                  _id: "figures",
                                                  pos: `${tree?.length + 1}.1`,
                                                  showStatus: false,
                                                  title: "List of Figures",
                                              }
                                            : null,
                                    ].filter((x) => x),
                                }}
                            />
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    );
});

const Section = observer(({ data, level, expandedButton, children }) => {
    const history = useHistory();
    const userReviewer = reportDocumentsStore.getUserReviewer(data.reviewers);
    const reportDocument = reportDocumentsStore.reportDocument;

    /**
     * Select a section
     */
    const select = () => {
        history.push({
            pathname: history.location.pathname,
            search: `?section=${data._id}`,
        });
    };

    let status = data.status;
    let showStatus = data.showStatus ?? true;

    if (
        userReviewer?.reviewed ||
        reportDocument.status === ReportDocumentStatus.REVIEWED ||
        reportDocument.status === ReportDocumentStatus.COMPLETED
    ) {
        status = ReportDocumentSectionStatus.REVIEWED;
    }

    return (
        <>
            <div id={data.pos} className={`sectionContainer level-${level}`} onClick={select}>
                {showStatus && (
                    <StatusIcon
                        status={status}
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                        }}
                    />
                )}
                <label className="sectionNumber">{data.displayPos}</label>
                <div className="sectionTitle">{data.title}</div>
                {expandedButton}
            </div>
            {children}
        </>
    );
});

const RenderSection = observer(({ root, data, numberOnly, level = 1 }) => {
    const [expanded, setExpanded] = useState(true);

    const expandedButton =
        root && data?.children?.length > 0 && !numberOnly ? (
            <span className="expanedButton">
                <Button
                    icon={expanded ? <UpOutlined /> : <DownOutlined />}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setExpanded(!expanded);
                    }}
                />
            </span>
        ) : null;

    if (expanded && data?.children?.length > 0) {
        return (
            <Section
                root={root}
                data={data}
                level={level}
                numberOnly={numberOnly}
                expandedButton={expandedButton}
            >
                {data.children.map((child) => (
                    <RenderSection
                        key={child._id}
                        root={false}
                        data={child}
                        numberOnly={numberOnly}
                        level={level + 1}
                    />
                ))}
            </Section>
        );
    } else {
        return (
            <Section
                root={root}
                data={data}
                level={level}
                numberOnly={numberOnly}
                expandedButton={expandedButton}
            />
        );
    }
});

TOC.displayName = "TOC";

export default TOC;
