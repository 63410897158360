import React from "react";
import { observer } from "mobx-react";
import Filter from "@app/components/filter";
import Sidebar from "@app/components/filter/sidebar";
import activity from "@app/state/store/activity";

const ActivityFilter = observer(({ filter }) => {
    React.useEffect(() => {
        activity.allUsers();
    }, []);

    const users = activity.users
        ?.filter((user) => user.fullName)
        .map((user) => ({
            label: user.fullName,
            value: user._id,
        }));

    return (
        <Sidebar filter={filter}>
            <Filter.DateRange
                filter={filter}
                title="Activity Date"
                startDateName="minActivityDate"
                endDateName="maxActivityDate"
                groupKey="activity.date"
            />

            <Filter.MultiSelect filter={filter} title="User(s)" name="users" options={users} />
        </Sidebar>
    );
});

export default ActivityFilter;
