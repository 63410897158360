import { useEffect } from "react";
import { useParams } from "react-router-dom";

import versionStore from "@app/state/store/report-document/version";

export const useLoadVersionedContent = ({ versionId }) => {
    const { project: projectId, reportDocumentId } = useParams();
    const { loadContentVersion, versionedContent, loadingState, errorState } = versionStore || {};
    const isLoading = loadingState[versionId];
    const isError = errorState[versionId];
    const content = versionedContent[versionId];

    useEffect(() => {
        async function fetchData() {
            await loadContentVersion({ versionId, projectId, reportDocumentId });
        }

        if (!content && !isLoading && versionId && projectId && reportDocumentId && !isError) {
            fetchData();
        }
    }, [versionId, isLoading, content, projectId, reportDocumentId]);

    return {
        isLoading,
        hasLoaded: !!versionedContent[versionId],
        content: versionedContent[versionId] || { lhs: {}, rhs: {} },
        isError,
    };
};

export default useLoadVersionedContent;
