import { Plugin } from "prosemirror-state";

import { ReportDocumentSection } from "@app/constants";

export const helpTextPlugin = () =>
    new Plugin({
        appendTransaction(transactions, oldState, newState) {
            const tr = newState.tr;
            let modified = false;
            const helpTextNodes = new Set();
            const otherNodes = new Set();
            const commentNodes = new Set();
            const nodesWithoutHelpText = new Set();

            const hasHelpTextMark = (node) => {
                return node.marks.some(
                    (mark) => mark.type.name === ReportDocumentSection.HELP_TEXT.MARK_NAME,
                );
            };

            const hasCommentMark = (node) => {
                return node.marks.some(
                    (mark) => mark.type.name === ReportDocumentSection.COMMENT.MARK_NAME,
                );
            };

            const filterParagraphContent = (contentNode) => {
                // filter out non-text nodes - should not be here, just as safety check
                if (contentNode.type.name !== "text") {
                    return false;
                }

                // filter out empty text nodes
                if (!contentNode.text.replace(" ", "").length) {
                    return false;
                }

                return true;
            };

            const isHelpTextParagraph = (node) => {
                // empty paragraph could not hold help text
                if (node.content.content.length === 0) {
                    return false;
                }

                if (helpTextNodes.has(node)) {
                    return true;
                }

                if (otherNodes.has(node)) {
                    return false;
                }

                const filteredContent = node.content.content.filter(filterParagraphContent);

                if (
                    filteredContent.length &&
                    node.content.content.filter(filterParagraphContent).every(hasHelpTextMark)
                ) {
                    helpTextNodes.add(node);
                    return true;
                }

                otherNodes.add(node);

                return false;
            };

            const isParagraphWithComment = (node) => {
                if (commentNodes.has(node)) {
                    return true;
                }

                return node.content.content.some((textNode) => {
                    if (hasCommentMark(textNode)) {
                        commentNodes.add(node);
                        return true;
                    }

                    nodesWithoutHelpText.add(node);

                    return false;
                });
            };

            const checkTextRecursively = (node) => {
                let hasHelpTextMark = undefined;
                let hasCommentMark = undefined;

                if (helpTextNodes.has(node) || otherNodes.has(node)) {
                    hasHelpTextMark = helpTextNodes.has(node);
                }

                if (commentNodes.has(node) || nodesWithoutHelpText.has(node)) {
                    hasCommentMark = commentNodes.has(node);
                }

                if (hasHelpTextMark !== undefined && hasCommentMark !== undefined) {
                    return { hasHelpTextMark, hasCommentMark };
                }

                if (node.type.name === "paragraph") {
                    return {
                        hasHelpTextMark: isHelpTextParagraph(node),
                        hasCommentMark: isParagraphWithComment(node),
                    };
                } else if (node.type.name === "listItem") {
                    const nodeContentResult = node.content.content.map(checkTextRecursively);

                    let hasHelpTextMark = nodeContentResult.every((r) => r.hasHelpTextMark);
                    let hasCommentMark = nodeContentResult.some((r) => r.hasCommentMark);

                    if (hasHelpTextMark) {
                        helpTextNodes.add(node);
                    } else {
                        otherNodes.add(node);
                    }

                    if (hasCommentMark) {
                        commentNodes.add(node);
                    } else {
                        nodesWithoutHelpText.add(node);
                    }

                    return { hasHelpTextMark, hasCommentMark };
                }

                return { hasHelpTextMark: false, hasCommentMark: false };
            };

            const changeHelpTextAttributeIfRequired = (node, pos, hasHelpTextMark, hasComment) => {
                if (
                    hasHelpTextMark !== node.attrs.helpText ||
                    hasComment !== node.attrs.hasComment
                ) {
                    tr.setNodeMarkup(pos, undefined, {
                        ...node.attrs,
                        helpText: hasHelpTextMark,
                        hasComment,
                    });
                    modified = true;
                }
            };

            newState.doc.descendants((node, pos) => {
                if (node.type.name === "orderedList" || node.type.name === "bulletList") {
                    const result = node.content.content.map((listItemNode) =>
                        checkTextRecursively(listItemNode),
                    );

                    const hasHelpTextMark = result.every((r) => r.hasHelpTextMark);
                    const hasComment = result.some((r) => r.hasCommentMark);

                    changeHelpTextAttributeIfRequired(node, pos, hasHelpTextMark, hasComment);
                } else if (node.type.name === "listItem") {
                    const { hasHelpTextMark, hasCommentMark } = checkTextRecursively(node);

                    changeHelpTextAttributeIfRequired(node, pos, hasHelpTextMark, hasCommentMark);
                } else if (node.type.name === "paragraph") {
                    const { hasHelpTextMark, hasCommentMark } = checkTextRecursively(node);

                    changeHelpTextAttributeIfRequired(node, pos, hasHelpTextMark, hasCommentMark);
                }
            });

            return modified ? tr : null;
        },
    });

export default helpTextPlugin;
