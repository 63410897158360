import React from "react";
import { FileSyncOutlined } from "@ant-design/icons";

import "./style/sync-warning.scoped.scss";

const SyncWarning = () => {
    return (
        <div className="syncing">
            <div className="icon">
                <FileSyncOutlined />
            </div>

            <p>
                The adverse events database is being synchronized at the moment. Please try later
                after all events has been downloaded.
            </p>
        </div>
    );
};

export default SyncWarning;
