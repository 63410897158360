import React, { useCallback, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { Input, Button } from "antd";

import Modal from "@app/components/modal";
import Card from "./card";

import "./styles/picker.scss";

const PickerModal = observer(({ dictionary, onPick, onClose, onAddEntry }) => {
    const containerRef = useRef(null);
    const [input, setInput] = useState(null);
    const [value, setValue] = useState();
    const inputRef = useCallback((ref) => {
        if (ref) {
            ref.focus({ cursor: "start" });
            setInput(ref);
        }
    });

    /**
     * Handle the search event
     */
    const onSearch = (value) => {
        dictionary.search(value);
        setValue(value);
    };

    /**
     * Get the currently focused element
     */
    const getFocused = () => {
        const container = containerRef.current;
        const node = container.querySelector("input:focus, .entry-card:focus");

        if (node.tagName === "INPUT") {
            return { type: "search", node: input };
        } else {
            return { type: "entry", node };
        }
    };

    /**
     * Get the next item to be focused
     */
    const focusNext = (direction) => {
        const container = containerRef.current;
        const focused = getFocused();

        let nextNode;
        if (direction === "down") {
            if (!focused || focused.type === "search") {
                nextNode = container.querySelector(".entry-card:first-child");
            } else {
                nextNode = focused.node.nextSibling;
            }
        } else {
            if (focused?.node.previousSibling) {
                nextNode = focused.node.previousSibling;
            } else {
                nextNode = input;
            }
        }

        if (nextNode) {
            setTimeout(() => {
                nextNode.focus({ cursor: "end" });
            }, 0);
        }
    };

    /**
     * Keyboard navigation
     */
    const onKeyDown = (e) => {
        if (e.key === "ArrowDown") {
            focusNext("down");
        } else if (e.key === "ArrowUp") {
            focusNext("up");
        }
    };

    /**
     * Handle the onEnter event for the card
     */
    const onEnter = (event, entry) => {
        if (event.key === "Enter") {
            onPick(entry);
        }
    };

    const onNewAddEntry = async () => {
        const params = {
            name: value.trim(),
        };

        onAddEntry(params);
    };

    // reset the search on close
    useEffect(() => {
        return () => {
            dictionary.search("");
        };
    }, []);

    return (
        <Modal
            title="Pick an item from the dictionary"
            visible={true}
            width={1000}
            footer={null}
            onCancel={onClose}
            className="picker-modal"
            keyboard={true}
        >
            <div className="picker" onKeyDown={onKeyDown} ref={containerRef}>
                <div className="filter">
                    <Input.Search
                        placeholder="Search"
                        onChange={(e) => onSearch(e.target.value)}
                        value={dictionary.filter}
                        onSearch={onSearch}
                        allowClear
                        ref={inputRef}
                    />
                    {value && (
                        <Button
                            size="small"
                            style={{ position: "absolute", right: "47px", top: "14px" }}
                            onClick={() => onNewAddEntry()}
                        >
                            Add "{value}" to the dictionary
                        </Button>
                    )}
                </div>
                <div className="list">
                    {dictionary.filteredList.map((entry) => {
                        return (
                            <Card
                                key={entry._id}
                                entry={entry}
                                onClick={() => onPick(entry)}
                                onKeyDown={(event) => onEnter(event, entry)}
                                short
                                tabIndex="0"
                            />
                        );
                    })}
                </div>
            </div>
        </Modal>
    );
});

export default PickerModal;
