/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-case-declarations */
/* eslint-disable default-case */
import * as React from "react";

import events from "@app/lib/store/events";

import { HIGHLIGHT_LAYER_ATTR, HIGHLIGHT_PAGE_ATTR } from "./constants";
import { getRectFromOffsets } from "./get-rect-from-offsets";
import { getTextFromOffsets } from "./get-text-from-offsets";
import { getOffsetsFromText } from "./get-offset-from-text";
import { NO_SELECTION_STATE, SelectedState, SELECTING_STATE } from "./highlight-state";
import { SelectionRange } from "./selection-range";
import { transformArea } from "./transform-area";
import { useRotation } from "./useRotation";

// `\n` is the document selection string when double clicking a page without selecting any text
const EMPTY_SELECTION = ["", "\n"];

function getRelativeCoordinates(event, referenceElement) {
    const position = {
        x: event.pageX,
        y: event.pageY,
    };

    const offset = {
        left: referenceElement.offsetLeft,
        top: referenceElement.offsetTop,
    };

    let reference = referenceElement.offsetParent;

    while (reference) {
        offset.left += reference.offsetLeft;
        offset.top += reference.offsetTop;
        reference = reference.offsetParent;
    }

    return {
        x: position.x - offset.left,
        y: position.y - offset.top,
    };
}

export const Tracker = ({ store }) => {
    const { rotation } = useRotation(store);
    const pagesRef = React.useRef(null);
    const [arePagesFound, setPagesFound] = React.useState(false);

    const handlePagesContainer = (getPagesContainer) => {
        const ele = getPagesContainer();
        pagesRef.current = ele;
        setPagesFound(!!ele);
    };

    const onMouseUpHandler = (event) => {
        const isRightClick = event.button === 2;
        const selection = document.getSelection();

        const highlightState = store.get("highlightState");
        const hasSelection =
            (highlightState === NO_SELECTION_STATE || highlightState === SELECTING_STATE) &&
            selection.rangeCount > 0 &&
            EMPTY_SELECTION.indexOf(selection.toString()) === -1;

        const clickY = event.clientY;

        if (!hasSelection && isRightClick) {
            const textLayer = event.target.closest(".rpv-core__text-layer");
            if (!textLayer) {
                return;
            }

            const innerPage = textLayer.closest(".rpv-core__inner-page");
            if (!innerPage) {
                return;
            }

            const pageLabel = innerPage.getAttribute("aria-label");
            const pageIndex = pageLabel ? parseInt(pageLabel.split(" ")[1]) - 1 : -1;

            if (pageIndex === -1) {
                return;
            }

            const pageRect = textLayer.getBoundingClientRect();
            const relativeY = clickY - pageRect.top;

            const { x } = getRelativeCoordinates(event, textLayer);

            const area = {
                height: 1, // small height
                left: (x * 100) / pageRect.width,
                pageIndex,
                top: (relativeY * 100) / pageRect.height,
                width: 1, // small width
            };

            const selectionData = {
                startPageIndex: pageIndex,
                endPageIndex: pageIndex,
                startOffset: 0,
                startDivIndex: 0,
                endOffset: 0,
                endDivIndex: 0,
            };

            store.update(
                "highlightState",
                new SelectedState(
                    "", // no selected text
                    [{ isPoint: true, ...transformArea(area, rotation) }],
                    selectionData,
                    area,
                ),
            );
            return;
        } else if (!hasSelection) {
            return;
        } else {
            const range = selection.getRangeAt(0);
            const startDiv = range.startContainer.parentNode;
            const parentEndContainer = range.endContainer.parentNode;
            const shouldIgnoreEndContainer =
                parentEndContainer instanceof HTMLElement &&
                parentEndContainer.hasAttribute(HIGHLIGHT_LAYER_ATTR);

            let endDiv, endOffset;

            if (startDiv && startDiv.parentNode === range.endContainer) {
                endDiv = startDiv;
                endOffset = endDiv.textContent.length;
            } else if (shouldIgnoreEndContainer && range.endOffset === 0) {
                endDiv = range.endContainer.previousSibling;
                endOffset = endDiv.textContent.length;
            } else if (shouldIgnoreEndContainer) {
                endDiv = range.endContainer;
                endOffset = range.endOffset;
            } else {
                endDiv = parentEndContainer;
                endOffset = range.endOffset;
            }

            if (!(startDiv instanceof HTMLElement) || !(endDiv instanceof HTMLElement)) {
                return;
            }

            const startPageIdx = parseInt(startDiv.getAttribute(HIGHLIGHT_PAGE_ATTR), 10);
            const endPageIdx = parseInt(endDiv.getAttribute(HIGHLIGHT_PAGE_ATTR), 10);

            const startTextLayer = startDiv.parentElement;
            const endTextLayer = endDiv.parentElement;

            const startPageRect = startTextLayer.getBoundingClientRect();
            const startDivSiblings = [].slice.call(
                startTextLayer.querySelectorAll(`[${HIGHLIGHT_PAGE_ATTR}]`),
            );
            const startDivIdx = startDivSiblings.indexOf(startDiv);

            const endPageRect = endTextLayer.getBoundingClientRect();
            const endDivSiblings = [].slice.call(
                endTextLayer.querySelectorAll(`[${HIGHLIGHT_PAGE_ATTR}]`),
            );
            const endDivIdx = endDivSiblings.indexOf(endDiv);

            let rangeType = SelectionRange.DifferentPages;
            switch (true) {
                case startPageIdx === endPageIdx && startDivIdx === endDivIdx:
                    rangeType = SelectionRange.SameDiv;
                    break;
                case startPageIdx === endPageIdx && startDivIdx < endDivIdx:
                    rangeType = SelectionRange.DifferentDivs;
                    break;
                default:
                    rangeType = SelectionRange.DifferentPages;
                    break;
            }

            const getRectBetween = (min, max, eleArray) =>
                Array(max - min + 1)
                    .fill(0)
                    .map((_, i) => {
                        return eleArray[min + i];
                    })
                    .filter((ele) => ele.nodeName !== "BR")
                    .map((ele) => ele.getBoundingClientRect());

            let selectedText = "";
            switch (rangeType) {
                case SelectionRange.SameDiv:
                    selectedText = getTextFromOffsets(
                        startTextLayer,
                        startDivIdx,
                        range.startOffset,
                        startDivIdx,
                        endOffset,
                    );
                    break;

                case SelectionRange.DifferentDivs:
                    selectedText = getTextFromOffsets(
                        startTextLayer,
                        startDivIdx,
                        range.startOffset,
                        endDivIdx,
                        endOffset,
                    );
                    break;

                case SelectionRange.DifferentPages:
                    const startText = getTextFromOffsets(
                        startTextLayer,
                        startDivIdx,
                        range.startOffset,
                        startDivSiblings.length,
                    );

                    const endText = getTextFromOffsets(endTextLayer, 0, 0, endDivIdx, endOffset);

                    selectedText = `${startText}\n${endText}`;
                    break;
            }

            let highlightAreas = [];
            switch (rangeType) {
                case SelectionRange.SameDiv:
                    const rect = getRectFromOffsets(startDiv, range.startOffset, endOffset);

                    if (!rect) {
                        return;
                    }

                    highlightAreas = [
                        {
                            height: (rect.height * 100) / startPageRect.height,
                            left: ((rect.left - startPageRect.left) * 100) / startPageRect.width,
                            pageIndex: startPageIdx,
                            top: ((rect.top - startPageRect.top) * 100) / startPageRect.height,
                            width: (rect.width * 100) / startPageRect.width,
                        },
                    ];
                    break;

                case SelectionRange.DifferentDivs:
                    const textOffset = getOffsetsFromText(startDiv, selectedText);

                    const selectionRect = getRectFromOffsets(
                        startDiv,
                        textOffset.startOffset,
                        textOffset.endOffset,
                    );

                    if (!selectionRect) {
                        return;
                    }

                    highlightAreas = [selectionRect]
                        .concat(getRectBetween(startDivIdx + 1, endDivIdx - 1, startDivSiblings))
                        .concat([getRectFromOffsets(endDiv, 0, endOffset)])
                        .filter((rect) => rect)
                        .map((rect) => {
                            return {
                                height: (rect.height * 100) / startPageRect.height,
                                left:
                                    ((rect.left - startPageRect.left) * 100) / startPageRect.width,
                                pageIndex: startPageIdx,
                                top: ((rect.top - startPageRect.top) * 100) / startPageRect.height,
                                width: (rect.width * 100) / startPageRect.width,
                            };
                        });
                    break;

                case SelectionRange.DifferentPages:
                    const pageRect = getRectFromOffsets(
                        startDiv,
                        range.startOffset,
                        startDiv.textContent.length,
                    );

                    if (!pageRect) {
                        return;
                    }

                    const startAreas = [pageRect]
                        .concat(
                            getRectBetween(
                                startDivIdx + 1,
                                startDivSiblings.length - 1,
                                startDivSiblings,
                            ),
                        )
                        .map((rect) => {
                            return {
                                height: (rect.height * 100) / startPageRect.height,
                                left:
                                    ((rect.left - startPageRect.left) * 100) / startPageRect.width,
                                pageIndex: startPageIdx,
                                top: ((rect.top - startPageRect.top) * 100) / startPageRect.height,
                                width: (rect.width * 100) / startPageRect.width,
                            };
                        });

                    const endRect = getRectFromOffsets(endDiv, 0, endOffset);

                    if (!endRect) {
                        return;
                    }

                    const endAreas = getRectBetween(0, endDivIdx - 1, endDivSiblings)
                        .concat([endRect])
                        .map((rect) => {
                            return {
                                height: (rect.height * 100) / endPageRect.height,
                                left: ((rect.left - endPageRect.left) * 100) / endPageRect.width,
                                pageIndex: endPageIdx,
                                top: ((rect.top - endPageRect.top) * 100) / endPageRect.height,
                                width: (rect.width * 100) / endPageRect.width,
                            };
                        });
                    highlightAreas = startAreas.concat(endAreas);
                    break;
            }

            let selectionRegion;
            if (highlightAreas.length > 0) {
                selectionRegion = highlightAreas[highlightAreas.length - 1];
            } else {
                const endDivRect = endDiv.getBoundingClientRect();
                selectionRegion = {
                    height: (endDivRect.height * 100) / endPageRect.height,
                    left: ((endDivRect.left - endPageRect.left) * 100) / endPageRect.width,
                    pageIndex: endPageIdx,
                    top: ((endDivRect.top - endPageRect.top) * 100) / endPageRect.height,
                    width: (endDivRect.width * 100) / endPageRect.width,
                };
            }

            const selectionData = {
                startPageIndex: startPageIdx - 1,
                endPageIndex: endPageIdx - 1,
                startOffset: range.startOffset,
                startDivIndex: startDivIdx,
                endOffset,
                endDivIndex: endDivIdx,
            };

            store.update(
                "highlightState",
                new SelectedState(
                    selectedText,
                    highlightAreas.map((area) => transformArea(area, rotation)),
                    selectionData,
                    selectionRegion,
                ),
            );
        }
    };

    React.useEffect(() => {
        const ele = pagesRef.current;
        if (!ele) {
            return;
        }

        ele.addEventListener("mouseup", onMouseUpHandler);
        return () => {
            ele.removeEventListener("mouseup", onMouseUpHandler);
        };
    }, [arePagesFound, rotation]);

    React.useEffect(() => {
        store.subscribe("getPagesContainer", handlePagesContainer);

        return () => {
            store.unsubscribe("getPagesContainer", handlePagesContainer);
        };
    }, []);

    React.useEffect(() => {
        const setSelectionToNone = () => {
            store.update("highlightState", NO_SELECTION_STATE);
        };

        events.on("clickOutsidePdf", setSelectionToNone);

        return () => {
            events.off("clickOutsidePdf", setSelectionToNone);
        };
    }, []);

    return <></>;
};
