import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import qs from "qs";
import { observer } from "mobx-react";
import { Tabs } from "antd";
import classNames from "classnames";
import {
    FilterOutlined,
    FileSearchOutlined,
    ProfileOutlined,
    InfoCircleOutlined,
} from "@ant-design/icons";
import { Page } from "@app/components/page";
import { useSidebar } from "../../page/sidebar";
import Filters from "./filter";
import Activity from "@app/components/activity";
import { Activities } from "@app/constants";
import Comments from "@app/components/comments/sidebarComments";

import "./style/sidebar.scoped.scss";

const FILTER_TAB = "filter";
const COMMENTS_TAB = "comments";
const ACTIVITY_TAB = "activity";

const Sidebar = observer(({ search, searchState, filter, disabled, width = 380, ...rest }) => {
    const history = useHistory();

    const urlParams = qs.parse(history.location.search, {
        ignoreQueryPrefix: true,
    });

    const [activeKey, setActiveKey] = React.useState(undefined);
    const sidebar = useSidebar();

    const hasComments = searchState.comments.length > 0;

    useEffect(() => {
        if (urlParams.comments === "true") {
            switchTab(COMMENTS_TAB);
        }
    }, []);

    const switchTab = (key) => {
        setActiveKey(key);
    };

    return (
        <Page.Sidebar
            className="search-sidebar"
            sidebar={sidebar}
            width={width}
            padding={false}
            collapsible={false}
            {...rest}
        >
            <Tabs type="card" activeKey={activeKey} onTabClick={switchTab} destroyInactiveTabPane>
                <Tabs.TabPane
                    tab={<FilterOutlined className="tab-icon" />}
                    key={FILTER_TAB}
                    className="filter-tab"
                >
                    <Filters filter={filter} disabled={disabled} />
                </Tabs.TabPane>

                {search && (
                    <>
                        <Tabs.TabPane
                            tab={
                                <ProfileOutlined
                                    className={classNames("tab-icon", { badge: hasComments })}
                                />
                            }
                            key={COMMENTS_TAB}
                        >
                            <Comments
                                state={searchState}
                                noItemsContent={
                                    <div className="empty">
                                        <div className="icon">
                                            <InfoCircleOutlined />
                                        </div>
                                        <div className="text">
                                            There are no comments available.
                                            <br />
                                            You can add a comment using the input field below.
                                        </div>
                                    </div>
                                }
                            />
                        </Tabs.TabPane>

                        <Tabs.TabPane
                            tab={<FileSearchOutlined className="tab-icon" />}
                            key={ACTIVITY_TAB}
                        >
                            <Activity id={search} context={Activities.SEARCH} view="sidebar" />
                        </Tabs.TabPane>
                    </>
                )}
            </Tabs>
        </Page.Sidebar>
    );
});

export default Sidebar;
