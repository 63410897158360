import React, { useState } from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import Modal from "./modal";

import "./style/icon.scoped.scss";

const Header = () => {
    const [modalOpen, setModalOpen] = useState(false);

    const onClick = () => {
        setModalOpen(true);
    };

    const onClose = () => {
        setModalOpen(false);
    };

    return (
        <>
            <div className="icon" onClick={onClick}>
                <QuestionCircleOutlined />
            </div>
            <Modal opened={modalOpen} onClose={onClose} />
        </>
    );
};

export default Header;
