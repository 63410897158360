import React from "react";
import { observer } from "mobx-react";
import { Alert } from "antd";

const Feedback = observer(({ state }) => {
    if (!state.allowed || state.error) {
        return (
            <Alert
                message="Error"
                description="This signup link has expired"
                type="error"
                showIcon
            />
        );
    } else if (state.success) {
        return (
            <Alert
                message="Success"
                description="Your account was successfully created."
                type="success"
                showIcon
            />
        );
    } else {
        return null;
    }
});
export default Feedback;
