import { computed, action } from "mobx";
import http from "@app/lib/http";
import { events } from "@app/lib/store";
import BaseStore from "../base";
import ProjectConfig from "@app/state/model/project/config";
import { ws } from "@app/lib/socket";
import project from "./details";
import notify from "@app/components/notify";
import { ProjectStatus } from "@app/constants";

/**
 * State management controlling the project setup
 */
export class Setup extends BaseStore {
    last = null;

    /**
     * observable store data
     */
    observable() {
        return {
            id: null,
            project: null,
            config: null,
            loading: false,
            saving: false,
        };
    }

    constructor() {
        super();
        events.on("project.unload", () => this.unload());
        ws.on("project.config.changed", () => {
            this.loadConfig();
        });
    }

    @computed get busy() {
        return this.loading || this.saving;
    }

    @computed get isComplete() {
        return this.project?.status === ProjectStatus.COMPLETE;
    }

    /**
     * Load the project details
     */
    @action
    async load(id) {
        // do not load the data twice
        if (this.id === id || this.loading) {
            return;
        }

        this.loading = true;
        this.id = id;

        await this.loadProject();
        await this.loadConfig();

        this.loaded = true;
        this.loading = false;
    }

    /**
     * Load the project
     */
    @action
    async loadProject() {
        if (!this.id) {
            return;
        }

        await project.load(this.id);
        this.project = project.data;
    }

    /**
     * Load the project configuration
     */
    @action
    async loadConfig() {
        if (!this.id) {
            return;
        }

        let { data } = await http.get(`/project/${this.id}/config`);

        if (data) {
            this.last = data;
            this.config = new ProjectConfig(data);
        }
    }

    /**
     * Update the project configuration
     */
    @action
    async updateConfig(params, args = {}) {
        try {
            // update the config value immediately
            if (args.preview) {
                this.config.set(params);
            }

            let { data } = await http.post(`/project/${this.id}/config`, params);
            if (data) {
                this.last = data;
                this.config.set(data);
            }
        } catch (ex) {
            // restore the config value
            this.config.set({ ...this.last });

            notify.error(ex.response?.data?.error);
            // re-throw the error
            throw ex;
        }
    }

    /**
     * Update the project configuration
     */
    @action
    async updateProject(params) {
        await project.update(params);
        this.project = project.data;
    }

    // /**
    //  * Activate a project
    //  */
    // @action
    // async activate() {
    //     if (!this.id) {
    //         return;
    //     }

    //     if (this.project.status === "active") {
    //         return;
    //     }

    //     this.saving = true;
    //     await http.put(`/project/${this.id}/activate`);

    //     this.project.status = "active";
    //     events.emit("project.update", this.project);
    //     this.saving = false;
    // }

    /**
     * Change project status
     */
    @action
    async status(status) {
        if (!this.id) {
            return;
        }

        this.saving = true;
        await http.put(`/project/${this.id}/status`, { status });

        this.project.status = status;
        events.emit("project.update", this.project);
        this.saving = false;
    }

    /**
     * Unload the report details
     */
    @action
    async unload() {
        this.project = null;
        this.config = null;
        this.id = null;
    }
}

export default new Setup();
