import React, { useCallback, useEffect } from "react";
import { observer, useLocalStore } from "mobx-react";
import { Button } from "antd";

import Modal from "@app/components/modal";

export { default as ClipboardPlugin } from "./plugin";

export const ClipboardWarning = observer(({ editor }) => {
    const state = useLocalStore(() => ({
        showMessage: false,
    }));

    const handler = useCallback(() => {
        state.showMessage = true;
    });

    useEffect(() => {
        if (editor) {
            editor.on("showClipboardWarning", handler);
            return () => editor.off("showClipboardWarning", handler);
        }
    }, [editor]);

    if (!state.showMessage) {
        return null;
    }

    /**
     * Render the drawer footer
     */
    const Footer = observer(() => {
        return (
            <div className="button-group">
                <Button type="primary" onClick={() => (state.showMessage = false)}>
                    OK
                </Button>
            </div>
        );
    });

    return (
        <Modal
            title={"Paste not supported"}
            visible={true}
            closable={false}
            width={500}
            footer={<Footer />}
        >
            <p>
                We can't access your clipboard. Please use the shortcuts below to perform copy/paste
                operations.
            </p>

            <div className="keys">
                <div className="key">
                    Cut selected text: <b>Ctrl+X / Cmd+X</b>
                </div>
                <div className="key">
                    Copy selected text: <b>Ctrl+C / Cmd+C</b>
                </div>
                <div className="key">
                    Paste: <b>Ctrl+V / Cmd+V</b>
                </div>
            </div>
        </Modal>
    );
});
