import React from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import ReportWrapper from "./report-wrapper";
import EmptyList from "@app/components/list/empty";
import state from "@app/state/store/report/output/slr/scoring";

import { SearchType } from "@app/constants";

const ReportPage = () => (
    <ReportWrapper name="L2 Screening Summary" component={<Report />} maxWidth="100%" />
);

const Report = observer(() => {
    const { type } = useParams();

    React.useEffect(() => {
        state.load(type);
    }, [type]);

    const { data, loading, columns: reportColumns } = state;

    // Remove Score column for SoTA report
    const columns =
        type === SearchType.SLR ? reportColumns : reportColumns.filter((c) => c.title !== "Score");

    const noOfQuestions = data?.questions?.length;
    const noOfArticles = data?.articles?.length;

    return (
        <>
            <EmptyList
                title="No Records Found"
                show={
                    (data === null && !loading) ||
                    (!loading && (noOfQuestions === 0 || noOfArticles === 0))
                }
            ></EmptyList>
            {!loading && noOfQuestions > 0 && noOfArticles > 0 && (
                <DataTable data={data} columns={columns} />
            )}
        </>
    );
});

const DataTable = observer((props) => {
    const { data, columns } = props;
    if (data?.articles === undefined) {
        return null;
    } else {
        return (
            <>
                <table>
                    <colgroup>
                        <col />
                        {columns.map((col) => (
                            <col key={col.title} width={col.width} />
                        ))}
                    </colgroup>
                    <thead>
                        <tr>
                            <th>S No.</th>
                            {columns.map((col, idx) => (
                                <th key={idx}>{col.title}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.articles.map((row, idx) => (
                            <tr key={row.mdrId}>
                                <td>{idx + 1}</td>
                                {columns.map((col, idx) => (
                                    <td key={idx}>{row[col.dataIndex]}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>
        );
    }
});

export default ReportPage;
