import { action, computed } from "mobx";
import http from "@app/lib/http";

import BaseStore from "@app/state/store/base";

import report from "../../../report";
import format from "@app/lib/format";

const fixedColumns = [
    {
        title: "Literature ID",
        dataIndex: "mdrId",
        width: "100",
    },
    {
        title: "Author(s)",
        dataIndex: "authors",
        width: "300",
    },
    {
        title: "Title",
        dataIndex: "title",
        width: "300",
    },
    {
        title: "Year",
        dataIndex: "publicationYear",
        width: "200",
    },
    {
        title: "Reference",
        dataIndex: "article",
        width: "200",
    },
    {
        title: "Source",
        dataIndex: "source",
        width: "200",
    },
    {
        title: "Include Article? Yes/No",
        dataIndex: "inclusionStatus",
        width: "200",
    },
    {
        title: "Reason For Exclusion",
        dataIndex: "reason",
        width: "200",
    },
];

const scoreColumn = {
    title: "Score",
    dataIndex: "score",
    width: "100",
};

export class SearchStringsStore extends BaseStore {
    observable() {
        return {
            loading: false,
            data: null,
            columns: [],
        };
    }

    @computed get busy() {
        return this.loading;
    }

    @computed get project() {
        return report.id;
    }

    @action
    updateColumnsAndTransformedData = (input) => {
        this.columns = this.updateTableColumns(input);
        this.transformData(input);
        return input;
    };

    @action
    transformData = (input) => {
        // Loop through the rows and columns and then prepare columnName, Data Object and push it into an array.

        input = input.articles.map((article) => {
            article.mdrId = format.cid(article.mdrId);
            article.additionalData.map((additionalData) => {
                article[additionalData._id] = additionalData.value;
            });

            article.questions.map((question) => {
                article[question._id] = question.grade;
            });
        });
    };

    @action
    updateTableColumns = (input) => {
        const columns = [...fixedColumns];

        // additionalData
        input.additionalData.map((data) => {
            const { title, _id } = data;

            columns.push({
                title,
                dataIndex: _id,
            });
        });

        input.questions.map((data) => {
            const { contribution, _id } = data;

            columns.push({
                title: contribution,
                dataIndex: _id,
            });
        });

        // add the score
        columns.push(scoreColumn);

        return columns;
    };

    @action
    async load(type) {
        this.loading = true;

        let { data } = await http.get(`/project/${this.project}/reports/scoring/${type}`);
        if (Object.keys(data).length > 0 && data.articles && data.questions) {
            this.data = this.updateColumnsAndTransformedData(data);
        }
        this.loading = false;
    }
}

export default new SearchStringsStore();
