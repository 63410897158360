import React from "react";
import { observer } from "mobx-react-lite";
import { FileSearchOutlined } from "@ant-design/icons";

import { Review, SearchType } from "@app/constants";

import Alert from "@app/components/alert/alert";

const ReviewStatus = observer(({ article, stage = Review.L2, type = SearchType.SLR }) => {
    const review = (stage === Review.L2 ? article.review?.l2 : article.review?.l1) || {};
    const included = review.status === Review.PASS;

    // do not render it the article is pending
    if (!review.status) {
        return;
    }

    const description = included ? (
        <>
            <label>Included</label>
        </>
    ) : (
        <>
            <label>Excluded{review.reason ? ":" : ""}</label>
            <span className="excluded-reason">{review.reason}</span>
        </>
    );

    return (
        <Alert
            status={included ? "success" : "error"}
            description={description}
            icon={<FileSearchOutlined />}
        />
    );
});

export default ReviewStatus;
