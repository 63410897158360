import React from "react";
import { observer, useLocalStore } from "mobx-react";
import { useHistory } from "react-router-dom";
import Page from "@app/components/page/page";
import { Button } from "antd";

import Categories from "@app/components/setup/source-documents/categories";
import ToggleSourceDocuments from "@app/components/setup/source-documents/toggle";
import { PlusOutlined } from "@ant-design/icons";

import setup from "@app/state/store/projects/setup";
import categories from "@app/state/store/report/document/categories";
import confirm from "@app/components/confirm/index";
import report from "@app/state/store/report";

const SourceDocuments = observer(() => {
    const history = useHistory();
    const state = useLocalStore(() => ({
        add: false,
        category: {},
    }));

    const config = setup.config;
    const readonly = report.readOnly || setup.isComplete;

    if (!config) {
        return;
    }

    const setAdd = (input) => (state.add = input);

    const close = () => {
        history.push("../setup");
    };

    const edit = (payload) => {
        setAdd(true);
        state.category = payload;
    };

    React.useEffect(() => {
        if (config.sourceDocuments?.enabled === true) {
            categories.loadConfig();
        }
    }, [config.sourceDocuments]);

    // Update Project Configuration - Enable/Disable Source Documents.
    const updateConfig = async (data) => {
        const updates = {
            sourceDocuments: {
                ...config.sourceDocuments,
                ...data,
            },
        };

        config.set(updates);
        await setup.updateConfig({
            ...config,
            ...updates,
        });
    };

    // Update Category Details
    const update = async ({ _id, ...payload }) => {
        await categories.update(_id, payload);
    };

    const save = async (data) => {
        if (state.category) {
            await update(data);
        } else {
            await categories.create(data);
        }
    };

    const remove = async (payload) => {
        const proceed = await confirm("Are you sure you want to remove this category ?");

        if (proceed) {
            payload.deleted = true;
            await categories.delete(payload._id);
        }
    };

    return (
        <Page>
            <Page.Header closable onClose={close}>
                <Page.Header.Left shrink>
                    <Page.Title>Documents</Page.Title>
                </Page.Header.Left>

                <Page.Header.Right>
                    {!readonly && (
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => {
                                state.category = undefined; // not editing any category
                                setAdd(true);
                            }}
                            disabled={!config.sourceDocuments?.enabled}
                        >
                            Add Category
                        </Button>
                    )}
                </Page.Header.Right>
            </Page.Header>

            <Page.Body maxWidth={1200}>
                <ToggleSourceDocuments
                    config={config.sourceDocuments}
                    onChange={(data) => {
                        updateConfig(data);
                    }}
                    readonly={readonly}
                />
                {config.sourceDocuments?.enabled ? (
                    <>
                        <Categories
                            categories={categories.configList}
                            onChange={(data) => {
                                update(data);
                            }}
                            save={(data) => save(data)}
                            add={state.add}
                            onCancel={() => setAdd(false)}
                            onEdit={edit}
                            onDelete={remove}
                            category={state.category}
                            readonly={readonly}
                        />
                    </>
                ) : null}
            </Page.Body>
        </Page>
    );
});

export default SourceDocuments;
