import { action, computed } from "mobx";
import BaseStore from "../../base";
import http from "@app/lib/http";
import notify from "@app/components/notify";
import setup from "@app/state/store/projects/setup";
import report from "@app/state/store/report";
import { v4 as uuid } from "uuid";
import Category from "@app/state/model/document/category";
import events from "@app/lib/store/events";
import FilterState from "@app/components/filter/state";
import { CustomCategories } from "@app/constants";
import Filter from "@app/components/filter/state";

const STGR_LOAD = uuid();

const customCategories = [
    {
        name: "All Files",
        _id: CustomCategories.ALL,
        enabled: true,
        system: false,
    },
    {
        name: "Uncategorized",
        _id: CustomCategories.UNCATEGORIZED,
        enabled: true,
        system: false,
    },
];

export class CategoryStore extends BaseStore {
    constructor() {
        super();

        this.filter = new FilterState({
            default: {
                enabled: true,
            },
        });

        this.filter.on("find", () => {
            this.load();
        });

        events.on("category.update", this.reload.bind(this));
        events.on("document.update", this.load.bind(this));
        events.on("project.unload", () => {
            this.resetFilters();
            this.reset();
        });
    }

    observable() {
        return {
            loading: false,
            list: [],
            configList: [],
        };
    }

    @computed get project() {
        return report.id || setup.id;
    }

    @action
    async load() {
        this.loading = true;

        try {
            let filter = this.filter.value();
            filter.enabled = "true";

            let { data } = await http
                .get(`/project/${this.project}/document/categories`, filter)
                .stagger(STGR_LOAD);

            this.list = [...customCategories, ...data.list].map((entry) => {
                if (entry._id === CustomCategories.UNCATEGORIZED) {
                    entry.documents = {};
                    entry.documents.count = data?.uncategorized?.count;
                }
                if (entry._id === CustomCategories.ALL) {
                    entry.documents = {};
                    entry.documents.count = data?.totalDocuments;
                }

                return new Category(entry);
            });

            this.filter.stats(data.stats);
        } catch (ex) {
            notify.error(ex.response?.data?.error);
        } finally {
            this.loading = false;
        }
    }

    @action
    async loadConfig() {
        this.loading = true;

        try {
            let filter = this.filter.value();
            const { enabled, ...otherFilters } = filter;

            let { data } = await http
                .get(`/project/${this.project}/document/categories`, otherFilters)
                .stagger(STGR_LOAD);

            this.configList = data.list.map((entry) => {
                return new Category(entry);
            });

            this.filter.stats(data.stats);
        } catch (ex) {
            notify.error(ex.response?.data?.error);
        } finally {
            this.loading = false;
        }
    }

    @action
    async reload() {
        await this.loadConfig();
        await this.load();
    }

    @action
    async create(payload) {
        if (!payload.name) {
            return;
        }

        try {
            this.loading = true;
            await http
                .post(`/project/${this.project}/document/category`, payload)
                .stagger(STGR_LOAD);

            events.emit("category.update");
        } catch (ex) {
            notify.error(ex.response?.data?.error);
        } finally {
            this.loading = false;
        }
    }

    @action
    async update(categoryId, payload) {
        if (!categoryId) {
            return;
        }

        try {
            this.loading = true;
            await http
                .put(`/project/${this.project}/document/category/${categoryId}`, payload)
                .stagger(STGR_LOAD);

            events.emit("category.update");

            return true;
        } catch (ex) {
            notify.error(ex.response?.data?.error);
            return false;
        } finally {
            this.loading = false;
        }
    }

    getDetails(id) {
        return this.list.find((category) => category._id === id);
    }

    @action
    resetFilters() {
        this.filter = new Filter({});
        this.filter.on("find", () => {
            this.load();
        });
    }

    @action
    async delete(categoryId) {
        if (!categoryId) {
            return;
        }

        try {
            this.loading = true;
            await http
                .delete(`/project/${this.project}/document/category/${categoryId}`)
                .stagger(STGR_LOAD);

            events.emit("category.update");
            return true;
        } catch (ex) {
            notify.error(ex.response?.data?.error);
            return false;
        } finally {
            this.loading = false;
        }
    }

    @action
    async configuredCategories() {
        // This will not return customCategories

        if (this.list.length === 0) {
            await this.load();
        }
        const allCategories = this.list.filter(
            (item) => ![CustomCategories.ALL, CustomCategories.UNCATEGORIZED].includes(item._id),
        );
        return allCategories;
    }
}

export default new CategoryStore();
