import React from "react";
import { observer } from "mobx-react";

import { ReportDocumentSection } from "@app/constants";

import CaptisLinkBubble from "./captis-link-bubble";
import CommentBubble from "./comment-bubble";

const BubbleContent = observer(({ type, ...rest }) => {
    if (type === ReportDocumentSection.COMMENT.MARK_NAME) {
        return <CommentBubble {...rest} />;
    }

    if (type === ReportDocumentSection.CAPTIS_LINK.MARK_NAME) {
        return <CaptisLinkBubble {...rest} />;
    }

    return null;
});

export default BubbleContent;
