import React from "react";
import { observer } from "mobx-react";
import { NavLink } from "react-router-dom";

import "./style/pending.scoped.scss";

const Pending = observer(({ summary, withSota, withSourceDocuments }) => {
    if (!summary) {
        return;
    }

    const { documentCategories: categories, documents, l1Review, l2Review } = summary;

    const actions = [
        {
            group: "due",
            count: l1Review.slr.pending,
            text: {
                single: "article is pending a screening",
                plural: "articles are pending a screening",
            },
            link: "./slr/l1?page=1&status=pending",
        },
        {
            group: "due",
            count: l2Review.slr.pending,
            text: {
                single: "article is pending a review",
                plural: "articles are pending a review",
            },
            link: "./slr/l2?page=1&status=pending",
        },
        {
            group: "sota",
            count: withSota ? l1Review.sota.pending : 0,
            text: {
                single: "article is pending a screening",
                plural: "articles are pending a screening",
            },
            link: "./sota/l1?page=1&status=pending",
        },
        {
            group: "sota",
            count: withSota ? l2Review.sota.pending : 0,
            text: {
                single: "article is pending a review",
                plural: "articles are pending a review",
            },
            link: "./sota/l2?page=1&status=pending",
        },
        {
            group: "documents",
            count: withSourceDocuments ? documents.pending : 0,
            text: {
                single: "document is pending a review",
                plural: "documents are pending a review",
            },
            link: "./document/categories/all?page=1&status=pending",
        },
        {
            group: "documents",
            count: withSourceDocuments ? categories.pending : 0,
            text: {
                single: "category requires source documents",
                plural: "categories require source documents",
            },
            link: "./document/categories?status=pending",
        },
    ];

    const pending = actions.filter((action) => action.count > 0);
    const dueActions = pending.filter((action) => action.group === "due");
    const sotaActions = pending.filter((action) => action.group === "sota");
    const documentActions = pending.filter((action) => action.group === "documents");

    return (
        <div className="pending">
            <div className="heading">Pending Actions</div>

            {pending.length === 0 && (
                <div className="empty">No pending actions. You are all caught up.</div>
            )}

            {dueActions.length > 0 && (
                <div className="group">
                    <div className="title">DUE</div>
                    {dueActions.map((action, idx) => {
                        return (
                            <Action
                                key={idx}
                                text={action.text}
                                count={action.count}
                                link={action.link}
                            />
                        );
                    })}
                </div>
            )}

            {sotaActions.length > 0 && (
                <div className="group">
                    <div className="title">SoTA</div>
                    {sotaActions.map((action, idx) => {
                        return (
                            <Action
                                key={idx}
                                text={action.text}
                                count={action.count}
                                link={action.link}
                            />
                        );
                    })}
                </div>
            )}

            {documentActions.length > 0 && (
                <div className="group">
                    <div className="title">Source Documents</div>
                    {documentActions.map((action, idx) => {
                        return (
                            <Action
                                key={idx}
                                text={action.text}
                                count={action.count}
                                link={action.link}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
});

const Action = ({ text, count, link }) => {
    return (
        <div className="action">
            <div className="text">
                {count === 1 && `${count} ${text.single}`}
                {count !== 1 && `${count} ${text.plural}`}
            </div>
            <div className="link">
                <NavLink to={link}>View</NavLink>
            </div>
        </div>
    );
};

export default Pending;
