// Keyword

import React from "react";
import { observer } from "mobx-react";
import Keyword from "@app/components/setup/keywords/card";

import "./style/keyword.scoped.scss";

const Keywords = observer(({ data }) => {
    return (
        <div className="keyword">
            <Keyword value={data} readonly={true} />
        </div>
    );
});

export default Keywords;
