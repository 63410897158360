import { Tag } from "antd";
import React from "react";
import { observer } from "mobx-react";

const MissingAbstract = observer(({ article }) => {
    const { abstract } = article;

    if (abstract) {
        return;
    } else {
        return <Tag color="red">Missing Abstract</Tag>;
    }
});

export default MissingAbstract;
