import React, { useState, useRef, useEffect } from "react";
import { observer } from "mobx-react";
import { Button, Input } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import "./style/input-field.scoped.scss";

const InputField = observer(({ value, onOk, onCancel }) => {
    const [fieldValue, setFieldValue] = useState(value);
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef?.current !== null) {
            inputRef.current.focus();
        }
    }, [inputRef]);

    return (
        <div
            className="editField"
            onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
            }}
        >
            <Input
                ref={inputRef}
                value={fieldValue}
                onChange={(event) => {
                    setFieldValue(event.currentTarget.value);
                }}
                onKeyDown={(event) => {
                    switch (event.key) {
                        case "Escape":
                            onCancel();
                            break;
                        case "Enter":
                            onOk(fieldValue);
                            break;
                        default:
                            break;
                    }
                }}
            />
            <div className="inputButtons">
                <label>Hit enter to save or ESC to cancel</label>
                <Button type="icon" icon={<CheckOutlined />} onClick={() => onOk(fieldValue)} />
                <Button type="icon" icon={<CloseOutlined />} onClick={onCancel} />
            </div>
        </div>
    );
});

export default InputField;
