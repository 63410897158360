import tippy from "tippy.js";
import { PluginKey } from "prosemirror-state";
import { ReactRenderer } from "@tiptap/react";
import { default as MentionList } from "./suggestion-list.jsx";
import { getMarks } from "../utils/content";

const items = ({ editor }) => {
    return editor.storage.citation.store.citations;
};

const renderSuggestionList = () => {
    let component;
    let popup;

    const onExit = () => {
        if (popup && component) {
            popup.destroy();
            component.destroy();
        }
    };

    return {
        onStart: (props) => {
            component = new ReactRenderer(MentionList, {
                props: {
                    ...props,
                    onExit,
                },
                editor: props.editor,
            });

            if (!props.clientRect) {
                return;
            }

            popup = tippy(document.querySelector("div.viewer > div.content"), {
                getReferenceClientRect: props.clientRect,
                content: component.element,
                showOnCreate: true,
                interactive: true,
                trigger: "manual",
                placement: "bottom-start",
                popperOptions: {
                    modifiers: [{ name: "flip", enabled: false }],
                },
                zIndex: 2,
            });
        },

        onUpdate(props) {
            component.updateProps(props);

            if (!props.clientRect) {
                return;
            }

            popup.setProps({
                getReferenceClientRect: props.clientRect,
            });
        },

        onKeyDown(props) {
            if (props.event.key === "Escape") {
                popup.hide();

                return true;
            }

            return component.ref?.onKeyDown(props);
        },

        onExit,
    };
};

const command = ({ editor, range, props }) => {
    const nodeAfter = editor.view.state.selection.$to.nodeAfter;
    const nodeBefore = editor.view.state.selection.$from.nodeBefore;

    const overrideSpace = nodeAfter?.text?.startsWith(" ");
    const marks = getMarks({ editor });

    if (overrideSpace) {
        range.to += 1;
    }

    if (props.resetFocus) {
        return editor.commands.focus();
    }

    if (props.addCitation) {
        return editor.chain().focus().addCitation({ atRange: range }).run();
    }

    // remove the spaces before the citation
    const spaces = nodeBefore?.text?.match(/(\s*)@\S*$/)[1] ?? "";
    if (spaces.length) {
        range.from -= spaces.length;
    }

    const content = props.items.map((item) => ({
        type: "citation",
        attrs: item,
        marks,
    }));

    editor.chain().focus().insertContentAt(range, content).run();
};

const allow = ({ editor, state, range }) => {
    const {
        view: {
            _props: { templateMode },
        },
    } = editor;

    if (templateMode) {
        return false;
    }

    // only trigger for single @
    const text = state.tr.doc.textBetween(range.from, range.to);
    const allowed = text === "@";
    return allowed;
};

export default {
    pluginKey: new PluginKey("citation"),
    allowedPrefixes: null,
    items,
    render: renderSuggestionList,
    command,
    allow,
};
