import React, { useState } from "react";
import { observer } from "mobx-react";
import { Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { UploadService } from "./service";
import { useUploadContext } from "./context";

const UploadButton = observer(
    ({
        icon,
        multiple = false,
        label = "Upload",
        accept = "*",
        primary = false,
        state,
        onChange,
    }) => {
        const [loading, setLoading] = useState(false);
        const { projectId, clientId } = useUploadContext();

        /**
         * Handle the beforeUpload event and start the upload process.
         * Return false to prevent the component uploading the file.
         * We are handling the upload ourselves.
         *
         * @param {File} file
         */
        const onFilePick = (file) => {
            (async () => {
                setLoading(!multiple && true);

                const { data } = await UploadService.upload(file, {
                    projectId,
                    clientId,
                    state,
                });

                setLoading(false);

                if (data) {
                    onChange && (await onChange(data));
                }
            })();

            return false;
        };

        /**
         * Render the actual upload button
         */
        const Content = observer(() => {
            if (icon) {
                return <Button icon={icon} type="icon" loading={loading} />;
            } else {
                return (
                    <Button icon={<UploadOutlined />} loading={loading} type={primary && "primary"}>
                        {label}
                    </Button>
                );
            }
        });

        return (
            <Upload
                showUploadList={false}
                beforeUpload={onFilePick}
                accept={accept}
                multiple={multiple}
            >
                <Content />
            </Upload>
        );
    },
);

export default UploadButton;
