import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Button, Dropdown, Menu } from "antd";
import {
    CheckOutlined,
    EllipsisOutlined,
    EyeOutlined,
    DownOutlined,
    FileWordOutlined,
    FileZipOutlined,
    HistoryOutlined,
} from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import { Page } from "@app/components/page";
import { Viewer } from "@app/components/report-document";
import { ReportDocumentStatus } from "@app/constants";
import { events } from "@app/lib/store";
import confirm from "@app/components/confirm";
import { antdHelpers } from "@app/util";
import Status from "@app/components/report-document/status";

import reportDocumentsStore from "@app/state/store/report-document/report-documents";
import sectionsStore from "@app/state/store/report-document/report-document-sections";
import dictionaryStore from "@app/state/store/report/dictionary/pick";
import abbreviationStore from "@app/state/store/report/abbreviation/pick";
import citationsStore from "@app/state/store/report/citation/pick";
import commentStore from "@app/state/store/report-document/comment";
import documentStore from "@app/state/store/report-document/document";
import captisLinkStore from "@app/state/store/report-document/captis-link";

import ReportDocumentSidebar from "@app/components/report-document/sidebar";
import setup from "@app/state/store/projects/setup";

import "./report-document.scoped.scss";
import Loader from "@app/components/loader/content";

const ReportDocument = observer(() => {
    const { project, reportDocumentId } = useParams();
    const history = useHistory();
    const [editing, setEditing] = useState(null);
    const [loading, setLoading] = useState(false);
    const readonly = setup.isComplete;

    const reportDocument = reportDocumentsStore.reportDocument;
    const canAccess = reportDocumentsStore.canAccess(reportDocument);

    useEffect(() => {
        load();

        events.on("reportDocument.viewer.reload", () => {
            load();
        });

        events.on("reportDocument.toc.reload", async () => {
            await reportDocumentsStore.getReportDocumentSections(reportDocumentId);
        });

        events.on("reportDocument.viewer.reload.metaData", async () => {
            await reportDocumentsStore.updateCurrentReportDocumentMetaData(reportDocumentId);
        });

        return () => {
            documentStore.reset();
            commentStore.reset();
            abbreviationStore.resetUsage();
            captisLinkStore.reset();

            events.removeAllListeners(["reportDocument.viewer.reload"]);
            events.removeAllListeners(["reportDocument.toc.reload"]);
            events.removeAllListeners(["reportDocument.viewer.reload.metaData"]);
        };
    }, [project, reportDocumentId]);

    if (!reportDocument || !canAccess) {
        return null;
    }

    async function load() {
        setLoading(true);

        if (project) {
            reportDocumentsStore.setProjectId(project);
            await setup.load(project);
        }

        if (reportDocumentId) {
            sectionsStore.section = null;
            sectionsStore.sections = [];

            await reportDocumentsStore.load(reportDocumentId);

            if (reportDocumentsStore.reportDocument) {
                sectionsStore.sections = reportDocumentsStore.reportDocumentSections;

                documentStore.init(reportDocumentId, sectionsStore.sections);

                await dictionaryStore.load();
                await abbreviationStore.load();
                await commentStore.load(reportDocumentId);
                await citationsStore.load();
                await captisLinkStore.load();
            }
        }

        setLoading(false);
    }

    const close = () => {
        history.push(`../documents`);
    };

    const userReviewer = reportDocumentsStore.getUserReviewer(
        reportDocument.review?.stage?.reviewers,
    );
    const reviewStage = reportDocument.review?.stage;
    const reviewStarted = reviewStage?.title;
    const hasReviewers = reportDocument.review?.stage?.reviewers?.length > 0;

    const review = async () => {
        if (reviewStarted) {
            // check if any the sections have not been reviewed by the user
            const anySectionNotReviewed = reportDocumentsStore.reportDocumentSections?.findIndex(
                (section) => {
                    const sectionUserReviewer = reportDocumentsStore.getUserReviewer(
                        section.reviewers,
                    );

                    return !sectionUserReviewer.reviewed;
                },
            );

            if (anySectionNotReviewed >= 0) {
                let proceed = await confirm(
                    "There are sections not yet reviewed, do you want to proceed?",
                );

                if (!proceed) {
                    return;
                }
            }
        }

        await reportDocumentsStore.review(reportDocumentId);
    };

    const onMenu = async (action) => {
        switch (action.key) {
            case "versionHistory":
                history.push(`../documents/${reportDocumentId}/history`);
                break;
            case "startReview":
                await review();
                break;
            default:
                return;
        }
    };

    const menuItems = (
        <Menu onClick={onMenu} className="menu">
            {[ReportDocumentStatus.NOT_STARTED, ReportDocumentStatus.IN_PROGRESS].includes(
                reportDocument.status,
            ) &&
                !reviewStarted &&
                !readonly &&
                reportDocumentsStore.canUserStartReview && (
                    <Menu.Item key="startReview" icon={<EyeOutlined />}>
                        Send document for review
                    </Menu.Item>
                )}
            <Menu.Item key="versionHistory" icon={<HistoryOutlined />}>
                Version history
            </Menu.Item>
        </Menu>
    );

    const baseURL = `/api/project/${project}/report-documents/${reportDocumentId}`;
    const exportMenu = (
        <Menu>
            <Menu.Item key="word" icon={<FileWordOutlined />}>
                <a href={`${baseURL}/export?format=word`} target="_blank" rel="noopener noreferrer">
                    Word
                </a>
            </Menu.Item>
            <Menu.Item key="word" icon={<FileWordOutlined />}>
                <a
                    href={`${baseURL}/export?format=wordplus`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Word + Comments
                </a>
            </Menu.Item>
            <Menu.Item key="refs" icon={<FileZipOutlined />}>
                <a href={`${baseURL}/refPack`} target="_blank" rel="noopener noreferrer">
                    Reference Pack
                </a>
            </Menu.Item>
        </Menu>
    );

    return (
        <Page className={"report-document"}>
            <Page.Header closable={true} onClose={close} className={"report-document-header"}>
                <Page.Header.Left shrink>
                    <Page.Title>
                        <div>{reportDocument?.name}</div>
                    </Page.Title>
                </Page.Header.Left>
                <Page.Header.Right>
                    {reportDocument.status === ReportDocumentStatus.IN_PROGRESS && (
                        <>
                            {reviewStage?.title && (
                                <label>
                                    Current review stage:
                                    <b> {reviewStage.title}</b>
                                </label>
                            )}

                            {reviewStage?.reviewers?.length > 0 && (
                                <>
                                    <div className={"vl"} />
                                    <Status.AssignedUsers
                                        users={reportDocument.metaData?.currentStage?.reviewers}
                                    />
                                </>
                            )}
                        </>
                    )}

                    {reportDocument.status === ReportDocumentStatus.IN_PROGRESS &&
                        userReviewer &&
                        (userReviewer.reviewed ? (
                            <Button type={"primary"} icon={<CheckOutlined />}>
                                Review completed
                            </Button>
                        ) : (
                            !readonly && (
                                <Button type={"primary"} icon={<EyeOutlined />} onClick={review}>
                                    Mark as reviewed
                                </Button>
                            )
                        ))}

                    {reportDocument.status === ReportDocumentStatus.REVIEWED && (
                        <label className="allReivewsCompleted">All reviews are completed</label>
                    )}

                    {reviewStarted && !hasReviewers && (
                        <label className="noReviewers">Please setup reviewers</label>
                    )}

                    <Dropdown overlay={exportMenu} trigger="click" placement="bottomRight">
                        <Button>
                            Export <DownOutlined />
                        </Button>
                    </Dropdown>

                    {antdHelpers.hasMenuItems(menuItems) && (
                        <Dropdown overlay={menuItems} trigger="click" placement="bottomRight">
                            <Button
                                type="default"
                                icon={<EllipsisOutlined />}
                                style={{ border: "none" }}
                            />
                        </Dropdown>
                    )}
                </Page.Header.Right>
            </Page.Header>
            <Page.Layout>
                <Page.Body padding={false}>
                    {loading && (
                        <div className="loader">
                            <Loader loading={true} />
                        </div>
                    )}
                    {!loading && (
                        <Viewer
                            sections={sectionsStore.sections}
                            dictionary={dictionaryStore}
                            citations={citationsStore}
                            abbreviations={abbreviationStore}
                            editingState={[editing, setEditing]}
                            onReload={load}
                            readonly={readonly}
                            documentId={reportDocument._id}
                        />
                    )}
                </Page.Body>
                <ReportDocumentSidebar width={350} readonly={readonly} />
            </Page.Layout>
        </Page>
    );
});

ReportDocument.displayName = "ReportDocument";

export default ReportDocument;
