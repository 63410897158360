import React from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import Page from "@app/components/page/page";
import StylesOverview from "@app/components/setup/report-documents/styles/overview";

import client from "@app/state/store/clients/details";

const ReportDocuments = observer(() => {
    const history = useHistory();
    const config = client?.config;

    if (!config) {
        return;
    }

    const close = () => {
        history.push("../setup");
    };

    const onStyleEdit = () => {
        history.push(`./report-documents/styles`);
    };

    return (
        <Page>
            <Page.Header closable onClose={close}>
                <Page.Header.Left shrink>
                    <Page.Title>Report Documents</Page.Title>
                </Page.Header.Left>

                <Page.Header.Right></Page.Header.Right>
            </Page.Header>

            <Page.Body maxWidth={1200}>
                <StylesOverview config={config} onEdit={onStyleEdit} />
            </Page.Body>
        </Page>
    );
});

export default ReportDocuments;
