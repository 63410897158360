import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import Modal from "@app/components/modal";
import { Input, Table, Tooltip } from "antd";

import store from "@app/state/store/report/original-article";

import "./style/original-article-modal.scoped.scss";

const OriginalArticleModal = observer(
    ({ modalOpen, setModalOpen, articleIds = [], afterSelect, type }) => {
        const [search, setSearch] = useState();
        const [tableData, setTableData] = useState([]);
        const [selectedOriginalArticle, setSelectedOriginalArticle] = useState();

        const { Search } = Input;

        useEffect(() => {
            if (modalOpen) {
                store.findArticles(articleIds);
                store.getOriginalArticleSuggestions({
                    search,
                    duplicateArticleIds: articleIds,
                    type,
                });
            }
        }, [modalOpen, articleIds, search]);

        useEffect(() => {
            setTableData(buildTableData(store.suggestedArticles, store.searchArticles));
        }, [store.suggestedArticles, store.searchArticles]);

        const closeModal = () => {
            setModalOpen(false);
        };

        const onSearch = (searchTerm) => {
            setSearch(searchTerm);
        };

        const onSelect = async () => {
            if (selectedOriginalArticle) {
                await store.setOriginalArticle({
                    originalArticleId: selectedOriginalArticle,
                    duplicateArticleIds: articleIds,
                });

                afterSelect && (await afterSelect());
            }
            closeModal();
        };

        const tableColumns = [
            {
                title: "Article",
                dataIndex: "article",
            },
        ];

        const buildTableData = (suggestedArticles, searchArticles) => {
            const buildRow = (article, suggested = false) => {
                return {
                    key: article._id,
                    article: (
                        <div>
                            {suggested && <div className="suggestedBadge">Suggested</div>}
                            <span>
                                C{article.mdrId} - {article.title}
                            </span>
                            <br />
                            <span>
                                <b>{`Author(s): `}</b>
                                {article.authors
                                    .map((item) => `${item.firstName} ${item.lastName}`)
                                    .join(", ")}
                            </span>
                        </div>
                    ),
                };
            };

            return [
                ...suggestedArticles.map((article) => buildRow(article, true)),
                ...searchArticles.map((article) => buildRow(article)),
            ];
        };

        return (
            <Modal
                title={`Select Original Article`}
                visible={modalOpen}
                onOk={onSelect}
                onCancel={closeModal}
                okButtonProps={{
                    disabled: !selectedOriginalArticle,
                    type: "primary",
                    role: "btn-ok",
                }}
                width={1000}
            >
                <div className="selectedArticles">
                    <b>{`Selected Article(s): `}</b>
                    {store.selectedArticles.map((article) => {
                        return (
                            <Tooltip placement="bottomLeft" title={article.title}>
                                <label className="selectedArticleBadge">C{article.mdrId}</label>
                            </Tooltip>
                        );
                    })}
                </div>
                <Search
                    placeHolder="Search original article"
                    onSearch={onSearch}
                    style={{ margin: "10px 0" }}
                    allowClear={true}
                />
                <div>
                    <Table
                        rowSelection={{
                            type: "radio",
                            onChange: (selectedRowKeys, selectedRows) => {
                                setSelectedOriginalArticle(selectedRowKeys[0]);
                            },
                        }}
                        columns={tableColumns}
                        dataSource={tableData}
                        loading={store.loading || store.saving}
                        showHeader={false}
                        scroll={{ x: 0, y: 450 }}
                    />
                </div>
            </Modal>
        );
    },
);

export default OriginalArticleModal;
