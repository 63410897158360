import React from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import ReportWrapper from "./report-wrapper";
import EmptyList from "@app/components/list/empty";
import state from "@app/state/store/report/output/slr/exclusion";

const ReportPage = () => <ReportWrapper name="L1 Exclusion Summary" component={<Report />} />;

const Report = observer(() => {
    const { type } = useParams();

    React.useEffect(() => {
        state.load(type);
    }, [type]);

    const { list, stats, loading } = state;

    return (
        <>
            <EmptyList title="No Records Found" show={list.length === 0 && !loading}></EmptyList>
            {list.length !== 0 && (
                <>
                    <table>
                        <colgroup>
                            <col />
                            <col />
                            <col width="90" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th className="center">S. No.</th>
                                <th>Exclusion Criteria</th>
                                <th className="center">Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((exclusion, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="center">{index + 1}</td>
                                        <td>{exclusion.reason}</td>
                                        <td className="center">{exclusion.count}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                    <table className="summary">
                        <colgroup>
                            <col />
                            <col width="90" />
                        </colgroup>
                        <tbody>
                            <tr>
                                <td>Total Abstracts/articles from Databases</td>
                                <td className="center">{stats.total}</td>
                            </tr>
                            <tr>
                                <td>Total Abstracts/articles Excluded at L1</td>
                                <td className="center">{stats.excluded}</td>
                            </tr>
                        </tbody>
                    </table>
                </>
            )}
        </>
    );
});

export default ReportPage;
