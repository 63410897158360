import _ from "lodash";
import classNames from "classnames";

import { default as React, useEffect } from "react";
import { observer, useLocalStore } from "mobx-react";
import { v4 as uuid } from "uuid";
import { Form, Input, Select, Button, Tooltip } from "antd";
import { DeleteOutlined, PlusOutlined, SyncOutlined, CloudUploadOutlined } from "@ant-design/icons";
import UploadButton from "@app/components/form/upload/button";

import Modal from "@app/components/modal";
import List from "@app/components/list/ordered";
import notify from "@app/components/notify";
import format from "@app/lib/format";
import confirm from "@app/components/confirm/index";

import { ReactComponent as DragIcon } from "@app/assets/icons/drag-handle.svg";

import "./style/citation-modal.scoped.scss";

const MIN_YEAR = 1800;
const MAX_YEAR = new Date().getFullYear();

export const CitationModal = observer(({ open, citation, onSubmit, onCancel, onFetch }) => {
    const [form] = Form.useForm();
    const state = useLocalStore(() => ({
        authors: [],
        lookupId: undefined,
        lookupLoading: false,
        file: undefined,
    }));

    useEffect(() => {
        if (citation) {
            loadCitation(citation);
        }

        // add an empty row
        addAuthor();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, citation]);

    /**
     * Load the citation data into the form
     */
    const loadCitation = (citation) => {
        form.setFieldsValue({
            title: citation.title,
            journalName: citation.journalName,
            journalIssue: citation.journalIssue,
            journalVolume: citation.journalVolume,
            publicationYear: citation.publicationYear,
            publicationMonth: citation.publicationMonth,
            pageNumbers: citation.pageNumbers,
            doi: citation.doi,
        });

        state.authors = _.cloneDeep(citation.authors).map((el) => {
            el.$key = uuid();
            el.$error = {};

            return el;
        });

        state.file = citation.file;
    };

    /**
     * Show the validation error
     */
    const error = () => {
        notify.error("Please fill all required fields to continue");
    };

    /**
     * Cancel the edit
     */
    const cancel = () => {
        onCancel && onCancel();
        reset();
    };

    /**
     * Save the value
     */
    const save = (data) => {
        let valid = true;
        const changed = {};

        // process the title
        changed.title = (data.title || "").replace(/\s{2,}/g, " ").replace(/\n/, " ");
        // process the source information
        changed.journalName = (data.journalName || "").trim();
        changed.journalIssue = (data.journalIssue || "").trim();
        changed.journalVolume = (data.journalVolume || "").trim();
        changed.pageNumbers = (data.pageNumbers || "").trim();
        changed.doi = (data.doi || "").trim();
        changed.publicationYear = data.publicationYear ? Number(data.publicationYear) : undefined;
        changed.publicationMonth = data.publicationMonth
            ? Number(data.publicationMonth)
            : undefined;

        // validate the values
        changed.authors = state.authors
            .map((entry) => {
                entry.firstName = entry.firstName ? entry.firstName.trim() : "";
                entry.lastName = entry.lastName ? entry.lastName.trim() : "";
                return entry;
            })

            // remove the empty values
            .filter((entry) => {
                return entry.firstName || entry.lastName;
            })

            // check for valid properties
            .map((entry) => {
                entry.$error = {};

                for (let prop of ["firstName", "lastName"]) {
                    let value = entry[prop];

                    if (!value) {
                        entry.$error[prop] = true;
                        valid = false;
                    }
                }

                return entry;
            });

        // check for valid authors
        if (!valid) {
            return error();
        }

        changed.file = state.file;

        onSubmit && onSubmit(changed);
        reset();
    };

    /**
     * Add a new author to the list
     */
    const addAuthor = () => {
        state.authors.push({
            $key: uuid(),
            $new: true,
            $error: {},
        });
    };

    /**
     * Remove an entry from the list
     */
    const removeAuthor = async (entry) => {
        let proceed = true;

        // Confirm it only if the user removes existing author. In order to reduce
        // the noise with constant confirmations, we are confirming only when the user
        // tries to delete one of existing authors, so they don't remove it by mistake.
        if (!entry.$new) {
            proceed = await confirm("Are you sure you want to remove the author?");
        }

        if (proceed) {
            let idx = state.authors.indexOf(entry);
            state.authors.splice(idx, 1);
        }
    };

    /**
     * Reorder the list
     */
    const reorderAuthors = (action) => {
        let [item] = state.authors.splice(action.from, 1);
        state.authors.splice(action.to, 0, item);
    };

    /**
     * Update a field value
     */
    const setAuthorValue = (entry, field, value) => {
        entry[field] = value;
        entry.$error[field] = false;

        let idx = state.authors.indexOf(entry);
        if (idx === state.authors.length - 1) {
            addAuthor();
        }
    };

    /**
     * Update the value of the lookup id
     */
    const onIdChange = (event) => {
        state.lookupId = String(event.target.value ?? "").trim();
    };

    /**
     * Handle the new file upload event
     */
    const onUpload = (data) => {
        state.file = data._id;
    };

    /**
     * Reset the form
     */
    const reset = () => {
        form.resetFields();
        state.authors = [];
        state.lookupId = undefined;
        state.file = undefined;

        addAuthor();
    };

    /**
     * Load the reference details based on supplied ID
     */
    const lookupArticle = async () => {
        if (!state.lookupId || !String(state.lookupId).trim()) {
            notify.error("Please enter a valid PubMed ID or DOI to continue");
            return;
        }

        // detect the ID type
        let isPMID = false;
        let isDOI = false;

        if (state.lookupId.match(/^\d{6,}$/)) {
            isPMID = true;
        }

        if (state.lookupId.match(/^10.\d{4}\/.*$/i)) {
            isDOI = true;
        }

        if (!isDOI && !isPMID) {
            notify.error("Please enter a valid PubMed ID or DOI to continue");
            return;
        }

        state.lookupLoading = true;
        let article = await onFetch({ type: isPMID ? "pmid" : "doi", id: state.lookupId });
        if (article) {
            loadCitation({
                ...article,
                doi: article.ids?.doi,
            });
        } else {
            notify.warn("Could not find an article with the specified ID");
        }

        state.lookupLoading = false;
    };

    const title = citation?._id ? "Edit Reference" : "Add Reference";

    return (
        <Modal
            title={title}
            visible={open}
            okText="Save"
            onOk={form.submit.bind(form)}
            onCancel={cancel}
            width={800}
        >
            <div className="edit-article">
                <Form layout="vertical" form={form} onFinishFailed={error} onFinish={save}>
                    <div className="mb-2">
                        You can automatically load the reference information using the PubMed ID or
                        DOI below.
                    </div>
                    <div className="row gap load-data">
                        <div className="col">
                            <Form.Item label="PubMed ID or DOI">
                                <Input
                                    placeholder="Please enter article ID and click the load button"
                                    value={state.lookupId}
                                    onInput={onIdChange}
                                />
                            </Form.Item>
                        </div>
                        <div className="col auto load-button">
                            <Tooltip title="Load article data">
                                <Button
                                    icon={<SyncOutlined />}
                                    onClick={lookupArticle}
                                    loading={state.lookupLoading}
                                />
                            </Tooltip>
                        </div>
                    </div>

                    <div className="section">
                        Full Text
                        <div className="buttons">
                            <UploadButton
                                type="icon"
                                accept="application/pdf"
                                onChange={onUpload}
                                icon={<CloudUploadOutlined />}
                            ></UploadButton>
                        </div>
                    </div>
                    <div className="full-text">
                        {!state.file && <div className="missing-file">Missing full-text file</div>}
                        {state.file && (
                            <div className="file">
                                <a href={format.file(state.file)} target="_blank" rel="noreferrer">
                                    Download full-text file
                                </a>
                            </div>
                        )}
                    </div>

                    <div className="section">Title</div>
                    <Form.Item
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: "Please enter reference title",
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <div className="section">Publication Details</div>
                    <Form.Item
                        label="Journal Name (publication source)"
                        name="journalName"
                        rules={[
                            {
                                required: true,
                                message: "Please enter reference journal name",
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <div className="row gap">
                        <div className="col">
                            <Form.Item label="Journal Volume" name="journalVolume">
                                <Input />
                            </Form.Item>
                        </div>
                        <div className="col">
                            <Form.Item label="Journal Issue" name="journalIssue">
                                <Input />
                            </Form.Item>
                        </div>
                        <div className="col">
                            <Form.Item label="DOI" name="doi">
                                <Input />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row gap">
                        <div className="col">
                            <Form.Item
                                label="Publication Year"
                                name="publicationYear"
                                rules={[
                                    {
                                        message: "Invalid publication year",
                                        validator(_, value) {
                                            // do not allow empty value
                                            if (!value) {
                                                return Promise.resolve();
                                            }

                                            // convert to number and check if within the range
                                            value = Number(value);
                                            if (value < MIN_YEAR || value > MAX_YEAR) {
                                                return Promise.reject(
                                                    new Error(
                                                        `The value should be between ${MIN_YEAR} and ${MAX_YEAR}!`,
                                                    ),
                                                );
                                            }

                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                            >
                                <Input type="number" />
                            </Form.Item>
                        </div>
                        <div className="col">
                            <Form.Item label="Publication Month" name="publicationMonth">
                                <Select allowClear>
                                    <Select.Option value={1}>January</Select.Option>
                                    <Select.Option value={2}>February</Select.Option>
                                    <Select.Option value={3}>March</Select.Option>
                                    <Select.Option value={4}>April</Select.Option>
                                    <Select.Option value={5}>May</Select.Option>
                                    <Select.Option value={6}>June</Select.Option>
                                    <Select.Option value={7}>July</Select.Option>
                                    <Select.Option value={8}>August</Select.Option>
                                    <Select.Option value={9}>September</Select.Option>
                                    <Select.Option value={10}>October</Select.Option>
                                    <Select.Option value={11}>November</Select.Option>
                                    <Select.Option value={12}>December</Select.Option>
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col">
                            <Form.Item label="Page Numbers" name="pageNumbers">
                                <Input />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="section">Authors</div>
                    <div className="authors">
                        <div className="header">
                            <div className="col empty"></div>
                            <div className="col first">First Name</div>
                            <div className="col last">Last Name</div>
                            <div className="buttons">
                                <Button icon={<PlusOutlined />} type="icon" onClick={addAuthor} />
                            </div>
                        </div>
                        <div className="body">
                            <List onChange={reorderAuthors}>
                                {state.authors.map((author, index) => {
                                    return (
                                        <List.Item key={author.$key} id={author.$key} index={index}>
                                            <div className="row entry" key={author.$key}>
                                                <div className="col handle">
                                                    <DragIcon />
                                                </div>
                                                <div
                                                    className={classNames("col first", {
                                                        invalid: author.$error?.firstName,
                                                    })}
                                                >
                                                    <Input
                                                        value={author.firstName}
                                                        onChange={(e) =>
                                                            setAuthorValue(
                                                                author,
                                                                "firstName",
                                                                e.target.value,
                                                            )
                                                        }
                                                    />
                                                </div>

                                                <div
                                                    className={classNames("col last", {
                                                        invalid: author.$error?.lastName,
                                                    })}
                                                >
                                                    <Input
                                                        value={author.lastName}
                                                        onChange={(e) =>
                                                            setAuthorValue(
                                                                author,
                                                                "lastName",
                                                                e.target.value,
                                                            )
                                                        }
                                                    />
                                                </div>

                                                <div className="col remove">
                                                    <Button
                                                        type="icon"
                                                        icon={<DeleteOutlined />}
                                                        onClick={() => removeAuthor(author)}
                                                    />
                                                </div>
                                            </div>
                                        </List.Item>
                                    );
                                })}
                            </List>
                        </div>
                    </div>
                </Form>
            </div>
        </Modal>
    );
});

export default CitationModal;
