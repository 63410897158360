import React from "react";
import { observer } from "mobx-react";
import { Switch } from "antd";

import Group from "../group";
import liveWarn from "../warn";

const ReviewToggles = observer(
    ({ config, hasScore = true, onChange, readonly = false, warn = true }) => {
        const warnFn = warn ? liveWarn : (msg, cb) => cb();

        return (
            <Group title="Review Configuration">
                {hasScore && (
                    <div className="row toggle items-center ml-15 mr-15 mb-15">
                        <div className="col auto">
                            <Switch
                                checked={config.enableScore}
                                disabled={readonly}
                                onChange={(value) => {
                                    warnFn(liveWarn.review, () => {
                                        onChange({ enableScore: value });
                                    });
                                }}
                            />
                        </div>
                        <div className="col ml-15">Enable score based qualification</div>
                    </div>
                )}
                <div className="row toggle items-center ml-15 mr-15">
                    <div className="col auto">
                        <Switch
                            checked={config.requireAnswers}
                            disabled={readonly}
                            onChange={(value) => {
                                warnFn(liveWarn.review, () => {
                                    onChange({ requireAnswers: value });
                                });
                            }}
                        />
                    </div>
                    <div className="col ml-15">Require an answer for every questions</div>
                </div>
            </Group>
        );
    },
);

export default ReviewToggles;
