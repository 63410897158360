import React from "react";
import { observer } from "mobx-react";
import Modal from "@app/components/modal";

import "./legal.scoped.scss";

const PrivacyModal = observer(
    ({ visible, onOk, onCancel, closable = true, footer, width = "60rem", height = "50rem" }) => {
        return (
            <Modal
                bodyStyle={{ overflow: "auto", width, height }}
                title={`Privacy Policy`}
                visible={visible}
                closable={closable}
                okText="Accept"
                cancelText="Decline"
                onOk={onOk}
                onCancel={onCancel}
                footer={footer}
                width={width}
                height={height}
            >
                <div className="legal">
                    <p>
                        <b>Version Date:</b> October 6, 2021
                    </p>
                    <p>
                        At Celegence, privacy is a priority. The purpose of this document is to set
                        out how Celegence, LLC and its affiliates (“us,” “our,” or “we”) collect,
                        use, store, or otherwise process personal information about customers and
                        other individuals (collectively "you") who access or use our websites,
                        including Celegence.com, our mobile applications, our web client or
                        professional client, and/or any of our other websites, products, or services
                        that link to this Privacy Notice (the “Services”). By using our Services,
                        you understand that we will collect and use your personal information as
                        described in this Privacy Notice.
                    </p>
                    <p>
                        In some cases, we may process your personal information pursuant to an
                        agreement with a third party organization. In those cases, the terms of that
                        agreement may govern how we process your personal information. If you
                        believe a third party organization has asked us to process your personal
                        information on their behalf, please consult with them in the first instance
                        as they will be responsible for how we process your information. This
                        Privacy Notice (“Notice”) does not apply to any third-party websites and
                        apps that you may use, including those to which we link in our Services. You
                        should review the terms and policies for third-party websites and apps
                        before clicking on any links.
                    </p>
                    <p>
                        We recommend that you read this Notice in full to ensure you are fully
                        informed about the manner in which we collect, use, store, or otherwise
                        process your personal information as well as your privacy rights.
                    </p>
                    <p>1. Collection of Personal Information</p>
                    <p>
                        You have choices about whether you visit our websites, install our apps, or
                        provide personal information to us.{" "}
                    </p>
                    <p>
                        <b>Personal Information We Collect from You.</b> You provide us with
                        personal information about yourself when you:
                    </p>

                    <ul className="list">
                        <li>Register or log in to your account.</li>
                        <li>Create or edit your user profile.</li>
                        <li>Contact customer support.</li>
                        <li>
                            Participate in surveys, sponsored events, sweepstakes, or when you sign
                            up for newsletters.
                        </li>
                    </ul>

                    <p>
                        You also provide us with personal information about others when you use
                        parts of our Services, such as when you:
                    </p>
                    <ul className="list">
                        <li>Add others as a member to an existing account.</li>
                        <li>Leave comments.</li>
                    </ul>
                    <p>Examples of the categories of personal information you may provide are:</p>
                    <ul className="list">
                        <li>
                            <b>Identifiers and contact information.</b> This includes your name,
                            email address, mailing address, phone number
                        </li>
                        <li>
                            <b>Commercial information.</b> This includes billing and payment
                            information (e.g., credit card number, expiration date, visual
                            cryptogram), products or services purchased.
                        </li>
                        <li>
                            <b>Geolocation.</b> This includes physical location.
                        </li>
                    </ul>
                    <p>
                        <b>Personal Information We Collect Automatically.</b> We may automatically
                        collect personal information from you and your devices when you use our
                        Services, including when you visit our websites or apps without logging in.
                        For choices you may have on what information we automatically collect,
                        please see Section ­­5 of this Privacy Notice.
                    </p>
                    <p>
                        The categories of personal information we may automatically collect
                        includes:
                    </p>
                    <ul className="list">
                        <li>
                            <b>Device, Usage Information, and Transactional Data.</b> We collect
                            personal information about how you use our Services and the devices
                            (e.g., computers, mobile phones, tablets) you use to access our
                            Services. This may include, but is not limited to, the following:
                            <ul className="list">
                                <li>IP address.</li>
                                <li>
                                    Precise geolocation information that you allow our apps to
                                    access (usually from your mobile device).
                                </li>
                                <li>
                                    Unique device identifiers and device attributes, such as
                                    operating system and browser type.
                                </li>
                                <li>
                                    Usage data, such as web log data, referring and exit pages and
                                    URLs, platform type, number of clicks, domain names, landing
                                    pages, pages and content viewed and the order of those pages,
                                    the amount of time spent on particular pages, the date(s) and
                                    time(s) you used our Services, the frequency of your use of our
                                    Services, error logs, and other related information.
                                </li>
                                <li>
                                    Transactional data, such as names and email addresses of parties
                                    to a transaction, subject line, history of actions that
                                    individuals take in connection with a transaction (e.g., review,
                                    sign, enable features) and personal information about those
                                    individuals or their devices, such as name, email address, IP
                                    address, and authentication methods.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>Cookies and Related Technologies.</b> We use cookies, which are text
                            files containing small amounts of information that are downloaded on
                            your device, or related technologies, such as web beacons, local shared
                            objects and tracking pixels, to collect and/or store information.
                        </li>
                    </ul>

                    <p>
                        <b>Personal Information We Collect and Process on Behalf of Customers.</b>
                        When our business customers use certain Services, we generally process and
                        store limited personal information on their behalf as a data processor. For
                        example, in the context of CAPTIS, when a customer uploads documents, we act
                        as a data processor and process the documents on the customer's behalf and
                        in accordance with their instructions. In those instances, the customer is
                        the data controller and is responsible for most aspects of the processing of
                        the personal information. If you have any questions or concerns about how
                        personal information is processed in these cases, including how to exercise
                        your rights as a data subject, you should contact your employer. If we
                        receive any rights requests concerning instances where we act as data
                        processor, we will forward your query on to the relevant customer.
                    </p>
                    <p>2. Use of Personal Information</p>
                    <p>
                        In general, and subject to applicable law, including consent (as required),
                        we may use your personal information to provide, fix, and improve our
                        Services, develop new Services, and market our companies and their products
                        and Services. Examples of how we use the personal information we process
                        include, but are not limited to, the following:
                    </p>
                    <ul className="list">
                        <li>
                            Provide you with and collect payment for the products and Services and
                            products you request.
                        </li>
                        <li>
                            Create your account and manage your relationship with us (e.g.,
                            communicating with you, providing you with requested information).
                        </li>
                        <li>
                            Send you records of our relationship, including for purchases or other
                            events.
                        </li>
                        <li>
                            Market features, products, or special events using email or phone or
                            send you marketing communications about third party products and
                            services we think may be of interest to you.
                        </li>
                        <li>
                            Choose and deliver content and tailored advertising, and support the
                            marketing and advertising of our Services.
                        </li>
                        <li>
                            Create and review data about our users and how they use our Services.
                        </li>
                        <li>Test changes in our Services and develop new features and products.</li>
                        <li>
                            Fix problems you may have with our Services, including answering support
                            questions, customer education and training, and resolving disputes.
                        </li>
                        <li>
                            Manage the Services platform, including support systems and security.
                        </li>
                        <li>
                            Prevent, investigate and respond to fraud, unauthorized access to or use
                            of our Services, breaches of terms and policies, or other wrongful
                            behavior.
                        </li>
                        <li>Comply with legal obligations.</li>
                        <li>Meet legal retention periods.</li>
                        <li>Establish, exercise, or defend our rights in legal claims.</li>
                    </ul>

                    <p>
                        <b>Other Uses.</b> We may combine the personal information we collect
                        (“aggregate”) or remove pieces of personal information (“de-identify”) to
                        limit or prevent identification of any particular user or device to help
                        with goals like research and marketing. Once such information has been
                        aggregated and anonymized so that it is no longer considered personal
                        information under applicable data protection law, this Notice does not
                        apply.
                    </p>
                    <p>
                        <b>Lawful Basis for Processing Your Personal Information.</b> If European
                        data protection law applies and where Celegence acts as a data controller,
                        our lawful basis for collecting and using the personal information described
                        in this Notice will depend on the type of personal information concerned and
                        the specific context in which we collect or use it.
                    </p>
                    <p>
                        We normally collect or use personal information only where we have your
                        consent to do so, where we need the personal information to perform a
                        contract with you, or where the processing is in our legitimate interests
                        and not overridden by your data protection interests or fundamental rights
                        and freedoms. In some cases, we may have a legal obligation to collect or
                        retain personal information or may need the personal information to protect
                        your vital interests or those of another person. For example, when we:
                    </p>
                    <ul className="list">
                        <li>
                            Use personal information to create and manage an account, we do so in
                            order to provide you with relevant Services and perform our contract
                            with you.
                        </li>
                        <li>
                            Use names and email addresses for email marketing purposes, we do so
                            with your consent (which you can withdraw at any time) or, where
                            permitted under applicable law, on the basis of our legitimate
                            interests.
                        </li>
                        <li>
                            Gather usage data and analyze it to improve our Services or ensure the
                            security of our websites, we do so based on our legitimate interest in
                            safeguarding and improving our Services.
                        </li>
                    </ul>

                    <p>
                        If you have questions about or need further information concerning the
                        lawful basis on which we collect and use your personal information, please
                        contact us. Note that in situations where Celegence acts as a processor, it
                        is our customer who determines the appropriate legal basis associated with
                        processing activities, and queries about the applicable lawful basis should
                        be directed to them.
                    </p>

                    <p>3. Personal Information Sharing</p>
                    <p>
                        Subject to applicable law, including consent (as required), we may share
                        personal information as follows:
                    </p>
                    <ul className="list">
                        <li>
                            <b>Service Providers.</b> We share your personal information with
                            service providers we use to support our Services. These companies
                            provide services like intelligent search technology, intelligent
                            analytics, advertising, authentication systems, bill collection, fraud
                            detection, and customer support. We have contracts with our service
                            providers that address the safeguarding and proper use of your personal
                            information.
                        </li>
                        <li>
                            <b>Affiliates.</b> We may share your personal information with other
                            companies under common ownership or control with Celegence. These
                            companies use your personal information as described in this Notice.
                        </li>
                        <li>
                            <b>Public or Government Authorities.</b> We may share your personal
                            information to comply with our legal obligations, regulations, or
                            contracts, or to respond to a court order, administrative, or judicial
                            process, such as a subpoena, government audit, or search warrant where
                            we are legally compelled to do so. We also may share your information
                            when there are threats to the physical safety of any person, violations
                            of Celegence policies or other agreements, or to protect the legal
                            rights of third parties, including our employees, users, or the public.
                        </li>
                        <li>
                            <b>Corporate Transactions.</b> Your personal information may be
                            disclosed or transferred to relevant third parties in the event of, or
                            as part of the due diligence for, any proposed or actual reorganization,
                            sale, merger, consolidation, joint venture, assignment, transfer, or
                            other disposition of all or part of our business, assets, or stock
                            (including in connection with any bankruptcy or similar proceeding). If
                            a corporate transaction occurs, we will provide notification of any
                            changes to the control of your information, as well as choices you may
                            have.
                        </li>
                        <li>
                            <b>Consent.</b> We may share your personal information in other ways if
                            you have asked us to do so or have given consent. For example, with your
                            consent, we post user testimonials that may identify you.
                        </li>
                    </ul>
                    <p>Your personal information may also be shared as described below:</p>
                    <ul className="list">
                        <li>
                            <b>Other CAPTIS users.</b> When you allow others to access, use, or edit
                            content in your account, we share that content with them.
                        </li>
                        <li>
                            <b>Third Parties.</b> When you make a payment to another user within our
                            Services, we share your payment method details with the third-party
                            payment processor selected by you.
                        </li>
                        <li>
                            <b>Public Information.</b>
                            <ul className="list">
                                <li>
                                    <b>Profile Information.</b> When you create a CAPTIS profile,
                                    other Celegence users can view your profile information. If you
                                    would like to make this information private, please visit your
                                    account settings.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>Your Employer or Organization.</b> When you create an account or user
                            role with an email address assigned to you as an employee, contractor or
                            member of an organization (e.g., yourname@youremployer.com or
                            yourname@nonprofit.org), that organization (if it is a Celegence
                            customer with certain features) can find your account and take certain
                            actions that may affect your account.
                        </li>
                    </ul>

                    <p>4. Retention of Personal Information</p>
                    <p>
                        We keep your personal information for no longer than necessary for the
                        purposes for which it is processed. The length of time for which we retain
                        personal information depends on the purposes for which we collected and use
                        it and/or as required to comply with applicable laws as set out in our data
                        retention policy and information handling standards. Generally, this means
                        we retain your personal information to comply with any retention or
                        statutory limitations or for purposes of performing a contract with you.
                        Where there are technical limitations that prevent deletion or
                        anonymization, we safeguard personal information and limit active use of it.
                    </p>

                    <p>5. Your Choices</p>
                    <p>
                        This section describes many of the actions you can take to change or limit
                        the collection, use, storage, or other processing of your personal
                        information.
                    </p>
                    <ul className="list">
                        <li>
                            <b>Profile.</b> You are not required to fill out a profile. If you do,
                            you can access and review this personal information. If any personal
                            information is inaccurate or incomplete, you can make changes in your
                            account settings.
                        </li>
                        <li>
                            <b>Marketing Messages.</b> You can opt out of email marketing messages
                            we send you by clicking on the “unsubscribe” link in the email message
                            or unsubscribe here. Please note that we may send you one message to
                            confirm you want to opt out. If you are a registered user of our
                            Services, or if you have engaged in transactions with us, we may
                            continue to send transactional or relationship messages (e.g., signing
                            notifications or account notifications) after you opt out of marketing
                            messages.
                        </li>
                        <li>
                            <b>Cookies and Other Related Technology.</b> You can decline cookies
                            through your browser settings or via the Celegence Cookie Preference
                            Center. However, if you decline cookies, you may not be able to use some
                            parts of our Services. Please note we do not recognize or respond to
                            browser-initiated Do Not Track signals.
                        </li>
                        <li>
                            <b>Device and Usage Information.</b> If you do not want us to see your
                            device location, you can turn off location sharing on your device,
                            change your device privacy settings, or decline to share location on
                            your browser.
                        </li>
                        <li>
                            <b>Closing Your Account.</b> If you wish to close your account, please
                            log in to your account and edit your plan or contact CAPTIS support.
                        </li>
                        <li>
                            <b>Complaints.</b> We are committed to resolving valid complaints about
                            your privacy and our collection, use, storage, or other processing of
                            your personal information. For questions or complaints regarding our
                            data use practices or this Notice, please contact us via Celegence
                            support.
                        </li>
                    </ul>

                    <p>6.Children's Privacy</p>
                    <p>
                        Our Services are not designed for and are not marketed to people under the
                        age of 18 or such other age designated by applicable law (“minors”). We do
                        not knowingly collect or ask for personal information from minors. We do not
                        knowingly allow minors to use our Services. If you are a minor, please do
                        not use our Services or send us your personal information. We delete
                        personal information that we learn is collected from a minor without
                        verified parental consent. Please contact us if you believe we might have
                        personal information from or about a minor that should be removed from our
                        system.
                    </p>

                    <p>7. Information for California Residents</p>
                    <p>
                        In addition to the other information and rights described in this Privacy
                        Notice, California residents are also entitled to certain additional
                        information and have certain additional rights under the California Consumer
                        Privacy Act (“CCPA”) with respect to their personal information. If you are
                        a resident of California (a “Consumer” as defined by the CCPA), this section
                        of the Privacy Notice applies to you. If you are not a Consumer, this
                        section does not apply to you and you should not rely on it.
                    </p>

                    <p>
                        <u>A. Information We Collect and How We Use It</u>
                    </p>
                    <p>
                        Below is a summary of our collection and use of “personal information” (as
                        defined by the CCPA) about Consumers in connection with our Services. For
                        additional details, please consult the other sections of this Privacy
                        Notice.
                    </p>

                    <p>
                        <i>
                            Categories of Personal Information we may collect and use for our
                            commercial and business purposes:
                        </i>
                    </p>
                    <ul className="list">
                        <li>
                            <b>Contact and account registration information.</b> When you register
                            for an account or contact us you may provide your name, email address,
                            physical address, phone number, account username and password, and other
                            related information.
                        </li>
                        <li>
                            <b>Transaction information.</b> When you use the Services to conduct a
                            transaction, we may collect names and email addresses of parties to a
                            transaction, subject line, history of actions that individuals take on
                            action and personal information about those individuals or their
                            devices, such as name, email address, IP address, and authentication
                            methods.
                        </li>
                        <li>
                            <b>Customer service information.</b> We collect information about your
                            customer service communications with us, including, for example,
                            questions and other messages you address to us directly through online
                            forms, by email, over the phone, or by post.
                        </li>
                        <li>
                            <b>
                                Information about your web browser, mobile device, or internet
                                connection.
                            </b>
                            When you access our Services online, we may automatically collect IP
                            address, device identifiers and device attributes, including cookie IDs,
                            mobile advertising IDs, and other similar unique identifiers, data about
                            how you interact with the Services online (usage data), location
                            information (e.g., city, state, country, and ZIP code associated with
                            your IP address or derived through Wi-Fi triangulation) and, with your
                            permission in accordance with your mobile device settings, precise
                            geolocation information from GPS-based functionality on your mobile
                            device.
                        </li>
                    </ul>

                    <p>
                        Commercial and Business purposes for which we use the personal information
                        we collect:
                    </p>
                    <ul className="list">
                        <li>
                            Provide you with and collect payment for the products and Services you
                            request.
                        </li>
                        <li>
                            Create your account and manage your relationship with us (e.g.,
                            communicating with you, providing you with requested information).
                        </li>
                        <li>
                            Send you records of our relationship, including for purchases or other
                            events.
                        </li>
                        <li>
                            Market features, products, or special events using email or phone or
                            send you marketing communications about third party products and
                            services we think may be of interest to you.
                        </li>
                        <li>
                            Choose and deliver content and tailored advertising, and support the
                            marketing and advertising of our Services.
                        </li>
                        <li>
                            Create and review data about our users and how they use our Services.
                        </li>
                        <li>Test changes in our Services and develop new features and products.</li>
                        <li>
                            Fix problems you may have with our Services, including answering support
                            questions and resolving disputes.
                        </li>
                        <li>
                            Manage the Services platform including support systems and security.
                        </li>
                        <li>
                            Prevent, investigate and respond to: fraud, unauthorized access to or
                            use of our Services, breaches of terms and policies, or other wrongful
                            behavior.
                        </li>
                        <li>Comply with legal obligations.</li>
                        <li>Meet legal retention periods.</li>
                        <li>Establish, exercise, or defend our rights in legal claims.</li>
                    </ul>

                    <p>
                        <u>B. The Right to Opt Out of Sales of Personal Information</u>
                    </p>
                    <p>
                        Consumers have the right to opt out of “sales” of their personal information
                        under the CCPA. According to the CCPA, and subject to certain exceptions, a
                        business “sells” personal information when it sells, rents, releases,
                        discloses, disseminates, makes available, transfers, or otherwise
                        communicates orally, in writing, or by electronic or other means, a
                        consumer’s personal information to another business or a third party for
                        monetary or other valuable consideration.
                    </p>

                    <p>
                        <u>C. The Right to Access</u>
                    </p>
                    <p>
                        Consumers have the right to request certain details about the personal
                        information we have collected about them (generally, within the past 12
                        months). This includes the right to request:
                    </p>
                    <ul className="list">
                        <li>
                            The specific pieces of personal information and the categories of
                            personal information that we have collected about them.
                        </li>
                        <li>
                            What categories of personal information we sold or disclosed for
                            business purposes and the categories of third parties to whom we sold or
                            disclosed that information for a business purpose.
                        </li>
                        <li>
                            The categories of sources from which we collected personal information.
                        </li>
                    </ul>

                    <p>
                        <u>D. The Right to Deletion</u>
                    </p>
                    <p>
                        Consumers have the right to request that we delete the personal information
                        we have collected from them. In certain circumstances, however, the CCPA
                        allows us to retain information for particular purposes (for example, if we
                        need to retain the information to meet our legal obligations, and for
                        certain other limited reasons set forth by the CCPA).
                    </p>

                    <p>
                        <u>E. How to Exercise Access and Deletion Rights</u>
                    </p>
                    <p>
                        For security purposes, we will attempt to verify your identity prior to
                        fulfilling your request. If you have an account with us, we may verify your
                        identity through your login to your account. If you do not have an account
                        with us, we may need to respond to your request or otherwise contact you to
                        request additional information from you to verify your identity (including,
                        but not limited to your name, email address, or telephone number).
                    </p>
                    <p>
                        If we are able to verify your identity, we will respond to your request to
                        the extent we are able to and as required by law. If we cannot verify your
                        identity, we may request additional information from you or we may deny your
                        request. If we are unable to fulfill your request(s) in their entirety, we
                        will provide further information to you about the reasons that we could not
                        comply with your request.
                    </p>
                    <p>
                        Certain personal information may be exempt from such requests under
                        applicable law. In addition, we need certain types of personal information
                        so that we can provide the product and Services to you. If you ask us to
                        delete it, you may no longer be able to access or use our product and
                        Services.
                    </p>

                    <p>
                        <u>F. The Right to Nondiscrimination</u>
                    </p>
                    <p>
                        Consumers have the right not to be discriminated against for exercising
                        their CCPA rights. We will not deny, charge different prices for, or provide
                        a different level of quality of goods or services with respect to Consumers
                        who choose to exercise their CCPA rights.
                    </p>

                    <p>
                        <u>G. Authorized Agents</u>
                    </p>
                    <p>
                        Consumers may designate an agent to make CCPA requests on their behalf. For
                        Consumers who do so, we will take steps to verify both the identity of the
                        consumer seeking to exercise their rights as described above, and to verify
                        that the consumer’s agent has been authorized by them to make a request on
                        their behalf by requesting a signed written authorization or a copy of a
                        power of attorney.
                    </p>

                    <p>8. Your Privacy Rights</p>
                    <p>
                        You may have certain rights related to your personal information, subject to
                        local data protection laws, as described in more detail below. To exercise
                        any of these rights, please contact us.
                    </p>
                    <ul className="list">
                        <li>
                            You can <b>access</b> and review personal information associated with
                            your account at any time by contacting us.
                        </li>
                        <li>
                            You also can <b>request</b> the following information: how we collect
                            and use your personal information and why; the categories of personal
                            information involved; the categories of recipients of your personal
                            information; how we received your personal information and its source;
                            our business purpose for using your personal information; and how long
                            we use or store your personal information or the manner in which we
                            determine relevant retention periods.
                        </li>
                        <li>
                            You have a right to <b>correct</b> inaccurate personal information about
                            you, and you should notify us immediately if you believe the personal
                            information we hold about you is inaccurate, incomplete, or out-of-date.
                        </li>
                        <li>
                            In certain situations, you can ask that we <b>erase</b> or stop using
                            your personal information, <b>object</b> to or <b>restrict</b> the use
                            of your personal information, or <b>export</b> your personal information
                            to another controller.
                        </li>
                        <li>
                            Where we rely on your consent to process your personal information, you
                            have the right to decline consent and/or, if provided, to
                            <b>withdraw consent</b> at any time. This will not affect the lawfulness
                            of processing prior to the withdrawal of your consent. At any time, you
                            can request that we stop using your personal information for direct
                            marketing purposes.
                        </li>
                        <li>
                            If you are unsatisfied with our response to your <b>complaint</b>, you
                            have a right to raise questions or complaints with your local data
                            protection authority at any time.
                        </li>
                    </ul>

                    <p>
                        If you make a request to exercise the rights referenced above, we will
                        require you to provide certain information for identity verification
                        purposes. If you have an account with us, we may verify you through your
                        login of your account. If you do not have an account with us, we may require
                        you to provide additional information from which we can confirm your
                        identity. You may authorize an agent to make a request to us on your behalf
                        and we will verify the identity of your agent or authorized legal
                        representative by either seeking confirmation from you or documents that
                        establish the agent’s authorization to act on your behalf.
                    </p>
                    <p>
                        Certain personal information may be exempt from such requests under
                        applicable law. We need certain types of personal information so that we can
                        provide the product and Services to you. If you ask us to delete it, you may
                        no longer be able to access or use our product and Services.
                    </p>
                    <p>
                        Transfers to the U.S. and Third Countries. Subject to applicable law, we may
                        transfer your personal information outside of your jurisdiction, including
                        for further processing. Celegence has adopted Binding Corporate Rules to
                        facilitate the transfer of personal information from the European Economic
                        Area and/or United Kingdom ("EEA") to Celegence outside of the EEA.
                        Transfers outside the Celegence group are only made to organizations that
                        agree to adhere to the standards in our Binding Corporate Rules or use
                        another valid alternative under data protection law.
                    </p>

                    <p>9. How We Protect Your Personal Information</p>
                    <p>
                        We have implemented appropriate technical, physical and organizational
                        measures to protect your personal information from misuse or accidental,
                        unlawful, or unauthorized destruction, loss, alteration, disclosure,
                        acquisition, or access as well as all other forms of unlawful processing. To
                        achieve this, we have developed and implemented an Information Security
                        Management System and other sub-policies and guidelines relating to the
                        protection of your personal information. For example, our staff is permitted
                        to access customer personal information only to the extent necessary to
                        fulfill the applicable business purpose(s) and to perform their job, subject
                        to confidentiality obligations.
                    </p>

                    <p>10. Changes to This Privacy Notice</p>
                    <p>
                        We may amend this Notice to reflect changes in the law, our companies, our
                        Services, our data processing practices, or advances in technology. Our use
                        of the personal information we collect is subject to the Privacy Notice in
                        effect at the time such personal information is used. Depending on the type
                        of change, we may notify you of the change by posting on this page.
                    </p>

                    <p>11. How to Contact Us</p>
                    <p>
                        For questions or complaints regarding our use of your personal information
                        or this Notice, please contact us at privacy@celegence.com or by sending a
                        letter to Celegence LLC, Attention: Privacy Team, 1213 Butterfield Road,
                        Downers Grove, IL 60515.
                    </p>
                </div>
            </Modal>
        );
    },
);

export default PrivacyModal;
