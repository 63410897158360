import React from "react";
import { observer } from "mobx-react";
import Modal from "@app/components/modal";
import TagPicker from "@app/components/document/chunks/picker";
import { Form, Input } from "antd";
import notify from "@app/components/notify";

export const CategoryModal = observer(
    ({
        document,
        allCategories,
        assignedCategoriesNames,
        setAssignedCategories,
        visible,
        onOk,
        onCancel,
    }) => {
        const [form] = Form.useForm();

        React.useEffect(() => {
            form.setFieldsValue({ name: document.name });
        }, [form, document]);

        const updateTags = (tags) => {
            setAssignedCategories(tags);
        };

        const error = () => {
            notify.error("Please fill all required fields to continue");
        };

        const cancel = () => {
            form.resetFields();
            onCancel && onCancel();
        };

        const save = (data) => {
            const payload = {};
            const categories = allCategories
                .filter((category) => assignedCategoriesNames.includes(category.name))
                .map((item) => item._id);

            const name = (data.name || "").replace(/\s{2,}/g, " ").replace(/\n/, " ");

            payload.categories = categories;

            if (document.name !== name) {
                payload.name = data.name;
            }
            onOk(payload);
        };

        return (
            <Modal
                title="Edit"
                visible={visible}
                onOk={form.submit.bind(form)}
                onCancel={cancel}
                width={800}
            >
                <>
                    <div className="form">
                        <Form layout="vertical" form={form} onFinishFailed={error} onFinish={save}>
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please provide a name",
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Form>
                    </div>

                    <div className="add-highlight">
                        <TagPicker
                            list={allCategories}
                            assigned={assignedCategoriesNames}
                            document={document}
                            onChange={(data) => {
                                updateTags(data);
                            }}
                            onCancel={() => {
                                onCancel();
                            }}
                        />
                    </div>
                </>
            </Modal>
        );
    },
);
