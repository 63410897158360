import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import classes from "classnames";
import { startCase } from "lodash";
import { Avatar, Card, List, Typography } from "antd";

import CrossReference from "@app/state/model/report-document/cross-reference";

export const SuggestionList = forwardRef((props, ref) => {
    const [selected, setSelected] = useState(0);
    const selectItem = (index) => {
        const item = props.items[index];

        if (item) {
            props.command({ id: item.id });
        }
    };

    const totalIndexes = props.items.length;

    const upHandler = () => {
        setSelected((selected + totalIndexes - 1) % totalIndexes);
    };

    const downHandler = () => {
        setSelected((selected + 1) % totalIndexes);
    };

    const enterHandler = () => {
        selectItem(selected);
    };

    useEffect(() => setSelected(0), [props.items]);

    useImperativeHandle(ref, () => ({
        onKeyDown: ({ event }) => {
            if (event.key === "ArrowUp") {
                upHandler();
                return true;
            }

            if (event.key === "ArrowDown") {
                downHandler();
                return true;
            }

            if (event.key === "Enter") {
                enterHandler();
                return true;
            }

            return false;
        },
    }));

    return (
        <Card className={"cross-reference-suggestion"}>
            <List
                className="cross-reference-suggestion-list"
                itemLayout={"horizontal"}
                dataSource={props.items}
                renderItem={(item, index) => (
                    <List.Item
                        key={index}
                        className={classes({
                            "cross-reference-list-item": true,
                            selected: index === selected,
                        })}
                        onClick={() => selectItem(index)}
                    >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: item.label,
                            }}
                        ></div>
                    </List.Item>
                )}
            />
        </Card>
    );
});

export default SuggestionList;
