import React from "react";
import { DocumentStatus } from "@app/constants";
import { Tag } from "antd";

const Status = ({ status }) => {
    if (status === DocumentStatus.PENDING) {
        return <Tag>Pending</Tag>;
    } else if (status === DocumentStatus.ACCEPTED) {
        return <Tag color="green">Accepted</Tag>;
    } else if (status === DocumentStatus.REJECTED) {
        return <Tag color="red">Rejected</Tag>;
    }
    return null;
};

export default Status;
