import { useMemo } from "react";
import { useEditor as useTipTap } from "@tiptap/react";

import Document from "@tiptap/extension-document";
import Text from "@tiptap/extension-text";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import HardBreak from "@tiptap/extension-hard-break";
import History from "@tiptap/extension-history";
import Underline from "@tiptap/extension-underline";
import Subscript from "@tiptap/extension-subscript";
import Superscript from "@tiptap/extension-superscript";
import Gapcursor from "@tiptap/extension-gapcursor";
import TextAlign from "@tiptap/extension-text-align";

import { CaptionListItem } from "./modules/appendix";
import { DictionaryPlugin } from "./modules/dictionary";
import { LoadingPlugin } from "./modules/loading";
import { ZoomPlugin } from "./modules/zoom";
import { ClipboardPlugin } from "./modules/clipboard";
import { LandscapePlugin } from "./modules/landscape";
import CustomCommands from "./modules/utils/commands";
import PastePlugin from "./modules/paste";
import OrderedList from "./modules/list/ordered";
import { Table, TableDependencies } from "./modules/table";
import { InsertMark, DeleteMark, VersionExtension } from "./modules/version";
import { Image } from "./modules/image";
import { Abbreviation } from "./modules/abbreviation";
import { CommentMark } from "./modules/comment";
import { PageBreak } from "./modules/page-break";
import { Citation } from "./modules/citation";
import { CitationGroup } from "./modules/citation-group";
import { CrossReference } from "./modules/cross-reference";
import { CaptisLinkMark } from "./modules/captis-link";
import { Link } from "./modules/link";
import { NodePosition } from "./modules/node-position";
import { HelpTextMark } from "./modules/help-text";
import { SampleTextMark } from "./modules/sample-text";
import { SymbolPicker } from "./modules/symbol-picker";
import { CustomObject } from "./modules/custom-object";
import { HighlightMark } from "./modules/highlight";
import { processCitations } from "./modules/citation-group/index";
import { PreserveMergeOnPaste } from "./modules/table/preserve-merge-on-paste";
import { ReportView } from "@app/components/report-document/editor/modules/report/index";
import { ReportDocumentSection } from "@app/constants";
import dictionaryStore from "@app/state/store/report/dictionary/pick";
import abbreviationStore from "@app/state/store/report/abbreviation/pick";
import citationsStore from "@app/state/store/report/citation/pick";
import imageStore from "@app/state/store/report-document/image";
import tableStore from "@app/state/store/report-document/table";
import commentStore from "@app/state/store/report-document/comment";
import crossReferenceStore from "@app/state/store/report-document/cross-reference";
import captisLinktStore from "@app/state/store/report-document/captis-link";
import Paragraph from "./modules/paragraph/paragraph";
import ListItem from "./modules/list/list-item";
import BulletList from "./modules/list/bullet-list";
import { verticalAlign } from "./modules/vertical-align";
import { validElements } from "./utils";

export function useEditor({ content, readonly, onUpdate, metadata, editorProps, templateMode }) {
    const extensions = [
        Document,
        Paragraph,
        Text,
        HardBreak,
        Bold,
        Italic,
        Underline,
        Subscript,
        Superscript,
        LoadingPlugin,
        LandscapePlugin,
        PastePlugin,
        Table.configure({
            store: tableStore,
            resizable: true,
            metadata,
        }),
        PreserveMergeOnPaste,
        OrderedList,
        ListItem,
        BulletList,
        Image.configure({
            store: imageStore,
            metadata,
        }),
        TextAlign.configure({
            types: ["paragraph"],
            defaultAlignment: "justify",
        }),
        verticalAlign.configure({
            types: validElements,
        }),
        PageBreak,
        ...(!templateMode
            ? [
                  CaptisLinkMark.configure({
                      store: captisLinktStore,
                  }),
                  Citation.configure({
                      store: citationsStore,
                  }),
                  CitationGroup,
              ]
            : []),
        CrossReference.configure({
            store: crossReferenceStore,
        }),
        DictionaryPlugin.configure({
            dictionary: dictionaryStore,
            readonly,
        }),
        Abbreviation.configure({
            abbreviation: abbreviationStore,
            readonly,
        }),
        CommentMark.configure({ comment: commentStore, readonly }),
        ReportView.configure({ readonly }),
        CaptionListItem,
        HighlightMark.configure({ multicolor: true }),
        Link.configure({
            autolink: true,
            linkOnPaste: true,
            validate: ReportDocumentSection.EXTERNAL_LINK.validate,
            HTMLAttributes: {
                // setting to _blank caused to open the link twice in preview mode
                // this will still open in new tab but only once
                target: "#",
            },
        }),
        InsertMark,
        DeleteMark,
        CustomCommands,
        NodePosition,
        HelpTextMark,
        SampleTextMark,
        SymbolPicker,
        VersionExtension,
        CustomObject,
        ClipboardPlugin,
    ].concat(TableDependencies);

    if (!readonly) {
        extensions.push(History, Gapcursor, ZoomPlugin);
    }

    /**
     * Configure the editor
     */
    const value = useMemo(() => {
        // validate the content value
        if (content?.type !== "doc") {
            content = undefined;
        }

        if (readonly) {
            content = processCitations(content);
        }

        return content;
    }, [content]);

    const editor = useTipTap(
        {
            editable: !readonly,
            extensions,
            onUpdate,
            editorProps,
            content: value,
        },
        [readonly],
    );

    return editor;
}
