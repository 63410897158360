import { action, computed } from "mobx";
import { v4 as uuid } from "uuid";

import http from "@app/lib/http";
import DictionaryEntry from "@app/state/model/dictionary/entry";
import { events } from "@app/lib/store";

import BaseStore from "../../base";
import report from "../../report";
import reportDocumentStore from "../../report-document/report-documents";
import listStore from "./list";

const STGR_LOAD = uuid();

/**
 * State management controlling the list of users in
 * the user management sections
 */
export class Dictionary extends BaseStore {
    /**
     * Observable store data
     */
    observable() {
        return {
            clientId: null,
            list: [],
            usage: {},
            nodes: {},
            filter: "",
            loading: false,
            saving: false,
            loaded: false,
        };
    }

    constructor() {
        super();

        // handle dictionary update events
        events.on("dictionary.update", () => this.load());
        events.on("dictionary.updateTemplateEntries", ({ documentId }) =>
            this.loadTemplateEntries({ documentId }),
        );
        events.on("dictionary.delete", () => this.load());
        events.on("project.unload", () => {
            this.reset();
        });
    }

    /**
     * Return the project id if the currently loaded project
     */
    @computed get project() {
        return report.id;
    }

    /**
     * Return the project id if the currently loaded project
     */
    @computed get entryMap() {
        const map = {};
        this.list.map((entry) => {
            map[entry.name] = entry;
        });

        return map;
    }

    /**
     * Return the project id if the currently loaded project
     */
    @computed get filteredList() {
        const tokens = this.filter
            .split(/\s+/g)
            .filter((token) => !!token)
            .map((token) => token.toLowerCase());

        if (tokens.length === 0) {
            return this.list;
        }

        return this.list.filter((entry) => {
            const name = entry.name?.toLowerCase() || "";
            const text = entry.text?.toLowerCase() || "";

            return tokens.every((token) => {
                return name.includes(token) || text.includes(token);
            });
        });
    }

    /**
     * Return an entry by id. Flags it as being used
     */
    use(key, source) {
        if (source && !this.nodes[source]) {
            this.nodes[source] = true;
            this.usage[key] = (this.usage[key] ?? 0) + 1;
        }

        return this.entryMap[key];
    }

    /**
     * Reset the usage stats
     */
    resetUsage() {
        this.used = {};
    }

    /**
     * Update the filter
     */
    search(text) {
        this.filter = text || "";
    }

    /**
     * Load the list of dictionary entries from the backend
     */
    @action
    async load() {
        this.loading = true;

        let { data } = await http.get(`/project/${this.project}/dictionary`).stagger(STGR_LOAD);

        this.list = data.list.map((entry) => {
            return new DictionaryEntry(entry);
        });

        this.loading = false;
        this.loaded = true;
    }

    /**
     * Load the list of template dictionary entries from the backend
     */
    @action
    async loadTemplateEntries({ documentId }) {
        if (!documentId) {
            return;
        }
        this.loading = true;
        const { clientId } = reportDocumentStore;

        let { data } = await http
            .get(`/client/${clientId}/template/${documentId}/dictionary`)
            .stagger(STGR_LOAD);

        this.list = data.list.map((entry) => {
            return new DictionaryEntry(entry);
        });

        this.loading = false;
        this.loaded = true;
    }

    /**
     * Save the entry
     */
    @action
    async save(data) {
        return await listStore.save(data);
    }

    /**
     * Save the entry
     */
    @action
    async saveEntryForTemplate(documentId, data) {
        return await listStore.saveEntryForTemplate(documentId, data);
    }

    @action unload() {
        this.list = [];
        listStore.unload();
    }
}

export default new Dictionary();
