import { ReactNodeViewRenderer, mergeAttributes } from "@tiptap/react";
import { default as Suggestion } from "@tiptap/suggestion";
import { default as Mention } from "@tiptap/extension-mention";

import { default as suggestion } from "./suggestion";
import { default as NodeView } from "./node-view";

import "./styles.scss";

export const CrossReference = Mention.extend({
    name: "cross-reference",
    group: "inline",
    inline: true,
    atom: true,
    selectable: true,

    addOptions() {
        return {
            ...this.parent?.(),
            suggestion: suggestion,
            store: {},
        };
    },
    addAttributes() {
        return {
            id: null,
            type: null,
            "data-diff-node": {
                default: undefined,
            },
            "data-diff-id": {
                default: undefined,
            },
        };
    },
    addStorage() {
        return {
            store: {},
        };
    },
    addNodeView() {
        return ReactNodeViewRenderer(NodeView);
    },
    addProseMirrorPlugins() {
        return [
            Suggestion({
                editor: this.editor,
                ...this.options.suggestion,
            }),
        ];
    },
    parseHTML() {
        return [
            {
                tag: `span[data-${this.name}]`,
            },
        ];
    },
    renderHTML({ node, HTMLAttributes }) {
        const key = `data-${this.name}`;

        return [
            "span",
            mergeAttributes({ [key]: "" }, this.options.HTMLAttributes, HTMLAttributes),
            this.options.renderLabel({
                options: this.options,
                node,
            }),
        ];
    },
    onBeforeCreate() {
        this.storage.store = this.options.store || {};
    },
});

export default CrossReference;
