import { computed, action } from "mobx";
import BaseStore from "../base";
import http from "@app/lib/http";
import notify from "@app/components/notify";
import Document from "@app/state/model/document";
import report from "@app/state/store/report";

export class CaptisLinkStore extends BaseStore {
    observable() {
        return {
            sourceDocuments: [],
            loading: false,
        };
    }

    /**
     * Return the project id if the currently loaded project
     */
    @computed get project() {
        return report.id;
    }

    @action
    async load() {
        try {
            this.loading = true;

            let { data } = await http.get(`/project/${this.project}/documents`, {
                category: "all",
            });

            this.sourceDocuments = data.list.map((entry) => {
                return new Document(entry);
            });
        } catch (ex) {
            notify.error(ex.response?.data?.error);
        } finally {
            this.loading = false;
        }
    }

    @action
    hasDocument(documentId) {
        return this.sourceDocuments.find((doc) => doc._id === documentId);
    }
}

export default new CaptisLinkStore();
