import { Paragraph } from "@tiptap/extension-paragraph";
import cn from "classnames";

const CustomParagraph = Paragraph.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            helpText: {
                default: false,
                parseHTML: (element) => element.classList.contains("help-text"),
            },
            hasComment: {
                default: false,
            },
            class: {
                default: undefined,
                renderHTML: (attributes) => {
                    return {
                        class: cn({
                            "help-text": attributes.helpText,
                            "has-comment": attributes.hasComment,
                        }),
                    };
                },
            },
        };
    },
});

export default CustomParagraph;
