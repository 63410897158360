import { observable, computed } from "mobx";
import Model from "@app/state/model/base";

export default class Result extends Model {
    @observable _id = undefined;
    @observable title = undefined;
    @observable authors = [];
    @observable publicationYear = undefined;
    @observable abstract = undefined;
    @observable snippet = undefined;
    @observable snippets = [];
    @observable source = undefined;
    @observable link = undefined;
    @observable ids = null;
    @observable $selected = false;

    /**
     * Return user's initials
     */
    @computed get team() {
        return this.authors.map(({ firstName, lastName }) => `${firstName} ${lastName}`).join(", ");
    }
}
