import { Plugin, PluginKey } from "@tiptap/pm/state";
import { Decoration, DecorationSet } from "@tiptap/pm/view";

export const highlightKey = new PluginKey("highlightSelection");

const skipNodes = [
    "paragraph",
    "listItem",
    "orderedList",
    "bulletList",
    "table",
    "tableHeader",
    "tableRow",
    "tableCell",
];

function applyMixedDecorations(doc, from, to) {
    const decorations = [];

    doc.nodesBetween(from, to, (node, pos) => {
        if (node.isTextblock && node.textContent.length > 0) {
            const textFrom = Math.max(from, pos);
            const textTo = Math.min(to, pos + node.content.size);
            if (textFrom < textTo) {
                decorations.push(
                    Decoration.inline(textFrom, textTo, { class: "highlight-selection" }),
                );
            }
        } else if (node.isBlock && !node.isTextblock && !skipNodes.includes(node.type.name)) {
            const nodeFrom = pos;
            const nodeTo = pos + node.nodeSize;
            if (nodeFrom < nodeTo) {
                decorations.push(
                    Decoration.node(nodeFrom, nodeTo, { class: "highlight-selection-node" }),
                );
            }
        } else if (node.isAtom && node.isInline && !skipNodes.includes(node.type.name)) {
            const nodeFrom = pos;
            const nodeTo = pos + node.nodeSize;
            if (nodeFrom < nodeTo) {
                decorations.push(
                    Decoration.node(nodeFrom, nodeTo, {
                        class: "highlight-selection",
                    }),
                );
            }
        }
    });
    return decorations;
}

// Define the plugin
export const HighlightPlugin = () =>
    new Plugin({
        key: highlightKey,
        state: {
            init() {
                return DecorationSet.empty;
            },
            apply(tr, oldValue, oldState, newState) {
                const highlightMeta = tr.getMeta(highlightKey);
                if (highlightMeta) {
                    if (highlightMeta.add) {
                        const { from, to } = newState.selection;
                        const decorations = applyMixedDecorations(oldState.doc, from, to);

                        return DecorationSet.create(newState.doc, decorations);
                    } else if (highlightMeta.clear) {
                        return DecorationSet.empty;
                    }
                }
                return oldValue.map(tr.mapping, tr.doc);
            },
        },
        props: {
            decorations(state) {
                return this.getState(state);
            },
        },
    });
