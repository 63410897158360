import EventEmitter from "events";
import { observable, reaction, action, comparer } from "mobx";

export default class FilterState extends EventEmitter {
    @observable state = {
        search: "",
        page: 1,
        rows: 40,
        filter: {},
        sort: {},
        source: undefined,
        stats: {
            count: 0,
            facets: {},
        },
        persistHistory: false,

        /**
         * Return the from row value
         */
        get from() {
            return (this.page - 1) * this.rows;
        },

        /**
         * Return the to row value
         */
        get to() {
            let to = this.from + this.rows;
            if (to > this.stats.count) {
                to = this.stats.count;
            }

            return to;
        },

        /**
         * Flag indicating we are at the last page
         */
        get eol() {
            if (this.to >= this.stats.count) {
                return true;
            }

            return false;
        },
    };

    get hasNextPage() {
        return !this.state.eol;
    }

    get hasPreviousPage() {
        return this.state.page > 1;
    }

    suspend() {
        this.suspended = true;
    }

    resume() {
        this.suspended = false;
    }

    @observable options = {
        sort: [],
    };

    constructor(options = {}) {
        super();

        // get the sorting options
        if (options.sort) {
            this.options.sort = Object.keys(options.sort).map((key) => {
                let cfg = options.sort[key];
                return {
                    key: key,
                    label: cfg.label,
                    order: cfg.order,
                };
            });
        }

        // set the number of rows
        if (options.rows) {
            this.state.rows = options.rows;
        }

        // read the default values
        this.defaults = options.default || {};
        this.loadDefaults();

        // emit the reload event when the state changes
        this.disposer = reaction(
            () => {
                return [this.state.search, this.state.sort.value, this.state.sort.order];
            },
            () => {
                this.state.page = 1;
                this.update();
            },
            { delay: 200 },
        );

        // construct the ui state
        this.ui = new UIState();
    }

    /**
     * Update the search string
     */
    @action
    search(value) {
        this.state.search = value;
    }

    /**
     * Update the filter values
     */
    @action
    filter(key, value, options = {}) {
        if (this.persistHistory) {
            this.emit("persist.history", { name: key, value, resetPage: true });
        } else {
            let filterContent = this.state.filter;
            if (options.filterKey) {
                filterContent[options.filterKey] = filterContent[options.filterKey] || {};
                filterContent = filterContent[options.filterKey];
            }

            if (value === null) {
                delete filterContent[key];
            } else if (value instanceof Array && value.length === 0) {
                delete filterContent[key];
            } else {
                filterContent[key] = value;
            }
        }

        if (options.trigger !== false) {
            this.state.page = 1;
            this.update();
        }
    }

    /**
     * Updates the source service to which these filters will be applied
     */
    setSource(source) {
        this.state.source = source;
    }

    /**
     * Updates the source service to which these filters will be applied
     */
    getSource() {
        return this.state.source;
    }

    /**
     * Update the sort value
     */
    @action
    sort(key, order) {
        if (key === this.state.sort.value) {
            this.state.sort.order *= -1;
        } else {
            this.state.sort.value = key;
            this.state.sort.order = order;
        }

        this.state.page = 1;
        this.update();
    }

    /**
     * Trigger a search event
     */
    @action
    update() {
        if (!this.suspended) {
            this.emit("find");
        }
    }

    // new function to update Pagination
    // emit persist history
    @action
    updatePage(value) {
        if (this.persistHistory) {
            this.emit("persist.history", { name: "page", value });
        } else {
            this.state.page = value;
            this.update();
        }
    }
    /**
     * Load the next page
     */
    @action
    next() {
        if (this.state.eol) {
            return;
        }

        this.state.page++;

        if (this.persistHistory) {
            this.emit("persist.history", { name: "page", value: this.state.page });
        } else {
            this.update();
        }
    }

    /**
     * Load the previous page
     */
    @action
    prev() {
        if (this.state.page === 1) {
            return;
        }

        this.state.page--;

        if (this.persistHistory) {
            this.emit("persist.history", { name: "page", value: this.state.page });
        } else {
            this.update();
        }
    }

    /**
     * Update the result stats
     */
    @action
    stats(args = { count: 0 }) {
        let stats = this.state.stats;
        if (stats.count !== args.count) {
            stats.count = args.count;
        }

        // update the facets only if there are changes
        if (!comparer.structural(stats.facets, args.facets)) {
            stats.facets = args.facets;
        }
    }

    /**
     * Update the filter values from the supplied defaults
     */
    @action
    loadDefaults() {
        // read the default values
        this.state.search = this.defaults.search ?? "";

        // set the default sorting
        if (this.defaults.sort) {
            let key = Object.keys(this.defaults.sort)[0];
            this.state.sort.value = key;
            this.state.sort.order = this.defaults.sort[key];
        }

        // set the default filtering
        Object.keys(this.defaults)
            .filter((key) => key !== "search" && key !== "sort")
            .map((key) => {
                this.filter(key, this.defaults[key]);
            });
    }

    /**
     * Reset the filter
     */
    @action
    reset() {
        this.state.page = 1;
        this.state.filter = {};
        this.loadDefaults();
        this.stats({ count: 0 });
    }

    /**
     * Build the filtering value
     */
    value() {
        return this.serialized(this.state);
    }

    serialized(state) {
        let value = {
            search: state.search,
            from: state.from,
            rows: state.rows,
            page: state.page,
        };

        if (state?.sort?.value && state?.sort?.order) {
            value.sort = {
                [state.sort.value]: state.sort.order,
            };
        }

        if (state.filter) {
            Object.keys(state.filter).forEach((key) => {
                const filterValue = state.filter[key];

                // remove null or empty array value
                if (
                    filterValue !== undefined &&
                    (filterValue !== null || filterValue?.length > 0)
                ) {
                    value[key] = filterValue;
                }
            });
        }

        return value;
    }

    /**
     * Cleanup
     */
    dispose() {
        this.disposer();
    }
}

class UIState extends EventEmitter {
    @observable visible = true;
    @observable inline = true;

    @action
    toggle() {
        if (this.visible) {
            this.hide();
        } else {
            this.show();
        }
    }

    @action
    show() {
        this.visible = true;
        this.emit("visible", this.visible);
    }

    @action
    hide() {
        this.visible = false;
        this.emit("visible", this.visible);
    }

    @action
    setInline(value) {
        this.inline = value;
        this.emit("inline", this.inline);
    }
}
