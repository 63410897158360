const trimNested = (values) => {
    if (values && typeof values === "object" && !Array.isArray(values)) {
        const group = {};

        Object.keys(values).forEach((key) => {
            group[key] = trimNested(values[key]);
        });

        return group;
    }

    if (typeof values === "string") {
        return values.trim();
    }

    return values;
};

export default trimNested;
