import { computed, action } from "mobx";
import { v4 as uuid } from "uuid";
import BaseStore from "@app/state/store/base";
import { events } from "@app/lib/store";
import http from "@app/lib/http";
import Project from "@app/state/model/project";
import notify from "@app/components/notify";
import Comment from "@app/state/model/comment";

const STAGGER_ID = uuid();

/**
 * State management controlling the project details
 */
export class ProjectDetails extends BaseStore {
    /**
     * Observable store data
     */
    observable() {
        return {
            loading: false,
            saving: false,
            id: undefined,
            data: null,
            comments: [],
            focusedComment: null,
            commentsTab: null,
        };
    }

    @computed get busy() {
        return this.loading || this.saving;
    }

    constructor() {
        super();

        events.on("project.unload", () => {
            this.reset();
        });
    }

    /**
     * Load project details
     */
    @action
    async load(id) {
        // do not load the data twice
        if (this.id === id) {
            return;
        }

        this.loading = true;
        this.data = null;

        let { data } = await http.get(`/project/${id}`).stagger(STAGGER_ID);
        if (data._id) {
            this.data = new Project(data);
            this.id = id;
        }

        this.loading = false;
    }

    /**
     * Update project details
     */
    @action
    async update(params) {
        if (!this.data || this.saving) {
            return;
        }

        this.saving = true;

        try {
            let result;
            if (this.id) {
                result = await http.post(`/project/${this.id}`, params);
            } else {
                result = await http.put(`/project`, params);
                this.id = result.data._id;
            }

            this.data = new Project(result.data);

            // emit the update event
            events.emit("project.update", this.data);

            return true;
        } catch (ex) {
            notify.error(ex.response?.data?.error || "An error occurred while trying to update");
            return false;
        } finally {
            this.saving = false;
        }
    }

    /**
     * Remove the project
     */
    @action
    async remove() {
        if (!this.data) {
            return;
        }

        this.saving = true;
        await http.delete(`/project/${this.id}`);

        // emit the delete event
        events.emit("project.delete", this.data);

        this.data = null;
        this.saving = false;
    }

    /**
     * Create a new project
     */
    @action
    create() {
        if (!this.data || this.id !== undefined) {
            this.id = undefined;
            this.data = new Project();
        }
    }

    @action
    focus(commentId, markClick = false) {
        if (!markClick && this.focusedComment === commentId) {
            return;
        }

        this.focusedComment = commentId;
    }

    @action
    onCommentsTabSwitch(tab) {
        this.commentsTab = tab;
        this.focusedComment = null;
    }

    @action
    resetFocus() {
        this.focusedComment = null;
    }

    /**
     * Load the comments
     */
    @action
    async loadComments(sort = "desc", category) {
        // check if the project is loaded
        if (!this.id) {
            return;
        }
        let url = `/project/${this.id}/comments?sort=${sort}`;
        if (category) {
            url += `&category=${category}`;
        }
        let { data } = await http.get(url);

        this.comments = data.map((entry) => {
            return new Comment(entry);
        });
    }

    /**
     * Save a comment
     */
    @action
    async saveComment(comment, sort = "desc") {
        // check if the project is loaded
        if (!this.id) {
            return;
        }

        if (comment._id) {
            // update existing comment
            const { data } = await http.post(`/project/${this.id}/comment/${comment._id}`, comment);

            let found = this.comments.find((el) => el._id === comment._id);
            if (found) {
                found = data.text;
            }
        } else {
            // add a new comment
            const { data } = await http.put(`/project/${this.id}/comment`, comment);

            if (sort === "desc") {
                this.comments.unshift(new Comment(data));
            } else {
                this.comments.push(new Comment(data));
            }
        }
    }

    /**
     * Remove a comment
     */
    @action
    async removeComment(comment) {
        await http.delete(`/project/${this.id}/comment/${comment._id}`);

        this.comments = this.comments.filter((el) => el._id !== comment._id);
    }

    /**
     * Resolve a comment
     */
    @action
    async resolveComment(comment) {
        const { data } = await http.put(`/project/${this.id}/comment/${comment._id}/resolve`);

        let found = this.comments.find((el) => el._id === comment._id);

        if (found) {
            found.status = data.status;
            found.resolvedBy = data.resolvedBy;
            found.resolvedOn = data.resolvedOn;
            found.replies = data.replies;
        }
    }

    /**
     * Un resolve Comment
     */
    @action
    async unResolveComment(comment) {
        const { data } = await http.put(`/project/${this.id}/comment/${comment._id}/unresolve`);

        let found = this.comments.find((el) => el._id === comment._id);

        if (found) {
            found.status = data.status;
            found.resolvedBy = undefined;
            found.resolvedOn = undefined;
            found.replies = data.replies;
            if (!found.expanded) {
                found.expanded = true;
            }
        }
    }

    /**
     * Add a reply to an existing comment
     */
    @action
    async addReply(params) {
        const { data } = await http.post(
            `/project/${this.id}/comment/${params.comment._id}/reply`,
            {
                text: params.text,
            },
        );

        const comment = this.comments.find((el) => el._id === params.comment._id);
        comment.replies = data.replies;
    }

    /**
     * Update an existing comment's reply
     */
    @action
    async updateReply(params) {
        const { data } = await http.put(
            `/project/${this.id}/comment/${params.comment._id}/reply/${params.replyId}`,
            {
                reply: params.reply,
                text: params.text,
            },
        );

        const comment = this.comments.find((el) => el._id === params.comment._id);

        comment.replies = data.replies;
    }
    /**
     * Remove an existing reply
     */
    @action
    async removeReply(params) {
        const { data } = await http.delete(
            `/project/${this.id}/comment/${params.comment._id}/reply/${params.replyId}`,
        );

        const comment = this.comments.find((el) => el._id === params.comment._id);
        comment.replies = data.replies;
    }

    @action
    async pasteReportDocument(targetProjectId, pasteData) {
        if (!pasteData.id) {
            return;
        }

        await http.post(`/project/${targetProjectId}/report-documents/copy`, {
            reportDocumentId: pasteData.id,
            sourceProjectId: pasteData.sourceProjectId,
        });
    }
}

export default new ProjectDetails();
