import React from "react";
import { Tag } from "antd";
import Avatar from "./avatar";
import Box from "@app/components/box/box";
import format from "@app/lib/format";

import "./style/details.scoped.scss";

export default function UserCard({ className = "", children, user, ...rest }) {
    let classes = `user-details ${className}`;

    return (
        <Box className={classes} {...rest}>
            <div className="avatar">
                <Avatar user={user} size={60} />
            </div>
            <div className="details">
                <div className="name">{user.fullName}</div>
                <div className="email">{user.email}</div>
                <div className="roles">
                    {user.roles.map((role) => {
                        return (
                            <Tag color="blue" key={role}>
                                {format.role(role)}
                            </Tag>
                        );
                    })}
                </div>
            </div>
        </Box>
    );
}
