import React, { useEffect, useMemo } from "react";
import _ from "lodash";
import classNames from "classnames";
import { observer } from "mobx-react";
import { NodeViewWrapper } from "@tiptap/react";
import { Popover, Tag } from "antd";

import { CitationPopover } from "@app/components/citation";

import documentStore from "@app/state/store/report-document/document";
import commentStore from "@app/state/store/report-document/comment";

const UPDATE_DELAY = 100;

export const CitationNodeView = observer(({ node, editor }) => {
    const citation = documentStore.refMap[node.attrs.id];
    const { "data-diff-node": change, "data-diff-id": diffId, comment } = node.attrs || {};
    const isHighlighted = comment && comment.includes(commentStore.focusedComment);
    const status =
        commentStore.focusedComment &&
        commentStore.comments.find((c) => c._id === commentStore.focusedComment)?.status;

    const updateRefs = useMemo(() => {
        return _.debounce(() => {
            const content = editor.getJSON();
            documentStore.processSection(editor.sectionId, content);
        }, UPDATE_DELAY);
    }, []);

    useEffect(() => {
        updateRefs();

        return () => {
            updateRefs();
        };
    }, []);

    if (!citation) {
        return null;
    }

    return (
        <NodeViewWrapper className={"citation"}>
            <Popover
                title={!citation && "Missing Reference..."}
                className={"citation-popover"}
                content={
                    citation?.isValid ? (
                        <CitationPopover citation={citation.data} />
                    ) : (
                        "Invalid reference"
                    )
                }
                children={
                    <sup>
                        <Tag.CheckableTag
                            className={classNames("citation-node", {
                                "missing-file": !citation.file,
                                pending: isHighlighted && status === "pending",
                                resolved: isHighlighted && status === "resolved",
                                inserted: change === "ins",
                                deleted: change === "del",
                            })}
                            checked={false}
                            children={citation.isValid ? `[${citation.index}]` : `[\u26A0]`}
                            data-diff-id={diffId}
                        />
                    </sup>
                }
            />
        </NodeViewWrapper>
    );
});

export default CitationNodeView;
