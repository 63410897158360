import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Form, Input, Select } from "antd";

import Modal from "@app/components/modal";
import notify from "../../notify";

import "./style/report-documents.scoped.scss";

const ReportDocumentForm = observer(({ onSave, onCancel, clientTemplates }) => {
    const [form] = Form.useForm();
    const ref = React.useRef(null);

    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    }, [form]);

    /**
     * Show the validation error
     */
    const error = () => {
        notify.error("Please fill all required fields to continue");
    };

    /**
     * Cancel the edit
     */
    const cancel = () => {
        onCancel && onCancel();
        form.resetFields();
    };

    /**
     * Save the file
     */
    const save = (data) => {
        onSave && onSave(data);
        cancel();
    };

    const title = "Create a new report document";

    return (
        <Modal
            title={title}
            visible={true}
            okText="Save"
            onOk={form.submit.bind(form)}
            onCancel={cancel}
            width={600}
        >
            <div className="form">
                <Form layout="vertical" form={form} onFinishFailed={error} onFinish={save}>
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: "Please enter the name",
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={ref} placeholder="Enter a name which describes your document" />
                    </Form.Item>

                    {clientTemplates?.length > 0 && (
                        <Form.Item name="clientTemplate" label="Client Template">
                            <Select placeholder="Please select a template" allowClear>
                                {clientTemplates.map((template) => (
                                    <Select.Option value={template._id}>
                                        {template.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}
                </Form>
            </div>
        </Modal>
    );
});

export default ReportDocumentForm;
