import React from "react";
import { Switch, Select } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import Group from "../group";

import "./style/databases.scoped.scss";

const AutoUpdateSearch = ({ config, onChange, readonly = false }) => {
    const update = async (args) => {
        const result = {
            ...config,
            ...args,
        };

        onChange && onChange(result);
    };

    return (
        <Group title="Search Auto-update">
            <div className="databases">
                <div className="row toggle items-center mb-15">
                    <div className="col auto">
                        <Switch
                            checked={config.autoUpdate}
                            onChange={(value) => update({ autoUpdate: value })}
                            disabled={readonly}
                        />
                    </div>
                    <div className="col ml-15">Enable Auto Update</div>
                </div>
                <div className="row toggle items-center mb-15">
                    <div className="col ml-15">Update Frequency:</div>

                    <div className="col auto">
                        <Select
                            defaultValue={
                                config?.autoUpdateFrequency.length
                                    ? config.autoUpdateFrequency
                                    : "weekly"
                            }
                            style={{ width: 120 }}
                            onChange={(value) => update({ autoUpdateFrequency: value })}
                            options={[
                                { value: "weekly", label: "Weekly" },
                                { value: "monthly", label: "Monthly" },
                            ]}
                            disabled={readonly}
                        />
                    </div>
                </div>
                <p className="note">
                    <InfoCircleOutlined style={{ marginRight: "5px" }} />
                    Only applicable for PubMed, Google Scholar, and Europe PMC searches conducted on
                    CAPTIS
                </p>
            </div>
        </Group>
    );
};

export default AutoUpdateSearch;
