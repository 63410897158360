import { action, computed } from "mobx";
import BaseStore from "@app/state/store/base";

import http from "@app/lib/http";

import Version from "@app/state/model/report-document/version";

import report from "../report";
import sectionStore from "./report-document-sections";
import sessionStore from "../session";

const emptyContent = {
    type: "doc",
    content: [],
};

export class VersionStore extends BaseStore {
    isLoading = false;
    projectId = null;
    reportDocumentId = null;
    versionId = null;
    lhsId = null;
    rhsId = null;
    versionedContent = {};
    versionHtml = {};
    viewMode = "diff";
    versionNames = {};
    versions = [];
    loadingState = {};
    errorState = {};
    lastUpdated = 0;

    observable() {
        return {
            isLoading: false,
            versionId: null,
            lhsId: null,
            rhsId: null,
            versions: [],
            tab: "all",
            versionedContent: {},
            versionHtml: {},
            viewMode: "diff",
            versionNames: {},
            loadingState: {},
            errorState: {},
            lastUpdated: 0,
        };
    }

    /**
     * Return the project id if the currently loaded project
     */
    @computed get project() {
        return report.id;
    }

    @computed get apiUrl() {
        return `/project/${this.project}/report-documents/${this.reportDocumentId}/versions`;
    }

    @computed get sectionMenuValues() {
        return sectionStore.sections.map(({ _id: value, title: label }) => ({ value, label }));
    }

    @computed get sectionUserMenuValues() {
        const authors = new Map();

        this.versions
            .filter((version) => version?.createdBy)
            .forEach(({ createdBy: { fullName: label, _id: value } }) => {
                authors.set(value, label);
            });

        return Array.from(authors, ([value, label]) => ({ value, label }));
    }

    @computed get userId() {
        return sessionStore.user._id;
    }

    @computed get hasLoaded() {
        if (this.isLoading) {
            return false;
        }

        if (!this.versionedContent[this.versionId]) {
            return false;
        }

        return true;
    }

    @computed get contentVersions() {
        const { reportDocumentId, versionId } = this;

        if (!reportDocumentId || !versionId) {
            return { left: emptyContent, right: emptyContent };
        }

        if (!this.versionedContent[this.versionId]) {
            return { left: emptyContent, right: emptyContent };
        }

        return this.versionedContent[this.versionId];
    }

    @action
    onTabSwitch(tab) {
        this.tab = tab;
    }

    @action
    setSelected = (versionId) => {
        this.viewMode = "diff";
        this.versionId = versionId;
    };

    @action
    setSelectedAlias = (versionId) => {
        this.viewMode = "version";
        this.versionId = versionId;
    };

    @action
    async load() {
        const { data } = await http.get(`${this.apiUrl}`);
        this.versions = data
            .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
            .map((entry) => new Version(entry));
        this.viewMode = "diff";
    }

    @action
    loadContentVersions = async () => {
        if (!this.versionId || this.isLoading) {
            return;
        }

        if (this.versionId === "initial") {
            return;
        }

        this.isLoading = true;

        const { data } = await http.get(`${this.apiUrl}/${this.versionId}`);

        this.versionedContent[this.versionId] = data;

        this.isLoading = false;
    };

    @action
    updateTitle = async (versionId, title) => {
        await http.post(`${this.apiUrl}/${versionId}`, { title });
        this.refresh();
    };

    @action
    async refresh() {
        this.versionedContent = {};
        await this.load(this.reportDocumentId);
    }

    @action
    reset() {
        this.reportDocumentId = null;
        this.versionId = null;
        this.versions = [];
        this.versionedContent = {};
        this.loadingState = {};
        this.errorState = {};
        this.tab = "all";
    }

    @action
    exitVersionPreview = () => {
        this.versionId = null;
    };

    @action
    setVersionHtml = ({ cacheKey, html }) => {
        this.versionHtml[cacheKey] = html;
    };

    @action
    loadContentVersion = async ({ versionId, projectId, reportDocumentId }) => {
        if (!this.loadingState[versionId] && !this.versionedContent[versionId]) {
            this.loadingState[versionId] = true;
            try {
                const { data } = await http.get(
                    `/project/${projectId}/report-documents/${reportDocumentId}/versions/${versionId}`,
                );

                this.versionedContent[versionId] = data;
            } catch (error) {
                this.errorState[versionId] = error;
            }

            this.loadingState[versionId] = false;
        }
    };

    @action.bound
    onCompareVersions = async (
        { versionId: versionAId, versionName: versionAName },
        { versionId: versionBId, versionName: versionBName },
    ) => {
        await this.loadContentVersion({
            versionId: versionAId,
            projectId: this.projectId,
            reportDocumentId: this.reportDocumentId,
        });
        await this.loadContentVersion({
            versionId: versionBId,
            projectId: this.projectId,
            reportDocumentId: this.reportDocumentId,
        });

        this.versionId = null;
        this.lhsId = versionAId;
        this.rhsId = versionBId;
        this.versionNames = {
            left: versionAName,
            right: versionBName,
        };
        this.viewMode = "compare";
    };

    @action.bound
    updateChangeCounter = () => {
        this.lastUpdated = new Date().getTime();
    };
}

export default new VersionStore();
