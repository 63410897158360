import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { useContent } from "./nested";
import { SplitContext, createSplitState } from "./split/context";
import throttle from "@app/lib/throttle";
import { useScreenSize } from "@app/lib/screen";

import "./styles/split.scoped.scss";
import "./styles/nested.scoped.scss";

export default function SplitPage({
    left,
    right,
    className,
    resizable = true,
    minWidth = 300,
    ...rest
}) {
    const leftRef = useRef(null);
    const rightRef = useRef(null);

    const nested = useContent();
    const size = useScreenSize();
    const splitState = createSplitState();
    const state = {
        resizing: false,
        handle: null,
        left: null,
        right: null,
        leftWidth: null,
        rightWidth: null,
        initial: null,
        offset: null,
    };

    /**
     * Cleanup the event handlers
     */
    useEffect(() => {
        return function cleanup() {
            document.removeEventListener("mousemove", onResize);
            document.removeEventListener("mouseup", onEnd);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Update the isSplit state of the page
     */
    useEffect(() => {
        splitState.left = leftRef.current?.offsetWidth ?? 0;
        splitState.right = rightRef.current?.offsetWidth ?? 0;
        splitState.isSplit = !!right;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [right]);

    /**
     * Monitor changes in the screen size to recalculate the split sizes
     */
    useEffect(() => {
        notify();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [size.width]);

    /**
     * Handle the drag start event
     */
    const onStart = (event) => {
        if (state.resizing || event.button !== 0) {
            return;
        }

        state.handle = event.target;
        state.left = event.target.previousSibling;
        state.right = event.target.nextSibling;

        // get the initial sizes
        state.leftWidth = state.left.clientWidth;
        state.rightWidth = state.right.clientWidth;

        // save the initial scree x value. We will use it to determine the
        // offset from the initial position
        state.initial = event.screenX;
        state.resizing = true;

        // attach the document event handlers
        document.addEventListener("mousemove", onResize);
        document.addEventListener("mouseup", onEnd);

        // set the resizing class
        state.handle.classList.add("resizing");

        event.stopPropagation();
        event.preventDefault();
    };

    /**
     * Handle the drag event
     */
    const onResize = (event) => {
        if (!state.resizing || !state.initial || !event.screenX) {
            return;
        }

        resize(event);
        notify();

        event.stopPropagation();
    };

    /**
     * Handle the drag end
     */
    const onEnd = (event) => {
        if (!state.resizing) {
            return;
        }

        // cleanup
        document.removeEventListener("mousemove", onResize);
        document.removeEventListener("mouseup", onEnd);

        // remove the resizing class
        state.handle.classList.remove("resizing");

        resize(event);

        state.resizing = false;
        event.stopPropagation();
    };

    /**
     * Resize the left/right side base on the current state
     */
    const resize = function (event) {
        state.offset = event.screenX - state.initial;
        let leftWidth = state.leftWidth + state.offset;
        let rightWidth = state.rightWidth - state.offset;

        // check for minimal width of the left side
        if (leftWidth < minWidth) {
            rightWidth = rightWidth - (minWidth - leftWidth);
            leftWidth = minWidth;
        }

        // check for minimal width of the right side
        if (rightWidth < minWidth) {
            leftWidth = leftWidth - (minWidth - rightWidth);
            rightWidth = minWidth;
        }

        state.left.style.flexBasis = `${leftWidth}px`;
        state.right.style.flexBasis = `${rightWidth}px`;
    };

    /**
     * Notify the children about the resize
     */
    const notify = throttle(() => {
        splitState.left = leftRef.current?.offsetWidth ?? 0;
        splitState.right = rightRef.current?.offsetWidth ?? 0;
        splitState.isSplit = !!right;
    });

    return (
        <>
            <div
                className={classNames("split-page", className, {
                    resizable,
                    split: !!right,
                })}
                {...rest}
            >
                <SplitContext.Provider value={splitState}>
                    <div className="left" ref={leftRef}>
                        {left}
                    </div>
                    <div className="handle" onMouseDown={onStart}>
                        <div className="grab"></div>
                    </div>
                    <div className="right" ref={rightRef}>
                        {right}
                    </div>
                </SplitContext.Provider>
            </div>
            <div className="nested">{nested}</div>
        </>
    );
}
