import { Node, mergeAttributes } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import Render from "./render";

import { getMarks } from "../utils/content";

export default Node.create({
    name: "abbreviation",
    group: "inline",
    inline: true,
    atom: true,

    onBeforeCreate() {
        this.storage.store = this.options.abbreviation || {};

        const readonly = !this.editor.options.editable;
        this.options.readonly = readonly;
        this.type.spec.selectable = !readonly;
    },

    addAttributes() {
        return {
            key: {
                default: null,
                required: true,
                parseHTML: (el) => el.getAttribute("data-key"),
                renderHTML: (attr) => ({ "data-key": attr.key }),
            },
            comment: {
                default: undefined,
            },
        };
    },

    addOptions() {
        return {
            HTMLAttributes: {},
            abbreviation: {},
            readonly: false,
        };
    },

    addStorage() {
        return {
            store: {},
        };
    },

    parseHTML() {
        return [
            {
                tag: 'span[data-type="abbreviation"]',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ["span", mergeAttributes(HTMLAttributes, { "data-type": "abbreviation" })];
    },

    addNodeView() {
        return ReactNodeViewRenderer(Render);
    },

    addCommands() {
        return {
            /**
             * Adds a new abbreviation entry to the content
             */
            addAbbreviationEntry: (options, surroundSpace = false) => ({ editor, commands }) => {
                const selection = editor.view.state.selection;
                const nodeBefore = selection.$from.nodeBefore;
                const nodeAfter = selection.$from.nodeAfter;
                const addSpaceBefore =
                    surroundSpace && nodeBefore && !nodeBefore.text?.endsWith(" ");
                const addSpaceAfter =
                    surroundSpace && nodeAfter && !nodeAfter.text?.startsWith(" ");

                const marks = getMarks({ editor });

                const content = [];
                if (addSpaceBefore) {
                    content.push({
                        type: "text",
                        text: " ",
                    });
                }

                content.push({
                    type: this.name,
                    attrs: options,
                    marks,
                });

                if (addSpaceAfter) {
                    content.push({
                        type: "text",
                        text: " ",
                    });
                }

                commands.insertContent(content);
            },

            /**
             * Replace text with abbreviation
             */
            replaceWithAbbreviation: ({ key, from, to }) => ({ editor, dispatch, tr }) => {
                const marks = getMarks({ editor });
                const node = editor.schema.nodeFromJSON({
                    type: this.name,
                    attrs: { key },
                    marks,
                });

                dispatch(tr.replaceWith(from, to, node));
            },

            /**
             * Updates the abbreviation values
             */
            setAbbreviation: (abbreviation) => async ({ editor }) => {
                editor.storage.abbreviation.store = abbreviation;
            },

            /**
             * Show a abbreviation picker
             */
            showAbbreviationPicker: () => async ({ editor }) => {
                editor.emit("abbreviationPicker");
            },

            /**
             * Create a abbreviation entry
             */
            createAbbreviationEntry: () => async ({ editor }) => {
                const { selection, doc } = editor.state;
                const text = doc.textBetween(selection.from, selection.to);

                editor.emit("abbreviationCreate", text);
            },
        };
    },

    addKeyboardShortcuts() {
        return {
            "Alt-a": () => {
                this.editor.commands.showAbbreviationPicker();
            },
        };
    },
    marks: "commentMark",
});
