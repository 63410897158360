import classNames from "classnames";
import React from "react";
import format from "@app/lib/format";

import "./style/report-table.scoped.scss";

export const ReportTable = ({ rows, columns, config }) => {
    const { width, layout, fontSize, type } = config;

    if (config.type === "appendix") {
        return <GroupTable rows={rows} columns={columns} config={config} />;
    }

    const constructHorizontalTableData = () => {
        return [
            // construct the header
            Object.values(columns).map((column) => {
                return {
                    key: `head-${column.key}`,
                    className: `th ${column.key}`,
                    value: column.title,
                };
            }),

            // contruct the rows
            ...rows.map((row) => {
                return Object.values(columns).map((column) => {
                    let className = column.key;
                    if (row.$totals) {
                        className += " totals";
                    }

                    return {
                        key: `${row._id}-${column.key}`,
                        className,
                        value: row[column.key],
                        isHTML: !!column.isHTML,
                    };
                });
            }),
        ];
    };

    const constructVerticalTableData = () => {
        const result = [];

        rows.map((row) => {
            const values = {};
            Object.entries(row).map(([key, cell]) => (values[key] = cell));

            const entries = Object.values(columns).map((column) => {
                const key = column.key;
                return [
                    {
                        key: `head-${key}-v-${row._id}`,
                        className: `th ${key}`,
                        value: column.title,
                    },
                    {
                        key: `${key}-v-${row._id}`,
                        className: key,
                        value: values[key],
                        isHTML: !!column.isHTML,
                    },
                ];
            });

            result.push(...entries);
            result.push({ $spacer: true });
        });

        return result;
    };

    const constructTableData = () => {
        if (layout === "horizontal") {
            return constructHorizontalTableData();
        } else if (layout === "vertical") {
            return constructVerticalTableData();
        }
        return [];
    };

    return (
        <table style={{ width, fontSize }} className={classNames("report-table", type)}>
            <tbody>
                {constructTableData().map((row, index) => {
                    if (row.$spacer) {
                        return (
                            <tr className="spacer" key={index}>
                                <td />
                            </tr>
                        );
                    }

                    return (
                        <tr key={index}>
                            {row.map((cell) => {
                                if (cell.isHTML) {
                                    return (
                                        <td
                                            key={cell.key}
                                            className={`htmlCell ${cell.className}`}
                                            dangerouslySetInnerHTML={{
                                                __html: format.sanitize(cell.value, {
                                                    p: ["class"],
                                                    ul: ["class"],
                                                    ol: ["class"],
                                                    li: ["class"],
                                                    br: [],
                                                }),
                                            }}
                                        />
                                    );
                                } else {
                                    return (
                                        <td key={cell.key} className={cell.className}>
                                            {cell.value}
                                        </td>
                                    );
                                }
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

const GroupTable = ({ rows, columns, config }) => {
    return rows.map((group, idx) => (
        <div className="report-table-group" style={{ width: config?.width }} key={`group-${idx}`}>
            <div className="report-table-group-stats">
                <div>
                    <span>Search </span> <span>{group.meta.query}</span>
                </div>
                {group.meta.source && (
                    <div>
                        <span>Database </span> <span>{group.meta.source}</span>
                    </div>
                )}
                <div>
                    <span>Results </span> <span>{group.meta.results}</span>
                </div>
            </div>

            <ReportTable
                key={group.meta.name}
                rows={group.rows}
                columns={columns}
                config={{ ...config, type: "" }}
            />
        </div>
    ));
};
