export const User = {
    SYSTEM: "000000000000000000000001",
};

export const Search = {
    PUBMED: "pubmed",
    EUPMC: "eupmc",
    GOOGLE: "google",
    EMBASE: "embase",
    COCHRANE: "cochrane",
    PROSPERO: "prospero",
    OTHER: "other",
};

export const SearchCategory = {
    SYSTEMATIC_SEARCH: "Systematic Search",
    GREY_LITERATURE: "Grey Literature",
    FOCUSED_SEARCH: "Focused Search",
};

export const Status = {
    ONLINE: "online",
    OFFLINE: "offline",
};

export const ReportDocumentStatus = {
    NOT_STARTED: "Not Started",
    IN_PROGRESS: "In Progress",
    REVIEWED: "Reviewed",
    COMPLETED: "Completed",
};

export const ReportDocumentSectionStatus = {
    NOT_STARTED: "Not Started",
    IN_PROGRESS: "In Progress",
    READY_FOR_REVIEW: "Ready For Review",
    REVIEWED: "Reviewed",
};

export const ReportDocumentReviewerStatus = {
    NOT_STARTED: "Not Started",
    IN_PROGRESS: "In Progress",
    REVIEWED: "Reviewed",
};

export const MonitoredSystem = {
    PUBMED: "pubmed",
    GOOGLE: "google",
};

export const SearchDatabases = [
    Search.PUBMED,
    Search.EUPMC,
    Search.GOOGLE,
    Search.EMBASE,
    Search.COCHRANE,
    Search.PROSPERO,
    Search.OTHER,
];

export const SearchType = {
    SLR: "slr",
    SOTA: "sota",
};

export const SearchTypeDescription = {
    [SearchType.SLR]: "DUE",
    [SearchType.SOTA]: "SoTA",
};

export const Review = {
    // main stages
    L1: "l1",
    L2: "l2",

    // outcome
    PASS: "pass",
    FAIL: "fail",
};

export const ReferenceStyle = {
    Vancouver: "vancouver",
    AMA: "ama",
};

export const Role = {
    ADMIN: "admin",
    AUTHOR: "author",
    CLIENT: "client",
    EXPERT: "expert",
    LEAD_AUTHOR: "lead_author",
    PROJECT_MANAGER: "pm",
    REVIEWER: "reviewer",
    USER: "user",
    ACCOUNT_ADMIN: "account_admin",
};

export const PubmedPublicationTypes = [
    "Journal Article",
    "Review",
    "Clinical Study",
    "Clinical Trial",
    "Randomized Controlled Trial",
    "Adaptive Clinical Trial",
    "Systematic Review",
    "---",
    "Address",
    "Autobiography",
    "Bibliography",
    "Biography",
    "Case Reports",
    "Classical Article",
    "Clinical Conference",
    "Clinical Trial, Phase I",
    "Clinical Trial, Phase II",
    "Clinical Trial, Phase III",
    "Clinical Trial, Phase IV",
    "Clinical Trial Protocol",
    "Clinical Trial, Veterinary",
    "Collected Works",
    "Comparative Study",
    "Congress",
    "Consensus Development Conference",
    "Consensus Development Conference, NIH",
    "Controlled Clinical Trial",
    "Dataset",
    "Dictionary",
    "Directory",
    "Duplicate Publication",
    "Editorial",
    "Electronic Supplementary Materials",
    "English Abstract",
    "Equivalence Trial",
    "Evaluation Study",
    "Expression of Concern",
    "Festschrift",
    "Government Publication",
    "Guideline",
    "Historical Article",
    "Interactive Tutorial",
    "Interview",
    "Introductory Journal Article",
    "Lecture",
    "Legal Case",
    "Legislation",
    "Letter",
    "Meta - Analysis",
    "Multicenter Study",
    "News",
    "Newspaper Article",
    "Observational Study",
    "Observational Study, Veterinary",
    "Overall",
    "Patient Education Handout",
    "Periodical Index",
    "Personal Narrative",
    "Portrait",
    "Practice Guideline",
    "Preprint",
    "Pragmatic Clinical Trial",
    "Publication Components",
    "Publication Formats",
    "Published Erratum",
    "Research Support, American Recovery and Reinvestment Act",
    "Research Support, N.I.H., Extramural",
    "Research Support, N.I.H., Intramural",
    "Research Support, Non - U.S.Gov't Research Support, U.S. Gov't, Non - P.H.S.",
    "Research Support, U.S.Gov't, P.H.S.",
    "Retracted Publication",
    "Retraction of Publication",
    "Scientific Integrity Review",
    "Study Characteristics",
    "Support of Research",
    "Technical Report",
    "Twin Study",
    "Validation Study",
    "Video - Audio Media",
    "Webcast",
];

export const EuPmcPublicationTypes = [
    "Clinical Trial",
    "Comparative Study",
    "In Vitro",
    "Journal Article",
    "Review",
    "---",
    "Addendum",
    "Addresses",
    "Announcement",
    "Autobiography",
    "Bibliography",
    "Biography",
    "Book and Documents",
    "Brief Report",
    "Case Reports",
    "Classical Article",
    "Clinical Conference",
    "Clinical Trial, Phase I",
    "Clinical Trial, Phase II",
    "Clinical Trial, Phase III",
    "Clinical Trial, Phase IV",
    "Comment",
    "Consensus Development Conference",
    "Congresses",
    "Controlled Clinical Trial",
    "Corrected and Republished Article",
    "Dictionary",
    "Directory",
    "Dissertation",
    "Editorial",
    "English Abstract",
    "Evaluation Studies",
    "Festschrift",
    "Guideline",
    "Historical Article",
    "Interactive Tutorial",
    "Interview",
    "Introduction",
    "Introductory Journal Article",
    "Lectures",
    "Legal Cases",
    "Legislation",
    "Letter",
    "Meeting Report",
    "Meta-Analysis",
    "Multicenter Study",
    "News",
    "Newspaper Article",
    "Obituary",
    "Patient Education Handout",
    "Periodical Index",
    "Personal Narratives",
    "Portraits",
    "Poster",
    "Practice Guideline",
    "Preprint",
    "Correction",
    "Randomized Controlled Trial",
    "Preprint removal",
    "Preprint withdrawal",
    "Report",
    "Research Support, N.I.H., Extramural",
    "Research Support, N.I.H., Intramural",
    "Research Support, Non-U.S. Gov't",
    "Research Support, U.S. Gov't, Non-P.H.S.",
    "Research Support, U.S. Gov't, P.H.S.",
    "Research-article",
    "Retracted Publication",
    "Retraction of Publication",
    "Scientific Integrity Review",
    "Technical Report",
    "Twin Study",
    "Validation Studies",
    "Video-Audio Media",
    "Webcasts",
];

//prettier-ignore
export const MaudeProductCodes = [
    "BRW", "BRX", "BRY", "BSE", "BSF", "BSI", "BSJ", "BSK", "BSN", "BSO", "BSP", "BSR", "BSY", "BSZ", "BTB", "BTC",
    "BTI", "BTK", "BTL", "BTM", "BTO", "BTP", "BTQ", "BTR", "BTS", "BTT", "BTY", "BWB", "BWC", "BWF", "BWL", "BXA",
    "BXH", "BXJ", "BXK", "BXL", "BXM", "BXN", "BXO", "BXP", "BXQ", "BXR", "BXW", "BXX", "BXY", "BYD", "BYE", "BYF",
    "BYG", "BYH", "BYI", "BYJ", "BYK", "BYL", "BYM", "BYN", "BYO", "BYP", "BYR", "BYT", "BYW", "BYX", "BZA", "BZB",
    "BZC", "BZD", "BZE", "BZG", "BZH", "BZK", "BZL", "BZM", "BZN", "BZO", "BZQ", "BZR", "BZS", "BZT", "BZW", "CAD",
    "CAE", "CAF", "CAG", "CAI", "CAL", "CAM", "CAN", "CAO", "CAP", "CAT", "CAW", "CAX", "CAZ", "CBA", "CBF", "CBG",
    "CBH", "CBI", "CBK", "CBL", "CBN", "CBO", "CBP", "CBQ", "CBR", "CBS", "CBT", "CBZ", "CCC", "CCE", "CCI", "CCJ",
    "CCK", "CCL", "CCM", "CCN", "CCO", "CCQ", "CCT", "CCW", "CCX", "ECX", "EPE", "FLS", "FNC", "FOG", "JAX", "JAY",
    "JED", "JEF", "JEG", "JEH", "JEZ", "JFE", "JOH", "KFZ", "KGB", "KGK", "KHA", "KLK", "KOI", "LKD", "LNZ", "LPP",
    "MIA", "MNR", "MNS", "MNT", "MOD", "MRN", "MRO", "MRP", "MRQ", "MZT", "NFB", "NHJ", "NHK", "NHO", "NHP", "NHQ",
    "NMA", "NMC", "NMR", "NNH", "NOU", "NPF", "NQY", "NVO", "NVP", "NXA", "OBN", "OFO", "OFQ", "OFR", "OFS", "OFT",
    "OFU", "OFV", "OFZ", "OGA", "OGB", "OGC", "OGD", "OGE", "OGF", "OGG", "OGH", "OGI", "OGJ", "OGK", "OGL", "OLF",
    "ONZ", "OQU", "OYI", "PHS", "PHZ", "PMB", "PNV", "PRK", "PUG", "PXE", "QAV", "QBY", "QCX", "QEB", "DPZ", "DQA",
    "DWM", "NLF", "OCR", "PGJ", "QLS", "OZR", "LLO", "NQV", "QEC", "QFB", "GZE", "PCM", "JCT", "OMT", "BSD", "LSZ",
    "LXO", "LYM", "MNQ", "NJK", "OAZ", "OIR", "OOY", "PDR", "PNC", "POI", "PSR", "QGZ", "QKS", "QLI", "NDC", "CCY",
    "CCZ", "CDA", "CDB", "CDC", "CDD", "CDE", "CDF", "CDG", "CDH", "CDK", "CDL", "CDM", "CDN", "CDO", "CDP", "CDQ",
    "CDR", "CDS", "CDT", "CDW", "CDX", "CDZ", "CEA", "CEC", "CED", "CEE", "CEF", "CEI", "CEJ", "CEK", "CEL", "CEM",
    "CEN", "CEO", "CEP", "CEQ", "CER", "CES", "CET", "CEW", "CEX", "CFA", "CFB", "CFD", "CFE", "CFG", "CFH", "CFJ",
    "CFL", "CFM", "CFO", "CFP", "CFR", "CFT", "CFW", "CGA", "CGC", "CGD", "CGE", "CGF", "CGH", "CGI", "CGJ", "CGL",
    "CGN", "CGO", "CGR", "CGS", "CGT", "CGX", "CGZ", "CHA", "CHC", "CHD", "CHE", "CHG", "CHH", "CHI", "CHJ", "CHK",
    "CHL", "CHM", "CHO", "CHP", "CHQ", "CHR", "CHS", "CHT", "CHW", "CHZ", "CIA", "CIB", "CIC", "CID", "CIE", "CIF",
    "CIG", "CIJ", "CIK", "CIL", "CIN", "CIO", "CIQ", "CIS", "CIT", "CIW", "CIX", "CIY", "CIZ", "CJA", "CJB", "CJC",
    "CJD", "CJE", "CJF", "CJG", "CJH", "CJI", "CJJ", "CJK", "CJL", "CJM", "CJN", "CJO", "CJQ", "CJR", "CJT", "CJW",
    "CJX", "CJY", "CJZ", "CKA", "CKB", "CKC", "CKD", "CKE", "CKF", "CKG", "CKH", "DHA", "GIM", "GJE", "GLE", "JBS",
    "JFH", "JFJ", "JFL", "JFM", "JFN", "JFO", "JFP", "JFS", "JFT", "JFY", "JGC", "JGD", "JGE", "JGF", "JGG", "JGI",
    "JGJ", "JGK", "JGM", "JGP", "JGQ", "JGR", "JGS", "JGT", "JGW", "JGX", "JGY", "JGZ", "JHA", "JHC", "JHD", "JHE",
    "JHF", "JHG", "JHH", "JHI", "JHJ", "JHL", "JHM", "JHN", "JHO", "JHP", "JHQ", "JHS", "JHT", "JHW", "JHX", "JHY",
    "JIA", "JIB", "JIC", "JID", "JIE", "JIF", "JIG", "JIJ", "JIK", "JIL", "JIM", "JIN", "JIQ", "JIR", "JIS", "JIT",
    "JIW", "JIX", "JIY", "JIZ", "JJA", "JJB", "JJC", "JJE", "JJF", "JJG", "JJH", "JJI", "JJJ", "JJK", "JJL", "JJM",
    "JJN", "JJO", "JJP", "JJQ", "JJR", "JJS", "JJT", "JJW", "JJX", "JJY", "JKA", "JKB", "JKC", "JKD", "JKF", "JKG",
    "JKH", "JKJ", "JKL", "JKP", "JKR", "JKW", "JKX", "JKY", "JKZ", "JLA", "JLB", "JLC", "JLD", "JLE", "JLF", "JLG",
    "JLH", "JLI", "JLJ", "JLK", "JLL", "JLN", "JLO", "JLP", "JLQ", "JLR", "JLS", "JLT", "JLW", "JLX", "JLY", "JLZ",
    "JMA", "JMD", "JME", "JMF", "JMI", "JMJ", "JMK", "JMM", "JMN", "JMO", "JMQ", "JMR", "JMS", "JMT", "JMW", "JMX",
    "JMY", "JMZ", "JNA", "JNB", "JNC", "JND", "JNE", "JNF", "JNG", "JNJ", "JNK", "JNN", "JNO", "JNP", "JNQ", "JNS",
    "JNT", "JNX", "JNY", "JNZ", "JOA", "JOB", "JOC", "JPZ", "JQA", "JQB", "JQC", "JQD", "JQE", "JQF", "JQG", "JQH",
    "JQI", "JQJ", "JQK", "JQL", "JQM", "JQN", "JQO", "JQP", "JQQ", "JQT", "JQW", "JQX", "JQY", "JQZ", "JRA", "JRB",
    "JRC", "JRE", "JRG", "JRI", "JRJ", "JRK", "JRL", "JRM", "JRO", "JRQ", "JRR", "JRS", "KHM", "KHO", "KHP", "KHQ",
    "KHS", "KLI", "KLJ", "KMS", "KNK", "KQN", "KQO", "KQP", "KWW", "KWX", "LBR", "LBS", "LBT", "LCH", "LCI", "LCX",
    "LFP", "LFQ", "LFR", "LFS", "LIG", "LPS", "LPT", "LPW", "LXG", "MMI", "MPG", "MQM", "MRG", "MRR", "MRV", "MSJ",
    "MSM", "MYT", "MZU", "MZV", "NAK", "NAL", "NAO", "NAQ", "NBC", "NBW", "NDR", "NDY", "NEO", "NHG", "NJV", "NQL",
    "NQM", "NRH", "NSU", "NVV", "OAM", "OHQ", "OLD", "OND", "OOI", "OSX", "OTA", "OUL", "OVA", "OYG", "OYJ", "PCA",
    "PDJ", "PDS", "PEG", "PER", "PFQ", "PHG", "PHV", "PIG", "PJC", "PJD", "PJE", "PJT", "PKU", "PQD", "PQT", "PQU",
    "PQV", "PQW", "PRJ", "PSL", "PSM", "PTC", "PYP", "PYV", "PZI", "QBJ", "QBZ", "QCC", "QCL", "QCM", "QDH", "QDK",
    "QDL", "QEI", "QFG", "QII", "QJE", "QJI", "QJS", "QLG", "QMA", "OJQ", "LPI", "LPJ", "LZF", "LZG", "JMG", "QDJ",
    "MXB", "OID", "MPQ", "MXT", "OHZ", "OIA", "OIB", "OIE", "MXA", "OIC", "LHE", "LKV", "LMY", "LTC", "MDS", "MWQ",
    "NCT", "NQC", "NUK", "NUL", "OTV", "OYC", "OZO", "OZP", "OZQ", "PAQ", "PAR", "PDA", "PEJ", "PHK", "PQF", "PZE",
    "QCD", "QHJ", "CAC", "OEV", "BYS", "DPS", "DPT", "DPW", "DQB", "DQC", "DQD", "DQE", "DQG", "DQH", "DQK", "DQO",
    "DQP", "DQR", "DQT", "DQX", "DQY", "DRA", "DRB", "DRC", "DRE", "DRF", "DRG", "DRI", "DRJ", "DRK", "DRL", "DRM",
    "DRN", "DRO", "DRP", "DRQ", "DRR", "DRS", "DRT", "DRW", "DRX", "DRY", "DRZ", "DSA", "DSB", "DSF", "DSH", "DSI",
    "DSJ", "DSK", "DSM", "DSP", "DSR", "DSS", "DST", "DSW", "DSY", "DSZ", "DTA", "DTB", "DTC", "DTD", "DTE", "DTF",
    "DTG", "DTI", "DTJ", "DTK", "DTL", "DTM", "DTN", "DTP", "DTQ", "DTR", "DTS", "DTW", "DTX", "DTY", "DTZ", "DWA",
    "DWB", "DWC", "DWD", "DWE", "DWF", "DWJ", "DWP", "DWQ", "DWS", "DWX", "DWZ", "DXC", "DXE", "DXF", "DXG", "DXH",
    "DXJ", "DXK", "DXL", "DXM", "DXN", "DXO", "DXP", "DXQ", "DXR", "DXS", "DXT", "DXW", "DXX", "DXY", "DXZ", "DYB",
    "DYC", "DYE", "DYF", "DYG", "IZQ", "JOD", "JOM", "JON", "JOO", "JOP", "JOQ", "JOR", "JOW", "KFJ", "KFM", "KRA",
    "KRB", "KRC", "KRD", "KRE", "KRF", "KRG", "KRH", "KRI", "KRJ", "KRK", "KRL", "LDD", "LDE", "LDF", "LHX", "LIT",
    "LIX", "LOX", "LXN", "MAB", "MAL", "MAV", "MCW", "MFX", "MFY", "MGZ", "MHX", "MJJ", "MJN", "MKJ", "MLC", "MLD",
    "MLN", "MLO", "MMX", "MNJ", "MOP", "MPC", "MPD", "MPE", "MSX", "MTD", "MUD", "MWI", "MWJ", "MWS", "MXC", "MXD",
    "MZY", "NCP", "NFA", "NHW", "NKO", "NKP", "NKQ", "NKR", "NKS", "NKT", "NKU", "NKW", "NLG", "NLH", "NLI", "NLJ",
    "NMB", "NMD", "NMF", "NMK", "NMM", "NPP", "NQG", "NQH", "NSA", "NTE", "NVM", "NZE", "OBI", "OBJ", "OCH", "OED",
    "OEQ", "OER", "OES", "OET", "OEU", "OEX", "OEY", "OEZ", "OFA", "OFB", "OFC", "OFD", "OFE", "OFF", "OFG", "OFH",
    "OFI", "OFJ", "OFK", "OFL", "OFN", "OGZ", "OKR", "OMH", "OMR", "OMZ", "ORD", "OTD", "OVJ", "OWQ", "OZT", "PAL",
    "PBL", "PCK", "PDU", "PFA", "PFY", "PJA", "PJB", "PLA", "PLB", "PMJ", "PMK", "PNE", "PNJ", "PNO", "PNQ", "PNS",
    "PPN", "PPS", "PPW", "PQK", "PSQ", "PST", "PTL", "PTM", "PTN", "PUM", "PYX", "PZS", "QAB", "QAQ", "QDA", "QDB",
    "QEK", "QEW", "QEX", "QEY", "QEZ", "QFJ", "QJH", "QJZ", "QLJ", "QLZ", "QMS", "QMW", "QNL", "LYK", "PZO", "KPK",
    "OCL", "PAW", "PZX", "DSQ", "FZC", "LOT", "LOZ", "LPA", "LPB", "LPC", "LWO", "LWP", "LWQ", "LWR", "LWS", "LWT",
    "LWW", "LWX", "LWZ", "LXA", "MAD", "MAE", "MAF", "MCX", "MFA", "MGB", "MIE", "MIH", "MIR", "MLV", "MNO", "MOM",
    "MOU", "MOX", "MOY", "MRM", "MTE", "MUQ", "MVK", "MWG", "MWH", "NAP", "NAV", "NGV", "NIK", "NIM", "NIN", "NIO",
    "NIP", "NIQ", "NIU", "NIV", "NKE", "NKM", "NPT", "NPU", "NPV", "NVN", "NVY", "NVZ", "NWX", "OAD", "OAE", "OBC",
    "OBD", "OCM", "ODT", "OHA", "OJE", "OJX", "ONU", "OSR", "OZD", "OZG", "OZH", "PAP", "PCW", "PCX", "PFV", "PIZ",
    "PJQ", "PKK", "PNF", "PNY", "PRC", "PRL", "QAN", "QBI", "QCT", "QFV", "QLL", "QLM", "QLN", "DYH", "DYJ", "DYN",
    "DYO", "DYT", "DYW", "DYX", "DZA", "DZB", "DZC", "DZD", "DZE", "DZH", "DZI", "DZJ", "DZK", "DZL", "DZM", "DZN",
    "DZP", "DZS", "EAQ", "EAS", "EAT", "EAX", "EAY", "EAZ", "EBA", "EBC", "EBD", "EBE", "EBF", "EBG", "EBH", "EBI",
    "EBL", "EBO", "EBP", "EBR", "EBW", "EBZ", "ECB", "ECC", "ECG", "ECI", "ECM", "ECN", "ECO", "ECP", "ECQ", "ECR",
    "ECS", "ECT", "EEA", "EEF", "EEG", "EEJ", "EFA", "EFB", "EFC", "EFD", "EFH", "EFK", "EFL", "EFN", "EFS", "EFT",
    "EFW", "EFX", "EGD", "EGG", "EGI", "EGM", "EGN", "EGQ", "EGS", "EGZ", "EHA", "EHE", "EHJ", "EHK", "EHL", "EHM",
    "EHO", "EHP", "EHQ", "EHR", "EHS", "EHY", "EHZ", "EIA", "EIB", "EIC", "EID", "EIE", "EIF", "EIG", "EIH", "EIK",
    "EIL", "EIR", "EIS", "EIT", "EIX", "EIY", "EJB", "EJE", "EJF", "EJG", "EJH", "EJI", "EJJ", "EJK", "EJL", "EJP",
    "EJQ", "EJR", "EJS", "EJT", "EJW", "EJX", "EJY", "EJZ", "EKA", "EKB", "EKC", "EKD", "EKE", "EKF", "EKG", "EKH",
    "EKI", "EKJ", "EKK", "EKL", "EKM", "EKN", "EKO", "EKP", "EKQ", "EKR", "EKS", "EKT", "EKW", "EKX", "EKY", "EKZ",
    "ELA", "ELB", "ELC", "ELE", "ELJ", "ELK", "ELL", "ELM", "ELN", "ELO", "ELR", "ELS", "ELW", "ELY", "ELZ", "EMA",
    "EMB", "EMD", "EME", "EMG", "EMH", "EMI", "EMJ", "EMK", "EML", "EMM", "EMN", "EMO", "EMP", "EMQ", "EMR", "EMS",
    "JEO", "JEP", "JEQ", "JER", "JES", "JET", "JEW", "JEX", "JEY", "KCQ", "KCR", "KCS", "KHR", "KIF", "KKO", "KLC",
    "KLE", "KMT", "KMW", "KMY", "KOJ", "KOK", "KOL", "KOM", "KON", "KOO", "KOP", "KOQ", "KOR", "KOS", "KOT", "KXR",
    "KXW", "KXX", "KXY", "KZN", "KZO", "KZP", "LBH", "LCN", "LFC", "LPK", "LQZ", "LRK", "LTF", "LYC", "LZD", "MAU",
    "MCF", "MDL", "MEF", "MMD", "MMT", "MMU", "MPI", "MPJ", "MPL", "MQN", "MVH", "MXF", "MZW", "NBL", "NDP", "NEI",
    "NFE", "NFP", "NFQ", "NFR", "NFS", "NHA", "NJM", "NKJ", "NLC", "NLD", "NME", "NMW", "NOB", "NOF", "NPK", "NPL",
    "NPM", "NPZ", "NQA", "NQS", "NRD", "NRQ", "NRU", "NTK", "NTO", "NUN", "NUX", "NXC", "NXV", "NXZ", "NYC", "NYD",
    "NYE", "NYF", "NYG", "NYH", "NYL", "OAT", "OFW", "OFX", "OFY", "OHP", "OIV", "ORY", "OYH", "PFL", "PHR", "PIP",
    "PLC", "PLH", "PLV", "PME", "PNN", "PNP", "POW", "PWL", "PZY", "QCJ", "QGO", "QIA", "QJC", "QJJ", "QJK", "QMJ",
    "QNF", "QNO", "LFE", "ONK", "KZM", "LQX", "NUW", "NVK", "OAJ", "PEM", "KJJ", "LFD", "LMW", "LPG", "LQY", "LTG",
    "LWM", "LYB", "MCL", "MQC", "MVL", "NSJ", "NSK", "NSL", "NSM", "NSN", "NSO", "NSP", "NSQ", "NSR", "NXJ", "OBR",
    "OCO", "OLR", "OLS", "PKJ", "OGP", "OGV", "OGW", "MYB", "NEA", "OGT", "BST", "BTG", "BWH", "EMX", "ENW", "ENZ",
    "EOB", "EOQ", "EOX", "EPF", "EPN", "EPP", "EQH", "EQJ", "EQL", "EQN", "ERA", "ERL", "ESD", "ESE", "ESH", "ESX",
    "ESZ", "ETA", "ETB", "ETC", "ETD", "ETF", "ETK", "ETM", "ETN", "ETP", "ETR", "ETS", "ETT", "ETW", "ETY", "EWA",
    "EWC", "EWG", "EWL", "EWO", "EWQ", "EWT", "EWW", "EWY", "FWN", "JAZ", "JEI", "JEJ", "JEK", "JEL", "JOF", "JPW",
    "JXS", "JXT", "JXW", "JXX", "JXY", "JXZ", "JYA", "JYB", "JYE", "JYF", "JYG", "JYH", "JYI", "JYJ", "JYL", "JYM",
    "JYN", "JYO", "JYP", "JYQ", "JYR", "JYS", "JYT", "JYW", "JYX", "JYY", "JYZ", "JZA", "JZB", "JZC", "JZD", "JZE",
    "JZF", "JZY", "JZZ", "KAA", "KAB", "KAC", "KAD", "KAE", "KAH", "KAI", "KAJ", "KAK", "KAL", "KAO", "KAP", "KAQ",
    "KAR", "KAS", "KAT", "KAW", "KAX", "KAY", "KAZ", "KBA", "KBB", "KBC", "KBD", "KBE", "KBF", "KBG", "KBH", "KBI",
    "KBJ", "KBK", "KBL", "KBM", "KBN", "KBO", "KBP", "KBQ", "KBR", "KBS", "KBT", "KBW", "KBX", "KBY", "KBZ", "KCA",
    "KCB", "KCC", "KCD", "KCF", "KCG", "KCH", "KCI", "KCJ", "KCL", "KCM", "KCN", "KCO", "KCP", "KHH", "KHJ", "KHL",
    "KLW", "KLX", "KLZ", "KMA", "KQL", "KTE", "KTF", "KTG", "KTH", "KTI", "KTR", "LBL", "LBM", "LBN", "LBP", "LDG",
    "LJW", "LMS", "LRB", "LRC", "LRD", "LRE", "LWF", "LXB", "LXR", "LYA", "LYN", "LZI", "MAH", "MCK", "MIX", "MMO",
    "MQW", "MXP", "NAS", "NHB", "NIX", "NLB", "NLE", "NLY", "NLZ", "NNJ", "NNN", "NNO", "NNP", "NNQ", "NNR", "NNS",
    "NNT", "NNU", "NNV", "NRK", "OGR", "OGS", "OGU", "OSM", "PFO", "PKA", "PLK", "PNZ", "PTO", "PTP", "QDD", "QGK",
    "QJL", "NUP", "OGQ", "PGW", "QLV", "LWI", "PSV", "QJG", "OLL", "EPG", "ESI", "EWD", "EWE", "LEZ", "LFA", "LFB",
    "LRA", "LXV", "MCM", "MHE", "MJV", "MPV", "MRK", "OAA", "OAF", "OAQ", "OWO", "PGQ", "QJA", "MNW", "OBH", "OMV",
    "PUH", "QJB", "BSS", "EXA", "EXB", "EXD", "EXE", "EXF", "EXG", "EXH", "EXI", "EXJ", "EXN", "EXQ", "EXR", "EXS",
    "EXW", "EXX", "EXY", "EXZ", "EYA", "EYB", "EYC", "EYD", "EYH", "EYI", "EYJ", "EYK", "EYQ", "EYS", "EYT", "EYZ",
    "EZB", "EZC", "EZD", "EZK", "EZL", "EZM", "EZN", "EZO", "EZP", "EZQ", "EZR", "EZS", "EZT", "EZW", "EZY", "FAD",
    "FAE", "FAF", "FAH", "FAJ", "FAK", "FAL", "FAM", "FAN", "FAP", "FAQ", "FAR", "FAS", "FAT", "FAX", "FBI", "FBJ",
    "FBK", "FBM", "FBN", "FBO", "FBP", "FBR", "FBS", "FBW", "FBX", "FCB", "FCC", "FCE", "FCF", "FCG", "FCH", "FCI",
    "FCK", "FCL", "FCM", "FCN", "FCO", "FCP", "FCQ", "FCR", "FCS", "FCW", "FCX", "FCY", "FCZ", "FDA", "FDB", "FDC",
    "FDE", "FDF", "FDG", "FDI", "FDJ", "FDL", "FDR", "FDS", "FDT", "FDW", "FDX", "FDY", "FDZ", "FEA", "FEC", "FED",
    "FEF", "FEG", "FEH", "FEI", "FEJ", "FEM", "FEN", "FEO", "FEQ", "FER", "FES", "FET", "FEW", "FEX", "FEZ", "FFA",
    "FFD", "FFE", "FFF", "FFG", "FFH", "FFI", "FFK", "FFL", "FFN", "FFO", "FFP", "FFQ", "FFR", "FFS", "FFT", "FFW",
    "FFX", "FFY", "FFZ", "FGA", "FGB", "FGC", "FGD", "FGE", "FGF", "FGH", "FGI", "FGK", "FGM", "FGN", "FGO", "FGS",
    "FGW", "FGX", "FHA", "FHB", "FHC", "FHF", "FHH", "FHI", "FHK", "FHL", "FHN", "FHO", "FHP", "FHQ", "FHS", "FHT",
    "FHW", "FHX", "FHY", "FHZ", "FIA", "FIB", "FID", "FIE", "FIF", "FIG", "FIH", "FII", "FIJ", "FIK", "FIL", "FIN",
    "FIP", "FIQ", "FIR", "FIS", "FIW", "FIZ", "FJA", "FJB", "FJC", "FJD", "FJF", "FJG", "FJH", "FJI", "FJJ", "FJK",
    "FJL", "FJM", "FJN", "FJO", "FJP", "FJQ", "FJR", "FJS", "FJT", "FJW", "FJX", "FJY", "FJZ", "FKA", "FKB", "FKC",
    "FKD", "FKE", "FKF", "FKG", "FKH", "FKI", "FKJ", "FKK", "FKL", "FKM", "FKN", "FKO", "FKP", "FKQ", "FKR", "FKT",
    "FKW", "FKX", "FKY", "FLA", "FLB", "FLC", "FLD", "FOC", "FON", "FPD", "FRQ", "FTI", "FTJ", "FTO", "FTQ", "GBL",
    "GBM", "GBT", "GCA", "GCF", "GCK", "GCL", "GCM", "GCN", "GCO", "GCP", "GCQ", "GCS", "GCT", "GCW", "GDB", "GDS",
    "JCW", "KDH", "KDI", "KDJ", "KDK", "KDL", "KDM", "KDN", "KDO", "KGC", "KGD", "KGE", "KLA", "KNQ", "KNR", "KNS",
    "KNT", "KNW", "KNX", "KNY", "KNZ", "KOA", "KOB", "KOC", "KOD", "KOE", "KPF", "KPH", "KPI", "KPL", "KPM", "KPN",
    "KPO", "KPP", "KQQ", "KQR", "KQS", "KQT", "KXM", "LBW", "LCG", "LFJ", "LFK", "LIF", "LJH", "LKY", "LLB", "LNS",
    "LQC", "LQR", "LRI", "MJC", "MLW", "MMZ", "MND", "MNG", "MNK", "MNL", "MNV", "MON", "MPB", "MQS", "MSB", "MSD",
    "MSE", "MSF", "MSY", "MSZ", "MYE", "NAM", "NBG", "NDS", "NEZ", "NFK", "NHV", "NIE", "NIF", "NLM", "NLR", "NLS",
    "NLT", "NLU", "NLV", "NLW", "NLX", "NML", "NMX", "NMY", "NNE", "NNG", "NNW", "NNX", "NNY", "NNZ", "NOA", "NON",
    "NOO", "NOY", "NQJ", "NQT", "NQU", "NSI", "NTN", "NTZ", "NUB", "NWB", "NWI", "NWO", "NWP", "NWQ", "NWR", "NWS",
    "NWT", "NWU", "NYU", "NYV", "NZU", "OCS", "OCT", "OCU", "OCV", "OCW", "OCX", "OCY", "OCZ", "ODA", "ODB", "ODC",
    "ODD", "ODE", "ODF", "ODG", "ODN", "ODX", "OHR", "OLH", "ONJ", "ONW", "ONY", "OOW", "OQT", "PBP", "PCU", "PCV",
    "PEA", "PEH", "PEV", "PEW", "PFH", "PGD", "PGU", "PHT", "PIA", "PID", "PIE", "PIF", "PIH", "PIN", "PIO", "PJH",
    "PKI", "PKL", "PLI", "PLL", "PLP", "PLQ", "PNB", "PNG", "PNR", "POZ", "PPA", "PPB", "PPC", "PPD", "PPE", "PPF",
    "PPG", "PPH", "PPI", "PPJ", "PPK", "PPL", "PPO", "PQE", "PRM", "PRO", "PRP", "PRQ", "PRR", "PRS", "PRT", "PRU",
    "PRV", "PRW", "PRX", "PRY", "PRZ", "PSA", "PSB", "PSC", "PSD", "PSE", "PSF", "PSG", "PSH", "PSJ", "PSK", "PSX",
    "PTK", "PTQ", "PTR", "PTS", "PTT", "PTU", "PTV", "PTW", "PVY", "QAJ", "QAX", "QEF", "QFQ", "QFZ", "QGA", "QGB",
    "QGC", "QGD", "QGE", "QGG", "QIJ", "QIR", "QIS", "QJN", "QKA", "QKZ", "QMY", "QNE", "OZW", "GZD", "HIR", "OCQ",
    "PTE", "PWJ", "QAG", "OAG", "ESW", "LNK", "MQR", "MUK", "MUM", "NEJ", "OTM", "OTN", "PAG", "PAH", "PZP", "EZZ",
    "FAA", "FAB", "FAC", "FAG", "FAO", "FBY", "FBZ", "LEI", "LIL", "LJE", "LKN", "LKX", "LNL", "LNM", "LNQ", "LNR",
    "LOA", "LQQ", "LQS", "LRL", "LST", "LTI", "LXW", "MDP", "MEQ", "MER", "MES", "MIK", "MIP", "MMY", "MOA", "MRE",
    "MXQ", "NAZ", "NCV", "NZC", "OAY", "OCK", "ODH", "OKN", "OVL", "OYF", "PAT", "PAZ", "PBN", "PDI", "PFE", "PHN",
    "PHO", "PIK", "PIM", "PMH", "QBA", "QLO", "QMK", "QNB", "PHY", "QBV", "BSA", "BSB", "DAP", "DAR", "DAT", "DAX",
    "DAZ", "DDQ", "GFK", "GFO", "GFS", "GFT", "GFX", "GGC", "GGF", "GGG", "GGJ", "GGK", "GGL", "GGM", "GGN", "GGO",
    "GGP", "GGQ", "GGT", "GGW", "GGX", "GGY", "GGZ", "GHA", "GHC", "GHD", "GHH", "GHK", "GHM", "GHO", "GHQ", "GHR",
    "GHS", "GHY", "GIE", "GIF", "GIG", "GII", "GIL", "GIO", "GIQ", "GIR", "GIS", "GIT", "GIZ", "GJA", "GJB", "GJC",
    "GJG", "GJH", "GJJ", "GJL", "GJN", "GJP", "GJR", "GJS", "GJT", "GJW", "GJZ", "GKA", "GKB", "GKE", "GKF", "GKG",
    "GKH", "GKJ", "GKK", "GKL", "GKM", "GKN", "GKP", "GKQ", "GKR", "GKT", "GKW", "GKX", "GKZ", "GLG", "GLK", "GLQ",
    "GLY", "JBB", "JBD", "JBE", "JBF", "JBG", "JBH", "JBI", "JBJ", "JBK", "JBL", "JBM", "JBN", "JBO", "JBP", "JBQ",
    "JBR", "JBT", "JBW", "JBX", "JBY", "JBZ", "JCA", "JCF", "JCG", "JCJ", "JCM", "JCN", "JCO", "JIO", "JIP", "JLM",
    "JMB", "JMC", "JMH", "JOX", "JOY", "JOZ", "JPA", "JPB", "JPC", "JPD", "JPE", "JPF", "JPH", "JPI", "JPJ", "JPK",
    "JWO", "JWR", "KFF", "KHE", "KHF", "KHG", "KQE", "KQF", "KQG", "KQH", "KQI", "KQJ", "KRX", "KRY", "KRZ", "KSA",
    "KSB", "KSD", "KSE", "KSF", "KSG", "KSH", "KSI", "KSK", "KSL", "KSM", "KSN", "KSO", "KSP", "KSQ", "KSR", "KSS",
    "KST", "KSW", "KSX", "KSY", "KSZ", "KZL", "LCO", "LCP", "LGL", "LGO", "LGP", "LIM", "LJX", "LKM", "LKZ", "LLG",
    "MAM", "MIF", "MLL", "MMH", "MYJ", "MYX", "MZG", "NOZ", "NRF", "NRS", "NVG", "OAO", "OBW", "ODV", "OMM", "OOF",
    "OOX", "OQX", "ORG", "OSL", "OYE", "PBC", "PGF", "PGG", "PKG", "PMG", "PMQ", "POV", "PWD", "QFR", "QGU", "QHR",
    "QHS", "QHT", "QLU", "MZM", "DBT", "DDE", "DDF", "DDH", "DDX", "DGB", "DGQ", "DGR", "KTK", "LOQ", "MVS", "IAZ",
    "IFO", "IFS", "IGK", "IJZ", "MVU", "KSJ", "MTH", "MTK", "MTL", "MTN", "MTO", "MTP", "MTQ", "MTR", "MTS", "MVW",
    "MVX", "MVY", "MVZ", "MWB", "MWO", "MYK", "MYP", "MYQ", "MYS", "MYW", "MYY", "MZH", "MZI", "MZJ", "MZK", "NDV",
    "NJN", "OTQ", "OVG", "OYT", "PBA", "PCZ", "PDC", "PEP", "PJF", "PZT", "PZU", "PZV", "QBG", "QCZ", "CAH", "MAJ",
    "OFP", "DQF", "DQI", "OFM", "NSB", "FEB", "LEY", "NIH", "NII", "NVE", "NZA", "PUP", "BRT", "BRZ", "BWJ", "BYY",
    "BYZ", "CAK", "CAR", "CBC", "CBD", "DWL", "FLE", "FLF", "FLG", "FLH", "FLI", "FLK", "FLL", "FLN", "FMA", "FMC",
    "FME", "FMF", "FMG", "FMI", "FMJ", "FML", "FMP", "FMQ", "FMR", "FMS", "FMT", "FMW", "FMZ", "FNG", "FNJ", "FNK",
    "FNL", "FNM", "FNN", "FNP", "FNS", "FNY", "FOA", "FOB", "FOD", "FOH", "FOK", "FOS", "FOX", "FOZ", "FPA", "FPB",
    "FPF", "FPK", "FPL", "FPO", "FPP", "FPS", "FQA", "FQH", "FQJ", "FQK", "FQL", "FQM", "FQZ", "FRA", "FRC", "FRF",
    "FRG", "FRI", "FRJ", "FRK", "FRL", "FRN", "FRP", "FRR", "FRW", "FRZ", "FSA", "FSD", "GAZ", "GCX", "HEF", "HFS",
    "IKY", "JOJ", "JOK", "JOL", "KCT", "KDP", "KDQ", "KIA", "KME", "KMG", "KMH", "KMI", "KMJ", "KMK", "KMN", "KMO",
    "KPD", "KPE", "KXF", "KXG", "KYP", "KYQ", "KYR", "KYS", "KYT", "KYW", "KYX", "KYZ", "KZA", "KZD", "KZE", "KZF",
    "KZH", "LBI", "LBJ", "LDR", "LDS", "LGM", "LGN", "LGZ", "LHB", "LHF", "LHI", "LJL", "LJS", "LJT", "LLI", "LLK",
    "LRJ", "LRT", "LYY", "LYZ", "LZA", "LZB", "LZC", "LZH", "MDR", "MDZ", "MEA", "MEB", "MEC", "MED", "MEG", "MHC",
    "MHD", "MJF", "MKB", "MLR", "MMK", "MMS", "MQX", "MRB", "MRL", "MRZ", "MTC", "MTV", "MZR", "NEP", "NGT", "NKK",
    "NKN", "NRV", "NRW", "NSC", "NTC", "NTX", "NXH", "NZD", "NZG", "NZH", "NZJ", "NZW", "NZX", "OBK", "OCI", "ODI",
    "ODY", "OHY", "OIG", "OJA", "OKC", "OKE", "OKL", "OMF", "ONB", "ONM", "OPC", "OPH", "OPI", "OPJ", "OPP", "ORW",
    "OSI", "OSZ", "OUG", "OUW", "OVY", "OWL", "OWP", "OYS", "OZK", "PAD", "PDH", "PEC", "PEF", "PEQ", "PEZ", "PHC",
    "PJJ", "PJO", "PJP", "PKP", "PMS", "PND", "POQ", "POR", "POT", "PQH", "PQX", "PRN", "PTD", "PTI", "PUK", "PUN",
    "PUR", "PVZ", "PWH", "PWM", "PWN", "PWO", "PWP", "PWQ", "PWR", "PWS", "PWT", "PWU", "PWV", "PWW", "PWX", "PWY",
    "PWZ", "PXA", "PXB", "PXC", "PXD", "PXF", "PXG", "PXH", "PXI", "PXJ", "PXK", "PXL", "PXM", "PYQ", "PYR", "PZW",
    "QBL", "QDM", "QDO", "QEH", "QJY", "QKM", "QLF", "QLY", "QMT", "QNQ", "QNS", "LKG", "FMH", "NNI", "IQG", "LHC",
    "NXB", "NXQ", "OIZ", "OJL", "OUJ", "PSW", "ERY", "EYX", "EYY", "FLJ", "FRT", "FXO", "FXP", "FXX", "FXY", "FXZ",
    "FYA", "FYB", "FYC", "FYD", "FYH", "HMT", "HMW", "KGO", "KGQ", "KGT", "KKX", "LGX", "LYU", "MAC", "MMP", "MSH",
    "NZP", "OEA", "OKF", "OKG", "OKH", "OKI", "ONT", "OPA", "ORC", "OUK", "OVR", "OXZ", "PIR", "PLY", "PQM", "QBW",
    "QGI", "QMX", "LDH", "LKB", "LKK", "LLC", "LLD", "LMQ", "LNX", "LNY", "LPZ", "LSX", "LWE", "MQT", "NSX", "PGO",
    "PWC", "QBP", "QIX", "QIY", "QIZ", "QKR", "QKU", "QKY", "QLA", "QLB", "QLD", "QLE", "QMF", "OUE", "LIZ", "LJD",
    "NID", "PDX", "CFF", "CFN", "CFQ", "CGM", "CHN", "CZJ", "CZK", "CZL", "CZM", "CZN", "CZO", "CZP", "CZQ", "CZR",
    "CZS", "CZT", "CZW", "CZX", "CZY", "CZZ", "DAB", "DAC", "DAD", "DAE", "DAF", "DAG", "DAH", "DAI", "DAJ", "DAK",
    "DAL", "DAM", "DAN", "DAO", "DAQ", "DAS", "DAW", "DAY", "DBA", "DBC", "DBE", "DBF", "DBH", "DBI", "DBJ", "DBK",
    "DBL", "DBM", "DBN", "DBY", "DCB", "DCE", "DCF", "DCH", "DCJ", "DCK", "DCN", "DCO", "DCT", "DCY", "DDB", "DDC",
    "DDD", "DDG", "DDI", "DDJ", "DDK", "DDL", "DDN", "DDO", "DDR", "DDS", "DDT", "DDY", "DDZ", "DEA", "DEB", "DEC",
    "DEF", "DEG", "DEH", "DEI", "DEJ", "DEK", "DEL", "DEM", "DEN", "DEO", "DER", "DES", "DET", "DEW", "DEX", "DEZ",
    "DFB", "DFC", "DFF", "DFG", "DFH", "DFI", "DFJ", "DFK", "DFL", "DFM", "DFO", "DFQ", "DFS", "DFT", "DFX", "DFZ",
    "DGA", "DGC", "DGD", "DGE", "DGG", "DGI", "DGJ", "DGK", "DGL", "DGM", "DGN", "DGP", "DGW", "DGX", "DHB", "DHC",
    "DHI", "DHL", "DHN", "DHQ", "DHR", "DHX", "DHY", "JFQ", "JFR", "JHR", "JKM", "JNL", "JNM", "JZG", "JZH", "JZJ",
    "JZO", "JZP", "JZQ", "JZR", "JZS", "JZT", "JZW", "JZX", "KHT", "KTL", "KTM", "KTO", "KTP", "KTQ", "KTS", "LJM",
    "LKJ", "LKL", "LKO", "LKP", "LLL", "LOJ", "LRM", "LSW", "LTJ", "LTK", "MGA", "MID", "MLE", "MLH", "MMW", "MOB",
    "MOI", "MQA", "MST", "MSV", "MSW", "MUT", "MVE", "MVJ", "MVM", "NBO", "NBS", "NBT", "NCW", "NHS", "NHX", "NIG",
    "NIY", "NJF", "NOE", "NPH", "NQD", "NRI", "NRX", "NSF", "NST", "NTY", "NUM", "NVA", "NWG", "NXO", "NYO", "OAU",
    "OBE", "OBZ", "OCN", "OEG", "OIF", "OIU", "OKM", "OPN", "OPX", "OPY", "OQZ", "OSK", "OVK", "OYL", "OYV", "PAF",
    "PCG", "PCN", "PCO", "PDE", "PDF", "PET", "PHF", "PIV", "PJI", "PNI", "PTA", "PUW", "PZH", "QAT", "MYR", "MZC",
    "NZB", "PGV", "ONX", "IBB", "KEF", "NVI", "KTJ", "LOK", "LTQ", "MAN", "MJB", "MTF", "MTG", "MYZ", "MZA", "MZE",
    "MZF", "MZL", "NAF", "NAH", "NYQ", "OAW", "OCB", "OTR", "OWD", "OYA", "OYM", "OYY", "PIL", "QLC", "QIG", "PMI",
    "PNK", "MJX", "MJY", "MJZ", "MKA", "PQQ", "JTS", "KTN", "OUY", "QIC", "GLZ", "GMA", "GMB", "GMD", "GME", "GMG",
    "GMH", "GMI", "GMJ", "GMK", "GML", "GMM", "GMN", "GMO", "GMP", "GMQ", "GMR", "GMS", "GMT", "GMW", "GMX", "GMY",
    "GMZ", "GNA", "GNB", "GNC", "GND", "GNE", "GNF", "GNG", "GNH", "GNI", "GNJ", "GNK", "GNL", "GNM", "GNN", "GNO",
    "GNP", "GNQ", "GNR", "GNS", "GNT", "GNW", "GNX", "GNY", "GNZ", "GOA", "GOB", "GOC", "GOD", "GOE", "GOF", "GOG",
    "GOH", "GOI", "GOJ", "GOK", "GOL", "GOM", "GON", "GOO", "GOP", "GOS", "GOT", "GOW", "GOX", "GOY", "GOZ", "GPD",
    "GPE", "GPF", "GPG", "GPH", "GPI", "GPJ", "GPK", "GPM", "GPN", "GPO", "GPP", "GPQ", "GPR", "GPS", "GPT", "GPW",
    "GPX", "GPY", "GPZ", "GQA", "GQB", "GQC", "GQD", "GQE", "GQF", "GQG", "GQH", "GQI", "GQJ", "GQK", "GQL", "GQM",
    "GQN", "GQO", "GQP", "GQQ", "GQR", "GQS", "GQT", "GQW", "GQX", "GQY", "GQZ", "GRA", "GRB", "GRC", "GRD", "GRE",
    "GRF", "GRG", "GRH", "GRI", "GRJ", "GRK", "GRL", "GRM", "GRO", "GRP", "GRT", "GRW", "GRX", "GRY", "GRZ", "GSA",
    "GSB", "GSD", "GSE", "GSF", "GSG", "GSH", "GSI", "GSJ", "GSK", "GSL", "GSM", "GSN", "GSO", "GSP", "GSQ", "GSR",
    "GSS", "GST", "GSW", "GSX", "GSY", "GSZ", "GTA", "GTB", "GTC", "GTD", "GTE", "GTF", "GTH", "GTI", "GTJ", "GTL",
    "GTN", "GTO", "GTP", "GTQ", "GTR", "GTX", "GTY", "GTZ", "GWB", "GWC", "GWD", "JRW", "JRY", "JRZ", "JSA", "JSB",
    "JSC", "JSD", "JSE", "JSF", "JSG", "JSH", "JSI", "JSJ", "JSK", "JSL", "JSM", "JSN", "JSO", "JSP", "JSR", "JSS",
    "JST", "JSW", "JSX", "JSY", "JSZ", "JTA", "JTB", "JTC", "JTL", "JTM", "JTN", "JTO", "JTQ", "JTR", "JTT", "JTW",
    "JTX", "JTY", "JTZ", "JWK", "JWL", "JWT", "JWW", "JWX", "JWY", "JWZ", "JXA", "JXB", "JXC", "KFG", "KFH", "KFI",
    "KHW", "KLH", "KZB", "KZC", "KZI", "KZJ", "KZK", "LFX", "LFY", "LFZ", "LGA", "LGB", "LGC", "LGD", "LHJ", "LHK",
    "LHL", "LHT", "LHW", "LIA", "LIB", "LIC", "LIE", "LIN", "LIO", "LIP", "LIQ", "LIR", "LJB", "LJC", "LJF", "LJG",
    "LJK", "LJN", "LJO", "LJP", "LJY", "LJZ", "LKA", "LKC", "LKH", "LKI", "LKQ", "LKS", "LKT", "LLA", "LLH", "LLM",
    "LOL", "LON", "LOO", "LOP", "LQF", "LQG", "LQH", "LQL", "LQM", "LQN", "LQO", "LQP", "LRF", "LRG", "LRH", "LSC",
    "LSD", "LSE", "LSF", "LSG", "LSH", "LSI", "LSJ", "LSK", "LSL", "LSM", "LSN", "LSO", "LSQ", "LSR", "LTS", "LTT",
    "LTW", "LTX", "LYF", "LYR", "MBT", "MCB", "MCC", "MCD", "MCE", "MCG", "MCS", "MCT", "MDB", "MDC", "MDE", "MDF",
    "MDJ", "MDK", "MDT", "MDU", "MGM", "MHI", "MHJ", "MIU", "MIV", "MIY", "MIZ", "MJA", "MJD", "MJE", "MJH", "MJK",
    "MJL", "MJM", "MKZ", "MLA", "MSQ", "MWA", "MXJ", "MYF", "MYI", "NDZ", "NGS", "NGZ", "NHT", "NHY", "NHZ", "NIA",
    "NIJ", "NJO", "NJR", "NOM", "NOP", "NPO", "NQX", "NQZ", "NRL", "NTM", "NVQ", "NWZ", "NXD", "NXX", "NZS", "OAH",
    "OAI", "OAX", "OCC", "OEH", "OEM", "OEP", "OJY", "OMG", "OMI", "OMN", "OMS", "OOU", "OPL", "OPM", "OQO", "OQW",
    "OSU", "OTG", "OUC", "OUS", "OUZ", "OVF", "OWF", "OYP", "OYZ", "OZA", "OZB", "OZE", "OZN", "OZX", "OZY", "OZZ",
    "PAM", "PBK", "PCH", "PCI", "PCL", "PEN", "PEO", "PEU", "PEX", "PGH", "PGI", "PGX", "PII", "PIQ", "PIT", "PLO",
    "PMN", "PMT", "PMY", "POC", "PPU", "PQA", "PQZ", "PRA", "PRE", "PRH", "PRI", "PSZ", "PTF", "PTJ", "PUQ", "PVQ",
    "PYN", "QAA", "QBD", "QBH", "QBN", "QBQ", "QBX", "QCH", "QCU", "QDP", "QDZ", "QEP", "QFA", "QFO", "QFS", "QGN",
    "QHM", "QHO", "QID", "QIV", "QLX", "QMC", "QMI", "QMV", "QNJ", "PZF", "QDS", "LOM", "LQI", "MAQ", "MHR", "MKT",
    "MYL", "MYM", "MZO", "MZP", "NCD", "NWY", "OBF", "OIH", "OJN", "OJO", "ORL", "OTT", "OYB", "PAB", "PCY", "POA",
    "POG", "PYS", "QHV", "QIM", "QIN", "QIO", "QIP", "QIQ", "QJR", "QKO", "QKP", "QLT", "QLW", "QMN", "MOF", "NCX",
    "NDQ", "NRY", "NUI", "QAF", "QBE", "QEM", "QJP", "PDQ", "QHH", "GWE", "GWF", "GWG", "GWI", "GWJ", "GWK", "GWL",
    "GWM", "GWN", "GWO", "GWQ", "GWR", "GWS", "GWW", "GWX", "GWY", "GWZ", "GXB", "GXC", "GXD", "GXE", "GXH", "GXI",
    "GXJ", "GXL", "GXM", "GXN", "GXO", "GXP", "GXQ", "GXR", "GXS", "GXT", "GXW", "GXX", "GXY", "GXZ", "GYA", "GYB",
    "GYC", "GYD", "GYE", "GYK", "GYZ", "GZA", "GZB", "GZF", "GZI", "GZJ", "GZK", "GZL", "GZM", "GZN", "GZO", "GZP",
    "GZQ", "GZT", "GZX", "HAD", "HAE", "HAO", "HAS", "HAW", "HAX", "HBA", "HBB", "HBC", "HBD", "HBE", "HBF", "HBG",
    "HBL", "HBM", "HBN", "HBO", "HBP", "HBQ", "HBR", "HBS", "HBW", "HBX", "HBZ", "HCA", "HCB", "HCC", "HCD", "HCE",
    "HCG", "HCH", "HCI", "HCJ", "HCS", "JXE", "JXF", "JXG", "JXH", "JXI", "JXK", "JXL", "LEL", "LEM", "LHG", "LIH",
    "LLN", "LQW", "MVA", "MYU", "MZQ", "NCG", "NFO", "NHC", "NHI", "NLN", "NLO", "NLP", "NMN", "NTU", "NUF", "NUH",
    "NYN", "NYW", "NYX", "OAN", "OBP", "OBV", "OCF", "OJG", "OKP", "OLT", "OLU", "OLV", "OLW", "OLX", "OLY", "OLZ",
    "OMA", "OMB", "OMC", "ORR", "ORT", "OSG", "PAV", "PBJ", "PCB", "PCC", "PIW", "PIY", "PJM", "PJN", "PKQ", "PKR",
    "PLU", "PNA", "POL", "POM", "POP", "POS", "PPT", "PSP", "PTB", "PTY", "PUU", "PVL", "PVM", "PVO", "PVP", "PWE",
    "PZR", "QAK", "QAR", "QBC", "QCI", "QEA", "QFD", "QFF", "QFX", "QGH", "QGL", "QGT", "QJQ", "QMD", "QMZ", "QFT",
    "OPT", "HRW", "HST", "KQW", "KQX", "LBB", "NKI", "IKN", "IKT", "LYH", "NHH", "OTX", "OVP", "PHL", "ONO", "BWK",
    "KGF", "KGG", "LBK", "LGW", "LHY", "LII", "LKW", "LLE", "LLF", "LLP", "LME", "LQD", "LYI", "LYJ", "MFE", "MFR",
    "MHY", "MHZ", "MKD", "MLQ", "MRU", "MUZ", "MVV", "MXM", "NHL", "NJE", "NPE", "NQR", "NZK", "OBA", "OLM", "OPR",
    "OUT", "OUU", "OVC", "OZJ", "PAX", "PCQ", "PFN", "PJS", "PMP", "POH", "QCA", "QLK", "LNW", "NAJ", "OAC", "PSI",
    "QHC", "FHG", "FHJ", "HCY", "HCZ", "HDA", "HDB", "HDC", "HDD", "HDE", "HDF", "HDG", "HDH", "HDI", "HDJ", "HDK",
    "HDL", "HDM", "HDN", "HDO", "HDP", "HDQ", "HDR", "HDT", "HDW", "HDX", "HDY", "HDZ", "HEB", "HEC", "HED", "HEE",
    "HEG", "HEH", "HEI", "HEJ", "HEK", "HEL", "HEM", "HEN", "HEO", "HEP", "HEQ", "HER", "HES", "HET", "HEW", "HEX",
    "HEZ", "HFA", "HFB", "HFC", "HFD", "HFE", "HFF", "HFG", "HFH", "HFI", "HFJ", "HFK", "HFL", "HFM", "HFN", "HFO",
    "HFP", "HFW", "HFX", "HFY", "HFZ", "HGA", "HGB", "HGC", "HGD", "HGE", "HGF", "HGG", "HGH", "HGI", "HGK", "HGL",
    "HGM", "HGN", "HGO", "HGP", "HGS", "HGW", "HGX", "HGY", "HGZ", "HHA", "HHD", "HHE", "HHF", "HHG", "HHI", "HHJ",
    "HHK", "HHL", "HHM", "HHN", "HHO", "HHP", "HHQ", "HHR", "HHS", "HHT", "HHW", "HHX", "HIB", "HIC", "HIF", "HIG",
    "HIH", "HII", "HIL", "HIM", "HIN", "HIO", "HIQ", "HIS", "KNA", "KNB", "KNC", "KND", "KNE", "KNF", "KNG", "KNH",
    "KOH", "KXN", "KXO", "KXP", "KXQ", "LBX", "LHZ", "LKF", "LLW", "LQK", "LTZ", "LZL", "MBU", "MDG", "MFD", "MKO",
    "MLS", "MLT", "MOH", "MOK", "MOL", "MOV", "MPU", "MQE", "MQF", "MQG", "MQH", "MQI", "MQJ", "MQK", "MQL", "MRX",
    "MSC", "MTW", "MTX", "NBV", "NBZ", "NHM", "NKA", "NKC", "NMG", "NMH", "NMI", "NNB", "NRC", "NUC", "NUQ", "NUR",
    "NVT", "NWV", "NWW", "OBB", "OBY", "OHC", "OHD", "OHE", "OHF", "OHG", "OHH", "OHL", "OHM", "OJF", "OKU", "OKV",
    "OKW", "OKX", "OKY", "OKZ", "OOA", "OQY", "OSP", "OSV", "OTP", "OYO", "PAI", "PBH", "PBQ", "PCF", "PEB", "PFJ",
    "PGK", "PGT", "PKN", "PMU", "PNU", "PON", "PTZ", "PUA", "PUB", "PUC", "PUD", "PVR", "PVS", "PVT", "PVU", "PVV",
    "PVW", "PVX", "PWA", "PWB", "PWI", "PWK", "PYT", "QHZ", "QKH", "QKI", "NPB", "OTO", "PAJ", "HHY", "LHD", "LIY",
    "LLQ", "LLT", "LLX", "LOB", "LTA", "LTY", "MCI", "MCN", "MCP", "MGI", "MKN", "MMA", "MNB", "MPR", "MPT", "MWM",
    "NRZ", "OVX", "PAS", "PKF", "QLH", "KYH", "MPA", "NKZ", "MRH", "FCT", "FSR", "FTZ", "HIT", "HIW", "HIX", "HIY",
    "HJA", "HJB", "HJC", "HJD", "HJF", "HJG", "HJH", "HJI", "HJJ", "HJK", "HJL", "HJM", "HJN", "HJO", "HJQ", "HJR",
    "HJS", "HJT", "HJW", "HJX", "HJY", "HJZ", "HKB", "HKC", "HKD", "HKF", "HKG", "HKI", "HKK", "HKL", "HKM", "HKN",
    "HKO", "HKP", "HKQ", "HKR", "HKS", "HKT", "HKW", "HKX", "HKY", "HKZ", "HLA", "HLC", "HLD", "HLE", "HLF", "HLG",
    "HLH", "HLI", "HLJ", "HLK", "HLL", "HLM", "HLN", "HLO", "HLP", "HLQ", "HLR", "HLS", "HLT", "HLW", "HLX", "HLZ",
    "HMC", "HMD", "HME", "HMF", "HMG", "HMH", "HMJ", "HMK", "HML", "HMM", "HMQ", "HMR", "HMS", "HMX", "HMY", "HMZ",
    "HNA", "HNB", "HNC", "HND", "HNE", "HNF", "HNG", "HNH", "HNI", "HNJ", "HNK", "HNL", "HNM", "HNN", "HNO", "HNP",
    "HNQ", "HNR", "HNS", "HNT", "HNW", "HNX", "HNY", "HNZ", "HOA", "HOB", "HOC", "HOD", "HOE", "HOF", "HOG", "HOH",
    "HOI", "HOJ", "HOK", "HOL", "HOM", "HON", "HOO", "HOP", "HOQ", "HOR", "HOS", "HOT", "HOW", "HOX", "HOY", "HOZ",
    "HPA", "HPB", "HPC", "HPD", "HPE", "HPF", "HPG", "HPI", "HPJ", "HPK", "HPL", "HPM", "HPN", "HPO", "HPP", "HPQ",
    "HPS", "HPT", "HPW", "HPY", "HPZ", "HQA", "HQB", "HQC", "HQD", "HQE", "HQF", "HQG", "HQH", "HQJ", "HQL", "HQM",
    "HQN", "HQO", "HQP", "HQQ", "HQR", "HQS", "HQT", "HQW", "HQX", "HQY", "HQZ", "HRC", "HRD", "HRE", "HRF", "HRG",
    "HRH", "HRI", "HRJ", "HRK", "HRL", "HRN", "HRO", "HRP", "KYB", "KYD", "KYE", "KYF", "KYG", "LCC", "LPL", "LPM",
    "LPN", "LPO", "LQJ", "LRX", "LWL", "LXP", "LXS", "LYL", "LZP", "MFK", "MJP", "MLP", "MLZ", "MMC", "MMF", "MML",
    "MMQ", "MOE", "MQU", "MRC", "MSG", "MSR", "MSS", "MTZ", "MUS", "MUW", "MVN", "MWL", "MXK", "MXO", "MXY", "MYC",
    "MYD", "MZD", "NAA", "NAI", "NCB", "NCE", "NCF", "NCK", "NCR", "NCZ", "NFD", "NFM", "NGY", "NIC", "NIZ", "NJG",
    "NJH", "NJJ", "NKX", "NKY", "NLA", "NOG", "NOH", "NOI", "NQB", "NQE", "NUU", "NVB", "NXM", "NXR", "NYK", "OBO",
    "OHW", "OHX", "OJK", "OOE", "ORZ", "OTZ", "OUM", "PIB", "PIC", "PJZ", "PLX", "PLZ", "PMW", "POE", "PQJ", "PUE",
    "PUF", "PUL", "PZG", "PZK", "QBR", "QCB", "QDV", "QDW", "QDY", "QER", "QGJ", "QKV", "QMM", "NFF", "NFG", "NFH",
    "NFI", "NFJ", "PYU", "HPX", "LOG", "LOH", "LOI", "LQB", "LQE", "LXQ", "LYX", "LZQ", "LZR", "LZS", "LZU", "MRJ",
    "MSI", "MTA", "MUR", "MWD", "NBF", "NCJ", "ODK", "OGO", "OKS", "OTL", "OXW", "PFZ", "QBT", "QIT", "OJP", "OLO",
    "ONN", "OSE", "OSF", "HRR", "HRS", "HRT", "HRX", "HRY", "HRZ", "HSA", "HSB", "HSD", "HSH", "HSN", "HSP", "HSQ",
    "HSR", "HSX", "HTG", "HTH", "HTJ", "HTN", "HTO", "HTP", "HTQ", "HTS", "HTT", "HTW", "HTX", "HTY", "HWA", "HWB",
    "HWC", "HWD", "HWI", "HWJ", "HWK", "HWL", "HWN", "HWO", "HWP", "HWQ", "HWR", "HWT", "HWW", "HWX", "HXA", "HXB",
    "HXC", "HXE", "HXG", "HXI", "HXJ", "HXK", "HXN", "HXO", "HXP", "HXQ", "HXS", "HXW", "HXX", "HXY", "HXZ", "JDB",
    "JDC", "JDD", "JDG", "JDH", "JDI", "JDL", "JDN", "JDO", "JDP", "JDQ", "JDR", "JDS", "JDT", "JDW", "JDX", "JDY",
    "JDZ", "JEC", "JWH", "JWI", "JWJ", "KIG", "KIH", "KII", "KIL", "KMB", "KMC", "KMD", "KQZ", "KRN", "KRO", "KRP",
    "KRQ", "KRR", "KRS", "KTT", "KTW", "KTX", "KTZ", "KWA", "KWB", "KWD", "KWE", "KWF", "KWG", "KWH", "KWI", "KWJ",
    "KWK", "KWL", "KWM", "KWN", "KWO", "KWP", "KWQ", "KWR", "KWS", "KWT", "KWY", "KWZ", "KXA", "KXB", "KXC", "KXD",
    "KXE", "KYI", "KYJ", "KYK", "KYM", "KYN", "LBC", "LGE", "LGF", "LGG", "LGH", "LOD", "LPH", "LRN", "LTO", "LWJ",
    "LXH", "LXT", "LYP", "LYQ", "LYS", "LYT", "LZE", "LZO", "LZV", "LZY", "MAI", "MAX", "MAY", "MBB", "MBF", "MBH",
    "MBI", "MBL", "MBP", "MBV", "MEH", "MJW", "MNH", "MNI", "MNU", "MQP", "MQV", "MRY", "NBH", "NDD", "NDE", "NDF",
    "NDG", "NDH", "NDI", "NDJ", "NDK", "NDL", "NDM", "NDN", "NGR", "NJA", "NJD", "NKB", "NKG", "NOV", "NPJ", "NQP",
    "NQW", "NUD", "NVC", "NWN", "OAR", "OBL", "OBM", "OBT", "OCG", "OCJ", "ODP", "OIS", "OIY", "OJB", "OJM", "OOG",
    "OOL", "OQB", "OQG", "OQH", "OQI", "OSH", "OSN", "OUR", "OVD", "OVE", "OVZ", "OWI", "OYK", "OZI", "PAO", "PBF",
    "PBI", "PEK", "PHM", "PHQ", "PHX", "PKC", "PLF", "PLR", "PLW", "PML", "PPQ", "PPR", "PQC", "QAC", "QAD", "QBO",
    "QFP", "QHE", "QHG", "QHQ", "QLQ", "QLR", "QNI", "HSZ", "HWE", "JDJ", "JDK", "KIJ", "LXI", "LZN", "OJH", "OLC",
    "PPP", "HTM", "HWF", "LML", "LMN", "LOE", "LOF", "LPF", "LPQ", "LWA", "LXC", "LXY", "LZJ", "MBD", "MBJ", "MBS",
    "MCV", "MDI", "MJO", "MJT", "MOZ", "MPK", "MPW", "MQO", "MRA", "MRW", "NBN", "NCO", "NEG", "NEK", "NJL", "NOX",
    "NQO", "NRA", "NTG", "NVR", "NXT", "OJZ", "OKD", "OME", "OMX", "OVO", "OYR", "PBU", "PFM", "PGM", "PGN", "PIX",
    "PJY", "PNW", "PWG", "QCG", "QES", "QGP", "QHP", "GGH", "GHZ", "GIA", "HYB", "HYC", "HYD", "HYE", "HYF", "HYG",
    "HYH", "HYI", "HYJ", "HYK", "HYL", "HYO", "HYQ", "HYR", "HYS", "HYT", "HYW", "HYY", "HYZ", "HZA", "HZC", "HZD",
    "HZE", "HZF", "HZG", "HZH", "HZJ", "HZL", "HZM", "HZN", "HZO", "HZP", "HZQ", "HZR", "HZS", "HZT", "HZX", "HZY",
    "HZZ", "IAA", "IAB", "IAC", "IAD", "IAE", "IAF", "ICC", "ICD", "ICF", "ICG", "ICH", "ICI", "ICJ", "ICL", "ICM",
    "ICN", "ICO", "ICQ", "ICR", "ICS", "ICT", "ICX", "ICY", "ICZ", "IDA", "IDB", "IDC", "IDD", "IDE", "IDF", "JCH",
    "JCI", "KFC", "KFD", "KFE", "KIP", "KIQ", "KIR", "KIS", "KIT", "KIW", "KIX", "KIY", "KIZ", "KJA", "KJB", "KJC",
    "KJD", "KJE", "KJF", "KJH", "KJK", "KJL", "KJM", "KJN", "KJO", "KJP", "KJQ", "KJR", "KJS", "KJT", "KJW", "KJX",
    "KJY", "KJZ", "KKA", "KKB", "KKC", "KKD", "KKE", "KKF", "KKG", "KKH", "KKI", "KKJ", "KKK", "KKL", "KKM", "KKP",
    "KKQ", "KKR", "KKS", "KKT", "KKW", "KPB", "KQC", "LED", "LEE", "LEF", "LGY", "LNJ", "MXZ", "MYA", "NBK", "NJT",
    "NJW", "NKF", "NOT", "NPQ", "NPR", "NQF", "NQN", "NTR", "OEO", "PDO", "PFG", "PFK", "PHJ", "DAA", "DEP", "DEY",
    "DFD", "DGH", "DGO", "DHF", "LYE", "NQI", "NTH", "NTW", "NUA", "NYI", "OVQ", "OWK", "OWM", "OXP", "OYU", "OZF",
    "PFR", "PFS", "PFX", "PKB", "PZJ", "OYX", "PSU", "QAZ", "QDI", "QNC", "HZI", "IAL", "IAM", "IAT", "IAW", "IAY",
    "IBA", "IBC", "IBD", "IBE", "IBF", "IBG", "IBJ", "IBK", "IBL", "IBM", "IDL", "IDM", "IDN", "IDO", "IDP", "IDQ",
    "IDR", "IDS", "IDT", "IDW", "IDX", "IDY", "IDZ", "IEG", "IEH", "IEO", "IER", "IEX", "IEZ", "IFB", "IFF", "IFH",
    "IFI", "IFJ", "IFL", "IFN", "IFP", "IFQ", "IFT", "IFY", "IFZ", "IGB", "IGC", "IGD", "IGE", "IGF", "IGG", "IGM",
    "IGN", "IHJ", "JCB", "JCC", "JCE", "KDT", "KDW", "KDX", "KDY", "KDZ", "KEE", "KEG", "KEH", "KEI", "KEJ", "KEL",
    "KEM", "KEO", "KEP", "KEQ", "KER", "KES", "KET", "KEW", "KEY", "KFL", "KIM", "KIN", "KIO", "KJG", "KPA", "LDW",
    "LDX", "LDY", "LDZ", "LEA", "LEB", "LEC", "LIJ", "MJI", "NNK", "NNL", "PPM", "PRB", "PSY", "PZM", "PZZ", "QDC",
    "QKQ", "MAO", "MKQ", "MNM", "MTI", "MVC", "MVD", "NSD", "NXG", "OQS", "OTU", "OWE", "PAN", "PDD", "PFU", "PHP",
    "PJG", "PKW", "PLS", "PQP", "QFI", "QFK", "GZC", "HSS", "BXB", "EGJ", "HPH", "IKD", "IKE", "IKK", "IKO", "IKP",
    "IKW", "IKX", "IKZ", "ILC", "ILD", "ILE", "ILG", "ILH", "ILI", "ILJ", "ILK", "ILM", "ILO", "ILP", "ILQ", "ILS",
    "ILT", "ILW", "ILX", "ILY", "ILZ", "IMA", "IMB", "IMC", "IMD", "IME", "IMF", "IMG", "IMI", "IMJ", "IMK", "IML",
    "IMM", "IMN", "IMO", "IMP", "IMQ", "IMR", "IMS", "IMW", "IMX", "IMY", "IMZ", "INA", "INB", "INC", "INE", "INF",
    "ING", "INI", "INK", "INM", "INN", "INO", "INP", "INQ", "INT", "INW", "INX", "INY", "IOA", "IOB", "IOD", "IOE",
    "IOG", "IOL", "ION", "IOQ", "IOR", "IOY", "IOZ", "IPF", "IPG", "IPL", "IPM", "IPO", "IPR", "IPS", "IPT", "IPW",
    "IPX", "IPY", "IQA", "IQB", "IQC", "IQE", "IQF", "IQI", "IQJ", "IQK", "IQM", "IQO", "IQP", "IQQ", "IQW", "IQX",
    "IQZ", "IRA", "IRD", "IRE", "IRN", "IRO", "IRP", "IRQ", "IRR", "IRS", "IRT", "ISA", "ISB", "ISD", "ISH", "ISL",
    "ISM", "ISN", "ISP", "ISR", "ISS", "ISW", "ISY", "ISZ", "ITC", "ITG", "ITH", "ITI", "ITJ", "ITM", "ITN", "ITO",
    "ITQ", "ITS", "ITW", "JFA", "JFB", "JFC", "KFT", "KFW", "KFX", "KGH", "KHX", "KHY", "KIC", "KID", "KNL", "KNM",
    "KNN", "KNO", "KNP", "KTB", "KTC", "KTD", "LBE", "LBG", "LFF", "LSB", "LXF", "LXJ", "LYG", "MNE", "MOC", "MPO",
    "MRI", "NDA", "NGX", "NHF", "NHN", "NJB", "NXE", "NYY", "NYZ", "OAL", "OHI", "OMW", "OSD", "PAE", "PCD", "PCE",
    "PFW", "PHW", "PKS", "PMV", "PQY", "QDT", "QKC", "QKD", "LDK", "LWB", "LXM", "LZW", "MBQ", "MLX", "MLY", "EAG",
    "EAH", "EAP", "EHB", "EHD", "MUH", "FCD", "IYM", "KXZ", "KYA", "LHM", "LHP", "LHQ", "LHR", "LXE", "MAA", "EAJ",
    "EAK", "EAM", "EGT", "EGW", "EGY", "HBK", "ITX", "ITY", "ITZ", "IWA", "IWB", "IWD", "IWE", "IWF", "IWG", "IWH",
    "IWI", "IWJ", "IWK", "IWL", "IWM", "IWN", "IWO", "IWP", "IWQ", "IWR", "IWS", "IWT", "IWW", "IWX", "IWY", "IWZ",
    "IXA", "IXC", "IXD", "IXF", "IXG", "IXH", "IXI", "IXJ", "IXK", "IXL", "IXO", "IXQ", "IXR", "IXW", "IXX", "IXY",
    "IYB", "IYC", "IYD", "IYE", "IYG", "IYH", "IYI", "IYJ", "IYK", "IYL", "IYN", "IYO", "IYP", "IYQ", "IYT", "IYW",
    "IYX", "IYY", "IYZ", "IZC", "IZD", "IZF", "IZG", "IZH", "IZI", "IZJ", "IZL", "IZO", "IZP", "IZS", "IZT", "IZW",
    "IZX", "IZZ", "JAA", "JAB", "JAC", "JAD", "JAE", "JAF", "JAG", "JAI", "JAK", "JAM", "JAN", "JAO", "JAQ", "JAR",
    "JWM", "KGI", "KPQ", "KPR", "KPS", "KPT", "KPW", "KPX", "KPY", "KPZ", "KQA", "KXH", "KXJ", "KXK", "LEK", "LHN",
    "LHO", "LLZ", "LMA", "LMB", "LMC", "LMD", "LNH", "LNI", "LQA", "MOS", "MQB", "MUA", "MUE", "MUI", "MUJ", "MWP",
    "MWW", "MYN", "NCS", "NMP", "NWE", "NZT", "OAS", "OEB", "OII", "OIJ", "OIK", "OIL", "OIM", "OIN", "OIO", "OIP",
    "OMJ", "ONL", "OQQ", "OUO", "OVB", "OWB", "OXO", "PCS", "PCT", "PDK", "PGY", "POK", "QAS", "QBS", "QDQ", "QFM",
    "QHA", "QHX", "QHY", "QIH", "QJU", "QKB", "RCC", "RGC", "KTA", "LMZ", "LNB", "LNC", "LOC", "LSY", "MJS", "MOT",
    "NAN", "NAW", "NCL", "OLQ", "OTE", "PAA", "QNK", "PDT", "MIB", "MIC", "EXM", "EYN", "FBQ", "FDP", "GCG", "GCH",
    "GCI", "GCJ", "MFJ", "NAY", "NEQ", "NGU", "OEJ", "OEK", "OTJ", "OWN", "QGY", "FPX", "FPY", "KGX", "KMF", "LRS",
    "LWH", "MCY", "MHW", "NEC", "OXQ", "PBZ", "PYO", "OEW", "ONA", "NZY", "OAP", "OZC", "NQQ", "POY", "BTA", "BTX",
    "BWA", "BWN", "BWP", "DWG", "DWH", "DWI", "DWO", "EMF", "EPT", "EPW", "EPY", "ESF", "EYR", "EZX", "FAZ", "FGY",
    "FHM", "FHR", "FMK", "FNW", "FQO", "FQP", "FSE", "FSH", "FSM", "FSO", "FSP", "FSQ", "FSS", "FST", "FSW", "FSX",
    "FSY", "FSZ", "FTA", "FTC", "FTD", "FTF", "FTG", "FTH", "FTL", "FTM", "FTN", "FTR", "FTS", "FTT", "FTX", "FTY",
    "FWB", "FWC", "FWD", "FWE", "FWF", "FWG", "FWH", "FWI", "FWJ", "FWK", "FWL", "FWM", "FWP", "FWW", "FWX", "FWY",
    "FWZ", "FXE", "FXF", "FXG", "FXM", "FXN", "FXQ", "FXR", "FXS", "FXT", "FXW", "FYE", "FYF", "FYI", "FZD", "FZE",
    "FZF", "FZG", "FZH", "FZI", "FZK", "FZM", "FZO", "FZP", "FZQ", "FZR", "FZS", "FZT", "FZW", "FZX", "FZY", "FZZ",
    "GAA", "GAB", "GAC", "GAD", "GAE", "GAF", "GAG", "GAH", "GAI", "GAJ", "GAK", "GAL", "GAM", "GAN", "GAO", "GAP",
    "GAQ", "GAR", "GAS", "GAT", "GAW", "GAX", "GBA", "GBB", "GBC", "GBI", "GBJ", "GBN", "GBO", "GBP", "GBQ", "GBS",
    "GBW", "GBX", "GBY", "GBZ", "GCB", "GCC", "GCD", "GCE", "GCY", "GCZ", "GDC", "GDF", "GDG", "GDH", "GDI", "GDJ",
    "GDL", "GDM", "GDN", "GDO", "GDR", "GDT", "GDW", "GDX", "GDY", "GDZ", "GEA", "GEC", "GED", "GEE", "GEF", "GEG",
    "GEH", "GEI", "GEJ", "GEN", "GEO", "GES", "GET", "GEX", "GEY", "GFA", "GFB", "GFC", "GFD", "GFE", "GFF", "GFG",
    "GFI", "GFJ", "HAB", "HAM", "HBI", "HBT", "HCF", "HJE", "HMP", "HRB", "HRM", "HRQ", "HSO", "HTC", "HTD", "HTE",
    "HTF", "HTR", "HTZ", "HWM", "HXD", "HXL", "HXR", "HXT", "HYA", "JCQ", "JCX", "JCY", "JEA", "JEB", "JOS", "JOT",
    "JYC", "JYD", "JYK", "KAG", "KAM", "KAN", "KCE", "KCW", "KCX", "KCY", "KCZ", "KDA", "KDC", "KDD", "KDG", "KFK",
    "KGL", "KGS", "KGW", "KGY", "KGZ", "KIK", "KKY", "KOX", "KOY", "KOZ", "KPJ", "KQM", "LEJ", "LMF", "LMG", "LRO",
    "LRP", "LRW", "LRY", "LRZ", "LSA", "LWG", "LWK", "LXK", "LXZ", "LYV", "LZK", "LZM", "MCA", "MCH", "MCZ", "MDA",
    "MDM", "MGO", "MIJ", "MJG", "MKY", "MNF", "MOQ", "MPN", "MUL", "MUU", "MVR", "MWY", "MXG", "MXW", "NAB", "NAC",
    "NAD", "NAE", "NBY", "NCA", "NEH", "NEU", "NEW", "NEY", "NJC", "NJU", "NJZ", "NLK", "NLL", "NMJ", "NMT", "NMU",
    "NNA", "NNC", "NOC", "NOD", "NOJ", "NOK", "NRB", "NRJ", "NTB", "NUJ", "NUV", "NVH", "NVJ", "NWA", "NWF", "NWJ",
    "NXF", "NYS", "NYT", "OAB", "ODR", "ODU", "OEI", "OEL", "OHO", "OHS", "OHT", "OHV", "OJJ", "OJR", "OJS", "OJT",
    "OJU", "OJV", "OJW", "OKJ", "OKO", "OLI", "OLP", "OMD", "OMP", "ONE", "ONF", "ONG", "ONQ", "OOD", "OOK", "OQL",
    "ORE", "ORK", "ORQ", "OTK", "OUB", "OUH", "OUP", "OVN", "OWR", "OWS", "OWT", "OWU", "OWV", "OWW", "OWX", "OWY",
    "OWZ", "OXA", "OXB", "OXC", "OXD", "OXE", "OXF", "OXG", "OXH", "OXI", "OXJ", "OXK", "OXL", "OXM", "OXN", "OXT",
    "OYW", "PAY", "PBD", "PBX", "PBY", "PDG", "PDW", "PDZ", "PFP", "PGZ", "PIJ", "PJK", "PKD", "PKE", "PKO", "PKT",
    "PMC", "PMX", "PNL", "POD", "PQI", "PQN", "PSN", "PTX", "PUI", "PXN", "PXO", "PXP", "PXQ", "PXR", "PXS", "PXU",
    "PXV", "PXW", "PXX", "PXY", "PXZ", "PYA", "PYB", "PYC", "PYD", "PYE", "PYF", "PYG", "PYH", "PYI", "PYJ", "PYK",
    "PYL", "PZL", "PZQ", "QAI", "QAU", "QCR", "QCY", "QDF", "QDG", "QFC", "QGX", "QHF", "QJF", "QKL", "RAB", "REF",
    "REG", "REH", "DTH", "DZG", "EFQ", "EYF", "FRO", "GEL", "GER", "HMN", "HMO", "KGM", "KGN", "LCJ", "LFL", "LMH",
    "LMI", "LRQ", "LRR", "LXU", "MAW", "MDD", "MGP", "MGQ", "MGR", "MQZ", "MRD", "MSA", "MTJ", "MVF", "MVG", "MWZ",
    "MXE", "MYH", "NBE", "NLQ", "NQK", "NRN", "NSS", "NZF", "OCE", "ODS", "OEE", "OJI", "ONV", "OYD", "PFC", "PHU",
    "PKY", "PUV", "PZC", "DII", "DJA", "DJO", "DJS", "DJY", "DKG", "DKK", "DKO", "DKS", "DKY", "DLC", "DLG", "DLO",
    "DLY", "DMG", "DMS", "DMZ", "DNH", "DOP", "DPA", "DPM", "JSQ", "JXQ", "JXR", "KEA", "KIE", "KZQ", "KZR", "KZS",
    "LDM", "LEQ", "LTB", "MAR", "MGS", "MGU", "MGV", "MKW", "MLM", "NFX", "NFZ", "NGA", "NGD", "NGE", "NGJ", "NGO",
    "PFT", "PQO", "QKE", "QKT", "NGB", "NGK", "NTV", "PFF", "DIC", "DID", "DIE", "DIF", "DIG", "DIH", "DIJ", "DIK",
    "DIL", "DIM", "DIN", "DIO", "DIP", "DIQ", "DIR", "DIS", "DIT", "DIW", "DIX", "DIY", "DIZ", "DJB", "DJC", "DJD",
    "DJE", "DJF", "DJG", "DJH", "DJI", "DJJ", "DJK", "DJL", "DJN", "DJP", "DJQ", "DJR", "DJX", "DJZ", "DKA", "DKB",
    "DKC", "DKD", "DKE", "DKH", "DKJ", "DKN", "DKP", "DKQ", "DKR", "DKT", "DKW", "DKX", "DKZ", "DLA", "DLB", "DLD",
    "DLE", "DLF", "DLH", "DLI", "DLJ", "DLN", "DLP", "DLR", "DLS", "DLT", "DLX", "DLZ", "DMB", "DMF", "DMI", "DMJ",
    "DMK", "DML", "DMN", "DMP", "DMQ", "DMR", "DMT", "DMX", "DMY", "DNA", "DNC", "DND", "DNE", "DNF", "DNG", "DNI",
    "DNJ", "DNK", "DNL", "DNN", "DNO", "DNP", "DNQ", "DNS", "DNT", "DNW", "DNX", "DNZ", "DOA", "DOB", "DOD", "DOE",
    "DOF", "DOG", "DOH", "DOJ", "DOK", "DOL", "DOM", "DON", "DOO", "DOR", "DOS", "DOW", "DOX", "DOY", "DOZ", "DPB",
    "DPD", "DPE", "DPG", "DPH", "DPI", "DPJ", "DPK", "DPL", "DPN", "DPO", "DPP", "DPQ", "JIH", "JII", "JKQ", "JKS",
    "JKT", "JXM", "JXN", "JXO", "KJI", "KLB", "KLN", "KLO", "KLP", "KLQ", "KLR", "KLS", "KLT", "KXS", "KXT", "KZT",
    "KZW", "KZX", "KZY", "KZZ", "LAA", "LAB", "LAC", "LAD", "LAE", "LAF", "LAG", "LAH", "LAI", "LAJ", "LAK", "LAL",
    "LAM", "LAN", "LAR", "LAS", "LAT", "LAW", "LAX", "LAY", "LAZ", "LBA", "LBZ", "LCA", "LCD", "LCE", "LCQ", "LCR",
    "LCS", "LCT", "LCW", "LCY", "LCZ", "LDA", "LDB", "LDC", "LDJ", "LDN", "LDO", "LDP", "LEG", "LEH", "LER", "LES",
    "LET", "LEW", "LFG", "LFH", "LFI", "LFM", "LFN", "LFO", "LFT", "LFW", "LGI", "LGJ", "LGQ", "LGR", "LGS", "LPX",
    "MKU", "MLK", "MOJ", "MRS", "MSL", "NDW", "NFT", "NFV", "NFW", "NFY", "NGG", "NGI", "NGL", "NRP", "NTI", "NWM",
    "OAV", "ODW", "OIW", "ORA", "ORH", "ORI", "OTF", "OUF", "PDY", "POX", "PTG", "PTH", "PUJ", "PUX", "PUY", "PUZ",
    "PVA", "PVB", "PVD", "PVE", "PVF", "PVG", "PVH", "PVI", "PVJ", "PVK", "QAW", "QBF", "QBK", "QDR", "LAO", "LCL",
    "LCM", "LTD", "MGX", "MIG", "MSK", "MVO", "NGM", "OTW", "OXR", "PDB", "PGE", "PKM", "PRF", "PSS", "PZN", "QBB",
    "RAA", "RAC", "RAD", "RAE", "RAF", "RAG", "RAH", "RAI", "RAJ", "RAK", "RAL", "RAM", "RAN", "RAO", "RAP", "RAQ",
    "RAR", "RAS", "RAT", "RAU", "RAV", "RAW", "RAX", "RAY", "RAZ", "RBA", "RBB", "RBC", "RBD", "RBE", "RBF", "RBG",
    "RBH", "RBI", "RBJ", "RBK", "RBL", "RBM", "RBN", "RBO", "RBP", "RBQ", "RBR", "RBS", "RBT", "RBU", "RBV", "RBW",
    "RBX", "RBY", "RBZ", "RCA", "RCB", "RCD", "RCE", "RCF", "RCG", "RCH", "RCI", "RCJ", "RCK", "RCL", "RCM", "RCN",
    "RCO", "RCP", "RCQ", "RCR", "RCS", "RCT", "RCU", "RCV", "RCW", "RCX", "RCY", "RCZ", "RDA", "RDB", "RDC", "RDD",
    "RDE", "RDF", "RDG", "RDH", "RDI", "RDJ", "RDK", "RDL", "RDM", "RDN", "RDO", "RDP", "RDQ", "RDS", "RDT", "RDU",
    "RDV", "RDW", "RDX", "RDY", "RDZ", "REA", "REB", "REC", "RED", "REE", "REI", "REJ", "REK", "REL", "REM", "REN",
    "REO", "REP", "REQ", "RER", "RES", "RET", "REU", "REV", "REW", "REX", "REY", "REZ", "RFA", "RFB", "RFC", "RFD",
    "RFE", "RFF", "RFG", "RFI", "RFJ", "RFK", "RFL", "RFM", "RFN", "RFO", "RFP", "RFQ", "RFR", "RFS", "RFT", "RFU",
    "RFV", "RFW", "RFX", "RFY", "RFZ", "RGA", "RGB", "RGD", "RGE", "RGF", "RGG", "RGH", "RGI", "RGJ", "RGK", "RGM",
    "RGN", "RGO", "RGP", "RGQ", "RGS", "RGT", "RGU", "RGV", "RGW", "RGX", "RGY", "RGZ", "RHA", "RHB", "RHC", "RHD",
    "RHF", "RHG", "RHH", "RHI", "RHJ", "RHK", "RHL", "RHM", "RHN", "RHO", "RHP", "RHQ", "RHR", "RHS", "RHT", "RHU",
    "RHV", "RHW", "RHX", "RHY", "RHZ", "RIA", "RIB", "RIC", "RID", "RIE", "RIF", "RIG", "RIH", "RII", "RIJ", "RIK",
    "RIL", "RIM", "RIN", "RIO", "RIP", "RIQ", "QUJ"
];

export const DocumentStatus = {
    ACCEPTED: "accepted",
    PENDING: "pending",
    REJECTED: "rejected",
};

export const CategoryStatus = {
    APPROVED: "approved",
    PENDING: "pending",
};

export const CustomCategories = {
    ALL: "all",
    UNCATEGORIZED: "uncategorized",
};

export const EventTypes = ["Death", "Injury", "Malfunction", "Other", "No Answer Provided"];

export default {
    MaudeProductCodes,
    PubmedPublicationTypes,
    Review,
    Role,
    Search,
    EventTypes,
    DocumentStatus,
};

export const FDADeviceProblemCodes = {
    "Patient Device Interaction Problem": 4001,
    "Patient-Device Incompatibility": 2682,
    Biocompatibility: 2886,
    "Device Appears to Trigger Rejection": 1524,
    "Inadequacy of Device Shape and/or Size": 1583,
    "Osseointegration Problem": 3003,
    "Failure to Osseointegrate": 1863,
    "Loss of Osseointegration": 2408,
    "Loosening of Implant Not Related to Bone-Ingrowth": 4002,
    "Migration or Expulsion of Device": 1395,
    Expulsion: 2933,
    Migration: 4003,
    "Manufacturing, Packaging or Shipping Problem": 2975,
    "Product Quality Problem": 1506,
    "Dull, Blunt": 2407,
    "Nonstandard Device": 1420,
    "Defective Component": 2292,
    "Defective Device": 2588,
    "Device Damaged Prior to Use": 2284,
    "Packaging Problem": 3007,
    "Difficult to Open or Remove Packaging Material": 2922,
    "Incomplete or Missing Packaging": 2312,
    "Unsealed Device Packaging": 1444,
    "Tear, Rip or Hole in Device Packaging": 2385,
    "Device Misassembled During Manufacturing /Shipping": 2912,
    "Component Misassembled": 4004,
    "Component Missing": 2306,
    "Shipping Damage or Problem": 1570,
    "Delivered as Unsterile Product": 1421,
    "Chemical Problem": 2893,
    "Device Emits Odor": 1425,
    "Device Ingredient or Reagent Problem": 2910,
    "Clumping in Device or Device Ingredient": 1095,
    "Coagulation in Device or Device Ingredient": 1096,
    "Precipitate in Device or Device Ingredient": 1478,
    "Cross Reactivity": 1137,
    Particulates: 1451,
    "High pH": 2426,
    "Low pH": 4005,
    "Unexpected Color": 4055,
    "Improper Chemical Reaction": 2952,
    "Material Integrity Problem": 2978,
    Break: 1069,
    Fracture: 1260,
    "Loss of or Failure to Bond": 1068,
    "Material Fragmentation": 1261,
    "Solder Joint Fracture": 2324,
    "Burst Container or Vessel": 1074,
    Explosion: 4006,
    Crack: 1135,
    Degraded: 1153,
    Calcified: 1077,
    Corroded: 1131,
    "Material Erosion": 1214,
    Pitted: 1460,
    Flaked: 1246,
    "Peeled/Delaminated": 1454,
    "Naturally Worn": 2988,
    "Unraveled Material": 1664,
    "Material Deformation": 2976,
    "Deformation Due to Compressive Stress": 2889,
    "Dent in Material": 2526,
    "Failure to Fold": 1255,
    "Failure to Unfold or Unwrap": 1669,
    "Material Frayed": 1262,
    "Material Invagination": 1336,
    "Material Too Rigid or Stiff": 1544,
    "Material Too Soft/Flexible": 4007,
    "Material Twisted/Bent": 2981,
    Melted: 1385,
    Stretched: 1601,
    "Thickening of Material": 4056,
    "Material Discolored": 1170,
    "Material Disintegration": 1177,
    "Material Opacification": 1426,
    "Material Perforation": 2205,
    "Material Puncture/Hole": 1504,
    "Material Protrusion/Extrusion": 2979,
    "Material Rupture": 1546,
    "Material Separation": 1562,
    "Material Split, Cut or Torn": 4008,
    "Scratched Material": 3020,
    "Mechanical Problem": 1384,
    "Detachment of Device or Device Component": 2907,
    "Device Damaged by Another Device": 2915,
    "Ejection Problem": 4009,
    "Failure to Eject": 4010,
    "Unintended Ejection": 1234,
    "Leak/Splash": 1354,
    "Fluid Leak": 1250,
    "Gas Leak": 2946,
    "Gel Leak": 1267,
    "Radiation Leak": 1357,
    "Perivalvular Leak": 1457,
    "Firing Problem": 4011,
    "Failure to Fire": 2610,
    Misfire: 2532,
    "Mechanical Jam": 2983,
    "Mechanics Altered": 2984,
    "Failure to Align": 2522,
    "Failure to Cut": 2587,
    "Failure to Cycle": 1142,
    "Failure to Form Staple": 2579,
    "Noise, Audible": 3273,
    "Physical Resistance/Sticking": 4012,
    "Retraction Problem": 1536,
    "Structural Problem": 2506,
    Collapse: 1099,
    "Sharp Edges": 4013,
    "Difficult to Fold, Unfold or Collapse": 1254,
    "Difficult to Open or Close": 2921,
    "Incomplete Coaptation": 2507,
    "Unintended Movement": 3026,
    "Device Dislodged or Dislocated": 2923,
    "Device Tipped Over": 2589,
    "Device Fell": 4014,
    "Device Slipped": 1584,
    "Unintended Collision": 1429,
    "Unintended System Motion": 1430,
    Unstable: 1667,
    Vibration: 1674,
    "Inadequate Lubrication": 4057,
    "Optical Problem": 3001,
    Misfocusing: 1401,
    "Optical Decentration": 1360,
    "Optical Discoloration": 2999,
    "Optical Distortion": 3000,
    "Optical Obstruction": 3002,
    "Electrical /Electronic Property Problem": 1198,
    "Capturing Problem": 2891,
    "Failure to Capture": 1081,
    "High Capture Threshold": 3266,
    "Intermittent Capture": 1080,
    "Unstable Capture Threshold": 3269,
    "Continuous Firing": 1123,
    Arcing: 2583,
    "Arcing at Paddles": 1032,
    "Arcing of Electrodes": 2289,
    Sparking: 2595,
    "Battery Problem": 2885,
    "Battery Problem: High Impedance": 2947,
    "Battery Problem: Low Impedance": 2973,
    "Failure to Run on Battery": 1466,
    "Premature Discharge of Battery": 1057,
    "Charging Problem": 2892,
    "Aborted Charge": 2288,
    "Delayed Charge Time": 2586,
    "Failure to Charge": 1085,
    "Failure to Discharge": 1169,
    "Power Problem": 3010,
    "Complete Loss of Power": 4015,
    "Intermittent Loss of Power": 4016,
    "Failure to Power Up": 1476,
    "Unintended Power Up": 1162,
    "Device Sensing Problem": 2917,
    "Decreased Sensitivity": 2534,
    "Increased Sensitivity": 2535,
    "Failure to Analyze Signal": 1539,
    "Failure to Select Signal": 1582,
    "High Sensing Threshold": 2574,
    "Low Sensing Threshold": 2575,
    "Loss of Threshold": 1633,
    "Failure to Sense": 1559,
    "Over-Sensing": 1438,
    "Under-Sensing": 1661,
    "Sensing Intermittently": 1558,
    "Incorrect Interpretation of Signal": 1543,
    "Failure to Conduct": 1114,
    "Interrogation Problem": 4017,
    "Difficult to Interrogate": 1331,
    "Failure to Interrogate": 1332,
    "Pacing Problem": 1439,
    "Failure to Convert Rhythm": 1540,
    "Inaccurate Synchronization": 1609,
    "Inappropriate Waveform": 2536,
    "No Pacing": 3268,
    "Pacemaker Found in Back-Up Mode": 1440,
    "Pacing Asynchronously": 1441,
    "Pacing Inadequately": 1442,
    "Pacing Intermittently": 1443,
    "Pocket Stimulation": 1463,
    "Defibrillation/Stimulation Problem": 1573,
    "Failure to Deliver Shock/Stimulation": 1133,
    "Inappropriate/Inadequate Shock/Stimulation": 1574,
    "Intermittent Shock/Stimulation": 2287,
    "Unintended Electrical Shock": 4018,
    "Grounding Malfunction": 1271,
    "Electrical Overstress": 2924,
    "Electro-Static Discharge": 2149,
    "Failure to Shut Off": 2939,
    "Device Remains Activated": 1525,
    "Unexpected Shutdown": 4019,
    "Electromagnetic Compatibility Problem": 2927,
    "Electromagnetic Interference": 1194,
    "Radiofrequency Interference (RFI)": 2314,
    "Circuit Failure": 1089,
    "Capacitative Coupling": 1079,
    "Electrical Shorting": 2926,
    "Intermittent Continuity": 1121,
    "Impedance Problem": 2950,
    "High impedance": 1291,
    "Low impedance": 2285,
    "Calibration Problem": 2890,
    "Failure to Calibrate": 2440,
    "Failure to Recalibrate": 1517,
    Imprecision: 1307,
    Overcorrection: 3006,
    Undercorrection: 4058,
    "Output Problem": 3005,
    "Audible Prompt/Feedback Problem": 4020,
    "Inappropriate Audible Prompt/Feedback": 2280,
    "Inaudible or Unclear Audible Prompt/Feedback": 2283,
    "No Audible Prompt/Feedback": 2282,
    "Display or Visual Feedback Problem": 1184,
    "Device Displays Incorrect Message": 2591,
    "Display Difficult to Read": 1181,
    "Erratic or Intermittent Display": 1182,
    "Image Display Error/Artifact": 1304,
    "Image Orientation Incorrect": 1305,
    "No Display/Image": 1183,
    "No Visual Prompts/Feedback": 4021,
    "Poor Quality Image": 1408,
    "Visual Prompts will not Clear": 2281,
    "Tactile Prompts/Feedback": 4022,
    "Inappropriate Tactile Prompt/Feedback": 4023,
    "No Tactile Prompts/Feedback": 4024,
    "Energy Output Problem": 1431,
    "Energy Spectrum Incorrect": 1210,
    "Failure to Deliver Energy": 1211,
    "Intermittent Energy Output": 4025,
    "Output above Specifications": 1432,
    "Output below Specifications": 3004,
    "Therapeutic or Diagnostic Output Failure": 3023,
    "Therapy Delivered to Incorrect Body Area": 1508,
    "Radiation Output Problem": 4026,
    "Radiation Output Failure": 4027,
    "Radiation Overexposure": 3017,
    "Radiation Underexposure": 3018,
    "Unexpected/Unintended Radiation Output": 4028,
    "Gas Output Problem": 1266,
    "No Device Output": 1435,
    "Incorrect, Inadequate or Imprecise Result or Readings": 1535,
    "Signal Artifact/Noise": 1036,
    "Failure to Obtain Sample": 2533,
    "False Negative Result": 1225,
    "False Positive Result": 1227,
    "Incorrect Measurement": 1383,
    "Non Reproducible Results": 4029,
    "High Readings": 2459,
    "Low Readings": 2460,
    "High Test Results": 2457,
    "Low Test Results": 2458,
    "Unable to Obtain Readings": 1516,
    "Missing Test Results": 3267,
    "Erratic Results": 4059,
    "Unexpected Therapeutic Results": 1631,
    "Temperature Problem": 3022,
    "Excessive Cooling": 2932,
    "Excessive Heating": 4030,
    "Insufficient Cooling": 1130,
    "Insufficient Heating": 1287,
    "Overheating of Device": 1437,
    "Thermal Decomposition of Device": 1071,
    Fire: 1245,
    "Flare or Flash": 2942,
    Smoking: 1585,
    "Computer Software Problem": 1112,
    "Application Network Problem": 2879,
    "Application Program Problem": 2880,
    "Application Program Freezes, Becomes Nonfunctional": 4031,
    "Application Program Problem: Dose Calculation Error": 1189,
    "Application Program Problem: Medication Error": 3198,
    "Application Program Problem: Parameter Calculation Error": 1449,
    "Application Program Problem: Power Calculation Error": 1473,
    "Application Program Version or Upgrade Problem": 2881,
    "Problem with Software Installation": 3013,
    "Unintended Application Program Shut Down": 4032,
    "Program or Algorithm Execution Problem": 4033,
    "Delayed Program or Algorithm Execution": 4034,
    "Intermittent Program or Algorithm Execution": 4035,
    "Program or Algorithm Execution Failure": 4036,
    "Computer Operating System Problem": 2898,
    "Operating System Becomes Nonfunctional": 2996,
    "Operating System Version or Upgrade Problem": 2997,
    "Computer System Security Problem": 2899,
    "Application Security Problem": 2882,
    "Unauthorized Access to Computer System": 3025,
    "Data Back-Up Problem": 2902,
    "Failure to Back-Up": 1047,
    "Failure to Convert to Back-Up": 1048,
    "Data Problem": 3196,
    "Loss of Data": 2903,
    "Patient Data Problem": 3197,
    "Date/Time-Related Software Problem": 2582,
    "Connection Problem": 2900,
    "Blocked Connection": 2888,
    Decoupling: 1145,
    Disconnection: 1171,
    "Failure to Disconnect": 2541,
    "Loose or Intermittent Connection": 1371,
    Misconnection: 1399,
    "Incomplete or Inadequate Connection": 4037,
    "Fitting Problem": 2183,
    "Communication or Transmission Problem": 2896,
    "Failure to Read Input Signal": 1581,
    "Failure to Transmit Record": 1521,
    "Intermittent Communication Failure": 4038,
    "Telemetry Discrepancy": 1629,
    "Wireless Communication Problem": 3283,
    "Infusion or Flow Problem": 2964,
    "Deflation Problem": 1149,
    "Failure to Deflate": 4060,
    "Unintended Deflation": 4061,
    "Excess Flow or Over-Infusion": 1311,
    "Filling Problem": 1233,
    "Inability to Auto-Fill": 1044,
    Overfill: 2404,
    "Short Fill": 1575,
    "Volume Accuracy Problem": 1675,
    "Filtration Problem": 2941,
    "Inadequate Filtration Process": 2308,
    "Inadequate Ultra Filtration": 1656,
    "Improper Flow or Infusion": 2954,
    Backflow: 1064,
    "Free or Unrestricted Flow": 2945,
    "Gradient Increase": 1270,
    "Inaccurate Delivery": 2339,
    "Inaccurate Flow Rate": 1249,
    "Intermittent Infusion": 2341,
    "Reflux within Device": 1522,
    "Restricted Flow rate": 1248,
    "Tidal Volume Fluctuations": 1634,
    "Inflation Problem": 1310,
    "Insufficient Flow or Under Infusion": 2182,
    "No Flow": 2991,
    "Failure to Deliver": 2338,
    "Failure to Infuse": 2340,
    "Inability to Irrigate": 1337,
    "Obstruction of Flow": 2423,
    "Complete Blockage": 1094,
    "Partial Blockage": 1065,
    "Difficult to Flush": 1251,
    "Pressure Problem": 3012,
    "Decrease in Pressure": 1490,
    "Increase in Pressure": 1491,
    "No Pressure": 2994,
    "Pumping Problem": 3016,
    "Decreased Pump Speed": 1500,
    "Increased Pump Speed": 1501,
    "Failure to Pump": 1502,
    "Pumping Stopped": 1503,
    "Suction Problem": 2170,
    "Decrease in Suction": 1146,
    "Increase in Suction": 1604,
    "Suction Failure": 4039,
    "Priming Problem": 4040,
    "Failure to Prime": 1492,
    "Incomplete or Inadequate Priming": 4041,
    "Air/Gas in Device": 4062,
    "Activation, Positioning or Separation Problem": 2906,
    "Activation Problem": 4042,
    "Activation Failure": 3270,
    "Difficult or Delayed Activation": 2577,
    "Premature Activation": 1484,
    "Self-Activation or Keying": 1557,
    "Key or Button Unresponsive/not Working": 4063,
    "Positioning Problem": 3009,
    "Positioning Failure": 1158,
    "Malposition of Device": 2616,
    "Difficult or Delayed Positioning": 1157,
    "Failure to Advance": 2524,
    "Difficult to Advance": 2920,
    "Difficult to Insert": 1316,
    "Difficult to Remove": 1528,
    "Entrapment of Device": 1212,
    "Separation Problem": 4043,
    "Separation Failure": 2547,
    "Difficult or Delayed Separation": 4044,
    "Premature Separation": 4045,
    "Protective Measures Problem": 3015,
    "Device Alarm System": 1012,
    "Alarm Not Visible": 1022,
    "No Audible Alarm": 1019,
    "Low Audible Alarm": 1016,
    "Delayed Alarm": 1011,
    "False Alarm": 1013,
    "Defective Alarm": 1014,
    "Fail-Safe Problem": 2936,
    "Fail-Safe Did Not Operate": 4046,
    "No Fail-Safe Mechanism": 2990,
    "Failure of Device to Self-Test": 2937,
    "Failure to Auto Stop": 2938,
    "Reset Problem": 3019,
    "Failure to Reset": 1532,
    "Failure to Zero": 1683,
    "Inappropriate or Unexpected Reset": 2959,
    "Premature Indicator Activation": 3011,
    "Premature Elective Replacement Indicator": 1483,
    "Premature End-of-Life Indicator": 1480,
    "Shielding Failure": 1568,
    "Compatibility Problem": 2960,
    "Component or Accessory Incompatibility": 2897,
    "Accessory Incompatible": 1004,
    "Component Incompatible": 1108,
    "Device-Device Incompatibility": 2919,
    "Measurement System Incompatibility": 2982,
    "Unintended Compatibility": 4047,
    "Contamination /Decontamination Problem": 2895,
    Contamination: 1120,
    "Biofilm coating in Device": 1062,
    "Contamination of Device Ingredient or Reagent": 2901,
    "Device Contamination with Body Fluid": 2317,
    "Device Contamination with Chemical or Other Material": 2944,
    "Microbial Contamination of Device": 2303,
    "Device Contaminated During Manufacture or Shipping": 2969,
    "Device Reprocessing Problem": 1091,
    "Failure to Clean Adequately": 4048,
    "Failure to Disinfect": 1175,
    "Flushing Problem": 1252,
    "Problem with Removal of Enzymatic Cleaner": 1213,
    "Problem with Sterilization": 1596,
    "Residue After Decontamination": 2325,
    "Device Contaminated at the User Facility": 4064,
    "Environmental Compatibility Problem": 2929,
    "Ambient Noise Problem": 2877,
    "Ambient Temperature Problem": 2878,
    "Fumes or Vapors": 2529,
    "Fungus in Device Environment": 2316,
    "Moisture or Humidity Problem": 2986,
    "Moisture Damage": 1405,
    "Ventilation Problem in Device Environment": 3027,
    Fogging: 1253,
    "Device Unsafe to Use in Environment": 2918,
    "Environmental Particulates": 2930,
    "Medical Gas Supply Problem": 2985,
    "Electrical Power Problem": 2925,
    "Emergency Power Failure": 1205,
    "Loss of Power": 1475,
    "Power Conditioning Problem": 1474,
    "Installation-Related Problem": 2965,
    "Misassembled During Installation": 4049,
    "Labelling, Instructions for Use or Training Problem": 1318,
    "Device Markings/Labelling Problem": 2911,
    "Expiration Date Error": 2528,
    "Illegible Information": 4050,
    "Inaccurate Information": 4051,
    "Unclear Information": 4052,
    "Missing Information": 4053,
    "Lack of Maintenance Documentation or Guidelines": 2971,
    "Inadequate Instructions for Healthcare Professional": 1319,
    "Inadequate Instructions for Non-Healthcare Professional": 2956,
    "Inadequate or Insufficient Training": 1643,
    "Human-Device Interface Problem": 2949,
    "Device Difficult to Setup or Prepare": 1487,
    "Device Difficult to Program or Calibrate": 1496,
    "Device Difficult to Maintain": 3134,
    "Inadequate User Interface": 2958,
    "Use of Device Problem": 1670,
    "Device Handling Problem": 3265,
    "Use of Incorrect Control Settings": 1126,
    "Improper or Incorrect Procedure or Method": 2017,
    "Off-Label Use": 1494,
    Misassembled: 1398,
    "Misassembly by Users": 3133,
    "Misassembly During Maintenance/Repair": 4054,
    "Adverse Event Without Identified Device or Use Problem": 2993,
    "No Apparent Adverse Event": 3189,
    "Insufficient Information": 3190,
    "Appropriate Term/Code Not Available": 3191,
};

export const FDAPatientProblemCodes = {
    "Abdominal Cramps": 2543,
    "Abdominal Distention": 2601,
    "Abdominal Pain": 1685,
    "Abnormal Blood Gases": 1034,
    "Abnormal Vaginal Discharge": 2123,
    Abortion: 1688,
    Abrasion: 1689,
    Abscess: 1690,
    "Acanthamoeba Keratitis": 1945,
    Achalasia: 1692,
    "Acoustic Shock": 1693,
    "Acoustic Trauma": 1694,
    "Adhesion(s)": 1695,
    "Adult Respiratory Distress Syndrome": 1696,
    Ageusia: 4423,
    "Air Embolism": 1697,
    "Airway Obstruction": 1699,
    "Alteration in Body Temperature": 4568,
    "Ambulation Difficulties": 2544,
    Amnionitis: 2217,
    "Anaphylactic Shock": 1703,
    Anaphylactoid: 2218,
    Anemia: 1706,
    Aneurysm: 1708,
    Angina: 1710,
    Angioedema: 4536,
    Ankylosis: 4527,
    Anxiety: 2328,
    "Aortic Valve Insufficiency/ Regurgitation": 4450,
    "Aortic Valve Stenosis": 1717,
    Aphonia: 4409,
    Apnea: 1720,
    "Appropriate Term / Code Not Available": 4581,
    Arrhythmia: 1721,
    "Arteriosclerosis/ Atherosclerosis": 4437,
    Arthralgia: 2355,
    Arthritis: 1723,
    Ascites: 2596,
    "Aspiration Pneumonitis": 4455,
    "Aspiration/Inhalation": 1725,
    Asthma: 1726,
    Asystole: 4442,
    "Atrial Fibrillation": 1729,
    "Atrial Flutter": 1730,
    "Autoimmune Disorder": 1732,
    "Awareness during Anaesthesia": 1707,
    "Bacterial Infection": 1735,
    "Balance Problems": 4401,
    "Biliary Cirrhosis": 1736,
    Blister: 4537,
    "Blurred Vision": 2137,
    "Bone Cement Implantation Syndrome": 4583,
    "Bone Fracture(s)": 1870,
    "Bone Shedding Debris": 4557,
    "Bowel Burn": 1756,
    "Bowel Perforation": 2668,
    Bradycardia: 1751,
    "Brain Injury": 2219,
    "Breast Cancer": 1759,
    "Breast Discomfort/Pain": 4504,
    "Breast Implant Associated Anaplastic Large Cell Lymphoma (BIA ALCL)": 4549,
    "Breast Mass": 2439,
    "Bronchial Hemorrhage": 4456,
    Bronchitis: 1752,
    Bronchopneumonia: 2437,
    Bronchospasm: 2598,
    "Bruise/Contusion": 1754,
    Bruxism: 4427,
    "Burn(s)": 1757,
    "Burning Sensation": 2146,
    "Calcium Deposits/Calcification": 1758,
    "Cancer Cells Dissemination": 4541,
    Cancer: 3262,
    "Capsular Bag Tear": 2639,
    "Capsular Contracture": 1761,
    "Cardiac Arrest": 1762,
    "Cardiac Enzyme Elevation": 1838,
    "Cardiac Perforation": 2513,
    "Cardiac Tamponade": 2226,
    "Cardiogenic Shock": 2262,
    Cardiomyopathy: 1764,
    "Cardiovascular Insufficiency": 4445,
    Cataract: 1766,
    "Caustic/Chemical Burns": 2549,
    Cellulitis: 1768,
    Cephalohematoma: 2599,
    "Cerebral Edema": 4403,
    "Cerebral Hyperperfusion Syndrome": 4404,
    "Cerebral Ventriculomegaly": 2133,
    "Cerebrospinal Fluid Leakage": 1772,
    "Cervical Changes": 1773,
    "Chemical Exposure": 2570,
    Chemosis: 1775,
    "Chest Pain": 1776,
    Chills: 2191,
    Choking: 2464,
    Chorioamnionitis: 1777,
    "Chronic Obstructive Pulmonary Disease (COPD)": 2237,
    "Coagulation Disorder": 1779,
    "Cognitive Changes": 2551,
    Coma: 2417,
    "Complete Induced Abortion": 2212,
    "Complete Neonatal Hearing Loss": 4515,
    Concussion: 2192,
    "Confusion/ Disorientation": 2553,
    "Congenital Defect/Deformity": 1782,
    Conjunctivitis: 1784,
    "Connective Tissue Disease": 1786,
    Constipation: 3274,
    "Contact Dermatitis": 4546,
    "Convulsion, Clonic": 2222,
    "Convulsion, Tonic": 2223,
    "Convulsion/Seizure": 4406,
    "Corneal Abrasion": 1789,
    "Corneal Clouding/Hazing": 1878,
    "Corneal Decompensation": 1790,
    "Corneal Edema": 1791,
    "Corneal Epithelial Microcysts": 4465,
    "Corneal Infiltrates": 2231,
    "Corneal Pannus": 1447,
    "Corneal Perforation": 1792,
    "Corneal Scar": 1793,
    "Corneal Stromal Edema": 1824,
    "Corneal Touch": 1794,
    "Corneal Ulcer": 1796,
    Cough: 4457,
    "Cramp(s) /Muscle Spasm(s)": 4521,
    "Crushing Injury": 1797,
    "Cryogenic Burn": 4539,
    "Cusp Tear": 2656,
    Cyanosis: 1798,
    "Cyst(s)": 1800,
    "Cytomegalovirus (CMV) Infection": 2220,
    "Damage to Ligament(s)": 1952,
    "Decreased Appetite": 4569,
    "Decreased Peak Expiratory Flow rate": 2435,
    "Decreased Respiratory Rate": 2485,
    "Decreased Sensitivity": 2683,
    "Deformity/ Disfigurement": 2360,
    Dehydration: 1807,
    Dementia: 4405,
    Deposits: 1809,
    Depression: 2361,
    "Device Embedded In Tissue or Plaque": 3165,
    "Device Overstimulation of Tissue": 1991,
    "Diabetic Ketoacidosis": 2364,
    Diaphoresis: 2452,
    Diarrhea: 1811,
    "Difficulty Chewing": 4522,
    "Diminished Pulse Pressure": 2606,
    Discomfort: 2330,
    "Disseminated Intravascular Coagulation (DIC)": 1813,
    Distress: 2329,
    Dizziness: 2194,
    "Double Capsule": 4579,
    "Drug Resistant Bacterial Infection": 4553,
    "Dry Eye(s)": 1814,
    "Dry Mouth": 4485,
    Dysgeusia: 4424,
    Dyskinesia: 2363,
    Dyspareunia: 4505,
    "Dysphagia/ Odynophagia": 1815,
    Dysphasia: 2195,
    Dyspnea: 1816,
    Dysuria: 2684,
    "Easy Bruising": 4558,
    Ecchymosis: 1818,
    "Ectopic Heartbeat": 4443,
    "Ectopic Pregnancy": 1819,
    Eczema: 4547,
    "Electric Shock": 2554,
    "Electrolyte Imbalance": 2196,
    "Embolism/Embolus": 4438,
    "Emotional Changes": 1831,
    Encephalitis: 2429,
    Encephalocele: 4402,
    Encephalopathy: 1833,
    Endocarditis: 1834,
    Endophthalmitis: 1835,
    Epilepsy: 4407,
    Epistaxis: 4458,
    "Erectile Dysfunction": 4511,
    Erosion: 1750,
    Eructate: 1839,
    Erythema: 1840,
    "Excessive Tear Production": 2235,
    "Exposure to Body Fluids": 1745,
    Exsanguination: 1841,
    "External Prosthetic Device Pain": 4560,
    Extravasation: 1842,
    "Extreme Exhaustion": 1843,
    "Eye Burn": 2523,
    "Eye Infections": 4466,
    "Eye Injury": 1845,
    "Eye Pain": 4467,
    "Failure of Implant": 1924,
    "Failure to Anastomose": 1028,
    Fall: 1848,
    "Fallopian Tube Perforation": 4506,
    Fasciitis: 2375,
    Fatigue: 1849,
    "Fecal Incontinence": 4571,
    "Feeding Problem": 1850,
    "Fetal Distress": 1856,
    Fever: 1858,
    Fibromyositis: 1860,
    Fibrosis: 3167,
    Fistula: 1862,
    Flashers: 1864,
    Flatus: 1865,
    "Fluid Discharge": 2686,
    "Forced Expiratory Volume Decreased": 2430,
    "Forced Expiratory Volume Increased": 2431,
    "Foreign Body Embolism": 4439,
    "Foreign Body In Patient": 2687,
    "Foreign Body Reaction": 1868,
    "Foreign Body Sensation in Eye": 1869,
    "Full thickness (Third Degree) Burn": 2696,
    "Fungal Infection": 2419,
    Gastritis: 1874,
    "Gastroesophageal Burn": 4475,
    "Gastrointestinal Hemorrhage": 4476,
    "Gastrointestinal Regurgitation": 4477,
    "Genital Bleeding": 4507,
    Glaucoma: 1875,
    Glossitis: 4490,
    Granuloma: 1876,
    "Great Vessel Perforation": 2152,
    "Hair Loss": 1877,
    Hallucination: 4428,
    Halo: 2227,
    "Head Injury": 1879,
    Headache: 1880,
    "Hearing Impairment": 1881,
    "Heart Block": 4444,
    "Heart Failure/Congestive Heart Failure": 4446,
    Hematemesis: 4478,
    Hematoma: 1884,
    Hematuria: 2558,
    Hemolysis: 1886,
    "Hemolytic Anemia": 2279,
    Hemoptysis: 1887,
    "Hemorrhage/Bleeding": 1888,
    "Hemorrhagic Stroke": 4417,
    Hemothorax: 1896,
    Hepatitis: 1897,
    Hernia: 2240,
    Hiccups: 1899,
    "High Blood Pressure/ Hypertension": 1908,
    "High Oxygen Saturation": 2478,
    "High Pulmonary Arterial Wedge Pressure": 2480,
    "Hip Fracture": 2349,
    "Hormonal Imbalance": 4495,
    "Hot Flashes/Flushes": 2153,
    Hydrocephalus: 3272,
    Hyperbilirubinemia: 1903,
    Hyperemia: 1904,
    Hyperesthesia: 4420,
    Hyperextension: 4523,
    Hyperglycemia: 1905,
    Hypernatremia: 2242,
    Hyperplasia: 1906,
    "Hypersensitivity/Allergic reaction": 1907,
    Hyperthermia: 1909,
    Hyperventilation: 1910,
    Hypervolemia: 2664,
    Hyphema: 1911,
    Hypoesthesia: 2352,
    Hypoglycemia: 1912,
    "Hypoglycemic Shock": 4575,
    Hyponatremia: 4494,
    Hypopyon: 1913,
    Hypothermia: 1915,
    Hypoventilation: 1916,
    Hypovolemia: 2243,
    "Hypovolemic Shock": 1917,
    "Hypoxia in Utero": 2210,
    Hypoxia: 1918,
    "Idioventricular Rhythm": 1923,
    Immunodeficiency: 2156,
    "Impaired Healing": 2378,
    "Implant Pain": 4561,
    "Inadequate Osseointegration": 2646,
    "Inadequate Pain Relief": 2388,
    "Incompetent Cervix": 1927,
    "Incomplete Induced Abortion": 2213,
    Incontinence: 1928,
    "Increased Appetite": 4570,
    "Increased Intra-Peritoneal Volume (IIPV)": 4498,
    "Increased Peak Expiratory Flow rate": 2436,
    "Increased Respiratory Rate": 2486,
    "Increased Sensitivity": 4538,
    "Induced Abortion": 2214,
    "Infiltration into Tissue": 1931,
    Inflammation: 1932,
    "Injection Site Reaction": 4562,
    "Insufficient Information": 4580,
    "Intermenstrual Bleeding": 2665,
    "Internal Organ Perforation": 1987,
    "Intervertebral Disc Compression or Protrusion": 4524,
    "Intra-Abdominal Hemorrhage": 4479,
    "Intracranial Hemorrhage": 1891,
    "Intraocular Infection": 1933,
    "Intraocular Pressure Decreased": 4468,
    "Intraocular Pressure Increased": 1937,
    "Intraoperative Pain": 2662,
    Iritis: 1940,
    "Irregular Pulse": 2469,
    Irritability: 2421,
    "Ischemia Stroke": 4418,
    Ischemia: 1942,
    "Ischemic Heart Disease": 2493,
    "Itching Sensation": 1943,
    Jaundice: 2187,
    "Joint Contracture": 4528,
    "Joint Dislocation": 2374,
    "Joint Laxity": 4526,
    Keratitis: 1944,
    "Kidney Infection": 4502,
    "Laceration(s) of Esophagus": 2398,
    "Laceration(s)": 1946,
    "Lactate Dehydrogenase Increased": 4567,
    Leiomyosarcoma: 4550,
    Lethargy: 2560,
    "Limb Fracture": 4518,
    "Liver Damage/Dysfunction": 1954,
    "Liver Failure": 4492,
    "Liver Laceration(s)": 1955,
    "Local Reaction": 2035,
    "Localized Skin Lesion": 4542,
    "Loss of consciousness": 2418,
    "Loss of Range of Motion": 2032,
    "Loss of Vision": 2139,
    "Low Apgar Score": 1718,
    "Low Blood Pressure/ Hypotension": 1914,
    "Low Cardiac Output": 2501,
    "Low Oxygen Saturation": 2477,
    "Low Pulmonary Arterial Wedge Pressure": 2479,
    "Low White Blood Cell Count": 4433,
    Lymphoma: 3263,
    "Macular Edema": 1822,
    Malaise: 2359,
    "Malunion of Bone": 4529,
    "Mediastinal Shift": 2198,
    Melena: 4480,
    "Memory Loss/Impairment": 1958,
    Meningitis: 2389,
    Menorrhagia: 4508,
    "Menstrual Irregularities": 1959,
    "Metal Related Pathology": 4530,
    "Microcystic Edema": 1823,
    "Micturition Urgency": 1871,
    "Missed Abortion": 2215,
    "Mitral Perforation": 2512,
    "Mitral Valve Insufficiency/ Regurgitation": 4451,
    "Mitral Valve Stenosis": 1965,
    "Movement Disorder": 4412,
    "Multiple Fractures": 4519,
    "Multiple Organ Dysfunction Syndrome": 3261,
    "Muscle Hypotonia": 4531,
    "Muscle Weakness": 1967,
    "Muscle/Tendon Damage": 4532,
    "Muscular Rigidity": 1968,
    Myalgia: 2238,
    "Myocardial Contusion": 1763,
    "Myocardial Hypertrophy": 4447,
    "Myocardial Infarction": 1969,
    Myocarditis: 2470,
    "Nasal Obstruction": 2466,
    Nausea: 1970,
    "Neck Pain": 2433,
    "Neck Stiffness": 2434,
    Necrosis: 1971,
    "Needle Stick/Puncture": 2462,
    "Neonatal Deformities": 1974,
    "Neonatal Hearing Impairment": 1975,
    Neovascularization: 1978,
    "Nerve Damage": 1979,
    "Nervous System Injury": 2689,
    Neuralgia: 4413,
    "Neurogenic Shock": 2265,
    Neuropathy: 1983,
    "Nipple Sensation Changes": 2162,
    "Nipple Ulceration": 2441,
    "No Clinical Signs, Symptoms or Conditions": 4582,
    Nodule: 4551,
    "Non specific EKG/ECG Changes": 1817,
    "Nonunion Bone Fracture": 2369,
    Numbness: 2415,
    "Obstruction/Occlusion": 2422,
    "Optical Nerve Damage": 1986,
    "Organ Dehiscence": 2502,
    Ossification: 1428,
    Osteolysis: 2377,
    Osteomyelitis: 4533,
    "Osteopenia/ Osteoporosis": 2651,
    "Overinflation of Lung": 2397,
    Oversedation: 1990,
    "Overwear Syndrome": 2234,
    Pain: 1994,
    Pallor: 2468,
    Pancreatitis: 4481,
    Paralysis: 1997,
    Paraplegia: 2448,
    Paresis: 1998,
    Paresthesia: 4421,
    "Partial Hearing Loss": 4472,
    "Partial Neonatal Hearing loss": 4516,
    "Partial thickness (Second Degree) Burn": 2694,
    Peeling: 1999,
    "Pelvic Inflammatory Disease": 2000,
    "Perforation of Esophagus": 2399,
    "Perforation of Vessels": 2135,
    Perforation: 2001,
    "Pericardial Effusion": 3271,
    Pericarditis: 4448,
    "Perinatal Brain Injury": 1852,
    "Peripheral Edema": 4578,
    "Peripheral Nervous Injury": 4414,
    "Peritoneal Laceration(s)": 2003,
    Peritonitis: 2252,
    Pharyngitis: 2367,
    Phlebitis: 2004,
    "Phosphene Visualization": 2164,
    Phototoxicity: 2165,
    "Physical Asymmetry": 4573,
    "Physical Entrapment": 2327,
    "Pleural Effusion": 2010,
    "Pleural Empyema": 4459,
    Pneumonia: 2011,
    Pneumothorax: 2012,
    "Pocket Erosion": 2013,
    Polydipsia: 2604,
    Polymyositis: 1992,
    "Positive antinuclear antibodies (ANA)": 2015,
    "Post Operative Wound Infection": 2446,
    "Post Traumatic Wound Infection": 2447,
    "Pregnancy with a Contraceptive Device": 4517,
    "Premature Labor": 2465,
    "Premature Menopause": 4496,
    "Pressure Sores": 2326,
    Presyncope: 4410,
    Prolapse: 2475,
    Pseudoaneurysm: 2605,
    Ptosis: 2620,
    "Pulmonary Dysfunction": 2019,
    "Pulmonary Edema": 2020,
    "Pulmonary Embolism": 1498,
    "Pulmonary Emphysema": 1832,
    "Pulmonary Hypertension": 4460,
    "Pulmonary Infarction": 2021,
    "Pulmonary Valve Insufficiency/ Regurgitation": 4452,
    "Pulmonary Valve Stenosis": 2024,
    "Pupillary Block": 2026,
    "Purulent Discharge": 1812,
    "Pyogenic Infection": 4554,
    "Pyrosis/Heartburn": 1883,
    Quadriplegia: 2449,
    "Radiation Burn": 1755,
    "Radiation Sickness Syndrome": 2256,
    Radiodermatitis: 2255,
    Rash: 2033,
    "Reaction to Medicinal Component of Device": 4574,
    "Rectal Anastomotic Leakage": 4482,
    "Rectovaginal Fistula": 4509,
    "Red Eye(s)": 2038,
    "Renal Failure": 2041,
    "Renal Impairment": 4499,
    "Respiratory Acidosis": 2482,
    "Respiratory Alkalosis": 2483,
    "Respiratory Arrest": 4461,
    "Respiratory Distress Syndrome of Newborns": 2046,
    "Respiratory Failure": 2484,
    "Respiratory Insufficiency": 4462,
    "Respiratory Tract Infection": 2420,
    Restenosis: 4576,
    "Retinal Degeneration": 2049,
    "Retinal Detachment": 2047,
    "Retinal Injury": 2048,
    "Retinal Tear": 2050,
    "Retroperitoneal Hemorrhage": 4483,
    "Rheumatoid Arthritis": 1724,
    Rupture: 2208,
    "Ruptured Aneurysm": 4436,
    "Salivary Gland Problem": 4484,
    "Salivary Hypersecretion": 4486,
    "Scar Tissue": 2060,
    "Sensitivity of Teeth": 2427,
    Sepsis: 2067,
    "Septic Shock": 2068,
    Seroma: 2069,
    "Sexual Dysfunction": 4510,
    "Sexually Transmitted Infection": 4555,
    "Shaking/Tremors": 2515,
    "Shock from Patient Lead(s)": 3162,
    Shock: 2072,
    "Sinus Perforation": 2277,
    "Skin Burning Sensation": 4540,
    "Skin Discoloration": 2074,
    "Skin Disorders": 4543,
    "Skin Erosion": 2075,
    "Skin Infection": 4544,
    "Skin Inflammation/ Irritation": 4545,
    "Skin Tears": 2516,
    "Skull Fracture": 2077,
    "Sleep Dysfunction": 2517,
    Sneezing: 2251,
    "Solid Tumour": 4552,
    "Sore Throat": 2396,
    "Speech Disorder": 4415,
    "Spinal Arachnoiditis": 4416,
    "Spinal Column Injury": 2081,
    "Spinal Cord Injury": 2432,
    "Spontaneous Abortion": 4514,
    "Stacking Breaths": 1593,
    "Status Epilepticus": 4408,
    "Stenosis of the esophagus": 4487,
    Stenosis: 2263,
    "Stomach Ulceration": 4488,
    Stomatitis: 4489,
    Strangulation: 2084,
    "Stroke/CVA": 1770,
    "Subclinical Infection": 2247,
    "Subcutaneous Nodule": 4548,
    Subluxation: 4525,
    "Suicidal Ideation": 4429,
    "Superficial (First Degree) Burn": 2685,
    "Swelling/ Edema": 4577,
    "Swollen Lymph Nodes/Glands": 4432,
    "Syncope/Fainting": 4411,
    Synovitis: 2094,
    Tachycardia: 2095,
    "Tactile Disorders": 4419,
    "Taste Disorder": 4422,
    "Teratogenic Effects": 2273,
    Thrombocytopenia: 4431,
    Thromboembolism: 2654,
    "Thrombosis/Thrombus": 4440,
    "Tics/Tremor": 4425,
    Tinnitus: 2103,
    "Tissue Breakdown": 2681,
    "Tooth Fracture": 2428,
    "Total Hearing Loss": 4473,
    Toxemia: 2207,
    "Toxic Anterior Segment Syndrome (TASS)": 4469,
    "Toxic Shock Syndrome": 2108,
    "Toxins In Children": 2098,
    "Transient Ischemic Attack": 2109,
    "Transmissible Spongiform Encephalopathy(TSE)": 4556,
    "Tricuspid Valve Insufficiency/ Regurgitation": 4453,
    "Tricuspid Valve Stenosis": 2113,
    "Twiddlers Syndrome": 4563,
    Twitching: 2172,
    "UGH (Uveitis Glaucoma Hyphema) Syndrome": 4470,
    Ulcer: 2274,
    "Undesired Nerve Stimulation": 1980,
    "Unequal Limb Length": 4534,
    "Unintended Extubation": 4564,
    "Unintended Radiation Exposure": 4565,
    "Unspecified Blood or Lymphatic problem": 4434,
    "Unspecified Ear or Labyrinth Problem": 4474,
    "Unspecified Eye / Vision Problem": 4471,
    "Unspecified Gastrointestinal Problem": 4491,
    "Unspecified Heart Problem": 4454,
    "Unspecified Hepatic or Biliary Problem": 4493,
    "Unspecified Immune System Problem": 4435,
    "Unspecified Infection": 1930,
    "Unspecified Kidney or Urinary Problem": 4503,
    "Unspecified Mental, Emotional or Behavioural Problem": 4430,
    "Unspecified Musculoskeletal problem": 4535,
    "Unspecified Nervous System Problem": 4426,
    "Unspecified Reproductive System or Breast Problem": 4513,
    "Unspecified Respiratory Problem": 4464,
    "Unspecified Tissue Injury": 4559,
    "Unspecified Vascular Problem": 4441,
    Uremia: 2188,
    "Urethral Stenosis/Stricture": 4501,
    "Urinary Frequency": 2275,
    "Urinary Incontinence": 4572,
    "Urinary Retention": 2119,
    "Urinary Tract Infection": 2120,
    Urticaria: 2278,
    "Uterine Perforation": 2121,
    Uveitis: 2122,
    "Vaginal Mucosa Damage": 2124,
    "Valvular Insufficiency/ Regurgitation": 4449,
    "Valvular Stenosis": 2697,
    "Vascular Dissection": 3160,
    Vasoconstriction: 2126,
    Vasodilatation: 2127,
    "Ventilator Dependent": 2395,
    "Ventricular Fibrillation": 2130,
    "Vertebral Fracture": 4520,
    Vertigo: 2134,
    "Vesicovaginal Fistula": 4512,
    "Viral Infection": 2248,
    "Visual Disturbances": 2140,
    "Visual Impairment": 2138,
    "Vitamin Deficiency": 4497,
    "Vitreous Detachment": 2445,
    "Vitreous Floaters": 1866,
    "Vitreous Hemorrhage": 2143,
    "Vitreous Loss": 2142,
    Vitritis: 2181,
    Vomiting: 2144,
    "Weight Changes": 2607,
    "Wheal(s)": 2241,
    Wheezing: 4463,
    "Wound Dehiscence": 1154,
    Wrinkling: 2613,
    "Zonular Dehiscence": 2698,
};

export const ActionType = {
    ARTICLE: "article",
};

export const ReasonType = {
    DUPLICATE: "Duplicate Article",
    BOOK: "White Papers/Books/Thesis",
    LOW_SCORE: "Insufficient Score",
};

export const LanguageCodes = {
    AF: "Afrikaans ",
    SQ: "Albanian",
    AM: "Amharic",
    AR: "Arabiic",
    HY: "Armenian",
    AZ: "Azerbaijani",
    BN: "Bengali",
    BS: "Bosnian",
    BG: "Bulgarian",
    CA: "Catalan",
    ZH: "Chinese",
    CS: "Czech",
    DA: "Danish",
    NL: "Dutch",
    EN: "English",
    EO: "Esperanto",
    ET: "Estonian",
    FI: "Finnish",
    FR: "French",
    KA: "Georgian",
    DE: "German",
    GD: "Scottish Gaelic",
    EL: "Greek",
    HE: "Hebrew",
    HI: "Hindi",
    HR: "Croatian",
    HU: "Hungarian",
    IS: "Icelandic",
    ID: "Indonesian",
    IT: "Italian",
    JA: "Japanese",
    RW: "Kinyarwanda",
    KO: "Korean",
    LA: "Latin",
    LV: "Latvian",
    LT: "Lithuanian",
    MK: "Macedonian",
    ML: "Malayalam",
    MI: "Maori",
    MS: "Malay",
    NO: "Norwegian",
    FA: "Persian, Iranian",
    PL: "Polish",
    PT: "Portuguese",
    PS: "Pushto",
    RO: "Romanian",
    RU: "Russian",
    SA: "Sanskrit",
    SK: "Slovak",
    SL: "Slovenian",
    ES: "Spanish",
    SR: "Serbian",
    SV: "Swedish",
    TH: "Thai",
    TR: "Turkish",
    UK: "Ukrainian",
    UR: "Urdu",
    VI: "Vietnamese",
    CY: "Welsh",
};

export const PubMedLanguages = Object.keys(LanguageCodes);
export const GoogleLanguages = [
    "ZH",
    "NL",
    "EN",
    "FR",
    "DE",
    "IT",
    "JA",
    "KO",
    "PL",
    "PT",
    "ES",
    "TR",
];

export const ArticleReviewState = {
    L1_INCLUDED: "L1 Included",
    L1_EXCLUDED: "L1 Excluded",
    L1_PENDING: "L1 Pending",
    L2_INCLUDED: "L2 Included",
    L2_EXCLUDED: "L2 Excluded",
    L2_INPROGRESS: "L2 In Progress",
    L2_PENDING: "L2 Pending",
};

export const NotificationType = {
    ArticleComment: "articleComment",
    SearchComment: "searchComment",
    ProjectComment: "projectComment",
    DocumentComment: "documentComment",
    ReportDocumentComment: "reportDocumentComment",
    DictionaryComment: "dictionaryComment",
    AbbreviationComment: "abbreviationComment",
    ReferenceComment: "referenceComment",
    ReviewStage: "reviewStage",
    ReviewComplete: "reviewComplete",
    SearchAutoUpdate: "searchAutoUpdate",
};

export const Activities = {
    ARTICLE: "article",
    ARTICLE_COMMENT: "article_comment",
    ARTICLE_HIGHLIGHT: "article_highlight",
    SEARCH: "search",
    SEARCH_COMMENT: "search_comment",
    PROJECT: "project",
    PROJECT_CONFIG: "project_config",
    PROJECT_COMMENT: "project_comment",
    CLIENT: "client",
    CLIENT_CONFIG: "client_config",
    DOCUMENT: "document",
    DOCUMENT_BOOKMARK: "document_bookmark",
    DOCUMENT_CATEGORY: "document_category",
    DOCUMENT_COMMENT: "document_comment",
    USER: "user",
    REPORT_DOCUMENT: "report_document",
    REPORT_DOCUMENT_COMMENT: "report_document_comment",
    REPORT_DOCUMENT_SECTION: "report_document_section",
    REPORT_DOCUMENT_REVIEW: "report_document_review",
    DICTIONARY: "dictionary",
    DICTIONARY_COMMENT: "dictionary_comment",
    ABBREVIATION: "abbreviation",
    ABBREVIATION_COMMENT: "abbreviation_comment",
    CITATION: "citation",
    REFERENCE_COMMENT: "reference_comment",
};

export const ActivityLogView = {
    Sidebar: "sidebar",
    Main: "main",
};

export const ProjectStatus = {
    DRAFT: "draft",
    ACTIVE: "active",
    COMPLETE: "complete",
    HOLD: "onhold",
};

export const PageSize = {
    A4P: 794,
    A4L: 1123,
    A4: "A4",
    Letter: "Letter",
};

export const FontFace = {
    TIMES: "Times New Roman",
    ARIAL: "Arial",
    CALIBRI: "Calibri",
};

export const CommentStatus = {
    PENDING: "pending",
    RESOLVED: "resolved",
};

export const CommentStatusType = {
    REPLY: "reply",
    RESOLVE: "resolve",
    UNRESOLVE: "unresolve",
};

export const AppendixSections = {
    APPENDIX: "appendix",
    TABLES: "tables",
    FIGURES: "figures",
    CITATIONS: "citations",
    ABBREVIATIONS: "abbreviations",
};

export const ReportDocumentSection = {
    image: {
        minWidth: 15,
        // subtract the width allowance for borders etc.
        getMaxWidth: (width) => width - 10,
        getCellMaxWidth: (width) => width - 20,
    },
    CAPTIS_LINK: {
        MARK_NAME: "captis-link",
        SOURCE_DOCUMENT_LINK: "source-document-link",
        TYPE: {
            SOURCE_DOCUMENT: "source-document",
        },
    },
    ARTICLE_TEXT: {
        MARK_NAME: "article-text",
        ARTICLE_TEXT: "article-text",
    },
    EXTERNAL_LINK: {
        validate: (href) =>
            /^https?:\/\//.test(href) && !/^https?:\/\/.+celegence\.com|localhost/.test(href),
    },
    COMMENT: {
        MARK_NAME: "commentMark",
        FULL_NODES: [
            "abbreviation",
            "citation",
            "citation-group",
            "cross-reference",
            "dictionary",
            "custom-object",
        ],
    },
    HELP_TEXT: {
        MARK_NAME: "helpTextMark",
    },
    SAMPLE_TEXT: {
        MARK_NAME: "sampleTextMark",
    },
    CUSTOM_OBJECT: {
        NODE_NAME: "custom-object",
        SHOW_SELECT_MODAL_EVENT: "show-custom-object-select-modal",
        SHOW_EDIT_MODAL_EVENT: "show-custom-object-edit-modal",
        TYPES: {
            ABBREVIATIONS: "abbreviations",
            REFERENCES: "references",
        },
        REFERENCES_VARIANT: {
            ALL: "all",
            LITERATURE: "literature",
            INTERNAL: "internal",
        },
    },
};

export const ReportTypes = {
    PRISMA_DIAGRAM: "prisma-flow",
    ARTICLE_FLOW: "article-flow",
    L1_EXCLUSION_SUMMARY: "l1-exclusion-summary",
    L2_REVIEW_SUMMARY: "l2-review-summary",
    ARTICLE_DATA_SHEET: "article-data-sheet",
    APPENDIX: "appendix",
    SEARCH_PARAMS: "search-params",
    CUSTOM: "custom",
};

export const SidebarTabKeys = {
    COMMENTS_TAB: "comments",
    BOOKMARKS_TAB: "bookmarks",
    VERSIONS_TAB: "versions",
    ACTIVITY_TAB: "activity",
    HIGHLIGHT_TAB: "highlight",
    REVIEW_TAB: "review",
    HISTORY_TAB: "history",
    FILTER_TAB: "filter",
};

export const CAPTIS_USER = {
    fullName: "CAPTIS",
    email: "system@captis.celegence.com",
};

export const AI_SOURCE_TYPE = {
    ARTICLE: "article",
    SOURCE_DOCUMENT: "source document",
};

export const AI_ROLE_TYPE = {
    USER: "user",
    ASSISTANT: "assistant",
};

export const AI_SUGGESTION_KEY = {
    NO_MATCH: "No_Match",
    NOT_REQUIRED: "Not_Required",
};

export const SCHEMA_VERSION = "1.0.0";

export const COMMENT_CATEGORY = {
    DICTIONARY: "dictionary",
    ABBREVIATION: "abbreviation",
    REFERENCE: "reference",
};
