import React from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";

import { Button } from "antd";

const Success = observer(({ state }) => {
    if (state.success) {
        return (
            <>
                <p>
                    You can use the link below to go to the login page and sign in using your
                    credentials.
                </p>

                <Link to="./login">
                    <Button type="primary">Sign In</Button>
                </Link>
            </>
        );
    } else {
        return null;
    }
});

export default Success;
