import React, { useState, useRef, useEffect } from "react";
import { DownOutlined } from "@ant-design/icons";

import "./collapse.scoped.scss";

import { useIsOpen } from "./context";

export const Collapse = ({ title, children, id }) => {
    const [contentHeight, setContentHeight] = useState(0);
    const ref = useRef(null);
    const { toggleOpen, isOpen } = useIsOpen(id);

    useEffect(() => {
        if (ref.current) {
            setContentHeight(ref.current.clientHeight);
        }
    }, [children]);

    const iconStyle = {
        transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
        transition: "transform 0.5s ease-in-out",
    };

    return (
        <div className="collapse-container">
            <div className="collapse-header" onClick={toggleOpen}>
                <span>{title}</span>
                <DownOutlined style={iconStyle} />
            </div>
            <div
                className="collapse"
                style={{
                    height: isOpen ? contentHeight : 0,
                }}
            >
                <div ref={ref}>{children}</div>
            </div>
        </div>
    );
};

export default Collapse;
