import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Button, Menu, Dropdown } from "antd";
import { PlusOutlined, DownOutlined } from "@ant-design/icons";
import { NavLink, useHistory } from "react-router-dom";
import { Page } from "@app/components/page";
import Filter from "@app/components/filter";
import SearchCard from "@app/components/search/card";
import SearchFilter from "@app/components/search/filter";
import SearchSource from "@app/components/search/source";
import confirm from "@app/components/confirm";

import state from "@app/state/store/report/search";
import session from "@app/state/store/session";
import report from "@app/state/store/report";
import EmptyList from "@app/components/list/empty";
import { Search } from "@app/constants";

import "./style/search.scoped.scss";

const SearchPage = observer(() => {
    const history = useHistory();
    const config = report.config || {};
    const searchConfig = config.search || {};
    const readonly = !session.can("project.search") || report.readOnly;

    // initialize the state when component mounts.
    useEffect(() => {
        state.load();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.project]);

    // wait until the project state is initialized
    if (!config) {
        return;
    }

    /**
     * Remove a record
     */
    const remove = async (search) => {
        let proceed = await confirm("Are you sure you want to remove this search?");
        if (proceed) {
            await state.remove(search._id);
        }
    };

    const newSearch = (type) => {
        history.push({
            pathname: "search/new",
            search: `?source=${type}`,
        });
    };

    /**
     * Prepare the reasons menu
     */
    const searchMenu = (
        <Menu className="menu">
            {searchConfig.pubmed && (
                <Menu.Item key={Search.PUBMED} onClick={() => newSearch(Search.PUBMED)}>
                    <SearchSource name="pubmed" />
                </Menu.Item>
            )}

            {searchConfig.eupmc && (
                <Menu.Item key={Search.EUPMC} onClick={() => newSearch(Search.EUPMC)}>
                    <SearchSource name="eupmc" />
                </Menu.Item>
            )}

            {searchConfig.google && (
                <Menu.Item key={Search.GOOGLE} onClick={() => newSearch(Search.GOOGLE)}>
                    <SearchSource name="google" />
                </Menu.Item>
            )}

            {searchConfig.embase && (
                <Menu.Item key={Search.EMBASE} onClick={() => newSearch(Search.EMBASE)}>
                    <SearchSource name="embase" />
                </Menu.Item>
            )}

            {searchConfig.cochrane && (
                <Menu.Item key={Search.COCHRANE} onClick={() => newSearch(Search.COCHRANE)}>
                    <SearchSource name="cochrane" />
                </Menu.Item>
            )}

            {searchConfig.prospero && (
                <Menu.Item key={Search.PROSPERO} onClick={() => newSearch(Search.PROSPERO)}>
                    <SearchSource name="prospero" />
                </Menu.Item>
            )}

            <Menu.Item key={Search.OTHER} onClick={() => newSearch(Search.OTHER)}>
                <SearchSource name="other" />
            </Menu.Item>
        </Menu>
    );

    return (
        <Page className="search">
            <Page.Header title="Data Gathering">
                <Page.Header.Right>
                    <Filter.Count filter={state.filter} className="gt-sm" />
                    <Filter.SmallPager filter={state.filter} className="gt-sm" />
                    <Page.Header.Separator className="gt-sm" />

                    <Filter.Toggle filter={state.filter} />
                    <Page.Header.Separator />

                    {/* <NavLink to="./search/new"> */}
                    {!readonly && (
                        <Dropdown overlay={searchMenu} trigger="click" placement="bottomRight">
                            <Button type="primary" icon={<PlusOutlined />}>
                                New Search <DownOutlined />
                            </Button>
                        </Dropdown>
                    )}

                    {/* </NavLink> */}
                </Page.Header.Right>
            </Page.Header>

            <Page.Layout>
                <Page.Body>
                    <div className="searches">
                        {state.list?.length > 0 ? (
                            state.list.map((search) => {
                                return (
                                    <NavLink to={`./search/${search._id}`} key={search._id}>
                                        <SearchCard
                                            search={search}
                                            onRemove={remove}
                                            readonly={readonly}
                                        />
                                    </NavLink>
                                );
                            })
                        ) : (
                            <EmptyList title="No Records Found" show={true}></EmptyList>
                        )}
                    </div>
                </Page.Body>
                <SearchFilter filter={state.filter} config={config} />
            </Page.Layout>

            <Page.Footer>
                <Filter.Pager filter={state.filter} />
            </Page.Footer>
        </Page>
    );
});

export default SearchPage;
