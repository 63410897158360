import { observable } from "mobx";
import Model from "@app/state/model/base";

export default class ProjectMapping extends Model {
    @observable _id = undefined;
    @observable sourceId = undefined;
    @observable targetid = undefined;
    @observable article = {
        excludeReason: undefined,
        questions: {},
        additionalData: {},
    };
}
