import React, { useEffect } from "react";
import { observer, useLocalStore } from "mobx-react";
import Modal from "@app/components/modal";
import notify from "@app/components/notify";
import { Input } from "antd";

const StageModal = observer(({ visible, stage, onSave, onCancel }) => {
    const state = useLocalStore(() => ({
        title: "",
    }));

    useEffect(() => {
        if (!visible) {
            state.title = "";
        }
    }, [visible]);

    useEffect(() => {
        state.title = stage?.title || "";
    }, [stage]);

    const onOk = async () => {
        if (state.title?.trim() === "") {
            notify.error("Please enter a title");
            return;
        }

        onSave && onSave({ ...stage, title: state.title });
    };

    return (
        visible && (
            <Modal title={"Stage"} visible={true} onOk={onOk} onCancel={onCancel} width={600}>
                <Input
                    placeholder="Title"
                    value={state.title}
                    onChange={(e) => {
                        state.title = e.currentTarget.value;
                    }}
                />
            </Modal>
        )
    );
});

export default StageModal;
