import React from "react";
import { Button } from "antd";
import Modal from "@app/components/modal";

const SessionExpiredModal = ({ visible }) => {
    const refreshPage = () => {
        window.location.reload(false);
    };
    return (
        <Modal
            visible={visible}
            footer={null}
            closable={false}
            centered
            style={{ textAlign: "center" }}
        >
            <div style={{ marginBottom: "1rem" }}>
                Your session has expired.
                <br />
                Click here to sign in
            </div>
            <Button onClick={refreshPage} type="primary">
                Sign In
            </Button>
        </Modal>
    );
};

export default SessionExpiredModal;
