import React, { useState } from "react";
import { observer } from "mobx-react";
import { Dropdown, Button, Menu, Popover } from "antd";
import {
    EditOutlined,
    DeleteOutlined,
    ClockCircleOutlined,
    EllipsisOutlined,
    CheckCircleOutlined,
    WarningOutlined,
} from "@ant-design/icons";

import Avatar from "@app/components/user/avatar";
import format from "@app/lib/format";
import session from "@app/state/store/session";
import { CommentStatus, CommentStatusType, Role } from "@app/constants";
import confirm from "@app/components/confirm/index";
import sectionStore from "@app/state/store/report-document/report-document-sections";

import Input from "../input";

import "./style/details.scoped.scss";

/**
 * Handles both Comments and Replies to the comment.
 * comment - Can be either a comment or a reply.
 *      When its a comment, onSave will save the comment.
 *      When its a reply, onSave will save the reply.
 */

const Details = observer(({ comment, onSave, onRemove, readonly = false }) => {
    //TODO: Is onReply needed ?
    const [isEditing, setEditing] = useState(false);
    const { title: sectionTitle } =
        sectionStore.sections.find((s) => s._id === comment.section) || {};

    const formatComment = (comment) => {
        comment = format.sanitize(comment);
        comment = format.nl2br(comment);

        return format.mentions(comment);
    };

    const isOwner = session.user._id === comment.author?._id;
    const canModify =
        isOwner || session.isProjectManager || session.isAdmin || session.is(Role.ACCOUNT_ADMIN);

    const onAction = async (action) => {
        if (action.key === "edit") {
            setEditing(true);
        } else if (action.key === "delete") {
            let proceed = await confirm("Are you sure you want to remove this comment?");
            if (proceed) {
                await onRemove();
            }
        }
    };

    const actions = (
        <Menu onClick={onAction} className="menu">
            {isOwner && (
                <Menu.Item key="edit" icon={<EditOutlined />}>
                    Edit
                </Menu.Item>
            )}
            <Menu.Item key="delete" icon={<DeleteOutlined />}>
                Delete
            </Menu.Item>
        </Menu>
    );

    const Status = () => {
        return (
            <div className={comment.status}>
                {!readonly && (
                    <Dropdown overlay={actions} trigger="click" placement="bottomRight">
                        {comment.status === CommentStatus.PENDING ? (
                            <>
                                <span className="icon">
                                    <ClockCircleOutlined />
                                </span>
                            </>
                        ) : (
                            <>
                                <span className="icon">
                                    <CheckCircleOutlined />
                                </span>
                            </>
                        )}
                    </Dropdown>
                )}
                <span className="label">{format.properCase(comment.status)}</span>
            </div>
        );
    };

    // comment.type is present only for replies
    const systemReply = [CommentStatusType.UNRESOLVE, CommentStatusType.RESOLVE].includes(
        comment?.type,
    );
    const commentText = systemReply ? format.commentActionLabel(comment) : comment.text;
    const quotedText = format.shorten(comment?.quotedText, 100);

    return (
        <>
            <div className="head">
                <Avatar className="avatar" size={42} user={comment.author} />
                <div className="user">
                    <div className="name">{comment.author?.fullName}</div>
                    <div className="time">{format.datetime(comment.created)}</div>
                </div>
                {comment.status && <Status />}

                <NotFoundInDocument comment={comment} />

                {canModify && !systemReply && !readonly && (
                    <div
                        className="dropdown"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Dropdown overlay={actions} trigger="click" placement="bottomRight">
                            <Button type="icon" icon={<EllipsisOutlined />} />
                        </Dropdown>
                    </div>
                )}
            </div>
            {!isEditing && (
                <>
                    {comment.isSectionComment && (
                        <div className="notice">
                            Commented on section "{sectionTitle ? sectionTitle : "Removed section"}"
                        </div>
                    )}
                    <div
                        className="text"
                        dangerouslySetInnerHTML={{ __html: formatComment(commentText) }}
                    />
                    {quotedText && <blockquote className="quoted-text">{quotedText}</blockquote>}
                </>
            )}

            {isEditing && (
                <Input
                    comment={comment}
                    onSave={(input) => {
                        onSave(input);
                        setEditing(false);
                    }}
                    onCancel={() => {
                        setEditing(false);
                    }}
                    focus={isEditing}
                />
            )}
        </>
    );
});

const NotFoundContent = ({ selectedText, isSectionComment }) => {
    if (isSectionComment) {
        return (
            <div>
                <h4>Comment removed</h4>
                <p>The section removed from the document.</p>
            </div>
        );
    }

    if (!selectedText) {
        return (
            <div>
                <h4>Comment removed</h4>
                <p>The selected text was removed from the document.</p>
            </div>
        );
    }

    return (
        <div>
            <h4>Comment removed</h4>
            <p>The selected text was removed from the document.</p>
            <h4>Selected text:</h4>
            <p>
                {selectedText?.length > 50
                    ? selectedText
                          .split(" ")
                          .reduce(
                              (lines, word) => {
                                  const lastLine = lines[lines.length - 1];
                                  const potentialLine = lastLine ? `${lastLine} ${word}` : word;

                                  if (potentialLine.length > 50) {
                                      lines.push(word);
                                  } else {
                                      lines[lines.length - 1] = potentialLine;
                                  }

                                  return lines;
                              },
                              [""],
                          )
                          .map((line, index, array) => (
                              <React.Fragment key={index}>
                                  {line}
                                  {index < array.length - 1 && <br />}
                                  {index === array.length - 1 && "..."}
                              </React.Fragment>
                          ))
                    : selectedText}
            </p>
        </div>
    );
};

const NotFoundInDocument = observer(({ comment }) => {
    const { isFoundInDocument, selectedText, isSectionComment, isParsed } = comment;

    // undefined for other cases, true/false is set only in report document
    if (isFoundInDocument !== false || !isParsed) {
        return;
    }

    return (
        <div className="not-found">
            <Popover
                content={
                    <NotFoundContent
                        selectedText={selectedText}
                        isSectionComment={isSectionComment}
                    />
                }
            >
                <WarningOutlined className="not-found-icon" />
            </Popover>
        </div>
    );
});

export default Details;
