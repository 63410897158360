import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import HBox from "@app/components/container/hbox/hbox";
import { CheckCircleOutlined, CloseCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { Button } from "antd";

import "./styles/footer.scoped.scss";

export default function Footer({ spacing = true, className, children, ...rest }) {
    return (
        <HBox className={classNames("footer", className, { spacing: spacing })} {...rest}>
            {children}
        </HBox>
    );
}

function Separator({ className, ...rest }) {
    const classes = classNames("separator", className);
    return <div className={classes} {...rest}></div>;
}

Footer.Left = HBox.Left;
Footer.Right = HBox.Right;
Footer.Separator = Separator;

Footer.SaveStatus = observer(({ status }) => {
    return (
        <div
            className={classNames("save-status", {
                draft: !status,
                saved: status,
            })}
        >
            <div className="icon">{status ? <CheckCircleOutlined /> : <CloseCircleOutlined />}</div>
            <div className="label">
                <div className="status">{status ? "Saved" : "Draft"}</div>
            </div>
        </div>
    );
});

Footer.SaveButton = observer(({ onClick, disabled }) => {
    return (
        <Button icon={<SaveOutlined />} type="primary" onClick={onClick} disabled={disabled}>
            Save
        </Button>
    );
});

Footer.CloseButton = observer(({ onClick }) => {
    return <Button onClick={onClick}>Close</Button>;
});
