import React from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

import BookmarkList from "@app/components/document/bookmark/list";

const Bookmarks = observer(({ readonly = false }) => {
    const { project } = useParams();

    const openBookmark = (bookmark) => {
        const document = bookmark.document;
        const area = bookmark.areas[0];
        const link =
            `/fp/r/${project}/document/bookmarks/${document}` +
            `?area=${JSON.stringify(area)}&bid=${bookmark._id}`;

        window.open(link, "_blank").focus();
    };

    return <BookmarkList onOpen={openBookmark} readonly={readonly} />;
});

export default Bookmarks;
