import React from "react";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { Button, Menu, Dropdown, Tooltip } from "antd";
import { DownOutlined, EditOutlined } from "@ant-design/icons";
import { SearchType } from "@app/constants";
import { MarkdownViewer } from "@app/components/markdown-editor";

import "./style/summary.scoped.scss";
import report from "@app/state/store/report";
import session from "@app/state/store/session";

const Summary = observer(({ article, type, questions, score, onResolve, onEdit }) => {
    const criteria = report?.config?.[type]?.l2.criteria;
    const review = article.review || {};

    const config = report.config || {};
    const reasons = config[type]?.l2.excludeReasons || [];
    const readonly = !session.can("article.update") || report.readOnly;
    const topRef = React.useRef(null);

    React.useEffect(() => {
        const top = topRef?.current;
        top.scrollIntoView();
    }, []);

    /**
     * Resolve the article state to passed/failed
     */
    const resolve = (action, reason) => {
        onResolve && onResolve(action, reason);
    };

    // get the list of questions with values
    const summaryQuestions = questions
        .filter((question) => question.answer !== undefined)
        .map((question) => {
            const data = config[type]?.l2?.questions?.find((cfg) => cfg._id === question._id);
            question.color = data?.color;

            return question;
        });

    // Prepare actions button
    const l2Complete = type === SearchType.SLR ? article.slrL2Complete : article.sotaL2Complete;
    const l2Passed = type === SearchType.SLR ? article.slrL2Passed : article.sotaL2Passed;

    const includeBtnType = l2Complete && l2Passed ? "success" : "default";
    const excludeBtnType = l2Complete && !l2Passed ? "danger" : "default";

    /**
     * Prepare the reasons menu
     */
    const reasonsMenu = (
        <Menu className="menu">
            {reasons.length === 0 && (
                <div className="no-reason">
                    No exclusion reasons are configured. Please configure any exclusion reason from
                    the project configuration in order to exclude an article.
                </div>
            )}
            {reasons.map((reason) => {
                return (
                    <Menu.Item
                        key={reason._id}
                        onClick={() => {
                            resolve("fail", reason.reason);
                        }}
                    >
                        {reason.reason}
                    </Menu.Item>
                );
            })}
        </Menu>
    );

    return (
        <div className="summary" ref={topRef}>
            <div className="box">
                <div className="title">
                    <div className="text">L2 Review Summary</div>
                    {!readonly && (
                        <div className="edit">
                            <Tooltip title="Edit the review?">
                                <Button icon={<EditOutlined />} type="icon" onClick={onEdit} />
                            </Tooltip>
                        </div>
                    )}
                </div>
                <div className="scores">
                    {score && (
                        <div className="score value">
                            <em>{review.l2.score}</em>
                            <label>Score</label>
                        </div>
                    )}
                </div>

                {score && criteria && (
                    <div className="criteria">
                        <div className="title">Criteria</div>
                        <div className="group">
                            <em>
                                {criteria.include.min} - {criteria.include.max}
                            </em>
                            <label>{criteria.include.label}</label>
                        </div>
                        {criteria.maybe.enabled && (
                            <div className="group">
                                <em>
                                    {criteria.maybe.min} - {criteria.maybe.max}
                                </em>
                                <label>{criteria.maybe.label}</label>
                            </div>
                        )}
                        <div className="group">
                            <em>
                                {criteria.exclude.min} - {criteria.exclude.max}
                            </em>
                            <label>{criteria.exclude.label}</label>
                        </div>
                    </div>
                )}
            </div>

            {!readonly && (
                <div className="actions">
                    <div className="text">Use the buttons below to include/exclude the article</div>
                    <div className="buttons">
                        <Button type={includeBtnType} onClick={() => resolve("pass")}>
                            Include
                        </Button>

                        <Dropdown overlay={reasonsMenu} trigger="click" placement="bottomRight">
                            <Button type={excludeBtnType}>
                                Exclude <DownOutlined />
                            </Button>
                        </Dropdown>
                    </div>
                </div>
            )}

            <div className="questions">
                {summaryQuestions.map((question) => {
                    return (
                        <div
                            className={classNames("answer", { [question.color]: question.color })}
                            key={question._id}
                        >
                            <div className="question">
                                <div className="text">
                                    {<MarkdownViewer content={question.question} />}
                                </div>
                                <div className="contribution">{question.contribution}</div>
                            </div>
                            <div className="grading">
                                <label>{question.answer.grade}:</label>
                                {question.answer.answer}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
});

export default Summary;
