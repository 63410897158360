import React from "react";
import classes from "classnames";
import { observer } from "mobx-react-lite";
import { Link, useParams } from "react-router-dom";
import { useCrumbs } from "./state";
import "./style/breadcrumbs.scoped.scss";

const Breadcrumbs = observer(({ className, ...rest }) => {
    const { type } = useParams();
    const state = useCrumbs(type);

    return (
        <div className={classes("breadcrumbs", className)} {...rest}>
            {state.crumbs.map(({ path, text }) => {
                // Pass context into breadcrumb for dynamic trails
                const crumbText = typeof text === "function" ? text(type) : text;

                return (
                    <Link to={path} key={path}>
                        {crumbText}
                    </Link>
                );
            })}
        </div>
    );
});

export default Breadcrumbs;
