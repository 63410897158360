import BaseStore from "./base";
export class Features extends BaseStore {
    setFeaturesInSession(params) {
        if (params) {
            const features = params.split(",");
            window.sessionStorage.setItem("features", JSON.stringify(features));
        }
    }

    getFeaturesInSession() {
        const features = {};

        try {
            let sessionFeatures = window.sessionStorage.getItem("features");
            if (sessionFeatures) {
                sessionFeatures = JSON.parse(sessionFeatures);
                sessionFeatures.forEach((feature) => (features[feature] = true));
            }
            return features;
        } catch (error) {
            console.log("Cannot get features in session!");
        }
    }

    checkFeatureInSession(feature) {
        try {
            let sessionFeatures = window.sessionStorage.getItem("features");
            if (sessionFeatures) {
                sessionFeatures = JSON.parse(sessionFeatures);
                if (sessionFeatures.includes(feature)) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } catch (error) {
            console.log("Cannot check features in session!");
        }
    }
}

const features = new Features();
export default features;
