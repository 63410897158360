import React from "react";

import loader from "@app/assets/loader.svg";
import "./style/app.scoped.scss";

export default function Loader({ children }) {
    return (
        <div className="app-loader">
            <div className="center">
                <img src={loader} />
            </div>
        </div>
    );
}
