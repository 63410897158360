import React from "react";
import { observer, useLocalStore } from "mobx-react";

import Group from "../group";
import Criteria from "./criteria";
import Form from "./form";

const ScoreBands = observer(({ criteria = {}, onChange, readonly = false }) => {
    const state = useLocalStore(() => ({
        edit: false,
    }));

    const save = (data) => {
        state.edit = false;
        onChange && onChange(data);
    };

    // determine if we have the edit enabled
    const onEdit = readonly
        ? undefined
        : () => {
              state.edit = true;
          };

    return (
        <>
            <Group title="Appraisal Score Range" onEdit={onEdit}>
                <Criteria score={criteria?.include} label={criteria?.include?.label} />
                {criteria?.maybe?.enabled && (
                    <Criteria score={criteria?.maybe} label={criteria?.maybe?.label} />
                )}
                <Criteria score={criteria?.exclude} label={criteria?.exclude?.label} />
            </Group>

            {state.edit && (
                <Form
                    criteria={criteria}
                    onSave={save}
                    onCancel={() => {
                        state.edit = false;
                    }}
                />
            )}
        </>
    );
});

export default ScoreBands;
