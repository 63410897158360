import React from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { Progress } from "antd";

const SearchProgress = observer(({ value, width, className, ...rest }) => {
    return (
        <Progress
            className={classNames("progress", className)}
            type="circle"
            width={width}
            percent={value}
            showInfo={false}
            {...rest}
        />
    );
});

export default SearchProgress;
