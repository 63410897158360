import React, { useEffect } from "react";
import { observer, useLocalStore } from "mobx-react";
import classNames from "classnames";
import filter from "@app/lib/format";
import { Input } from "antd";

import "../style/content.scoped.scss";

const TEXT_LIMIT = 160;

export const FoldedContent = observer(({ content, onChange, disableEdit, ...rest }) => {
    const state = useLocalStore(() => ({
        shortened: false,
        fold: false,
        short: null,
    }));

    useEffect(() => {
        state.shortText = filter.shorten(content, TEXT_LIMIT);
        state.shortened = state.shortText !== content;
        state.fold = state.shortened;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggle = () => {
        state.fold = !state.fold;
    };

    return (
        <div
            className={classNames("content", {
                shortened: state.shortened,
                folded: state.fold,
            })}
            {...rest}
        >
            {disableEdit ? (
                <>
                    <span className="short">{state.shortText}</span>
                    <span className="full">
                        <Input.TextArea
                            style={{ resize: "none", padding: "unset" }}
                            autoSize={true}
                            bordered={false}
                            value={content}
                            disabled={true}
                        />
                    </span>
                    <div className="more" onClick={toggle}>
                        {state.fold ? " read more" : "read less"}
                    </div>
                </>
            ) : (
                <Input.TextArea
                    style={{ resize: "none" }}
                    autoSize={{ minRows: 6, maxRows: 12 }}
                    value={content}
                    onChange={(e) => {
                        onChange && onChange(e.currentTarget.value);
                    }}
                />
            )}
        </div>
    );
});

export default FoldedContent;
