import React, { useEffect } from "react";
import { observer, useLocalStore } from "mobx-react";
import { Upload, Button } from "antd";
import classNames from "classnames";
import { EditOutlined, CloseOutlined, CameraOutlined, LoadingOutlined } from "@ant-design/icons";
import format from "@app/lib/format";
import { UploadService } from "./service";

import "./style/image.scoped.scss";

const ImageUpload = observer(({ value, className, share, children, onChange, ...rest }) => {
    const state = useLocalStore(() => ({
        loading: true,
    }));

    let url = value ? format.image(value, { width: 100, height: 100 }) : undefined;

    useEffect(() => {
        // preload the image
        const preload = () => {
            state.loading = true;

            if (url) {
                let img = new window.Image();
                img.onload = () => {
                    state.loading = false;
                };
                img.src = url;
            } else {
                state.loading = false;
            }
        };

        preload();
    }, [state, url]);

    /**
     * Handle the beforeUpload event and start the upload process.
     * Return false to prevent the component uploading the file.
     * We are handling the upload ourselves. After the upload is done
     * show a preview
     * @param {File} file
     */
    const onFilePick = (file) => {
        (async () => {
            state.loading = true;
            const { data } = await UploadService.upload(file);
            state.loading = false;

            if (data && onChange) {
                onChange(data._id);
            }
        })();

        return false;
    };

    /**
     * Remove the avatar file
     */
    const remove = () => {
        onChange(null);
    };

    // render the preview image
    const Preview = observer(() => {
        if (state.loading) {
            return (
                <div className="loading">
                    <LoadingOutlined className="icon" />
                </div>
            );
        } else if (url) {
            return (
                <div className="image">
                    <img src={url} />
                </div>
            );
        } else {
            return (
                <div className="no-image">
                    <CameraOutlined className="icon" />
                </div>
            );
        }
    });

    return (
        <div className={classNames("avatar-upload", className)} {...rest}>
            <Preview />
            <Upload
                accept="image/png,image/jpeg,image/gif,image/webp,.png,.jpg,.jpeg,.gif,.webp"
                showUploadList={false}
                beforeUpload={onFilePick}
            >
                <Button
                    className="edit-icon"
                    type="dashed"
                    shape="circle"
                    icon={<EditOutlined />}
                />
            </Upload>
            {value ? (
                <Button
                    onClick={remove}
                    className="remove-icon"
                    type="dashed"
                    shape="circle"
                    icon={<CloseOutlined />}
                />
            ) : null}
        </div>
    );
});

export default ImageUpload;
