import React from "react";

export const CollapseContext = React.createContext({
    activeKey: "",
    setActiveKey: () => {},
});

export const useCollapseContext = () => React.useContext(CollapseContext);

export const useIsOpen = (key) => {
    const { activeKey, setActiveKey } = useCollapseContext();

    const toggleOpen = () => {
        setActiveKey(activeKey === key ? null : key);
    };

    return { isOpen: activeKey === key, toggleOpen };
};

export const CollapseProvider = ({ children }) => {
    const [activeKey, setActiveKey] = React.useState(null);

    return (
        <CollapseContext.Provider
            value={{
                activeKey,
                setActiveKey,
            }}
        >
            {children}
        </CollapseContext.Provider>
    );
};

export default CollapseProvider;
