import React from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import Page from "@app/components/page/page";
import Loader from "@app/components/loader/content";
import { Form, Button } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { UploadContextProvider } from "@app/components/form/upload/context";
import StylesForm from "@app/components/setup/report-documents/styles/edit";
import notify from "@app/components/notify";

import client from "@app/state/store/clients/details";

const Styles = observer(() => {
    const [form] = Form.useForm();
    const history = useHistory();
    const config = client?.config;
    const clientId = client.data?._id;

    const loaded = !!config;

    const handleClose = () => {
        history.push(`../report-documents`);
    };

    // Update Project Configuration - Enable/Disable Source Documents.
    const updateConfig = async (data) => {
        const updates = {
            reportDocuments: {
                ...config.reportDocuments,
                style: data,
            },
        };

        try {
            await client.updateConfig(updates, { preview: true });
        } catch (ex) {
            notify.error("Error saving the report configuration");
        }
    };

    return (
        <Page>
            <Page.Header title="Document Styles" closable onClose={handleClose} />
            <Page.Body maxWidth={1000}>
                {loaded && (
                    <UploadContextProvider clientId={clientId}>
                        <StylesForm config={config} form={form} onSave={updateConfig} />
                    </UploadContextProvider>
                )}

                <Loader loading={!loaded} />
            </Page.Body>
            <Page.Footer>
                {loaded && (
                    <Page.Footer.Right>
                        <Button
                            icon={<SaveOutlined />}
                            type="primary"
                            onClick={() => form.submit()}
                        >
                            Save
                        </Button>
                        <Button onClick={handleClose}>Close</Button>
                    </Page.Footer.Right>
                )}
            </Page.Footer>
        </Page>
    );
});

export default Styles;
