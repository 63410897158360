import React, { useEffect } from "react";
import { observer, useLocalStore } from "mobx-react-lite";
import { Form, Input, Switch } from "antd";
import notify from "@app/components/notify";

import Modal from "@app/components/modal";
import "./style/form.scoped.scss";

const CriteriaForm = observer(({ criteria, onCancel, onSave }) => {
    const [form] = Form.useForm();
    const groups = ["include", "maybe", "exclude"];

    const state = useLocalStore(() => ({
        maybe: { enabled: false },
    }));

    useEffect(() => {
        if (criteria) {
            for (let name of groups) {
                form.setFieldsValue({
                    [`${name}_label`]: criteria[name]?.label,
                    [`${name}_min`]: criteria[name]?.min,
                    [`${name}_max`]: criteria[name]?.max,
                });

                if (criteria[name]?.enabled !== undefined) {
                    state[name].enabled = criteria[name]?.enabled;
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [criteria]);

    const error = () => {
        notify.error("Please fill all required fields to continue");
    };

    const onValidate = () => {
        let validate = true;
        let error;
        const values = form.getFieldsValue();

        if (state.maybe.enabled) {
            if ([values.include_label, values.exclude_label].includes(values.maybe_label)) {
                validate = false;
                error = "Labels cannot be the same";
            } else if (Number(values.maybe_min) >= Number(values.maybe_max)) {
                validate = false;
                error = "min value must be lower than max value";
            }
        }

        if (values.include_label === values.exclude_label) {
            validate = false;
            error = "Labels cannot be the same";
        } else if (
            Number(values.include_min) >= Number(values.include_max) ||
            Number(values.exclude_min) >= Number(values.exclude_max)
        ) {
            validate = false;
            error = "min value must be lower than max value";
        }

        if (!validate) {
            notify.error(error);
        }

        return validate;
    };

    /**
     * Save the reason
     * @param {Object} data
     */
    const save = async (data) => {
        if (onValidate()) {
            onSave({
                include: {
                    label: data.include_label,
                    min: Number(data.include_min),
                    max: Number(data.include_max),
                },
                maybe: {
                    label: data.maybe_label,
                    min: Number(data.maybe_min),
                    max: Number(data.maybe_max),
                    enabled: state.maybe.enabled,
                },
                exclude: {
                    label: data.exclude_label,
                    min: Number(data.exclude_min),
                    max: Number(data.exclude_max),
                },
            });
        }
    };

    /**
     * Cancel the edit
     */
    const cancel = () => {
        onCancel && onCancel();
    };

    /**
     * Render a row from the table
     */
    const Row = observer(({ group }) => {
        return (
            <tr>
                <td>
                    <Form.Item
                        label=""
                        name={`${group}_min`}
                        rules={[
                            {
                                message: "invalid",
                                required: true,
                                pattern: /^\d+$/i,
                            },
                        ]}
                    >
                        <Input type="number" min={0} />
                    </Form.Item>
                </td>
                <td>
                    <Form.Item
                        label=""
                        name={`${group}_max`}
                        rules={[
                            {
                                message: "invalid",
                                required: true,
                                pattern: /^\d+$/i,
                            },
                        ]}
                    >
                        <Input type="number" min={0} />
                    </Form.Item>
                </td>
                <td className="scorelabel">
                    <Form.Item
                        label=""
                        name={`${group}_label`}
                        rules={[
                            {
                                message: "required",
                                required: true,
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>
                </td>
            </tr>
        );
    });

    /**
     * Toggle MayBe(Possibly Include) state
     */
    const toggleMayBe = async (value) => {
        state.maybe.enabled = value;
    };

    return (
        <Modal
            title="Edit Criteria"
            visible={true}
            okText="Save"
            onOk={form.submit.bind(form)}
            onCancel={cancel}
            width={600}
        >
            <Form layout="vertical" form={form} onFinishFailed={error} onFinish={save}>
                <div className="criteria-form">
                    <div className="row toggle items-center mb-15">
                        <div className="col auto">
                            <Switch checked={state.maybe.enabled} onChange={toggleMayBe} />
                        </div>
                        <div className="col ml-15">Enable 3 Scoring Groups</div>
                    </div>

                    <div className="form">
                        <table className="form-table">
                            <thead>
                                <tr>
                                    <th>Min</th>
                                    <th>Max</th>
                                    <th>Label</th>
                                </tr>
                            </thead>
                            <tbody>
                                <Row group="include" />
                                {state.maybe.enabled && <Row group="maybe" />}
                                <Row group="exclude" />
                            </tbody>
                        </table>
                    </div>
                </div>
            </Form>
        </Modal>
    );
});

export default CriteriaForm;
