import React from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { SplitPage } from "@app/components/page";

import List from "./list.js";
import Details from "./details";

import "./style/list.scoped.scss";

const Documents = observer(() => {
    const { document } = useParams();
    return (
        <SplitPage
            className="documents-split"
            left={<List />}
            right={document ? <Details /> : null}
        />
    );
});

export default Documents;
