import React from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import EmptyList from "@app/components/list/empty";

import state from "@app/state/store/report/output/slr/performance";
import { Switch } from "antd";
import { Page } from "@app/components/page";
import { Copy } from "@app/components/clipboard";
import { Box } from "@app/components/box";
import { useHistory } from "react-router-dom";
import format from "@app/lib/format";

const ReportPage = observer(() => {
    const [abstract, setAbstract] = React.useState(false);
    const history = useHistory();

    const close = () => {
        history.push("../report");
    };

    const props = {
        abstract,
    };

    return (
        <>
            <Page className="setup">
                <Page.Header
                    title="Clinical Safety and Performance Summary"
                    closable
                    onClose={close}
                >
                    <Page.Header.Right>
                        <Copy component={<Report {...props} />} className="copy-style" />
                    </Page.Header.Right>
                </Page.Header>
                <Page.Body maxWidth="1200">
                    {state.list.length !== 0 && !state.loading && (
                        <div className="row toggle items-center mb-15">
                            <div className="col auto">
                                <Switch
                                    checked={abstract}
                                    onChange={(value) => setAbstract(value)}
                                />
                            </div>
                            <div className="col ml-15">Show Abstract</div>
                        </div>
                    )}
                    <Box className="display-style">
                        <Report {...props} />
                    </Box>
                </Page.Body>
            </Page>
        </>
    );
});

const Report = observer(({ abstract }) => {
    const { type } = useParams();

    React.useEffect(() => {
        state.load(type);
    }, [type]);

    const { list, loading } = state;

    return (
        <>
            <EmptyList title="No Records Found" show={list.length === 0 && !loading}></EmptyList>
            {list.length !== 0 && (
                <table>
                    <thead>
                        <tr>
                            <th className="nowrap center">S No.</th>
                            <th>Literature ID</th>
                            <th>Reference</th>
                            <th>Title</th>
                            <th>Author(s)</th>
                            <th>Year</th>
                            {abstract && <th>Abstract</th>}
                            <th>Study Type</th>
                            <th>Device</th>
                            <th>Samples</th>
                            <th>Follow-up</th>
                            <th>Treatment/ Outcome Measure</th>
                            <th>Failure Mode/ Interventions</th>
                            <th>Conclusions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((article, idx) => {
                            return (
                                <tr key={article._id}>
                                    <td className="center">{idx + 1}</td>
                                    <td>{format.cid(article.mdrId)}</td>
                                    <td>{article.article}</td>
                                    <td>{article.title}</td>
                                    <td>{article.authors}</td>
                                    <td>{article.publicationYear}</td>
                                    {abstract && <td>{article.abstract}</td>}
                                    <td></td>

                                    <td></td>
                                    <td></td>
                                    <td></td>

                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            )}
        </>
    );
});

export default ReportPage;
