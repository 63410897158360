import { observable } from "mobx";
import Model from "@app/state/model/base";
import format from "@app/lib/format";

export default class Activity extends Model {
    @observable time = undefined;
    @observable target = {
        _id: undefined,
        type: undefined,
        name: undefined,
        key: undefined,
    };
    @observable article = {
        _id: undefined,
        name: undefined,
        key: undefined,
    };
    @observable document = {
        _id: undefined,
        name: undefined,
    };
    @observable client = {
        _id: undefined,
        name: undefined,
    };
    @observable project = {
        _id: undefined,
        name: undefined,
    };
    @observable user = {
        id: undefined,
        fullName: undefined,
        avatar: undefined,
    };
    @observable action = undefined;
    @observable operation = undefined;
    @observable path = undefined;
    @observable oldValue = undefined;
    @observable newValue = undefined;
    @observable device = undefined;
    @observable address = undefined;
    @observable keywords = undefined;
    @observable _index = undefined;
    @observable date = undefined;
    @observable created = undefined;

    set(data) {
        super.set(data);
        this.user.initials = format.initials(this.user.fullName);
    }
}
