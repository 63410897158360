import { action } from "mobx";
import { v4 as uuid } from "uuid";
import BaseStore from "../base";
import { events } from "@app/lib/store";
import http from "@app/lib/http";
import User from "@app/state/model/user";
import FilterState from "@app/components/filter/state";

const STAGGER_ID = uuid();

/**
 * State management controlling the list of users in
 * the user management sections
 */
export class UserList extends BaseStore {
    filter = null;

    /**
     * Observable store data
     */
    observable() {
        return {
            list: [],
            loading: false,
        };
    }

    constructor() {
        super();

        // handle user events
        events.on("user.update", () => this.load());
        events.on("user.delete", () => this.load());
        events.on("user.invite", () => this.load());

        this.filter = new FilterState({
            default: {
                search: "",
                role: [],
            },
        });

        this.filter.on("find", () => {
            this.load();
        });
    }

    /**
     * Load a list with users from the backend
     */
    @action
    async load(status) {
        this.loading = true;

        let filter = this.filter.value();

        if (status) {
            filter.status = status;
        }

        let { data } = await http.get("/users", filter).stagger(STAGGER_ID);

        this.list = data.list.map((entry) => {
            return new User(entry);
        });

        // update the query stats
        this.filter.stats(data.stats);
        this.loading = false;
    }

    /**
     * Remove the user
     */
    @action
    async remove(id) {
        this.loading = true;
        let { data } = await http.delete(`/user/${id}`);

        // emit the delete event
        let user = new User(data);
        events.emit("user.delete", user);

        this.loading = true;
    }
}

export default new UserList();
