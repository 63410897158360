import React from "react";
import { observer } from "mobx-react";

import "./style/summary.scoped.scss";

const ProjectSummary = observer(({ summary, withSota, withSourceDocuments }) => {
    if (!summary) {
        return;
    }

    const { documentCategories: categories, documents, l1Review, l2Review, search } = summary;

    l1Review.slr.complete = l1Review.slr.pass + l1Review.slr.fail;
    l2Review.slr.complete = l2Review.slr.pass + l2Review.slr.fail;
    l1Review.sota.complete = l1Review.sota.pass + l1Review.sota.fail;
    l2Review.sota.complete = l2Review.sota.pass + l2Review.sota.fail;

    return (
        <div className="summary">
            <div className="heading"> Project Summary </div>
            <div className="group">
                <div className="title">DUE</div>
                <div className="line">
                    <div className="text">Saved Searches</div>
                    <div className="count">{search.slr.saved}</div>
                </div>
                <div className="line">
                    <div className="text">Screened articles (L1)</div>
                    <div className="count">
                        {l1Review.slr.complete}/{l1Review.slr.total}
                    </div>
                </div>
                <div className="line">
                    <div className="text">Reviewed articles (L2)</div>
                    <div className="count">
                        {l2Review.slr.complete}/{l2Review.slr.total}
                    </div>
                </div>
            </div>

            {withSota && (
                <div className="group">
                    <div className="title">SoTA</div>
                    <div className="line">
                        <div className="text">Saved Searches</div>
                        <div className="count">{search.sota.saved}</div>
                    </div>
                    <div className="line">
                        <div className="text">Screened articles (L1)</div>
                        <div className="count">
                            {l1Review.sota.complete}/{l1Review.sota.total}
                        </div>
                    </div>
                    <div className="line">
                        <div className="text">Reviewed articles (L2)</div>
                        <div className="count">
                            {l2Review.sota.complete}/{l2Review.sota.total}
                        </div>
                    </div>
                </div>
            )}

            {withSourceDocuments && (
                <div className="group">
                    <div className="title">Source Documents</div>
                    <div className="line">
                        <div className="text">Reviewed documents</div>
                        <div className="count">
                            {documents.approved}/{documents.total}
                        </div>
                    </div>
                    <div className="line">
                        <div className="text">Complete categories</div>
                        <div className="count">
                            {categories.approved}/{categories.total}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
});

export default ProjectSummary;
