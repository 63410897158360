import React from "react";
import { observer } from "mobx-react";
import { NodeViewWrapper } from "@tiptap/react";

import "./style.scoped.scss";

export default observer(({ node }) => {
    return (
        <NodeViewWrapper as="p" className="pageBreak">
            {node.attrs.text}
        </NodeViewWrapper>
    );
});
