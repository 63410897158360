import { action, computed } from "mobx";
import BaseStore from "../../base";
import { v4 as uuid } from "uuid";

import http from "@app/lib/http";
import AbbreviationEntry from "@app/state/model/abbreviation/entry";
import { events } from "@app/lib/store";

import report from "../../report";
import reportDocumentStore from "../../report-document/report-documents";

import listStore from "./list";

const STGR_LOAD = uuid();

export class Abbreviation extends BaseStore {
    /**
     * Observable store data
     */
    observable() {
        return {
            list: [],
            usage: {},
            nodes: {},
            filter: "",
            loading: false,
            saving: false,
            loaded: false,
        };
    }

    constructor() {
        super();

        // handle abbreviation update events
        events.on("abbreviation.update", () => this.load());
        events.on("abbreviation.updateTemplateValues", (entry) => this.loadTemplateValues(entry));

        events.on("abbreviation.delete", () => this.load());
        events.on("project.unload", () => {
            this.reset();
        });
    }

    /**
     * Return the project id if the currently loaded project
     */
    @computed get project() {
        return report.id;
    }

    /**
     * Return the project id if the currently loaded project
     */
    @computed get entryMap() {
        const map = {};
        this.list.map((entry) => {
            map[entry.name] = entry;
        });

        return map;
    }

    /**
     * Return the project id if the currently loaded project
     */
    @computed get filteredList() {
        const tokens = this.filter
            .split(/\s+/g)
            .filter((token) => !!token)
            .map((token) => token.toLowerCase());

        if (tokens.length === 0) {
            return this.list;
        }

        return this.list.filter((entry) => {
            const name = entry.name?.toLowerCase() || "";
            const text = entry.text?.toLowerCase() || "";

            return tokens.every((token) => {
                return name.includes(token) || text.includes(token);
            });
        });
    }

    /**
     * Return a list of used abbreviations
     */
    @computed get usedAbbreviations() {
        const result = [];

        for (const id of Object.keys(this.usage)) {
            const count = this.usage[id];
            if (count > 0 && this.entryMap[id]) {
                result.push(this.entryMap[id]);
            }
        }

        // sort by name
        return result.sort((left, right) => {
            const a = left.name.toUpperCase();
            const b = right.name.toUpperCase();

            if (a < b) {
                return -1;
            } else if (a > b) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    /**
     * Return an entry by id. Flags it as being used
     */
    use(key, source) {
        if (source && !this.nodes[source]) {
            this.nodes[source] = true;
            this.usage[key] = (this.usage[key] ?? 0) + 1;
        }

        return this.entryMap[key];
    }

    /**
     * Reset the usage stats
     */
    resetUsage() {
        this.usage = {};
    }

    /**
     * Update the filter
     */
    search(text) {
        this.filter = text || "";
    }

    /**
     * Load the list of abbreviation entries from the backend
     */
    @action
    async load() {
        this.loading = true;

        let { data } = await http.get(`/project/${this.project}/abbreviation`).stagger(STGR_LOAD);

        this.list = data.list.map((entry) => {
            return new AbbreviationEntry(entry);
        });

        this.loading = false;
        this.loaded = true;
    }

    /**
     * Load the list of abbreviation entries from the backend
     */
    @action
    async loadTemplateValues({ documentId }) {
        this.loading = true;
        const { clientId } = reportDocumentStore;

        let { data } = await http
            .get(`/client/${clientId}/template/${documentId}/abbreviation`)
            .stagger(STGR_LOAD);

        this.list = data.list.map((entry) => {
            return new AbbreviationEntry(entry);
        });

        this.loading = false;
        this.loaded = true;
    }

    /**
     * Save the entry
     */
    @action
    async save(data) {
        return await listStore.save(data);
    }

    /**
     * Save the entry for template value
     */
    @action
    async saveTemplateEntry(documentId, data) {
        return await listStore.saveTemplateEntry(documentId, data);
    }

    @action
    async unload() {
        this.list = [];
        listStore.unload();
    }
}

export default new Abbreviation();
