import React from "react";
import { observer, useLocalStore } from "mobx-react";
import { Form, Button, Select } from "antd";
import MultiStepModal from "@app/components/modal/multi-step-modal";
import ReuseArticleList from "./reuse-article-list";
import { Review } from "@app/constants";
import notify from "@app/components/notify";
import { ReasonType } from "@app/constants";

import projectMappingStore from "@app/state/store/projects/project-mapping";

import "./style/reuse-article.scoped.scss";

const ReuseArticleL1Modal = observer(({ article, articles, stageConfig, onFinish, onCancel }) => {
    const [form1] = Form.useForm();
    const excludeReasons = stageConfig.excludeReasons;

    const state = useLocalStore(() => ({
        currentStepIndex: 0,
        loading: false,
        projectMapping: null,
        selectedArticle: null,
        excludeReason: {},
    }));

    const resetState = () => {
        state.currentStepIndex = 0;
        state.loading = false;
        state.selectedArticle = null;
        state.excludeReason = {};
    };

    const formError = () => {
        notify.error("Please fill all required fields to continue");
    };
    const Footer = observer(({ actions }) => {
        return (
            <div>
                {actions && actions}
                <Button onClick={onCancel}>Cancel</Button>
            </div>
        );
    });

    const Step2 = observer(() => {
        const showReason = (reason) => {
            const newReason = "Add as a new reason";
            state.excludeReason.source = reason;

            const onSelectReason = (value) => {
                if (value) {
                    if (newReason === value) {
                        state.excludeReason.target = reason;
                    } else {
                        state.excludeReason.target = value;
                    }
                }
            };

            if (reason) {
                const found = excludeReasons.find((r) => r.reason === reason);
                if (found) {
                    state.excludeReason.target = reason;
                    return <div style={{ color: "#9D85E2" }}>Existing reason</div>;
                } else {
                    // remove Duplicate Article reason
                    const reasonList = excludeReasons
                        .filter((r) => r.reason !== ReasonType.DUPLICATE)
                        .map((r) => {
                            return { value: r.reason, label: r.reason };
                        });
                    // add newReason option
                    reasonList.push({ value: newReason, label: newReason });

                    // try to match from the project mapping
                    if (reason === state.projectMapping?.article?.excludeReason?.source) {
                        const matchReason = state.projectMapping.article.excludeReason.target;
                        form1.setFieldsValue({
                            excludeReason: matchReason,
                        });
                        state.excludeReason.target = matchReason;
                    }

                    return (
                        <div>
                            <Form layout="vertical" form={form1} onFinishFailed={formError}>
                                <Form.Item
                                    label=""
                                    name="excludeReason"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select an option",
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select a reason"
                                        style={{ width: 300 }}
                                        onChange={onSelectReason}
                                        options={reasonList}
                                    />
                                </Form.Item>
                            </Form>
                        </div>
                    );
                }
            }
        };

        const showStatusPassItem = (status) => {
            if (status === Review.FAIL) {
                return <span style={{ color: "#8C8C8C" }}>excluded</span>;
            } else if (status === Review.PASS) {
                return <span style={{ color: "#4ECB71" }}>included</span>;
            } else {
                return "-";
            }
        };

        return (
            <div className="l1Step2">
                <div>
                    <b>Project:</b> {state.selectedArticle.projectName}
                </div>
                {state.selectedArticle.review.l1?.status === Review.FAIL ? (
                    <div className="statusFail">
                        <div>
                            L1 exclusion reason: {state.selectedArticle.review.l1.fail.reason}
                        </div>
                        {showReason(state.selectedArticle.review.l1.fail.reason)}
                    </div>
                ) : (
                    <div className="statusPass">
                        <div>L1: {showStatusPassItem(state.selectedArticle.review.l1?.status)}</div>
                        <div>L2: {showStatusPassItem(state.selectedArticle.review.l2?.status)}</div>
                    </div>
                )}
            </div>
        );
    });

    const getProjectMapping = async (data) => {
        state.loading = true;
        state.projectMapping = await projectMappingStore.get(data);
        state.loading = false;
    };

    const steps = [
        {
            title: "Previously reviewed in the following projects",
            body: (
                <ReuseArticleList
                    articles={articles}
                    onUse={async (selectedArticle) => {
                        await getProjectMapping({
                            sourceId: selectedArticle.project,
                            targetId: article.project,
                        });
                        state.selectedArticle = selectedArticle;
                        state.currentStepIndex = 1;
                    }}
                />
            ),
            footer: <Footer />,
        },
        {
            title: "Previously reviewed in the following projects",
            body: <Step2 />,
            footer: (
                <Footer
                    actions={
                        <Button
                            type={"primary"}
                            onClick={() => {
                                form1
                                    .validateFields()
                                    .then(async () => {
                                        await onFinish(state);
                                        resetState();
                                        onCancel();
                                    })
                                    .catch(() => {});
                            }}
                        >
                            Accept L1 reference
                        </Button>
                    }
                />
            ),
        },
    ];
    return (
        <MultiStepModal
            steps={steps}
            currentStepIndex={state.currentStepIndex}
            onCancel={onCancel}
            loading={state.loading}
            width={1000}
        />
    );
});

export default ReuseArticleL1Modal;
