import React, { useEffect } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";

import { drawerState as state } from "./context";

import "./style/drawer.scoped.scss";
import { useMediaQuery } from "@app/lib/screen";

const Container = observer((props) => {
    const query = useMediaQuery({ xs: "(max-width: 600px)" });

    // add a dummy use effects so the components is re-rendered
    // when the state._render value changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {}, [state._render]);

    const hidden = state.drawers.length === 0;
    if (hidden) {
        return;
    }

    return (
        <div className={classNames("drawer-container", { "full-screen": query.xs })}>
            {state.drawers.map((entry) => {
                return <React.Fragment key={entry.id}>{entry.drawer}</React.Fragment>;
            })}
        </div>
    );
});

export default Container;
