import React from "react";
import { observer, useLocalStore } from "mobx-react";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import ReviewStatus from "./review-status";
import Title from "./title";
import Authors from "./authors";
import IdList from "./id-list";
import Snippets from "./snippets";
import notify from "@app/components/notify";
import MarkdownIt from "markdown-it";
import Abstract from "./abstract";
import Note from "./note";
import Source from "./source";
import ArticleTags from "./article-tags";
import Keywords from "./keywords";
import EditModal from "./edit/article";
import CommentsTag from "@app/components/comments/chunks/comments-tag";
import format from "@app/lib/format";

import { DownOutlined, UpOutlined, SearchOutlined } from "@ant-design/icons";

import "./style/summary.scoped.scss";

// Configure markdown
const md = new MarkdownIt();
md.set({ html: true });

const Summary = observer(
    ({
        article = {},
        warning,
        className,
        refStyle,
        onChange,
        onAddHighlight,
        keywords,
        readonly = false,
        stage,
    }) => {
        const { type } = useParams();
        const review = article.review || {};

        var state = useLocalStore(() => ({
            editTitle: false,
            editAuthors: false,
            editSource: false,
            editLink: false,
            editId: false,
        }));

        /**
         * Handle the save abstract event
         */
        const saveAbstract = async (data) => {
            // Warn the user if the article has already been acted on (e.g. L1 scored, highlighted, etc).
            if (review.l1?.status) {
                notify.info(
                    "You have already taken action against this abstract. Kindly review your action once you modify the abstract!",
                );
            }

            onChange && (await onChange(data));
        };

        const addTag = (tag) => {
            let tags = [...article.articleTags];
            tags.push(tag);

            article.articleTags = tags;
            onChange && onChange({ articleTags: tags });
        };

        const removeTag = (tag) => {
            let tags = article.articleTags.filter((item) => item._id !== tag._id);

            article.articleTags = tags;
            onChange && onChange({ articleTags: tags });
        };

        return (
            <>
                <div className={classNames("article", className)}>
                    <Title article={article} keywords={keywords} />
                    <Authors article={article} />

                    <IdList article={article} className="id-list" />
                    <ReviewStatus article={article} stage={stage} type={type} />

                    <div className={"tags-comments"}>
                        <ArticleTags
                            article={article}
                            edit={!readonly}
                            onAdd={addTag}
                            onRemove={removeTag}
                        />
                        {article.totalComments > 0 && <CommentsTag count={article.totalComments} />}
                    </div>
                    {/**/}
                    {warning}

                    <Source article={article} refStyle={refStyle} />

                    <Note
                        className="group"
                        text={article.note}
                        add={state.addNote}
                        readonly={readonly}
                        onChange={(note) => {
                            onChange({ note });
                            state.addNote = false;
                        }}
                        onCancel={() => {
                            state.addNote = false;
                        }}
                    />

                    <Abstract
                        abstract={article.abstract}
                        processing={article.processing?.abstract}
                        onChange={saveAbstract}
                        onHighlight={onAddHighlight}
                        readonly={readonly}
                        className="group"
                        keywords={keywords}
                    />
                    <SnippetsList article={article} keywords={keywords} />

                    {article.keywords?.length > 0 && (
                        <Keywords label="Keywords" keywords={article.keywords} />
                    )}

                    {article.descriptors?.length > 0 && (
                        <Keywords label="Descriptors" keywords={article.descriptors} />
                    )}

                    {article.devices?.length > 0 && (
                        <Keywords label="Devices & Manufacturers" keywords={article.devices} />
                    )}

                    {article.publicationType?.length > 0 && (
                        <Keywords label="Publication Type" keywords={article.publicationType} />
                    )}

                    <Search article={article} />

                    {article.linkedArticle?.reviewState && (
                        <Keywords
                            label="Linked Article Status"
                            keywords={[
                                format.articleReviewState(article.linkedArticle.reviewState),
                            ]}
                        />
                    )}
                </div>

                {state.editArticle && (
                    <EditModal
                        article={article}
                        onChange={(data) => {
                            onChange(data);
                            state.editArticle = false;
                        }}
                        onCancel={() => {
                            state.editArticle = false;
                        }}
                    />
                )}
            </>
        );
    },
);

/**
 * Show the list of snippets if there are any
 */
const SnippetsList = observer(({ article, keywords }) => {
    if (article.snippets.length === 0) {
        return;
    }

    return (
        <div className="group snippets">
            <div className="header">
                <div className="title">Snippets</div>
            </div>
            <div className="content">
                <Snippets article={article} includeAbstract={false} keywords={keywords} />
            </div>
        </div>
    );
});

/**
 * Show the search query
 */
const Search = observer(({ article }) => {
    var state = useLocalStore(() => ({
        expand: false,
    }));

    const toggleTerm = (e) => {
        e.stopPropagation();
        state.expand = !state.expand;
    };

    const search = article.search?._id ? article.search : article.originalSearch;

    if (!search?.name) {
        return null;
    }

    return (
        <div className={classNames("search", { expanded: state.expand })}>
            <div className="header" onClick={toggleTerm}>
                <div className="source">
                    <SearchOutlined />
                </div>
                <div className="title">{search.name}</div>
                <div className="toggle">{state.expand ? <UpOutlined /> : <DownOutlined />}</div>
            </div>

            {state.expand && <div className="body">{search.query}</div>}
        </div>
    );
});

export default Summary;
