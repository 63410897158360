import React, { useEffect } from "react";
import { observer, useLocalStore } from "mobx-react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Page } from "@app/components/page";
import Filter from "@app/components/filter";
import ClientCard from "@app/components/client/card";
import EmptyList from "@app/components/list/empty";
import confirm from "@app/components/confirm";
import session from "@app/state/store/session";
import clients from "@app/state/store/clients/list";

const ClientsList = observer((props) => {
    const canEdit = session.can("client.edit");
    const canRemove = session.can("client.remove");

    const state = useLocalStore(() => ({ focus: null }));

    // load the list when the component mounts
    useEffect(() => {
        clients.load();
    }, []);

    /**
     * Focus an item
     */
    const focus = (id) => {
        state.focus = id;
    };

    /**
     * Handle edit event
     */
    const onEdit = (client) => {
        focus(client._id);

        let url = `/clients/${client._id}/edit`;
        props.history.push(url);
    };

    /**
     * Handle delete event
     */
    const onRemove = async (client) => {
        let proceed = await confirm("Are you sure you want to remove this client?");
        if (proceed) {
            await clients.remove(client._id);
        }
    };

    return (
        <Page className="clients">
            <Page.Header>
                <Page.Header.Left shrink>
                    <Page.Title>Clients</Page.Title>
                </Page.Header.Left>

                {canEdit && (
                    <Page.Header.Right>
                        <Link to="/clients/add">
                            <Button type="primary" icon={<PlusOutlined />}>
                                Add Client
                            </Button>
                        </Link>
                    </Page.Header.Right>
                )}
            </Page.Header>

            <Filter.Header filter={clients.filter} />

            <Page.Layout>
                <Page.Body filter={clients.filter}>
                    <EmptyList
                        title="No Results Found"
                        show={clients.list.length === 0 && !clients.loading}
                    >
                        <div>
                            You can use the <b>Add Client</b> button to add more clients.
                        </div>
                    </EmptyList>

                    {clients.list.map((client) => {
                        return (
                            <Link
                                to={`/clients/${client._id}/view`}
                                onClick={() => focus(client._id)}
                                key={client._id}
                            >
                                <ClientCard
                                    client={client}
                                    focus={client._id === state.focus}
                                    onEdit={onEdit}
                                    onRemove={onRemove}
                                    canEdit={canEdit}
                                    canRemove={canRemove}
                                />
                            </Link>
                        );
                    })}
                </Page.Body>
            </Page.Layout>

            <Page.Footer>
                <Filter.Pager filter={clients.filter} />
            </Page.Footer>
        </Page>
    );
});

export default ClientsList;
