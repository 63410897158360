import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import moment from "moment";
import { Page } from "@app/components/page";
import { Button, Pagination } from "antd";
import { FileExcelOutlined } from "@ant-design/icons";
import SearchCriteria from "@app/components/adverse-events/search-criteria";
import Result from "@app/components/adverse-events/result";
import EmptyList from "@app/components/list/empty";
import { triggerDownload } from "@app/lib/file";
import SyncWarning from "../sync-warning";
import { NavLink } from "react-router-dom";

import adverseEvents from "@app/state/store/report/adverse-events";

const AdverseEventsPage = observer((props) => {
    const hasSyncState = adverseEvents?.synchronizing !== undefined;
    const [focused, setFocused] = useState(null);

    useEffect(() => {
        adverseEvents.loadSyncState();
    }, []);

    const focus = (key) => {
        setFocused(key);
    };

    const onPageChange = (newPage) => {
        adverseEvents.pager.currentPage = newPage;
        adverseEvents.loadEvents();
    };

    /**
     * Render the search results
     */
    const Results = observer(() => {
        if (!adverseEvents.loaded) {
            return null;
        }

        if (adverseEvents.results.length === 0) {
            return (
                <EmptyList title="No Results Found" show={!adverseEvents.loading}>
                    No results were found using the search criteria.
                </EmptyList>
            );
        }

        return (
            <div className="results">
                {adverseEvents.results.map((event, idx) => {
                    return (
                        <NavLink
                            to={`./adverse-events/${event.key}`}
                            key={event.key}
                            onClick={() => focus(event.key)}
                        >
                            <Result event={event} focus={event.key === focused} />
                        </NavLink>
                    );
                })}
            </div>
        );
    });

    /**
     * Render the button for exporting the complete result set
     */
    const ExportButton = observer(() => {
        // only show the export button when their are events to export
        if (adverseEvents.results.length === 0) {
            return null;
        }

        const triggerExport = async () => {
            const fileContent = await adverseEvents.export();
            triggerDownload(`AdverseEvents-${moment().format("YYYYMMDDhhmmss")}.xlsx`, fileContent);
        };

        return (
            <Button
                type="primary"
                onClick={triggerExport}
                icon={<FileExcelOutlined />}
                disabled={!adverseEvents.exportable}
                loading={adverseEvents.exporting}
            >
                Export
            </Button>
        );
    });

    const Pager = observer(() => {
        if (!adverseEvents.results || adverseEvents.results.length === 0) {
            return null;
        }

        return (
            <div className="pager">
                <Pagination
                    current={adverseEvents.pager.currentPage}
                    pageSize={adverseEvents.pager.pageSize}
                    showSizeChanger={false}
                    onChange={onPageChange}
                    total={adverseEvents.totalCount}
                />
            </div>
        );
    });

    return (
        <Page className="adverse-events">
            <Page.Header title="MAUDE">
                <Page.Header.Right shrink>
                    <ExportButton />
                </Page.Header.Right>
            </Page.Header>

            <Page.Layout>
                <Page.Body>
                    {hasSyncState && (
                        <>
                            {adverseEvents.synchronizing && <SyncWarning />}
                            {!adverseEvents.synchronizing && (
                                <>
                                    <SearchCriteria />
                                    <Results />
                                </>
                            )}
                        </>
                    )}
                </Page.Body>
            </Page.Layout>

            <Page.Footer>
                <Page.Footer.Left>
                    <Pager />
                </Page.Footer.Left>
                <Page.Footer.Right>
                    {adverseEvents.totalCount ? <>{adverseEvents.totalCount} results</> : ""}
                </Page.Footer.Right>
            </Page.Footer>
        </Page>
    );
});

export default AdverseEventsPage;
