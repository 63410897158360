import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import format from "@app/lib/format";

import "./style/id-list.scoped.scss";

/**
 * Render the id list
 */
const IdList = observer(({ article, className, editLink, editId, ...rest }) => {
    const ids = article.ids || {};
    const links = [];
    const publicationDate = article.publicationDate
        ? format.date(article.publicationDate, "MMM YYYY")
        : "Not Available";

    // add DOI
    if (ids.doi) {
        links.push({ label: "DOI", value: ids.doi, link: `https://doi.org/${ids.doi}` });
    }

    // add PMID
    if (ids.pubmed) {
        links.push({
            label: "PMID",
            value: ids.pubmed,
            link: `https://pubmed.ncbi.nlm.nih.gov/${ids.pubmed}/`,
        });
    }

    // add europe pmc
    if (ids.eupmc) {
        links.push({
            label: "EUPMC",
            value: ids.eupmc,
            link: `https://europepmc.org/article/MED/${ids.eupmc}`,
        });
    }

    // add PMC
    if (ids.pmc) {
        links.push({
            label: "PMC",
            value: ids.pmc,
            link: `https://www.ncbi.nlm.nih.gov/pmc/articles/${ids.pmc}/`,
        });
    }

    // add cochrane
    if (ids.cnid) {
        links.push({
            label: "Cochrane",
            value: ids.cnid,
            link: article.link,
        });
    }

    // add prospero
    if (ids.prid) {
        links.push({
            label: "Prospero",
            value: ids.prid,
            link: article.link,
        });
    }

    // add the source link
    if (article.link) {
        let source = article.link;

        // extract the domain name
        if (source) {
            source = source.replace(/(?<=^\w+tps?:\/\/.*?)\/.*/, "");
            links.push({ label: "Source", value: source, link: article.link });
        }
    }

    // Publication Date
    links.push({
        label: "Published",
        value: publicationDate,
    });

    return (
        <div className={classNames("id-list", className)} {...rest}>
            {links.map((link) => {
                return (
                    <div className="id" key={link.label}>
                        <label>{link.label}</label>
                        <em>
                            {link.link ? (
                                <a href={link.link} target="_blank" rel="noreferrer">
                                    {link.value}
                                </a>
                            ) : (
                                link.value
                            )}
                        </em>
                    </div>
                );
            })}
        </div>
    );
});

export default IdList;
