import { observable } from "mobx";
import Model from "@app/state/model/base";

export default class TplcReport extends Model {
    @observable _id = undefined;
    @observable deviceProblems = [];
    @observable endDate = undefined;
    @observable productCode = undefined;
    @observable startDate = undefined;
    @observable totalEventCount = undefined;
}
