import axios from "axios";
import qs from "qs";
import EventEmitter from "events";
export class HttpClient extends EventEmitter {
    active = 0;
    staggered = {};

    constructor() {
        super();

        let options = {
            baseURL: "/",
            paramsSerializer: function (params) {
                return qs.stringify(params);
            },
        };

        // create the axios instance
        this.instance = axios.create(options);

        // attach response interceptors
        this.instance.interceptors.response.use(
            this._onResponseSuccess.bind(this),
            this._onResponseError.bind(this),
        );

        // attach request interceptors
        this.instance.interceptors.request.use(this._onRequestSuccess.bind(this));

        // delayed complete event
        this.$complete = () => setTimeout(this._complete.bind(this), 100);
    }

    /**
     * Change the client configuration
     */
    configure(config) {
        // istanbul ignore else
        if (config.baseUrl) {
            this.instance.defaults.baseURL = config.baseUrl;
        }

        // istanbul ignore else
        if (config.adapter) {
            this.instance.defaults.adapter = config.adapter;
        }
    }

    /**
     * Perform a HTTP GET request
     */
    get(path, data, options = {}) {
        return this.request({ ...options, url: path, method: "get", params: data });
    }

    /**
     * Perform a HTTP POST request
     */
    post(path, data, options = {}) {
        return this.request({ ...options, url: path, method: "post", data: data });
    }

    /**
     * Perform a HTTP POST request to retrieve a binary file
     */
    // FIXME: This needs to go away. The way we download files is by doing a get request to
    // /api/file/file_id and it sorts itself out. This implementation reds the whole file into
    // the memopry of the browser. What if the file is a few Gigabytes?
    fetchRawFileContent(path, data, options = {}) {
        return this.request({
            ...options,
            url: path,
            method: "post",
            data: data,
            responseType: "arraybuffer",
        });
    }

    /**
     * Perform a HTTP PUT request
     */
    put(path, data, options = {}) {
        return this.request({ ...options, url: path, method: "put", data: data });
    }

    /**
     * Perform a HTTP DELETE request
     */
    delete(path, data, options = {}) {
        return this.request({ ...options, url: path, method: "delete", params: data });
    }

    /**
     * Perform a HTTP request
     */
    request(options) {
        let source = axios.CancelToken.source();
        let token = source.token;

        options.cancelToken = token;
        let req = this.instance.request(options).catch((error) => {
            if (error.response) {
                error.status = error.response.status;
            }

            // return a promise which won't be resolved
            if (axios.isCancel(error)) {
                req.canceled = true;
                return new Promise(() => {});
            }

            return Promise.reject(error);
        });

        // define the cancel function
        req.cancel = () => {
            return source.cancel();
        };

        // define the stagger function
        req.stagger = (key) => {
            let old = this.staggered[key];
            if (old) {
                old.cancel();
            }

            this.staggered[key] = req;
            return req.finally(() => {
                this.staggered[key] = null;
            });
        };

        return req;
    }

    _isNetworkError(ex = {}) {
        const { code, message, response } = ex;

        if (response === undefined && (code === "ECONNABORTED" || message === "Network Error")) {
            return true;
        }
        return false;
    }

    /**
     * Request success interceptor
     */
    _onRequestSuccess(config) {
        this._start();
        return config;
    }

    /**
     * Response success interceptor
     */
    _onResponseSuccess(res) {
        if (res) {
            this.emit("success", res.status);
        }

        this.$complete();
        return res;
    }

    /**
     * Response error interceptor
     */
    _onResponseError(error) {
        this.$complete();

        if (error.response) {
            this.emit("error", error.response.status);
        }

        return Promise.reject(error);
    }

    /**
     * Start a job
     */
    _start() {
        if (this.active === 0) {
            this.emit("loading", true);
        }
        this.active++;
    }

    /**
     * Complete a job
     */
    _complete() {
        this.active--;
        if (!this.active) {
            this.emit("loading", false);
        }
    }
}

const http = new HttpClient();

export default http;
