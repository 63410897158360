import React from "react";
import { Select } from "antd";
import classNames from "classnames";

import config from "@app/config";
import "./style/color-picker.scoped.scss";

class ColorPicker extends Select {
    constructor(props) {
        super(props);

        // clean up some of the props
        this.className = this.props.className;

        delete this.className;
        delete this.showArrow;
    }

    render() {
        return (
            <Select
                className={classNames("color-picker", this.props.className)}
                showArrow={false}
                {...this.props}
            >
                {config.colors.map((entry) => {
                    return (
                        <Select.Option key={entry.name} value={entry.name}>
                            <div className="color-option" style={{ color: entry.color }}></div>
                        </Select.Option>
                    );
                })}
            </Select>
        );
    }
}

export default ColorPicker;
