import React, { useCallback, useEffect } from "react";
import { observer, useLocalStore } from "mobx-react";

import EditModal from "@app/components/abbreviation/edit";
import Picker from "@app/components/abbreviation/picker";

import listStore from "@app/state/store/report/abbreviation/list";

const AbbreviationPlugin = observer(
    ({ editor, abbreviation, previousWord, templateMode, documentId }) => {
        const state = useLocalStore(() => ({
            showPicker: false,
            showEditModal: false,
            entry: undefined,
        }));

        const onShowPicker = useCallback(() => {
            state.showPicker = true;
        });

        const onEditEntry = useCallback((entry) => {
            state.entry = entry;
            state.showEditModal = true;
        });

        const onAddEntry = useCallback((entry) => {
            state.entry = entry;
            state.showPicker = false;
            state.showEditModal = true;
        });

        useEffect(() => {
            if (!editor) {
                return;
            }

            editor.on("abbreviationPicker", onShowPicker);
            editor.on("abbreviationEdit", onEditEntry);
            editor.on("abbreviationCreate", (name) => onAddEntry({ name }));

            return () => {
                editor.off("abbreviationPicker", onShowPicker);
                editor.off("abbreviationEdit", onEditEntry);
                editor.off("abbreviationCreate", onAddEntry);
            };
        }, [editor]);

        useEffect(() => {
            if (editor && previousWord) {
                const found = abbreviation.entryMap[previousWord.text];
                if (found) {
                    editor.commands.replaceWithAbbreviation({
                        key: previousWord.text,
                        from: previousWord.from,
                        to: previousWord.to,
                    });
                }
            }
        }, [previousWord]);

        /**
         * Close the abbreviation picker
         */
        const closePicker = () => {
            state.showPicker = false;
            editor.commands.focus();
        };

        /**
         * Pick a abbreviation entry to add to the content
         */
        const pick = (entry) => {
            editor.chain().focus().addAbbreviationEntry({ key: entry.name }).run();
            closePicker();
        };

        /**
         * Save an entry
         */
        const save = (entry) => {
            if (!listStore.validateSave(entry)) {
                return;
            }

            if (templateMode) {
                abbreviation.saveTemplateEntry(documentId, entry);
            } else {
                abbreviation.save(entry);
            }

            state.entry = undefined;
            state.showEditModal = false;

            // if we create a new entry replace the selected text with it
            if (!entry._id) {
                editor
                    .chain()
                    .focus()
                    .deleteSelection()
                    .addAbbreviationEntry({ key: entry.name })
                    .run();
            }
        };

        return (
            <>
                {state.showPicker && (
                    <Picker
                        onClose={closePicker}
                        onPick={pick}
                        onAddEntry={onAddEntry}
                        abbreviation={abbreviation}
                        templateMode={templateMode}
                        documentId={documentId}
                    />
                )}

                {state.showEditModal && (
                    <EditModal
                        entry={state.entry}
                        onChange={save}
                        onCancel={() => {
                            state.entry = null;
                            state.showEditModal = false;
                        }}
                    />
                )}
            </>
        );
    },
);

export default AbbreviationPlugin;
