import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { MentionsInput, Mention } from "react-mentions";

import assigned from "@app/state/store/projects/assigned";
import { useParams } from "react-router-dom";
import "./style/mention.scss";

const Mentions = observer(
    ({
        users = [],
        onChange,
        onKeyDown,
        value,
        placeholder = "Comment here...",
        focus = false,
        setFocus,
    }) => {
        const { project } = useParams();

        const inputEl = React.useRef(null);

        useEffect(() => {
            if (users.length === 0) {
                assigned.load(project);
            }
        }, [project, users]);

        useEffect(() => {
            if (inputEl?.current !== null && focus) {
                inputEl.current.focus();
            }
        }, [inputEl, focus]);

        const onChangeHandler = (e, newValue) => {
            onChange(newValue);
        };

        return (
            <MentionsInput
                className="mentions"
                allowSuggestionsAboveCursor={true}
                allowSpaceInQuery={true}
                value={value ?? ""}
                placeholder={placeholder}
                onChange={onChangeHandler}
                inputRef={inputEl}
                onKeyDown={onKeyDown}
                onFocus={(e) =>
                    e.currentTarget.setSelectionRange(
                        e.currentTarget.value.length,
                        e.currentTarget.value.length,
                    )
                }
                onBlur={(e) => {
                    setFocus && setFocus(false);
                }}
            >
                <Mention
                    displayTransform={(_, name) => `${name}`}
                    trigger="@"
                    data={users
                        .filter((user) => user._id && user.fullName)
                        .map((user) => {
                            user.id = user._id;
                            user.display = user.fullName;
                            return user;
                        })}
                    markup="@{{__display__||__id__}}"
                    appendSpaceOnAdd={true}
                    className="mentions__mention"
                />
            </MentionsInput>
        );
    },
);

export default Mentions;
