import { action, computed } from "mobx";
import events from "@app/lib/store/events";

import BaseStore from "../../base";
import report from "../../report";
import Bookmark from "@app/state/model/document/bookmark";
import Category from "@app/state/model/document/category";
import FilterState from "@app/components/filter/state";

import http from "@app/lib/http";
import { ws } from "@app/lib/socket";

export class BookmarkStore extends BaseStore {
    filter = null;

    observable() {
        return {
            document: null,
            bookmarks: null,
            categories: null,
            loading: false,
        };
    }

    constructor() {
        super();

        events.on("category.update", this.loadCategories.bind(this));
        events.on("project.unload", () => {
            this.reset();
        });
        ws.on("bookmarks.update", () => {
            this.load(this.document);
        });

        this.filter = new FilterState({
            default: {
                search: "",
                user: null,
            },
        });
    }

    @computed get project() {
        return report.id;
    }

    @computed get busy() {
        return this.loading || this.saving;
    }

    /**
     * Load the bookmarks
     */
    @action
    async load(document) {
        this.loading = true;

        // reset the bookmarks list if the document property changes
        if (this.document !== document && this.bookmarks) {
            this.bookmarks = [];
        }

        this.document = document;
        let { data } = await http.get(`/project/${this.project}/document/bookmarks`, {
            document,
        });

        this.bookmarks = data.map((entry) => {
            return new Bookmark(entry);
        });

        // load the categories
        if (this.categories === null) {
            await this.loadCategories();
        }

        this.loading = false;
    }

    @computed get filteredGroupedBookmarks() {
        const group = {};

        const { search, user } = this.filter.value();

        let bookmarks = this.bookmarks ?? [];

        if (user) {
            bookmarks = bookmarks.filter((bookmark) => bookmark.author?.fullName === user);
        }

        if (search) {
            const tokens = search.toLowerCase().split(" ");
            bookmarks = bookmarks.filter((bookmark) => {
                return tokens.every((token) => bookmark.name.toLowerCase().indexOf(token) !== -1);
            });
        }

        const categories = this.categories ?? [];

        const none = (group["_"] = {
            _id: "_",
            name: "Uncategorized",
            bookmarks: [],
        });

        categories.map((category) => {
            group[category._id] = {
                _id: category._id,
                name: category.name,
                bookmarks: [],
            };
        });

        bookmarks.map((bookmark) => {
            if (bookmark.categories.length) {
                bookmark.categories.map((id) => {
                    // eslint-disable-next-line no-unused-expressions
                    group[id]?.bookmarks.push(bookmark);
                });
            } else {
                none.bookmarks.push(bookmark);
            }
        });

        return Object.values(group).filter((group) => group.bookmarks.length > 0);
    }

    /**
     * Save a bookmark
     */
    @action
    async save(bookmark, focusBookmark) {
        if (bookmark._id) {
            // update existing comment
            const { data } = await http.post(
                `/project/${this.project}/document/${bookmark.document}/bookmark/${bookmark._id}`,
                bookmark,
            );

            let found = this.bookmarks.find((el) => el._id === bookmark._id);
            if (found) {
                found.name = data.name;
                found.categories = data.categories;
                this.bookmarks = [...this.bookmarks];
            }
        } else {
            // add a new bookmark
            const { data } = await http.put(
                `/project/${this.project}/document/${bookmark.document}/bookmark`,
                bookmark,
            );

            // eslint-disable-next-line no-unused-expressions
            this.bookmarks?.push(new Bookmark(data));

            if (focusBookmark) {
                focusBookmark(data);
            }
        }
    }

    /**
     * Remove a bookmark
     */
    @action
    async remove(bookmark) {
        await http.delete(
            `/project/${this.project}/document/${bookmark.document}/bookmark/${bookmark._id}`,
        );

        if (this.bookmarks) {
            this.bookmarks = this.bookmarks.filter((el) => el._id !== bookmark._id);
        }
    }

    /**
     * Load the list of categories
     */
    @action
    async loadCategories() {
        if (!this.project) {
            return;
        }

        let { data } = await http.get(`/project/${this.project}/document/categories`, {
            enabled: "true",
        });

        this.categories = data.list.map((entry) => {
            return new Category(entry);
        });
    }

    reset() {
        this.filter.reset();
        super.reset();
    }
}

export default new BookmarkStore();
