import { action, computed } from "mobx";
import BaseStore from "../../base";
import http from "@app/lib/http";
import PatientProblemReport from "@app/state/model/patient-problem-report";

import report from "../../report";
import notify from "@app/components/notify/index";

/**
 * State management controlling the list of patient problem reports rendered
 */
export class PatientProblems extends BaseStore {
    /**
     * Observable store data
     */
    observable() {
        return {
            reports: [],
            loading: false,
        };
    }

    /**
     * Return the project id if the currently loaded project
     */
    @computed get project() {
        return report.id;
    }

    /**
     * Load the set of TPLC reports that exist for the current projects
     */
    @action
    async load() {
        this.loading = true;

        let { data } = await http.get(`/project/${this.project}/patient-problems`);
        this.reports = data.list.map((entry) => {
            return new PatientProblemReport(entry);
        });

        this.loading = false;
    }

    /**
     * Generates a new set of TPLC reports for the current projects
     */
    @action
    async generate(config) {
        try {
            this.loading = true;

            let { data } = await http.post(
                `/project/${this.project}/patient-problems/generate`,
                config,
            );
            this.reports = data.map((entry) => {
                return new PatientProblemReport(entry);
            });
        } catch (ex) {
            notify.error(ex.response?.data?.error || "Unable to generate the report.");
        } finally {
            this.loading = false;
        }
    }

    /**
     * Retrieves the set of adverse events contained within a patient problems report as an Excel file
     */
    @action
    async export(productCode) {
        let { data } = await http.fetchRawFileContent(
            `/project/${this.project}/patient-problems/${productCode}/export`,
        );
        return data;
    }

    @action
    async options() {
        this.loading = true;
        const { data } = await http.get(`/project/${this.project}/patient-problems/options`);
        this.loading = false;
        return data;
    }
}

export default new PatientProblems();
