import React from "react";
import MarkdownIt from "markdown-it";
import classNames from "classnames";
import format from "@app/lib/format";

import "./style/editor.scss";
import "./style/card.scss";

// Configure markdown
const md = new MarkdownIt();

md.set({ html: true });

const HtmlViewer = ({
    content = "",
    className,
    doubleLineBreaks = false,
    truncate = false,
    keywords = [],
    ...rest
}) => {
    let renderContent = doubleLineBreaks ? content.replace(/\n/g, "\n\n") : content;
    renderContent = format.sanitize(renderContent);

    // If truncate is numeric, apply to text
    if (truncate && typeof truncate === "number") {
        renderContent = renderContent.substring(0, truncate);
        renderContent = renderContent.replace(/(\s\w*)?$/, " …");
    }

    // render the md to html and apply the highlights
    let html = md.render(renderContent);
    html = format.highlight(html, keywords);

    return (
        <div
            className={classNames("html", className)}
            dangerouslySetInnerHTML={{ __html: html }}
            {...rest}
        ></div>
    );
};

export default HtmlViewer;
