import { action, computed } from "mobx";
import http from "@app/lib/http";
import BaseStore from "@app/state/store/base";

import report from "../../../report";

export class SearchStringsStore extends BaseStore {
    /**
     * Observable store data
     */
    observable() {
        return {
            list: [],
            loading: false,
        };
    }

    @computed get busy() {
        return this.loading;
    }

    @computed get project() {
        return report.id;
    }

    @action
    async load(type) {
        this.loading = true;

        let { data } = await http.get(`/project/${this.project}/reports/searches/${type}`);

        this.list = data.map(({ time, ...search }) => ({
            time: new Date(time),
            ...search,
        }));

        this.loading = false;
    }
}

export default new SearchStringsStore();
