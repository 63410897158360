import React, { useState } from "react";
import { Select } from "antd";
import { observer } from "mobx-react";

import versionAliasStore from "@app/state/store/report-document/version-alias";

import { Empty } from "@app/components/empty";

import VersionListItem from "./version-list-item";

const UserSelect = ({ onSelectUser, userMenuValues = [], userId }) => {
    const optionsUsers = [
        { value: 0, label: "Show all users" },
        {
            label: "Show changes made by user:",
            options: userMenuValues,
        },
    ];

    if (userMenuValues.length <= 1) {
        return null;
    }

    return (
        <Select
            placeholder="Show all users"
            mode="single"
            maxTagCount={2}
            allowClear
            autoClearSearchValue={false}
            className="user-select"
            value={userId}
            options={optionsUsers}
            onSelect={onSelectUser}
        />
    );
};

const aliasFilter = ({ createdBy } = {}, { userId }) => {
    let result = true;

    if (userId) {
        result = createdBy?._id === userId;
    }

    return result;
};

const List = observer(() => {
    const [userId, setUserId] = useState(null);
    const { aliases, userMenuValues, selectedAliasId } = versionAliasStore;

    if (!aliases.length) {
        return <Empty title="No versions have been created" />;
    }

    return (
        <div>
            <UserSelect onSelectUser={setUserId} userMenuValues={userMenuValues} userId={userId} />
            <ul>
                {aliases
                    .filter((a) => aliasFilter(a, { userId }))
                    .sort((a, b) => new Date(b.versionDate) - new Date(a.versionDate))
                    .map(
                        (
                            { versionName, _id, versionId, createdBy, versionDate, created },
                            index,
                        ) => (
                            <VersionListItem
                                key={_id}
                                index={index}
                                versionName={versionName}
                                versionId={versionId}
                                createdBy={createdBy}
                                aliasId={_id}
                                selectedAliasId={selectedAliasId}
                                versionDate={versionDate}
                                created={created}
                            />
                        ),
                    )}
            </ul>
        </div>
    );
});

export default List;
