import "antd/dist/antd.less";
import "mobx-react/batchingForReactDom";
import React from "react";
import ReactDOM from "react-dom";
import config from "./config";
import http from "./lib/http";
import Router from "./routes";
import "./styles/main.scss";
import "react-sortable-tree/style.css";

// setup mocks http request
if (process.env.HTTP_MOCK) {
    // setup the mock request adaptor
    let { mock } = require(`./lib/mock`);
    mock.install(http, {
        delay: process.env.MOCK_DELAY,
    });

    require(`@mock/index`);

    // enable the mock in the web socket client
    let { ws } = require(`./lib/socket`);
    ws.mock = true;
}

// configure the http client
http.configure({ baseUrl: config.backend });

// enable hot reloading
if (module.hot) {
    module.hot.accept();

    module.hot.addStatusHandler((status) => {
        if (status === "apply") {
            console.clear();
        }
    });
}

// render the app
ReactDOM.render(
    // <React.StrictMode>
    <Router />,
    // </React.StrictMode>,
    document.getElementById("app"),
);
