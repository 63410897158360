import React, { createContext } from "react";

export const UploadContext = createContext();

export const UploadContextProvider = ({ projectId, clientId, children }) => {
    return (
        <UploadContext.Provider value={{ projectId, clientId }}>{children}</UploadContext.Provider>
    );
};

/**
 * A convenient hook to use the page context and check if it
 * is available in the place of usage
 */
export function useUploadContext() {
    const context = React.useContext(UploadContext);
    if (context === null) {
        throw new Error(
            "Missing upload context. The upload component require a valid upload" +
                "context to function properly",
        );
    }

    return context;
}
