import React from "react";
import { observer } from "mobx-react";
import Filter from "@app/components/filter";
import Sidebar from "@app/components/filter/sidebar";
import config from "@app/config";
import session from "@app/state/store/session";

const UserFilter = observer(({ filter }) => {
    const roleFacets = filter.state.stats?.facets?.role || {};
    const statusFacets = filter.state.stats?.facets?.status || {};

    // const isAdmin = session.user.roles.includes(Role.ADMIN);
    const isAdmin = session.can("system.admin");

    return (
        <Sidebar filter={filter}>
            <Filter.OptionGroup filter={filter} title="Roles" name="role" groupKey="user.role">
                {config.roles.map((role) => {
                    if (!isAdmin && role.viewOnly) {
                        return;
                    }

                    const count = roleFacets[role.value] ?? 0;

                    return (
                        <Filter.Option
                            label={role.label}
                            value={role.value}
                            count={count}
                            key={role.value}
                        ></Filter.Option>
                    );
                })}
            </Filter.OptionGroup>

            <Filter.OptionGroup filter={filter} title="Status" name="status" groupKey="user.status">
                <Filter.Option
                    label="Active"
                    value="active"
                    count={statusFacets.active ?? 0}
                    key="active"
                ></Filter.Option>
                <Filter.Option
                    label="Pending"
                    value="pending"
                    count={statusFacets.pending ?? 0}
                    key="pending"
                ></Filter.Option>
            </Filter.OptionGroup>
        </Sidebar>
    );
});

export default UserFilter;
