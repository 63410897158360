import { v4 as uuid } from "uuid";
import { mergeAttributes, Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import Component from "./node";

import tr from "../utils/transaction";
import documentStore from "@app/state/store/report-document/document";

export default Node.create({
    name: "report",
    group: "block",
    inline: false,
    selectable: true,

    addStorage() {
        return {
            store: {},
        };
    },

    onBeforeCreate() {
        const readonly = !this.editor.options.editable;
        this.options.readonly = readonly;
        this.type.spec.selectable = !readonly;

        this.storage.store = this.options.store || {};
    },

    onTransaction(args) {
        let update = false;
        const editor = args.editor;

        if (!tr.isEdit(args)) {
            return;
        }

        const added = tr.addedNodes(args, { type: this.name });
        if (added.length) {
            update = true;
        }

        const removed = tr.removedNodes(args, { type: this.name });
        if (removed.length) {
            update = true;
        }

        if (update && editor.sectionId) {
            const content = editor.getJSON();
            documentStore.processSection(editor.sectionId, content);
        }
    },

    addAttributes() {
        return {
            id: {
                default: "",
                parseHTML: (report) => {
                    if (!report.hasAttribute("data-diff-mode") || !report.hasAttribute("id")) {
                        return uuid();
                    }

                    return report.getAttribute("id");
                },
            },
            report: {
                default: null,
                parseHTML: (el) => el.getAttribute("data-report"),
                renderHTML: (attrs) => ({ "data-report": attrs.report }),
            },
            type: {
                default: "slr",
                parseHTML: (el) => el.getAttribute("type"),
                renderHTML: (attrs) => ({ type: attrs.type }),
            },
            caption: {
                default: "",
            },
            dataType: {
                default: "",
            },
            reportType: {
                default: "",
            },
            headersWidth: {
                default: {},
            },
            error: {
                default: false,
            },
            "data-diff-node": {
                default: undefined,
            },
            "data-diff-id": {
                default: undefined,
                parseHTML: (img) => {
                    if (img.hasAttribute("data-diff-node")) {
                        return uuid();
                    }

                    return undefined;
                },
            },
        };
    },

    parseHTML() {
        return [{ tag: "report" }];
    },

    renderHTML({ HTMLAttributes }) {
        return ["report", mergeAttributes(HTMLAttributes)];
    },

    addNodeView() {
        return ReactNodeViewRenderer(Component);
    },

    addCommands() {
        return {
            selectReport: () => async ({ editor }) => {
                editor.emit("selectReport");
            },
            insertReport: ({ report, type, caption, dataType, reportType }) => async ({
                editor,
                commands,
            }) => {
                const { selection } = editor.state;
                commands.insertContentAt(selection.to, {
                    type: this.name,
                    attrs: { id: uuid(), report, type, caption, dataType, reportType },
                });
            },
            previewReport: (report) => async ({ editor }) => {
                editor.emit("previewReport", report);
            },
            editReportCaption: () => ({ editor }) => {
                if (editor.state.selection) {
                    const node = editor.state.selection.node;
                    if (node?.type?.name === this.name) {
                        return true;
                    }
                }

                return false;
            },
        };
    },
});
