import React, { useEffect } from "react";
import { observable } from "mobx";

export class MultiSelectState {
    @observable totalList = [];
    @observable selected = [];
    @observable stats = { selected: 0, unSelected: 0 };
    @observable serialisedTotalList;

    setTotalList = (value) => {
        this.totalList = value;
    };

    setSelected = (value) => {
        this.selected = value;
    };

    setStats = (value) => {
        this.stats = value;
    };

    setSerialisedTotalList = (value) => {
        this.serialisedTotalList = value;
    };

    reset = () => {
        this.setSelected(
            this.totalList.map((item) => {
                return { _id: item, checked: false };
            }),
        );
    };

    selectAll = () => {
        this.setSelected(
            this.totalList.map((item) => {
                return { _id: item, checked: true };
            }),
        );
    };

    isChecked = (id) => {
        return this.selected?.find((item) => item._id === id)?.checked;
    };

    toggle = (id, option) => {
        const selectItem = (id) => {
            this.setSelected(
                this.selected.map((item) =>
                    item._id === id ? { _id: item._id, checked: !item.checked } : item,
                ),
            );
        };

        if (option?.range) {
            const currentIndex = this.selected.findIndex((item) => item._id === id);
            const firstSelectedIndex = this.selected.findIndex((item) => item.checked);
            const lastSelectedIndex =
                this.selected.length -
                1 -
                this.selected
                    .slice()
                    .reverse()
                    .findIndex((item) => item.checked);

            if (firstSelectedIndex === -1) {
                selectItem(id);
            } else {
                const newSelected = this.selected.map((item, i) => {
                    if (
                        (currentIndex > firstSelectedIndex &&
                            i <= currentIndex &&
                            i >= firstSelectedIndex) ||
                        (currentIndex < lastSelectedIndex &&
                            i >= currentIndex &&
                            i <= lastSelectedIndex)
                    ) {
                        return { ...item, checked: true };
                    } else {
                        return item;
                    }
                });

                this.setSelected([...newSelected]);
            }
        } else {
            selectItem(id);
        }
    };
}

const createState = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const state = React.useMemo(() => {
        return new MultiSelectState();
    }, []);

    return state;
};

const Context = React.createContext(null);

const useContext = () => {
    const context = React.useContext(Context);

    if (context === null) {
        throw new Error("useContext must be used within a MultiSelect");
    }

    const {
        selected,
        totalList,
        setStats,
        serialisedTotalList,
        setSerialisedTotalList,
        reset,
    } = context;

    useEffect(() => {
        const cloneList = [...totalList];
        const serialisedList = JSON.stringify(cloneList.sort());

        if (serialisedList !== serialisedTotalList) {
            setSerialisedTotalList(serialisedList);
            reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalList]);

    useEffect(() => {
        const calculateStats = () => {
            let selectedCount = 0;
            let unSelectedCount = 0;

            if (selected?.length > 0) {
                selected.forEach((item) => {
                    if (item.checked) {
                        selectedCount++;
                    } else {
                        unSelectedCount++;
                    }
                });
                setStats({ selected: selectedCount, unSelected: unSelectedCount });
            }
        };

        calculateStats();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    return { context };
};

export default Context;
export { Context, useContext, createState };
