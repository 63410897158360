import { computed, action } from "mobx";
import { v4 as uuid } from "uuid";
import BaseStore from "@app/state/store/base";
import http from "@app/lib/http";
import { ws } from "@app/lib/socket";
import { events } from "@app/lib/store";

import notify from "@app/components/notify";
import Filter from "@app/components/filter/state";
import Search from "@app/state/model/search";
import Result from "@app/state/model/search/result";
import History from "@app/state/model/search/history";
import Comment from "@app/state/model/comment";

// report wrapper state
import report from "../report";

const SEARCH_ID = uuid();
const RESULTS_ID = uuid();

/**
 * Search state class
 */
export class QueryStore extends BaseStore {
    /**
     * Observable store data
     */
    observable() {
        return {
            data: null,
            results: [],
            history: {
                source: null,
                records: [],
            },
            loading: false,
            fetching: false,
            upload: false,
            allowSave: false,
            comments: [],
        };
    }

    @computed get busy() {
        return this.loading || this.saving || this.fetching;
    }

    /**
     * Return the project id if the currently loaded project
     */
    @computed get project() {
        return report.id;
    }

    constructor() {
        super();

        // results pager
        this.pager = new Filter({ rows: 20 });
        this.pager.on("find", () => {
            if (this.data?._id) {
                this.loadResults();
            }
        });

        // query state
        this.filter = new Filter({ default: { excludeBooks: true } });

        // listen on ws events to update the save progress
        ws.on("search.fetch", (data) => {
            if (this.data?._id === data.id) {
                this.data.status = "saving";
                this.data.progress = data.progress;
            }
        });

        // listen on complte event to set total number of articles
        ws.on("search.complete", (data) => {
            if (this.data?._id === data.id) {
                this.data.status = "saved";
                this.fetching = false;

                // reload the search to show the logs
                this.load(data.id, { force: true, clear: false });
                this.loadResults();
            }
        });

        events.on("project.unload", () => {
            this.reset();
        });
    }

    notifyError(error) {
        const map = {
            SOTA_DISABLED: "SoTA is disabled for this project",
            INVALID_FILE_FORMAT: "Please upload a valid file to continue.",
            SEARCH_FAILURE: "There was an error performing the search.",
        };
        const message = map[error];
        if (message) {
            notify.error(message);
        } else {
            notify.error(error || "An error occurred while trying to perform the action");
        }
    }

    /**
     * Perform a search
     */
    @action
    async search(saveHistory = true) {
        if (!this.project) {
            return;
        }

        this.loading = true;
        const isNewSearch = !this.data?._id;

        try {
            let { data } = await http
                .post(`/project/${this.project}/search`, { ...this.filter.value(), saveHistory })
                .stagger(SEARCH_ID);

            // save the search details
            this.data = new Search(data);

            // reset the results pagination
            this.pager.reset();
            this.pager.stats({ count: data.results });

            // load the results
            if (!isNewSearch && this.data?._id) {
                await this.loadResults();
            }

            // add to the search history
            if (saveHistory) {
                this.history.records.unshift(
                    new History({
                        ...data,
                        _id: uuid(),
                        date: new Date(),
                    }),
                );
            }

            return { success: true };
        } catch (ex) {
            if (ex.response?.data.code === "SEARCH_FAILURE") {
                this.results = [];
            }

            this.notifyError(ex.response?.data.code);
            return { error: true, code: ex.response?.data.code };
        } finally {
            this.loading = false;
        }
    }

    /**
     * Load search details
     */
    @action
    async load(id, args = {}) {
        if (this.loading || (!args.force && this.data?.id === id)) {
            return;
        }

        this.loading = true;

        // reset the data when start loading another search
        if (args.clear !== false) {
            this.data = null;
            this.results = [];
        }

        let { data } = await http.get(`/project/${this.project}/search/${id}`);
        this.data = new Search(data);

        // allow a search to be saved immediately after loading if it is not
        // saved and if it is not a clone of another search.
        this.allowSave = this.data.status !== "saved" && !this.data.updateFor;

        this.filter.filter("query", data.query, { trigger: false });
        this.filter.filter("source", data.source, { trigger: false });
        this.filter.filter("type", data.type, { trigger: false });
        this.filter.filter("excludeBooks", data.excludeBooks, { trigger: false });
        this.filter.filter("humanOnly", data.humanOnly, { trigger: false });
        this.filter.filter("expandSynonyms", data.expandSynonyms, { trigger: false });
        this.filter.filter("minPublicationDate", data.minPublicationDate, { trigger: false });
        this.filter.filter("maxPublicationDate", data.maxPublicationDate, { trigger: false });
        this.filter.filter("publicationTypes", data.publicationTypes, { trigger: false });
        this.filter.filter("languageCode", data.languageCode, { trigger: false });
        this.filter.filter("maxResults", data.maxResults, { trigger: false });
        this.filter.filter("medline", data.medline, { trigger: false });
        this.filter.filter("performedOn", data.performedOn, { trigger: false });
        this.filter.filter(
            "year",
            {
                min: data.minPublicationYear,
                max: data.maxPublicationYear,
            },
            { trigger: false },
        );

        // reset the results pagination
        this.pager.reset();
        this.pager.stats({ count: data.results });
        this.pager.update();

        if (data._id) {
            await this.loadComments();
        }

        this.loading = false;
    }

    /**
     * Include the search results for the review stage
     */
    @action
    async include(id) {
        if (!id) {
            return;
        }

        this.loading = true;

        try {
            this.data.saving = true;

            const result = await http.put(`/project/${this.project}/search/${id}/save`);
            const verified = result.data?.verified ? result.data.verified : this.data.verifed;
            this.data.verified = verified;
            // this.data.saved = verified === "successful";

            return { success: true, verified };
        } catch (ex) {
            // limit exceeded
            if (ex.status === 403) {
                return { error: true, limit: true };
            }

            // other error
            return { error: true };
        } finally {
            this.loading = false;
            this.fetching = true;
        }
    }

    /**
     * Exclude the search results for the review stage
     */
    @action
    async exclude(id) {
        if (!id) {
            return;
        }

        this.loading = true;
        await http.delete(`/project/${this.project}/search/${id}/save`);

        // update the state
        this.data.status = "hidden";
        this.loading = false;
    }

    /**
     * Start the search editing process
     */
    @action
    async edit(id) {
        if (!id) {
            return;
        }

        this.loading = true;
        const { data } = await http.put(`/project/${this.project}/search/${id}/edit`);

        // save the search details
        this.data = new Search(data);
        this.loading = false;

        return data;
    }

    /**
     * Update the search
     */
    @action
    async update(id) {
        if (!id) {
            return;
        }

        try {
            this.loading = true;
            const { data } = await http.put(`/project/${this.project}/search/${id}/update`);
            events.emit("search.update");
            return data;
        } catch (ex) {
            // limit exceeded
            if (ex.status === 403) {
                return { error: true, limit: true };
            }

            // other error
            return { error: true };
        } finally {
            this.loading = false;
        }
    }

    /**
     * Create an empty search object
     */
    @action
    create() {
        let search = new Search();
        search.project = this.project;

        this.filter.reset();
        this.pager.reset();

        // set the default language filter
        this.filter.filter("languageCode", ["EN"], { trigger: false });

        // expand the synonym by default
        this.filter.filter("expandSynonyms", true, { trigger: false });

        this.data = search;
        this.results = [];
    }

    /**
     * Load search results from the backend
     */
    @action
    async loadResults() {
        this.loading = true;

        let filter = this.pager.value();
        filter.maxResults = this.filter.value().maxResults;
        let { data } = await http
            .get(`/project/${this.project}/search/${this.data._id}/results`, filter)
            .stagger(RESULTS_ID);

        this.results = data.map((entry) => {
            return new Result(entry);
        });

        this.loading = false;
    }

    /**
     * Load the search history
     */
    @action
    async loadHistory(source) {
        if (source === this.history.source) {
            return;
        }

        this.history.source = source;

        let { data } = await http.get(`/project/${this.project}/search/history`, { source });
        this.history.records = data.map((entry) => {
            return new History(entry);
        });
    }

    /**
     * Reset the search
     */
    @action
    async reset() {
        this.results = [];
        this.history = {
            source: undefined,
            records: [],
        };
        this.filter.reset();
        this.allowSave = false;
        this.comments = [];
    }

    /**
     * Load the comments
     */
    @action
    async loadComments(sort = "desc") {
        if (!this.data?._id) {
            return;
        }

        let { data } = await http.get(
            `/project/${this.project}/search/${this.data._id}/comments?sort=${sort}`,
        );

        this.comments = data.map((entry) => {
            return new Comment(entry);
        });
    }

    /**
     * Save a comment
     */
    @action
    async saveComment(comment, sort = "desc") {
        if (!this.data?._id) {
            return;
        }

        if (comment._id) {
            // update existing comment
            const { data } = await http.post(
                `/project/${this.project}/search/${this.data._id}/comment/${comment._id}`,
                comment,
            );

            let found = this.comments.find((el) => el._id === comment._id);
            if (found) {
                found = data.text;
            }
        } else {
            // add a new comment
            const { data } = await http.put(
                `/project/${this.project}/search/${this.data._id}/comment`,
                comment,
            );

            if (sort === "desc") {
                this.comments.unshift(new Comment(data));
            } else {
                this.comments.push(new Comment(data));
            }
        }
    }

    /**
     * Remove a comment
     */
    @action
    async removeComment(comment) {
        await http.delete(
            `/project/${this.project}/search/${this.data._id}/comment/${comment._id}`,
        );

        this.comments = this.comments.filter((el) => el._id !== comment._id);
    }

    /**
     * Resolve a comment
     */
    @action
    async resolveComment(params) {
        const { data } = await http.put(
            `/project/${this.project}/search/${this.data._id}/comment/${params._id}/resolve`,
        );

        let comment = this.comments.find((el) => el._id === params._id);

        if (comment) {
            comment.status = data.status;
            comment.resolvedBy = data.resolvedBy;
            comment.resolvedOn = data.resolvedOn;
            comment.replies = data.replies;
        }
    }

    /**
     * Mark a comment as pending
     */
    @action
    async unResolveComment(params) {
        const { data } = await http.put(
            `/project/${this.project}/search/${this.data._id}/comment/${params._id}/unresolve`,
        );

        let comment = this.comments.find((el) => el._id === params._id);

        if (comment) {
            comment.status = data.status;
            comment.resolvedBy = undefined;
            comment.resolvedOn = undefined;
            comment.replies = data.replies;
            if (!comment.expanded) {
                comment.expanded = true;
            }
        }
    }

    /**
     * Add a reply to an existing comment
     */
    @action
    async addReply(params) {
        const { data } = await http.post(
            `/project/${this.project}/search/${this.data._id}/comment/${params.comment._id}/reply`,
            {
                text: params.text,
            },
        );

        const comment = this.comments.find((el) => el._id === params.comment._id);
        comment.replies = data.replies;
    }

    /**
     * Update an existing comment's reply
     */
    @action
    async updateReply(params) {
        const { data } = await http.put(
            `/project/${this.project}/search/${this.data._id}/comment/${params.comment._id}/reply/${params.replyId}`,
            {
                reply: params.reply,
                text: params.text,
            },
        );

        const comment = this.comments.find((el) => el._id === params.comment._id);

        comment.replies = data.replies;
    }

    /**
     * Remove an existing reply
     */
    @action
    async removeReply(params) {
        const { data } = await http.delete(
            `/project/${this.project}/search/${this.data._id}/comment/${params.comment._id}/reply/${params.replyId}`,
        );

        const comment = this.comments.find((el) => el._id === params.comment._id);
        comment.replies = data.replies;
    }
}

export default new QueryStore();
