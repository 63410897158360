import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Tag, Tooltip } from "antd";
import { CategoryStatus } from "@app/constants";
import Card from "@app/components/card/card";
import format from "@app/lib/format";

import "./style/card.scoped.scss";

const Status = observer(({ status }) => {
    if (status === CategoryStatus.PENDING) {
        return <Tag className="tag">Pending</Tag>;
    } else if (status === CategoryStatus.APPROVED) {
        return (
            <Tag color="green" className="tag">
                Approved
            </Tag>
        );
    }
    return null;
});

const CategoryCard = observer(({ category, className, ...rest }) => {
    const count = category.documents?.count ?? 0;
    return (
        <>
            <Card className={classNames("card", className)} {...rest}>
                <div>
                    <div className="name">{category.name}</div>
                    <div className="count">
                        {count} {format.pluralize(count, "Document")}
                        <Status status={category.status} />
                    </div>
                    {category.documents?.hasPending === true ? (
                        <Tooltip title="This category has documents which are not accepted yet">
                            <div className="red-dot"></div>
                        </Tooltip>
                    ) : null}
                </div>
            </Card>
        </>
    );
});

export default CategoryCard;
