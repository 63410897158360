import React from "react";
import { observer } from "mobx-react";
import "./style/question.scoped.scss";

const Question = observer(({ data }) => {
    return (
        <div className="details">
            <div className="info">Review Question: {data?.question}</div>
            <div className="info">Report Label: {data?.contribution}</div>
            <div className="info">Color: {data?.color}</div>
            {data?.answers?.map((entry, index) => {
                let type = entry.type === "include" ? "Include" : "Exclude";

                return (
                    <div key={entry.key} id={entry.key} index={index}>
                        <div className="question-details">
                            Grade: {entry.grade} <br />
                            Answer: {entry.answer} <br />
                            Include/Exclude: {type} <br />
                            Score: {entry.score} <br />
                        </div>
                    </div>
                );
            })}
        </div>
    );
});

export default Question;
