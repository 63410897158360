import React from "react";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import { Page } from "@app/components/page";
import { Box } from "@app/components/box";

import "./style/page.scoped.scss";
import project from "@app/state/store/projects/details";

const EditPage = observer(({ onSave, step, title, nextButton, children, ...rest }) => {
    const history = useHistory();
    const { id } = useParams();

    /**
     * Close the page
     */
    const close = () => {
        history.push("/projects");
    };

    return (
        <Page className="project edit" {...rest}>
            <Page.Header>
                <Page.Header.Left>
                    <Page.Title>{id ? project.data.name : "Add New Project"} </Page.Title>
                </Page.Header.Left>

                <Page.Header.Right>
                    <Button
                        type="icon"
                        loading={project.busy}
                        onClick={close}
                        icon={<CloseOutlined />}
                    ></Button>
                </Page.Header.Right>
            </Page.Header>

            <Page.Body>
                <div className="content">
                    <Box>
                        {title && <Box.Header>{title}</Box.Header>}
                        <div className={`content ${step}`}>{children}</div>
                    </Box>
                </div>
            </Page.Body>

            <Page.Footer>
                <Page.Footer.Left>
                    <div className="button-group">
                        <Button type="primary" loading={project.busy} onClick={onSave}>
                            Save
                        </Button>

                        <Button onClick={close}>Close</Button>
                    </div>
                </Page.Footer.Left>
            </Page.Footer>
        </Page>
    );
});

export default EditPage;
