import React from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { NodeViewWrapper } from "@tiptap/react";
import { NodeSelection } from "prosemirror-state";
import cn from "classnames";

import CrossReference from "@app/state/model/report-document/cross-reference";

function isNodeSelected(editor, node, getPos) {
    const { from, to, node: selectedNode } = editor.state.selection;

    if (selectedNode) {
        if (node?.attrs?.id && node?.attrs?.id === selectedNode?.attrs?.id) {
            return true;
        }
        return node === selectedNode;
    }
    return from === getPos() && to === getPos() + node.nodeSize;
}

export const CitationNodeView = observer(({ node, editor, getPos }) => {
    const crossReferencesMap = editor.storage["cross-reference"].store.crossReferencesMap;
    const crossReference = crossReferencesMap[node.attrs.id];
    const history = useHistory();
    const isSelected = isNodeSelected(editor, node, getPos);
    const { "data-diff-node": change, "data-diff-id": diffId } = node.attrs || {};

    if (!crossReference) {
        return;
    }

    const goTo = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const key = crossReference.type === CrossReference.SECTION ? "section" : "node";

        history.push({
            pathname: history.location.pathname,
            search: `?${key}=${crossReference.id}`,
        });
    };

    const selectCrossReferenceNode = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const nodeSelection = NodeSelection.create(editor.view.state.doc, getPos());
        editor.view.dispatch(editor.view.state.tr.setSelection(nodeSelection));
    };

    function parseChanges(string) {
        if (!string) {
            return [];
        }

        let regex = /\[(ins|del)\](.*?)\[\/\1\]|([^[]+)/g;
        let matches = Array.from(string.matchAll(regex));
        let changes = matches.map((match) => {
            if (match[1]) {
                return {
                    change: match[1],
                    text: match[2],
                };
            } else {
                return {
                    change: "none",
                    text: match[3],
                };
            }
        });

        return changes;
    }

    const CaptionText = ({ crossReference }) => {
        if (!crossReference) {
            return `\u26A0 Missing Reference`;
        }

        const parts = parseChanges(crossReference.text);

        return (
            <span
                className={cn("cross-reference", {
                    inserted: change === "ins",
                    deleted: change === "del",
                })}
                onClick={crossReference ? goTo : null}
                onMouseDown={selectCrossReferenceNode}
                data-diff-id={diffId}
            >
                {parts.map((part, index) => {
                    if (part.change === "ins") {
                        return <ins key={index}>{part.text}</ins>;
                    } else if (part.change === "del") {
                        return <del key={index}>{part.text}</del>;
                    } else {
                        return <span key={index}>{part.text}</span>;
                    }
                })}
            </span>
        );
    };

    return (
        <NodeViewWrapper
            className={cn("cross-reference-node", {
                selected: isSelected,
            })}
        >
            <CaptionText crossReference={crossReference} />
        </NodeViewWrapper>
    );
});

export default CitationNodeView;
