import { action, computed } from "mobx";
import { v4 as uuid } from "uuid";
import BaseStore from "../base";
import http from "@app/lib/http";
import { ws } from "@app/lib/socket";
import Search from "@app/state/model/search";
import Filter from "@app/components/filter/state";

// report wrapper state
import report from "../report";
import { events } from "@app/lib/store";

const SEARCH_ID = uuid();
const STATS_ID = uuid();

/**
 * State management controlling the list of searches in
 * the report sections
 */
export class SearchStore extends BaseStore {
    filter = null;

    /**
     * Observable store data
     */
    observable() {
        return {
            list: [],
            stats: null,
            loading: false,
        };
    }

    /**
     * Return the project id if the currently loaded project
     */
    @computed get project() {
        return report.id;
    }

    constructor() {
        super();

        this.resetFilters();

        events.on("project.unload", () => {
            this.resetFilters();
            this.reset();
        });

        // listen on ws events to update the save progress
        ws.on("search.fetch", (data) => {
            this.list.map((search) => {
                if (search._id === data.id) {
                    search.status = "saving";
                    search.progress = data.progress;

                    if (data.progress === 100) {
                        search.status = "saved";
                    }
                }
            });
        });
    }

    /**
     * Load a list with searches from the backend
     */
    @action
    async load() {
        if (!this.project) {
            return;
        }

        await this.loadSearches();
        await this.loadStats();
    }

    @action
    resetFilters() {
        this.filter = new Filter({});
        this.filter.on("find", () => {
            this.loadSearches();
        });
    }

    /**
     * Load a list with searches from the backend
     */
    @action
    async loadSearches() {
        this.loading = true;

        let filter = this.filter.value();
        let { data } = await http
            .get(`/project/${this.project}/searches`, filter)
            .stagger(SEARCH_ID);

        this.list = data.list.map((entry) => {
            return new Search(entry);
        });

        // update the query stats
        this.filter.stats(data.stats);
        this.loading = false;
    }

    /**
     * Load the data gathering stats
     */
    @action
    async loadStats() {
        this.loading = true;
        let { data } = await http.get(`/project/${this.project}/search/stats`).stagger(STATS_ID);

        this.stats = data;
        this.loading = false;
    }

    /**
     * Remove a search from the list
     */
    @action
    async remove(id) {
        this.loading = true;
        await http.delete(`/project/${this.project}/search/${id}`);

        this.loading = false;
        return this.load();
    }
}

export default new SearchStore();
