import React, { useRef, useEffect, useState, useImperativeHandle } from "react";
import { List, Input } from "antd";

import { CitationListItem } from "@app/components/citation";

const InsertModalContent = ({
    onSetSearch,
    search,
    onClickItem,
    selected,
    filteredReferences,
    onKeyDown,
}) => {
    const inputRef = useRef(null);
    useEffect(() => {
        inputRef.current.focus();
    }, []);

    return (
        <div className="modal-content">
            <div className="search">
                <Input.Search
                    placeholder="Filter references..."
                    onChange={onSetSearch}
                    value={search}
                    allowClear
                    ref={inputRef}
                    tabIndex={0}
                />
            </div>
            <List
                onKeyDown={onKeyDown}
                className="citation-suggestion-list"
                itemLayout={"horizontal"}
                dataSource={filteredReferences}
                renderItem={(item) => (
                    <CitationListItem
                        key={item._id}
                        citation={item}
                        onClick={() => onClickItem(item._id)}
                        isSelected={selected.includes(item._id)}
                    />
                )}
            />
        </div>
    );
};

export default InsertModalContent;
