import { action, computed } from "mobx";
import BaseStore from "@app/state/store/base";

import http from "@app/lib/http";

import Comment from "@app/state/model/comment";
import { CommentStatus, ReportDocumentSection } from "@app/constants";
import { events } from "@app/lib/store";

import report from "../report";

import nodePosition from "./node-position";
import documentStore from "./report-documents";

const splitTextWholeWord = (text, length = 300) => {
    if (!text || typeof text !== "string") {
        console.error("Expected a string as the selected text");
        return "";
    }

    if (text?.length <= length) {
        return text;
    }

    return text
        .slice(0, length)
        .split(" ")
        .reduce(
            (lines, word) => {
                const lastLine = lines[lines.length - 1];
                const potentialLine = lastLine ? `${lastLine} ${word}` : word;

                if (potentialLine.length > length) {
                    lines.push(word);
                } else {
                    lines[lines.length - 1] = potentialLine;
                }

                return lines;
            },
            [""],
        )
        .join(" ");
};

const blankModalState = {
    isOpen: false,
    isSectionComment: false,
    from: undefined,
    to: undefined,
    selectedText: undefined,
};

export class CommentStore extends BaseStore {
    reportDocumentId = null;
    sectionId = null;

    observable() {
        return {
            comments: [],
            filteredComments: [],
            commentPositions: {},
            focusedComment: null,
            commentsTab: null,
            lastClickTime: 0,
            isError: false,
            foundCommentIdsBySection: {},
            isModalOpen: false,
            isSectionComment: false,
            modalState: {
                ...blankModalState,
            },
        };
    }

    /**
     * Return the project id if the currently loaded project
     */
    @computed get project() {
        return report.id;
    }

    @computed get apiUrl() {
        return `/project/${this.project}/report-documents/${this.reportDocumentId}/comments`;
    }

    @action
    filterComments() {
        let comments = [];

        if (this.commentsTab === "open") {
            comments = this.comments
                .slice()
                .filter((comment) => comment.status === CommentStatus.PENDING);
        } else if (this.commentsTab === "resolved") {
            comments = this.comments
                .slice()
                .filter((comment) => comment.status === CommentStatus.RESOLVED);
        } else {
            comments = this.comments.slice();
        }

        this.filteredComments = comments.filter((comment) => !comment.isSectionComment);
    }

    @action
    onCommentsTabSwitch(tab) {
        this.commentsTab = tab;
        this.filterComments();
    }

    @action
    focus(commentId, markClick = false) {
        if (!markClick && this.focusedComment === commentId) {
            return;
        }

        // this.lastClickTime = new Date().getTime();
        this.focusedComment = commentId;
    }

    @action
    resetFocus() {
        this.focusedComment = null;
    }

    @action
    sortComments() {
        const order = {};
        const sections = documentStore.reportDocumentSections ?? [];
        sections.map((section, i) => {
            order[section._id] = i;
        });

        this.comments = this.comments.slice().sort((a, b) => {
            const sectionA = order[a.section] ?? 0;
            const sectionB = order[b.section] ?? 0;

            if (sectionA !== sectionB) {
                return sectionA - sectionB;
            }

            const posA = Number(a.from) || 0;
            const posB = Number(b.from) || 0;

            return posA - posB;
        });

        this.filterComments();
    }

    @action
    async load(reportDocumentId, section) {
        this.reportDocumentId = reportDocumentId;
        this.sectionId = section;

        const { data } = await http.get(this.apiUrl);

        this.comments = data.map((entry) => new Comment(entry));
        this.commentsTab = "open";
        this.sortComments();
    }

    @action
    async reset() {
        this.reportDocumentId = null;
        this.sectionId = null;
        this.comments = [];
        this.filteredComments = [];
        this.focusedComment = null;
        this.commentsTab = null;
    }

    @action
    async saveComment({ _id, text, section, from, to, selectedText, isSectionComment, isParsed }) {
        let comment;

        if (_id) {
            const { data } = await http.put(`${this.apiUrl}/${_id}`, {
                text,
                section: section || this.sectionId,
                from,
                to,
            });
            this.comments = this.comments.map((comment) => {
                if (comment._id === _id) {
                    return {
                        ...comment,
                        text: data.text,
                    };
                }
                return comment;
            });
            comment = new Comment(data);
        } else {
            const { data } = await http.post(this.apiUrl, {
                text,
                section: section || this.sectionId,
                from,
                to,
                selectedText: !isSectionComment ? splitTextWholeWord(selectedText, 300) : undefined,
                isSectionComment,
                isParsed,
            });

            comment = new Comment(data);

            if (!this.comments.find((el) => el._id === comment._id)) {
                this.comments.unshift(comment);
            }

            this.sortComments();
        }

        this.focus(comment._id);
        return comment;
    }

    @action
    addCommentToStore(data) {
        const comment = new Comment(data);

        // fix for duplicate comments on websocket event (for the author)
        if (!this.comments.find((el) => el._id === comment._id)) {
            this.comments.unshift(comment);
        }
    }

    /**
     * Remove a comment
     */
    @action
    async removeComment(comment) {
        await http.delete(`${this.apiUrl}/${comment._id}`);

        this.comments = this.comments.filter((el) => el._id !== comment._id);

        if (this.focusedComment === comment._id) {
            this.focusedComment = null;
        }

        this.sortComments();
        events.emit("comments.remove", comment);
    }

    /**
     * Resolve a comment
     */
    @action
    async resolveComment(comment) {
        const { data } = await http.put(`${this.apiUrl}/${comment._id}/resolve`);

        let found = this.comments.find((el) => el._id === comment._id);

        if (found) {
            found.status = data.status;
            found.resolvedBy = data.resolvedBy;
            found.resolvedOn = data.resolvedOn;
            found.replies = data.replies;
        }

        this.lastUpdate = found;
    }

    /**
     * Un resolve Comment
     */
    @action
    async unResolveComment(comment) {
        const { data } = await http.put(`${this.apiUrl}/${comment._id}/unresolve`);

        let found = this.comments.find((el) => el._id === comment._id);

        if (found) {
            found.status = data.status;
            found.resolvedBy = undefined;
            found.resolvedOn = undefined;
            found.replies = data.replies;
            if (!found.expanded) {
                found.expanded = true;
            }
        }

        this.lastUpdate = found;
    }

    /**
     * Add a reply to an existing comment
     */
    @action
    async addReply(params) {
        const { data } = await http.post(`${this.apiUrl}/${params.comment._id}/reply`, {
            text: params.text,
        });

        const comment = this.comments.find((el) => el._id === params.comment._id);
        comment.replies = data.replies;
    }

    /**
     * Update an existing comment's reply
     */
    @action
    async updateReply(params) {
        const { data } = await http.put(
            `${this.apiUrl}/${params.comment._id}/reply/${params.replyId}`,
            {
                reply: params.reply,
                text: params.text,
            },
        );

        const comment = this.comments.find((el) => el._id === params.comment._id);

        comment.replies = data.replies;
    }
    /**
     * Remove an existing reply
     */
    @action
    async removeReply(params) {
        const { data } = await http.delete(
            `${this.apiUrl}/${params.comment._id}/reply/${params.replyId}`,
        );

        const comment = this.comments.find((el) => el._id === params.comment._id);
        comment.replies = data.replies;
    }

    @action
    async updateParsedComments({ sectionId }) {
        const appliedSectionCommentIds = nodePosition.nodes
            .filter(
                (node) =>
                    node.type === ReportDocumentSection.COMMENT.MARK_NAME &&
                    node.sectionId === sectionId,
            )
            .map((node) => node.id);

        const commentIds = this.comments
            .filter(
                (comment) => !comment.isParsed && appliedSectionCommentIds.includes(comment._id),
            )
            .map((comment) => comment._id);

        if (commentIds.length) {
            await http.put(`${this.apiUrl}/parsed`, { commentIds });

            this.comments.forEach((comment) => {
                if (commentIds.includes(comment._id)) {
                    comment.isParsed = true;
                }
            });
        }
    }

    @action
    setCommentPosition(commentId, position) {
        this.commentPositions[commentId] = position;
    }

    @computed get wholeSectionComments() {
        const sectionComments = this.comments.filter((comment) => comment.isSectionComment);

        const commentMap = {};

        sectionComments.forEach((comment) => {
            if (!commentMap[comment.section]) {
                commentMap[comment.section] = [];
            }

            commentMap[comment.section].push(comment);
        });

        return commentMap;
    }

    @action setCommentIdsForSection(sectionId, commentIds) {
        this.foundCommentIdsBySection[sectionId] = commentIds;

        const sectionIds = Object.keys(this.foundCommentIdsBySection);
        const foundCommentIds = Object.values(this.foundCommentIdsBySection).flat();

        this.comments.forEach((comment) => {
            if (
                foundCommentIds.includes(comment._id) ||
                (comment.isSectionComment && sectionIds.includes(comment.section))
            ) {
                comment.isFoundInDocument = true;
            } else {
                comment.isFoundInDocument = false;
            }
        });
    }

    @action openAddCommentModal({
        isSectionComment,
        selectedText,
        from,
        to,
        sectionId,
        onModalClose,
        modalPosition,
    }) {
        this.modalState = {
            isOpen: true,
            isSectionComment,
            selectedText,
            from,
            to,
            sectionId,
            onModalClose,
            modalPosition,
        };
    }

    @action closeAddCommentModal() {
        this.modalState = {
            ...blankModalState,
        };
    }
}

export default new CommentStore();
