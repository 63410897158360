import React from "react";
import { observer } from "mobx-react";
import Filter from "@app/components/filter";
import Sidebar from "@app/components/filter/sidebar";

import report from "@app/state/store/report";

const UserFilter = observer(({ filter }) => {
    const options = report.config?.contentTags;
    let facets = filter.state.stats?.facets?.tag || {};

    return (
        <Sidebar filter={filter}>
            <Filter.OptionGroup filter={filter} title="Tags" name="tag">
                {options.map((tag) => {
                    const count = facets[tag.label] ?? 0;

                    return (
                        <Filter.Option
                            label={tag.label}
                            value={tag.label}
                            count={count}
                            key={tag.label}
                        ></Filter.Option>
                    );
                })}
            </Filter.OptionGroup>
        </Sidebar>
    );
});

export default UserFilter;
