import { observable } from "mobx";
import Model from "@app/state/model/base";
import format from "@app/lib/format";

export default class Notification extends Model {
    @observable _id = undefined;
    @observable from = {
        _id: undefined,
        fullName: undefined,
        avatar: undefined,
    };
    @observable to = undefined;
    @observable read = undefined;
    @observable readAt = undefined;
    @observable type = undefined;
    @observable created = undefined;
    @observable context = undefined;
    @observable deleted = undefined;

    /**
     * Overwrite the set method
     */
    set(data) {
        super.set(data);
        this.from.initials = format.initials(this.from.fullName);
    }
}
