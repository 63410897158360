import React from "react";
import { observer } from "mobx-react";
import { Alert } from "antd";

import { Page } from "@app/components/page";

const DeletedProject = observer(() => {
    return (
        <Page>
            <Page.Body>
                <div className="container">
                    <Alert
                        title="Project Deleted"
                        message="This project was deleted."
                        type="error"
                    />
                </div>
            </Page.Body>
        </Page>
    );
});

export default DeletedProject;
