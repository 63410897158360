import React from "react";
import { observer } from "mobx-react";

import versionStore from "@app/state/store/report-document/version";

import { VersionAliasView, CompareVersionsView, HistoryView } from "./components";

const DiffPlugin = observer(({ editor, sectionId }) => {
    const { viewMode, versionId, lhsId, rhsId } = versionStore || {};

    if (!editor || !sectionId) {
        return null;
    }

    if (viewMode === "version" && versionId) {
        return <VersionAliasView editor={editor} sectionId={sectionId} versionId={versionId} />;
    }

    if (viewMode === "diff" && versionId) {
        return <HistoryView editor={editor} sectionId={sectionId} versionId={versionId} />;
    }

    if (viewMode === "compare" && lhsId && rhsId) {
        return (
            <CompareVersionsView
                editor={editor}
                sectionId={sectionId}
                versionA={lhsId}
                versionB={rhsId}
            />
        );
    }

    return null;
});

export default DiffPlugin;
