import React, { useState } from "react";
import { observer } from "mobx-react";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Select, List } from "antd";
import { Box } from "@app/components/box";

import { v4 as uuid } from "uuid";

import "./style/customize.scoped.scss";

const Filters = observer(({ data, selected, updatedItems, header }) => {
    const [selectedItems, setSelectedItems] = useState(selected);

    const handleChange = (items) => {
        setSelectedItems(items);
        updatedItems(items);
    };

    const remove = (item) => {
        let updatedList = selected.filter((name) => name !== item);
        updatedItems(updatedList);

        const found = selectedItems.indexOf(item);
        if (found !== -1) {
            const updated = selectedItems.filter((name) => name !== item);
            setSelectedItems(updated);
        }
    };

    return (
        <Box title={header}>
            <Select
                mode="multiple"
                autoClearSearchValue={false}
                className="select"
                placeholder="Click here to select more"
                showArrow
                value={selectedItems}
                onChange={handleChange}
            >
                {data.map((item) => (
                    <Select.Option key={uuid()} value={item}>
                        {item}
                    </Select.Option>
                ))}
            </Select>
            <div>
                <List
                    size="small"
                    className="list"
                    bordered
                    dataSource={selected}
                    renderItem={(item) => (
                        <List.Item className="item" key={uuid()}>
                            <em>{item}</em>
                            <Button
                                className="remove"
                                type="icon"
                                onClick={() => remove(item)}
                                icon={<DeleteOutlined />}
                            ></Button>
                        </List.Item>
                    )}
                />
            </div>
        </Box>
    );
});

export default Filters;
