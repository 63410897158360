import { observer } from "mobx-react";

import { emptyContent, removeCommentReferences } from "../diff-utility";
import { useLoadVersionedContent, useDiff } from "../hooks";

export const HistoryView = observer(({ editor, sectionId, versionId }) => {
    const { hasLoaded, content } = useLoadVersionedContent({
        versionId,
        sectionId,
    });

    const lhs = removeCommentReferences(content?.lhs);
    const rhs = removeCommentReferences(content?.rhs);

    const left =
        lhs[sectionId] && Array.isArray(lhs[sectionId]?.content) ? lhs[sectionId] : emptyContent;
    const right =
        rhs[sectionId] && Array.isArray(rhs[sectionId]?.content) ? rhs[sectionId] : emptyContent;

    useDiff({ editor, left, right, sectionId, hasLoaded });
});

export default HistoryView;
