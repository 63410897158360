import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Button } from "antd";

import "./style.scss";

export default forwardRef((props, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    const selectItem = (index) => {
        const item = props.items[index];

        if (item) {
            props.command({ id: item });
        }
    };

    const upHandler = () => {
        setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
    };

    const downHandler = () => {
        setSelectedIndex((selectedIndex + 1) % props.items.length);
    };

    const enterHandler = () => {
        selectItem(selectedIndex);
    };

    useEffect(() => setSelectedIndex(0), [props.items]);

    useImperativeHandle(ref, () => ({
        onKeyDown: ({ event }) => {
            if (event.key === "ArrowUp") {
                upHandler();
                return true;
            }

            if (event.key === "ArrowDown") {
                downHandler();
                return true;
            }

            if (event.key === "Enter") {
                enterHandler();
                return true;
            }

            return false;
        },
    }));

    if (!props.items.length) {
        return null;
    }

    let columnCount = 6;

    if (props.items.length < 6) {
        columnCount = props.items.length;
    }

    return (
        <div
            className="symbol-container"
            style={{ gridTemplateColumns: `repeat(${columnCount || 1}, 1fr)` }}
        >
            {props.items.map((item, index) => (
                <Button
                    className={`item ${index === selectedIndex ? "is-selected" : ""}`}
                    key={index}
                    onClick={() => selectItem(index)}
                >
                    {item.value}
                </Button>
            ))}
        </div>
    );
});
