import React from "react";
import { CommentOutlined } from "@ant-design/icons";
import classNames from "classnames";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import "./style/preview.scoped.scss";

const CommentTab = ({ comment }) => {
    const location = useLocation();
    const history = useHistory();
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    const selectedComment = params?.comment;

    const currentPath = location.pathname;

    const onClick = () => {
        const commentId = params?.comment !== comment._id ? comment._id : "";

        history.push(`${currentPath}?${commentId ? `comment=${commentId}` : ""}`);
    };

    return (
        <div
            onClick={onClick}
            className={classNames({
                "comment-tab": true,
                selected: selectedComment === comment._id,
                pending: comment.status === "pending",
                resolved: comment.status === "resolved",
            })}
            style={{
                top: `${comment.areas[0].top}%`,
            }}
        >
            <CommentOutlined />
        </div>
    );
};

export default CommentTab;
