import React, { useCallback, useEffect } from "react";
import { observer, useLocalStore } from "mobx-react";
import Mask from "./mask";

import "./styles.scoped.scss";

export { default as LoadingPlugin } from "./plugin";

export const LoadingMask = observer(({ editor }) => {
    const state = useLocalStore(() => ({
        showMask: false,
    }));

    const handler = useCallback((loading) => {
        if (loading !== state.showMask) {
            state.showMask = loading;
        }
    });

    useEffect(() => {
        if (editor) {
            editor.on("loading", handler);
            return () => editor.off("loading", handler);
        }
    }, [editor]);

    if (!state.showMask) {
        return null;
    }

    return <Mask />;
});
