import React from "react";
import { observer } from "mobx-react";
import Filter from "@app/components/filter";
import Sidebar from "@app/components/filter/sidebar";
import { CitationType } from "@app/state/model/citation";

export const CitationFilter = observer(({ filter }) => {
    let facets = filter.state.stats?.facets || {};
    let fullText = facets.fullText || {};
    let type = facets.type || {};

    return (
        <Sidebar filter={filter}>
            <Filter.OptionGroup filter={filter} title="Type" name="type" groupKey="citation.type">
                <Filter.Option label="DUE" value={CitationType.DUE} count={type.slr ?? 0} />
                <Filter.Option label="SoTA" value={CitationType.SOTA} count={type.sota ?? 0} />
                <Filter.Option
                    label="Source Document"
                    value={CitationType.DOCUMENT}
                    count={type.document ?? 0}
                />
                <Filter.Option
                    label="Custom"
                    value={CitationType.CUSTOM}
                    count={type.custom ?? 0}
                />
                <Filter.Option
                    label="Previous DUE"
                    value={CitationType.PREVIOUS_DUE}
                    count={type.previousSlr ?? 0}
                />
                <Filter.Option
                    label="Previous SoTA"
                    value={CitationType.PREVIOUS_SOTA}
                    count={type.previousSota ?? 0}
                />
            </Filter.OptionGroup>

            <Filter.OptionGroup
                filter={filter}
                title="Full Text"
                name="fullText"
                radio
                groupKey="citation.fullText"
            >
                <Filter.Option label="Has Full Text" value={true} count={fullText.true ?? 0} />
                <Filter.Option
                    label="Missing Full Text"
                    value={false}
                    count={fullText.false ?? 0}
                />
            </Filter.OptionGroup>
        </Sidebar>
    );
});

export default CitationFilter;
