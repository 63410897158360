import React, { useEffect } from "react";
import { observer } from "mobx-react";
import moment from "moment";
import { Button, Form, Input, DatePicker } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Page } from "@app/components/page";
import { Box } from "@app/components/box";
import notify from "@app/components/notify";
import format from "@app/lib/format";
import Filters from "../filters";

import setup from "@app/state/store/projects/setup";
import reportState from "@app/state/store/report";
import tplcState from "@app/state/store/report/output/tplc";

import "../style/customize.scoped.scss";
import "../style/common.scoped.scss";

const TplcConfig = observer(() => {
    const [ready, setReady] = React.useState(false);
    const [form] = Form.useForm();
    const history = useHistory();

    const [allBrands, setAllBrands] = React.useState([]);
    const [allManufacturers, setAllManufacturers] = React.useState([]);

    const [selectedBrands, setSelectedBrands] = React.useState([]);
    const [selectedManufacturers, setSelectedManufacturers] = React.useState([]);

    useEffect(() => {
        (async () => {
            await tplcState.load();
            await setup.load(reportState?.project?._id);

            const { manufacturerNames, brandNames } = await tplcState.options();
            const config = setup.config?.risk?.tplc || {};
            const startDate = config.startDate || setup.config?.risk?.startDate;
            const endDate = config.endDate || setup.config?.risk?.endDate;
            const cutOff = config.cutOff >= 0 ? config.cutOff : 1;

            setSelectedBrands(config.deviceNames || []);
            setSelectedManufacturers(config.manufacturerNames || []);
            form.setFieldsValue({
                startDate: startDate ? moment(startDate) : undefined,
                endDate: endDate ? moment(endDate) : undefined,
                cutOff,
            });

            const blackList = [".", "", "*"];
            setAllBrands(brandNames.filter((item) => !blackList.includes(item)).sort());
            setAllManufacturers(
                manufacturerNames.filter((item) => !blackList.includes(item)).sort(),
            );

            setReady(true);
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!ready) {
        return;
    }

    /**
     * Close the current report
     */
    const close = () => {
        history.push("../tplc");
    };

    const generate = async () => {
        let payload = {
            deviceNames: selectedBrands,
            manufacturerNames: selectedManufacturers,
        };

        try {
            const fields = await form.validateFields();
            payload = {
                ...payload,
                startDate: format.toUTC(fields.startDate.startOf("day").toDate()),
                endDate: format.toUTC(fields.endDate.startOf("day").toDate()),
                cutOff: fields.cutOff ? Number(fields.cutOff) : 1,
            };

            await tplcState.generate(payload);
            await setup.loadConfig();
            close();
        } catch (ex) {
            notify.error("Please enter all required fields");
        }
    };

    const canGenerate = selectedManufacturers.length || selectedBrands.length;

    return (
        <Page className="tplc">
            <Page.Header title="TPLC Report Configuration" closable onClose={close}>
                <Page.Header.Right>
                    <Button
                        type="primary"
                        icon={<SyncOutlined />}
                        onClick={generate}
                        disabled={!canGenerate || tplcState.loading}
                    >
                        Generate Report
                    </Button>
                </Page.Header.Right>
            </Page.Header>

            <Page.Layout>
                <Page.Body maxWidth={1200}>
                    <Form layout="vertical" form={form}>
                        <div className="row content">
                            <Box title="Configuration" className="col">
                                <div className="row gap">
                                    <div className="col">
                                        <Form.Item
                                            label="From Date"
                                            name="startDate"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please set a starting date",
                                                },
                                            ]}
                                        >
                                            <DatePicker
                                                style={{ width: "100%" }}
                                                disabledDate={(date) =>
                                                    date.isAfter(form.getFieldValue("endDate"))
                                                }
                                            />
                                        </Form.Item>
                                    </div>

                                    <div className="col">
                                        <Form.Item
                                            label="To Date"
                                            name="endDate"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please set an ending date",
                                                },
                                            ]}
                                        >
                                            <DatePicker
                                                style={{ width: "100%" }}
                                                disabledDate={(date) =>
                                                    date.isBefore(form.getFieldValue("startDate"))
                                                }
                                            />
                                        </Form.Item>
                                    </div>

                                    <div className="col">
                                        <Form.Item
                                            label="Results cut-off"
                                            name="cutOff"
                                            rules={[
                                                {
                                                    validator: (_, value) => {
                                                        if (
                                                            !value ||
                                                            parseInt(value) <= 0 ||
                                                            value.toString().indexOf(".") !== -1
                                                        ) {
                                                            return Promise.reject(
                                                                new Error(
                                                                    "Please provide a value more than zero (decimals not allowed)",
                                                                ),
                                                            );
                                                        } else {
                                                            return Promise.resolve();
                                                        }
                                                    },
                                                },
                                            ]}
                                            validateTrigger="onBlur"
                                        >
                                            <Input type="number" min="1" />
                                        </Form.Item>
                                    </div>
                                </div>
                            </Box>
                        </div>
                    </Form>
                    <div className="row content">
                        <div className="col split">
                            <Filters
                                header="Brands"
                                data={allBrands}
                                selected={selectedBrands.slice().sort()}
                                updatedItems={setSelectedBrands}
                            />
                        </div>

                        <div className="col split">
                            <Filters
                                header="Manufacturers"
                                data={allManufacturers}
                                selected={selectedManufacturers.slice().sort()}
                                updatedItems={setSelectedManufacturers}
                            />
                        </div>
                    </div>
                </Page.Body>
            </Page.Layout>
        </Page>
    );
});

export default TplcConfig;
