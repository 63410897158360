import { Button, Tag, Tooltip } from "antd";
import classes from "classnames";
import React from "react";
import {
    EditOutlined,
    DeleteOutlined,
    SettingOutlined,
    CopyOutlined,
    StarFilled,
    StarOutlined,
} from "@ant-design/icons";
import Card from "@app/components/card/card";
import session from "@app/state/store/session";
import format from "@app/lib/format";
import { ProjectStatus } from "@app/constants";

import "./style/card.scoped.scss";

export default function ProjectCard({
    children,
    project,
    readonly,
    onEdit,
    onRemove,
    onClone,
    className,
    onConfigure,
    onFavorite,
    favoriteProjects,
    ...rest
}) {
    let canConfigure = false;
    let canEdit = false;

    if (session.can("project.manage")) {
        canConfigure = true;
        canEdit = !readonly;
    }

    const isFavorite = favoriteProjects.includes(project._id);

    /**
     * Handle edit button click
     * @param {MouseEvent} event
     */
    const edit = (event) => {
        event.preventDefault();
        if (onEdit) {
            onEdit(project, event);
        }
    };

    /**
     * Handle delete button click
     * @param {MouseEvent} event
     */
    const remove = (event) => {
        event.preventDefault();
        if (onRemove) {
            onRemove(project, event);
        }
    };

    // Handle cloning of the project
    const clone = (event) => {
        event.preventDefault();
        if (onClone) {
            onClone(project, event);
        }
    };

    const favorite = (event) => {
        event.preventDefault();
        if (onFavorite) {
            onFavorite(project);
        }
    };

    /**
     * Render the edit/delete buttons
     */
    const Controls = () => {
        // if (project.userRoles.includes(Role.LEAD_AUTHOR)) {
        //     canConfigure = true;
        // }

        if (!canConfigure && !canEdit) {
            return null;
        }

        return (
            <div className="actions">
                {canConfigure && (
                    <Button onClick={configure} type="text" icon={<SettingOutlined />}></Button>
                )}
                {canEdit && <Button onClick={edit} type="text" icon={<EditOutlined />}></Button>}
                {canEdit && <Button onClick={clone} type="text" icon={<CopyOutlined />}></Button>}
                {canEdit && (
                    <Button onClick={remove} type="text" danger icon={<DeleteOutlined />}></Button>
                )}
            </div>
        );
    };

    const mergedClasses = classes("project-card", className);

    /**
     * Render the status tag
     */
    const Status = () => {
        if (project.status === ProjectStatus.DRAFT) {
            return <Tag>Draft</Tag>;
        } else if (project.status === ProjectStatus.ACTIVE) {
            return <Tag color="blue">Active</Tag>;
        } else if (project.status === ProjectStatus.COMPLETE) {
            return <Tag color="green">Complete</Tag>;
        } else if (project.status === ProjectStatus.HOLD) {
            return <Tag color="#faad14">On Hold</Tag>;
        }

        return null;
    };

    const configure = (event) => {
        event.preventDefault();

        if (onConfigure) {
            onConfigure(project);
        }
    };

    const Favorite = () => {
        return (
            <span>
                <Tooltip
                    placement="top"
                    title={isFavorite ? "Remove from favorites" : "Add to favorites"}
                >
                    <Button
                        onClick={favorite}
                        type="text"
                        icon={isFavorite ? <StarFilled className="isFavorite" /> : <StarOutlined />}
                    ></Button>
                </Tooltip>
            </span>
        );
    };

    return (
        <Card className={mergedClasses} {...rest}>
            <Controls />

            <div className="name">{project.name}</div>
            <div className="client">{project.client.name}</div>
            <div className="description">{project.description}</div>
            <div className="status">
                <Status />
                <Favorite />
                <span className="date">{format.date(project.endDate)}</span>
            </div>
        </Card>
    );
}
