import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import {
    EditOutlined,
    DeleteOutlined,
    InfoCircleOutlined,
    EllipsisOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import cn from "classnames";

import confirm from "@app/components/confirm";

import "./style/bookmarks.scoped.scss";

const Groups = observer(({ groups, selected, onOpen, setEdit, remove, setDetails, readonly }) => {
    if (!groups) {
        return null;
    }

    return groups.map((group, i) => {
        return (
            <div className={cn("group", { first: i === 0 })} key={group._id}>
                <div className="name">{group.name}</div>
                <div className="links">
                    {group.bookmarks.map((bookmark) => {
                        return (
                            <Bookmark
                                bookmark={bookmark}
                                key={bookmark._id}
                                selected={selected === bookmark._id}
                                readonly={readonly}
                                onOpen={() => {
                                    onOpen(bookmark);
                                }}
                                onEdit={() => {
                                    setEdit(bookmark);
                                }}
                                onDelete={() => {
                                    remove(bookmark);
                                }}
                                onDetails={() => {
                                    setDetails(bookmark);
                                }}
                            />
                        );
                    })}
                </div>
            </div>
        );
    });
});

const Bookmark = observer(
    ({ bookmark, selected, readonly, onOpen, onEdit, onDelete, onDetails }) => {
        const open = (e) => {
            onOpen && onOpen();
        };

        const remove = async (e) => {
            e.domEvent.preventDefault();
            e.domEvent.stopPropagation();

            let proceed = await confirm("Are you sure you want to remove this bookmark?");
            if (proceed) {
                onDelete && onDelete();
            }
        };

        const edit = (e) => {
            e.domEvent.preventDefault();
            e.domEvent.stopPropagation();

            onEdit && onEdit();
        };

        const details = (e) => {
            e.domEvent.preventDefault();
            e.domEvent.stopPropagation();

            onDetails && onDetails();
        };

        const dropDownMenu = (
            <Menu className="menu">
                <Menu.Item
                    key="details"
                    children={"Details"}
                    icon={<InfoCircleOutlined />}
                    onClick={details}
                />
                <Menu.Item key="edit" children={"Edit"} icon={<EditOutlined />} onClick={edit} />
                <Menu.Divider key="divider-1" className={"divider"} />
                <Menu.Item
                    key="Delete"
                    children={"Delete"}
                    icon={<DeleteOutlined />}
                    onClick={remove}
                />
            </Menu>
        );

        return (
            <div className={classNames("bookmark", { selected })}>
                <div className="name" onClick={open}>
                    {bookmark.name}
                </div>
                {!readonly && (
                    <Dropdown overlay={dropDownMenu} placement="bottomRight" trigger="click">
                        <Button type="icon" icon={<EllipsisOutlined />} />
                    </Dropdown>
                )}
            </div>
        );
    },
);

export default Groups;
