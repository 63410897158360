import moment from "moment";
import React from "react";
import { observer } from "mobx-react";
import { DatePicker } from "antd";
import Group from "./group";

import "./style/date-range.scoped.scss";

const DateRange = observer(
    ({
        filter,
        filterKey,
        startDateName,
        endDateName,
        title,
        disabled,
        picker = "date",
        collapsed,
        groupKey,
    }) => {
        let filterContent = filter.state.filter;
        if (filterKey) {
            filterContent = filterContent[filterKey] || {};
        }

        const startDate = filterContent[startDateName];
        const endDate = filterContent[endDateName];

        /**
         * Update the date range's starting value
         */
        const updateStartDate = (updatedStartDate, dateString) => {
            filter.filter(startDateName, updatedStartDate ? dateString : null, { filterKey });

            // updates the end date value if it is now before the start date
            if (updatedStartDate && endDate && updatedStartDate.isAfter(endDate)) {
                filter.filter(endDateName, dateString, { filterKey });
            }
        };

        /**
         * Update the date range's ending value
         */
        const updateEndDate = (updatedEndDate, dateString) => {
            filter.filter(endDateName, updatedEndDate ? dateString : null, { filterKey });

            // updates the start date value if it is now after the end date
            if (updatedEndDate && startDate && updatedEndDate.isBefore(startDate)) {
                filter.filter(startDateName, dateString, { filterKey });
            }
        };

        return (
            <Group
                className="date-range"
                title={title}
                hasActiveFilters={Boolean(startDate) || Boolean(endDate)}
                collapsed={collapsed}
                groupKey={groupKey ?? name}
            >
                <div className="options">
                    <div className="start-date">
                        <div className="title">From</div>
                        <DatePicker
                            value={startDate ? moment(startDate) : undefined}
                            onChange={updateStartDate}
                            disabled={disabled}
                            picker={picker}
                        />
                    </div>
                    <div className="end-date">
                        <div className="title">To</div>
                        <DatePicker
                            value={endDate ? moment(endDate) : undefined}
                            onChange={updateEndDate}
                            disabled={disabled}
                            picker={picker}
                        />
                    </div>
                </div>
            </Group>
        );
    },
);

export default DateRange;
