import React from "react";
import { observer } from "mobx-react";
import { Tooltip } from "antd";
import { WarningOutlined } from "@ant-design/icons";

import Group from "../group";
import "./style/criteria.scoped.scss";

const Criteria = observer(({ score, label }) => {
    const invalid = !score;

    return (
        <Group.Item order={false}>
            <div className="criteria">
                {invalid ? (
                    <div className="warn">
                        <Tooltip
                            placement="topLeft"
                            title="Invalid score values. Please edit the criteria to correct them."
                        >
                            <WarningOutlined />
                        </Tooltip>
                    </div>
                ) : (
                    <div className="score">
                        {score?.min} - {score?.max}
                    </div>
                )}

                <div className="label">{label}</div>
            </div>
        </Group.Item>
    );
});

export default Criteria;
