import { useEffect } from "react";
import { ws } from "@app/lib/socket";

import commentStore from "@app/state/store/report-document/comment";

export const useHandleCommentAdded = ({ editor, sectionId }) => {
    useEffect(() => {
        if (editor) {
            const handleCommentAdded = (data) => {
                if (data.section === sectionId) {
                    commentStore.addCommentToStore(data);
                }
            };

            ws.on("reportDocument.section.comment.created", handleCommentAdded);

            return () => {
                ws.removeListener("reportDocument.section.comment.created", handleCommentAdded);
            };
        }
    }, [editor, sectionId]);
};

export default useHandleCommentAdded;
