export const getTextFromOffsets = (
    textLayerDiv,
    startDivIdx,
    startOffset,
    endDivIdx,
    endOffset,
) => {
    const nodes = [].slice.call(textLayerDiv.children);
    if (startDivIdx < endDivIdx) {
        let text = "";

        nodes.slice(startDivIdx, startDivIdx + 1).forEach((node) => {
            text += node.textContent.substring(startOffset).trim();
        });

        nodes.slice(startDivIdx + 1, endDivIdx).forEach((node) => {
            if (node.nodeName === "SPAN") {
                text += node.textContent;
            } else if (node.nodeName === "BR") {
                text += " ";
            }

            return node.textContent.trim();
        });

        nodes.slice(endDivIdx, endDivIdx + 1).forEach((endDiv) => {
            text += endDiv.textContent.substring(0, endOffset || endDiv.textContent.length);
        });

        return text;
    } else {
        const div = nodes[startDivIdx];
        return div.textContent.substring(startOffset, endOffset || div.textContent.length).trim();
    }
};
