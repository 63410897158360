import React, { useState } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import BaseModal from "@app/components/modal";
import { Button } from "antd";
import { YoutubeOutlined, LeftCircleOutlined } from "@ant-design/icons";

import "./style/modal.scoped.scss";

const Modal = observer(({ opened, onClose }) => {
    const [video, setVideo] = useState(null);

    const videos = [
        { title: "How to Perform Search & Import on CAPTIS Platform", id: "mOMibm_aYks" },
        { title: "How to Add & Configure Project on CAPTIS Platform", id: "zx6UFgSeJ2E" },
        {
            title: "How to Add a Client and Client Configuration on CAPTIS Platform",
            id: "h-rnAtECEYo",
        },
        { title: "How to Perform L1 & L2 Review on CAPTIS Platform", id: "uGbo7TGXqSU" },
        {
            title:
                "How to Upload, Review, Categorize and Bookmark Source Document on CAPTIS Platform",
            id: "5bT3km-TBX0",
        },
        { title: "Reports Matchup on CAPTIS Platform", id: "gaQrmAuR4c8" },
        { title: "Risk Analysis Section on CAPTIS Platform", id: "ITRIN0ivuZU" },
    ];

    /**
     * Show a video
     */
    const show = (id) => {
        setVideo(id);
    };

    /**
     * Close a video
     */
    const close = () => {
        setVideo(null);
    };

    /**
     * Handle the dialog close event
     */
    const onCancel = () => {
        close();
        onClose();
    };

    /**
     * Render the header depending if a video is opened
     */
    const Header = () => {
        if (!video) {
            return "Help Section";
        }

        return (
            <div className="back">
                <Button icon={<LeftCircleOutlined />} onClick={close}>
                    Back
                </Button>
            </div>
        );
    };

    return (
        <>
            {opened && (
                <BaseModal
                    title={<Header />}
                    visible={true}
                    onCancel={onCancel}
                    footer={null}
                    width={1000}
                    height="50vh"
                    maskClosable={true}
                    bodyStyle={{
                        background: "#f7f8f8",
                        position: "relative",
                        overflowX: "hidden",
                        overflowY: "auto",
                    }}
                >
                    <div className="videos">
                        {videos.map((video) => {
                            return (
                                <div
                                    className="video"
                                    onClick={() => show(video.id)}
                                    key={video.id}
                                >
                                    <div className="icon">
                                        <YoutubeOutlined />
                                    </div>
                                    <div className="title">{video.title}</div>
                                </div>
                            );
                        })}
                    </div>
                    <div
                        className={classNames("preview", {
                            opened: !!video,
                        })}
                    >
                        {video && (
                            <iframe
                                title="youtube"
                                allowFullScreen={true}
                                frameBorder="0"
                                src={`https://www.youtube.com/embed/${video}`}
                                width="100%"
                                height="100%"
                            ></iframe>
                        )}
                    </div>
                </BaseModal>
            )}
        </>
    );
});

export default Modal;
