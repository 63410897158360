import React from "react";
import cn from "classnames";
import { List, Checkbox } from "antd";

import "./style/list-item.scoped.scss";

export const CitationListItem = ({ citation, onClick, className, isSelected }) => {
    const previousLabel = citation.type?.includes("previous") ? "<b>Prev. Ref. </b>" : "";

    const onChange = () => {
        onClick();
    };

    return (
        <List.Item
            key={citation._id}
            className={cn("citation-list-item", {
                // [className]: true,
            })}
            children={
                <List.Item.Meta
                    key={citation._id}
                    avatar={<Checkbox checked={isSelected} onChange={onChange} />}
                    description={
                        <div className="citation-list-item-description" onClick={onClick}>
                            {previousLabel} {citation?.highlightedTitle}
                        </div>
                    }
                />
            }
        />
    );
};
