import React from "react";
import { observable } from "mobx";
import { getFlatDataFromTree, getTreeFromFlatData } from "react-sortable-tree";

import { trimNested } from "@app/util";

export class TOCState {
    @observable treeData = [];
    @observable currentPos = null;
    @observable content = null;
    @observable validateContent = () => true;

    setTreeData = (data) => {
        this.treeData = data;
    };

    setCurrentPos = (value) => {
        this.currentPos = value;
    };

    setContent = (data) => {
        this.content = trimNested(data);
    };

    setValidateContent = (fn) => {
        this.validateContent = fn;
    };

    trimNodes = (list) => {
        return list.filter((node) => {
            if (node.children) {
                node.children = this.trimNodes(node.children);
            }

            return !!node.title.trim();
        });
    };

    convertTreeToFlatData = (treeData, { reportDocumentId, project, client }) => {
        return getFlatDataFromTree({
            treeData,
            getNodeKey: ({ node }) => node.pos,
            ignoreCollapsed: false,
        }).reduce((result, { node, path }) => {
            result.push({
                _id: node?._id,
                title: node.title,
                pos: node.pos,
                toc: node.toc,
                displayPos: node.displayPos,
                // The last entry in the path is this node's key
                // The second to last entry (accessed here) is the parent node's key
                parent: path.length > 1 ? path[path.length - 2] : null,
                reportDocumentId,
                project,
                client,
            });

            return result;
        }, []);
    };

    validateTree = (treeData) => {
        const validateNode = (node, list) => {
            let valid = true;
            node.invalid = false;

            // validate the children
            if (node.children?.length) {
                for (const child of node.children) {
                    valid = valid && validateNode(child, node.children);
                }

                // do not allow nodes with empty titles and child nodes
                if (!node.title?.trim()) {
                    node.invalid = true;
                    valid = false;
                }
            }

            // check if all the nodes after this one are empty
            if (valid && !node.title?.trim()) {
                const idx = list.indexOf(node);
                let empty = true;
                for (let i = idx; i < list.length; i++) {
                    const item = list[i];
                    empty = empty && !item.title?.trim();
                }

                valid = empty;
                node.invalid = !valid;
            }

            return valid;
        };

        let valid = true;
        for (const node of treeData) {
            valid = valid && validateNode(node, treeData);
        }

        return valid;
    };

    convertTOCToTree = (toc) => {
        const sortedData = toc.slice().sort((a, b) => (a.pos < b.pos ? -1 : 1));
        return getTreeFromFlatData({
            flatData: sortedData,
            getKey: (node) => node._id,
            getParentKey: (node) => node.parent,
            rootKey: null,
        });
    };
}

const createState = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const state = React.useMemo(() => {
        return new TOCState();
    }, []);

    return state;
};

const Context = React.createContext(null);

const useContext = () => {
    const context = React.useContext(Context);
    if (context === null) {
        throw new Error("useContext must be used within a TableOfContent");
    }

    return { context };
};

export default Context;
export { Context, useContext, createState };
