import React, { useEffect, useRef } from "react";
import { observer, useLocalStore } from "mobx-react";
import { Form, Button, Select, Radio } from "antd";
import MultiStepModal from "@app/components/modal/multi-step-modal";
import ReuseArticleList from "./reuse-article-list";
import notify from "@app/components/notify";
import { ReasonType } from "@app/constants";
import { MarkdownViewer } from "@app/components/markdown-editor";

import projectMappingStore from "@app/state/store/projects/project-mapping";

import "./style/reuse-article.scoped.scss";

const ReuseArticleL2Modal = observer(
    ({ article, articles, stageConfig, configAdditionalData, onFinish, onCancel }) => {
        const [form1] = Form.useForm();
        const excludeReasons = stageConfig.excludeReasons;
        const configQuestions = stageConfig.questions;
        const requireAnswers = stageConfig.requireAnswers;

        const questionFieldPrefix = `question_`;
        const answerFieldPrefix = `answer_`;
        const dataFieldPrefix = `data_`;
        const newReason = "Add as a new reason";
        const newDataField = "Add as a new data field";

        const state = useLocalStore(() => ({
            currentStepIndex: 0,
            loading: false,
            missingQuestions: null,
            projectMapping: null,
            selectedArticle: null,
            questionFields: [],
            excludeReason: {},
            questions: { source: [], target: [] },
            additionalData: { source: [], target: [] },
        }));

        const resetState = () => {
            state.currentStepIndex = 0;
            state.loading = false;
            state.missingQuestions = null;
            state.selectedArticle = null;
            state.questionFields = [];
            state.excludeReason = {};
            state.questions = { source: [], target: [] };
            state.additionalData = { source: [], target: [] };
        };

        const formError = () => {
            notify.error("Please fill all required fields to continue");
        };

        const Footer = observer(({ actions }) => {
            return (
                <div>
                    {actions && actions}
                    <Button onClick={onCancel}>Cancel</Button>
                </div>
            );
        });

        const Step2 = observer(() => {
            const l2Review = state.selectedArticle.review.l2;
            const articleAdditionalData = state.selectedArticle.additionalData;
            let reasonList = [];

            if (l2Review.fail.reason) {
                // remove Duplicate Article reason
                reasonList = excludeReasons
                    .filter((r) => r.reason !== ReasonType.DUPLICATE)
                    .map((r) => {
                        return { value: r.reason, label: r.reason };
                    });

                let matchReason = excludeReasons.find((r) => r.reason === l2Review.fail.reason)
                    ?.reason;

                // try to match from project mapping
                if (!matchReason) {
                    if (
                        l2Review.fail.reason ===
                        state.projectMapping?.article?.excludeReason?.source
                    ) {
                        matchReason = state.projectMapping.article.excludeReason.target;
                    }
                    // if exact match fails, add newReason option
                    reasonList.push({ value: newReason, label: newReason });
                }

                if (reasonList.find((r) => r.value === matchReason)) {
                    form1.setFieldsValue({
                        excludeReason: matchReason,
                    });
                }
            }

            const QuesionsAndAnswers = observer(({ l2Review, configQuestions }) => {
                state.questionFields =
                    configQuestions.length > l2Review.questions.length
                        ? configQuestions
                        : l2Review.questions;

                const questionList = configQuestions.map((q) => {
                    return { label: <MarkdownViewer content={q.question} />, value: q._id };
                });

                const getSelectedQuestions = (currentFieldNumber) => {
                    const questionFieldNames = state.questionFields.reduce((result, q, i) => {
                        if (i === currentFieldNumber) {
                            return result;
                        } else {
                            result.push(questionFieldPrefix + i);
                            return result;
                        }
                    }, []);

                    return Object.values(form1.getFieldsValue(questionFieldNames));
                };

                const QuestionAndAnswer = observer(({ itemNumber }) => {
                    const item =
                        l2Review.questions.length > itemNumber
                            ? l2Review.questions[itemNumber]
                            : undefined;
                    const localState = useLocalStore(() => ({
                        matchQuestion: null,
                        answerList: [],
                        matchAnswer: null,
                    }));

                    const getMatchingQuestion = (matchValue) => {
                        let found;

                        if (configQuestions) {
                            found = configQuestions.find((i) => i.question === matchValue);
                            if (!found) {
                                // check projectMapping
                                const mappingMatch =
                                    state.projectMapping?.article?.questions?.target?.[itemNumber]
                                        ?.question;
                                if (mappingMatch) {
                                    // see if the match exists
                                    found = configQuestions.find(
                                        (i) => i.question === mappingMatch,
                                    );
                                }
                            }
                        }
                        return found;
                    };

                    const getMatchingAnswer = (matchValue) => {
                        let found;

                        if (localState.matchQuestion?.answers) {
                            found = localState.matchQuestion.answers.find(
                                (a) => a.answer === matchValue,
                            );

                            if (!found) {
                                // check projectMapping
                                const mappingMatch =
                                    state.projectMapping?.article?.questions?.target?.[itemNumber]
                                        ?.answer;
                                if (mappingMatch) {
                                    // see if the match exists
                                    found = localState.matchQuestion.answers.find(
                                        (a) => a.answer === mappingMatch.answer,
                                    );
                                }
                            }
                        }

                        return found;
                    };

                    useEffect(() => {
                        if (item) {
                            localState.matchQuestion = getMatchingQuestion(item.question);
                        }
                    }, [item]);

                    useEffect(() => {
                        if (localState.matchQuestion?.answers) {
                            localState.answerList = localState.matchQuestion.answers.map((a, i) => {
                                return {
                                    label: (
                                        <span>
                                            <span key={"grade_" + i}>
                                                <b>{a.grade}: </b>
                                            </span>
                                            <span key={"answer_" + i}>{a.answer}</span>
                                        </span>
                                    ),
                                    value: a._id,
                                };
                            });

                            if (item) {
                                localState.matchAnswer = getMatchingAnswer(item.answer?.answer);
                            }
                        } else {
                            localState.answerList = [];
                            localState.matchAnswer = null;
                        }
                    }, [localState.matchQuestion]);

                    const onSelectQuestion = (selection) => {
                        const reset = () => {
                            localState.matchQuestion = null;
                            form1.setFieldsValue({
                                [questionFieldPrefix + itemNumber]: null,
                                [answerFieldPrefix + itemNumber]: null,
                            });
                        };

                        if (selection) {
                            // check if the question is selected
                            const selectedQuestions = getSelectedQuestions(itemNumber);
                            const inSelected = selectedQuestions.find((q) => q === selection);
                            if (inSelected) {
                                reset();
                                notify.warn(
                                    "You have already choose this question.  Please select another or remove from the other field",
                                );
                            } else {
                                localState.matchQuestion = configQuestions.find(
                                    (i) => i._id === selection,
                                );
                            }
                        } else {
                            reset();
                        }
                    };

                    const Question = observer(
                        ({ matchQuestion, questionList, onSelectQuestion }) => {
                            useEffect(() => {
                                if (matchQuestion) {
                                    form1.setFieldsValue({
                                        [questionFieldPrefix + itemNumber]: matchQuestion._id,
                                    });
                                }
                            }, [matchQuestion]);

                            return (
                                <Form.Item label="" name={questionFieldPrefix + itemNumber}>
                                    <Select
                                        placeholder="Select a question"
                                        style={{ width: "100%" }}
                                        options={questionList}
                                        onChange={onSelectQuestion}
                                        allowClear
                                    />
                                </Form.Item>
                            );
                        },
                    );

                    const Answer = observer(({ matchAnswer, answerList }) => {
                        useEffect(() => {
                            if (matchAnswer) {
                                form1.setFieldsValue({
                                    [answerFieldPrefix + itemNumber]: matchAnswer._id,
                                });
                            } else {
                                form1.setFieldsValue({
                                    [answerFieldPrefix + itemNumber]: null,
                                });
                            }
                        }, [matchAnswer]);

                        return (
                            answerList?.length > 0 && (
                                <Form.Item
                                    label=""
                                    name={answerFieldPrefix + itemNumber}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select an answer",
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select an answer"
                                        style={{ width: "100%" }}
                                        options={answerList}
                                        allowClear
                                    />
                                </Form.Item>
                            )
                        );
                    });

                    return (
                        <div>
                            <div className="questionAndAnswer divider">
                                <div>
                                    <MarkdownViewer content={item?.question} />
                                </div>
                                {item?.answer && (
                                    <div>
                                        <b>{item.answer.grade}:</b> {item.answer.answer}
                                    </div>
                                )}
                            </div>
                            <div className="questionAndAnswerMatch divider">
                                <Question
                                    matchQuestion={localState.matchQuestion}
                                    questionList={questionList}
                                    onSelectQuestion={onSelectQuestion}
                                />
                                <Answer
                                    matchAnswer={localState.matchAnswer}
                                    answerList={localState.answerList}
                                />
                            </div>
                        </div>
                    );
                });

                return state.questionFields.map((item, i) => (
                    <QuestionAndAnswer key={i} itemNumber={i} />
                ));
            });

            const DataExtractionFields = observer(
                ({ articleAdditionalData = [], configAdditionalData = [] }) => {
                    const dataFieldList = configAdditionalData.map((d) => {
                        return { label: d.title, value: d._id };
                    });

                    const DataExtractionField = observer(({ item, itemNumber }) => {
                        const localState = useLocalStore(() => ({
                            matchDataField: null,
                        }));

                        const getMatchingDataField = (matchValue) => {
                            let found;

                            if (configAdditionalData) {
                                found = configAdditionalData.find((i) => i.title === matchValue);

                                if (!found) {
                                    // check projectMapping
                                    const mappingMatch =
                                        state.projectMapping?.article?.additionalData?.target?.[
                                            itemNumber
                                        ]?.title;
                                    if (mappingMatch) {
                                        // see if the match exists
                                        found = configAdditionalData.find(
                                            (i) => i.title === mappingMatch,
                                        );
                                    }
                                }
                            }

                            return found;
                        };
                        const setMatchDataField = (matchingItem) => {
                            if (matchingItem && matchingItem.type === item.type) {
                                if (matchingItem.type === "text") {
                                    localState.matchDataField = matchingItem;
                                    return true;
                                } else if (matchingItem.type === "select") {
                                    // check if value is within one of the option
                                    const optionsValues = matchingItem.options.map((o) => o.value);
                                    if (optionsValues.includes(item.value)) {
                                        localState.matchDataField = matchingItem;
                                        return true;
                                    }
                                }
                            }

                            return false;
                        };

                        useEffect(() => {
                            const found = getMatchingDataField(item.title);
                            setMatchDataField(found);
                        }, [item]);

                        const getSelectedDataFields = (currentFieldNumber) => {
                            const dataFieldNames = articleAdditionalData.reduce((result, d, i) => {
                                if (i === currentFieldNumber) {
                                    return result;
                                } else {
                                    result.push(dataFieldPrefix + i);
                                    return result;
                                }
                            }, []);

                            return Object.values(form1.getFieldsValue(dataFieldNames));
                        };

                        const onSelectDataField = (selection) => {
                            const reset = () => {
                                localState.matchDataField = null;
                                form1.setFieldsValue({
                                    [dataFieldPrefix + itemNumber]: null,
                                });
                            };

                            if (selection) {
                                if (selection !== newDataField) {
                                    // check if the data field is selected
                                    const selectedDataFields = getSelectedDataFields(itemNumber);
                                    const inSelected = selectedDataFields.find(
                                        (q) => selection !== newDataField && q === selection,
                                    );
                                    if (inSelected) {
                                        reset();
                                        notify.warn(
                                            "You have already choose the data extraction field.  Please select another or remove from the other field",
                                        );
                                    } else {
                                        const found = configAdditionalData.find(
                                            (i) => i._id === selection,
                                        );

                                        if (!setMatchDataField(found)) {
                                            reset();
                                            notify.warn(
                                                "The field type or options does not match.  Please select another.",
                                            );
                                        }
                                    }
                                }
                            } else {
                                reset();
                            }
                        };

                        const DataField = observer(({ item, matchDataField, dataFieldList }) => {
                            useEffect(() => {
                                if (matchDataField) {
                                    form1.setFieldsValue({
                                        [dataFieldPrefix + itemNumber]: matchDataField._id,
                                    });
                                }
                            }, [matchDataField]);

                            const getOptionList = () => {
                                const optionList = [...dataFieldList];
                                const titleMatch = optionList.find((i) => i.label === item.title);
                                if (!titleMatch) {
                                    optionList.push({ label: newDataField, value: newDataField });
                                }
                                return optionList;
                            };

                            return (
                                <Form.Item label="" name={dataFieldPrefix + itemNumber}>
                                    <Select
                                        placeholder="Select a data extraction field"
                                        style={{ width: "100%" }}
                                        options={getOptionList()}
                                        onChange={onSelectDataField}
                                        allowClear
                                    />
                                </Form.Item>
                            );
                        });

                        return (
                            <div key={itemNumber}>
                                <div className="dataField divider">
                                    <div>{item.title}</div>
                                </div>
                                <div className="dataFieldMatch divider">
                                    <DataField
                                        item={item}
                                        matchDataField={localState.matchDataField}
                                        dataFieldList={dataFieldList}
                                    />
                                </div>
                            </div>
                        );
                    });

                    return articleAdditionalData.map((item, i) => (
                        <DataExtractionField key={i} item={item} itemNumber={i} />
                    ));
                },
            );

            const MissingQuestionsConfirmation = observer(() => {
                const containerRef = useRef(null);

                useEffect(() => {
                    if (containerRef && containerRef.current) {
                        containerRef.current.scrollIntoView({
                            behavior: "smooth",
                        });
                    }
                }, [state.missingQuestions]);

                return (
                    state.missingQuestions && (
                        <div ref={containerRef} className="validateQuestions">
                            {requireAnswers && (
                                <div>
                                    You are required to answer all of the questions and answers.
                                </div>
                            )}
                            <div>
                                There is a mismatch in the questions and answers between the two
                                projects, you have
                                <b> {state.missingQuestions} questions unanswered</b> in your
                                project.
                            </div>
                            {!requireAnswers && (
                                <Form.Item
                                    label=""
                                    name={"completeReview"}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select an option",
                                        },
                                    ]}
                                >
                                    <Radio.Group>
                                        <Radio value={true}>
                                            <span>
                                                {l2Review.fail?.reason ? (
                                                    <span style={{ color: "red" }}>EXCLUDE </span>
                                                ) : (
                                                    <span style={{ color: "green" }}>INCLUDE </span>
                                                )}
                                                <span>
                                                    anyway, without answering the rest of the
                                                    questions
                                                </span>
                                            </span>
                                        </Radio>
                                        <Radio value={false}>
                                            <span>I want to answer the rest of the questions</span>
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                            )}
                        </div>
                    )
                );
            });

            return (
                <>
                    <Form layout="vertical" form={form1} onFinishFailed={formError}>
                        <MissingQuestionsConfirmation />
                        <div className="l2Step2">
                            <div>
                                <div className="heading">{state.selectedArticle.projectName}</div>
                                <div className="heading">Current Project</div>
                            </div>

                            {l2Review.fail.reason && (
                                <>
                                    <div className="headingRow">
                                        <div className="fieldHeading">Exclude reason</div>
                                        <div className="fieldHeading">Exclude reason match</div>
                                    </div>
                                    <div>
                                        <div className="excludeReason">{l2Review.fail.reason}</div>
                                        <div>
                                            <Form.Item
                                                label=""
                                                name={"excludeReason"}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please select an option",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    placeholder="Select a reason"
                                                    style={{ width: "100%" }}
                                                    options={reasonList}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </>
                            )}

                            {l2Review.questions?.length > 0 && (
                                <>
                                    <div className="headingRow">
                                        <div className="fieldHeading">Questions and Answers</div>
                                        <div className="fieldHeading">
                                            Questions and Answers match
                                        </div>
                                    </div>

                                    <QuesionsAndAnswers
                                        l2Review={l2Review}
                                        configQuestions={configQuestions}
                                    />
                                </>
                            )}

                            {articleAdditionalData?.length > 0 && (
                                <>
                                    <div className="headingRow">
                                        <div className="fieldHeading">Data Extraction Fields</div>
                                        <div className="fieldHeading">
                                            Data Extraction Fields match
                                        </div>
                                    </div>
                                    <DataExtractionFields
                                        articleAdditionalData={articleAdditionalData}
                                        configAdditionalData={configAdditionalData}
                                    />
                                </>
                            )}
                        </div>
                    </Form>
                </>
            );
        });

        const getProjectMapping = async (data) => {
            state.loading = true;
            state.projectMapping = await projectMappingStore.get(data);
            state.loading = false;
        };

        const onFormSubmit = async () => {
            const l2Review = state.selectedArticle.review.l2;

            // extract excludeReason
            const excludeReason = form1.getFieldValue("excludeReason");
            if (excludeReason) {
                state.excludeReason.source = l2Review.fail.reason;
                if (excludeReason === newReason) {
                    state.excludeReason.target = l2Review.fail.reason;
                } else {
                    state.excludeReason.target = excludeReason;
                }
            }

            // extract questions and answers
            state.questions.source = l2Review.questions;
            state.questions.target = state.questionFields.map((question, i) => {
                let targetQuestion;

                const questionName = questionFieldPrefix + i;
                const answerName = answerFieldPrefix + i;
                const fieldQuestion = form1.getFieldValue(questionName);

                if (fieldQuestion) {
                    targetQuestion = configQuestions.find((q) => q._id === fieldQuestion);
                    const fieldAnswer = form1.getFieldValue(answerName);
                    if (fieldAnswer && targetQuestion) {
                        targetQuestion.answer = targetQuestion.answers.find(
                            (a) => a._id === fieldAnswer,
                        );
                    }
                }

                return targetQuestion;
            });

            // extract data fields
            state.additionalData.source = state.selectedArticle.additionalData;
            state.additionalData.target = state.selectedArticle.additionalData.map((data, i) => {
                let targetData;

                const dataName = dataFieldPrefix + i;
                const fieldData = form1.getFieldValue(dataName);

                if (fieldData) {
                    // copy the properties from the selected article
                    if (fieldData === newDataField) {
                        // remove the _id to allow create
                        targetData = { ...data, _id: undefined };
                    } else {
                        const configData = configAdditionalData.find((d) => d._id === fieldData);
                        if (configData) {
                            targetData = { ...configData, value: data.value };
                            targetData.value = data.value;
                        }
                    }
                }

                return targetData;
            });

            const missingQuestions =
                configQuestions.length - state.questions.target.filter((q) => q).length;
            let completeReview = form1.getFieldValue("completeReview");

            if (requireAnswers && missingQuestions > 0) {
                state.missingQuestions = missingQuestions;
            } else if (!requireAnswers && missingQuestions > 0 && completeReview === undefined) {
                state.missingQuestions = missingQuestions;
            } else {
                if (completeReview === undefined) {
                    completeReview = true;
                }
                await onFinish({ ...state, completeReview });
                resetState();
                onCancel();
            }
        };

        const steps = [
            {
                title: "Previously reviewed in the following projects",
                body: (
                    <ReuseArticleList
                        articles={articles}
                        onUse={async (selectedArticle) => {
                            await getProjectMapping({
                                sourceId: selectedArticle.project,
                                targetId: article.project,
                            });
                            state.selectedArticle = selectedArticle;
                            state.currentStepIndex = 1;
                        }}
                    />
                ),
                footer: <Footer />,
            },
            {
                title: "Previously reviewed in the following projects",
                body: <Step2 />,
                footer: (
                    <Footer
                        actions={
                            <Button
                                type={"primary"}
                                onClick={() => {
                                    form1
                                        .validateFields()
                                        .then(onFormSubmit)
                                        .catch(() => {});
                                }}
                            >
                                Accept L2 reference
                            </Button>
                        }
                    />
                ),
            },
        ];
        return (
            <MultiStepModal
                steps={steps}
                currentStepIndex={state.currentStepIndex}
                onCancel={onCancel}
                loading={state.loading}
                width={1000}
            />
        );
    },
);

export default ReuseArticleL2Modal;
