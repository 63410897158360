import React from "react";
import { observer } from "mobx-react";
import { useParams, useHistory } from "react-router-dom";
import BookmarkList from "../bookmark/list";

const Bookmarks = observer(({ readonly = false, keepBookmarksOnUnmount }) => {
    const { project, category, document } = useParams();
    const history = useHistory();

    if (!document) {
        return null;
    }

    /**
     * Open a bookmark
     */
    const open = (bookmark) => {
        let link = "";
        if (bookmark.areas) {
            link =
                `/r/${project}/document/categories/${category}/${document}/open` +
                `?area=${JSON.stringify(bookmark.areas[0])}&bid=${bookmark._id}`;
        } else if (bookmark.excel) {
            link =
                `/r/${project}/document/categories/${category}/${document}/open` +
                `?excel=${JSON.stringify(bookmark.excel)}&bid=${bookmark._id}`;
        }
        history.push(link);
    };

    return (
        <div className="bookmarks">
            <BookmarkList
                document={document}
                onOpen={open}
                readonly={readonly}
                keepBookmarksOnUnmount={keepBookmarksOnUnmount}
            />
        </div>
    );
});

export default Bookmarks;
