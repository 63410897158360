import { Extension } from "@tiptap/core";

export default Extension.create({
    name: "loading",

    addCommands() {
        return {
            setLoading: () => ({ editor }) => {
                editor.emit("loading", true);
            },

            unsetLoading: () => ({ editor }) => {
                editor.emit("loading", false);
            },
        };
    },
});
