import React from "react";
import classes from "classnames";
import "./style/card.scoped.scss";
import { observer } from "mobx-react-lite";

const Card = observer(
    ({
        className = "",
        children,
        focus = false,
        scrollIntoView = false,
        selected,
        onClick,
        ...rest
    }) => {
        const ref = React.useRef(null);

        React.useEffect(() => {
            if (focus && ref.current && scrollIntoView) {
                setTimeout(() => {
                    ref.current &&
                        ref.current.scrollIntoView({
                            behavior: "smooth",
                            block: "nearest",
                        });
                }, [100]);
            }
        }, [focus, ref, scrollIntoView]);

        return (
            <div
                className={classes({
                    card: true,
                    [className]: true,
                    focus: focus,
                    selected,
                })}
                {...rest}
                ref={ref}
                onClick={onClick}
            >
                {children}
            </div>
        );
    },
);

export default Card;
