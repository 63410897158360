import { observable, computed } from "mobx";

import Model from "@app/state/model/base";
import format from "@app/lib/format";

import File from "./file";

const PREVIEW_MIME_TYPES = {
    "application/pdf": "pdf",
    "image/png": "png",
    "image/gif": "gif",
    "image/jpeg": "jpeg",
    "image/bmp": "bmp",
    "application/vnd.ms-excel": "xls",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
};

export default class Document extends Model {
    @observable _id = undefined;
    @observable project = undefined;
    @observable status = undefined;
    @observable categories = undefined;
    @observable deleted = undefined;

    @observable name = undefined;
    @observable fileName = undefined;
    @observable file = undefined;
    @observable preview = undefined;
    @observable previewStatus = undefined;
    @observable version = undefined;
    @observable author = {
        _id: undefined,
        fullName: undefined,
        avatar: undefined,
    };

    @observable created = undefined;
    @observable history = undefined;
    @observable documentTags = [];

    /**
     * Check if the document can be opened in the platform
     */
    @computed get canOpen() {
        return !!this.file?.type || this.preview;
    }

    /**
     * Check if the document can be opened in the platform
     */
    @computed get isImage() {
        return !!this.file?.isImage;
    }

    @computed get isExcel() {
        return !!this.file?.isExcel;
    }

    @computed get isPdf() {
        if (this.preview?.mime === "application/pdf") {
            return this.preview._id;
        } else if (this.file?.mime === "application/pdf") {
            return this.file._id;
        } else {
            return undefined;
        }
    }

    /**
     * Overwrite the set method
     */
    set(data) {
        super.set(data);

        this.author.initials = format.initials(this.author.fullName);
        this.file = new File(this.file);
    }

    static canPreview(document) {
        const previewMime = PREVIEW_MIME_TYPES[document.file?.mime];
        if (previewMime) {
            return true;
        } else if (document.preview) {
            return true;
        }

        return false;
    }
}
