import { observable } from "mobx";
import Model from "@app/state/model/base";
import { NumberOutlined, TableOutlined, PictureOutlined } from "@ant-design/icons";

export default class CrossReference extends Model {
    static SECTION = "SECTION";
    static TABLE = "TABLE";
    static FIGURE = "FIGURE";

    static iconMap = {
        SECTION: NumberOutlined,
        TABLE: TableOutlined,
        FIGURE: PictureOutlined,
    };

    @observable id = undefined;
    @observable type = undefined;
    @observable label = undefined;
    @observable ref = undefined;
}
