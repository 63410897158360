import React from "react";

export default function () {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            width="1em"
            height="1em"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
        >
            <path
                fill="currentColor"
                d="M12.14 9.341v.002L7.37 2.328a.75.75 0 1 0-1.24.844l5.13 7.545l-2.395 3.743a4
                4 0 1 0 1.178.943l2.135-3.337l2.065 3.036a4 4 0 1 0
                1.261-.813l-2.447-3.597l.002-.002l-.918-1.348Zm-7.64 8.66a2.5 2.5 0 1 1 5 0a2.5
                2.5 0 0 1-5 0Zm10 0a2.5 2.5 0 1 1 5 0a2.5 2.5 0 0 1-5
                0Zm-.562-8.685l3.943-6.162a.75.75 0 1 0-1.263-.808L13.02 7.968l.918 1.348Z"
            />
        </svg>
    );
}
