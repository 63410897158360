import { observer } from "mobx-react";
import { Input as Text } from "antd";
import React from "react";
import Group from "./group";

const Input = observer(({ filter, name, title, disabled, collapsed, groupKey }) => {
    let value;
    if (name) {
        const filterContent = filter.state.filter;
        value = filterContent[name] ?? "";
    }

    const update = (value) => {
        if (value.trim() !== "") {
            filter.filter(name, Number(value));
        } else {
            filter.filter(name, undefined);
        }
    };

    return (
        <Group
            title={title}
            hasActiveFilters={value}
            collapsed={collapsed}
            groupKey={groupKey ?? name}
        >
            <div>
                <Text
                    type="number"
                    value={value}
                    onKeyPress={(event) => {
                        const keyCode = event.keyCode || event.which;
                        const keyValue = String.fromCharCode(keyCode);
                        if (/\+|-/.test(keyValue)) {
                            event.preventDefault();
                        }
                    }}
                    onChange={(e) => {
                        update(e.target.value);
                    }}
                    disabled={disabled}
                />
            </div>
        </Group>
    );
});

export default Input;
