import React from "react";
import { observer } from "mobx-react";
import format from "@app/lib/format";

import "./style/additional-data-configuration.scoped.scss";

const AdditionalDataConfiguration = observer(({ data }) => {
    if (!data?.title) {
        return null;
    }
    const hasOptions = data?.options?.length > 0;

    return (
        <div className="details">
            <div className="additional-data">
                <div className="columns">
                    <span>Title</span>
                    <span>Help message</span>
                    <span>Type</span>
                    <span>AI Question</span>
                </div>

                <div className="values">
                    <span>{data?.title}</span>
                    <span>{data?.helpMessage}</span>
                    <span>{format.properCase(data?.type)}</span>
                    <span>{data?.ai?.question}</span>
                </div>
            </div>

            {hasOptions && (
                <div className="options">
                    {<div className="options-title">Allowed Values</div>}
                    {data?.options?.map((option, index) => {
                        return (
                            <div className="option row" key={index}>
                                <div className="col value">({option.value})</div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
});

export default AdditionalDataConfiguration;
