import { default as React, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { CitationModal } from "@app/components/citation";
import { Citation as CitationNode } from "./node";

import { getMarks } from "../utils/content";
import listStore from "@app/state/store/report/citation/list";

export const CitationPlugin = observer(({ editor }) => {
    const [addCitation, setAddCitation] = useState(null);
    const [editCitation, setEditCitation] = useState(null);

    const store = editor.storage.citation.store;

    const insertCitation = (props) => {
        const marks = getMarks({ editor });
        editor
            .chain()
            .focus()
            .insertContentAt(addCitation, [
                {
                    type: CitationNode.name,
                    attrs: props,
                    marks,
                },
            ])
            .run();
    };

    const onSubmit = async (values) => {
        const citation = await store.save(editCitation ? { ...editCitation, ...values } : values);

        if (addCitation) {
            insertCitation({ id: citation._id });
            setAddCitation(null);
        }

        if (editCitation) {
            setEditCitation(null);
        }
    };

    const onCancel = () => {
        if (addCitation) {
            setAddCitation(null);
        }

        if (editCitation) {
            setEditCitation(null);
        }
    };

    const onFetch = (data) => {
        return listStore.fetchCitation(data);
    };

    const onAddCitation = (atRange) => setAddCitation(atRange);
    const onEditCitation = (citationId) => setEditCitation(store.citationsMap[citationId]);

    useEffect(() => {
        if (!editor) {
            return;
        }

        editor.on("addCitation", onAddCitation);
        editor.on("editCitation", onEditCitation);

        return () => {
            editor.off("addCitation", onAddCitation);
            editor.off("editCitation", onEditCitation);
        };
    }, [editor]);

    return (
        <CitationModal
            open={addCitation || editCitation}
            citation={editCitation}
            onSubmit={onSubmit}
            onCancel={onCancel}
            onFetch={onFetch}
        />
    );
});

export default CitationPlugin;
