import React from "react";

/**
 * The nested content context definition
 */
export const NestedContent = React.createContext(null);

/**
 * A convenient hook to use the content context and check if it
 * is available in the place of usage
 */
export function useContent() {
    const context = React.useContext(NestedContent);
    if (context === null) {
        throw new Error("useContent must be used within a Page");
    }

    return context;
}
