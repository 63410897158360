import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import format from "@app/lib/format";

const HtmlViewer = observer(({ html, className, ...rest }) => {
    const content = format.sanitize(html);
    return (
        <div
            className={classNames("html", className)}
            dangerouslySetInnerHTML={{ __html: content }}
            {...rest}
        ></div>
    );
});

export default HtmlViewer;
