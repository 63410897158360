import React from "react";
import { observable } from "mobx";

export class SplitState {
    @observable left = 0;
    @observable right = 0;
    @observable isSplit = false;
}

/**
 * The page context definition
 */
export const SplitContext = React.createContext(null);

/**
 * Create a page state
 */
export function createSplitState() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const state = React.useMemo(() => {
        return new SplitState();
    }, []);

    return state;
}

/**
 * A convenient hook to use the split page context and check if it
 * is available in the place of usage
 */
export function useSplitPage({ check = true } = {}) {
    const context = React.useContext(SplitContext);
    if (context === null && check) {
        throw new Error("useSplitPage must be used within a SplitPage");
    }

    return context;
}
