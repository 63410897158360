import React from "react";
import { observer, useLocalStore } from "mobx-react";
import { v4 as uuid } from "uuid";

import configClipboard from "@app/state/store/config-clipboard";

import List from "../../list/ordered";
import CopyConfig from "../../config-clipboard/copy";
import PasteConfig from "../../config-clipboard/paste";
import Group from "../group";
import Reason from "./reason";
import Form from "./form";

import "./style/reason.scoped.scss";
import notify from "@app/components/notify/index";

const Reasons = observer(({ list, title, onChange, readonly = false }) => {
    const state = useLocalStore(() => ({
        edit: false,
        reason: undefined,
    }));

    const copyable = list.filter((reason) => !reason.system);

    /**
     * Handle the reorder event and save the list
     */
    const reorder = (action) => {
        let [item] = list.splice(action.from, 1);

        list.splice(action.to, 0, item);
        save();
    };

    /**
     * Handle the remove event and save the list
     */
    const remove = (reason) => {
        const index = list.findIndex((entry) => {
            return entry._id === reason._id;
        });

        if (index !== -1) {
            list.splice(index, 1);
            save();
        }
    };

    /**
     * Handle the edit canceled event and hide the form
     */
    const onCancel = () => {
        state.edit = false;
        state.reason = undefined;
    };

    /**
     * Handle the save event from an edit or an add operation
     */
    const onSave = (entry) => {
        // validate the reason's unique name
        const match = list.find(
            (reason) =>
                reason.reason.toLowerCase().trim() === entry.reason.toLowerCase().trim() &&
                reason._id !== entry._id,
        );
        if (match) {
            notify.error("An exclusion reason with this name already exists.");
            return;
        }

        state.edit = false;
        state.reason = undefined;

        // add operation
        if (!entry._id) {
            list.push({ reason: entry.reason });
        } else {
            const reason = list.find((item) => item._id === entry._id);
            if (reason) {
                reason.reason = entry.reason;
            }
        }

        save();
    };

    /**
     * Do the actual configuration update
     */
    const save = () => {
        onChange && onChange(list);
    };

    const copy = () => {
        configClipboard.copy("excludeReasons", copyable);
    };

    const paste = () => {
        list.push(
            ...configClipboard.paste("excludeReasons", (entry) =>
                copyable.find((li) => li.reason === entry.reason),
            ),
        );
        save();
    };

    return (
        <>
            <Group
                title={title}
                className="reasons"
                onAdd={
                    readonly
                        ? undefined
                        : () => {
                              state.edit = true;
                              state.reason = undefined;
                          }
                }
                onCopyButton={
                    <CopyConfig
                        onClick={copy}
                        tooltip={"Copy Reasons"}
                        disabled={!copyable.length}
                    />
                }
                onPasteButton={
                    !readonly && configClipboard.excludeReasons.length ? (
                        <PasteConfig onClick={paste} tooltip={"Paste Reasons"} />
                    ) : null
                }
            >
                {list.length === 0 && (
                    <Group.Empty warn={true}>
                        There are no configured exclusion reasons.
                        <br />
                        Please use the add button to add some.
                    </Group.Empty>
                )}

                <List onChange={!readonly && reorder}>
                    {list.map((entry, index) => {
                        // TODO: Fix duplicate keys everywhere
                        const id = entry._id || "new_" + uuid();

                        return (
                            <Reason
                                index={index}
                                key={id}
                                value={entry}
                                onSave={save}
                                onRemove={remove}
                                onEdit={() => {
                                    state.edit = true;
                                    state.reason = entry;
                                }}
                                readonly={readonly}
                            />
                        );
                    })}
                </List>
            </Group>

            {state.edit && <Form reason={state.reason} onSave={onSave} onCancel={onCancel} />}
        </>
    );
});

export default Reasons;
