import React from "react";

export default function () {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            width="20"
            height="20"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 32 32"
        >
            <circle cx="7" cy="9" r="3" fill="currentColor" />
            <circle cx="7" cy="23" r="3" fill="currentColor" />
            <path fill="currentColor" d="M16 22h14v2H16zm0-14h14v2H16z" />
        </svg>
    );
}
