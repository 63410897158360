import React from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import { Menu, Button, Dropdown } from "antd";
import { CopyOutlined, EditOutlined, DeleteOutlined, EllipsisOutlined } from "@ant-design/icons";

import confirm from "@app/components/confirm";
import Group from "../group";

import InputField from "@app/components/input-field";

import "./style/report-documents.scoped.scss";

const Card = observer(
    ({
        value,
        index,
        readonly = false,
        onRemove,
        edit = false,
        toggleEditView,
        onEdit,
        onCopy,
    }) => {
        const history = useHistory();

        /**
         * Handle delete button click
         */
        const handleRemove = async ({ domEvent }) => {
            domEvent.preventDefault();
            domEvent.stopPropagation();
            let proceed = await confirm(
                <div className="deleteMessage">
                    <div>You are deleting a report document. This action cannot be undone.</div>
                    <div>Are you sure you want to proceed?</div>
                </div>,
            );
            if (proceed) {
                onRemove && onRemove(value, domEvent);
            }
        };

        const handleCopy = async ({ domEvent }) => {
            domEvent.preventDefault();
            domEvent.stopPropagation();
            onCopy && onCopy(value._id);
        };

        const dropDownMenu = (
            <Menu>
                {onCopy && (
                    <Menu.Item icon={<CopyOutlined />} onClick={handleCopy}>
                        Copy
                    </Menu.Item>
                )}
                <Menu.Item
                    icon={<EditOutlined />}
                    onClick={({ domEvent }) => {
                        domEvent.preventDefault();
                        domEvent.stopPropagation();
                        toggleEditView();
                    }}
                >
                    Edit
                </Menu.Item>
                <Menu.Item icon={<DeleteOutlined />} onClick={handleRemove}>
                    Delete
                </Menu.Item>
            </Menu>
        );

        return (
            <div className="groupItemWrapper">
                <Group.Item id={value._id || "new"} index={index} disableDrag={true}>
                    <div
                        className="reportDocumentItem"
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            history.push(`./report-documents/${value._id}`);
                        }}
                    >
                        {edit ? (
                            <InputField
                                value={value.name}
                                onOk={(fieldValue) => {
                                    onEdit && onEdit(fieldValue);
                                    toggleEditView();
                                }}
                                onCancel={() => toggleEditView()}
                            />
                        ) : (
                            <>
                                <div className="text">{value.name}</div>

                                {!readonly && (
                                    <div className="actions">
                                        <Dropdown
                                            overlay={dropDownMenu}
                                            placement="bottomRight"
                                            trigger={["click"]}
                                        >
                                            <Button
                                                type="icon"
                                                icon={
                                                    <EllipsisOutlined
                                                        style={{ fontSize: "20px" }}
                                                    />
                                                }
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    event.stopPropagation();
                                                }}
                                            ></Button>
                                        </Dropdown>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </Group.Item>
            </div>
        );
    },
);

export default Card;
