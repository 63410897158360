import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Form, Input, Select } from "antd";
import Modal from "@app/components/modal";
import notify from "@app/components/notify";

import state from "@app/state/store/report/document/bookmarks";

export const BookmarkModal = observer(({ visible, bookmark, onSave, onDiscard }) => {
    const [form] = Form.useForm();
    const categories = state.categories ?? [];

    useEffect(() => {
        if (state.categories === null && !state.loading) {
            state.loadCategories();
        }

        form.setFieldsValue({
            name: bookmark?.name,
            categories: bookmark?.categories,
        });
    }, [bookmark, form]);

    if (state.categories === null) {
        return;
    }

    /**
     * Handle the OK button press and validate the form
     */
    const save = (data) => {
        const value = {
            ...bookmark,
            ...data,
        };

        onSave && onSave(value);
    };

    /**
     * Close the modal
     */
    const cancel = () => {
        onDiscard && onDiscard();
    };

    /**
     * Show the validation error
     */
    const validationError = () => {
        notify.error("Please fill all required fields to continue");
    };

    return (
        <Modal
            title={bookmark?._id ? "Edit a bookmark" : "Create a bookmark"}
            visible={visible}
            onOk={() => {
                form.submit();
            }}
            onCancel={cancel}
            width={800}
        >
            <div className="bookmark">
                <Form
                    layout="vertical"
                    initialValues={bookmark}
                    form={form}
                    onFinishFailed={validationError}
                    onFinish={save}
                >
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                message: "Please enter the bookmark's name",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="Categories" name="categories">
                        <Select
                            mode="multiple"
                            optionFilterProp="name"
                            placeholder="Pick a category"
                        >
                            {categories.map((category) => {
                                return (
                                    <Select.Option
                                        key={category._id}
                                        value={category._id}
                                        label={category.name}
                                    >
                                        {category.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
});

export default BookmarkModal;
