import React, { useState, useEffect, createRef } from "react";
import { Upload } from "antd";
import classNames from "classnames";
import { CloudUploadOutlined } from "@ant-design/icons";
import { UploadService } from "../service";
import { useUploadContext } from "../context";

import "./area.scss";

const DropArea = ({
    multiple = false,
    state,
    onChange,
    show = false,
    disabled = false,
    accept,
    className,
    children,
    ...rest
}) => {
    const [visible, setVisible] = useState(show);

    if (disabled) {
        return children;
    }

    return (
        <Trigger
            className={className}
            onChange={(visible) => {
                setVisible(visible);
            }}
            {...rest}
        >
            {children}
            {visible && (
                <Dropper
                    multiple={multiple}
                    onChange={onChange}
                    state={state}
                    accept={accept}
                    {...rest}
                />
            )}
        </Trigger>
    );
};

const Dropper = ({ accept, multiple, state, onChange }) => {
    const { projectId, clientId } = useUploadContext();

    /**
     * Handle the beforeUpload event and start the upload process.
     * Return false to prevent the component uploading the file.
     * We are handling the upload ourselves.
     *
     * @param {File} file
     */
    const onFileDrop = (file) => {
        (async () => {
            const { data } = await UploadService.upload(file, {
                projectId,
                clientId,
                state,
            });

            if (data) {
                onChange && (await onChange(data));
            }
        })();
        return false;
    };

    return (
        <Upload.Dragger
            beforeUpload={onFileDrop}
            multiple={multiple}
            accept={accept}
            className="drag-area"
        >
            <p className="ant-upload-drag-icon">
                <CloudUploadOutlined />
            </p>
            <p className="ant-upload-text">Drag & Drop a File</p>
            <p className="ant-upload-hint">
                Drag and drop a file in this area to upload it.
                {multiple && " You can drag and drop more than one file if you want to"}
            </p>
        </Upload.Dragger>
    );
};

const Trigger = ({ onChange, className, children, ...rest }) => {
    const ref = createRef();
    let active = false;
    let timer = null;

    useEffect(() => {
        const options = { capture: true, passive: true };
        const trigger = ref.current;

        const events = {
            dragover: onEnter,
            dragleave: onLeave,
            drop: onLeave,
            mouseleave: onLeave,
        };

        if (trigger) {
            // attache the drop start/stop event handler to toggle the visibility
            // of the dropper widget
            for (const event of Object.keys(events)) {
                trigger.addEventListener(event, events[event], options);
            }
        }

        return function () {
            if (trigger) {
                // cleanup event handlers
                for (const event of Object.keys(events)) {
                    trigger.removeEventListener(event, events[event], options);
                }
            }

            clearTimeout(timer);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref.current]);

    const onEnter = (e) => {
        clearTimeout(timer);

        const items = e.dataTransfer.items;
        if (items[0]?.kind !== "file") {
            return;
        }

        if (!active) {
            active = true;
            onChange(active);
        }
    };

    const onLeave = () => {
        if (!active) {
            return;
        }

        // instead of triggering an immediate hide event we are scheduling it to prevent
        // flickering when multiple enter/leave events are constantly triggered
        timer = setTimeout(() => {
            active = false;
            timer = null;

            onChange(active);
        }, 200);
    };

    return (
        <div className={classNames("drop-area-trigger", className)} ref={ref} {...rest}>
            {children}
        </div>
    );
};

export default DropArea;
