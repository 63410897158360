import { mergeAttributes, ReactNodeViewRenderer } from "@tiptap/react";
import { default as Suggestion } from "@tiptap/suggestion";
import { default as Mention } from "@tiptap/extension-mention";

import { default as citationSuggestion } from "./suggestion";
import { default as NodeView } from "./node-view";

import "./styles.scss";

export const Citation = Mention.extend({
    name: "citation",

    addOptions() {
        return {
            ...this.parent?.(),
            store: {},
        };
    },
    addCommands() {
        return {
            ...this.parent?.(),
            addCitation: ({ atRange }) => ({ editor }) => {
                editor.emit("addCitation", atRange);
            },
        };
    },
    addAttributes() {
        return {
            id: this.parent?.().id,
            comment: {
                default: undefined,
            },
            "data-diff-node": {
                default: undefined,
            },
            "data-diff-id": {
                default: undefined,
            },
        };
    },
    addStorage() {
        return {
            store: {},
        };
    },
    addNodeView() {
        return ReactNodeViewRenderer(NodeView);
    },
    addKeyboardShortcuts() {
        return {
            Backspace: () =>
                this.editor.commands.command(({ tr, state }) => {
                    let isMention = false;
                    const { selection } = state;
                    const { empty, anchor } = selection;

                    if (!empty) {
                        return false;
                    }

                    state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
                        if (node.type.name === this.name) {
                            isMention = true;
                            tr.insertText("", pos, pos + node.nodeSize);

                            return false;
                        }
                    });

                    return isMention;
                }),
        };
    },
    addProseMirrorPlugins() {
        return [
            Suggestion({
                editor: this.editor,
                ...this.options.suggestion,
                ...citationSuggestion,
            }),
        ];
    },
    parseHTML() {
        return [
            {
                tag: `span[data-${this.name}]`,
            },
        ];
    },
    renderHTML({ node, HTMLAttributes }) {
        const key = `data-${this.name}`;

        return [
            "span",
            mergeAttributes({ [key]: "" }, this.options.HTMLAttributes, HTMLAttributes),
            this.options.renderLabel({
                options: this.options,
                node,
            }),
        ];
    },
    onBeforeCreate() {
        this.storage.store = this.options.store || {};
    },
});
export default Citation;
