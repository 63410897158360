import React from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";

import { Button } from "antd";

const Success = observer(({ state }) => {
    return (
        <>
            <p>
                You can use the link below to go to the login page and sign in using your new
                password.
            </p>

            <Link to="./login">
                <Button type="primary">Sign In</Button>
            </Link>
        </>
    );
});

export default Success;
