import React from "react";
import { observer } from "mobx-react";
import session from "@app/state/store/session";
import { Form, Button, Input, Alert } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import "./style/mfa.scoped.scss";

const MFA = observer(() => {
    const history = useHistory();
    const [form] = Form.useForm();

    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);

    /**
     * Perform MFA
     * @param {Object} args
     */
    const check = async (args) => {
        setLoading(true);
        const success = await session.validateTOTP(args);
        setLoading(false);

        // show the error
        if (!success || !session.loggedIn) {
            form.resetFields();
            return setError(true);
        }

        history.push("/");
    };

    const resend = async () => {
        setLoading(true);
        await session.generateTOTP();
        setLoading(false);
    };

    return (
        <div className="login auth">
            <div className="logo">
                <img src={require("@app/assets/logo-name.svg")} />
            </div>

            {error ? (
                <Alert
                    className="alert"
                    message="Verification Failed"
                    description="Please provide a correct verification code"
                    type="error"
                    showIcon
                />
            ) : null}

            <Form layout="vertical" form={form} onFinish={check}>
                <div className="form-title">Verification Code</div>

                <div className="label">Please enter the one time code we sent to your email.</div>

                <div className="row">
                    <div className="col">
                        <Form.Item
                            label="Code"
                            name="totp"
                            rules={[{ required: true, message: "Please enter the code!" }]}
                        >
                            <Input prefix={<LockOutlined />} placeholder="Enter the code here" />
                        </Form.Item>
                    </div>
                    <div className="col auto resend">
                        <Button loading={loading} onClick={resend}>
                            Resend Code
                        </Button>
                    </div>
                </div>

                <Form.Item>
                    <Button type="primary" size="large" loading={loading} htmlType="submit">
                        Verify
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
});

export default MFA;
