/**
 * Check if the transaction changed the document
 */
function isEdit({ editor, transaction }) {
    return editor.isEditable && transaction.docChanged;
}

/**
 * Return a list of added nodes from specific type
 */
function addedNodes({ editor, transaction }, { type }) {
    const nodes = [];

    for (const step of transaction.steps) {
        const fragment = step.slice?.content || [];

        // check for added nodes
        const op = step.jsonID;
        if ((op === "replace" || op === "replaceAround") && fragment.size > 0) {
            for (const node of fragment.content) {
                if (node.type?.name === type) {
                    nodes.push(node);
                }
            }
        }
    }

    return nodes;
}

/**
 * Get a list of removed nodes from specific type
 */
function removedNodes({ editor, transaction }, { type }) {
    const nodes = [];

    // scan removed nodes and mark the section for update
    const scanRemovedNodes = (node) => {
        if (node.type.name === type) {
            nodes.push(node);
        }
    };

    for (const [i, step] of transaction.steps.entries()) {
        const fragment = step.slice?.content || [];

        if (step.jsonID === "replace" && fragment.size === 0) {
            const map = transaction.mapping.maps[i];
            const start = map.ranges[0];
            const end = map.ranges[0] + map.ranges[1];

            try {
                transaction.before.nodesBetween(start, end, scanRemovedNodes);
                // eslint-disable-next-line
            } catch (ex) {}
        }
    }

    return nodes;
}

export default {
    isEdit,
    addedNodes,
    removedNodes,
};
