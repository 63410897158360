/****
 * Copies the component (prop) - Usually a table into the clipboard.
 * By Providing a Copy to Clipboard button.
 * Once Copied, you can now - Paste the copied content directly to the editor of your choice.
 */

//#region - Imports
import React from "react";
import { Button } from "antd";
import notify from "@app/components/notify/index";
import { CopyOutlined } from "@ant-design/icons";
//#endregion

//#region - Component

const CLEANUP_TIMEOUT = 100;

/**
 *
 * To DO : document.execCommand will be being deprecated.
 * Need to explore alternate approaches like - Clipboard API
 */

export const Copy = ({ component, text, icon = false, className = "clipboard-content" }) => {
    const [isRendered, setRendered] = React.useState(false);
    const refContent = React.useRef(null);

    React.useEffect(() => {
        const element = refContent.current;
        if (element !== null) {
            var selection = window.getSelection();
            var range = document.createRange();
            range.selectNodeContents(element);
            selection.removeAllRanges();
            selection.addRange(range);

            document.execCommand("copy");
            notify.info("The content was successfully copied.");

            // remove the content from the DOM
            setTimeout(() => {
                setRendered(false);
            }, CLEANUP_TIMEOUT);
        }
    }, [isRendered]);

    /**
     * Render the content
     */
    const Content = () => {
        if (component) {
            return component;
        }

        if (text) {
            return <>{text}</>;
        }

        return null;
    };

    return (
        <>
            <Button
                type={icon === true ? "icon" : "primary"}
                icon={<CopyOutlined />}
                onClick={() => {
                    setRendered(true);
                }}
            >
                {icon === true ? "" : "Copy"}
            </Button>
            {isRendered && (
                <div
                    className={className}
                    contentEditable={true}
                    suppressContentEditableWarning="true" // Suppress React Warning
                    ref={refContent}
                    style={{
                        position: "fixed",
                        width: "500px",
                        left: "-9999px",
                    }}
                >
                    <Content />
                </div>
            )}
        </>
    );
};

//#endregion
