import React from "react";
import { observer } from "mobx-react";
import { Page } from "@app/components/page";

import Search from "./search";
import Sort from "./sort";
import Toggle from "./toggle";
import Count from "./count";
import SmallPager from "./pager/small";

import "./style/header.scss";

const Header = observer(
    ({
        filter,
        toggle = true,
        sorting = true,
        showRight = true,
        showMultiAction,
        multiAction,
        afterSearch,
        ...rest
    }) => {
        return (
            <Page.Header className="filter-header" {...rest}>
                <Page.Header.Left>
                    <Search filter={filter} afterSearch={afterSearch} />
                </Page.Header.Left>

                {showRight && (
                    <Page.Header.Right shrink>
                        <Count filter={filter} />
                        <SmallPager filter={filter} />

                        {showMultiAction && multiAction && (
                            <>
                                <Page.Header.Separator />
                                {multiAction}
                            </>
                        )}

                        <Page.Header.Separator />

                        {sorting && <Sort filter={filter} />}
                        {toggle && <Toggle filter={filter} />}
                    </Page.Header.Right>
                )}
            </Page.Header>
        );
    },
);

export default Header;
