import React, { useEffect } from "react";
import { observer, useLocalStore } from "mobx-react";
import { useHistory, useParams, Link } from "react-router-dom";
import { Button, Menu, Dropdown } from "antd";
import classNames from "classnames";
import {
    EllipsisOutlined,
    CloudDownloadOutlined,
    DeleteOutlined,
    FileSearchOutlined,
    EditOutlined,
} from "@ant-design/icons";

import { CustomCategories, DocumentStatus } from "@app/constants";

import Document from "@app/state/model/document";

import documents from "@app/state/store/report/document/list";
import state from "@app/state/store/report/document/details";
import categories from "@app/state/store/report/document/categories";
import session from "@app/state/store/session";
import reportStore from "@app/state/store/report";
import applicationStore from "@app/state/store/application";

import { Page } from "@app/components/page";
import UploadMenuItem from "@app/components/form/upload/menu-item";
import DocumentDetails from "@app/components/document/details";
import DocumentSidebar from "@app/components/document/sidebar/index";
import { CategoryModal } from "@app/components/document/chunks/modal";
import confirm from "@app/components/confirm";

import { download } from "@app/lib/file";

const Details = observer(({ className, ...rest }) => {
    const history = useHistory();

    const readonly = reportStore.readOnly;

    const store = useLocalStore(() => ({
        allCategories: [],
        assignedCategories: [],
        edit: false,
        modal: false,
    }));

    const document = state.document || {};

    const { document: documentId, category: categoryId } = useParams();
    const category = categories.getDetails(categoryId);
    const canReview = session.can("sourceDocuments.review") && !readonly;
    const canOpen = Document.canPreview(document);

    useEffect(() => {
        (async () => {
            if (documentId) {
                await state.load(documentId);

                const allConfiguredCategories = await categories.configuredCategories();
                const { assignedCategories, assignedCategoryNames } = state.categoryDetails(
                    allConfiguredCategories,
                );

                store.assignedCategories = assignedCategories;
                store.assignedCategoriesNames = assignedCategoryNames;
                store.allCategories = allConfiguredCategories;
            }
        })();
    }, [documentId, store]);

    if (!document._id) {
        return;
    }

    /**
     * Close the article
     */
    const close = () => {
        history.push(`../${categoryId}`);
    };

    const update = async (data) => {
        if (data.preview) {
            data.preview.name = `${document.name}.pdf`;
        }

        if (data.file) {
            data.preview = null;
        }

        await state.update(data);
    };

    const addTag = (tag) => {
        let tags = [...document.documentTags];
        tags.push(tag);

        document.documentTags = tags;
        update({ documentTags: tags });
    };

    const removeTag = async (tag) => {
        let tags = document.documentTags.filter((item) => item._id !== tag._id);

        document.documentTags = tags;
        await update({ documentTags: tags });
    };

    const addCategory = async (tag) => {
        let categories = [...document.categories];

        categories.push(tag._id);
        await updateDetails({ categories });
    };

    const removeCategory = async (tag) => {
        let categories = document.categories.filter((item) => item !== tag._id);

        await updateDetails({ categories });
    };

    const updateDetails = async (payload) => {
        await update(payload);

        const { assignedCategories, assignedCategoryNames } = state.categoryDetails(
            store.allCategories,
        );

        store.assignedCategories = assignedCategories;
        store.assignedCategoriesNames = assignedCategoryNames;

        const found = store.assignedCategories.find((item) => item.name === category.name);

        if ((!found && CustomCategories.ALL !== category._id) || payload.categories?.length === 0) {
            close();
        }
    };

    const deleteDocument = async () => {
        let proceed = await confirm("Are you sure you want to delete this document?");

        if (proceed) {
            await documents.delete(document._id);
            close();
        }
    };

    const menu = (
        <Menu className="menu">
            {!readonly && (
                <Menu.Item key="upload">
                    <UploadMenuItem
                        onChange={(file) => {
                            update({ file });
                        }}
                    >
                        Upload new version
                    </UploadMenuItem>
                </Menu.Item>
            )}

            <Menu.Item key="upload-preview">
                <UploadMenuItem
                    onChange={(preview) => {
                        update({ preview });
                    }}
                    accept="application/pdf"
                >
                    Upload Preview PDF
                </UploadMenuItem>
            </Menu.Item>

            <Menu.Item
                onClick={() => {
                    download(document.file._id);
                }}
                icon={<CloudDownloadOutlined />}
            >
                Download
            </Menu.Item>

            {[DocumentStatus.ACCEPTED, DocumentStatus.REJECTED].includes(document.status) &&
                canReview &&
                !readonly && (
                    <Menu.Item
                        onClick={() => update({ status: DocumentStatus.PENDING })}
                        icon={<FileSearchOutlined />}
                    >
                        Mark as pending
                    </Menu.Item>
                )}

            {!readonly && (
                <Menu.Item onClick={() => (store.modal = true)} icon={<EditOutlined />}>
                    Edit
                </Menu.Item>
            )}

            {!readonly && <Menu.Divider />}
            {!readonly && (
                <Menu.Item onClick={deleteDocument} icon={<DeleteOutlined />}>
                    Delete
                </Menu.Item>
            )}
        </Menu>
    );

    const restore = async (data) => {
        await state.restore(document._id, data);
    };

    return (
        <Page className={classNames("document", className)} {...rest}>
            <Page.Header closable={true} onClose={close}>
                <Page.Header.Left>
                    <Page.Title breadcrumbs={false}>Document Details</Page.Title>
                </Page.Header.Left>
                <Page.Header.Right shrink>
                    {canOpen && (
                        <Link to={`./${document._id}/open`}>
                            <Button>Open</Button>
                        </Link>
                    )}
                    {!canOpen && (
                        <Button
                            onClick={() => {
                                download(document.file._id);
                            }}
                        >
                            Download
                        </Button>
                    )}

                    {document.status === DocumentStatus.PENDING && canReview && !readonly && (
                        <Button onClick={() => update({ status: DocumentStatus.ACCEPTED })}>
                            Accept
                        </Button>
                    )}

                    {document.status === DocumentStatus.PENDING && canReview && !readonly && (
                        <Button onClick={() => update({ status: DocumentStatus.REJECTED })}>
                            Reject
                        </Button>
                    )}

                    <Dropdown overlay={menu} trigger="click" placement="bottomRight">
                        <Button icon={<EllipsisOutlined />} />
                    </Dropdown>
                </Page.Header.Right>
            </Page.Header>

            <Page.Layout>
                <Page.Body className="page-body">
                    <DocumentDetails
                        document={document}
                        availableTags={reportStore.config?.documentTags}
                        assignedTags={document.documentTags}
                        assignedCategories={store.assignedCategories}
                        availableCategories={store.allCategories}
                        addCategory={addCategory}
                        removeCategory={removeCategory}
                        edit={!readonly}
                        onAdd={addTag}
                        onRemove={removeTag}
                    />
                </Page.Body>
                <DocumentSidebar
                    document={document}
                    onRestore={restore}
                    readonly={readonly}
                    enableAI={applicationStore.enableAI}
                />
            </Page.Layout>
            {store.modal && (
                <CategoryModal
                    allCategories={store.allCategories}
                    assignedCategoriesNames={store.assignedCategoriesNames}
                    setAssignedCategories={(data) => {
                        store.assignedCategoriesNames = data;
                    }}
                    document={document}
                    visible={store.modal}
                    onOk={(data) => {
                        updateDetails(data);
                        store.modal = false;
                    }}
                    onCancel={() => (store.modal = false)}
                />
            )}
        </Page>
    );
});

Details.displayName = "Details";

export default Details;
