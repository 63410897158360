import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Page } from "@app/components/page";
import { useSidebar } from "../page/sidebar";
import classNames from "classnames";

import "./style/sidebar.scoped.scss";

const UserFilter = observer(({ filter, className, children }) => {
    const sidebar = useSidebar();
    const ui = filter.ui;

    useEffect(() => {
        /**
         * Handle visibility toggle from the filter ui state
         */
        const onToggle = (visible) => {
            if (visible) {
                sidebar.show();
            } else {
                sidebar.hide();
            }
        };

        // handle the change of state
        ui.on("visible", onToggle);

        // cleanup event handler
        return function () {
            ui.off("visible", onToggle);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Handle sidebar state change event
     */
    const onChange = () => {
        if (ui.visible !== sidebar.visible) {
            ui.visible = sidebar.visible;
        }

        if (ui.inline !== sidebar.inline) {
            ui.inline = sidebar.inline;
        }
    };

    return (
        <Page.Sidebar
            className={classNames("filter-sidebar", className)}
            sidebar={sidebar}
            width={320}
            padding={false}
            title="Filter"
            onChange={onChange}
        >
            <div className="filter-groups">{children}</div>
        </Page.Sidebar>
    );
});

export default UserFilter;
