import BaseStore from "../../base";
import http from "@app/lib/http";
import notify from "@app/components/notify";
import { action, computed } from "mobx";
import report from "../../report";

class DynamicReportStore extends BaseStore {
    observable() {
        return { loading: false, data: {} };
    }

    @computed get project() {
        return report.id;
    }

    @action async load(type, id) {
        this.loading = true;

        try {
            const { data } = await http.get(`/project/${this.project}/reports/new/${type}/${id}`);
            this.data = data;
        } catch (ex) {
            notify.error(ex.response?.data?.error);
        } finally {
            this.loading = false;
        }
    }
}

export default new DynamicReportStore();
