import { observable, action } from "mobx";
import { v4 as uuid } from "uuid";
import EventEmitter from "events";
import http from "@app/lib/http";
import notify from "@app/components/notify/index";

export class FileUpload extends EventEmitter {
    @observable uid = uuid();
    @observable name = "";
    @observable error = false;
    @observable complete = false;
    @observable progress = 0;

    constructor({ file, share = false, projectId, clientId }) {
        super();

        if (!projectId && !clientId) {
            share = true;
        }

        this.data = new FormData();
        this.data.append("file", file);
        this.data.append("private", !share);
        this.data.append("name", file.name);

        if (clientId) {
            this.data.append("clientId", clientId);
        }

        if (projectId) {
            this.data.append("projectId", projectId);
        }

        this.name = file.name;
    }

    /**
     * Start the upload process
     */
    @action
    async start() {
        try {
            let config = {};
            config.onUploadProgress = (event) => {
                if (!event.loaded || !event.total) {
                    return;
                }

                this.progress = Math.round((event.loaded / event.total) * 100);
                this.emit("progress", this.progress);
            };

            const req = http.put("/file", this.data, config);

            // wrap the cancel function to set the canceled flag
            this.cancel = () => {
                req.cancel();
                this.canceled = true;
                this.progress = 100;

                this.emit("cancel");
            };

            // wait for the http response
            let { data } = await req;

            this.complete = true;
            this.progress = 100;

            return data;
        } catch (ex) {
            this.error = true;
            this.progress = 100;
            this.emit("error");

            return {
                error: true,
                code: ex.response?.data.code,
                message: ex.response?.data.message,
            };
        }
    }
}

export class UploadService {
    static async upload(file, args = {}) {
        let upload = new FileUpload({ file, projectId: args.projectId, clientId: args.clientId });

        // add the upload file to the state if any is passed
        if (args.state) {
            args.state.addFile(upload);
        }

        let data = await upload.start();
        if (data.error) {
            notify.error(data.message || "An error occurred while uploading a file");
            return { error: true };
        }

        return { error: false, data };
    }
}
