import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router-dom";
import Loader from "@app/components/loader/content";
import { Button } from "antd";

import dictionaryStore from "@app/state/store/report/dictionary/pick";
import abbreviationStore from "@app/state/store/report/abbreviation/pick";
import citationsStore from "@app/state/store/report/citation/pick";

import Page from "@app/components/page/page";

import documentsStore from "@app/state/store/report-document/report-documents";
import documentStore from "@app/state/store/report-document/document";
import Viewer from "@app/components/report-document/viewer/index";

const EditTemplate = observer(() => {
    const history = useHistory();
    const { id, documentId } = useParams();
    const reportDocument = documentsStore.reportDocument;
    const sections = documentsStore.reportDocumentSections;
    const loaded = reportDocument && sections && documentStore.loaded;
    const [editing, setEditing] = useState(null);

    useEffect(() => {
        (async () => {
            documentsStore.setClientId(id);
            await dictionaryStore.loadTemplateEntries({ documentId });
            await abbreviationStore.loadTemplateValues({ documentId });
            await documentsStore.load(documentId);

            documentStore.init(documentId, documentsStore.reportDocumentSections);
        })();

        return () => {
            documentsStore.unload();
            documentStore.reset();
            dictionaryStore.unload();
            abbreviationStore.unload();
        };
    }, [id, documentId]);

    const close = () => {
        history.push(`../${documentId}`);
    };

    return (
        <Page>
            <Page.Header closable onClose={close}>
                <Page.Header.Left shrink>
                    <Page.Title>{reportDocument?.name ?? "Loading..."}</Page.Title>
                </Page.Header.Left>

                <Page.Header.Right></Page.Header.Right>
            </Page.Header>

            <Page.Body padding={false}>
                {loaded && (
                    <Viewer
                        sections={sections}
                        dictionary={dictionaryStore}
                        citations={citationsStore}
                        abbreviations={abbreviationStore}
                        editingState={[editing, setEditing]}
                        documentId={documentId}
                        templateMode={true}
                    />
                )}

                <Loader loading={!loaded} />
            </Page.Body>
            {!editing && (
                <Page.Footer>
                    {loaded && (
                        <Page.Footer.Right>
                            <Button onClick={close}>Back</Button>
                        </Page.Footer.Right>
                    )}
                </Page.Footer>
            )}
        </Page>
    );
});

export default EditTemplate;
