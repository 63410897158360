import React from "react";

export default function () {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 36 36"
            className="icon"
        >
            <path
                fill="currentColor"
                d="M14.65 27.1a1.1 1.1 0 0 0 1.1 1.1H30V26H15.75a1.1 1.1 0 0 0-1.1 1.1Z"
            />
            <path
                fill="currentColor"
                d="M6.9 21.1A1.1 1.1 0 0 0 8 22.2h22V20H8a1.1 1.1 0 0 0-1.1 1.1Z"
            />
            <path
                fill="currentColor"
                d="M13.4 15.1a1.1 1.1 0 0 0 1.1 1.1H30V14H14.5a1.1 1.1 0 0 0-1.1 1.1Z"
            />
            <path fill="currentColor" d="M6.75 8a1.1 1.1 0 1 0 0 2.2H30V8Z" />
            <path fill="none" d="M0 0h36v36H0z" />
        </svg>
    );
}
