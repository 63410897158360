import React from "react";
import { observer } from "mobx-react";
import cn from "classnames";

import BubbleContent from "./bubble-content";

import "./bubble-container.scoped.scss";

const BubbleContainer = observer(({ pos, nodes, editor }) => {
    const isInLandscape = nodes.some((node) => node.isInLandscape);
    const [mouseIn, setMouseIn] = React.useState(false);
    const nodeCount = nodes.length;

    const onMouseEnter = () => {
        if (nodeCount === 1) {
            return;
        }

        setMouseIn(true);
    };

    const onMouseLeave = () => {
        if (nodeCount === 1) {
            return;
        }

        setMouseIn(false);
    };

    return (
        <div
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={cn({
                "bubble-container": true,
                landscape: isInLandscape,
            })}
            style={{
                top: `${Number.parseInt(pos) + 12}px`,
            }}
        >
            {nodes.map((node, index) => (
                <BubbleContent
                    key={node.id}
                    id={node.id}
                    type={node.type}
                    index={index}
                    zIndex={nodeCount}
                    mouseIn={mouseIn}
                    metadata={node.metadata}
                    editor={editor}
                    isSectionComment={node.isSectionComment}
                />
            ))}
        </div>
    );
});

export default BubbleContainer;
