import React, { useRef } from "react";
import { observer } from "mobx-react";
import cn from "classnames";

import "./bubble-item-container.scoped.scss";

const BubbleItemContainer = observer(
    ({
        isInLandscape,
        index,
        zIndex,
        mouseIn,
        children,
        focused,
        onClick,
        style,
        classNames = {},
        attrs = {},
    }) => {
        const ref = useRef(null);

        return (
            <div
                onClick={onClick}
                ref={ref}
                className={cn({
                    "bubble-item-container": true,
                    landscape: isInLandscape,
                    focused: focused,
                    ...classNames,
                })}
                style={{
                    zIndex: zIndex - index,
                    transform: mouseIn
                        ? `translateX(${index * 34}px)`
                        : `translateX(${index * 12}px)`,
                    ...style,
                }}
                {...attrs}
            >
                {children}
            </div>
        );
    },
);

export default BubbleItemContainer;
