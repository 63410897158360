import React from "react";
import { observer } from "mobx-react";

import versionStore from "@app/state/store/report-document/version";

import format from "@app/lib/format";

import "./styles/viewer.scoped.scss";

const CompareNotification = observer(() => {
    const { versionId, viewMode, versions, versionNames } = versionStore;

    if (viewMode === "diff" && versionId) {
        const activeVersion = versions.find((version) => version._id === versionId);
        const previousVersion = versions.find(
            (v) => new Date(v.updatedAt) < new Date(activeVersion.updatedAt),
        );

        const olderVersionTitle = previousVersion?.updatedAt
            ? format.datetime(previousVersion.updatedAt)
            : "initial";
        const newerVersionTitle = format.datetime(activeVersion.updatedAt);

        return (
            <div className="compare-notification">
                <p>
                    Comparing <b>{olderVersionTitle}</b> with <b>{newerVersionTitle}</b>
                </p>
            </div>
        );
    }

    if (viewMode === "compare") {
        return (
            <div className="compare-notification">
                <p>
                    Comparing <b>{versionNames.left}</b> with <b>{versionNames.right}</b>
                </p>
            </div>
        );
    }

    return null;
});

export default CompareNotification;
