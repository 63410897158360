import React from "react";
import { observer } from "mobx-react";

import { Button, Menu, Dropdown } from "antd";
import { EllipsisOutlined, CloudDownloadOutlined } from "@ant-design/icons";

import UploadMenuItem from "@app/components/form/upload/menu-item";
import file from "@app/lib/file";

import "./style/menu.scoped.scss";
import classNames from "classnames";

const ListActions = observer(({ article = {}, onUpload, className, ...props }) => {
    const onAction = (action) => {
        if (action.key === "download") {
            file.download(article.file);
        }
    };

    const actions = (
        <Menu onClick={onAction} className="menu">
            <Menu.Item key="upload">
                <UploadMenuItem
                    accept=".pdf,application/pdf"
                    onChange={(file) => {
                        onUpload(file);
                    }}
                >
                    Upload Full Text
                </UploadMenuItem>
            </Menu.Item>

            {article.file && (
                <Menu.Item key="download" icon={<CloudDownloadOutlined />}>
                    Download Full Text
                </Menu.Item>
            )}
        </Menu>
    );

    return (
        <div className={classNames("card-menu", className)} {...props}>
            <div onClick={(e) => e.stopPropagation()}>
                <Dropdown overlay={actions} trigger="click" placement="bottomRight">
                    <Button type="icon" icon={<EllipsisOutlined />} />
                </Dropdown>
            </div>
        </div>
    );
});

export default ListActions;
