import React from "react";
import { observer } from "mobx-react";
import { Switch } from "antd";

import Group from "../group";

const ToggleReportDocuments = observer(({ config, readonly, onChange }) => {
    const toggle = (key, value) => {
        config[key] = value;

        const data = {
            enabled: config.enabled,
        };

        onChange && onChange(data);
    };

    return (
        <Group title="Report Documents Configuration">
            <div className="toggle">
                <div className="row items-center mb-15 ml-15">
                    <Switch
                        checked={config.enabled}
                        onChange={(value) => {
                            toggle("enabled", value);
                        }}
                        disabled={readonly}
                    />
                    <div className="col ml-15">Enable report document editing</div>
                </div>
            </div>
        </Group>
    );
});

export default ToggleReportDocuments;
