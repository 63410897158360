import React from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import Page from "@app/components/page/page";
import ReportGroup from "@app/components/setup/reports/group";
import notify from "@app/components/notify";

import setup from "@app/state/store/projects/setup";
import report from "@app/state/store/report";

const SetupReports = observer(() => {
    const history = useHistory();
    const config = setup.config;
    const readonly = report.readOnly || setup.isComplete;

    if (!config) {
        return;
    }

    const close = () => {
        history.push("../setup");
    };

    /**
     * Update the config value
     */
    const update = async (updates, args = {}) => {
        const reports = {
            ...config.reports,
            ...updates,
        };

        try {
            await setup.updateConfig({ reports }, { preview: true });
        } catch (ex) {
            const message = args.error || "Error saving the report configuration";
            notify.error(message);
        }
    };

    return (
        <Page>
            <Page.Header closable onClose={close}>
                <Page.Header.Left shrink>
                    <Page.Title>Reports</Page.Title>
                </Page.Header.Left>

                <Page.Header.Right></Page.Header.Right>
            </Page.Header>

            <Page.Body maxWidth={1200}>
                <ReportGroup
                    list={config.reports.slr}
                    title="DUE"
                    type="slr"
                    onChange={(list) => {
                        update({ slr: list });
                    }}
                    readonly={readonly}
                />
                {config.sota.workflowEnabled && (
                    <ReportGroup
                        list={config.reports.sota}
                        title="SoTA"
                        type="sota"
                        onChange={(list) => {
                            update({ sota: list });
                        }}
                        readonly={readonly}
                    />
                )}
            </Page.Body>
        </Page>
    );
});

export default SetupReports;
