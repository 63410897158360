import React from "react";
import { Button } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import Card from "@app/components/card/card";
import Logo from "./logo";

import "./style/card.scoped.scss";

export default function UserCard({
    client,
    onEdit,
    onRemove,
    canEdit,
    canRemove,
    children,
    ...rest
}) {
    /**
     * Handle edit button click
     * @param {MouseEvent} event
     */
    const edit = (event) => {
        event.preventDefault();
        if (onEdit) {
            onEdit(client, event);
        }
    };

    /**
     * Handle delete button click
     * @param {MouseEvent} event
     */
    const remove = (event) => {
        event.preventDefault();
        if (onRemove) {
            onRemove(client, event);
        }
    };

    return (
        <Card className="client-card" {...rest}>
            <div className="logo">
                <Logo client={client} size={60} />
            </div>
            <div className="details">
                <div className="name">{client.name}</div>
            </div>

            <div className="actions">
                {canEdit && <Button onClick={edit} type="icon" icon={<EditOutlined />}></Button>}
                {canRemove && (
                    <Button onClick={remove} type="icon" danger icon={<DeleteOutlined />}></Button>
                )}
            </div>
        </Card>
    );
}
