import React from "react";
import { observer } from "mobx-react";
import { v4 as uuid } from "uuid";

import configClipboard from "@app/state/store/config-clipboard";

import List from "../../list/ordered";
// import CopyConfig from "../../config-clipboard/copy";
import PasteConfig from "../../config-clipboard/paste";
import Group from "../group";
import Card from "./card";

import { useHistory } from "react-router-dom";
import { ReportTypes } from "@app/constants";

const ReportsGroup = observer(({ list, type, title, onChange, readonly = false }) => {
    const history = useHistory();

    /**
     * Handle the reorder event and save the list
     */
    const reorder = (action) => {
        let [item] = list.splice(action.from, 1);

        list.splice(action.to, 0, item);
        save();
    };

    /**
     * Handle the remove event and save the list
     */
    const remove = (report) => {
        const index = list.findIndex((entry) => {
            return entry._id === report._id;
        });

        if (index !== -1) {
            list.splice(index, 1);
            save();
        }
    };

    const onEdit = (report) => {
        history.push(`./reports/${type}/${report._id}`);
    };

    /**
     * Do the actual configuration update
     */
    const save = () => {
        onChange && onChange(list);
    };

    // const copy = () => {
    //     configClipboard.copy("reports", list);
    // };

    const paste = readonly
        ? undefined
        : () => {
              list.push(
                  ...configClipboard.paste("reports", (entry) =>
                      list.some((li) => li.reportName === entry.reportName),
                  ),
              );
              save();
          };

    /**
     * Allow adding additional data only if the widget is not in read-only mode
     */
    const addAdditionalData = readonly
        ? undefined
        : () => {
              history.push(`./reports/${type}/new`);
          };
    return (
        <>
            <Group
                title={title}
                className="reports"
                onAdd={addAdditionalData}
                // onCopyButton={
                //     <CopyConfig onClick={copy} tooltip={"Copy Reports"} disabled={!list.length} />
                // }
                onPasteButton={
                    !readonly && configClipboard.reports.length ? (
                        <PasteConfig onClick={paste} tooltip={"Paste Reports"} />
                    ) : null
                }
            >
                {list.length === 0 && (
                    <Group.Empty>
                        No report have been configured yet.
                        <br />
                        You can use the add button to add some.
                    </Group.Empty>
                )}

                <List onChange={reorder}>
                    {list.map((entry, index) => {
                        const id = entry._id || "new_" + uuid();
                        const canRemove = entry.reportType === ReportTypes.CUSTOM;

                        return (
                            <Card
                                index={index}
                                key={id}
                                value={entry}
                                onSave={save}
                                onRemove={remove}
                                onEdit={onEdit}
                                readonly={readonly}
                                canRemove={canRemove}
                            />
                        );
                    })}
                </List>
            </Group>
        </>
    );
});

export default ReportsGroup;
