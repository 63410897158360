import { useEffect, useMemo } from "react";
import { observer } from "mobx-react";

import nodePosition from "@app/state/store/report-document/node-position";
import { events } from "@app/lib/store";
import { ws } from "@app/lib/socket";
import sectionsStore from "@app/state/store/report-document/report-document-sections";
import sessionStore from "@app/state/store/session";

import { useHandleCommentAdded, useManageCommentNodes } from "./hooks";
import { removeCommentFromContent } from "./hooks/util";

const CommentListener = observer(
    ({ editor, sectionId, section, sectionComments, focusedComment, readonly }) => {
        const needToBeApplied = useMemo(() => {
            const danglingComments = sectionComments.filter((comment) => !comment.isParsed);
            return danglingComments.filter((comment) => !nodePosition.applied[comment._id]);
        }, [sectionComments, sectionId, focusedComment, nodePosition.applied, nodePosition.nodes]);

        useHandleCommentAdded({ editor, sectionId });
        useManageCommentNodes({
            editor,
            sectionComments,
            needToBeApplied,
            focusedCommentId: focusedComment,
            section,
        });

        useEffect(() => {
            const handler = (comment) => {
                try {
                    removeCommentFromContent(editor, comment._id);
                } catch (ex) {
                    // catch an exception while fail to delete a comment
                }
            };

            events.on("comments.remove", handler);

            return () => {
                events.off("comments.remove", handler);
            };
        }, []);

        useEffect(() => {
            if (editor) {
                const refetchSection = async (update) => {
                    if (sectionId === update.sectionId && update.userId !== sessionStore.user._id) {
                        const section = await sectionsStore.refetch(update);

                        try {
                            editor.commands.setContent(section.content);
                        } catch (ex) {
                            // no action
                        }
                    }
                };

                ws.on("reportDocument.section.content.updated", refetchSection);
                return () => {
                    ws.off("reportDocument.section.content.updated", refetchSection);
                };
            }
        }, []);

        useEffect(() => {
            if (editor) {
                nodePosition.redraw(editor);
            }
        }, [readonly]);

        return null;
    },
);

export default CommentListener;
