import React from "react";

export default function () {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.0"
            width="200.000000pt"
            height="200.000000pt"
            viewBox="0 0 200.000000 200.000000"
            preserveAspectRatio="xMidYMid meet"
        >
            <g
                transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
                fill="currentColor"
                stroke="none"
            >
                <path d="M447 1882 c-15 -17 -17 -52 -17 -345 0 -351 0 -349 53 -362 12 -3 320 -4 685 -3 644 3 664 4 678 22 11 16 14 80 14 346 0 304 -1 328 -18 343 -22 20 -83 22 -100 5 -9 -9 -12 -88 -12 -295 l0 -283 -580 0 -580 0 0 268 c0 306 -2 315 -66 320 -29 2 -45 -2 -57 -16z" />
                <path d="M152 1188 c-17 -17 -17 -359 0 -376 7 -7 23 -12 37 -12 34 0 201 166 201 200 0 34 -167 200 -201 200 -14 0 -30 -5 -37 -12z" />
                <path d="M688 1039 c-25 -14 -24 -65 1 -79 11 -5 43 -10 71 -10 67 0 90 13 90 50 0 19 -7 33 -19 40 -25 13 -121 13 -143 -1z" />
                <path d="M942 1038 c-15 -15 -15 -61 0 -76 7 -7 39 -12 74 -12 66 0 94 15 94 50 0 35 -28 50 -94 50 -35 0 -67 -5 -74 -12z" />
                <path d="M1196 1034 c-9 -8 -16 -24 -16 -34 0 -36 28 -50 95 -50 58 0 66 3 81 25 15 23 15 27 0 50 -15 22 -23 25 -81 25 -44 0 -68 -5 -79 -16z" />
                <path d="M1458 1039 c-25 -14 -24 -65 1 -79 11 -5 43 -10 71 -10 67 0 90 13 90 50 0 19 -7 33 -19 40 -25 13 -121 13 -143 -1z" />
                <path d="M470 823 c-38 -14 -40 -28 -40 -363 0 -309 1 -328 19 -344 13 -12 32 -16 57 -14 62 5 64 16 64 321 l0 267 580 0 580 0 0 -283 c0 -207 3 -286 12 -295 19 -19 82 -15 101 6 15 17 17 55 17 343 0 265 -3 329 -14 345 -14 18 -34 19 -688 21 -370 1 -680 -1 -688 -4z" />
            </g>
        </svg>
    );
}
