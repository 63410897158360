import React from "react";
import { observer, useLocalStore } from "mobx-react";
import { v4 as uuid } from "uuid";

import configClipboard from "@app/state/store/config-clipboard";
import notify from "@app/components/notify";

import List from "../../list/ordered";
import CopyConfig from "../../config-clipboard/copy";
import PasteConfig from "../../config-clipboard/paste";
import Group from "../group";
import Question from "./card";
import Form from "./form";
import liveWarn from "../warn";

const Questions = observer(
    ({ list, useScore = false, readonly = false, onChange, warn = true }) => {
        const warnFn = warn ? liveWarn : (msg, cb) => cb();

        const state = useLocalStore(() => ({
            edit: false,
            question: undefined,
        }));

        /**
         * Handle the reorder event and save the list
         */
        const reorder = (action) => {
            warnFn(liveWarn.review, () => {
                let [item] = list.splice(action.from, 1);

                list.splice(action.to, 0, item);
                save();
            });
        };

        /**
         * Handle the remove event and save the list
         */
        const remove = (question) => {
            // check if any of the questions depend on this one
            const linked = list.find((entry) => {
                return entry.conditional && entry.condition?.question === question._id;
            });

            if (linked) {
                notify.error(
                    "Some conditional questions depend on this one. " +
                        "Please remove them before removing this one.",
                );
                return;
            }

            const index = list.findIndex((entry) => {
                return entry._id === question._id;
            });

            if (index !== -1) {
                warnFn(liveWarn.review, () => {
                    list.splice(index, 1);
                    save();
                });
            }
        };

        /**
         * Handle the edit canceled event and hide the form
         */
        const onCancel = () => {
            state.edit = false;
            state.question = undefined;
        };

        /**
         * Handle the save event from an edit or an add operation
         */
        const onSave = (entry) => {
            state.edit = false;
            state.question = undefined;

            // add operation
            if (!entry._id) {
                list.push(entry);
            } else {
                const existing = list.find((el) => el._id === entry._id);
                Object.assign(existing, entry);
            }

            save();
        };

        /**
         * Do the actual configuration update
         */
        const save = () => {
            onChange && onChange(list);
        };

        const copy = () => {
            configClipboard.copy("questions", list);
        };

        const paste = () => {
            warnFn(liveWarn.review, () => {
                list.push(
                    ...configClipboard.paste("questions", (entry) =>
                        list.some((li) => li.question === entry.question),
                    ),
                );
                save();
            });
        };

        /**
         * Allow adding questions only if the widget is not in read-only mode
         */
        const addQuestion = readonly
            ? undefined
            : () => {
                  warnFn(liveWarn.review, () => {
                      state.edit = true;
                      state.question = undefined;
                  });
              };

        return (
            <>
                <Group
                    title="List of questions for article evaluation"
                    className="questions"
                    onAdd={addQuestion}
                    onCopyButton={
                        <CopyConfig
                            onClick={copy}
                            tooltip={"Copy Questions"}
                            disabled={!list.length && !readonly}
                        />
                    }
                    onPasteButton={
                        configClipboard.questions.length && !readonly ? (
                            <PasteConfig onClick={paste} tooltip={"Paste Questions"} />
                        ) : null
                    }
                >
                    {list.length === 0 && (
                        <Group.Empty warn={true}>
                            There are no configured questions.
                            <br />
                            Please use the add button to add some.
                        </Group.Empty>
                    )}

                    <List onChange={reorder}>
                        {list.map((entry, index) => {
                            const id = entry._id || "new_" + uuid();

                            return (
                                <Question
                                    question={entry}
                                    index={index}
                                    key={id}
                                    value={entry}
                                    onSave={save}
                                    onRemove={remove}
                                    onEdit={() => {
                                        warnFn(liveWarn.review, () => {
                                            state.edit = true;
                                            state.question = entry;
                                        });
                                    }}
                                    readonly={readonly}
                                    useScore={useScore}
                                />
                            );
                        })}
                    </List>
                </Group>

                {state.edit && (
                    <Form
                        question={state.question}
                        existing={list}
                        onSave={onSave}
                        onCancel={onCancel}
                        useScore={useScore}
                    />
                )}
            </>
        );
    },
);

export default Questions;
