const hasMenuItems = (menu) => {
    let hasItems = false;

    if (menu?.props?.children?.length > 0) {
        for (const c of menu.props.children) {
            if (c) {
                hasItems = true;
                break;
            }
        }
    } else if (menu?.props?.children) {
        hasItems = true;
    }

    return hasItems;
};

export default { hasMenuItems };
