import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { default as qs } from "qs";

import { publish } from "../../../util/events";

export const EventPublisher = () => {
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
        const { node, ...otherParams } = queryParams;
        const newSearch = qs.stringify(otherParams, { addQueryPrefix: true });

        if (node) {
            publish("scrollToNode", node);

            history.replace({
                pathname: location.pathname,
                search: newSearch,
            });
        }
    }, [location]);

    return null;
};

export default EventPublisher;
