import React from "react";
import { observer } from "mobx-react";
import { Tabs, Select } from "antd";

import { createState } from "@app/components/setup/report-documents/structure/context";
import sectionsStore from "@app/state/store/report-document/report-document-sections";

import "./version-sidebar.scoped.scss";

import List from "./list";

const ALL_TAB = "all";
const USER_TAB = "user";

const VersionsSidebar = observer(({ state, readonly }) => {
    const [activeKey, setActiveKey] = React.useState(ALL_TAB);
    const [sectionId, setSectionId] = React.useState(0);
    const [userId, setUserId] = React.useState(0);
    const tocState = createState();
    const { sections } = sectionsStore;
    const tree = tocState.convertTreeToFlatData(tocState.convertTOCToTree(sections), {});
    const sectionPosMap = new Map();

    tree.forEach(({ _id, displayPos }) => {
        sectionPosMap.set(_id, displayPos);
    });

    const { versionId, sectionMenuValues: menuListValues, sectionUserMenuValues } = state;

    const sectionMenuValues = menuListValues.map(({ value, label }) => ({
        value,
        label: `${sectionPosMap.get(value)} ${label}`,
    }));

    const switchTab = (key) => {
        setActiveKey(key);
        if (state.onTabSwitch) {
            state.onTabSwitch(key);
        }
    };

    const options = [
        { value: 0, label: "Show all sections" },
        {
            label: "Show changes per section:",
            options: sectionMenuValues,
        },
    ];

    const optionsUsers = [
        { value: 0, label: "Show all users" },
        {
            label: "Show changes made by user:",
            options: sectionUserMenuValues,
        },
    ];

    const onSelectFilterBySection = (sectionId) => {
        setSectionId(sectionId);
    };

    const onSelectFilterByUser = (userId) => {
        setUserId(userId);
    };

    return (
        <div className="versions sidebar">
            <Tabs size="small" activeKey={activeKey} onTabClick={switchTab}>
                <Tabs.TabPane tab="All changes" key={ALL_TAB}></Tabs.TabPane>
                <Tabs.TabPane tab="My changes" key={USER_TAB}></Tabs.TabPane>
            </Tabs>
            <Select
                placeholder="Show all sections"
                mode="single"
                maxTagCount={2}
                autoClearSearchValue={false}
                className="select"
                value={sectionId}
                options={options}
                onSelect={onSelectFilterBySection}
            />
            {activeKey === ALL_TAB && (
                <Select
                    placeholder="Show all users"
                    mode="single"
                    maxTagCount={2}
                    autoClearSearchValue={false}
                    className="user-select"
                    value={userId}
                    options={optionsUsers}
                    onSelect={onSelectFilterByUser}
                />
            )}
            <List
                state={state}
                versions={state.versions}
                sectionMap={sectionMenuValues}
                sectionId={sectionId}
                userId={activeKey === ALL_TAB ? userId : state.userId}
                activeTab={activeKey}
                selectedVersionId={versionId}
                readonly={readonly}
            />
        </div>
    );
});

export default VersionsSidebar;
