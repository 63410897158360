import React from "react";
import { observer } from "mobx-react";

import { Button, Tag } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import confirm from "../../confirm";
import Group from "../group";
import Locked from "../locked";

import "./style/tag.scoped.scss";

const TagCard = observer(({ value, readonly = false, onRemove, onEdit }) => {
    /**
     * Handle edit button click
     */
    const edit = (event) => {
        event.preventDefault();
        onEdit && onEdit(value);
    };

    /**
     * Handle delete button click
     */
    const remove = async (event) => {
        event.preventDefault();

        let proceed = await confirm("Are you sure you want to remove this entry?");
        if (proceed) {
            onRemove && onRemove(value, event);
        }
    };

    return (
        <Group.Item order={false}>
            <div className="tag-card">
                <div className="color-tag">
                    <Tag className="color" color={value.color}>
                        &nbsp;
                    </Tag>
                </div>
                <div className="text">{value.label}</div>

                {!readonly && (
                    <div className="actions">
                        <div className="icons">
                            <Button onClick={edit} type="icon" icon={<EditOutlined />}></Button>

                            {value.system && <Locked />}
                            {!value.system && (
                                <Button
                                    onClick={remove}
                                    type="icon"
                                    danger
                                    icon={<DeleteOutlined />}
                                ></Button>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </Group.Item>
    );
});

export default TagCard;
