import { extendObservable } from "mobx";
import { events } from "@app/lib/store";

/**
 * Base store class
 */
export default class BaseStore {
    constructor() {
        // increase the max event listeners because every state is attaching an
        // event listener on the logout event
        events.setMaxListeners(events.getMaxListeners() + 1);

        if (this.observable instanceof Function) {
            let data = this.observable();
            extendObservable(this, data);

            // reset the store
            events.on("auth.logout", this.reset.bind(this));
        }
    }

    reset() {
        let data = this.observable();
        for (let key in data) {
            this[key] = data[key];
        }
    }
}
