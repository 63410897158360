import React from "react";
import { observer } from "mobx-react";
import { Select } from "antd";
import Group from "./group";

import "./style/multi-select.scss";

const MultiSelect = observer(
    ({ filter, filterKey, name, title, options = [], disabled, collapsed, groupKey }) => {
        let filterContent = filter.state.filter;
        if (filterKey) {
            filterContent = filterContent[filterKey] || {};
        }

        let values = filterContent[name] ?? [];
        if (!(values instanceof Array)) {
            values = [values];
        }

        /**
         * Update the selected values
         */
        const update = (values) => {
            filter.filter(name, values, { filterKey });
        };

        /**
         * Remove selected item
         */
        const remove = (value) => {
            values = values.filter((el) => {
                return el !== value;
            });

            filter.filter(name, values, { filterKey });
        };

        const optionElements = [];
        const optionMap = {};
        for (const index in options) {
            const { label, value, separator } = options[index];

            if (separator) {
                optionElements.push(
                    <Select.Option
                        key={`selectOption-${index}`}
                        value=""
                        className="separator"
                        disabled
                    />,
                );
            } else {
                optionMap[value] = label;
                optionElements.push(
                    <Select.Option key={`selectOption-${index}`} value={value}>
                        {label}
                    </Select.Option>,
                );
            }
        }

        return (
            <Group
                className="multi-select-filter"
                title={title}
                hasActiveFilters={values.length}
                collapsed={collapsed}
                groupKey={groupKey ?? name}
            >
                <Select
                    mode="multiple"
                    allowClear
                    value={values}
                    onChange={update}
                    className="select"
                    dropdownClassName="multi-select-filter-dropdown"
                    placeholder="Pick an item"
                    filterOption={(filter, option) => {
                        if (!option.children) {
                            return false;
                        }

                        let label = option.children.toLowerCase();
                        return label.indexOf(filter.toLowerCase()) > -1;
                    }}
                    maxTagCount={0}
                    showArrow
                    disabled={disabled}
                >
                    {optionElements}
                </Select>
                <div className="selected">
                    {values.map((value) => {
                        return (
                            <div className="option" key={value}>
                                <em>{optionMap[value]}</em>
                                {!disabled && (
                                    <i className="remove" onClick={() => remove(value)}>
                                        x
                                    </i>
                                )}
                            </div>
                        );
                    })}
                </div>
            </Group>
        );
    },
);

export default MultiSelect;
