import { observable, computed } from "mobx";
import Model from "@app/state/model/base";

import report from "../store/report";

export const DEFAULT_COLOR = "#ea74b0";

export default class Highlight extends Model {
    @observable _id = undefined;
    @observable project = undefined;
    @observable content = undefined;
    @observable author = {
        _id: undefined,
        name: undefined,
    };
    @observable tags = [];
    @observable article = undefined;
    @observable data = {};
    @observable created = undefined;

    @computed get color() {
        if (report?.config?.tags) {
            let tag = report.config?.contentTags?.find((tag) => this.tags.indexOf(tag.label) >= 0);
            if (tag) {
                return tag.color;
            }
        }

        // return the default color
        return DEFAULT_COLOR;
    }

    @computed get rgbColor() {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(this.color);
        return result
            ? {
                  r: parseInt(result[1], 16),
                  g: parseInt(result[2], 16),
                  b: parseInt(result[3], 16),
              }
            : null;
    }
}
