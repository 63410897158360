import React from "react";
import { observer, useLocalStore } from "mobx-react";
import classNames from "classnames";
import {
    EditOutlined,
    DeleteOutlined,
    FileImageOutlined,
    CloudSyncOutlined,
} from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import UploadButton from "@app/components/form/upload/button";
import { MarkdownViewer, MarkdownEditor } from "@app/components/markdown-editor";
import HighlightModal from "@app/components/highlight/modal";
import Highlighter from "@app/components/highlight/highlighter";
import Highlight from "@app/state/model/highlight";
import confirm from "@app/components/confirm";
import ClipBoardButton from "@app/components/form/upload/clipBoardButton";

import "./style/abstract.scoped.scss";

/**
 * Render the article abstract
 */
const Abstract = observer(
    ({
        abstract,
        processing,
        className,
        onChange,
        onHighlight,
        keywords,
        readonly = false,
        ...rest
    }) => {
        var state = useLocalStore(() => ({
            abstract: "",
            draft: "",
            edit: false,
            dirty: false,
            highlight: null,
        }));

        /**
         * Update an image for OCR
         */
        const ocr = async (file) => {
            if (abstract) {
                let proceed = await confirm(
                    "Are you sure you want to replace the existing abstract?",
                );
                if (!proceed) {
                    return;
                }
            }

            onChange && onChange({ abstractImage: file._id });
        };

        /**
         * Handle the edit abstract event
         */
        const edit = () => {
            state.draft = abstract;
            state.edit = true;
            state.dirty = false;
        };

        /**
         * Handle the save abstract event
         */
        const save = async () => {
            if (state.draft !== abstract) {
                onChange && onChange({ abstract: state.draft });
            }

            state.draft = "";
            state.edit = false;
        };

        /**
         * Handle the abstract remove
         */
        const remove = async (event) => {
            let proceed = await confirm("Are you sure you want to remove this abstract?");
            if (proceed) {
                state.draft = "";
                save();
            }
        };

        /**
         * Handle the abstract change event while editing
         */
        const update = (text) => {
            state.draft = text;
            state.dirty = true;
        };

        /**
         * Cancel the abstract editing
         */
        const onCancel = () => {
            state.draft = "";
            state.dirty = false;
            state.edit = false;
        };

        /**
         * Handle the highlight event
         */
        const addHighlight = (text) => {
            let hl = new Highlight();
            hl.content = text;
            hl.source = "text";

            state.highlight = hl;
        };

        return (
            <div className={classNames("group abstract", className)} {...rest}>
                <div className="header">
                    <div className="title">Abstract</div>
                    <div className="icons">
                        {processing && (
                            <Tooltip title="Extracting the abstract from the uploaded file">
                                <CloudSyncOutlined className="processing" />
                            </Tooltip>
                        )}
                        {!processing && !state.edit && !readonly && (
                            <>
                                <ClipBoardButton handleItem={ocr} />
                                <UploadButton
                                    type="icon"
                                    accept="image/*"
                                    onChange={ocr}
                                    icon={<FileImageOutlined />}
                                ></UploadButton>
                                <Button type="icon" onClick={edit} icon={<EditOutlined />}></Button>
                                {abstract && (
                                    <Button
                                        type="icon"
                                        onClick={remove}
                                        icon={<DeleteOutlined />}
                                    ></Button>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div className="content">
                    {state.edit && (
                        <MarkdownEditor content={abstract} onChange={update}>
                            <div className="buttons button-group">
                                <Button type="primary" onClick={save}>
                                    Save
                                </Button>
                                <Button onClick={onCancel}>Cancel</Button>
                            </div>
                        </MarkdownEditor>
                    )}

                    {!state.edit && (
                        <>
                            {abstract && (
                                <Highlighter onAddHighlight={addHighlight} disable={readonly}>
                                    <MarkdownViewer content={abstract} keywords={keywords} />
                                </Highlighter>
                            )}
                            {!abstract && <div className="missing">Abstract is missing</div>}
                        </>
                    )}
                </div>

                <HighlightModal
                    visible={!!state.highlight}
                    highlight={state.highlight}
                    onSave={(hl) => {
                        onHighlight && onHighlight(hl);
                        state.highlight = null;
                    }}
                    onDiscard={() => {
                        state.highlight = null;
                    }}
                />
            </div>
        );
    },
);

export default Abstract;
