import { computed, action } from "mobx";
import { v4 as uuid } from "uuid";
import BaseStore from "../base";
import { events } from "@app/lib/store";
import http from "@app/lib/http";
import User from "@app/state/model/user";
import Client from "@app/state/model/client";
import notify from "@app/components/notify";

const STAGGER_ID = uuid();

/**
 * State management controlling the list of users in
 * the user management sections
 */
export class UserDetails extends BaseStore {
    constructor() {
        super();
        events.on("user.reload", () => this.load());
    }

    /**
     * Observable store data
     */
    observable() {
        return {
            loading: false,
            saving: false,
            data: null,
            clients: [],
        };
    }

    @computed get busy() {
        return this.loading || this.saving;
    }

    /**
     * Invite user
     */
    @action
    async invite(params) {
        this.saving = true;

        try {
            await http.post(`/user/invite`, params);

            // emit the invite event
            events.emit("user.invite", this.data);
            const found = params?.users.find((user) => user.email === this.data?.email);
            if (found) {
                this.data = null;
                events.emit("user.reload");
            }
            return true;
        } catch (ex) {
            notify.error(ex.response?.data?.error || "An error occurred while trying to invite");
            return false;
        } finally {
            this.saving = false;
        }
    }

    /**
     * Load user details
     */
    @action
    async load(id) {
        // do not load the user data twice
        if (this.data?._id === id || this.loading) {
            return;
        }

        this.loading = true;
        this.data = null;

        let { data } = await http.get(`/user/${id}`).stagger(STAGGER_ID);
        if (data._id) {
            this.data = new User(data);
        }

        this.loading = false;
    }

    /**
     * Get user details
     */
    @action
    async get(id) {
        let { data } = await http.get(`/user/${id}`);
        if (data._id) {
            return data;
        }
    }

    /**
     * Update user details
     */
    @action
    async update(params) {
        if (!this.data || this.saving) {
            return;
        }

        this.saving = true;

        try {
            let result;
            if (this.data._id) {
                result = await http.post(`/user/${this.data._id}`, params);
            } else {
                result = await http.put(`/user`, params);
            }

            this.data = new User(result.data);

            // emit the update event
            events.emit("user.update", this.data);
            return true;
        } catch (ex) {
            notify.error(
                ex.response?.data?.error || "An error occurred while trying to update the details",
            );
            return false;
        } finally {
            this.saving = false;
        }
    }

    /**
     * Remove the user
     */
    @action
    async remove() {
        if (!this.data || this.saving) {
            return;
        }

        this.saving = true;
        await http.delete(`/user/${this.data._id}`);

        // emit the delete event
        events.emit("user.delete", this.data);

        this.data = null;
        this.saving = false;
    }

    /**
     * Create a new user
     */
    @action
    create() {
        if (!this.data || this.data._id !== undefined) {
            this.data = new User();
        }
    }

    /**
     * Load the list of clients
     */
    @action
    async loadClients() {
        let { data } = await http.get("/clients");
        this.clients = data.list.map((entry) => {
            return new Client(entry);
        });
    }
}

export default new UserDetails();
