import React from "react";
import { observer } from "mobx-react";
import { ReportDocumentStatus, ReportDocumentReviewerStatus } from "@app/constants";
import { Progress, Tooltip } from "antd";
import Avatar from "@app/components/user/avatar";

import "../styles/status.scoped.scss";

const Status = observer(({ reportDocument }) => {
    const getStatus = () => {
        if (reportDocument.review?.stage?.title) {
            return "Review: " + reportDocument.review.stage.title;
        } else {
            if (ReportDocumentStatus.IN_PROGRESS === reportDocument.status) {
                return "Drafting";
            } else if (ReportDocumentStatus.COMPLETED === reportDocument.status) {
                return "Complete";
            } else {
                return reportDocument.status;
            }
        }
    };

    return <>{getStatus()}</>;
});

const Stage = observer(({ reportDocument, reportDocumentStatus }) => {
    const { currentStage } = reportDocumentStatus;

    return (
        currentStage && (
            <div className="stage">
                <span>{reportDocument.review.stage.title}</span>
                <span> Review </span>
                <span>
                    {currentStage.stagePos}/{reportDocumentStatus.totalStages}
                </span>
            </div>
        )
    );
});

const StageProgress = observer(({ reportDocumentStatus = {} }) => {
    const getPercent = () => {
        const { currentStage } = reportDocumentStatus;
        if (currentStage) {
            return Math.round(
                (currentStage.totalSectionsApproved /
                    (currentStage.reviewers.length * reportDocumentStatus.totalSections)) *
                    100,
            );
        } else {
            return Math.round(
                (reportDocumentStatus.totalSectionsReadyForReview /
                    reportDocumentStatus.totalSections) *
                    100,
            );
        }
    };

    const percentage = getPercent();

    return (
        <>
            {percentage === 100 ? (
                <span style={{ color: "#52c41a" }}>Completed</span>
            ) : (
                <Progress
                    className="stageProgress"
                    percent={percentage}
                    size="small"
                    strokeColor="#52c41a"
                />
            )}
        </>
    );
});

const mapTeamStatus = (team = [], reportDocument) => {
    return team.map((user) => {
        if (reportDocument.status === ReportDocumentStatus.NOT_STARTED) {
            user.status = ReportDocumentReviewerStatus.NOT_STARTED;
        } else if (reportDocument.status === ReportDocumentStatus.IN_PROGRESS) {
            user.status = ReportDocumentReviewerStatus.IN_PROGRESS;
        } else if (reportDocument.status === ReportDocumentStatus.REVIEWED) {
            user.status = ReportDocumentReviewerStatus.REVIEWED;
        }

        return user;
    });
};

const AssignedUsers = observer(({ users = [] }) => {
    const getAvatarStyle = (reviewer) => {
        switch (reviewer.status) {
            case ReportDocumentReviewerStatus.NOT_STARTED:
                return "not-reviewed";
            case ReportDocumentReviewerStatus.IN_PROGRESS:
                return "review-in-progress";
            case ReportDocumentReviewerStatus.REVIEWED:
                return "reviewed";
            default:
                return "";
        }
    };

    return (
        users.length > 0 && (
            <div className={"reviewers"}>
                {users
                    .filter((user) => user._id)
                    .slice()
                    .sort((a, b) => {
                        // sort in order of reviewed, in-progress and not started
                        const priority = {
                            [ReportDocumentReviewerStatus.NOT_STARTED]: 1,
                            [ReportDocumentReviewerStatus.IN_PROGRESS]: 2,
                            [ReportDocumentReviewerStatus.REVIEWED]: 3,
                        };

                        if (priority[a.status] < priority[b.status]) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })

                    .map((user) => (
                        <Tooltip title={user.fullName} key={user._id}>
                            <Avatar
                                className={`avatar ${getAvatarStyle(user)}`}
                                user={user}
                                size={30}
                            />
                        </Tooltip>
                    ))}
            </div>
        )
    );
});

export default { Status, Stage, StageProgress, AssignedUsers, mapTeamStatus };
