import { observable } from "mobx";
import Model from "@app/state/model/base";

export default class VersionAlias extends Model {
    @observable _id = undefined;
    @observable versionName = undefined;
    @observable documentId = undefined;
    @observable versionId = undefined;
    @observable versionDate = undefined;
    @observable createdBy = {
        _id: undefined,
        fullName: undefined,
        avatar: undefined,
    };
    @observable updatedAt = undefined;
}
