import React from "react";
import { Tooltip } from "antd";
import { LockOutlined } from "@ant-design/icons";

import "./style/locked.scoped.scss";

const Locked = () => {
    return (
        <div className="locked">
            <Tooltip title="This is a system resource and removing it is not allowed.">
                <LockOutlined className="icon" />
            </Tooltip>
        </div>
    );
};

export default Locked;
