import React from "react";
import { observer } from "mobx-react";

import versionAliasStore from "@app/state/store/report-document/version-alias";

import List from "./list";

const VersionAliasSidebar = observer(() => {
    const { isLoading } = versionAliasStore;

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return <List />;
});

export default VersionAliasSidebar;
