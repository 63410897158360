import { action, computed } from "mobx";
import BaseStore from "../../base";
import http from "@app/lib/http";
import TplcReport from "@app/state/model/tplc-report";

import report from "../../report";
import notify from "@app/components/notify/index";

/**
 * State management controlling the list of TPLC reports rendered
 */
export class TPLC extends BaseStore {
    /**
     * Observable store data
     */
    observable() {
        return {
            reports: [],
            loading: false,
        };
    }

    /**
     * Return the project id if the currently loaded project
     */
    @computed get project() {
        return report.id;
    }

    /**
     * Load the set of TPLC reports that exist for the current projects
     */
    @action
    async load() {
        this.loading = true;

        let { data } = await http.get(`/project/${this.project}/tplc`);
        this.reports = data.list.map((entry) => {
            return new TplcReport(entry);
        });

        this.loading = false;
    }

    /**
     * Generates a new set of TPLC reports for the current projects
     */
    @action
    async generate(config) {
        this.loading = true;

        try {
            let { data } = await http.post(`/project/${this.project}/tplc/generate`, config);
            this.reports = data.map((entry) => {
                return new TplcReport(entry);
            });
        } catch (ex) {
            notify.error(ex.response?.data?.error || "Unable to generate the report.");
        } finally {
            this.loading = false;
        }
    }

    /**
     * Retrieves the set of adverse events contained within a TPLC report as an Excel file
     */
    @action
    async export(id) {
        let { data } = await http.fetchRawFileContent(`/project/${this.project}/tplc/${id}/export`);
        return data;
    }

    @action
    async options() {
        this.loading = true;
        const { data } = await http.get(`/project/${this.project}/tplc/options`);
        this.loading = false;
        return data;
    }
}

export default new TPLC();
