import { computed, action } from "mobx";
import http from "@app/lib/http";
import BaseStore from "@app/state/store/base";
import report from "@app/state/store/report";
import ProjectMappingEntry from "@app/state/model/project/project-mapping";

export class ProjectMapping extends BaseStore {
    @computed get project() {
        return report.id;
    }

    @action
    async get(args) {
        let { data } = await http.post(`/project/${this.project}/mapping`, args);
        if (data) {
            return new ProjectMappingEntry(data);
        } else {
            return null;
        }
    }
}

export default new ProjectMapping();
