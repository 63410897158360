import React, { useState, useEffect, useRef } from "react";
import { observer, useObserver } from "mobx-react";
import { v4 as uuid } from "uuid";
import { Tooltip } from "antd";
import { NodeViewWrapper } from "@tiptap/react";
import { TextSelection } from "@tiptap/pm/state";
import cn from "classnames";

import commentStore from "@app/state/store/report-document/comment";

import "./style.scss";

export default observer(({ editor, node, extension, getPos }) => {
    const ref = useRef();
    const store = extension.storage.store;
    const nodeId = useState(uuid());
    const { comment } = node.attrs;
    const isHighlighted = comment && comment.includes(commentStore.focusedComment);
    const status =
        commentStore.focusedComment &&
        commentStore.comments.find((c) => c._id === commentStore.focusedComment)?.status;
    // re-render the component upon store change
    useObserver(() => store.entries);

    const handleContextMenu = (event) => {
        event.preventDefault();
        if (node.type.spec.selectable) {
            editor.chain().focus().setNodeSelection(getPos()).run();
        }
    };

    const handleMouseDown = (event) => {
        event.preventDefault();

        const { view } = editor;
        const { tr } = view.state;

        const from = getPos();
        const to = getPos() + node.nodeSize;

        tr.setSelection(new TextSelection(tr.doc.resolve(from), tr.doc.resolve(to)));
        view.dispatch(tr);
    };

    useEffect(() => {
        if (!editor) {
            return;
        }

        editor.on("removeAbbreviation", remove);

        return () => editor.off("removeAbbreviation", remove);
    }, [editor]);

    useEffect(() => {
        if (ref.current && editor) {
            const nodeRef = ref.current;
            nodeRef.addEventListener("contextmenu", handleContextMenu);
            nodeRef.addEventListener("mousedown", handleMouseDown);

            return () => {
                nodeRef.removeEventListener("contextmenu", handleContextMenu);
                nodeRef.removeEventListener("mousedown", handleMouseDown);
            };
        }
    }, [ref.current, editor]);

    // do not render the node in case if missing id
    const key = node.attrs.key;
    if (!key) {
        return null;
    }

    // do not render the node if we don't have a abbreviation entry
    const entry = store.use(key, nodeId);
    const invalid = !entry;

    /**
     * Remove the entry
     */

    const remove = ({ node }) => {
        if (node.attrs.key === key) {
            const from = getPos();
            const to = from + node.nodeSize;

            editor.chain().deleteRange({ from, to }).focus().run();
        }
    };

    const Entry = observer(({ entry }) => {
        return (
            <Tooltip title={entry.text} placement="top">
                {entry.name}
            </Tooltip>
        );
    });

    if (invalid) {
        return (
            <NodeViewWrapper
                ref={ref}
                className={cn({
                    "abbreviation-entry": true,
                    invalid: true,
                    pending: isHighlighted && status === "pending",
                    resolved: isHighlighted && status === "resolved",
                })}
            >
                {key}
            </NodeViewWrapper>
        );
    }

    return (
        <NodeViewWrapper
            ref={ref}
            className={cn({
                "abbreviation-entry": true,
                pending: isHighlighted && status === "pending",
                resolved: isHighlighted && status === "resolved",
            })}
        >
            <Entry entry={entry} />
        </NodeViewWrapper>
    );
});
