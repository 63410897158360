import React from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";

import { Button } from "antd";

const Error = observer(({ state }) => {
    return (
        <>
            <p>Please use the link below to start the password recovery process again.</p>

            <Link to="./password">
                <Button type="primary">Recover Your Password</Button>
            </Link>
        </>
    );
});

export default Error;
