import { observable } from "mobx";
import Model from "@app/state/model/base";

export default class ChatHistory extends Model {
    @observable _id = undefined;
    @observable project = undefined;
    @observable source = {
        _id: undefined,
        type: undefined,
        fileId: undefined,
    };
    @observable user = {
        _id: undefined,
        fullName: undefined,
        avatar: undefined,
    };
    @observable role = undefined;
    @observable content = undefined;
    @observable answer = undefined;
    @observable created = undefined;
}
