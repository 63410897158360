import React from "react";
import { observer } from "mobx-react";
import Filter from "@app/components/filter";
import Sidebar from "@app/components/filter/sidebar";
import { CategoryStatus } from "@app/constants";

const CategoryFilter = observer(({ filter }) => {
    let facets = filter.state.stats?.facets || {};

    let status = facets.status || {};

    return (
        <Sidebar filter={filter}>
            <Filter.OptionGroup filter={filter} title="Category Status" radio name="status">
                <Filter.Option
                    label="Approved"
                    value={CategoryStatus.APPROVED}
                    count={status.approved ?? 0}
                />
                <Filter.Option
                    label="Pending"
                    value={CategoryStatus.PENDING}
                    count={status.pending ?? 0}
                />
            </Filter.OptionGroup>
        </Sidebar>
    );
});

export default CategoryFilter;
