import React from "react";
import { observer, useLocalStore } from "mobx-react-lite";
import { Button, Tooltip } from "antd";
import {
    EditOutlined,
    DeleteOutlined,
    CheckCircleOutlined,
    RightOutlined,
    DownOutlined,
    WarningOutlined,
    BranchesOutlined,
} from "@ant-design/icons";
import { MarkdownViewer } from "@app/components/markdown-editor";
import classNames from "classnames";

import Group from "../group";
import confirm from "@app/components/confirm";

import "./style/card.scoped.scss";

const Question = observer(
    ({ question, index, readonly = false, useScore = false, onEdit, onRemove }) => {
        const state = useLocalStore(() => ({
            expanded: false,

            toggle() {
                this.expanded = !this.expanded;
            },
        }));

        const invalid = question.answers.some((element) => {
            return useScore && element.requireScore && element.score === undefined;
        });

        /**
         * Handle edit button click
         * @param {MouseEvent} event
         */
        const edit = (event) => {
            event.preventDefault();
            event.stopPropagation();

            onEdit && onEdit(question, event);
        };

        /**
         * Handle delete button click
         * @param {MouseEvent} event
         */
        const remove = async (event) => {
            event.preventDefault();
            event.stopPropagation();

            let proceed = await confirm("Are you sure you want to remove this question?");
            if (proceed) {
                onRemove && onRemove(question, event);
            }
        };

        return (
            <Group.Item id={question._id || "new"} index={index} order={!readonly}>
                <div
                    className={classNames("question", {
                        expanded: state.expanded,
                        [question.color]: question.color,
                    })}
                    onClick={() => state.toggle()}
                >
                    <div className="row main">
                        {invalid && (
                            <div className="col warn">
                                <Tooltip
                                    placement="topLeft"
                                    title="Some answers are missing required values. Please edit the question to correct the problem."
                                >
                                    <WarningOutlined />
                                </Tooltip>
                            </div>
                        )}

                        <div className="col expand">
                            <Button
                                type="icon"
                                icon={state.expanded ? <DownOutlined /> : <RightOutlined />}
                            ></Button>
                        </div>

                        <div className="col content">
                            <div className="question">
                                {<MarkdownViewer content={question.question} />}
                            </div>
                            <div className="contribution">({question.contribution})</div>
                        </div>

                        {question.conditional && (
                            <div className="conditional">
                                <Tooltip placement="top" title="Conditional question">
                                    <BranchesOutlined />
                                </Tooltip>
                            </div>
                        )}

                        {!readonly && (
                            <div className="col actions">
                                <Button onClick={edit} type="icon" icon={<EditOutlined />}></Button>
                                <Button
                                    onClick={remove}
                                    type="icon"
                                    danger
                                    icon={<DeleteOutlined />}
                                ></Button>
                            </div>
                        )}
                    </div>
                    <div className="answers">
                        {question.answers.map((answer, index) => {
                            return (
                                <div className="answer row" key={index}>
                                    <div className="icon">
                                        <CheckCircleOutlined />
                                    </div>
                                    <div className="col answer">{answer.answer}</div>
                                    <div className="col grade">{answer.grade}</div>
                                    {useScore && question.requireScore && (
                                        <div className="col score">({answer.score})</div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Group.Item>
        );
    },
);

export default Question;
