import React from "react";
import { observer } from "mobx-react";
import { Button, Tooltip, Dropdown, Menu, Tag } from "antd";
import format from "@app/lib/format";
import {
    LeftOutlined,
    RightOutlined,
    FileTextOutlined,
    FieldTimeOutlined,
} from "@ant-design/icons";

import "./style/navigation.scoped.scss";
import { Review, SearchType } from "@app/constants";

const Navigation = observer(
    ({ hasNext, hasPrevious, onNext, onPrevious, onRecent, recentActions }) => {
        function handleMenuClick({ key }) {
            const target = recentActions.actions.filter((action) => action.id === key);

            if (target?.length > 0) {
                const stage = target[0].meta?.stage;
                const type = target[0].meta?.type;
                onRecent(type, stage, key);
            }
        }

        const menu = (
            <Menu onClick={handleMenuClick} className="last-articles">
                {[...(recentActions?.actions || [])]
                    .reverse()
                    .filter((action) => action.id && action.meta)
                    .map((action) => {
                        const type = action.meta.type === SearchType.SLR ? "DUE" : "SoTA";
                        const stage = action.meta.stage === Review.L1 ? "L1" : "L2";

                        return (
                            <Menu.Item key={action.id} className="menu-item">
                                <span className="article">{`${format.cid(
                                    action.key,
                                )} - ${format.shorten(action.name)}`}</span>
                                <Tag>{type}</Tag>
                                <Tag>{stage}</Tag>
                            </Menu.Item>
                        );
                    })}
            </Menu>
        );

        return (
            <div className="navigation">
                <Button.Group>
                    <Tooltip placement="bottomLeft" title="Open the previous article in the list">
                        <Button onClick={onPrevious} disabled={!hasPrevious} className="button">
                            <LeftOutlined className="arrow" />
                            <FileTextOutlined className="file" />
                        </Button>
                    </Tooltip>

                    <Tooltip placement="bottomLeft" title="Open the next article in the list">
                        <Button onClick={onNext} disabled={!hasNext} className="button">
                            <FileTextOutlined className="file" />
                            <RightOutlined className="arrow" />
                        </Button>
                    </Tooltip>

                    <Tooltip placement="right" title="Open recently actioned articles">
                        <Dropdown
                            overlay={menu}
                            trigger="click"
                            placement="bottomCenter"
                            disabled={recentActions?.actions?.length === 0}
                        >
                            <Button className="button" icon={<FieldTimeOutlined />} />
                        </Dropdown>
                    </Tooltip>
                </Button.Group>
            </div>
        );
    },
);

export default Navigation;
