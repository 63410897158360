import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Page } from "@app/components/page";
import { useHistory } from "react-router-dom";
import notify from "@app/components/notify";
import events from "@app/lib/store/events";
import Status from "@app/components/report-document/status";
import { Role } from "@app/constants";

import store from "@app/state/store/report-document/report-documents";
import assignedStore from "@app/state/store/projects/assigned";

import "./output/style/common.scoped.scss";
import "./style/report-document-list.scoped.scss";

const ReportDocumentList = observer(() => {
    const { project } = useParams();
    const history = useHistory();
    const [totalAuthors, setTotalAuthors] = useState([]);

    useEffect(() => {
        const load = async () => {
            store.setProjectId(project);
            store.loadReportDocuments({ metaData: "true" });
            await assignedStore.load(project);
            setTotalAuthors(getTotalAuthors());
        };

        load();

        events.on("reportDocumentList.reload", async () => {
            load();
        });

        return () => {
            events.removeListener("reportDocumentList.reload", load);
        };
    }, [project]);

    if (!store.reportDocuments) {
        return null;
    }

    const getTotalAuthors = () => {
        return assignedStore
            .getAllUsers()
            ?.filter((user) =>
                user.projectRoles.find((projectRole) =>
                    [Role.LEAD_AUTHOR, Role.AUTHOR].includes(projectRole),
                ),
            );
    };

    const navigate = (reportDocument) => {
        const link = `/r/${project}/reports/documents/${reportDocument._id}`;
        history.push(link);
    };

    const ReportDocumentStatuses = observer(({ reportDocument, reportDocumentStatus }) => {
        return (
            <div className="status">
                <div>
                    <div>Status: </div>
                    <div>
                        <Status.Status reportDocument={reportDocument} />
                    </div>
                </div>

                <div>
                    <div>Progress: </div>
                    <Status.StageProgress reportDocumentStatus={reportDocumentStatus} />
                </div>
                <div>
                    <div>Assigned Team: </div>
                    {reportDocumentStatus?.currentStage ? (
                        <Status.AssignedUsers users={reportDocumentStatus.currentStage.reviewers} />
                    ) : (
                        <Status.AssignedUsers
                            users={Status.mapTeamStatus(totalAuthors, reportDocument)}
                        />
                    )}
                </div>
            </div>
        );
    });

    return (
        <Page>
            <Page.Header>
                <Page.Header.Left shrink>
                    <Page.Title>{`Report Documents`}</Page.Title>
                </Page.Header.Left>
                <Page.Header.Right></Page.Header.Right>
            </Page.Header>
            <Page.Body maxWidth={800}>
                <div className="section">
                    <div className="header">{`Report Documents`}</div>
                    <div className="body">
                        {store.reportDocuments?.map((reportDocument, i) => {
                            const canAccess = store.canAccess(reportDocument);

                            return (
                                <div
                                    key={i}
                                    className={classNames("link", { disabledLink: !canAccess })}
                                    onClick={() => {
                                        if (!canAccess) {
                                            notify.warn(
                                                "Your designated review stage for the Report Document has not commenced. Kindly await the official notification, which will be sent to your email when your review stage begins",
                                            );
                                        } else {
                                            navigate(reportDocument);
                                        }
                                    }}
                                >
                                    <div>
                                        <b>{reportDocument.name}</b>
                                    </div>
                                    <br />
                                    <ReportDocumentStatuses
                                        reportDocument={reportDocument}
                                        reportDocumentStatus={reportDocument.metaData}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Page.Body>
        </Page>
    );
});

export default ReportDocumentList;
